import React, { useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropleft } from "react-icons/io";
import { GroupContent } from "./styles";

interface TGroup {
  nameGroup: string;
  isOpen?: boolean;
}

export const Group: React.FC<TGroup> = ({ nameGroup, isOpen, children }) => {

  const [open, setOpen] = useState<boolean>(isOpen ? isOpen : false);

  const onClickOpen = (value: boolean) => {
    setOpen(value);
  }

  return (
    <GroupContent>
      <section>
        {open ? <IoIosArrowDropleft className="open-arrow" onClick={() => onClickOpen(false)} size={22} color={"var(--color-primary-light)"} /> : <IoIosArrowDropdown className="open-arrow" onClick={() => onClickOpen(true)} size={22} color={"var(--color-primary-light)"} />}
        <small>{nameGroup}</small>
      </section>
      {open && <div>{children}</div>}
    </GroupContent>
  );
};
