import styled from 'styled-components';

export const IndisponivelContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 52px;
  border-radius: 12px;
  padding: 12px;
  background-color: var(--color-content-light);
  margin: 12px;
`;
