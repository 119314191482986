import { ButtonBottomLess } from "jusadmin-ui-kit";
import { Fragment } from "react";
import { FaRegImage } from "react-icons/fa";
import { MesclarEntidadesState } from "../../../../components/app/mesclar-entidade";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { Orgao } from "../../../../services/api-services/orgao";

export const OrgaoHeader = (
  selectedEntitiesAction: SelectedDataActions<Orgao>
) => {
  const OrgaoRow = (elem: ItemDataSelect<Orgao>, index: number) => {

    const orgao = elem.entity as Orgao;
    const context = useGerenciarContext();

    const onClickMesclar = () => {

      const selecionados = selectedEntitiesAction.getSelectItems();

      if (selecionados.length >= 1) {

        const mesclagemState: MesclarEntidadesState = {
          destino: {
            id: orgao.id,
            nome: orgao.name
          },
          entidades: selecionados.map((value) => {
            return {
              entity: {
                id: value.entity.id,
                nome: value.entity.name
              },
              id_item: value.entity.id,
              isChecked: value.isChecked
            }
          })
        };

        context.changeAction({
          action: "mesclar-orgao",
          id: orgao.id,
          object: mesclagemState
        });

      } else {

        alert("Selecione um ou mais orgãos para mesclagem");

      }

    };

    const handleOpenImagem = () => {

      const image = orgao.image;

      if (image) {

        const width = 300;
        const height = 200;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const url = `${process.env.REACT_APP_STORAGE_IMAGE}/body/${orgao.id}/images/${image.id}`;

        const externalWindow = window.open(
          url,
          '_blank',
          `width=${width},height=${height},left=${left},top=${top}`
        );

        if (externalWindow) {
          externalWindow.focus();
        } else {
          alert('Habilite os pop-ups para abrir a janela externa.');
        }

      }

    }

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={false}
            options={[
              <ThreeNormalOption
                name="Mesclagem de orgãos"
                onClick={onClickMesclar}
              />,
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar-orgao",
                    id: orgao.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: orgao.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
        <RowItem>{orgao.id}</RowItem>
        <RowItem>{orgao.image ?
          <ButtonBottomLess
            className=""
            onClick={handleOpenImagem}
            icon={FaRegImage}
          />
          : <Fragment />}</RowItem>
        <RowItem>{orgao.name}</RowItem>
        <RowItem>{orgao.abbreviation}</RowItem>
      </ListTableRow>
    );
  };

  return {
    header: OrgaoRow,
  };
};
