import { Fragment } from 'react';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { StatusComponent } from '../../components/status';

export const Ativado: React.FC<{
  value: boolean;
  classname?: string;
}> = (props) => {
  const render = () => {
    if (props.value) {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={RiCheckboxBlankCircleFill}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-clear)'}
          icon={RiCheckboxBlankCircleFill}
        />
      );
    }
  };

  return <Fragment>{render()}</Fragment>;
};
