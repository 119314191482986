import {createContext,useContext} from 'react';

export type TSidebarActiveCX = {
    active : boolean;
    setActive: (isActive : boolean) => void;
}

export const SidebarActiveCX = createContext<TSidebarActiveCX>
({
    active : true, 
    setActive: active => console.warn(active)
});

export const useSidebarActive = () => useContext(SidebarActiveCX);