import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ModalAuthRoute } from '../../../../components/router/ModalAuthRoute';
import { APIFetch } from '../../../../services/api';
import { useIndicacaoFonteRespostaService } from '../../../../services/api-services/indicacaoFonteResposta';
import { useQuestaoService } from '../../../../services/api-services/questao';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const AprovarIndicacao: React.FC<{}> = () => {

  const auth = useAuthContext();
  const context = useGerenciarContext();
  const indicacaoFonteResService = useIndicacaoFonteRespostaService(APIFetch(auth));

  const handle = () => {
    indicacaoFonteResService
      .aprovarIndicacao(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          action: 'sucesso',
          id: 0,
          message: 'Indicação aprovada com sucesso!',
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          action: 'erro',
          id: 0,
          message: error.response.data.message,
        });
      });
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: 'Você não tem permissão para acessar a funcionalidade!',
        });
      }}
      permissions={['PERMISSION_GERENCIAR_QUESTOES']}
      roles={['ROLE_LIDER_EDITORIAL', 'ROLE_ADMIN', 'ROLE_EDITOR']}>
      <ConfirmationModal
        action={context.currentActionState}
        message={`Tem certeza se deseja aprovar a indicação atual na questão com ID #${context.currentAction.object?.idQuestao} ?`}
        title={'Aprovar indicação'}
        onOptionNot={() =>
          context.changeAction({
            action: '',
            id: 0,
          })
        }
        onOptionYes={handle}
      />
    </ModalAuthRoute>
  );
};
