import React, { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline, MdViewHeadline } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { CheckBox } from "../../components/checkbox";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { ThreeNormalOption } from "../../components/menu/three-normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../components/page/structure/FilterComponent/types";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../components/select/async";
import { SelectOption } from "../../components/select/option";
import {
  classificacaoQuestao,
  dificuldades,
  fontes,
  pesquisaQuestaoOptions,
  questaoOrdenacao,
  statusQuestao,
  tipoQuestao,
} from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAutorService } from "../../services/api-services/autores";
import { useDisciplinaService } from "../../services/api-services/disciplina";
import { usePerfilQuestoesService } from "../../services/api-services/perfilQuestoes";
import { Prova, useProvaService } from "../../services/api-services/prova";
import {
  Questao,
  useQuestaoService
} from "../../services/api-services/questao";
import { useTagService } from "../../services/api-services/tag";
import { useTemaService } from "../../services/api-services/tema";
import { useTipoLeiService } from "../../services/api-services/tipoLeis";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { DivisoesProjetoProvider } from "../CriarProjeto/forms/assistente-estrutura/provider";
import { CriarTipoLei } from "../GerenciarTipoLeis/forms/criar-tipo-lei";
import { FormProva } from "./forms/adicionar-prova";
import { AdicionarQuestoesProjeto } from "./forms/adicionar-questoes-projeto";
import { FormClassificacao } from "./forms/classificar-questoes";
import { ExcluirQuestao } from "./forms/excluir-questao";
import { ExclusaoLoteQuestao } from "./forms/exclusao-lote-questao";
import { FecharFormQuestao } from "./forms/fechar-form-card";
import { ReferenciaLegalFiltros } from "./forms/pesquisa-referencias";
import { PosExclusaoQuestoesModal } from "./forms/pos-exclusao-questoes";
import { FormValidacaoQuestao } from "./forms/validar-questoes";
import { usePesquisaQuestaoCheckHandler } from "./hooks/usePesquisaQuestaoCheckHandler";
import { HeaderLeft, HeaderRight, PesquisaOptions } from "./styles";
import { QuestaoCardRender } from "./views/card-questao/render";
import { useDispositivosLegaisHook } from "./views/referencias-legais/hooks/useDispositivosLegaisHook";
import { QuestaoHeader } from "./views/row-questao";
import { getHeaderProva } from "./views/visualizar-questao/enunciado";

export interface QuestaoPesquisaOpcoes {
  label: string;
  valor: string;
  check: boolean;
}

export interface GerenciarQuestoesPropsURL {
  search?: string;
}

export const GerenciarQuestoesComponent = () => {
  const location = useLocation<GerenciarQuestoesPropsURL>();

  const auth = useAuthContext();

  const { roles } = auth.user;

  const context = useGerenciarContext();

  const dispositivosLegaisHook = useDispositivosLegaisHook();

  const questaoService = useQuestaoService(APIFetch(auth));

  const disciplinaService = useDisciplinaService(APIFetch(auth));

  const tipoLeiService = useTipoLeiService(APIFetch(auth));

  const provaService = useProvaService(APIFetch(auth));

  const temaService = useTemaService(APIFetch(auth));

  const tagService = useTagService(APIFetch(auth));

  const autorService = useAutorService(APIFetch(auth));

  const perfilQuestoesService = usePerfilQuestoesService(APIFetch(auth));

  const [prova, setProva] = useState<Prova>();

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState(() => {
    if (roles.includes("ROLE_CLASSIFICADOR")) {
      return "item";
    } else {
      return "header";
    }
  });

  const [status, setStatus] = useState("");

  const [porUsuario, setPorUsuario] = useState("");

  const [incluirComentario, setIncluirComentario] = useState("");

  const [period, setPeriod] = useState("");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const [disciplina, setDisciplina] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const pesquisaCheckboxFiltros = useState<Array<QuestaoPesquisaOpcoes>>(
    pesquisaQuestaoOptions
  );

  const pesquisaTodosCheckbox = useState<QuestaoPesquisaOpcoes>({
    check: true,
    label: "Todos",
    valor: "",
  });

  const questaoState = useState<Array<ItemDataSelect<Questao>>>([]);
  const setQuestaos = questaoState[1];

  const useTagActions = useTagAction();

  const usePesquisaCheckHandler = usePesquisaQuestaoCheckHandler({
    filtrarTodosState: pesquisaTodosCheckbox,
    filtrosState: pesquisaCheckboxFiltros,
  });

  const selectedEntitiesAction = useSelectedDataAction<Questao>({
    state: questaoState,
  });

  const listarTiposLeisAPI = (page: number, value: string) => {
    return tipoLeiService.listarTipoLeis(page, 30, value);
  };

  const keys: string[] = [
    "status",
    "type",
    "difficulty",
    "nature",
    "exam",
    "subject",
    "topic",
    "source",
    "authorName",
    "profileName",
    "classification",
    "tag",
    "legalReferences",
  ];

  useEffect(() => {
    if (location.search.length >= 1) {
      const params = new URLSearchParams(location.search);
      const provaId = params.get("exam");

      if (provaId) {
        provaService.buscarProva(Number.parseInt(provaId)).then((data) => {
          setProva(data);
        });
      }
    } else {
      if (prova) {
        setProva(undefined);
        context.changeLoading(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (context.loading) {
      questaoService
        .listarQuestaosPorPagina(
          context.resetPagination() ? 0 : context.nextPage - 1,
          mode,
          qtdItems,
          status,
          period,
          porUsuario,
          incluirComentario,
          useTagActions.getFilters(keys),
          search,
          usePesquisaCheckHandler.getFiltros(),
          sort,
          order,
          prova?.id
        )
        .then((questoes) => {
          setQuestaos(
            questoes.content.map((value: Questao) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(questoes.paginationData);
          context.toPage(questoes.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading, prova]);

  const rightMenu = () => {
    if (!roles.includes("ROLE_CLASSIFICADOR")) {
      return (
        <HeaderRight>
          <ButtonNormal
            icon={MdAddCircleOutline}
            title={"Nova questão"}
            className={"nova-questao"}
            onClick={() => {
              window.open(
                `/questao${prova ? `?exam=${prova.id}` : ``}`,
                "_blank"
              );
            }}
          />
        </HeaderRight>
      );
    }
  };

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Questão" isOpen={true}>
          <GroupMain>
            <SelectOption
              className="fonte field-group"
              label="Filtrar por fonte"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={fontes}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por dificuldade"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={dificuldades}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por tipo"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={tipoQuestao}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por status"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={statusQuestao}
            />
            {!prova && (
              <AsyncSelect
                className="prova field-group"
                label="Filtrar por prova"
                onAddFilterItem={(item) => useTagActions.addTag(item)}
                getDataAPI={(page, value) =>
                  provaService.listarProvas(page, 30, value)
                }
              />
            )}
          </GroupMain>
        </Group>
        <ReferenciaLegalFiltros
          dispositivosLegaisHook={dispositivosLegaisHook}
          useTagActions={useTagActions}
          listarTiposLeisAPI={listarTiposLeisAPI}
        />
        <Group nameGroup="Classificação">
          <GroupMain>
            <SelectOption
              className="fonte field-group"
              label="Filtrar por classificação"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={classificacaoQuestao}
            />
            <AsyncSelect
              className="disciplina field-group"
              label="Filtrar por disciplina"
              onAddFilterItem={(item) => {
                useTagActions.addTag(item);
                setDisciplina(item);
              }}
              getDataAPI={(page, value) =>
                disciplinaService.listarDisciplinas(page, 30, value)
              }
            />
            <AsyncSelect
              className="tema field-group"
              label="Filtrar por tema"
              dependency={true}
              secondaryValue={disciplina}
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPIParam={(page, value, param) =>
                temaService.listarTemasByDisciplina(
                  page,
                  30,
                  value,
                  param,
                  true
                )
              }
            />
            <AsyncSelect
              className="tag field-group"
              label="Filtrar por TAG"
              dependency={true}
              secondaryValue={disciplina}
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPIParam={(page, value, param) =>
                tagService.listarTagsByDisciplina(page, 30, value, param, true)
              }
            />
          </GroupMain>
        </Group>
        {!roles.includes("ROLE_CLASSIFICADOR") && (
          <Group nameGroup="Comentários da questão">
            <GroupMain>
              <AsyncSelect
                className="perfil field-group"
                label="Filtrar por perfil de questão"
                onAddFilterItem={(item) => useTagActions.addTag(item)}
                getDataAPI={(page, value) =>
                  perfilQuestoesService.listarPerfils(page, 30, value)
                }
              />
              <AsyncSelect
                className="autor field-group"
                label="Filtrar por autor"
                onAddFilterItem={(item) => useTagActions.addTag(item)}
                getDataAPI={(page, value) =>
                  autorService.listarAutores(page, 30, value)
                }
              />
            </GroupMain>
          </Group>
        )}
      </Fragment>
    );
  };

  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(true);
  };

  const modals = () => {
    switch (context.currentAction.action) {

      case "excluir": {
        return <ExcluirQuestao />;
      }

      case "add-questao-projeto": {
        return (
          <DivisoesProjetoProvider>
            <AdicionarQuestoesProjeto
              ids={selectedEntitiesAction.getSelectItems()}
            />
          </DivisoesProjetoProvider>
        );
      }

      case "add-prova": {
        return <FormProva ids={selectedEntitiesAction.getSelectItems()} />;
      }

      case "fechar-form-questao": {
        return <FecharFormQuestao />
      }

      case "criar-tipo-lei": {
        return <CriarTipoLei externalAction={context.currentActionState} />;
      }

      case "atualizar-questao-form": {
        return <FecharFormQuestao />
      }

      case "add-classificacao": {
        return (
          <FormClassificacao ids={selectedEntitiesAction.getSelectItems()} />
        );
      }

      case "validacao": {
        return (
          <FormValidacaoQuestao ids={selectedEntitiesAction.getSelectItems()} />
        );
      }

      case "exclusao-questoes": {
        return <ExclusaoLoteQuestao />;
      }

      case "pos-exclusao-questoes": {
        return <PosExclusaoQuestoesModal />;
      }

      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }

      default: {
        return <Fragment />;
      }
    }
  };

  const opcaoGerenciamento = () => {
    if (roles.includes("ROLE_CLASSIFICADOR")) {
      return [];
    } else {
      const exclusaoQuestaoBotao = prova && (
        <ThreeNormalOption
          name="Excluir questões"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length === 0) {
              alert("Você precisa selecionar ao menos uma questão");
            } else if (selectedEntitiesAction.getSelectItems().length >= 200) {
              alert("Você só pode excluir até 200 questões por vez");
            } else {
              context.changeAction({
                action: "exclusao-questoes",
                id: 0,
                object: {
                  questoes: new Set(selectedEntitiesAction.getSelectItems().map((value) => {
                    return value.entity.id;
                  })),
                },
              });
            }
          }}
        />
      );

      const botoes = [
        <ThreeNormalOption
          name="Adicionar ao projeto"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length == 0) {
              alert("Você precisa selecionar ao menos uma questão..");
            } else {
              context.changeAction({
                action: "add-questao-projeto",
                id: 0,
              });
            }
          }}
        />,
        <ThreeNormalOption
          name="Adicionar prova a questões"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length == 0) {
              alert("Você precisa selecionar ao menos uma questão..");
            } else {
              context.changeAction({
                action: "add-prova",
                id: 0,
              });
            }
          }}
        />,
        <ThreeNormalOption
          name="Classificar questões"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length == 0) {
              alert("Você precisa selecionar ao menos uma questão..");
            } else {
              context.changeAction({
                action: "add-classificacao",
                id: 0,
              });
            }
          }}
        />,
        <ThreeNormalOption
          name="Validação de questões"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length == 0) {
              alert("Você precisa selecionar ao menos uma questão..");
            } else {
              context.changeAction({
                action: "validacao",
                id: 0,
              });
            }
          }}
        />,
      ];

      exclusaoQuestaoBotao && botoes.push(exclusaoQuestaoBotao);

      return botoes;
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<Questao>
        callbacks={{
          search: (key) => {
            setSearch(key);

            if (key.length === 0) {
              context.changeLoading(true);
            }
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => {
            setMode(button.value);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={questaoState}
        filters={filters()}
        filterBar={{
          left: null,
          right: (
            <Fragment>
              <GroupButtons
                initialPositionSelected={2}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setStatus),
                    name: "validadas",
                    classname: "status-option",
                    value: "Validada",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setStatus),
                    name: "pendentes",
                    classname: "status-option",
                    value: "Pendente",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setStatus),
                    classname: "status-option ambos-validacao",
                    value: "",
                    minWidth: 8,
                    icon: MdViewHeadline,
                  },
                ]}
              />
              <GroupButtons
                initialPositionSelected={3}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "hoje",
                    classname: "period-option",
                    value: "day",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "semana",
                    classname: "period-option",
                    value: "week",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "mês",
                    classname: "period-option",
                    value: "month",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    classname: "period-option",
                    value: "",
                    minWidth: 9,
                    icon: MdViewHeadline,
                  },
                ]}
              />
              <GroupButtons
                initialPositionSelected={2}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setIncluirComentario),
                    name: "com comentários",
                    classname: "incluir-comentarios-option",
                    value: "1",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setIncluirComentario),
                    name: "sem comentários",
                    classname: "incluir-comentarios-option",
                    value: "0",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setIncluirComentario),
                    classname: "incluir-comentarios-option",
                    value: "2",
                    minWidth: 9,
                    icon: MdViewHeadline,
                  },
                ]}
              />
            </Fragment>
          ),
        }}
        page={{
          name: prova
            ? `Questões: ${getHeaderProva(prova)}`
            : `${roles.includes("ROLE_CLASSIFICADOR")
              ? "Questões"
              : "Gerenciar questões"
            }`,
          header: {
            left: (
              <HeaderLeft>
                <GroupButtons
                  className="botoes-gerenciamento-questao"
                  initialPositionSelected={
                    !roles.includes("ROLE_CLASSIFICADOR") ? 1 : 0
                  }
                  buttons={
                    !roles.includes("ROLE_CLASSIFICADOR")
                      ? [
                        {
                          onClick: (current) =>
                            loadingByButtonFilter(current, setPorUsuario),
                          name: "Minhas questões",
                          classname: "status-option",
                          value: "user",
                        },
                        {
                          onClick: (current) =>
                            loadingByButtonFilter(current, setPorUsuario),
                          name: "Questões",
                          classname: "status-option",
                          value: "",
                        },
                      ]
                      : []
                  }
                />
              </HeaderLeft>
            ),
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por id, código, ou palavra-chave",
          searchOptions: (
            <PesquisaFiltros
              opcoes={pesquisaCheckboxFiltros[0]}
              todos={pesquisaTodosCheckbox[0]}
              handleCheckAllPesquisa={
                usePesquisaCheckHandler.handleCheckAllPesquisa
              }
              handleCheckPesquisa={usePesquisaCheckHandler.handleCheckPesquisa}
            />
          ),
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: questaoOrdenacao,
          },
          options: opcaoGerenciamento(),
          mode: mode,
          card: {
            dataColumn: QuestaoCardRender({ selectedEntitiesAction, mode }).component,
          },
          table: {
            dataRow: [
              <div>Ações</div>,
              <div>ID</div>,
              <div>Status</div>,
              <div>Elegível para classificação externa(JusQuestões)?</div>,
              <div>Validado</div>,
              <div>Cód</div>,
              <div>Tipo</div>,
              <div>Tema</div>,
              <div>TAG</div>,
              <div>Disciplina(PROVA)</div>,
              <div>Disciplina</div>,
              <div>Banca</div>,
              <div>Cargo</div>,
              <div>Carreira</div>,
              <div>Ano</div>,
              <div>Dificuldade</div>,
              <div>Fonte</div>,
            ],
            dataColumn: QuestaoHeader(selectedEntitiesAction, mode)
              .questaoHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const PesquisaFiltros = ({
  opcoes,
  todos,
  handleCheckAllPesquisa,
  handleCheckPesquisa,
}: {
  opcoes: Array<QuestaoPesquisaOpcoes>;
  todos: QuestaoPesquisaOpcoes;
  handleCheckAllPesquisa: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckPesquisa: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: QuestaoPesquisaOpcoes
  ) => void;
}) => {
  return (
    <PesquisaOptions>
      <small>Encontrar a pesquisa em: </small>
      <div className="options">
        {opcoes.map((value, index) => {
          return (
            <CheckBox
              key={index}
              label={value.label}
              value={value.valor}
              checked={value.check}
              onChange={(event) => handleCheckPesquisa(event, value)}
            />
          );
        })}
        <CheckBox
          label={todos.label}
          checked={todos.check}
          value={todos.valor}
          onChange={(event) => handleCheckAllPesquisa(event)}
        />
      </div>
    </PesquisaOptions>
  );
};

export const GerenciarQuestoes = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarQuestoesComponent />
    </GerenciarBaseProvider>
  );
};
