import styled from "styled-components";


export const TextItemContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 12px;
    
  h4 {
     font-weight: 500;
     color: var(--color-text-dark);  
  }

  p {
    font-size: 1.0rem;
    color: var(--color-text-grey);
  }

    .icon-title{
      margin-right: 8px;
      color: var(--color-primary-light);
    }
  
    .content{
        width: 100%;
        margin-left: 12px;
        margin-right: 12px;
    }
`;