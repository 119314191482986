import { IconBaseProps } from "react-icons";
import { RiArrowDownSLine } from "react-icons/ri";
import { MenuHeaderText } from "../../menu/three-dots/styles";



export interface TCollapseButton {
    className: string;
    icon?: React.ComponentType<IconBaseProps>;
    degreeIcon?: number;
    selecionado?: string;
    editarOpen: boolean;
    setEditar: (type: string) => void;
    label?: string;
    type: string;
}

export const CollapseButton: React.FC<TCollapseButton> = ({
    icon: Icon,
    className,
    selecionado,
    editarOpen,
    setEditar,
    label,
    degreeIcon,
    type,
}) => {
    const open = () => {
        if (selecionado) {
            if (type === selecionado) {
                return editarOpen;
            } else {
                return false;
            }
        }

        return editarOpen;
    };

    const renderIcon = () => {
        if (Icon) {
            return <Icon size={18} className={"select-icon-open"} />
        } else {
            return <RiArrowDownSLine size={18} className={"select-icon-open"} />
        }
    }

    return (
        <MenuHeaderText
            className={className}
            degree={degreeIcon}
            isOpen={open()}
            onClick={() => setEditar(type)}
        >
            {renderIcon()}
            {label && <span>{label}</span>}
        </MenuHeaderText>
    );
};