import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { orgaoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarCarreira } from "./forms/criar-carreira";
import { Carreira, useCarreiraService } from "../../services/api-services/carreira";
import { CarreiraHeader } from "./views/row-carreira";
import { ExcluirCarreira } from "./forms/excluir-carreira";

const GerenciarCarreirasComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const carreiraService = useCarreiraService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const carreiraState = useState<Array<ItemDataSelect<Carreira>>>([]);
  const setCarreiras = carreiraState[1];

  const selectedEntitiesAction = useSelectedDataAction<Carreira>({
    state: carreiraState,
  });

  useEffect(() => {
    if (context.loading) {
      carreiraService
        .listagemCarreira(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((carreiras) => {
          setCarreiras(
            carreiras.content.map((value: Carreira) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(carreiras.paginationData);
          context.toPage(carreiras.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Nova carreira"}
          className={""}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar-carreira" });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirCarreira />;
      }
      case "criar-carreira": {
        return <CriarCarreira isModal />;
      }
      case "editar-carreira": {
        return <CriarCarreira isModal />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<Carreira>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={carreiraState}
        page={{
          name: "Listagem de carreiras",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome da carreira",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: orgaoOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Imagem</div>,
              <div>Nome</div>,
              <div>Ações</div>
            ],
            dataColumn: CarreiraHeader(selectedEntitiesAction).header,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarCarreiras = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarCarreirasComponent />
    </GerenciarBaseProvider>
  );
};
