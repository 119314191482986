import styled from "styled-components";

export const TagFilterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary-dark);
  padding: 6px;
  margin: 4px;
  border-radius: 12px;
  user-select: none;


  small {
    color: var(--color-text-light);
    font-weight: 500;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-left: 4px;
    margin-right: 4px;
  }

  .delete-tag {
    color: var(--color-text-light);
    cursor: pointer;
  }
`;
