import { CollapseButton } from "jusadmin-ui-kit";
import { MdOutlineEditNote } from "react-icons/md";
import { VscOpenPreview } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { CountTag } from "../../../../../components/page/structure/CountTag";
import { QuestaoItem } from "../../../../../services/api-services/questao";
import { useAuthContext } from "../../../../../services/auth-services/auth/contextAuth";
import { LabelValue } from "../../../../GerenciarComentarios/elements";
import { FooterLeft, FooterRight, FooterStyle } from "../styles";

export interface QuestaoCardFooterProps {
    questao: QuestaoItem;
    abrirRefsState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const QuestaoCardFooter = ({ questao, abrirRefsState }: QuestaoCardFooterProps) => {

    const auth = useAuthContext();
    const { roles } = auth.user;

    const [abrirReferencias, setAbrirReferencias] = abrirRefsState;

    return (
        <FooterStyle className={"item-margin"}>
            <FooterRight>
                {questao.created_by !== undefined && (
                    <LabelValue
                        label={"Criado/importado por"}
                        value={questao.created_by}
                    />
                )}
                <CountTag
                    count={questao.qtd_comments}
                    title="comentários"
                    classname={"item-margin"}
                />
                <CountTag
                    count={questao.qtd_answers}
                    title="respostas"
                    classname={"item-margin"}
                />
                <CountTag
                    count={questao.qtd_supports}
                    title="textos de apoios"
                    classname={"item-margin"}
                />
            </FooterRight>
            <FooterLeft>
                <CollapseButton
                    label="Referências legais"
                    className={""}
                    editarOpen={abrirReferencias}
                    setEditar={() => setAbrirReferencias(abrirReferencias ? false : true)}
                    type={""}
                />
                {!roles.includes("ROLE_CLASSIFICADOR") ? (
                    <ButtonBottomLess
                        className="button-view"
                        title={"Ver questão"}
                        icon={VscOpenPreview}
                        onClick={() => {
                            window.open(`/questao/${questao.id}`, "_blank");
                        }}
                    />
                ) : (
                    <ButtonBottomLess
                        className="button-view"
                        title={"Atualizar questão"}
                        icon={MdOutlineEditNote}
                        onClick={() => {
                            window.open(`/questao/editar/${questao.id}`, "_blank");
                        }}
                    />
                )}
            </FooterLeft>
        </FooterStyle>
    );
};
