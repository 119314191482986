import React, { useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { BsThreeDots } from 'react-icons/bs';
import useOnClickOutside from '../../util/ClickOutside';
import { DropdrownItem } from '../styles';
import { MainMenu, MenuContent, MenuHeader, ThreeDotsOptionContent } from '../three-dots/styles';

export interface TThreeNormal {
  size: number;
  isLastPosition: boolean;
  className: string;
  options: Array<React.ReactNode>;
}

export const ThreeNormal: React.FC<TThreeNormal> = (props) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  useOnClickOutside(divRef, () => {
    setIsOpen(false);
  });

  return (
    <MainMenu ref={divRef} onClick={open} className={props.className}>
      <MenuHeader isOpen={isOpen} isLastPosition={props.isLastPosition}>
        <BsThreeDots size={props.size} className={'select-icon-open'} />
      </MenuHeader>
      {isOpen && (
        <MenuContent className={`${props.className}-content`} isLastPosition={props.isLastPosition}>
          {props.options.map((value: React.ReactNode, index) => (
            <DropdrownItem>{value}</DropdrownItem>
          ))}
        </MenuContent>
      )}
    </MainMenu>
  );
};

export interface TThreeNormalOption {
  onClick: () => void;
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

export const ThreeNormalOption: React.FC<TThreeNormalOption> = ({ icon: Icon, name, onClick, ...rest }) => {
  return (
    <ThreeDotsOptionContent onClick={onClick} {...rest}>
      {Icon && <Icon size={18} className={'bottom-icon'} />}
      <div className="item-link">{name}</div>
    </ThreeDotsOptionContent>
  );
};
