import { AxiosInstance } from "axios";
import { ImageInfoDetails, Service } from "../../service";

export class ProjetoFileService extends Service {
  private ENDPOINT = "project";

  async salvarImagemProjeto(
    dados: FormData,
    idProjeto: number
  ): Promise<ImageInfoDetails> {
    return this.fetch
      .post(this.ENDPOINT + `/${idProjeto}/images/upload`, dados)
      .then((response) => {
        return response.data;
      });
  }

  async deletarImagemProjeto(
    idImagem: String,
    idProjeto: number
  ): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idProjeto}/images/${idImagem}`
    );
  }
}

export const useProjetoFileService = (axios: AxiosInstance) => {
  return new ProjetoFileService(axios);
};
