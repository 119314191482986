import React, { HTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { ButtonGroupContent } from './styles';

export interface TButtonGroup extends HTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType<IconBaseProps>;
  title?: string;
  className: string;
  active: boolean;
  minWidth?: number;
}

export const ButtonGroup: React.FC<TButtonGroup> = ({ icon: Icon, className, title, active, minWidth, ...rest }) => {
  return (
    <ButtonGroupContent active={active} minWidth={minWidth ? minWidth : 100} className={className} {...rest}>
      {Icon && <Icon size={22} className={'icon'} />}
      {title && title}
    </ButtonGroupContent>
  );
};
