import styled from 'styled-components';

export const MainDashboard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
`;

interface TCircle {
  color: string;
}

export const Circle = styled.div`
  border-radius: 50%;
  ${(circle: TCircle) => `background-color: ${circle.color}`};
  height: 10px;
  width: 10px;
  max-width: 10px;
`;

export const Group = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--color-content-light);
  border: 1px solid var(--color-select-border);
  border-radius: 12px;
  height: 100%;

  p {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 1rem;
    margin-left: 12px;
  }

  section {
    margin: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .legenda-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    margin: 12px;
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-left: 12px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: var(--color-secondary-grey);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-height: 80px;

    span {
      color: var(--color-text-secondary);
      font-weight: 500;
      font-size: 0.9rem;
      margin-left: 12px;
    }
  }

  .chart-1 {
    max-width: 280px;
    padding: 24px;
  }

  .chart-2 {
    max-width: 220px;
    padding: 24px;
  }
`;

export const ColunaPrimaria = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 18px;
`;

export const ColunaSecundaria = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 18px;

  .row-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    .numbers {
      max-height: 140px;
      margin-left: 12px;
    }
  }
`;

export const ItemLegenda = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  b {
    margin-left: 12px;
  }
`;
