import { AxiosInstance } from "axios";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { Service } from "../../service";

export class TagService extends Service {
  private ENDPOINT = "tag";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemTag(
    nomeDisciplina: string,
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string,
    filters: string[]
  ): Promise<TagsPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${search}*';subject:'${nomeDisciplina}'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let disciplinas = new Array<Tag>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        disciplinas = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: disciplinas,
          paginationData: pagination,
        };
      });
  }

  async listarTagsByDisciplina(
    page: number,
    qtd: number,
    nome: string,
    disciplina: string,
    useIdAsKey: boolean
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${nome}*';subject:'${disciplina}'&page=${page}&size=${qtd}&sort=name&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        if (disciplina != "") {
          response.data.content.forEach((value: Tag) =>
            array.push({
              id: value.id,
              value: value.name,
              key: "tag",
              useId: useIdAsKey ? true : false,
            })
          );
        }

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async buscarTag(id: number): Promise<Tag> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarTag(tag: TagRequest): Promise<Tag> {
    return this.fetch
      .post(this.ENDPOINT, tag, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarTag(tag: TagRequest): Promise<Tag> {
    return this.fetch
      .put(this.ENDPOINT + `/${tag.id}`, tag, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirTag(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useTagService = (axios: AxiosInstance) => {
  return new TagService(axios);
};

export interface Tag {
  id: number;
  name: string;
  subject: {
    id: number;
    name: string;
  };
}

export interface TagRequest {
  id?: number;
  name: string;
  selected_subject_id: number;
}

export type TagsPagination = {
  content: Array<Tag>;
  paginationData: PaginationData;
};

export function tagIsClassificado(tag: string) {
  if (!tag || tag === "N/A") {
    return "Sem classificação";
  }
  return tag;
}
