import styled from 'styled-components';

export const CriarEstiloExportacaoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 8px;

  .content {
    background-color: var(--color-background);
  }

  .titulo h3 {
    margin-left: 12px;
  }
`;


export interface TColorPicker {
  color: string
}

export const ColorPickerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .rcp{
    position: absolute;
    top: 110%;
    left: -6%;
    z-index: 100;
    width: 100%;
    box-shadow: -4px 2px 25px 0px rgba(0, 0, 0, 0.1);
  }
  
`

export const ColorPickerStyle = styled.div`
  min-height: 20px;
  border-radius: 5px;
  border: 1px solid var(--color-content-border);
  ${(picker) => picker.color && ' background-color:' + picker.color};
`
export const EstiloExportacaoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;

  .list-options {
    margin-right: 24px;
    margin-bottom: -18px;
  }

  .nome-estilo{
    min-width: 240px;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
