import { AxiosInstance } from "axios";
import { ItemSelect, ItemsSelectPage } from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { Service } from "../../service";

export class EstiloExportacaoService extends Service {
    private ENDPOINT = "project/export-styles";

    constructor(axios: AxiosInstance) {
        super(axios);
    }

    async listagemEstiloExportacao(
        page: number,
        qtd: string,
        search: string,
        sort: string,
        order: string
    ): Promise<EstiloExportacaoPagination> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `?search=search_key:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
            )
            .then((response) => {
                let EstiloExportacaos = new Array<EstiloExportacao>();
                let pagination = {
                    current_page: 0,
                    total_items: 0,
                    total_number_pages: 1,
                };

                EstiloExportacaos = response.data.content;
                pagination = {
                    total_items: response.data.total_items,
                    current_page: response.data.current_page,
                    total_number_pages: response.data.total_number_pages,
                };

                return {
                    content: EstiloExportacaos,
                    paginationData: pagination,
                };
            });
    }

    async listarFontesDisponiveis(search: string): Promise<ItemsSelectPage> {
        return this.fetch.get(this.ENDPOINT + `/fonts?search=${search}`).then((response) => {
            const array = new Array<ItemSelect>();

            response.data.forEach((value: string) =>
                array.push({
                    id: 0,
                    value: value,
                    key: "font",
                    useId: false,
                })
            );

            return {
                content: array,
                total_number_pages: 1,
            };
        })
    }
    async listarEstiloExportacaos(
        page: number,
        qtd: number,
        nome: string
    ): Promise<ItemsSelectPage> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `?search=search_key:'*${nome}*'&page=${page}&size=${qtd}&sort=name&order=desc`
            )
            .then((response) => {
                const array = new Array<ItemSelect>();

                response.data.content.forEach((value: EstiloExportacao) =>
                    array.push({
                        id: value.id,
                        value: value.style_name,
                        key: "export-styles",
                        useId: true,
                    })
                );

                return {
                    content: array,
                    total_number_pages: response.data.total_number_pages,
                };
            });
    }

    async buscarEstiloExportacao(id: number): Promise<EstiloExportacao> {
        return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
            return response.data;
        });
    }

    async salvarEstiloExportacao(EstiloExportacao: EstiloExportacaoRequest): Promise<EstiloExportacao> {
        return this.fetch
            .post(this.ENDPOINT, EstiloExportacao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async editarEstiloExportacao(EstiloExportacao: EstiloExportacaoRequest): Promise<EstiloExportacao> {
        return this.fetch
            .put(this.ENDPOINT + `/${EstiloExportacao.id}`, EstiloExportacao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async excluirEstiloExportacao(id: number): Promise<void> {
        return this.fetch.delete(this.ENDPOINT + `/${id}`);
    }
}

export const useEstiloExportacaoService = (axios: AxiosInstance) => {
    return new EstiloExportacaoService(axios);
};

export interface EstiloExportacao {
    id: number;
    style_name: string,
    style: {
        header: EstiloPadrao,
        statement: EstiloPadrao,
        answers: EstiloPadrao
    }
}

export interface EstiloExportacaoRequest {
    id?: number;
    style_name: string,
    style: {
        header: EstiloPadraoRequest,
        statement: EstiloPadraoRequest,
        answers: EstiloPadraoRequest
    }
}

export interface EstiloPadrao {
    font: string,
    bold: boolean,
    italic: boolean,
    fontSize: number,
    fontColor: string,
    paragraphAlignment: string,
    paragraphSpacing: number,
    textHighlightColor: string,
}

export interface EstiloPadraoRequest {
    font?: string,
    bold?: boolean,
    italic?: boolean,
    fontSize?: number,
    fontColor?: string,
    paragraphAlignment?: string,
    paragraphSpacing?: number,
    textHighlightColor?: string,
}

export type EstiloExportacaoPagination = {
    content: Array<EstiloExportacao>;
    paginationData: PaginationData;
};
