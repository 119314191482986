import { AxiosInstance } from 'axios';
import { CheckBoxItem } from '../../../components/group-checkbox';
import { PaginationData } from '../../../components/pagination/types';
import { Service } from '../../service';

export class UsuarioService extends Service {
  private ENDPOINT = 'user';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemUsuarios(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<UsuariosPagination> {
    return this.fetch
      .get(this.ENDPOINT + `?search=fullname:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`)
      .then((response) => {
        let usuarios = new Array<Usuario>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        usuarios = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: usuarios,
          paginationData: pagination,
        };
      });
  }

  async buscarUsuario(id: number): Promise<Usuario> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async listarPermissoes(nomePermissaoRaiz: string): Promise<CheckBoxItem[]> {
    return this.fetch.get(this.ENDPOINT + `/permission?rootName=${nomePermissaoRaiz}`).then((response) => {

      const permissoes: CheckBoxItem[] = response.data.map((item: Permission) => {

        const check: CheckBoxItem = {
          className: "field-checkbox field-size",
          id: item.id,
          value: item.name,
          isChecked: false,
          disabled: false,
        };

        return check;

      });

      return permissoes;

    });
  }

  async salvarUsuario(usuario: UsuarioRequest): Promise<Usuario> {
    return this.fetch
      .post(this.ENDPOINT, usuario, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarUsuario(usuario: UsuarioRequest): Promise<Usuario> {
    return this.fetch
      .put(this.ENDPOINT + `/${usuario.id}`, usuario, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirUsuario(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }

  async trocarSenha(idUsuario: number, senhaUpdate: SenhaUpdate): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/${idUsuario}/password`, senhaUpdate);
  }

  async resetarSenha(idUsuario: number, senhaUpdate: SenhaUpdate): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/${idUsuario}/password/reset`, senhaUpdate);
  }
}

export const useUsuarioService = (axios: AxiosInstance) => {
  return new UsuarioService(axios);
};

export interface Permission {
  id: number;
  name: string;
  root: Permission;
}

export interface UsuarioResumido {
  id: number;
  username: string;
  name: string;
  surname: string;
  email: string;
  activated: boolean;
  suspended: boolean;
  authority: string;
}

export interface Usuario {
  id: number;
  username: string;
  name: string;
  surname: string;
  email: string;
  activated: boolean;
  suspended: boolean;
  authority: string;
  two_fa: boolean;
  permissions: Permission[];
}

//////////////////////////////

export interface PermissionID {
  id: number;
}

export interface UsuarioRequest {
  id?: number;
  username: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmed_password: string;
  authority: string;
  permissions: PermissionID[];
}

export type UsuariosPagination = {
  content: Array<Usuario>;
  paginationData: PaginationData;
};

export interface SenhaUpdate {
  new_password: string;
  confirmed_new_password: string;
  current_password: string;
}
