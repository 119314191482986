import React, { Fragment } from "react";
import { TagFilterContent } from "./styles";

interface Ttag {
  nome?: string;
  classname?: string;
  title?: string;
  position?: string;
  width?: string;
  colorDark?: string;
  html?: React.ReactNode;
  divRef?: React.RefObject<HTMLDivElement>;
}

export const Tag: React.FC<Ttag> = ({
  nome,
  width,
  colorDark,
  title,
  position,
  classname,
  html,
  divRef,
}) => {
  return (
    <Fragment>
      {nome && (
        <TagFilterContent
          ref={divRef}
          width={width}
          position={position}
          colorDark={colorDark}
          className={classname}
        >
          {title && <small>{title}</small>}
          <div>{nome}</div>
        </TagFilterContent>
      )}
      {html && (
        <TagFilterContent
          ref={divRef}
          width={width}
          position={position}
          colorDark={colorDark}
          className={classname}
        >
          {html}
        </TagFilterContent>
      )}
    </Fragment>
  );
};
