import { CheckBox } from "../../../../components/checkbox";
import {
  ThreeNormal,
  ThreeNormalOption
} from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { EstiloExportacao } from "../../../../services/api-services/estiloExportacao";

export const EstiloExportacaoHeader = (
  selectedEntitiesAction: SelectedDataActions<EstiloExportacao>
) => {
  const EstiloExportacaoRow = (elem: ItemDataSelect<EstiloExportacao>, index: number) => {
    const estiloExportacao = elem.entity as EstiloExportacao;
    const context = useGerenciarContext();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{estiloExportacao.id}</RowItem>
        <RowItem>{estiloExportacao.style_name}</RowItem>
        <RowItem width="10px">
          <ThreeNormal
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar-estilo-exportacao",
                    id: estiloExportacao.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: estiloExportacao.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    estiloExportacaoHeader: EstiloExportacaoRow,
  };
};
