import styled from "styled-components";

export const QuestaoAcoes = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--color-primary-clear);
  padding: 8px;
  border-radius: 18px;
  gap: 8px;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    .cancelar {
      margin-right: 2px;
      height: 38px;
    }

    .salvar {
      height: 38px;
    }
  }
`;

export const QuestaoOptions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  border-radius: 8px;
  padding: 18px;
  height: 100%;

  .opcoes-questao {
    flex-direction: row;
    flex: 1 1 100%;

    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
`;

export const QuestaoMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 8px;

  @media (max-width: 700px) {
    flex-direction: column;
    margin: 12px;
  }
`;

export const RespostasForm = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 32px;
  width: 100%;
  height: 100%;
`;

export const ApoioForm = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 32px;
  width: 100%;
  height: 100%;
`;

export const EnunciadoForm = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 32px;
  width: 100%;
  height: 100%;
`;

export const CriarClassificacaoContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--color-background);
  border-radius: 8px;
  margin-bottom: 12px;

  @media (max-width: 700px) {
    width: 100%;
    flex-direction: column;
  }

  .form-classificacao,
  .form-options {
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    justify-content: center;
  }

  .salvar-tema,
  .nova-disciplina {
    margin-left: 18px;
  }

  .form-classificacao {
    .field-group {
      margin-left: 18px;
    }
  }
`;

export const QuestaoInfoForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  background-color: var(--color-content-light);
  height: 100%;
  padding: 28px;


  .criarTemaOuDisciplina {
    margin-bottom: 24px;
    margin-top: -18px;
  }

  @media (max-width: 700px) {
    margin: 12px 4px 12px 4px;
  }

  h4 {
    font-weight: bold;
    color: var(--color-text-dark);
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .criar-classificao {
    margin-bottom: 24px;
  }

  .editor-enunciado {
    max-width: 100%;
    height: 380px;
  }

  .fontes-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 14px;
      display: flex;
      min-width: 220px;
      flex-direction: column;

      label b {
        text-transform: none;
      }

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      .error {
        color: #db2269;
        font-size: 0.625em;
        position: relative;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;

export const RespostaEscolhaContent = styled.div`
  display: flex;
  flex-direction: column;

  .new-alternativa {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 4px;
    margin-bottom: 4px;

    .options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .add-alternativa {
        margin-left: 14px;
      }
    }
  }

  .colunas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 700px) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .relacional-options {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 24px;

    .coluna {
      margin-right: 24px;
    }
  }

  .resposta {
    max-width: 200px;
    margin-bottom: 24px;
  }

  .editor-enunciado {
    width: 100%;
    margin-bottom: 24px;
  }

  h4 {
    font-weight: 500;
    color: var(--color-text-dark);
    padding: 12px 0px 12px 0px;
  }
`;

export const ApoioContent = styled.div`
  display: flex;
  flex-direction: column;

  .new-apoio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }

  .editor-enunciado {
    width: 100%;
    margin-bottom: 24px;
  }

  h4 {
    font-weight: 500;
    color: var(--color-text-dark);
    padding: 12px 0px 12px 0px;
    font-weight: bold;
  }
`;

export const ProvaTitulo = styled.div`
  margin-right: 18px;

  .prova-tag {
    padding: 12px;
  }
`;
