import styled from "styled-components";

export const MainList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;

  section {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;

export const HeaderList = styled.tr`
  padding: 8px 20px 12px 20px;
  background-color: var(--color-content-light);
  border-radius: 12px;

  .header-item {
    color: var(--color-text-grey);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: start;

    :last-child {
      text-align: right !important;
    }
  }
`;

export const ListTableRow = styled.tr`
  background-color: var(--color-content-light);
  border-radius: 12px;

  .body-item {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 1rem;
    text-align: start;

    :last-child {
      text-align: right !important;
    }
  }
`;

export const ListResponsibleContent = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 28px;
  padding-bottom: 48px;

  table {
    width: 100%;
    text-indent: initial;
    border-collapse: separate;
    border-spacing: 0 0.4rem;

    td {
      background-color: var(--color-content-light);
      padding: 1.4rem 1rem 1rem 1.4rem;
      cursor: pointer;

      :first-child {
        border-left-width: 1px;
        border-radius: 5px 0 0 5px;
      }

      :last-child {
        border-right-width: 1px;
        border-radius: 0 5px 5px 0;
      }
    }

    th {
      padding: 1.2rem;
    }
  }
`;

export const ListCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PaginationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 48px;
  max-height: 100px;
`;
