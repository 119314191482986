import { CheckBox, ItemDataSelect, ListTableKit, SelectedDataActions } from "jusadmin-ui-kit";
import { ListTableRow } from 'jusadmin-ui-kit/lib/esm/components/page/structure/ListComponent/styles';
import { QuestaoInfo } from "../../../../../services/api-services/questao";

const { RowItem } = ListTableKit;


export const QuestaoInfoItem = () => {

    const render = (
        elem: ItemDataSelect<QuestaoInfo>,
        index: number
    ) => {

        const entity = elem.entity;

        return (
            <ListTableRow key={index}>
                <RowItem>

                </RowItem>
                <RowItem
                    onClick={() => {
                        window.open(`/questao/${entity.id}`, "_blank");
                    }}
                >
                    {entity.id}
                </RowItem>
                <RowItem>
                    {entity.cod}
                </RowItem>
                <RowItem>
                    {entity.statement_question}
                </RowItem>
            </ListTableRow>
        );
    };

    return {
        render: render,
    };
};
