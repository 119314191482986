import React, { useEffect, useState } from "react";
import { useDebounce as debounce } from "use-debounce";
import { Select } from "..";
import {
  ItemSelect,
  ItemsSelectPage
} from "../../page/structure/FilterComponent/types";
import { SubMenuItem } from "../styles";

export interface TAsyncSelect {
  label?: string;
  dependency?: boolean;
  secondaryValue?: ItemSelect;
  selectItem?: ItemSelect;
  className: string;
  showSelectItem?: boolean;
  getDataAPI?: (page: number, value: string) => Promise<ItemsSelectPage>;
  getDataAPIParam?: (
    page: number,
    value: string,
    param: string
  ) => Promise<ItemsSelectPage>;
  onAddFilterItem?: (item: ItemSelect) => void;
  onLastItem?: (item: ItemSelect) => void;
}

export const AsyncSelect: React.FC<TAsyncSelect> = (props) => {
  const INITIAL_STATE = {
    id: 0,
    key: "select",
    useId: false,
    value: "",
  };

  const [open, setOpen] = useState(false);

  //estado titulo do select

  const [title, setTitle] = useState(props.label);

  const [items, setItems] = useState<Array<ItemSelect>>([]);

  const [text, setText] = useState("");

  const searchClearState = useState(false);

  const setClearSearch = searchClearState[1];

  const [maxPage, setMaxPage] = useState(0);

  const [pageNumber, setPageNumber] = useState(0);

  const [debouncedPage] = debounce(pageNumber, 1000);

  const [lastItem, setLastItem] = useState<ItemSelect>(INITIAL_STATE);

  const [lastSecondary, setLastSecondary] = useState<ItemSelect>(INITIAL_STATE);

  const [secondaryItemAPI, setSecondaryItemAPI] = useState(INITIAL_STATE);

  const [itemAPI, setItemAPI] = useState(INITIAL_STATE);

  useEffect(() => {
    if (props.dependency) {
      if (secondaryItemAPI.value !== "") {
        props.getDataAPIParam &&
          props.getDataAPIParam(0, text, secondaryItemAPI.value).then((res) => {
            let items = res.content;

            if (props.showSelectItem) {
              const labelItem: ItemSelect = {
                id: -2,
                key: "first",
                useId: false,
                value: props.label ? props.label : "Selecione opção",
              };

              items = [labelItem, ...items];
            }

            setItems(items);
            setMaxPage(res.total_number_pages);
            setPageNumber(0);

            if (items.length > 0 && lastSecondary.value !== "") {
              setLastItem(INITIAL_STATE);
            }
          });
      }
    }
  }, [secondaryItemAPI]);

  useEffect(() => {
    if (props.dependency) {
      if (secondaryItemAPI.value !== "") {
        props.getDataAPIParam &&
          props
            .getDataAPIParam(debouncedPage, text, secondaryItemAPI.value)
            .then((res) => {
              setItems([...items, ...res.content]);
              setMaxPage(res.total_number_pages);
            });
      }
    }
  }, [debouncedPage]);

  useEffect(() => {
    if (props.dependency) {
      if (secondaryItemAPI.value !== "") {
        props.getDataAPIParam &&
          props.getDataAPIParam(0, text, secondaryItemAPI.value).then((res) => {
            let items = res.content;

            if (props.showSelectItem) {
              const labelItem: ItemSelect = {
                id: -2,
                key: "first",
                useId: false,
                value: props.label ? props.label : "Selecione opção",
              };

              items = [labelItem, ...items];
            }
            setItems(items);
            setMaxPage(res.total_number_pages);
            setPageNumber(0);
          });
      }
    }
  }, [text]);
  //
  //
  useEffect(() => {
    if (!props.dependency) {
      if (open) {
        props.getDataAPI &&
          props.getDataAPI(debouncedPage, text).then((res) => {
            setItems([...items, ...res.content]);
            setMaxPage(res.total_number_pages);
          });
      }
    }
  }, [debouncedPage]);

  useEffect(() => {
    if (!props.dependency) {
      if (open) {
        props.getDataAPI &&
          props.getDataAPI(0, text).then((res) => {
            let items = res.content;

            if (props.showSelectItem) {
              const labelItem: ItemSelect = {
                id: -2,
                key: "first",
                useId: false,
                value: props.label ? props.label : "Selecione opção",
              };

              items = [labelItem, ...items];
            }
            setItems(items);
            setMaxPage(res.total_number_pages);
            setPageNumber(0);
          });
      }
    }
  }, [open]);

  useEffect(() => {
    if (!props.dependency) {
      if (open) {
        props.getDataAPI &&
          props.getDataAPI(0, text).then((res) => {
            let items = res.content;

            if (props.showSelectItem) {
              const labelItem: ItemSelect = {
                id: -2,
                key: "first",
                useId: false,
                value: props.label ? props.label : "Selecione opção",
              };

              items = [labelItem, ...items];
            }
            setItems(items);
            setMaxPage(res.total_number_pages);
            setPageNumber(0);
          });
      }
    }
  }, [text]);

  //////////

  useEffect(() => {
    if (props.selectItem) {
      if (props.selectItem.id !== -1) {
        if (props.selectItem.id !== itemAPI.id) {
          setItemAPI(props.selectItem);
        }
      }
    }
  }, [props.selectItem]);

  useEffect(() => {
    if (props.secondaryValue) {
      if (props.secondaryValue !== secondaryItemAPI) {
        setLastSecondary(secondaryItemAPI);
        setSecondaryItemAPI(props.secondaryValue);
      }
    }
  }, [props.secondaryValue]);

  useEffect(() => {
    if (itemAPI !== INITIAL_STATE) {
      if (itemAPI.value) {
        handleselectItem(itemAPI);
      }
    }
  }, [itemAPI]);

  const getTitle = () => {
    let title: string | undefined = "";

    if (props.showSelectItem) {
      if (lastItem.value === "") {
        title = props.label;
      } else {
        title = lastItem.value;
      }
    } else {
      title = props.label;
    }

    return title;
  };

  function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
    const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;

    const bottomBorder = (scrollHeight - clientHeight) / 2;

    if (bottomBorder > scrollTop) {
      if (debouncedPage <= maxPage) {
        setPageNumber(debouncedPage + 1);
      }
    }
  }

  function handleselectItem(selected: ItemSelect) {
    const item = selected;
    setTitle(item.value);
    setLastItem(item);
    setClearSearch(true);

    if (item.key !== "first" && item.id !== -2) {
      props.onAddFilterItem && props.onAddFilterItem(item);
    } else {
      props.onAddFilterItem &&
        props.onAddFilterItem({
          id: 0,
          key: "",
          useId: false,
          value: "",
        });
    }
  }

  return (
    <Select
      onOpenSelect={(open) => setOpen(open)}
      minWidth={0}
      onScrollEvent={handleScroll}
      title={getTitle()}
      input={{
        valueInput: (value) => {
          setText(value);
          setClearSearch(false);
        },
      }}
      clearState={searchClearState}
      className={props.className}
    >
      {items.map((item: ItemSelect, index) => (
        <SubMenuItem key={index} onClick={() => handleselectItem(item)}>
          <h5>{item.value}</h5>
        </SubMenuItem>
      ))}
    </Select>
  );
};
