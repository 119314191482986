import styled from 'styled-components';

interface ButtonGroupSettings {
  active: boolean;
}

export const MainOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

export const ButtonGroupContent = styled.button`
  display: flex;
  width: 100%;
  min-height: 80px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 18px;
  margin: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
  border: 2px solid var(--color-primary-light-clear);

  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 1.2rem;
    padding: 4px;
  }

  .icon {
    margin-right: 12px;
  }


  ${(option: ButtonGroupSettings) =>
    option.active
      ? `
        background-color: var(--color-primary-light-clear);
        color: var(--color-primary-darking);

        .text{
            color: var(--color-text-grey);
            font-size: 0.8rem;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
        }

        :hover {
          background-color: var(--color-primary-light-clear);
        }
        `
      : `
          background: none;
          color: var(--color-primary-dark);

        .text{
            color: var(--color-text-grey);
            font-size: 0.8rem;
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
        }

        :hover {
          color: var(--color-primary-darking);
        }
      `}
`;
