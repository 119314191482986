import React from "react";
import { CountTagContent } from "./styles";

export interface TCountTag {
  count: number;
  title: string;
  classname?: string;
}

export const CountTag: React.FC<TCountTag> = (props) => {
  return (
    <CountTagContent className={props.classname}>
      <div className="count">{props.count}</div>
      <div>
        <span>{props.title}</span>
      </div>
    </CountTagContent>
  );
};
