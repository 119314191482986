import React, { FormEvent, Fragment, useEffect, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { VscSave } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { ButtonNormal } from "../../../../components/button/normal";
import { InputForm } from "../../../../components/input";
import { SidebarModal } from "../../../../components/modals/sidebar-modal";
import { SidebarForm } from "../../../../components/modals/sidebar-modal/styles";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch } from "../../../../services/api";
import {
  BancaService,
  useBancaService,
} from "../../../../services/api-services/banca";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { FormCriarBanca } from "./styles";

export interface TCriarBanca {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  isModal: boolean;
}

export const CriarBanca: React.FC<TCriarBanca> = ({
  externalAction,
  isModal,
}) => {
  const auth = useAuthContext();

  const services = {
    bancaService: useBancaService(APIFetch(auth)),
  };

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [action, setAction] = actionState;

  const [nome, setNome] = useState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (action.action == "editar-banca") {
      editarBancaAPI(
        nome,
        services.bancaService,
        action,
        setAction,
        changeAction
      );
    } else if (action.action == "criar-banca") {
      salvarBancaAPI(
        nome,
        services.bancaService,
        action,
        setAction,
        changeAction
      );
    }
  };

  const editarBancaAPI = (
    nomeBanca: string,
    bancaService: BancaService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    bancaService
      .editarBanca({
        id: action.id,
        name: nomeBanca,
      })
      .then((response) => {
        const dispatchAction = {
          ...action,
          loading: true,
        };

        if (!setAction) {
          changeAction(dispatchAction);
        } else {
          setAction(dispatchAction);
        }

        alert("Banca salva com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const salvarBancaAPI = (
    nomeBanca: string,
    bancaService: BancaService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    bancaService
      .salvarBanca({
        name: nomeBanca,
      })
      .then((response) => {
        const dispatchAction = {
          ...action,
          loading: true,
        };

        if (!setAction) {
          changeAction(dispatchAction);
        } else {
          setAction(dispatchAction);
        }

        alert("Banca salva com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {
    action.action == "editar-banca" &&
      services.bancaService.buscarBanca(action.id).then((banca) => {
        setNome(banca.name);
      });
  }, []);

  const renderContent = (isModal: boolean) => {
    return (
      <Fragment>
        <label>Nome</label>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        {isModal ? (
          <ButtonNormal
            icon={VscSave}
            title={"Salvar"}
            className={"save"}
            type={"submit"}
          />
        ) : (
          <ButtonBottomLess
            icon={IoMdCheckmark}
            title={"Salvar banca"}
            className={"save"}
            type={"submit"}
          />
        )}
      </Fragment>
    );
  };

  const renderForm = (isModal: boolean) => {
    return (
      <Fragment>
        {isModal ? (
          <SidebarForm onSubmit={onSubmit} name="criar-banca-api">
            {renderContent(isModal)}
          </SidebarForm>
        ) : (
          <FormCriarBanca name="criar-banca-api" onSubmit={onSubmit}>
            {renderContent(isModal)}
          </FormCriarBanca>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isModal ? (
        <SidebarModal
          action={actionState}
          title={
            action.action == "criar-banca"
              ? "Nova banca"
              : `Editar banca #${action.id}`
          }
        >
          {renderForm(isModal)}
        </SidebarModal>
      ) : (
        <Fragment> {renderForm(isModal)} </Fragment>
      )}
    </Fragment>
  );
};
