import { TAuthContext } from "../../services/auth-services/auth/contextAuth";

export const isAuthenticated = (auth: TAuthContext) => {
    return auth.authenticated;
};

export const isAuthorized = (auth: TAuthContext, roles: String[], permissions: String[]) => {
    let authorizedRole = false;
    let authorizedPermission = false;

    auth.user.roles.forEach((role) => {
        if (roles.includes(role)) {
            authorizedRole = true;
        }
    });

    if (!authorizedRole) {
        auth.user.roles.forEach((role) => {
            if (permissions.includes(role)) {
                authorizedPermission = true;
            }
        });
    }

    return authorizedRole || authorizedPermission;
};