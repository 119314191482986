import styled from "styled-components";

export interface CardSettings {
  width: string;
}

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  margin-bottom: 12px;
  margin-top: 12px;
  background-color: var(--color-content-light);
  border: 2px solid var(--color-primary-light-clear);
  box-shadow: 4px 0 5px rgb(0 0 0 / 8%);
  border-radius: 12px;
  ${(settings: CardSettings) =>
    settings.width
      ? `
  width: ${settings.width};
  `
      : ``}

  .header-card-view {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .header-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .header-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-select-border);
    width: 100%;

    .header-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .header-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .list-options{
      margin-bottom: 12px;
    }

    .clear-filters {
      margin-right: 8px;
      height: 42px;
    }
  }

  .body-card {
    display: flex;
    flex-direction: row;
  }

  .footer-card {
    display: flex;
    flex-direction: row;
  }
`;

export const PaginationOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary-clear);
  color: var(--color-text-secondary);
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 4px;
  border-radius: 8px;
`;
