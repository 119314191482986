import { AxiosInstance } from "axios";
import { Service } from "../../service";
import { ProjetoDetails } from "../projeto";
import { Tag } from "../tag";
import { PaginationData } from "./../../../components/pagination/types";
import { Disciplina } from "./../disciplina/index";
import { Prova } from "./../prova/index";
import { Tema } from "./../tema/index";
import { TipoLei } from "./../tipoLeis/index";

export class QuestaoService extends Service {
  private ENDPOINT = "question";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarQuestaosPorPagina(
    page: number,
    mode: string,
    qtd: string,
    validation: string,
    period: string,
    porUsuario: string,
    incluirComentarios: string,
    filters: string[],
    search: string,
    search_content: string,
    sort: string,
    order: string,
    prova?: number
  ): Promise<QuestaosPagination> {
    const query = `?search=search_key:'*${search}*';search_content:'${search_content}';validation:'${validation}';status:'${
      filters[0]
    }';type:'${filters[1]}';difficulty:'${filters[2]}';nature:'${
      filters[3]
    }';exam:'${prova ? prova : filters[4]}';subject:'${filters[5]}';topicIds:'${
      filters[6]
    }';source:'${filters[7]}';authorName:'${filters[8]}';profileName:'${
      filters[9]
    };classification:'${filters[10]}';tagsIds:'${
      filters[11]
    }';period:'${period}';targets:'${porUsuario}';includeComments:'${incluirComentarios};legalReferences:'*${
      filters[12]
    }*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}&mode=${mode}`;

    return this.fetch.get(this.ENDPOINT + query).then((response) => {
      let questoes = new Array<QuestaoResumida>();
      let pagination = {
        current_page: 0,
        total_items: 0,
        total_number_pages: 1,
      };

      questoes = response.data.content;
      pagination = {
        total_items: response.data.total_items,
        current_page: response.data.current_page,
        total_number_pages: response.data.total_number_pages,
      };

      return {
        content: questoes,
        paginationData: pagination,
      };
    });
  }

  async buscarQuestao(id: number): Promise<QuestaoDetails> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async excluirQuestao(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }

  async adicionarProva(
    questions: Array<number>,
    idProva: number
  ): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/exam/${idProva}`, questions);
  }

  async classificar(
    questions: Array<number>,
    ativarDiscProva?: boolean,
    ativarElegibilidadeClassificacao?: boolean,
    idTema?: number,
    idDisciplina?: number,
    idTag?: number,
    discProva?: string
  ): Promise<void> {
    const classificacao = {
      classification: {
        selected_topic_id: idTema,
        selected_subject_id: idDisciplina,
        selected_tag_id: idTag,
        eligibility_classification: ativarElegibilidadeClassificacao,
        exam_discipline: discProva,
        exam_disc_active: ativarDiscProva,
      },
      questions: questions,
    };

    return this.fetch.patch(this.ENDPOINT + `/classification`, classificacao);
  }

  async alterarStatusValidacao(
    questions: Array<number>,
    status: String
  ): Promise<void> {
    const validacao = {
      status: status,
      questions: questions,
    };

    return this.fetch.patch(this.ENDPOINT + `/approval`, validacao);
  }

  async atribuirTipoResposta(
    idQuestao: number,
    tipoResposta: String
  ): Promise<void> {
    const request = {
      type: tipoResposta,
    };

    return this.fetch.post(
      this.ENDPOINT + `/${idQuestao}/tipoResposta`,
      request
    );
  }

  async salvarQuestao(questao: QuestaoRequest): Promise<QuestaoResumida> {
    return this.fetch
      .post(this.ENDPOINT, questao, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async salvarQuestaoNaProva(
    questao: QuestaoRequest,
    prova: number
  ): Promise<QuestaoResumida> {
    return this.fetch
      .post(this.ENDPOINT + `/exam/${prova}`, questao, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarQuestao(questao: QuestaoRequest): Promise<void> {
    return this.fetch.put(this.ENDPOINT + `/${questao.id}`, questao, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async salvarResposta(
    idQuestao: number,
    resposta: RespostaRequest
  ): Promise<RespostaDetails> {
    return this.fetch
      .post(this.ENDPOINT + `/${idQuestao}/answer`, resposta, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarResposta(
    idQuestao: number,
    resposta: RespostaRequest,
    idResposta: number
  ): Promise<void> {
    return this.fetch.put(
      this.ENDPOINT + `/${idQuestao}/answer/${idResposta}`,
      resposta,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async excluirResposta(idResposta: number, idQuestao: number): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idQuestao}/answer/${idResposta}`
    );
  }

  async salvarTexto(
    idQuestao: number,
    apoio: ApoioRequest
  ): Promise<ApoioTexto> {
    return this.fetch
      .post(this.ENDPOINT + `/${idQuestao}/support`, apoio, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarTexto(
    idQuestao: number,
    apoio: ApoioRequest,
    idApoio: number
  ): Promise<void> {
    return this.fetch.put(
      this.ENDPOINT + `/${idQuestao}/support/${idApoio}`,
      apoio,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async excluirTexto(idApoio: number, idQuestao: number): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idQuestao}/support/${idApoio}`
    );
  }

  async listarProjetosVinculadosQuestao(
    idQuestao: number
  ): Promise<Array<ProjetoDetails>> {
    return this.fetch
      .get(this.ENDPOINT + `/${idQuestao}/project`)
      .then((response) => {
        return response.data;
      });
  }

  async importarQuestoes(dados: FormData): Promise<Array<QuestaoResumida>> {
    return this.fetch
      .post(this.ENDPOINT + `/import`, dados)
      .then((response) => {
        return response.data;
      });
  }

  async salvarReferenciaLegal(
    idQuestao: number,
    referenciaLegal: ReferenciaLegalRequest
  ): Promise<ReferenciasLegaisDetails> {
    return this.fetch
      .post(this.ENDPOINT + `/${idQuestao}/legal-references`, referenciaLegal, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarReferenciaLegal(
    idQuestao: number,
    referenciaLegal: ReferenciaLegalRequest,
    idReferenciaLegal: number
  ): Promise<void> {
    return this.fetch.put(
      this.ENDPOINT + `/${idQuestao}/legal-references/${idReferenciaLegal}`,
      referenciaLegal,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async excluirReferenciaLegal(
    idReferenciaLegal: number,
    idQuestao: number
  ): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idQuestao}/legal-references/${idReferenciaLegal}`
    );
  }

  async excluirQuestaoEmLote(ids: Set<Number>): Promise<Set<QuestaoInfo>> {
    return this.fetch
      .delete(this.ENDPOINT, {
        data: Array.from(ids),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export const useQuestaoService = (axios: AxiosInstance) => {
  return new QuestaoService(axios);
};

export interface Questao {
  id: number;
  cod: string;
  type: string;
  nature: string;
  created_by: string;
  topic: string;
  tag: string;
  subject: string;
  difficulty: string;
  status_question: string;
  validation: string;
  eligibility_classification: boolean;
  sources: string[];
  legal_references: Array<ReferenciasLegaisDetails>;
  exam_discipline: string;
  exam_disc_active: boolean;
}

export interface QuestaoResumida extends Questao {
  statement_question: string;
  exam_board: string;
  office: string;
  body: string;
  career: string;
  year: string;
}

export function isQuestaoItem(
  questao: QuestaoItem | any
): questao is QuestaoItem {
  if ((questao as QuestaoItem).exam_header !== undefined) {
    return true;
  }

  return false;
}

export interface QuestaoItem extends Questao {
  exam_header: string;
  career: string;
  statement_question: string;
  qtd_comments: number;
  qtd_answers: number;
  qtd_supports: number;
}

export type QuestaosPagination = {
  content: Array<Questao>;
  paginationData: PaginationData;
};

export interface QuestaoDetails {
  id: number;
  cod: string;
  type: string;
  statement_question: string;
  sources: string[];
  legal_references: ReferenciasLegaisDetails[];
  nature: string;
  answer_type: string;
  exam_header: Prova;
  difficulty: string;
  status: string;
  validation: string;
  topic: Tema;
  tag: Tag;
  correct: string;
  subject: Disciplina;
  answers: Map<string, Array<RespostaDetails>>;
  supports: Array<ApoioTexto>;
  exam_discipline: string;
  exam_disc_active: boolean;
}

export interface ReferenciasLegaisDetails {
  id: number;
  law: string;
  article?: string;
  paragrafo?: string;
  inciso?: string;
  alinea?: string;
  item?: string;
  law_type: TipoLei;
}

export interface RespostaDetails {
  id: number;
  answer: string;
  points: string;
  column: string;
  anwser_correct: string;
  related_column: number;
}

export interface ApoioTexto {
  id: number;
  sequence_id: number;
  support_text: string;
}

//////////////////////////////////////////////////////////////

export interface RespostaRequest {
  id?: number;
  type: string;
  answer: string;
}

export interface RespostaRelacionalRequest extends RespostaRequest {
  selected_column: string;
  related_column?: number;
}

export interface RespostaPadraoRequest extends RespostaRequest {
  points: number;
}

export interface RespostaEscolhaRequest extends RespostaRequest {
  answer_correct: string;
}

//////////////////////////////////////

export interface ApoioRequest {
  id?: number;
  type: string;
  sequence_id: number;
}

export interface ApoioTextoRequest extends ApoioRequest {
  support_text: string;
}
//////////////////////////////////////////////

export interface ReferenciaLegalRequest {
  id?: number;
  selected_law_type_id: number;
  law: string;
  article?: string;
  paragrafo?: string;
  inciso?: string;
  alinea?: string;
  item?: string;
}

//////////////////////////////////////
export interface QuestaoInfo {
  id: number;
  cod: string;
  statement_question: string;
}

//////////////////////////////////////
export interface QuestaoRequest {
  id?: number;
  type: string;
  selected_answer_type: string;
  selected_type: string;
  selected_nature: string;
  selected_difficulty?: string;
  selected_status: string;
  sources?: string[];
  legal_references: Array<ReferenciaLegalRequest>;
  correct?: string;
  classification?: {
    selected_topic_id: number;
    selected_subject_id: number;
    selected_tag_id?: number;
  };
  statement_question: string;
  answers: any;
  supports: Array<ApoioTextoRequest>;
  exam_discipline: string;
  exam_disc_active: boolean;
}

export interface InfoJuridicaRequest {
  law?: string;
  article?: string;
  selected_law_type_id?: number;
}

export function questaoIsClassificada(questao: Questao) {
  let test: boolean = true;

  if (!questao.topic || questao.topic === "N/A") {
    test = false;
  }

  if (!questao.subject || questao.subject === "N/A") {
    test = false;
  }

  return test;
}
