import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDebounce as debounce } from "use-debounce";
import useOnClickOutside from "../util/ClickOutside";
import { MainSelect, SelectHeader, SubMenuContent } from "./styles";

export type TSelect = {
  className: string;
  minWidth: number;
  title?: string;
  input?: Input;
  valueInput?: string;
  clearState?: [boolean, Dispatch<SetStateAction<boolean>>];
  onScrollEvent?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onOpenSelect: (open: boolean) => void;
};

export type Input = {
  valueInput?: (value: string) => void;
};

export const Select: React.FC<TSelect> = ({
  title,
  className,
  onScrollEvent,
  children,
  input,
  minWidth,
  clearState,
  onOpenSelect,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [debouncedText] = debounce(value, 500);
  const setClearSearch = clearState?.[1];
  const clearSearch = clearState?.[0];

  function open() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  useEffect(() => {
    onOpenSelect(isOpen);
  }, [open]);

  useEffect(() => {
    if (clearSearch) {
      setValue("");
      setClearSearch && setClearSearch(false);
    }
  }, [clearSearch]);

  useEffect(() => {
    input?.valueInput && input.valueInput(debouncedText);
  }, [debouncedText]);

  useOnClickOutside(divRef, () => {
    setIsOpen(false);
    setValue("");
    setClearSearch && setClearSearch(false);
  });

  return (
    <MainSelect ref={divRef} onClick={open} className={className}>
      <SelectHeader minWidth={minWidth} isOpen={isOpen}>
        {input ? (
          <div>
            <input
              className="select-input"
              type="text"
              value={value}
              placeholder={title}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>
        ) : (
          <div>{title}</div>
        )}
        <IoIosArrowDown size={18} className={"select-icon-open"} />
      </SelectHeader>
      {isOpen && (
        <SubMenuContent onScroll={onScrollEvent}>{children}</SubMenuContent>
      )}
    </MainSelect>
  );
};
