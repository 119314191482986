import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { DropZoneData } from "../dropzone";
import {
  ChildrenElement,
  ItemTypes,
  LayoutDragFeatures
} from "../hooks/useLayoutDragSystem";
import { SubElementoContent } from "../subelement/styles";

export interface TSubElement<T> {
  dragAndDropName: string;
  data: ChildrenElement<T>;
  path: string;
  features: LayoutDragFeatures<T>;
  renderContent?: (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<T>,
    zone: DropZoneData<T>,
    data?: T
  ) => React.ReactNode;
}

export const ChildrenElementComponent = <T extends unknown>({
  data,
  path,
  renderContent,
  features,
  dragAndDropName
}: TSubElement<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const zone: DropZoneData<T> = {
    data: data,
    path: path,
    type: ItemTypes.CHILDREN,
    dragAndDropName: dragAndDropName
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CHILDREN,
    item: zone,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (path !== data.path) {
      features.setPath(path);
    }
  }, [path]);

  const isRoot = () => {
    const pathStr = path.split("-");

    if (pathStr.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  drag(ref);

  return (
    <SubElementoContent ref={ref} isDragging={isDragging} isRoot={isRoot()}>
      <div className={"content"}>
        {renderContent
          ? renderContent(ref, features, zone, data.data)
          : data.name}
      </div>
    </SubElementoContent>
  );
};
