import { Fragment } from "react";
import { Link } from "react-router-dom";
import { CardItem } from "../../../../../components/page/structure/ListComponent/list-card/model";
import { Tag } from "../../../../../components/tag";
import { ProjetoDetails } from "../../../../../services/api-services/projeto";
import { Prova } from "../../../../../services/api-services/prova";
import {
  Questao,
  QuestaoDetails
} from "../../../../../services/api-services/questao";
import { clearStringURL } from "../../../../../utils/string";
import { Classificacao, CodigoID, Status, Validation } from "../../../elements";
import { BodyStyle, FooterStyle, HeaderStyle } from "../styles";

export interface TEnunciado {
  questao: QuestaoDetails;
  projetos: Array<ProjetoDetails>;
}

export const Enunciado: React.FC<TEnunciado> = ({ questao, projetos }) => {
  return (
    <Fragment>
      <CardItem
        header={<HeaderContent questao={questao} />}
        body={<BodyContent questao={questao} projetos={projetos} />}
        options={[]}
        footer={<FooterContent questao={questao} />}
      />
    </Fragment>
  );
};

const HeaderContent: React.FC<{
  questao?: QuestaoDetails;
}> = ({ questao }) => {
  return (
    <HeaderStyle>
      <CodigoID
        codigo={questao ? questao.cod : ""}
        id={questao ? questao.id : 0}
        classname={"item-margin"}
      />
      <Status
        status={questao ? questao.status : ""}
        classname={"item-margin"}
      />
      <Validation
        value={questao ? questao.validation : ""}
        mode={"card"}
        classname={"item-margin"}
      />
      <Classificacao
        questao={questao as unknown as Questao}
        classname={"item-margin"}
      />
    </HeaderStyle>
  );
};

const BodyContent: React.FC<{
  questao: QuestaoDetails;
  projetos: Array<ProjetoDetails>;
}> = ({ questao, projetos }) => {

  const {
    subject,
    topic,
    type,
    difficulty,
    statement_question,
    exam_header,
    tag,
    sources
  } = questao;


  return (
    <Fragment>
      {questao && (
        <BodyStyle className={"item-margin"}>
          <span className={"exam-header"}>{getHeaderProva(exam_header)}</span>
          <div className="statement_question">
            <div
              className={"ql-editor"}
              dangerouslySetInnerHTML={{
                __html: statement_question,
              }}
            />
          </div>
          <div className="tags">
            {questao.exam_discipline && <Tag nome={questao.exam_discipline} title="Disciplina (PROVA)" />}
            {subject && <Tag nome={subject.name} title={"Disciplina"} />}
            {topic && <Tag nome={topic.name} title={"Tema"} />}
            {tag && <Tag nome={tag.name} title={"TAG"} />}
            {sources && <Tag nome={sources.join(";")} title={"Fonte"} />}
            {type && <Tag nome={type} title={"Tipo de questão"} />}
            {difficulty && <Tag nome={difficulty} title={"Dificuldade"} />}
          </div>
          <div className="projetos">
            Projetos:
            {projetos.map((projeto) => {
              return (
                <Link
                  className="projeto-link"
                  to={`/projeto-questoes/${projeto.id}/${clearStringURL(
                    projeto.name
                  )} ${projeto.year}`}
                >{`${projeto.name}`}</Link>
              );
            })}
          </div>
        </BodyStyle>
      )}
    </Fragment>
  );
};

const FooterContent: React.FC<{ questao?: QuestaoDetails }> = (props) => {
  return <FooterStyle className={"item-margin"}></FooterStyle>;
};

export const getHeaderProva = (prova: Prova) => {

  if (prova) {
    return prova.exam_name;
  } else {
    return "Sem prova anexada";
  }

};

export const getProvaInfoHeader = (
  exam_board: string,
  office: string,
  level: string,
  body: string,
  year: string
) => {
  let header = "";

  const provaObj: Array<string> = [
    exam_board ? exam_board : "",
    office ? office : "",
    level ? level : "",
    body ? body : "",
    year ? year + "" : "",
  ];

  for (var i = 0; i < provaObj.length; i++) {
    let s = provaObj[i];

    if (s) {
      if (i === 0) {
        header = s;
      } else {
        header = header + " - " + s;
      }
    }
  }

  return header;
};
