import React from "react";
import { IconBaseProps } from "react-icons";
import { TitleIconContent } from "./styles";

interface TTitleIcon {
  icon?: React.ComponentType<IconBaseProps>;
  extra?: React.ReactNode;
  name: string;
  classname?: string;
}

export const TitleIcon: React.FC<TTitleIcon> = (props) => {
  const { icon: Icon, name, classname, extra } = props;

  return (
    <TitleIconContent className={classname}>
      <div>
        {Icon && <Icon size={22} className={"icon"} />}
        <h3>{name}</h3>
      </div>
      <div>{extra && extra}</div>
    </TitleIconContent>
  );
};
