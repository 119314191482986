import {
    ButtonBottomLess,
    ItemDataSelect,
    ListTableKit,
    useSelectedDataAction,
} from "jusadmin-ui-kit";
import { ListTableRow } from "jusadmin-ui-kit/lib/esm/components/page/structure/ListComponent/styles";
import { useState } from "react";
import { BiMerge } from "react-icons/bi";
import { Modal } from "../../modals/modal-base";
import { useGerenciarContext } from "../../page/GerenciarPageBase/context";
import { ModalAuthRoute } from "../../router/ModalAuthRoute";
import { SubMenuTitle } from "../../sub-menu-title";
import { Tag } from "../../tag";
import { MesclarEntidadeModalButton, MesclarEntidadesContent, MesclarEntidadesStyles } from "./styles";

const { ListTableComponent } = ListTableKit;
const { RowItem } = ListTableKit;

export interface MesclagemResponse {
    updated_references: number;
}

export interface MesclarEntidadesProps {
    permissions: string[];
    onMesclarEntidadesAPI: (idDestino: number, ids: Set<number>) => Promise<MesclagemResponse>
    tituloUI: string;
    onFinishMesclagemEvent: () => void;

}

export interface EntidadeProps {
    id: number;
    nome: string;
}

export interface MesclarEntidadesState {
    entidades: ItemDataSelect<EntidadeProps>[];
    destino: EntidadeProps;
}

export function MesclarEntidades({ permissions, onMesclarEntidadesAPI, tituloUI, onFinishMesclagemEvent }: MesclarEntidadesProps) {

    const context = useGerenciarContext();

    const dataState: MesclarEntidadesState | undefined = context.currentAction
        .object as unknown as MesclarEntidadesState;

    const entidadeState = useState<Array<ItemDataSelect<EntidadeProps>>>(
        dataState ? dataState.entidades : []
    );

    const [entidades, setEntidades] = entidadeState;

    const entidadesHook = useSelectedDataAction<EntidadeProps>({
        state: entidadeState,
    });

    const onClickMesclarEntidades = () => {

        const selecionados = entidades.map((value) => value.id_item);

        if (dataState.destino && selecionados.length >= 1) {

            onMesclarEntidadesAPI(dataState.destino.id, new Set(selecionados))
                .then((value) => {

                    onFinishMesclagemEvent();

                    context.changeAction({
                        action: "sucesso",
                        id: 0,
                        loading: true,
                        message: `Objetos selecionados foram mesclados e suas (${value.updated_references})referências foram atualizadas com sucesso!`
                    });

                })
                .catch((error) => {

                    context.changeAction({
                        id: 0,
                        action: 'erro',
                        message: error.response.data.message,
                    });

                });

        }
    }

    const footer = () => {
        return (<MesclarEntidadeModalButton>
            <ButtonBottomLess
                className=""
                title="Mesclar objetos"
                icon={BiMerge}
                onClick={onClickMesclarEntidades}
            />
        </MesclarEntidadeModalButton>);
    }

    return (
        <ModalAuthRoute
            handleUnauthorization={() => {
                context.changeAction({
                    id: 0,
                    action: "erro",
                    message: "Você não tem permissão para acessar a funcionalidade!",
                });
            }}
            permissions={permissions}
            roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
        >
            <Modal
                action={context.currentActionState}
                title={tituloUI}
                width={650}
                options={footer()}
            >
                <MesclarEntidadesStyles>
                    <SubMenuTitle
                        title={`Objeto de destino para mesclagem:`}
                        styles={{
                            titleBar: {
                                justifyContent: "center",
                            },
                        }}
                    >
                        {dataState && <Tag nome={dataState.destino.nome} title="Destino" />}
                    </SubMenuTitle>
                    <MesclarEntidadesContent>
                        <SubMenuTitle
                            title={`${entidades.length} objetos selecionados para serem mesclados com o item atual`}
                            styles={{
                                titleBar: {
                                    justifyContent: "center",
                                },
                            }}
                        >
                            <ListTableComponent
                                checkAnyIdIsSelected={entidadesHook.checkAnyIdIsSelected}
                                disablePagination
                                headerElements={[<div>ID</div>, <div>Nome</div>, <div></div>]}
                                className={"main-table"}
                                tableClassName={"list-table"}
                                onCheckAll={entidadesHook.checkAll}
                                dataColumn={() => {
                                    return entidadeState[0].map((elem, index) => {
                                        return EntidadeItemTable().render(elem, index);
                                    });
                                }}
                            />
                        </SubMenuTitle>
                    </MesclarEntidadesContent>
                </MesclarEntidadesStyles>
            </Modal>
        </ModalAuthRoute>
    );
}

export const EntidadeItemTable = () => {
    const render = (elem: ItemDataSelect<EntidadeProps>, index: number) => {
        const entity = elem.entity;

        return (
            <ListTableRow key={index}>
                <RowItem></RowItem>
                <RowItem>{entity.id}</RowItem>
                <RowItem>{entity.nome}</RowItem>
                <RowItem></RowItem>
            </ListTableRow>
        );
    };

    return {
        render: render,
    };
};
