import { Fragment } from "react";
import { ThreeNormalOption } from "../../../../../components/menu/three-normal";
import { CardItem } from "../../../../../components/page/structure/ListComponent/list-card/model";
import { Comentario } from "../../../../../services/api-services/comentarios";
import { ComentarioValidation, LabelValue } from "../../../elements";
import { BodyStyle, FooterStyle, HeaderStyle } from "../styles";

export interface TComentario {
  id: number;
  comentario: Comentario;
  onEditComentario: (idComentario: number, idResposta: number) => void;
}

export const ComentarioItem: React.FC<TComentario> = (props) => {
  //
  const { comentario } = props;

  return (
    <Fragment>
      <CardItem
        settings={{
          width: "98%",
        }}
        header={<HeaderContent comentario={comentario} />}
        body={<BodyContent comentario={comentario} />}
        options={[
          <ThreeNormalOption
            name="Editar"
            onClick={() => {
              props.onEditComentario(comentario.id, comentario.id_answer);
            }}
          />,
        ]}
        footer={<FooterContent />}
      />
    </Fragment>
  );
};

const HeaderContent: React.FC<{
  comentario: Comentario;
}> = ({ comentario }) => {
  return (
    <Fragment>
      <HeaderStyle>
        <LabelValue
          label={"Comentado por"}
          value={
            comentario.author ? comentario.author.name : "Não classificado"
          }
        />
        <LabelValue
          label={"Perfil de questão"}
          value={
            comentario.question_profile ? comentario.question_profile.name : "Não classificado"
          }
        />
        <ComentarioValidation
          value={comentario.validation}
          classname={"item-margin"}
        />
      </HeaderStyle>
    </Fragment>
  );
};

const BodyContent: React.FC<{ comentario: Comentario }> = ({ comentario }) => {
  return (
    <BodyStyle className={"item-margin"}>
      <div className="statement_question">
        <div>
          <div
            className={"ql-editor"}
            dangerouslySetInnerHTML={{
              __html: comentario.comment_content,
            }}
          />
        </div>
      </div>
    </BodyStyle>
  );
};

const FooterContent: React.FC<{}> = (props) => {
  return <FooterStyle className={"item-margin"}></FooterStyle>;
};
