import styled from "styled-components";


export interface CheckCSSOptions {
  selected: boolean
}

export const CheckContent = styled.div`

  user-select: none; 

 .bottom-icon {
    color: var(--color-primary-darking);
  }


  ${(options: CheckCSSOptions) => options.selected ?
    `background-color: var(--color-primary-light-clear);
     padding: 4px;
     border-radius: 5px;
   ` :
    `background-color: transparent`}


`