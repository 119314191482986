import { useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { Editor } from "../../../../components/editor";
import {
  NormalSelect,
  TItemSelect,
} from "../../../../components/select/normal";
import { TitleIcon } from "../../../../components/title-icon";
import { RespostaContent } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/styles";
import { Resposta, useQuestaoContext } from "../../context";
import { RespostaEscolhaContent } from "../../styles";

export interface RespostaObjetiva {
  resposta: Resposta;
  handleEditar: (resposta: Resposta) => void;
  excluirResposta: (resposta: Resposta) => void;
  count: number;
}

interface TCriarRespostaObjetiva {
  alternativa: React.ComponentType<RespostaObjetiva>;
  options: TItemSelect[];
}

export const CriarRespostaObjetiva: React.FC<TCriarRespostaObjetiva> = ({
  alternativa: RespostaObjetiva,
  options,
}) => {
  //
  const questaoContext = useQuestaoContext();

  const [respostaAnterior, setRespostaAnterior] = useState<Resposta>();

  const [edit, setEdit] = useState(false);

  const [enunciado, setEnunciado] = useState("<p><br></p>");

  const [correto, setCorreto] = useState<TItemSelect>({
    id: -1,
    key: "",
    selected: false,
    useId: false,
    value: "",
  });

  const handleResposta = () => {
    if (edit) {
      editarResposta();
    } else {
      criarResposta();
    }
  };

  const clear = () => {
    setEnunciado("<p><br></p>");
    setEdit(false);
  };

  const editarResposta = () => {
    let valid = true;

    if (enunciado === "<p><br></p>" && correto.value === "") {
      valid = false;
      alert("Informe o texto da alternativa e sua resposta!");
    }

    if (enunciado === "<p><br></p>") {
      valid = false;
      alert("Informe o texto da alternativa!");
    }

    if (correto.value === "") {
      valid = false;
      alert("Selecione a resposta!");
    }

    if (valid) {
      if (respostaAnterior) {
        const resposta: Resposta = {
          id: respostaAnterior.id,
          answer: enunciado,
          anwser_correct: correto,
          column: "",
          points: 0,
          related_column: "",
        };

        if (respostaAnterior) {
          questaoContext.editarResposta(
            respostaAnterior,
            resposta,
            "first_column"
          );
          clear();
        }
      }
    }
  };

  const criarResposta = () => {
    let valid = true;

    if (enunciado === "" && correto.value === "") {
      valid = false;
      alert("Informe o texto da alternativa e sua resposta!");
    }

    if (enunciado === "") {
      valid = false;
      alert("Informe o texto da alternativa!");
    }

    if (correto.value === "") {
      valid = false;
      alert("Selecione a resposta!");
    }

    if (valid) {
      const resposta: Resposta = {
        id: "",
        answer: enunciado,
        anwser_correct: correto,
        column: "",
        points: 0,
        related_column: "",
      };

      questaoContext.addResposta(resposta, "first_column");
      clear();
    }
  };

  const excluirResposta = (resposta: Resposta) => {
    questaoContext.removerResposta(resposta, "first_column");
  };

  const handleEditar = (resposta: Resposta) => {
    setRespostaAnterior(resposta);
    setEnunciado(resposta.answer);
    setCorreto(resposta.anwser_correct);
    setEdit(true);
  };

  const respostas = (respostas: Resposta[]) => {
    return (
      <RespostaContent>
        {respostas.map((ele, index) => {
          const count = index + 1;

          return (
            <RespostaObjetiva
              resposta={ele}
              count={count}
              key={index}
              excluirResposta={excluirResposta}
              handleEditar={handleEditar}
            />
          );
        })}
      </RespostaContent>
    );
  };

  return (
    <RespostaEscolhaContent>
      <TitleIcon
        name={edit ? "Editar alternativa" : "Nova alternativa"}
        icon={AiOutlineUnorderedList}
      />
      <NormalSelect
        minWidth={40}
        className="resposta"
        label="Selecione uma resposta"
        selectItem={correto}
        onSelectCurrentItem={(item) => setCorreto(item)}
        selectedItems={options}
      />
      <Editor
        referencia={"resposta"}
        currentText={enunciado}
        classname="editor-enunciado"
        html={(html) => setEnunciado(html)}
        uploadData={questaoContext.imageData}
      />
      <div className="new-alternativa">
        <div />
        <ButtonBottomLess
          className="add-alternativa"
          title={edit ? "Salvar alterações" : "Adicionar"}
          icon={edit ? FiCheck : IoAdd}
          onClick={handleResposta}
        />
      </div>
      {respostas(questaoContext.respostas[0])}
    </RespostaEscolhaContent>
  );
};
