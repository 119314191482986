import { Dispatch, SetStateAction } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { GrTextAlignFull } from 'react-icons/gr';
import { GroupButtons } from '../../../../components/group-buttons';
import { Modal } from '../../../../components/modals/modal-base';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { useQuestaoContext } from '../../context';
import { TipoQuestaoContent } from './styles';

export const TipoQuestao: React.FC<{
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
}> = (props) => {
  const [action, setAction] = props.action;
  const questaoContext = useQuestaoContext();

  return (
    <Modal action={props.action} title={'Escolha um formato de questão'} options={[]}>
      <TipoQuestaoContent>
        <GroupButtons
          initialPositionSelected={2}
          buttons={[
            {
              onClick: (current) => {
                questaoContext.callbacks.setTipo('Dissertativa');
                setAction({
                  id: 0,
                  action: 'questao',
                });
              },
              classname: 'status-option',
              value: 'Dissertativa',
              icon: GrTextAlignFull,
              name: 'Dissertativa',
              minWidth: 200,
            },
            {
              onClick: (current) => {
                questaoContext.callbacks.setTipo('Objetiva');
                setAction({
                  id: 0,
                  action: 'questao',
                });
              },
              classname: 'status-option',
              value: 'Objetiva',
              icon: AiOutlineUnorderedList,
              name: 'Objetiva',
              minWidth: 200,
            },
          ]}
        />
      </TipoQuestaoContent>
    </Modal>
  );
};
