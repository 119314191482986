import { Fragment, useRef } from "react";
import { AiOutlineFileImage } from "react-icons/ai";
import { BsFillFileImageFill } from "react-icons/bs";
import { ButtonBottomLess } from "../../../components/button/bottomless";
import { FilterTag } from "../../../components/filter-tag";
import { TitleIcon } from "../../../components/title-icon";
import { ImageInfoDetails } from "../../../services/service";
import { SelecaoImagemStyle } from "./styles";
export interface SelecaoImagemProps {
    referenciaImagem: string;
    onUploadImage: (formData: FormData, id: string) => Promise<ImageInfoDetails>;
    onDeleteImage: (idImagem: string, id: string) => Promise<void>;
    idEntidade: string;
    imagemProjeto: ImageInfoDetails | undefined;
    onPosUpload: (value?: ImageInfoDetails) => void;
}

export const SelecaoImagem = ({ imagemProjeto, referenciaImagem, idEntidade, onUploadImage, onPosUpload, onDeleteImage }: SelecaoImagemProps) => {

    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadImagemProjeto = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { files } = e.target;

        if (files) {
            const file = files[0];
            const formData = new FormData();

            formData.append("file", file);
            formData.append(
                "info",
                new Blob(
                    [
                        JSON.stringify({
                            reference: referenciaImagem,
                        }),
                    ],
                    { type: "application/json" }
                )
            );

            onUploadImage(formData, idEntidade).then((value) => {
                onPosUpload(value);
                alert("Imagem carregada com sucesso!")
            })
        }
    }

    const deletarImagem = () => {
        if (imagemProjeto) {

            onDeleteImage(imagemProjeto.id, idEntidade).then(() => {
                alert("Imagem deletada com sucesso!")
                onPosUpload(undefined);
            }).catch((error) => {
                alert(error.response.data.message);
            });

        }
    }

    const onSelecionarImagemEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        fileInputRef.current?.click();
    }

    return (
        <SelecaoImagemStyle>
            <TitleIcon name="Seleção de imagem" icon={BsFillFileImageFill} />
            <div className="selecao-imagem form-group">
                {(imagemProjeto === undefined || imagemProjeto === null) &&
                    <Fragment>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={uploadImagemProjeto}
                            ref={fileInputRef}
                        />
                        <ButtonBottomLess
                            title={"Selecionar imagem"}
                            className="importar-button"
                            onClick={onSelecionarImagemEvent}
                            icon={AiOutlineFileImage}
                        />
                        <small className="aviso-file">
                            Selecione uma imagem nos formatos válidos de imagem (png,jpg,jpeg e etc)
                        </small>
                    </Fragment>
                }
                {imagemProjeto &&
                    <div className="nome-arquivo">
                        <FilterTag nome={imagemProjeto.name}
                            option={[
                                <AiOutlineFileImage color={"var(--color-content-light)"} size={24} />
                            ]}
                            className="small-selecao"
                            onMouseClick={deletarImagem}
                        />
                    </div>
                }
            </div>
        </SelecaoImagemStyle>
    )
}