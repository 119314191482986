import styled from "styled-components";

export const TitleIconContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--color-primary-light-clear);
  margin-bottom: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: none;
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .icon {
    margin-right: 18px;
    color: var(--color-primary-light);
  }

  h4,
  h3 {
    font-weight: 600;
    font-size: 1.0rem;
    color: var(--color-primary-dark);
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;
