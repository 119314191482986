import { IconBaseProps } from "react-icons";
import { Link } from "react-router-dom";
import { SidebarSubItem } from "../styles";


export interface SubItemSidebarProps {
    title: string,
    path: string,
    icon: React.ComponentType<IconBaseProps>

}

export const SidebarSubItemOption: React.FC<SubItemSidebarProps> = ({ path, icon: Icon, title }) => {
    return (
        <SidebarSubItem>
            <Link to={path}>
                <div>
                    <Icon
                        size={28}
                        className={"sidebar-menu-item-icone"}
                    />
                    <h5>{title}</h5>
                </div>
            </Link>
        </SidebarSubItem>
    );
}