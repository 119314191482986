import { Fragment } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { TNoAuthorizationRoute } from "../types";

export const NoAuthRouteBase = ({ mainApp: MainApp, props, component, exact, path, }: TNoAuthorizationRoute): JSX.Element => {

    const renderComponent = (routeProps: RouteComponentProps) => {
        return MainApp ? <MainApp {...routeProps} {...props} component={component} /> : <Fragment />;
    };

    return <Route exact={exact} path={path} render={routeProps => (
        <Fragment>
            {renderComponent(routeProps)}
        </Fragment>
    )} />;
}; 