import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BaseComponent } from "../../../../components/page/structure/BaseComponent";
import { APIFetch } from "../../../../services/api";
import {
  ComentarioDetails,
  useComentarioService
} from "../../../../services/api-services/comentarios";
import { ProjetoDetails } from "../../../../services/api-services/projeto";
import {
  QuestaoDetails,
  RespostaDetails,
  useQuestaoService
} from "../../../../services/api-services/questao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { RespostaCertoErradoView } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/certoErrado";
import { RespostaEnunciado } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/enunciado";
import { RespostaEscolhaView } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/escolha";
import { RespostaPadraoView } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/padrao";
import { RespostaRelacionalView } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/relacional";
import { Apoios } from "../../../GerenciarQuestoes/views/visualizar-questao/apoios";
import { Enunciado } from "../../../GerenciarQuestoes/views/visualizar-questao/enunciado";
import { ComentarioView } from "./comentario";
import { EnunciadoContent, VisualizarQuestaoContent } from "./styles";

interface Props {
  idQuestao: string;
  idComentario: string;
}

export function VisualizarComentario() {
  const auth = useAuthContext();
  const questaoService = useQuestaoService(APIFetch(auth));
  const comentarioService = useComentarioService(APIFetch(auth));

  const { idQuestao, idComentario } = useParams<Props>();

  const id_questao = parseInt(idQuestao);

  const id_comentario = parseInt(idComentario);

  const answers = new Map<string, RespostaDetails[]>();

  const [questao, setQuestao] = useState<QuestaoDetails>({
    answer_type: "",
    legal_references: [],
    sources: [],
    nature: "",
    supports: [],
    statement_question: "",
    status: "",
    subject: {
      id: 0,
      name: "",
    },
    topic: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    tag: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    type: "",
    validation: "",
    id: 0,
    answers: answers,
    cod: "",
    correct: "",
    difficulty: "",
    exam_header: {
      id: 0,
      exam_name: "",
      oab_exam_number: "",
      body: {
        abbreviation: "",
        id: 0,
        name: "",
      },
      exam_board: {
        id: 0,
        name: "",
      },
      level: "",
      office: {
        id: 0,
        name: "",
      },
      year: 0,
      career: {
        id: 0,
        name: ""
      }
    },
    exam_disc_active: false,
    exam_discipline: ""
  });

  const [projeto, setProjeto] = useState<Array<ProjetoDetails>>([]);

  const [comentario, setComentario] = useState<ComentarioDetails>();

  useEffect(() => {
    questaoService.buscarQuestao(parseInt(idQuestao)).then((ele) => {
      setQuestao(ele);
    });
  }, []);

  useEffect(() => {
    comentarioService.buscarComentario(id_comentario).then((ele) => {
      setComentario(ele);
    });
  }, []);

  useEffect(() => {
    questaoService
      .listarProjetosVinculadosQuestao(id_questao)
      .then((projetos) => {
        setProjeto(projetos);
      });
  }, []);

  const respostas = () => {
    if (questao) {
      const tipo = questao.answer_type;
      const respostas: any = questao.answers;

      if (respostas) {
        switch (tipo) {
          case "Padrão": {
            const respostasColuna = respostas.first_column;

            if (respostasColuna) {
              return <RespostaPadraoView respostas={respostasColuna} />;
            }
            return <Fragment />;
          }
          case "Múltipla Escolha": {
            const respostasColuna = respostas.first_column;

            if (respostasColuna) {
              return <RespostaEscolhaView respostas={respostasColuna} />;
            }
            return <Fragment />;
          }
          case "Certo/Errado": {
            const respostasColuna = respostas.first_column;

            if (respostasColuna) {
              return <RespostaCertoErradoView respostas={respostasColuna} />;
            }
            return <Fragment />;
          }
          case "Verdadeiro/Falso": {
            const respostasColuna = respostas.first_column;

            if (respostasColuna) {
              return <RespostaCertoErradoView respostas={respostasColuna} />;
            }
            return <Fragment />;
          }
          case "Relacional": {
            if (respostas) {
              return <RespostaRelacionalView respostas={respostas} />;
            }
            return <Fragment />;
          }
        }
      }
    }
  };

  const getTitle = (comentarioObj: ComentarioDetails) => {
    let titulo =
      comentarioObj.comment_type === "Enunciado" ? "Enunciado" : "Alternativa";

    return titulo;
  };

  return (
    <BaseComponent pageName={`Comentário #${id_comentario}`}>
      <VisualizarQuestaoContent>
        <EnunciadoContent>
          <Enunciado questao={questao} projetos={projeto} />
          {questao?.answer_type === "Única" && (
            <RespostaEnunciado correta={questao.correct} />
          )}
          {comentario && (
            <ComentarioView
              title={getTitle(comentario)}
              comentario={comentario}
            />
          )}
          <Apoios questao={questao} />
        </EnunciadoContent>
        {respostas()}
      </VisualizarQuestaoContent>
    </BaseComponent>
  );
}
