import React, { useState } from 'react';
import { Select } from '..';
import { ItemSelect } from '../../page/structure/FilterComponent/types';
import { SubMenuItem } from '../styles';

type TSelectOption = {
  label?: string;
  className: string;
  getData: Array<ItemSelect>;
  onAddFilterItem(item: ItemSelect): void;
};

export const SelectOption: React.FC<TSelectOption> = (props) => {
  const [items, setItems] = useState<Array<ItemSelect>>(props.getData);

  const [open, setOpen] = useState(false);

  function handleselectItem(selectedIndex: number) {
    const item = items[selectedIndex];
    props.onAddFilterItem(item);
  }

  function handleInputValue(value: string) {}

  return (
    <Select
      onOpenSelect={(open) => setOpen(open)}
      minWidth={0}
      title={props.label}
      input={{
        valueInput: handleInputValue,
      }}
      className={props.className}>
      {items.map((item: ItemSelect, index) => (
        <SubMenuItem key={index} onClick={() => handleselectItem(index)}>
          <h5>{item.value}</h5>
        </SubMenuItem>
      ))}
    </Select>
  );
};
