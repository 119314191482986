import { Fragment } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { isAuthenticated, isAuthorized } from "..";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";
import { TAuthorizationRoute } from "../types";

export const AuthRoute = ({ component, roles, path, exact, permissions, mainApp }: TAuthorizationRoute): JSX.Element => {

    const auth = useAuthContext();

    const { type: MainApp, props } = mainApp;

    const componentWithPermissions = {
        type: component.type,
        props: {
            ...component.props,
            roles: roles,
            permissions: permissions
        }
    };

    const renderComponent = (routeProps: RouteComponentProps) => {
        if (isAuthenticated(auth)) {
            if (isAuthorized(auth, roles, permissions)) {
                return <MainApp  {...routeProps}{...props} component={componentWithPermissions} />;
            } else {
                alert('Você não tem permissão para acessar a funcionalidade!');
                return <Redirect to={{
                    pathname: '/', state: { from: routeProps.location }
                }} />;
            }
        } else {
            auth.logout();
            return <Redirect to={{
                pathname: '/login', state: { from: routeProps.location }
            }} />;
        }
    };

    return <Route exact={exact} path={path} render={routeProps => (
        <Fragment>
            {renderComponent(routeProps)}
        </Fragment>
    )} />;
};