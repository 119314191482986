import React from "react";
import {
  premissasOptions,
  respostaEscolhaOption,
  respostaVerdadeiroFalsoOptions
} from "../../../mocks/data";
import { useQuestaoContext } from "../context";
import { RespostasForm } from "../styles";
import { CriarRespostaObjetiva } from "./objetivas";
import { RespostaCertoErrado } from "./objetivas/certoErrado";
import { RespostaEscolha } from "./objetivas/escolha";
import { CriarRespostaPadrao } from "./objetivas/padrao";
import { CriarRespostasRelacionais } from "./objetivas/relacional";
import { RespostaVerdadeiroFalso } from "./objetivas/verdadeiroFalso";

export const CriarRespostas: React.FC<{}> = () => {
  const questaoContext = useQuestaoContext();
  //
  const criarRespostasMenu = () => {
    switch (questaoContext.questao.tipoResposta) {
      case "Múltipla Escolha": {
        return (
          <CriarRespostaObjetiva
            alternativa={RespostaEscolha}
            options={respostaEscolhaOption}
          />
        );
      }
      case "Certo/Errado": {
        return (
          <CriarRespostaObjetiva
            alternativa={RespostaCertoErrado}
            options={premissasOptions}
          />
        );
      }
      case "Verdadeiro/Falso": {
        return (
          <CriarRespostaObjetiva
            alternativa={RespostaVerdadeiroFalso}
            options={respostaVerdadeiroFalsoOptions}
          />
        );
      }
      case "Relacional": {
        return <CriarRespostasRelacionais />;
      }

      case "Padrão": {
        return <CriarRespostaPadrao />;
      }
    }
  };

  return <RespostasForm>{criarRespostasMenu()}</RespostasForm>;
};
