import React from "react";
import { IconBaseProps } from "react-icons";
import { ButtonNormalContent } from "./styles";

export interface TButtonNormal
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType<IconBaseProps>;
  title: string;
  className: string;
}

export const ButtonNormal: React.FC<TButtonNormal> = ({
  icon: Icon,
  className,
  title,
  ...rest
}) => {
  return (
    <ButtonNormalContent className={className} {...rest}>
      {Icon && <Icon size={18} className={"icon"} />}
      {title}
    </ButtonNormalContent>
  );
};
