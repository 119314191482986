import styled from "styled-components";

export const ReferenciasLegaisForm = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 32px;
  width: 100%;
  height: 100%;

  .referencias-options{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      
      .button-margin{
        margin-left: 12px;
      }

  }


 
`;

