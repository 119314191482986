import styled from "styled-components";

export type FilterSettings = {
  showFilters: boolean;
};

export const ListManagerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: 1;
`;

export const FilterSort = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  .margin-filter {
    margin-right: 12px;
  }

  small {
    color: var(--color-primary-light);
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 18px;
    margin-right: 12px;
    margin-left: 18px;
  }
`;

export const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 18px;

  justify-content: space-between;

  .filter-bar-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    small {
      color: var(--color-primary-dark);
      font-size: 1rem;
    }

    .filter-bar-icon {
      margin-right: 8px;
      color: var(--color-primary-dark);
    }
  }
`;

export const PesquisaOptions = styled.div``;

export const MenuFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;

  .left-menu-filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    align-items: flex-start;

    .qtd-select {
      margin-right: 12px;
    }

    .search-input {
      width: 100%;

      ${(settings: FilterSettings) =>
    settings.showFilters
      ? `flex: 1;
`
      : `      flex: 0.4;
`}

      font-size: 0.8rem;

      @media (max-width: 700px) {
        min-width: 0;
        width: 100%;
      }
    }
  }

  .right-menu-filter {
    display: flex;
    flex-direction: row;
    flex: 3 auto;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-wrap: wrap;

    .selected-items-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 24px;

      .qtd-selected-items {
        border: 2px solid var(--color-primary-dark);
        padding: 8px 12px 8px 12px;
        border-radius: 20%;
        margin-right: 18px;

        small {
          color: var(--color-primary-dark);
          font-size: 1rem;
        }
      }

      .selected-items-text {
        color: var(--color-primary-dark);
        font-weight: 500;
        margin-right: 12px;
      }
    }

    .list-options {
      margin-left: 12px;
    }

    @media (max-width: 700px) {
      justify-content: space-between;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 18px;
  margin-top: 18px;
  padding:8px;
  border: 4px solid var(--color-primary-light-clear);
  border-radius: 12px;

  @media (max-width: 700px) {
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const FilterOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--color-primary-light-clear);
  padding: 8px;
  section {
    display: flex;
    flex-direction: row;

  }
`;

export const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 18px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; 
  border: 2px solid var(--color-primary-light-clear);

  .search-content{
    margin: 12px;
  }
`;

export const ListContent = styled.div`
`;
