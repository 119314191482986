import { useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { Editor } from "../../../../../components/editor";
import { InputForm } from "../../../../../components/input";
import { Tag } from "../../../../../components/tag";
import { TitleIcon } from "../../../../../components/title-icon";
import {
  LeftResposta,
  RespostaContent,
  RespostaRow,
  RightResposta,
} from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/styles";
import { Resposta, useQuestaoContext } from "../../../context";
import { RespostaEscolhaContent } from "../../../styles";

export const CriarRespostaPadrao: React.FC<{}> = () => {
  //
  const questaoContext = useQuestaoContext();

  const [respostaAnterior, setRespostaAnterior] = useState<Resposta>();

  const [edit, setEdit] = useState(false);

  const [enunciado, setEnunciado] = useState("<p><br></p>");

  const [pontos, setPontos] = useState<number>(0);

  const handleResposta = () => {
    let valid = true;

    if (enunciado === "<p><br></p>") {
      valid = false;
      alert("Informe o texto padrão!");
    }

    if (valid) {
      if (edit) {
        editarResposta();
      } else {
        criarResposta();
      }
    }
  };

  const clear = () => {
    setEnunciado("<p><br></p>");
    setEdit(false);
  };

  const editarResposta = () => {
    if (respostaAnterior) {
      const resposta: Resposta = {
        id: respostaAnterior.id,
        answer: enunciado,
        anwser_correct: {
          id: -1,
          key: "",
          selected: false,
          useId: false,
          value: "",
        },
        column: "",
        points: pontos,
        related_column: "",
      };

      questaoContext.editarResposta(respostaAnterior, resposta, "first_column");
      clear();
    }
  };

  const criarResposta = () => {
    const resposta: Resposta = {
      id: respostaAnterior?.id ? respostaAnterior.id : "",
      answer: enunciado,
      anwser_correct: {
        id: -1,
        key: "",
        selected: false,
        useId: false,
        value: "",
      },
      column: "",
      points: pontos,
      related_column: "",
    };

    questaoContext.addResposta(resposta, "first_column");

    clear();
  };

  const excluirResposta = (resposta: Resposta) => {
    questaoContext.removerResposta(resposta, "first_column");
  };

  const handleEditar = (resposta: Resposta) => {
    setRespostaAnterior(resposta);
    setEnunciado(resposta.answer);
    setPontos(resposta.points);
    setEdit(true);
  };

  const alternativas = (respostas: Resposta[]) => {
    return (
      <RespostaContent>
        {respostas.map((ele, index) => {
          return (
            <RespostaRow key={index}>
              <LeftResposta>
                <div className="answer_correct">
                  <Tag
                    nome={ele.points + " pontos"}
                    colorDark={"color-primary-darking"}
                  />
                </div>
                <div
                  className="content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: ele.answer,
                  }}
                />
              </LeftResposta>
              <RightResposta>
                <ButtonBottomLess
                  className="edit"
                  icon={FiEdit2}
                  onClick={() => handleEditar(ele)}
                />
                <ButtonBottomLess
                  className="new-comentarios option-margin"
                  icon={MdDelete}
                  onClick={() => excluirResposta(ele)}
                />
              </RightResposta>
            </RespostaRow>
          );
        })}
      </RespostaContent>
    );
  };

  return (
    <RespostaEscolhaContent>
      <TitleIcon
        name={edit ? "Editar resposta padrão" : "Nova resposta padrão"}
        icon={AiOutlineUnorderedList}
      />
      <InputForm
        className="resposta"
        type={"number"}
        placeholder={"Pontos"}
        style={{
          minWidth: "40px",
        }}
        value={pontos}
        onChange={(value) => {
          setPontos(value.currentTarget.valueAsNumber);
        }}
      />
      <Editor
        currentText={enunciado}
        classname="editor-enunciado"
        html={(html) => setEnunciado(html)}
      />
      <div className="new-alternativa">
        <div />
        <ButtonBottomLess
          className="add-alternativa"
          title={edit ? "Salvar alterações" : "Adicionar"}
          icon={edit ? FiCheck : IoAdd}
          onClick={handleResposta}
        />
      </div>
      <h4>Respostas padrão</h4>
      {alternativas(questaoContext.respostas[0])}
    </RespostaEscolhaContent>
  );
};
