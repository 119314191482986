import styled from "styled-components";

export interface SidebarConfig {
  active: boolean;
}

export interface SidebarOption {
  optionIsActive?: boolean;
}

export const MenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  section {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
    height: 32px;

    small {
      margin-top: 12px;
      color: var(--color-sidebar-secondary-text);
      padding-top: 4px;
      font-size: 0.8rem;
      padding-bottom: 4px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
`;

export const ContentSidebar = styled.div`
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    display: block;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-sidebar-dark);
    opacity: 0.6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-sidebar-scroll-background);
    border-radius: 20px;
  }
`;

export const SidebarItem = styled.li`
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: none;
    padding: 12px;

    :hover {
      background-color: var(--color-sidebar-scroll-background);
      cursor: pointer;
      border-radius: 12px;
      margin: 4px;
      transition: all 1s ;
    }

    h4 {
      margin-left: 8px;
      color: var(--color-sidebar-secondary-text);
      font-weight: 500;
      font-size: 0.875rem;
    }

    .sidebar-menu-item-icone {
      color: var(--color-sidebar-secondary-text);
      padding: 8px;
      border-radius: 4px;
    }

    .sidebar-menu-option-icone {
      color: var(--color-sidebar-secondary-text);
      background-color: rgba(0, 0, 0, 0.08);
      border-color: var(--color-sidebar-secondary-text);
      padding: 8px;
      border-radius: 4px;
    }
  }

  ul {
    display: ${(option: SidebarOption) =>
    option.optionIsActive ? "block" : "none"};
  }
`;

export const SidebarSubItem = styled(SidebarItem)`
  div {
    padding: 8px 18px 8px 18px;
  }

  div {
    background-color: var(--color-sidebar-light);
  }

  h5 {
    color: var(--color-sidebar-secondary-text);
    font-weight: 400;
    margin-left: 8px;
    font-size: 0.80rem;
  }
`;

export const MainSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-sidebar-dark);
  z-index: 200;

  @media (max-width: 700px) {
    position: fixed;
  }
`;

export const HeaderLogo = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 64px;
  background-color: var(--color-sidebar-header);
  box-shadow: 4px 0 5px rgb(0 0 0 / 8%);

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 12px;
    padding-right: 12px;

    h3 {
      color: var(--color-sidebar-secondary-text);
      font-weight: 500;
    }

    h5 {
      color: var(--color-sidebar-secondary-text);
      font-weight: 500;
    }
  }
`;
