import styled from "styled-components";

export const TextoContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 24px;
  border-radius: 0px 0px 12px 12px;
`;

export const TextoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border-bottom: 2px solid var(--color-select-border);
  padding: 12px;
  margin: 12px;
`;

export const TextLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 88%;

  .content {
    max-width: 840px;
    margin-left: 8px;

    img {
      max-width: 100%;
    }
  }

  .number {
    color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 12px;
  }
`;

export const TextRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 8%;
`;
