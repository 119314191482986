import { AxiosInstance } from "axios";
import { Fragment, useRef, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { CollapseButton } from "../../../../components/button/collapse";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import { LayoutDragFeatures } from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { Tag } from "../../../../components/tag";
import {
  DivisaoData,
  useDivisaoProjeto,
} from "../../../../services/api-services/divisoesProjeto";
import { DivisaoContent } from "../divisao-item/styles";

export interface TDivisaoItem {
  type: "editar" | "criar";
  idProject?: number;
  zone: DropZoneData<DivisaoData>;
  data?: DivisaoData | undefined;
  selectedDivisao?: DropZoneData<DivisaoData>;
  onSelectedDivisao: (data: DropZoneData<DivisaoData>) => void;
  authInstance: AxiosInstance;
  features: LayoutDragFeatures<DivisaoData>;
  externalAction: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  atualizarLayout?: () => void;
}

export type TOpcaoSelecionada = "group" | "separator";

export const DivisaoOption: React.FC<TDivisaoItem> = ({
  zone,
  data,
  authInstance,
  features,
  onSelectedDivisao,
  externalAction,
  type,
  selectedDivisao,
  idProject,
}) => {
  const [action, setAction] = externalAction;

  const divRef = useRef<HTMLDivElement>(null);

  const [opcaoSelecionada, setOpcaoSelecionada] = useState<TOpcaoSelecionada>();

  const [divisaoOpen, setDivisaoOpen] = useState<boolean>(false);

  const [titulo, setTitulo] = useState<string>("");

  const services = {
    divisaoProjetoService: useDivisaoProjeto(authInstance),
  };

  const divisaoIsOpen = (tipo: string) => {
    if (zone.data.type === "parent") {
      if (zone.data.isOpen) {
        setDivisaoOpen(false);

        if (type === "editar") {
          features.addSubGroupsToParent(false, zone.path, []);
        } else if (type === "criar") {
          features.setOpen(false, zone.path);
        }
      } else {
        setDivisaoOpen(true);

        if (type === "editar") {
          services.divisaoProjetoService
            .listarSubDivisoes(Number(idProject), Number(zone.data.id))
            .then((divisoes) => {
              features.addSubGroupsToParent(true, zone.path, divisoes.content);
            });
        } else if (type === "criar") {
          features.setOpen(true, zone.path);
        }
      }
    }
  };

  const renderTitle = () => {
    let titleNode = <Fragment />;

    if (data) {
      const tipoDivisao = <b>{`${data.order} - ${data.level}`}</b>;

      if (data.title) {
        titleNode = (
          <span className="divisao-nome">
            {tipoDivisao}
            {data.title && (
              <div
                className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
          </span>
        );
      } else if (data.additional_text) {
        titleNode = (
          <span className="divisao-nome">
            {tipoDivisao}
            {data.additional_text && (
              <div
                className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: data.additional_text,
                }}
              />
            )}
          </span>
        );
      } else {
        titleNode = <span className="divisao-nome">{tipoDivisao}</span>;
      }
    }

    return titleNode;
  };

  const isSelected = () => {
    if (selectedDivisao) {
      if (selectedDivisao.data.id === zone.data.id) {
        return (
          <HiOutlineCheckCircle
            size={24}
            color={"var(--color-primary-darking)"}
          />
        );
      }
    }
  };

  return (
    <Fragment>
      {data && (
        <DivisaoContent>
          <div
            className={"titulos"}
            onClick={() => {
              onSelectedDivisao(zone);
            }}
          >
            <CollapseButton
              editarOpen={divisaoOpen}
              className={"button-option"}
              icon={IoIosArrowDropdownCircle}
              setEditar={divisaoIsOpen}
              type="separator"
              selecionado={opcaoSelecionada}
            />
            {renderTitle()}
            {isSelected()}
          </div>
        </DivisaoContent>
      )}
    </Fragment>
  );
};
