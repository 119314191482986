import { CheckBox } from "../../../../components/checkbox";
import {
  ThreeNormal,
  ThreeNormalOption
} from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { EstruturaExportacao } from "../../../../services/api-services/estruturaExportacao";

export const EstruturaExportacaoHeader = (
  selectedEntitiesAction: SelectedDataActions<EstruturaExportacao>
) => {
  const EstruturaExportacaoRow = (elem: ItemDataSelect<EstruturaExportacao>, index: number) => {
    const estruturaExportacao = elem.entity as EstruturaExportacao;
    const context = useGerenciarContext();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{estruturaExportacao.id}</RowItem>
        <RowItem>{estruturaExportacao.structure_name}</RowItem>
        <RowItem width="10px">
          <ThreeNormal
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar-estrutura-exportacao",
                    id: estruturaExportacao.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: estruturaExportacao.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    estruturaExportacaoHeader: EstruturaExportacaoRow,
  };
};
