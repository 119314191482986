import { Fragment } from "react";
import { Tag } from "../../../../../../components/tag";
import { RespostaRow, RightResposta } from "../styles";

interface TRespostaPadraoView {
  correta: string;
}

export const RespostaEnunciado: React.FC<TRespostaPadraoView> = ({
  correta,
}) => {
  const corretoRender = () => {
    let tag = <Fragment />;

    if (correta === "Certo" || correta === "Verdadeiro") {
      tag = <Tag nome={correta} colorDark={"color-resposta-correta"} />;
    } else if (correta === "Errado" || correta === "Falso") {
      tag = <Tag nome={correta} colorDark={"color-primary-darking"} />;
    }

    return tag;
  };

  return (
    <RespostaRow>
      <RightResposta>
        <p>Enunciado: </p>
        <div className="answer_correct">{corretoRender()}</div>
      </RightResposta>
    </RespostaRow>
  );
};
