import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

:root{
    --color-background: #FCF8F8;
    --color-primary-dark: #ff6565;
    --color-primary-darking: #ff4545;
    --color-primary-light: #ff9494;
    --color-primary-light-clear: #fff0f0;
    --color-primary-clear: rgba(255.0, 219.0, 219.0, 0.1);
    --color-sidebar-dark: #1a1c1e;
    --color-sidebar-header: #1a1c1e;
    --color-sidebar-scroll-background: #363636;
    --color-content-light: #FFFFFF;
    --color-content-border: #ffdede;
    --color-sidebar-primary-text: #C5C5C5;
    --color-sidebar-secondary-text:#dedede;
    --color-sidebar-border: #5e3838;
    --color-text-dark: #595757;
    --color-text-light: #FFFFFF;
    --color-secondary-grey: #F8F8F8;
    --color-select-border: #f5ebeb;
    --color-text-grey-light: #E6E6F0;
    --color-text-secondary:#666666; 
    --color-text-grey: #969696;  
    --color-status-regular: #5DD479;
    --color-status-desatualizada: #ede868;
    --color-status-cancelada: #262424;
    --color-resposta-correta: #5DD479;
}

*{
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;

   a{
     text-decoration: none;
   }

  .editor-image{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */            
    max-width: 100%;
    max-height: 100%;
  }

}

body{
    background-color: var(--color-background);
    -webkit-font-smoothing: antialiased;

    ::-webkit-scrollbar {
      width: 8px;
      display: block;
    }

    ::-webkit-scrollbar-track {
      background-color: var(--color-background);
      opacity: 0.6;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--color-content-border);
      border-radius: 20px;
    }
}

body,input,button,textarea,a{
    font-family: Segoe UI, -apple-system;
    font-size: 1rem;
}

@media(min-width: 700px){
    :root{
        font-size: 90%;
    }
}

@media(max-width: 700px){
    :root{
        font-size: 80%;
    }
}
`;

export default GlobalStyle;

export interface SidebarConfig {
  active: boolean;
}
