import { ButtonBottomLess } from "jusadmin-ui-kit";
import { MdSecurity } from "react-icons/md";
import { VscLaw } from "react-icons/vsc";
import { ThreeNormalOption } from "../../../components/menu/three-normal";
import {
  Navbar,
  NavBarBaseTypes
} from "../../../components/page/structure/Navbar";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";

export const JusBqNavbar: React.FC<NavBarBaseTypes> = () => {
  const { user, logout } = useAuthContext();

  const onLogoutEvent = () => {
    logout();
  };

  return (
    <Navbar
      nomeCompleto={user.fullname}
      onLogoutEvent={onLogoutEvent}
      optionsConfig={[
        <ThreeNormalOption
          icon={MdSecurity}
          name="Segurança e login"
          onClick={() => {
            window.open("/seguranca-login", "_blank");
          }}
        />,
      ]}
    >
      <ButtonBottomLess
        icon={VscLaw}
        className="banco-legislacoes"
        title="Banco de Legislações"
        onClick={() => {
          window.location.href = process.env.REACT_APP_BANCO_LEGISLACAO_URL ? process.env.REACT_APP_BANCO_LEGISLACAO_URL : "";
        }}
      />
    </Navbar>
  );
};
