import React, { useEffect, useState } from "react";
import { FaHashtag } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { VscSave } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { ButtonNormal } from "../../../../components/button/normal";
import { FilterTag } from "../../../../components/filter-tag";
import { InputForm } from "../../../../components/input";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { useTagAction } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { NormalSelect } from "../../../../components/select/normal";
import { TitleIcon } from "../../../../components/title-icon";
import { tipoPadraoLeiOptions } from "../../../../mocks/data";
import { APIFetch } from "../../../../services/api";
import { useTipoLeiService } from "../../../../services/api-services/tipoLeis";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { CriarTipoLeiContent, TipoLeiContent } from "./styles";

export interface TCriarTipoLei {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const CriarTipoLei: React.FC<TCriarTipoLei> = ({ externalAction }) => {
  const auth = useAuthContext();

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const services = {
    tipoLeiService: useTipoLeiService(APIFetch(auth)),
  };

  const [tipoPadrao, setTipoPadrao] = useState({
    id: -1,
    key: "level",
    useId: true,
    value: "",
    selected: false,
  });

  const [nome, setNome] = useState("");

  const [nomeTermo, setNomeTermo] = useState("");

  const termos = useTagAction();

  const onSubmit = () => {
    if (currentAction.action == "editar-tipo-lei") {
      services.tipoLeiService
        .editarTipoLei({
          id: currentAction.id,
          name: nome,
          pattern_type: tipoPadrao.value,
          terms: termos.getTagsString(";"),
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Tipo de Lei salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else if (currentAction.action == "criar-tipo-lei") {
      services.tipoLeiService
        .salvarTipoLei({
          name: nome,
          pattern_type: tipoPadrao.value,
          terms: termos.getTagsString(";"),
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Tipo de Lei salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    currentAction.action == "editar-tipo-lei" &&
      services.tipoLeiService
        .buscarTipoLei(currentAction.id)
        .then((tipoLei) => {
          if (tipoLei.terms) {
            termos.addAllTags(
              tipoLei.terms.split(";").map((termoTag, index) => {
                const item: ItemSelect = {
                  id: index,
                  value: termoTag,
                  key: termoTag,
                  useId: false,
                };

                return item;
              })
            );
          }

          if (tipoLei.pattern_type) {
            const tipoLeiCarregado = tipoPadraoLeiOptions.find(
              (item) => item.value === tipoLei.pattern_type
            );

            tipoLeiCarregado && setTipoPadrao(tipoLeiCarregado);
          }

          setNome(tipoLei.name);
        });
  }, []);

  const footer = () => {
    return (
      <ButtonNormal
        icon={VscSave}
        onClick={() => onSubmit()}
        title={"Salvar"}
        className={"save"}
        type={"button"}
      />
    );
  };

  const title = () => {
    return currentAction.action == "criar-tipo-lei"
      ? "Novo Tipo de Lei"
      : `Editar Tipo de lei (${nome && nome})`;
  };

  return (
    <Modal width={900} action={actionState} options={footer()} title={title()}>
      <CriarTipoLeiContent>
        <TipoLeiContent>
          <div className="form-group">
            <div className="field-group">
              <label>Nome do tipo da Lei</label>
              <InputForm
                className="tipo-lei"
                value={nome}
                onChange={(value) => {
                  setNome(value.currentTarget.value);
                }}
              />
            </div>
            <div className="field-group">
              <label>Padrão da Lei</label>
              <NormalSelect
                minWidth={0}
                className="padrao-lei form-group"
                label="Selecione o padrão da lei"
                selectItem={tipoPadrao}
                onSelectCurrentItem={(item) =>
                  setTipoPadrao({
                    ...tipoPadrao,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  })
                }
                selectedItems={tipoPadraoLeiOptions}
              />
            </div>
          </div>
          {tipoPadrao.value === "Termos" && (
            <div>
              <TitleIcon name="Termos" icon={FaHashtag} />
              <div className="form-group">
                <div className="field-group">
                  <InputForm
                    className="termo form-group"
                    placeholder="Nome do termo"
                    value={nomeTermo}
                    onChange={(value) => {
                      setNomeTermo(value.currentTarget.value);
                    }}
                  />
                </div>
                <div className="field-group">
                  <ButtonBottomLess
                    title="Adicionar"
                    className="add-termo form-group"
                    icon={IoAdd}
                    onClick={() => {
                      termos.setTagValue(nomeTermo, nomeTermo);
                    }}
                  />
                </div>
              </div>
              <div className="termos-tags">
                {termos.getTags().map((item, index) => {
                  return (
                    <FilterTag
                      key={index}
                      nome={item.value}
                      onMouseClick={() => termos.removeTag(item)}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </TipoLeiContent>
      </CriarTipoLeiContent>
    </Modal>
  );
};
