import styled from 'styled-components';

export const ComentarContent = styled.div`
  width: 100%;
  height: 100%;

  .editor-enunciado {
    width: 100%;
    height: 200px;
  }
`;

export const ComentarioOptions = styled.div`
  padding: 12px;
`;

export const ComentarFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;
