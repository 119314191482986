import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { tagOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  Disciplina,
  useDisciplinaService
} from "../../services/api-services/disciplina";
import { Tag, useTagService } from "../../services/api-services/tag";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarTag } from "./forms/criar-tag";
import { ExcluirTag } from "./forms/excluir-tag";
import { TagHeader } from "./views/row-tag";

interface props {
  id: string;
}
export const GerenciarTagsComponent = () => {
  //
  const { id } = useParams<props>();

  const [disciplina, setDisciplina] = useState<Disciplina>();

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const tagService = useTagService(APIFetch(auth));

  const disciplinaService = useDisciplinaService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const tagState = useState<Array<ItemDataSelect<Tag>>>([]);
  const setTags = tagState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<Tag>({
    state: tagState,
  });

  const keys: string[] = ["subject"];

  useEffect(() => {
    disciplinaService
      .buscarDisciplina(parseInt(id))
      .then((value: Disciplina) => {
        setDisciplina(value);
      });
  }, []);

  useEffect(() => {
    if (context.loading && disciplina) {
      tagService
        .listagemTag(
          disciplina.name,
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order,
          useTagActions.getFilters(keys)
        )
        .then((tags) => {
          setTags(
            tags.content.map((value: Tag) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(tags.paginationData);
          context.toPage(tags.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading, disciplina]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Nova tag"}
          className={"tag-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar" });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirTag />;
      }

      case "criar": {
        return <CriarTag disciplinaVinculada={disciplina} />;
      }
      case "editar": {
        return <CriarTag disciplinaVinculada={disciplina} />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<Tag>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={tagState}
        filters={null}
        page={{
          name: `${disciplina?.name} - Tags`,
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome da tag...",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: tagOrdenacao,
          },
          options: [],
          mode: mode,
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Nome</div>,
              <div>Disciplina</div>,
              <div>Ações</div>,
            ],
            dataColumn: TagHeader(selectedEntitiesAction).tagHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarTags = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarTagsComponent />
    </GerenciarBaseProvider>
  );
};
