import React, { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { OptionGroup } from "../option";
import { MainOptions } from "../option/styles";

export interface TOptionsButton {
  buttons: Array<TButtonSelect>;
  initialPositionSelected: number;
  className?: string;
  onCurrentButtonSelected?: (current: TButtonSelect) => void;
}

export interface TButtonSelect {
  onClick: (current: TButtonSelect) => void;
  name?: string;
  icon?: React.ComponentType<IconBaseProps>;
  classname: string;
  text: string;
  value: string;
}

export const OptionsButton: React.FC<TOptionsButton> = (props) => {
  //
  const [optionActive, setOptionActive] = useState(
    props.initialPositionSelected
  );

  const [optionsActive, setOptionsActive] = useState<boolean[]>(() => {
    {
      const array = props.buttons.map((ele) => {
        return false;
      });

      array[props.initialPositionSelected] = true;

      return array;
    }
  });

  useEffect(() => {
    props.onCurrentButtonSelected &&
      props.onCurrentButtonSelected(props.buttons[optionActive]);
  }, [optionActive]);

  const activeOption = (id: number) => {
    optionsActive[optionActive] = false;
    optionsActive[id] = true;
    setOptionActive(id);
  };

  const optionsIsActive = (index: number): boolean => {
    return optionsActive[index];
  };

  return (
    <MainOptions className={props.className}>
      {props.buttons.map((ele, index) => {
        return (
          <OptionGroup
            key={index}
            icon={ele.icon}
            className={ele.classname}
            title={ele.name}
            text={ele.text}
            onClick={() => {
              activeOption(index);
              ele.onClick(props.buttons[index]);
            }}
            active={optionsIsActive(index)}
          />
        );
      })}
    </MainOptions>
  );
};
