import React, { Fragment } from "react";
import { IoClose } from "react-icons/io5";
import { ItemSelect } from "../page/structure/FilterComponent/types";
import { TagFilterContent } from "./styles";

interface TFilterTag {
  nome: string;
  onMouseClick?: () => void;
  option?: React.ReactNode
  itemSelect?: ItemSelect;
  render?: (item?: ItemSelect) => React.ReactNode;
  className?: string;
}

export const FilterTag: React.FC<TFilterTag> = ({ nome, className, render, itemSelect, option, onMouseClick }) => {
  return (
    <TagFilterContent className={className}>
      {render ? render(itemSelect) : <Fragment> {option && option}
        <small>{nome}</small></Fragment>}
      <IoClose size={18} className="delete-tag" onClick={onMouseClick} />
    </TagFilterContent>
  );
};
