import { AxiosInstance } from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { FcDeleteRow } from "react-icons/fc";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdOutlineDragIndicator } from "react-icons/md";
import { VscSaveAs } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CollapseButton } from "../../../../components/button/collapse";
import { CheckIcon } from "../../../../components/check-label";
import { CheckBox } from "../../../../components/checkbox";
import { DragAndDropList } from "../../../../components/drag-and-drop";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  ItemTypes,
  LayoutDragFeatures,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { FilterTag } from "../../../../components/filter-tag";
import {
  ThreeNormal,
  ThreeNormalOption
} from "../../../../components/menu/three-normal";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { useTagAction } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { PaginationContent } from "../../../../components/page/structure/ListComponent/styles";
import { PaginationDropping } from "../../../../components/pagination";
import { AsyncSelect } from "../../../../components/select/async";
import {
  NormalSelect,
  TItemSelect
} from "../../../../components/select/normal";
import { SelectOption } from "../../../../components/select/option";
import { Tag } from "../../../../components/tag";
import useOnClickOutside from "../../../../components/util/ClickOutside";
import { getYears } from "../../../../components/util/DateUtil";
import {
  dificuldadesOptions,
  fontesOptions,
  parametrosOrdenacaoQuestao,
  paramsOptions,
  selectNivel
} from "../../../../mocks/data";
import { useBancaService } from "../../../../services/api-services/banca";
import { useCargoService } from "../../../../services/api-services/cargo";
import { useDisciplinaService } from "../../../../services/api-services/disciplina";
import {
  DivisaoData,
  DivisaoGrupo,
  DivisaoSeparador,
  OrdenacaoMeta,
  QuestaoProjetoDivisao,
  useDivisaoProjeto
} from "../../../../services/api-services/divisoesProjeto";
import { useOrgaoService } from "../../../../services/api-services/orgao";
import { useTemaService } from "../../../../services/api-services/tema";
import { QuestaoItemLista } from "../../../EdicaoDoProjeto/components/questao-item";
import { FiltrosOrdenacao } from "../../forms/assistente-estrutura/styles";
import { useQuestoesContext } from "./context";
import { QuestoesProviderContent } from "./provider";
import { DivisaoContent } from "./styles";

export interface TDivisaoItem {
  type: "editar" | "criar";
  idProject?: number;
  zone: DropZoneData<DivisaoData>;
  data?: DivisaoData | undefined;
  authInstance: AxiosInstance;
  features: LayoutDragFeatures<DivisaoData>;
  externalAction: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  atualizarLayout?: (features: LayoutDragFeatures<DivisaoData>) => void;
  dragTarget?: React.RefObject<HTMLDivElement>;
}

export type TOpcaoSelecionada = "group" | "separator";

export const DivisaoItem: React.FC<TDivisaoItem> = (props) => {
  return (
    <QuestoesProviderContent>
      <DivisaoItemContent {...props} />
    </QuestoesProviderContent>
  );
};

export const DivisaoItemContent: React.FC<TDivisaoItem> = ({
  zone,
  data,
  authInstance,
  features,
  externalAction,
  type,
  idProject,
  atualizarLayout,
  dragTarget,
}) => {
  const [action, setAction] = externalAction;

  const divRef = useRef<HTMLDivElement>(null);

  const [disciplina, setDisciplina] = useState<ItemSelect>();

  const [editarOpen, setEditarOpen] = useState<boolean>(false);

  const [divisaoOpen, setDivisaoOpen] = useState<boolean>(false);

  const [opcaoSelecionada, setOpcaoSelecionada] = useState<TOpcaoSelecionada>();

  const [titulo, setTitulo] = useState<string>("");

  const [parametro, setParametro] = useState<TItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
    selected: false,
  });

  const [valorParametro, setValorParametro] = useState<TItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
    selected: false,
  });

  const parametrosOrdenacao = useTagAction();

  const questoesCX = useQuestoesContext();

  const [textoAdicional, setTextoAdicional] = useState<string | "">("");

  const [reiniciarSequencia, setReiniciarSequencia] = useState<boolean>(false);

  const [totalPages, setTotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PARENT,
    item: {
      ...zone,
      target: "separator",
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(divRef);

  const services = {
    orgaoService: useOrgaoService(authInstance),
    bancaService: useBancaService(authInstance),
    cargoService: useCargoService(authInstance),
    disciplinaService: useDisciplinaService(authInstance),
    temaService: useTemaService(authInstance),
    divisaoProjetoService: useDivisaoProjeto(authInstance),
  };

  useEffect(() => {
    if (editarOpen && type === "editar" && zone.data && data) {
      services.divisaoProjetoService
        .consultarDivisoes(Number(idProject), Number(zone.data.id))
        .then((response) => {
          setDivisaoData(response);
          carregarParametro(response);
          carregarValorParametro(response);
          carregarParametroOrdenacao(response);
          setTextoAdicional(response.additional_text);
          setTitulo(response.title ? response.title : "");
          setReiniciarSequencia(response.restart_sequence);
        });
    } else if (editarOpen && type === "criar" && zone.data && data) {
      carregarParametro(data);
      carregarValorParametro(data);
      carregarParametroOrdenacao(data);
      setTextoAdicional(data.additional_text);
      setTitulo(data.title ? data.title : "");
      setReiniciarSequencia(data.restart_sequence);
    }

    setDisciplina({
      id: "",
      key: "",
      useId: false,
      value: "",
    });
  }, [editarOpen]);

  useEffect(() => {
    if (valorParametro && valorParametro.value !== "") {
      setTextoAdicional("");
    }
  }, [valorParametro]);

  useOnClickOutside(divRef, () => {
    if (action.action !== "editar-texto-divisao" && editarOpen) {
      setEditarOpen(false);
    }
  });

  const setDivisaoData = (value: DivisaoData) => {
    if (zone.data.data && zone) {
      let divTmp: DivisaoData = { ...zone.data.data };

      if (value.title) {
        divTmp = {
          ...divTmp,
          title: value.title,
        };
      }

      if (value.metadata) {
        divTmp = {
          ...divTmp,
          additional_text: "",
          metadata: {
            parameter: value.metadata.parameter,
            value: value.metadata.value,
          },
        };
      }

      if (value.additional_text) {
        divTmp = {
          ...divTmp,
          additional_text: value.additional_text,
        };
      }

      if (value.meta_sorting) {
        divTmp = {
          ...divTmp,
          meta_sorting: value.meta_sorting,
        };
      }

      divTmp = {
        ...divTmp,
        restart_sequence: value.restart_sequence,
      };

      features.setData(zone, divTmp);
    }
  };

  const salvarAlteracoes = () => {
    if (zone.data.data && zone) {
      if (type === "criar") {
        salvarDadosNoContexto();
        setEditarOpen(false);
      } else if (type === "editar") {
        if (opcaoSelecionada === "group") {
          let ordenacaoData: OrdenacaoMeta[] | undefined = undefined;

          ordenacaoData = parametrosOrdenacao.getTags().map((value) => {
            return {
              parameter: value.id as string,
              order: value.object ? value.object : "asc",
            };
          });

          const request: DivisaoGrupo = {
            restart_sequence: reiniciarSequencia,
            type: "group",
            meta_sorting: ordenacaoData,
          };

          setAction({
            ...action,
            action: "loading",
          });

          services.divisaoProjetoService
            .editarGrupo(Number(idProject), Number(zone.data.id), request)
            .then(() => {
              setAction({
                ...action,
                action: "",
              });

              salvarDadosNoContexto();
              features.setUpdateContent(zone, true);
            })
            .catch(() => {
              setAction({
                ...action,
                action: "",
              });

              alert(
                "Não foi possível atualizar, agrupamento pode ter sido movido ou excluído, atualize a página."
              );
            });
        } else if (opcaoSelecionada === "separator") {
          const request: DivisaoSeparador = {
            type: "separator",
            additional_text: textoAdicional,
            metadata: parametro
              ? {
                parameter: parametro.id,
                value: valorParametro.id,
              }
              : undefined,
          };

          services.divisaoProjetoService
            .editarSeparador(Number(idProject), Number(zone.data.id), request)
            .then(() => {
              salvarDadosNoContexto();
            })
            .catch(() => {
              alert(
                "Não foi possível atualizar, separador pode ter sido movido ou excluído, atualize a página."
              );
            });
        }

        setEditarOpen(false);
      }
    }
  };

  const excluirSeparadorAPI = () => {
    const excluirAPI = async (properties: any) => {
      const {
        adjacent_division_id,
        move_division_id,
        dynamic_movement_content,
        sort_questions,
      } = properties;
      return services.divisaoProjetoService.excluirSeparador(
        Number(idProject),
        Number(zone.data.id),
        {
          adjacent_division_id: adjacent_division_id,
          move_division_id: move_division_id,
        },
        dynamic_movement_content,
        sort_questions
      );
    };

    if (zone.data && data) {
      services.divisaoProjetoService
        .consultarDivisaoAdjacente(Number(idProject), Number(zone.data.id))
        .then((idAdjacent) => {
          setAction({
            ...action,
            action: "escolher-separador",
            promisse: excluirAPI,
            id: idAdjacent,
            object: {
              titulo: data.title ? data.title : `${data.order} - ${data.level}`,
              idDivision: zone.data.id,
            },
          });
        })
        .catch((error) => {
          const action = error.response.data.action;

          if (action === "mover-conteudo") {
            setAction({
              ...action,
              action: "info-adjacente",
              promisse: excluirAPI,
              message: error.response.data.message,
              object: {
                titulo: data.title
                  ? data.title
                  : `${data.order} - ${data.level}`,
                idDivision: zone.data.id,
              },
            });
          } else {
            setAction({
              ...action,
              id: 0,
              action: "escolher-separador",
              promisse: excluirAPI,
              object: {
                titulo: data.title
                  ? data.title
                  : `${data.order} - ${data.level}`,
                idDivision: zone.data.id,
              },
            });
          }
        });
    }
  };

  const excluirGrupoAPI = () => {
    if (zone.data) {
      services.divisaoProjetoService
        .excluirGrupo(Number(idProject), Number(zone.data.id))
        .then(() => {
          atualizarLayout && atualizarLayout(features);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const salvarDadosNoContexto = () => {
    if (zone.data.data && zone) {
      let divisaoDados: DivisaoData = {
        ...zone.data.data,
      };

      const setValorParametroContexto = () => {
        if (parametro && valorParametro) {
          const param = String(parametro.id);

          if (param.includes("Orgao")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.id)
            );
          } else if (param.includes("Banca")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.id)
            );
          } else if (param.includes("Cargo")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.id)
            );
          } else if (param.includes("NivelFormacao")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.value)
            );
          } else if (param.includes("FonteJuridica")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.value)
            );
          } else if (param.includes("AnoProva")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.value)
            );
          } else if (param.includes("Disciplina")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.id)
            );
          } else if (param.includes("Dificuldade")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.value)
            );
          } else if (param.includes("Tema")) {
            setParametroValorInObject(
              valorParametro.value,
              param,
              String(valorParametro.id)
            );
          }
        }
      };

      const setParametroValorInObject = (
        titulo: string,
        parametro: string,
        valor?: string
      ) => {
        setTitulo(titulo);

        if (zone.data.data) {
          divisaoDados = {
            ...divisaoDados,
            additional_text: "",
            title: titulo,
            metadata: {
              parameter: parametro,
              value: valor,
            },
          };
        }
      };

      setValorParametroContexto();

      divisaoDados = {
        ...divisaoDados,
        additional_text: textoAdicional ? textoAdicional : "",
        title: textoAdicional ? textoAdicional : divisaoDados.title,
        restart_sequence: reiniciarSequencia,
      };

      const ordenacaoData = parametrosOrdenacao.getTags().map((value) => {
        return {
          parameter: value.id as string,
          order: value.object ? value.object : "asc",
        };
      });

      divisaoDados = {
        ...divisaoDados,
        meta_sorting: ordenacaoData,
      };

      features.setData(zone, divisaoDados);
    }
  };

  const carregarParametroOrdenacao = (divisaoDados?: DivisaoData) => {
    if (divisaoDados) {
      const ordenacao = divisaoDados.meta_sorting;

      if (ordenacao) {
        parametrosOrdenacao.addAllTags(
          ordenacao.map((value) => {
            return {
              id: value.parameter,
              key: "param",
              useId: false,
              value: value.parameter,
              object: value.order,
            };
          })
        );
      }
    }
  };

  const carregarParametro = (divisaoDados?: DivisaoData) => {
    if (divisaoDados) {
      const found = paramsOptions.find(
        (value) => value.id === divisaoDados.metadata?.parameter
      );

      if (found) {
        setParametro(found);
      } else {
        setParametro(paramsOptions[0]);
      }
    }
  };

  const carregarValorParametro = (divisaoDados?: DivisaoData) => {
    if (divisaoDados) {
      if (divisaoDados.metadata) {
        const parametro = divisaoDados.metadata.parameter;

        if (parametro.includes("Orgao")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "body",
            useId: false,
            value: divisaoDados.title ? divisaoDados.title : "",
            selected: true,
          });
        } else if (parametro.includes("Banca")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "bank",
            useId: false,
            value: divisaoDados.title ? divisaoDados.title : "",
            selected: true,
          });
        } else if (parametro.includes("Cargo")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "office",
            useId: false,
            value: divisaoDados.title ? divisaoDados.title : "",
            selected: true,
          });
        } else if (parametro.includes("NivelFormacao")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "level",
            selected: true,
            useId: false,
            value:
              divisaoDados.metadata.value && divisaoDados.title
                ? divisaoDados.title
                : "",
          });
        } else if (parametro.includes("FonteJuridica")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "source",
            selected: true,
            useId: false,
            value:
              divisaoDados.metadata.value && divisaoDados.title
                ? divisaoDados.title
                : "",
          });
        } else if (parametro.includes("AnoProva")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "year",
            selected: true,
            useId: false,
            value: divisaoDados.metadata.value
              ? divisaoDados.metadata.value
              : "",
          });
        } else if (parametro.includes("Disciplina")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "subject",
            useId: false,
            value: divisaoDados.title ? divisaoDados.title : "",
            selected: true,
          });
        } else if (parametro.includes("Dificuldade")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "difficulty",
            selected: true,
            useId: false,
            value:
              divisaoDados.metadata.value && divisaoDados.title
                ? divisaoDados.title
                : "",
          });
        } else if (parametro.includes("Tema")) {
          setValorParametro({
            id: Number(divisaoDados.metadata.value),
            key: "topic",
            useId: false,
            value: divisaoDados.title ? divisaoDados.title : "",
            selected: true,
          });
        }
      }
    }
  };

  const setEditar = (tipoSelecionado: string) => {
    if (editarOpen && action.action !== "editar-texto-divisao") {
      setEditarOpen(false);
      setOpcaoSelecionada(undefined);
    } else {
      setEditarOpen(true);
      setOpcaoSelecionada(tipoSelecionado as TOpcaoSelecionada);
    }
  };

  const divisaoIsOpen = (tipo: string) => {
    if (zone.data.type === "parent") {
      if (zone.data.isOpen) {
        setDivisaoOpen(false);

        if (type === "editar") {
          features.addSubGroupsToParent(false, zone.path, []);
        } else if (type === "criar") {
          features.setOpen(false, zone.path);
        }
      } else {
        setDivisaoOpen(true);

        if (type === "editar") {
          services.divisaoProjetoService
            .listarSubDivisoes(Number(idProject), Number(zone.data.id))
            .then((divisoes) => {
              features.addSubGroupsToParent(true, zone.path, divisoes.content);
            });

          if (data?.is_grouping) {
            services.divisaoProjetoService
              .listarQuestoesDivisao(Number(idProject), Number(zone.data.id), 0)
              .then((value) => {
                const questoes = value.content.map((value) => {
                  return {
                    ...value,
                    externalPath: zone.path,
                  };
                });

                questoesCX.setLayout(questoes);
                setTotalPages(value.paginationData.total_number_pages);
                features.setUpdateContent(zone, false);
              });
          }
        } else if (type === "criar") {
          features.setOpen(true, zone.path);
        }
      }
    }
  };

  useEffect(() => {
    if (zone.data) {
      if (zone.data.updateContent) {
        if (type === "editar") {
          if (data?.is_grouping) {
            services.divisaoProjetoService
              .listarQuestoesDivisao(
                Number(idProject),
                Number(zone.data.id),
                currentPage - 1
              )
              .then((value) => {
                const questoes = value.content.map((value) => {
                  return {
                    ...value,
                    externalPath: zone.path,
                  };
                });

                questoesCX.setLayout(questoes);
                setTotalPages(value.paginationData.total_number_pages);
                features.setUpdateContent(zone, false);
              });
          }
        }
      }
    }
  }, [zone.data.updateContent]);

  const setTexto = () => {

    const editarTexto = async (properties: any) => {

      const texto = properties as string;

      setValorParametro({
        id: 0,
        key: "",
        useId: false,
        value: "",
        selected: false,
      });

      setTextoAdicional(texto);

    };

    return editarTexto;
  };

  const showAgrupamentoOptions = (ehAgrupamento: boolean) => {
    return (
      <Fragment>
        {ehAgrupamento && (
          <div className="agrupamento-options">
            <SelectOption
              className="parametro-ordenacao"
              label="Escolher parâmetro"
              onAddFilterItem={(item) =>
                parametrosOrdenacao.addTag({
                  ...item,
                  object: "asc",
                })
              }
              getData={parametrosOrdenacaoQuestao}
            />
            <small>{"Ordenar por: "}</small>
            <FiltrosOrdenacao className="filtros-ordenacao">
              {parametrosOrdenacao.getTags().map((item, index) => {
                return (
                  <FilterTag
                    option={
                      <CheckIcon
                        initialValue={item.object === "desc" ? true : false}
                        checked={HiSortDescending}
                        unchecked={HiSortAscending}
                        onCheck={(value) => {
                          parametrosOrdenacao.setObjectByTagValue(
                            item,
                            value ? "desc" : "asc"
                          );
                        }}
                      />
                    }
                    key={index}
                    nome={item.value}
                    onMouseClick={() => parametrosOrdenacao.removeTag(item)}
                  />
                );
              })}
            </FiltrosOrdenacao>
            <CheckBox
              className={"reiniciar-sequencia"}
              label={"Reiniciar sequência"}
              checked={reiniciarSequencia}
              onChange={(event) =>
                setReiniciarSequencia(event.currentTarget.checked)
              }
            />
          </div>
        )}
      </Fragment>
    );
  };

  const renderParametro = (param: string) => {
    if (param.includes("Orgao")) {
      return (
        <AsyncSelect
          key="orgao"
          className="orgao field-group"
          showSelectItem={true}
          selectItem={valorParametro}
          label="Selecionar orgão"
          onAddFilterItem={(item) =>
            setValorParametro({
              ...item,
              selected: true,
            })
          }
          getDataAPI={(page, value) =>
            services.orgaoService.listarOrgaos(page, 30, value)
          }
        />
      );
    } else if (param.includes("Banca")) {
      return (
        <AsyncSelect
          key="banca"
          className="banca field-group"
          label="Selecionar banca"
          showSelectItem={true}
          selectItem={valorParametro}
          onAddFilterItem={(item) =>
            setValorParametro({
              ...item,
              selected: true,
            })
          }
          getDataAPI={(page, value) =>
            services.bancaService.listarBancas(page, 30, value)
          }
        />
      );
    } else if (param.includes("Cargo")) {
      return (
        <AsyncSelect
          key="cargo"
          className="cargo field-group"
          label="Selecionar cargo"
          selectItem={valorParametro}
          showSelectItem={true}
          onAddFilterItem={(item) =>
            setValorParametro({
              ...item,
              selected: true,
            })
          }
          getDataAPI={(page, value) =>
            services.cargoService.listarCargos(page, 30, value)
          }
        />
      );
    } else if (param.includes("NivelFormacao")) {
      return (
        <NormalSelect
          key="nivel"
          minWidth={64}
          className="nivel field-group"
          label="Selecionar nível"
          selectItem={valorParametro}
          onSelectCurrentItem={(item) => setValorParametro(item)}
          selectedItems={selectNivel}
        />
      );
    } else if (param.includes("AnoProva")) {
      return (
        <NormalSelect
          key="ano"
          minWidth={64}
          className="ano field-group"
          label="Selecionar ano(Prova)"
          selectItem={valorParametro}
          onSelectCurrentItem={(item) => setValorParametro(item)}
          selectedItems={getYears()}
        />
      );
    } else if (param.includes("Disciplina")) {
      return (
        <AsyncSelect
          key="disciplina"
          className="disciplina field-group"
          label="Selecionar disciplina"
          showSelectItem={true}
          selectItem={valorParametro}
          onAddFilterItem={(item) =>
            setValorParametro({
              ...item,
              selected: true,
            })
          }
          getDataAPI={(page, value) =>
            services.disciplinaService.listarDisciplinas(page, 30, value)
          }
        />
      );
    } else if (param.includes("Dificuldade")) {
      return (
        <NormalSelect
          key={"dificuldade"}
          minWidth={64}
          className="dificuldade field-group"
          label="Selecionar dificuldade"
          selectItem={valorParametro}
          onSelectCurrentItem={(item) => setValorParametro(item)}
          selectedItems={dificuldadesOptions}
        />
      );
    } else if (param.includes("Tema")) {
      return (
        <Fragment>
          <AsyncSelect
            key={"disciplina"}
            className="disciplina"
            label="Filtrar por disciplina"
            showSelectItem={true}
            onAddFilterItem={(item) => setDisciplina(item)}
            getDataAPI={(page, value) =>
              services.disciplinaService.listarDisciplinas(page, 30, value)
            }
          />
          <AsyncSelect
            key={"tema"}
            className="tema field-group"
            label="Selecionar tema"
            showSelectItem={true}
            dependency={true}
            secondaryValue={disciplina}
            selectItem={valorParametro}
            onAddFilterItem={(item) =>
              setValorParametro({
                ...item,
                selected: true,
              })
            }
            getDataAPIParam={(page, value, param) =>
              services.temaService.listarTemasByDisciplina(
                page,
                30,
                value,
                param,
                true
              )
            }
          />
        </Fragment>
      );
    } else if (param.includes("FonteJuridica")) {
      return (
        <NormalSelect
          key={"fonte"}
          minWidth={64}
          className="fonte field-group"
          label="Selecionar fonte resposta"
          selectItem={valorParametro}
          onSelectCurrentItem={(item) => setValorParametro(item)}
          selectedItems={fontesOptions}
        />
      );
    }

    return <Fragment />;
  };

  const showCampoParametro = () => {
    return (
      <Fragment>
        <div className="parametro-options">
          {type === "editar" && (
            <NormalSelect
              minWidth={200}
              className="parametro"
              label="Selecione parâmetro"
              selectItem={parametro}
              onSelectCurrentItem={(item) => setParametro(item)}
              selectedItems={paramsOptions}
            />
          )}
          {data &&
            parametro &&
            parametro.id &&
            renderParametro(String(parametro.id))}
          <ButtonBottomLess
            title="Texto"
            className=""
            onClick={() => {
              setAction({
                ...action,
                action: "editar-texto-divisao",
                promisse: setTexto(),
                message: zone.data.data?.additional_text,
              });
            }}
          />
        </div>
      </Fragment>
    );
  };

  const renderTitle = () => {
    let titleNode = <Fragment />;

    if (data?.title) {
      titleNode = (
        <Tag
          divRef={divRef}
          position={"start"}
          classname={"titulo"}
          html={
            data.title && (
              <div
                className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )
          }
        />
      );
    } else if (data?.additional_text) {
      titleNode = (
        <Tag
          divRef={divRef}
          position={"start"}
          classname={"titulo"}
          html={
            data.additional_text ? (
              <div
                className={"ql-editor"}
                dangerouslySetInnerHTML={{
                  __html: data.additional_text,
                }}
              />
            ) : (
              "Escolha um título por metadado ou texto"
            )
          }
        />
      );
    } else {
      titleNode = (
        <Tag
          divRef={divRef}
          position={"start"}
          classname={"titulo"}
          nome={titulo ? titulo : "Escolha um título por metadado ou texto"}
        />
      );
    }

    return titleNode;
  };

  const renderQuestao = (
    dragTarget: React.RefObject<HTMLDivElement>,
    featuresQuestao: LayoutDragFeatures<QuestaoProjetoDivisao>,
    zoneQuestao: DropZoneData<QuestaoProjetoDivisao>,
    data?: QuestaoProjetoDivisao | undefined
  ) => {
    return (
      data &&
      zone && (
        <QuestaoItemLista
          externalAction={externalAction}
          data={data}
          setLoading={(loading) => features.setUpdateContent(zone, loading)}
          dragTarget={dragTarget}
          features={featuresQuestao}
          zone={zoneQuestao}
        />
      )
    );
  };

  const canDropQuestao = async (
    dropZone: DropZoneData<QuestaoProjetoDivisao>,
    item: DropZoneData<QuestaoProjetoDivisao>,
    layout: Array<
      | ParentElement<QuestaoProjetoDivisao>
      | ChildrenElement<QuestaoProjetoDivisao>
    >
  ) => {
    if (dropZone.data === undefined) {
      if (dropZone.isEmpty) {
        setAction({
          ...action,
          action: "loading",
        });

        await services.divisaoProjetoService
          .moverQuestao(
            Number(idProject),
            Number(item.data.id),
            Number(zone.data.id),
            1
          )
          .then(() => {
            features.setUpdateContent(zone, true);

            if (item.data.externalPath) {
              features.setUpdateContentByPath(item.data.externalPath, true);
            }

            setAction({
              ...action,
              action: "",
            });

            return true;
          })
          .catch((error) => {
            alert(error.response.data.message);

            setAction({
              ...action,
              action: "",
            });

            return false;
          });

        return false;
      }
    } else if (dropZone.data.data) {
      setAction({
        ...action,
        action: "loading",
      });

      await services.divisaoProjetoService
        .moverQuestao(
          Number(idProject),
          Number(item.data.id),
          Number(dropZone.data.data.id_division),
          dropZone.data.data.position
        )
        .then(() => {
          features.setUpdateContent(zone, true);

          if (item.data.externalPath) {
            features.setUpdateContentByPath(item.data.externalPath, true);
          }

          setAction({
            ...action,
            action: "",
          });

          return true;
        })
        .catch((error) => {
          alert(error.response.data.message);

          setAction({
            ...action,
            action: "",
          });

          return false;
        });

      return false;
    }

    return false;
  };

  return (
    <Fragment>
      {data && (
        <DivisaoContent>
          <div className={"titulos"}>
            <CollapseButton
              editarOpen={divisaoOpen}
              degreeIcon={180}
              className={"button-option"}
              icon={IoIosArrowDropdownCircle}
              setEditar={divisaoIsOpen}
              type="separator"
              selecionado={opcaoSelecionada}
            />
            <Tag
              colorDark={"color-primary-light"}
              classname={"tipo-titulo"}
              nome={`${data.order} - ${data.level}`}
            />
            {data.is_grouping && (
              <Tag
                colorDark={"color-primary-light"}
                classname={"tipo-conteudo"}
                nome={"Questões"}
              />
            )}
            {dragTarget && (
              <div className="separator">
                <MdOutlineDragIndicator
                  color={"var(--color-primary-light)"}
                  size={25}
                />
              </div>
            )}
            {process.env.NODE_ENV === "development" && <b>{zone.data.id}</b>}
            {renderTitle()}
            <div className="divisao-options">
              <CollapseButton
                className={"button-option"}
                editarOpen={editarOpen}
                label={"Editar separador"}
                setEditar={setEditar}
                type="separator"
                selecionado={opcaoSelecionada}
              />
              {data.is_grouping && (
                <CollapseButton
                  className={"button-option"}
                  label={"Editar grupo"}
                  editarOpen={editarOpen}
                  setEditar={setEditar}
                  selecionado={opcaoSelecionada}
                  type="group"
                />
              )}
            </div>
            <div className="other-options">
              <ThreeNormal
                size={20}
                isLastPosition={true}
                className={""}
                options={[
                  <ThreeNormalOption
                    name="Excluir separador"
                    icon={FcDeleteRow}
                    onClick={excluirSeparadorAPI}
                  />,
                  <ThreeNormalOption
                    name="Excluir grupo"
                    icon={FcDeleteRow}
                    onClick={excluirGrupoAPI}
                  />,
                ]}
              />
            </div>
          </div>
          {editarOpen && (
            <div className="options" ref={divRef}>
              {opcaoSelecionada === "group" &&
                showAgrupamentoOptions(data.is_grouping)}
              {opcaoSelecionada === "separator" && showCampoParametro()}
              {editarOpen && (
                <ButtonBottomLess
                  icon={VscSaveAs}
                  sizeIcon={24}
                  className="salvar-alteracoes"
                  title="Salvar"
                  onClick={() => salvarAlteracoes()}
                />
              )}
            </div>
          )}
          {divisaoOpen && data.is_grouping && (
            <div className="questoes">
              <DragAndDropList
                dragAndDropName={`questoes-${zone.data.id}-${currentPage}`}
                canDropItem={canDropQuestao}
                showBorder={true}
                renderItem={renderQuestao}
                context={questoesCX}
                maxLevel={1}
              />
              {questoesCX.layout.length >= 1 && (
                <PaginationContent>
                  <PaginationDropping<DropZoneData<QuestaoProjetoDivisao>>
                    totalNumberPages={totalPages}
                    isInitialPage={false}
                    onCurrentPage={(current) => {
                      setCurrentPage(current);
                      features.setUpdateContent(zone, true);
                    }}
                  />
                </PaginationContent>
              )}
            </div>
          )}
        </DivisaoContent>
      )}
    </Fragment>
  );
};
