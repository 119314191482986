import styled from 'styled-components';
import { DropdrownContent } from '../styles';

type MainSelectSettings = {
  isOpen?: boolean;
  isLastPosition?: boolean;
  degree?: number;
};

export const MainMenu = styled.div`
  cursor: pointer;
`;

export const MenuHeader = styled.div`
  border-radius: 50%;
  padding: 10px;
  height: 38px;
  background-color: var(--color-primary-light-clear);

  div {
    color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 0.875;
  }

  .select-icon-open {
    transform: ${(props: MainSelectSettings) => (props.isOpen ? `rotate(${props.degree ? props.degree : 90}deg)` : '')};
    color: var(--color-primary-dark);
  }
`;

export const MenuHeaderText = styled(MenuHeader)`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;

  span {
    color: var(--color-primary-darking);
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 2px; 
  }
`;

export const ThreeDotsOptionContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  .item-link {
  }

  .bottom-icon {
    margin-right: 12px;
  }
`;

export const MenuContent = styled(DropdrownContent)`
  min-width: 200px;
  ${(props: MainSelectSettings) =>
    props.isLastPosition
      ? `
          position: absolute;
          right: 20px;
        `
      : `position: absolute
      `}
`;
