import { Fragment } from "react";
import { CardItem } from "../../../../../components/page/structure/ListComponent/list-card/model";
import { Tag } from "../../../../../components/tag";
import { ComentarioDetails } from "../../../../../services/api-services/comentarios";
import { ComentarioValidation, LabelValue } from "../../../elements";
import { BodyStyle, FooterStyle, HeaderStyle } from "../styles";

export interface TComentarioView {
  comentario: ComentarioDetails;
  title: string;
}

export const ComentarioView: React.FC<TComentarioView> = ({
  comentario,
  title,
}) => {
  return (
    <Fragment>
      <CardItem
        settings={{
          width: "98%",
        }}
        header={<HeaderContent title={title} comentario={comentario} />}
        body={<BodyContent comentario={comentario} />}
        options={[]}
        footer={<FooterContent />}
      />
    </Fragment>
  );
};

const HeaderContent: React.FC<{
  comentario: ComentarioDetails;
  title: string;
}> = ({ comentario, title }) => {
  const nomeAutor = comentario.author
    ? comentario.author.name
    : "Não especificado";
  const nomePerfil = comentario.author
    ? comentario.author.name
    : "Não especificado";

  return (
    <HeaderStyle>
      <Tag nome={title} />
      <LabelValue label={"Comentado por"} value={nomeAutor} />
      <LabelValue label={"Perfil de questão"} value={nomePerfil} />
      <ComentarioValidation
        value={comentario.validation}
        classname={"item-margin"}
      />
    </HeaderStyle>
  );
};

const BodyContent: React.FC<{ comentario: ComentarioDetails }> = ({
  comentario,
}) => {
  return (
    <BodyStyle className={"item-margin"}>
      <div className="statement_question">
        <div>
          <div
            className={"ql-editor"}
            dangerouslySetInnerHTML={{
              __html: comentario.comment_content,
            }}
          />
        </div>
      </div>
    </BodyStyle>
  );
};

const FooterContent: React.FC<{}> = (props) => {
  return <FooterStyle className={"item-margin"}></FooterStyle>;
};
