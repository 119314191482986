import styled from "styled-components";
import { SidebarConfig } from "../../../../GlobalStyle";

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 700px) {
    .mobile-hide-background {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 199;
      background-color: rgba(255, 255, 255, 0.8);
      position: fixed;
      cursor: pointer;
    }
  }
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 62px;
  padding-left: ${(c: SidebarConfig) => (c.active ? "260px" : "0px")};

  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;
