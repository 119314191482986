import { VscOpenPreview } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import { LayoutDragFeatures } from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import {
  ThreeNormal,
  ThreeNormalOption
} from "../../../../components/menu/three-normal";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { Tag } from "../../../../components/tag";
import { QuestaoProjetoDivisao } from "../../../../services/api-services/divisoesProjeto";
import {
  Status
} from "../../../Projeto/elements";
import { QuestaoItemContent } from "./styles";

export interface TQuestaoItem {
  dragTarget: React.RefObject<HTMLDivElement>;
  features: LayoutDragFeatures<QuestaoProjetoDivisao>;
  zone: DropZoneData<QuestaoProjetoDivisao>;
  data: QuestaoProjetoDivisao;
  setLoading: (value: boolean) => void;
  externalAction: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const QuestaoItemLista: React.FC<TQuestaoItem> = ({ data, externalAction, setLoading }) => {
  const { question, sequence_number } = data;
  const [action, setAction] = externalAction;

  return (
    <QuestaoItemContent>
      <div className={"titulos"}>
        <Tag
          colorDark={"color-primary-light"}
          classname={"tipo-titulo"}
          nome={`QUESTÃO ${sequence_number} - `}
        />
        <Tag
          colorDark={"color-primary-light"}
          classname={"titulo"}
          nome={question.exam_header}
        />
        <Status status={question.status_question} classname={"item-margin"} />
        <div className="divisao-options">
          <ButtonBottomLess
            className="button-view"
            icon={VscOpenPreview}
            onClick={() => {
              window.open(`/projeto-questoes/${data.id_project}/questao/${data.id}`, "_blank");
            }}
          />
        </div>
        <div className="other-options">
          <ThreeNormal
            size={20}
            isLastPosition={true}
            className={""}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  window.open(`/questao/editar/${question.id}`, "_blank");
                }}
              />,
              <ThreeNormalOption
                name="Excluir do projeto"
                onClick={() => {
                  setAction({
                    ...action,
                    action: "excluir-questao-projeto",
                    id: data.id,
                    promisse: async (properties: any) => {
                      setLoading(true);
                    }
                  })
                }}
              />
            ]}
          />
        </div>
      </div>
      <div className="statement_question">
        <div
          className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: question.statement_question,
          }}
        />
      </div>{" "}
    </QuestaoItemContent>
  );
};

