import styled from "styled-components";

export const ImportacaoBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-width: 1px;
  border-color: var(--color-select-border);
  border-style: solid;
  border-radius: 12px;
  padding: 18px;

  .buttons-options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .loading {
      display: flex;
      flex-direction: row;
      align-items: center;

      small {
        margin-left: 12px;
        margin-right: 12px;
        font-weight: 500;
        font-size: 0.6 rem;
        color: var(--color-text-grey);
      }
    }
  }
`;

export const ImportacaoForm = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px;

  .selecao-arquivo {
    margin-left: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 220px;

    .aviso-file {
      margin-top: 12px;
      font-weight: 500;
      font-size: 0.9 rem;
      color: var(--color-text-grey);
    }

    .nome-arquivo {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
    }

    .importar-button {
      align-items: center;
      width: 100%;
      flex-direction: column;
      border-width: 4px;
      border-color: var(--color-select-border);
      border-style: dashed;
      border-radius: 12px;
      padding: 12px;
      height: 120px;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ListQuestoesImportadasContent = styled.div``;

export const MenuImportacaoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  border-radius: 8px;
  background-color: var(--color-content-light);
  padding: 32px;
  height: 100%;

  .criarProva {
    margin-top: 24px;
    margin-bottom: 14px;
  }

  h4 {
    font-weight: bold;
    color: var(--color-text-dark);
    margin-top: 14px;
    margin-bottom: 14px;
  }

  p {
    font-weight: 500;
    font-size: 0.9 rem;
    color: var(--color-text-grey);
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      display: flex;
      min-width: 320px;
      flex-direction: column;

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        max-width: 100%;
      }
    }
  }
`;
