import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { APIFetch } from '../../../../services/api';
import { useUsuarioService } from '../../../../services/api-services/usuarios';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const ExcluirUsuario: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();
  const usuarioService = useUsuarioService(APIFetch(auth));

  const handleExcluir = () => {
    usuarioService
      .excluirUsuario(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          id: 0,
          action: 'sucesso',
          message: 'Usuário foi excluído com sucesso!',
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          id: 0,
          action: 'erro',
          message: error.response.data.message,
        });
      });
  };

  return (
    <ConfirmationModal
      action={context.currentActionState}
      message={`Tem certeza se deseja excluir o usuário com id #${context.currentAction.id} ?`}
      title={'Excluir usuário'}
      onOptionNot={() =>
        context.changeAction({
          action: '',
          id: 0,
        })
      }
      onOptionYes={handleExcluir}
    />
  );
};
