import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { useDebounce as debounce } from "use-debounce";
import { Input, InputContent } from "./styles";

interface TInputIcon extends InputHTMLAttributes<HTMLInputElement> {
  onClickSearch(): void;
  onChangeValue(value: string): void;
  icon?: React.ComponentType<IconBaseProps>;
}

export const InputIcon: React.FC<TInputIcon> = ({
  onClickSearch,
  onChangeValue,
  icon: Icon,
  ...rest
}) => {
  const [value, setValue] = useState("");
  const [debouncedText] = debounce(value, 500);

  useEffect(() => {
    onChangeValue(debouncedText);
  }, [debouncedText]);

  return (
    <InputContent {...rest}>
      <Input
        type={"text"}
        {...rest}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {Icon && (
        <Icon size={22} className={"bottom-icon"} onClick={onClickSearch} />
      )}
    </InputContent>
  );
};
