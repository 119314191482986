import { ButtonBottomLess } from "jusadmin-ui-kit";
import { Fragment } from "react";
import { FaRegImage } from "react-icons/fa";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { Carreira } from "../../../../services/api-services/carreira";

export const CarreiraHeader = (
  selectedEntitiesAction: SelectedDataActions<Carreira>
) => {
  const CarreiraRow = (elem: ItemDataSelect<Carreira>, index: number) => {

    const carreira = elem.entity as Carreira;
    const context = useGerenciarContext();

    const handleOpenImagem = () => {

      const image = carreira.image;

      if (image) {

        const width = 300;
        const height = 200;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const url = `${process.env.REACT_APP_STORAGE_IMAGE}/career/${carreira.id}/images/${image.id}`;

        const externalWindow = window.open(
          url,
          '_blank',
          `width=${width},height=${height},left=${left},top=${top}`
        );

        if (externalWindow) {
          externalWindow.focus();
        } else {
          alert('Habilite os pop-ups para abrir a janela externa.');
        }

      }

    }

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{carreira.id}</RowItem>
        <RowItem>{carreira.image ?
          <ButtonBottomLess
            className=""
            onClick={handleOpenImagem}
            icon={FaRegImage}
          />
          : <Fragment />}</RowItem>
        <RowItem width="1000px">{carreira.name}</RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar-carreira",
                    id: carreira.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: carreira.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    header: CarreiraRow,
  };
};
