import { AxiosInstance } from "axios";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { Service } from "../../service";
import { PaginationData } from "./../../../components/pagination/types";

export class DisciplinaService extends Service {
  private ENDPOINT = "subject";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemDisciplinas(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<DisciplinasPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let disciplinas = new Array<Disciplina>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        disciplinas = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: disciplinas,
          paginationData: pagination,
        };
      });
  }

  async listarDisciplinas(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Disciplina) =>
          array.push({
            id: value.id,
            value: value.name,
            key: "subject",
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async buscarDisciplina(id: number): Promise<Disciplina> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarDisciplina(disciplina: DisciplinaRequest): Promise<Disciplina> {
    return this.fetch
      .post(this.ENDPOINT, disciplina, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarDisciplina(disciplina: DisciplinaRequest): Promise<Disciplina> {
    return this.fetch
      .put(this.ENDPOINT + `/${disciplina.id}`, disciplina, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirDisciplina(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useDisciplinaService = (axios: AxiosInstance) => {
  return new DisciplinaService(axios);
};

export interface Disciplina {
  id: number;
  name: string;
}

export interface DisciplinaRequest {
  id?: number;
  name: string;
}

export type DisciplinasPagination = {
  content: Array<Disciplina>;
  paginationData: PaginationData;
};

export function disciplinaIsClassificado(disciplina: string) {
  if (!disciplina || disciplina === "N/A") {
    return "Sem classificação";
  }
  return disciplina;
}
