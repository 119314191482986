import { CgCheckO, CgRadioCheck } from "react-icons/cg";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RespostaObjetiva } from "..";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import {
  LeftResposta,
  RespostaRow,
  RightResposta
} from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/styles";

export const RespostaEscolha: React.FC<RespostaObjetiva> = (props) => {
  //
  return (
    <RespostaRow>
      <LeftResposta>
        {props.resposta.anwser_correct.key === "Certo" ? (
          <CgCheckO
            size={22}
            style={{
              color: "var(--color-resposta-correta)",
            }}
          />
        ) : (
          <CgRadioCheck
            size={22}
            style={{
              color: "var(--color-primary-darking)",
            }}
          />
        )}
        <div
          className="content ql-editor"
          dangerouslySetInnerHTML={{
            __html: props.resposta.answer,
          }}
        />
      </LeftResposta>
      <RightResposta>
        <ButtonBottomLess
          className="edit"
          icon={FiEdit2}
          onClick={() => props.handleEditar(props.resposta)}
        />
        <ButtonBottomLess
          className="new-comentarios option-margin"
          icon={MdDelete}
          onClick={() => props.excluirResposta(props.resposta)}
        />
      </RightResposta>
    </RespostaRow>
  );
};
