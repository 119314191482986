import styled from 'styled-components';

type MainSelectSettings = {
  isOpen: boolean;
  minWidth: number;
};

export const MainDropdrown = styled.div`
  position: relative;
`;

export const DropdrownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  position: relative;
  padding: 10px;
  height: 38px;
  background-color: var(--color-content-light);
  border: 2px solid var(--color-select-border);
  ${(props: MainSelectSettings) => (props.minWidth != 0 ? `min-width: ${props.minWidth}px` : '')};

  div {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
  }

  .select-input {
    border-radius: 4px;
    background-color: var(--color-content-light);
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    width: 100%;
  }

  .select-icon-open {
    margin-left: 8px;
    transform: ${(props: MainSelectSettings) => (props.isOpen ? 'rotate(180deg)' : '')};
    color: var(--color-text-secondary);
  }
`;

export const DropdrownContent = styled.div`
  background-color: var(--color-content-light);
  border: 1px solid var(--color-select-border);
  border-radius: 8px;
  box-shadow: 4px 0 5px rgb(0 0 0 / 8%);
  z-index: 100;
  max-height: 220px;
  overflow: auto;
  scroll-behavior: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 4px;
    display: block;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    opacity: 0.6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-select-border);
    border-radius: 20px;
  }
`;

export const DropdrownItem = styled.div`
  h5 {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.875;
    width: 100%;
  }

  :hover {
    background-color: var(--color-background);

    h5 {
      color: var(--color-primary-dark);
    }
  }

  display: flex;
  max-width: 240px;
  flex-direction: row;
  padding: 10px;
  margin: 4px;
`;
