import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { APIFetch } from '../../../../services/api';
import { useProjetoService } from '../../../../services/api-services/projeto';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const ExcluirProjeto: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();
  const projetoService = useProjetoService(APIFetch(auth));

  const handleExcluir = () => {
    projetoService
      .excluirProjeto(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          id: 0,
          action: 'sucesso',
          message: 'Projeto foi excluído com sucesso!',
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          action: 'erro',
          id: 0,
          message: error.response.data.message,
        });
      });
  };

  return (
    <ConfirmationModal
      action={context.currentActionState}
      message={`Tem certeza se deseja excluir o projeto com id #${context.currentAction.id} ?`}
      title={'Excluir usuário'}
      onOptionNot={() =>
        context.changeAction({
          action: '',
          id: 0,
        })
      }
      onOptionYes={handleExcluir}
    />
  );
};
