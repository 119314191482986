import { useState } from "react";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { QuestaoProjetoDivisao } from "../../../../services/api-services/divisoesProjeto";
import { QuestoesProvider } from "./context";

export const QuestoesProviderContent: React.FC<{}> = ({ children }) => {

  const [questoes, setQuestoes] = useState<
    Array<ParentElement<QuestaoProjetoDivisao> | ChildrenElement<QuestaoProjetoDivisao>>
  >([]);

  const [handleDropFeature, setHandleDropFeature] = useState<{
    handleDrop: (dropZone: DropZoneData<QuestaoProjetoDivisao>, item: DropZoneData<QuestaoProjetoDivisao>) => void
  }>();

  const setHandleDropFeatureData = (handleDrop?: (dropZone: DropZoneData<QuestaoProjetoDivisao>, item: DropZoneData<QuestaoProjetoDivisao>) => void) => {

    if (handleDrop) {
      setHandleDropFeature({
        handleDrop: handleDrop
      })
    }

  }

  const setQuestoesData = (
    layout: Array<ParentElement<QuestaoProjetoDivisao> | ChildrenElement<QuestaoProjetoDivisao>>
  ) => {
    setQuestoes(layout);
  };

  return (
    <QuestoesProvider
      value={{
        layout: questoes,
        setLayout: setQuestoesData,
        handleDrop: handleDropFeature ? handleDropFeature.handleDrop : (dropZone, item) => { },
        setHandleDropFeatureData: setHandleDropFeatureData
      }}
    >
      {children}
    </QuestoesProvider>
  );
};
