import React, { InputHTMLAttributes } from "react";
import { Input } from "./normal/styles";

interface Input extends InputHTMLAttributes<HTMLInputElement> {
  minWidth?: string;
}

const style: React.CSSProperties = {
};
export const InputForm: React.FC<Input> = ({ minWidth, ...rest }) => {

  const styleProperties: React.CSSProperties = {
    minWidth: minWidth
  }

  return <Input style={minWidth ? styleProperties : style} type={"text"} {...rest} />;
};
