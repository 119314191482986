import React, { PropsWithChildren } from "react";
import { useDrop } from "react-dnd";
import {
  ChildrenElement,
  ItemTypes,
  ParentElement,
} from "../hooks/useLayoutDragSystem";
import { DropZoneContent } from "./styles";

export interface DropZoneData<T> {
  data: ChildrenElement<T> | ParentElement<T>;
  type: string;
  path: string;
  isDragging?: boolean;
  childrenCount?: number;
  target?: string;
  isInside?: boolean;
  isEmpty?: boolean;
  dragAndDropName: string;
}

export interface TDropZone<T> {
  data: DropZoneData<T>;
  handleDrop: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => void;
  canDropItem?: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => boolean;
  isLast?: boolean;
  isHorizontal?: boolean;
}

export const DropZone = <T extends unknown>({
  data,
  handleDrop,
  canDropItem,
  isHorizontal,
  isLast,
}: PropsWithChildren<TDropZone<T>>) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    drop: (item: DropZoneData<T>, monitor) => {
      handleDrop(data, item);
    },
    canDrop: (item: DropZoneData<T>, monitor) => {
      const dropZonePathArray = data.path.split("-");
      const itemPathArray = item.path.split("-");

      if (
        item.path === data.path &&
        item.dragAndDropName === data.dragAndDropName
      ) {
        return false;
      }

      // Current area
      if (itemPathArray.length === dropZonePathArray.length) {
        const pathToItem = itemPathArray.slice(0, -1).join("-");
        const currentItemIndex = Number(itemPathArray.slice(-1)[0]);

        const pathToDropZone = dropZonePathArray.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(dropZonePathArray.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;

          if (
            nextDropZoneIndex === currentDropZoneIndex &&
            item.dragAndDropName === data.dragAndDropName
          ) {
            return false;
          }
        }
      }

      if (canDropItem) {
        return canDropItem(data, item);
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <DropZoneContent
      ref={drop}
      isActive={isActive}
      isLast={isLast}
      isHorizontal={isHorizontal}
    />
  );
};
