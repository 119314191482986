import React, { Fragment } from 'react';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { StatusComponent } from '../../components/status';
import { CodigoIDContent, LabelContent } from './styles';

export const Status: React.FC<{ status: string; classname?: string }> = ({ status, classname }) => {
  const statusRender = () => {
    if (status === 'Regular') {
      return (
        <StatusComponent
          classname={classname}
          color={'var(--color-status-regular)'}
          icon={BsCheckCircle}
          status={status}
        />
      );
    } else if (status === 'Cancelada') {
      return (
        <StatusComponent
          classname={classname}
          color={'var(--color-status-cancelada)'}
          icon={BsXCircle}
          status={status}
        />
      );
    }
    return (
      <StatusComponent
        classname={classname}
        color={'var(--color-status-desatualizada)'}
        icon={HiOutlineExclamationCircle}
        status={status}
      />
    );
  };

  return <Fragment>{statusRender()}</Fragment>;
};

export const CodigoID: React.FC<{
  title: string;
  codigo: string;
  id: number;
  classname?: string;
}> = (props) => {
  const codigoRender = () => {
    if (props.codigo) {
      return (
        <CodigoIDContent className={props.classname}>
          <span>
            <b>{props.id}</b> -
          </span>
          <span>
            {props.title} #{props.codigo}
          </span>
        </CodigoIDContent>
      );
    }

    return (
      <CodigoIDContent className={props.classname}>
        <span>
          <b>{props.id}</b> -
        </span>
        <span>{props.title}</span>
      </CodigoIDContent>
    );
  };

  return <Fragment>{codigoRender()}</Fragment>;
};

export const ComentarioValidation: React.FC<{
  value: string;
  classname?: string;
}> = (props) => {
  const validationItemRender = () => {
    if (props.value === 'Publicado') {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={FaCheck}
          status={props.value}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={HiOutlineExclamationCircle}
          status={props.value}
        />
      );
    }
  };

  return <Fragment>{validationItemRender()}</Fragment>;
};

export const LabelValue: React.FC<{
  value: string;
  label: string;
  classname?: string;
}> = (props) => {
  return (
    <LabelContent className={props.classname}>
      <label>{props.label}</label>
      <p>{props.value}</p>
    </LabelContent>
  );
};
