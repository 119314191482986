import { useHistory } from "react-router-dom";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { Disciplina } from "../../../../services/api-services/disciplina";
import { ActionOptions } from "../../styles";

export const DisciplinaHeader = (
  selectedEntitiesAction: SelectedDataActions<Disciplina>
) => {
  const RowDisciplina = (elem: ItemDataSelect<Disciplina>, index: number) => {
    const disciplina = elem.entity as Disciplina;
    const context = useGerenciarContext();
    const history = useHistory();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{disciplina.id}</RowItem>
        <RowItem>{disciplina.name}</RowItem>
        <RowItem width="10px">
          <ActionOptions>
            <ThreeDotsLink
              size={18}
              className="list-options"
              isLastPosition={true}
              options={[
                <ThreeNormalOption
                  name="Tags"
                  onClick={() => {
                    history.push(
                      `/disciplina/${elem.entity.id}/gerenciar-tags`
                    );
                  }}
                />,
                <ThreeNormalOption
                  name="Temas"
                  onClick={() => {
                    history.push(
                      `/disciplina/${elem.entity.id}/gerenciar-temas`
                    );
                  }}
                />,
                <ThreeNormalOption
                  name="Editar"
                  onClick={() => {
                    context.changeAction({
                      action: "editar-disciplina",
                      id: disciplina.id,
                    });
                  }}
                />,
                <ThreeNormalOption
                  name="Excluir"
                  onClick={() => {
                    context.changeAction({
                      action: "excluir-disciplina",
                      id: disciplina.id,
                    });
                  }}
                />,
              ]}
            />
          </ActionOptions>
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    header: RowDisciplina,
  };
};
