import { createContext, useContext } from "react";
import { TDragContext } from "../../../../components/drag-and-drop";
import {
  ChildrenElement,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { SelectedTagActions } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { DivisaoData } from "../../../../services/api-services/divisoesProjeto";

export interface TNivel {
  nome: string;
  ordem: number;
  qtd: number;
  agrupar: boolean;
  fonte: string;
}

export interface DivisoesProjetoContext extends TDragContext<DivisaoData> {
  nivel: number;
  parametrosOrdenacao: SelectedTagActions;
  modoGeracao: number;
  niveis: Array<TNivel>;
  callbacks: {
    setModoGeracao: (value: number) => void;
    setNivel: (value: number) => void;
    setNiveis: (value: TNivel[]) => void;
  };
}

const initialState: DivisoesProjetoContext = {
  nivel: 0,
  modoGeracao: 0,
  parametrosOrdenacao: {
    addTagValue: () => { },
    addAllTags: () => { },
    addTag: () => { },
    setTagValue: () => { },
    getFilter: () => [],
    getFilters: () => [],
    getTags: () => [],
    getTagsString: () => "",
    removeAllTags: () => { },
    removeTag: () => { },
    setInitialValues: () => { },
    setObjectByTagValue: () => { }
  },
  niveis: [],
  layout: [],
  setLayout: (
    layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  ) => { },
  callbacks: {
    setModoGeracao: (value) => { },
    setNiveis: (value: TNivel[]) => { },
    setNivel: (value: number) => { },
  },
  handleDrop: (drop, item) => { },
  setHandleDropFeatureData: (handle) => { },
};

export const context = createContext(initialState);

export const DivisoesProjetoCXProvider = context.Provider;
export const DivisoesProjetoConsumer = context.Consumer;

export const useDivisoesProjetoContext = () => useContext(context);
