import { Fragment, useEffect, useState } from "react";
import { FaRegComments } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { GroupButtons, TButtonSelect } from "../../../../components/group-buttons";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../../../components/page/structure/BaseComponent";
import { PaginationData } from "../../../../components/pagination/types";
import { APIFetch } from "../../../../services/api";
import { Comentario } from "../../../../services/api-services/comentarios";
import { useComentarioProjetoService } from "../../../../services/api-services/comentariosProjeto";
import { ProjetoDetails } from "../../../../services/api-services/projeto";
import {
  QuestaoDetails,
  RespostaDetails,
  useQuestaoService
} from "../../../../services/api-services/questao";
import { useQuestaoProjetoService } from "../../../../services/api-services/questaoProjeto";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ComentarQuestao } from "../../../ComentarQuestao";
import { RespostaEnunciado } from "../../../GerenciarQuestoes/views/visualizar-questao/alternativas/enunciado";
import { Apoios } from "../../../GerenciarQuestoes/views/visualizar-questao/apoios";
import { ListBaseComentarios } from "../../../GerenciarQuestoes/views/visualizar-questao/comentarios";
import { Enunciado } from "../../../GerenciarQuestoes/views/visualizar-questao/enunciado";
import { ReferenciasLegaisViewDetails } from "../../../GerenciarQuestoes/views/visualizar-questao/referencias-legais";
import {
  ComentarioOptions,
  QuestaoViewContainer, VisualizarQuestaoContent
} from "../../../GerenciarQuestoes/views/visualizar-questao/styles";
import { Alternativas } from "./alternativas";

interface Props {
  idProjeto: string;
  idQuestao: string;
}

export function VisualizarQuestaoProjeto() {
  const actionState = useState<ActionType>({
    action: "enunciado",
    id: 3,
  });

  const modalState = useState<ActionType>({
    action: "",
    id: 0,
    loading: false,
  });

  const [modal, setModal] = modalState;

  const auth = useAuthContext();
  const questaoProjetoService = useQuestaoProjetoService(APIFetch(auth));
  const comentarioService = useComentarioProjetoService(APIFetch(auth));
  const questaoService = useQuestaoService(APIFetch(auth));

  const action = actionState[0];
  const setAction = actionState[1];

  const { idQuestao, idProjeto } = useParams<Props>();

  const [showComentarios, setShowComentarios] = useState(false);

  const answers = new Map<string, RespostaDetails[]>();

  const [questao, setQuestao] = useState<QuestaoDetails>({
    answer_type: "",
    legal_references: [],
    sources: [],
    nature: "",
    supports: [],
    statement_question: "",
    status: "",
    subject: {
      id: 0,
      name: "",
    },
    topic: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    tag: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    type: "",
    validation: "",
    id: 0,
    answers: answers,
    cod: "",
    correct: "",
    difficulty: "",
    exam_header: {
      id: 0,
      exam_name: "",
      oab_exam_number: "",
      body: {
        abbreviation: "",
        id: 0,
        name: "",
      },
      exam_board: {
        id: 0,
        name: "",
      },
      level: "",
      office: {
        id: 0,
        name: "",
      },
      year: 0,
      career: {
        id: 0,
        name: ""
      }
    },
    exam_disc_active: false,
    exam_discipline: ""
  });

  const [projeto, setProjeto] = useState<Array<ProjetoDetails>>([]);

  const [comentarios, setComentarios] = useState<Array<Comentario>>([]);

  const loadingState = useState(false);

  const [loading, setLoading] = loadingState;

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  const [validacao, setValidacao] = useState("");

  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 0,
    total_items: 0,
    total_number_pages: 0,
  });

  useEffect(() => {
    if (loading) {
      comentarioService
        .listarComentarioProjetosByQuestao(
          currentPage - 1,
          search,
          parseInt(idQuestao),
          validacao
        )
        .then((comentario) => {
          setComentarios(comentario.content.map((item) => item.comment));
          setPaginationData(comentario.paginationData);
          setLoading(false);
        });
    }
  }, [loading]);

  useEffect(() => {
    if (showComentarios) {
      if (modal.loading) {
        setLoading(true);
        setModal({
          ...modal,
          loading: false,
        });
      }
    }
  }, [modal]);

  useEffect(() => {
    questaoProjetoService.questaoProjeto
      .buscarQuestaoProjeto(parseInt(idQuestao))
      .then((ele) => {
        setQuestao(ele.question);
      });
  }, []);

  useEffect(() => {
    if (questao) {
      questaoService
        .listarProjetosVinculadosQuestao(questao.id)
        .then((projetos) => {
          setProjeto(projetos);
        });
    }
  }, [questao]);

  const modals = (action: ActionType) => {
    switch (action.action) {
      case "editar-comentario": {
        return (
          <ComentarQuestao action={modalState} edit={true} type="enunciado" />
        );
      }
    }
  };

  const onEditComentario = (idComentario: number, idResposta: number) => {
    if (questao) {
      setModal({
        id: 0,
        action: "editar-comentario",
        object: {
          idQuestao: questao.id,
          idComentario: idComentario,
          idResposta: idResposta,
        },
      });
    }
  };

  const options = (action: ActionType) => {
    switch (action.action) {
      case "referencias-legais": {
        return <ReferenciasLegaisViewDetails referenciasDetails={questao.legal_references} />
      }
      case "apoios": {
        return <Apoios questao={questao} />;
      }
      case "alternativas": {
        return (
          <Alternativas
            questao={questao}
            idQuestao={parseInt(idQuestao)}
            comentarioService={comentarioService}
          />
        );
      }
      case "enunciado": {
        return (
          <Fragment>
            <QuestaoViewContainer>
              <Enunciado questao={questao} projetos={projeto} />
              {questao.answer_type === "Única" && (
                <RespostaEnunciado correta={questao.correct} />
              )}
              <ComentarioOptions>
                <ButtonBottomLess
                  className="open-comentarios option-margin"
                  title="Comentários do projeto"
                  icon={FaRegComments}
                  onClick={() => {
                    setLoading(true);
                    setShowComentarios(showComentarios ? false : true);
                  }}
                />
              </ComentarioOptions>
            </QuestaoViewContainer>
            {showComentarios && (
              <ListBaseComentarios
                title={action.message ? action.message : "Comentários"}
                comentarios={comentarios}
                idQuestao={parseInt(idQuestao)}
                paginacaoData={paginationData}
                callback={{
                  onCurrentPage: (page) => {
                    setCurrentPage(page);
                    setLoading(true);
                  },
                  onSearchPage: (search) => {
                    setSearch(search);
                    setLoading(true);
                  },
                  onValidacao: (validacao) => {
                    setValidacao(validacao);
                    setLoading(true);
                  },
                  onEditComentario: onEditComentario,
                }}
              />
            )}
          </Fragment>
        );
      }
    }
  };

  const buttonGroupRender = () => {
    const comRespostas: TButtonSelect[] = [
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 1,
          });
        },
        name: "enunciado",
        classname: "status-option",
        value: "enunciado",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 2,
          });
        },
        name: "referências legais",
        classname: "status-option",
        value: "referencias-legais",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 3,
          });
        },
        name: "alternativas",
        classname: "status-option",
        value: "alternativas",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 4,
          });
        },
        name: "textos de apoio",
        classname: "status-option",
        value: "apoios",
      },
    ];

    const semRespostas: TButtonSelect[] = [
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 1,
          });
        },
        name: "enunciado",
        classname: "status-option",
        value: "enunciado",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 2,
          });
        },
        name: "referências legais",
        classname: "status-option",
        value: "referencias-legais",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 3,
          });
        },
        name: "textos de apoio",
        classname: "status-option",
        value: "apoios",
      },
    ];

    if (questao?.answer_type === "Única") {
      return semRespostas;
    }

    return comRespostas;
  };

  return (
    <BaseComponent pageName={`Questão`} headerRight={<GroupButtons
      initialPositionSelected={0}
      buttons={buttonGroupRender()}
    />}>
      <VisualizarQuestaoContent>{options(action)}</VisualizarQuestaoContent>
      {modals(modal)}
    </BaseComponent>
  );
}
