import Loader from "react-loader-spinner";
import { LoadingContent } from "../../styles";

export const LoadingComponent = () => {
    return (
        <LoadingContent>
            <div className="loading">
                <Loader
                    type="Oval"
                    color="var(--color-primary-dark)"
                    height={70}
                    secondaryColor="var(--color-primary-dark)"
                    radius={12}
                    width={70}
                    timeout={10000000000000}
                />
                <small>Salvando alterações...</small>
            </div>
        </LoadingContent>);
}