import styled from "styled-components";

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
  width: 100%;

  .item-margin {
    margin: 12px;
  }

  .header-prova{
      width: 90%;
      border: 4px solid var(--color-primary-light-clear);
      padding: 8px;
      background-color: var(--color-primary-clear);
      border-radius: 8px;
  }
`;


export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 18px;
  background-color: var(--color-primary-clear);
  border-radius: 12px;
  
  .item-margin {
    margin: 12px;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const FooterLeft = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 8px;
`;
