import styled from "styled-components";

interface ButtonGroupSettings {
  active: boolean;
  minWidth: number;
}

export const ButtonGroupContent = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 8px 12px 8px 12px;
  transition: background-color 0.2s;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  min-width: ${(option: ButtonGroupSettings) =>
    option.minWidth ? `${option.minWidth}px` : "80px"};
  justify-content: center;
  margin: 4px;

  .icon {
    margin: 2px;
  }

  ${(option: ButtonGroupSettings) =>
    option.active
      ? `
        background-color: var(--color-primary-dark);
        color: var(--color-content-light);
        border: 1px solid var(--color-content-border);


        :hover {
          background-color: var(--color-primary-darking);
        }
        `
      : `
          background-color: var(--color-primary-clear);
          color: var(--color-primary-dark);
          border: 2px solid var(--color-content-border);

        :hover {
          color: var(--color-primary-darking);
        }
      `}
`;
