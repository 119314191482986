import { AxiosInstance } from "axios";
import { Service } from "../../service";
import { PaginationData } from "./../../../components/pagination/types";

export class IndicacaoFonteRespostaService extends Service {
  private ENDPOINT = "source-indication";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listar(
    page: number,
    qtd: string,
    appliedIndication: string,
    period: string,
    sort: string,
    order: string
  ): Promise<IndicacaoFonteRespostaPagination> {
    const query = `?search=appliedIndication:'${appliedIndication}';period:'${period}'&page=${page}&size=${qtd}&order=${order}&sort=${sort}`;

    return this.fetch.get(this.ENDPOINT + query).then((response) => {
      let data = new Array<IndicacaoFonteRespostaDetails>();
      let pagination = {
        current_page: 0,
        total_items: 0,
        total_number_pages: 1,
      };

      data = response.data.content;
      pagination = {
        total_items: response.data.total_items,
        current_page: response.data.current_page,
        total_number_pages: response.data.total_number_pages,
      };

      return {
        content: data,
        paginationData: pagination,
      };
    });
  }

  async aprovarIndicacao(idIndicacao: number): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/${idIndicacao}/approve`);
  }

  async reprovarIndicacao(idIndicacao: number): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/${idIndicacao}/disapproved`);
  }
}

export const useIndicacaoFonteRespostaService = (axios: AxiosInstance) => {
  return new IndicacaoFonteRespostaService(axios);
};

export type IndicacaoFonteRespostaPagination = {
  content: Array<IndicacaoFonteRespostaDetails>;
  paginationData: PaginationData;
};

export interface IndicacaoFonteRespostaDetails {
  id: number;
  question_id: number;
  student_nickname: string;
  indication_date: string;
  source_response: FonteRespostaDetailsRest;
  status_indication: string;
}

export interface FonteRespostaDetailsRest {
  lawTypeId: number;
  lawType: string;
  law: string;
  article: string;
  paragraph: string;
  clause: string;
  subClause: string;
  item: string;
  legalSource: string;
}
