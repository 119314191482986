import React, { Fragment, isValidElement, useEffect, useState } from "react";
import { BsCardText, BsTable } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { HiOutlineFilter } from "react-icons/hi";
import { MdClearAll } from "react-icons/md";
import { VscClearAll } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { qtdOptions, sortOptions } from "../../../../mocks/data";
import { ButtonBottomLess } from "../../../button/bottomless";
import { ButtonNormal } from "../../../button/normal";
import { FilterTag } from "../../../filter-tag";
import { GroupButtons, TButtonSelect } from "../../../group-buttons";
import { InputIcon } from "../../../input/input-icon";
import { ThreeDotsLink } from "../../../menu/three-dots";
import { NormalSelect, TItemSelect } from "../../../select/normal";
import { TitleIcon } from "../../../title-icon";
import {
  FilterBar,
  FilterContent,
  FilterOption,
  FilterSort,
  Filters,
  ListContent,
  ListManagerContent,
  MenuFilter,
  PesquisaOptions
} from "./styles";
import { ItemSelect, SortItem } from "./types";

type TFilterComponent = {
  showMode: boolean;
  mode?: string;
  showFilters: boolean;
  showSearch: boolean;
  options: Array<React.ReactElement<Link>>;
  sort: {
    params: SortItem[];
  };
  searchOptions?: React.ReactNode;
  searchPlaceHolder: string;
  filters: React.ReactNode;
  filterItems?: Array<ItemSelect>;
  totalSelected: number;
  removeFilterItem?: (item: ItemSelect) => void;
  handleQtdItens: (qtd: string) => void;
  handleSearchValue: (text: string) => void;
  handleSearchClick: () => void;
  handleClickFilter: () => void;
  handleClearFilter: () => void;
  handleClearSelectedItens: () => void;
  onCurrentVisualizationMode?: (current: TButtonSelect) => void;
  onCurrentOrderParam: (order: string) => void;
  onCurrentSortParam: (item: string) => void;
};

export const FilterComponent: React.FC<TFilterComponent> = (props) => {
  const [params, setParams] = useState<TItemSelect[]>([]);

  useEffect(() => {
    props.sort.params.map((key, index) => {
      params.push({
        id: index,
        key: key.entity,
        selected: false,
        value: key.value,
        useId: false,
      });
    });
  }, []);

  const isFragment = (element: React.ReactNode) => {
    return isValidElement(element) && element.type !== Fragment;
  };

  return (
    <ListManagerContent>
      <FilterContent>
        {props.showSearch && (
          <div className={"search-content"}>
            <InputIcon
              onChangeValue={props.handleSearchValue}
              onClickSearch={props.handleSearchClick}
              icon={FaSearch}
              className={"search-input"}
              placeholder={props.searchPlaceHolder}
            />
            {props.searchOptions && (
              <PesquisaOptions>{props.searchOptions}</PesquisaOptions>
            )}
          </div>)
        }
        {props.showFilters && props.filters && isFragment(props.filters) && (
          <FilterBar>
            <TitleIcon
              name={"Filtros"}
              icon={HiOutlineFilter}
            />
          </FilterBar>
        )}
        {props.showFilters && props.filters}
        <MenuFilter showFilters={props.showFilters}>
          <section>
            <div className={"left-menu-filter"}>
            </div>
            {props.showFilters && (
              <div className={"right-menu-filter"}>
                {(isFragment(props.filters) && <Fragment>
                  <div className="selected-items-label">
                    <div className="qtd-selected-items">
                      <small>
                        <b>{props.totalSelected}</b>
                      </small>
                    </div>
                    <div className="selected-items-text">itens selecionados</div>
                  </div>
                  <ButtonBottomLess
                    className={"clear-select"}
                    title="Limpar seleção"
                    onClick={props.handleClearSelectedItens}
                    icon={VscClearAll}
                  />
                </Fragment>)}
                {props.options.length > 0 && (
                  <ThreeDotsLink
                    size={22}
                    className="list-options"
                    isLastPosition={true}
                    options={props.options}
                  />
                )}
              </div>
            )}
          </section>
          <section>
            {props.showFilters && (
              <FilterSort>
                <small>Mostrar: </small>
                <NormalSelect
                  minWidth={64}
                  selectItem={qtdOptions[0]}
                  className="qtd-select"
                  onSelectCurrentItem={(item) =>
                    props.handleQtdItens(item.value)
                  }
                  selectedItems={qtdOptions}
                />
                <small>Ordenar por: </small>
                <NormalSelect
                  minWidth={84}
                  className="margin-filter"
                  selectItem={params[0]}
                  onSelectCurrentItem={(item) =>
                    props.onCurrentSortParam(item.key)
                  }
                  selectedItems={params}
                />
                <NormalSelect
                  minWidth={84}
                  className="margin-filter"
                  selectItem={sortOptions[0]}
                  onSelectCurrentItem={(item) =>
                    props.onCurrentOrderParam(item.key)
                  }
                  selectedItems={sortOptions}
                />
              </FilterSort>
            )}
            {props.showFilters && (
              <Filters>
                {props.filterItems &&
                  props.filterItems.map((item, index) => {
                    return (
                      <FilterTag
                        key={index}
                        nome={item.value}
                        render={item.render}
                        itemSelect={item}
                        onMouseClick={() =>
                          props.removeFilterItem && props.removeFilterItem(item)
                        }
                      />
                    );
                  })}
              </Filters>
            )}
            {props.showFilters && (
              <FilterOption>
                <section>
                  {props.showMode && (
                    <GroupButtons
                      onCurrentButtonSelected={props.onCurrentVisualizationMode}
                      initialPositionSelected={props.mode === "item" ? 1 : 0}
                      buttons={[
                        {
                          icon: BsTable,
                          onClick: () => { },
                          classname: "active-table-mode",
                          minWidth: 50,
                          value: "header",
                        },
                        {
                          icon: BsCardText,
                          onClick: () => { },
                          classname: "active-card-mode",
                          minWidth: 50,
                          value: "item",
                        },
                      ]}
                    />
                  )}
                </section>
                {props.filterItems && (
                  <section>
                    <ButtonBottomLess
                      className={"clear-filters"}
                      title="Limpar"
                      icon={MdClearAll}
                      onClick={props.handleClearFilter}
                    />
                    <ButtonNormal
                      title={"Filtrar"}
                      className={"filtro-button"}
                      onClick={props.handleClickFilter}
                    />
                  </section>
                )}
              </FilterOption>
            )}
          </section>
        </MenuFilter>
      </FilterContent>
      <ListContent>{props.children}</ListContent>
    </ListManagerContent>
  );
};
