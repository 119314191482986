import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { autorOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { Autor, useAutorService } from "../../services/api-services/autores";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarAutor } from "./forms/criar-autor";
import { ExcluirAutor } from "./forms/excluir-autor";
import { AutorHeader } from "./views/row-autor";

const GerenciarAutoresComponent = () => {
  //
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const autorService = useAutorService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const autorState = useState<Array<ItemDataSelect<Autor>>>([]);
  const setAutores = autorState[1];

  const selectedEntitiesAction = useSelectedDataAction<Autor>({
    state: autorState,
  });

  useEffect(() => {
    if (context.loading) {
      autorService
        .listagemAutores(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((autores) => {
          setAutores(
            autores.content.map((value: Autor) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(autores.paginationData);
          context.toPage(autores.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo Autor"}
          className={"Autor-button"}
          onClick={() => {
            context.changeAction({
              id: 0,
              action: "criar",
            });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirAutor />;
      }
      case "criar": {
        return <CriarAutor />;
      }
      case "editar": {
        return <CriarAutor />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() => {
              context.changeAction({
                id: 0,
                action: "",
              });
            }}
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                id: 0,
                action: "",
              })
            }
          />
        );
      }
    }
  };
  return (
    <Fragment>
      <GerenciarPageBase<Autor>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={autorState}
        page={{
          name: "Listagem de Autores",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do autor",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: autorOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [<div>ID</div>, <div>Nome</div>, <div>Ações</div>],
            dataColumn: AutorHeader(selectedEntitiesAction).header,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarAutores = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarAutoresComponent />
    </GerenciarBaseProvider>
  );
};
