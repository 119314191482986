import { Fragment, useEffect, useState } from "react";
import { BiExport } from "react-icons/bi";
import { BsSortUpAlt } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { HiOutlineCheckCircle, HiOutlineFilter } from "react-icons/hi";
import { MdManageSearch } from "react-icons/md";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckBox } from "../../../../components/checkbox";
import { DragAndDropList } from "../../../../components/drag-and-drop";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  LayoutDragFeatures,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { FilterTag } from "../../../../components/filter-tag";
import {
  GroupButtons,
  TButtonSelect
} from "../../../../components/group-buttons";
import { InputIcon } from "../../../../components/input/input-icon";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { useTagAction } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../../../components/page/structure/FilterComponent/types";
import { GroupMain } from "../../../../components/page/structure/Group/styles";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
  ListCard,
  PaginationContent
} from "../../../../components/page/structure/ListComponent/styles";
import { Pagination } from "../../../../components/pagination";
import { ModalAuthRoute } from "../../../../components/router/ModalAuthRoute";
import { AsyncSelect } from "../../../../components/select/async";
import { SubMenuTitle } from "../../../../components/sub-menu-title";
import { APIFetch } from "../../../../services/api";
import { useAutorService } from "../../../../services/api-services/autores";
import {
  DivisaoData,
  useDivisaoProjeto
} from "../../../../services/api-services/divisoesProjeto";
import { usePerfilQuestoesService } from "../../../../services/api-services/perfilQuestoes";
import { useProjetoService } from "../../../../services/api-services/projeto";
import { Questao } from "../../../../services/api-services/questao";
import { useQuestaoProjetoService } from "../../../../services/api-services/questaoProjeto";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { DivisaoOption } from "../../../CriarProjeto/components/divisao-option";
import { useDivisoesProjetoContext } from "../../../CriarProjeto/forms/assistente-estrutura/context";
import {
  AddQuestaoProjetoContent,
  ComentariosFiltrosContent,
  Filtros,
  ListarProjetosContent,
  MainContentAddQuestaoProjeto,
  Options,
  ProjetoFooterContent,
  ProjetoItemContent
} from "./styles";

export const AdicionarQuestoesProjeto: React.FC<{
  ids: ItemDataSelect<Questao>[];
}> = (props) => {
  //
  const tabActionsState = useState<ActionType>({
    action: "projeto",
    id: 3,
  });

  const assistenteContext = useDivisoesProjetoContext();

  const context = useGerenciarContext();

  const auth = useAuthContext();

  const axiosInstance = APIFetch(auth);

  const useTagActions = useTagAction();

  const [tabActions, setTabActions] = tabActionsState;

  const questaoService = useQuestaoProjetoService(axiosInstance);

  const divisaoService = useDivisaoProjeto(axiosInstance);

  const projetoService = useProjetoService(axiosInstance);

  const autorService = useAutorService(axiosInstance);

  const perfilQuestoesService = usePerfilQuestoesService(axiosInstance);

  const [search_key, setSearch_key] = useState("");

  const [totalPages, setTotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const loadingState = useState(false);

  const loading = loadingState[0];
  const setLoading = loadingState[1];

  const provaState = useState<Array<ItemSelect>>([]);
  const setProvas = provaState[1];

  const [selectedProjeto, setSelectedProjeto] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const [divisaoEscolhida, setDivisaoEscolhida] = useState<{
    id: number;
    title: string;
    value?: DropZoneData<DivisaoData>;
  }>();

  const [ordenarQuestoes, setOrdenarQuestoes] = useState<boolean>(true);

  const initialOptions: TButtonSelect[] = [
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 1,
        });
      },
      name: "Projeto",
      classname: "status-option",
      value: "projeto",
    },
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 2,
        });
      },
      name: "Comentários",
      classname: "status-option",
      value: "comentarios",
    },

  ];

  const comDivisoesOptions: TButtonSelect[] = [
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 1,
        });
      },
      name: "Projeto",
      classname: "status-option",
      value: "projeto",
    },
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 2,
        });
      },
      name: "Comentários",
      classname: "status-option",
      value: "comentarios",
    },
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 3,
        });
      },
      name: "Configuração de importação",
      classname: "status-option",
      value: "configuracao-exportacao",
    },
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 4,
        });
      },
      name: "Escolher grupo - (Opcional)",
      classname: "status-option",
      value: "divisao",
    }
  ];

  useEffect(() => {
    if (selectedProjeto.id !== 0) {
      setDivisaoEscolhida({
        id: 0,
        title: "",
        value: undefined,
      });

      divisaoService
        .listarDivisoes(Number(selectedProjeto.id))
        .then((value) => {
          assistenteContext.setLayout(value.content);
        });
    }
  }, [selectedProjeto]);

  useEffect(() => {
    if (loading) {
      projetoService
        .listarProjetos(currentPage - 1, 5, search_key)
        .then((projetos) => {
          setProvas(
            projetos.content.map((value) => {
              return value;
            })
          );

          setTotalPages(projetos.total_number_pages);
          setLoading(false);
        });
    }
  }, [loading]);

  const exportarQuestoes = () => {
    if (selectedProjeto.id && divisaoEscolhida) {
      questaoService.exportarQuestaoParaProjeto
        .exportarQuestoesParaProjeto(
          props.ids.map((ele) => ele.id_item),
          selectedProjeto.id,
          divisaoEscolhida.id,
          useTagActions.getFilters(["authorName", "profileName"]), ordenarQuestoes
        )
        .then(() => {
          context.changeAction({
            id: 0,
            action: "",
            loading: true,
          });
          alert("Questões selecionadas foram exportadas com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const ConfiguracaoExportacao = () => {
    return (
      <div>
        <SubMenuTitle
          title={`Configuração de ordenação`}
          subTitle="Marque se ao final da importação as questões dos agrupamentos relacionados devem ser ordenadas"
          icon={BsSortUpAlt}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <CheckBox
            label={"Deve ordenar questões"}
            className={"ordenar-questoes field-group"}
            checked={ordenarQuestoes}
            onChange={(event) => {
              setOrdenarQuestoes(event.currentTarget.checked)
            }}
          />
        </SubMenuTitle>

      </div>
    );
  };

  const ComentariosFiltros = () => {
    return (
      <ComentariosFiltrosContent>
        <SubMenuTitle
          title="Filtrar comentários"
          subTitle="Selecione por meio dos filtros quais comentários serão importados"
          icon={HiOutlineFilter}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <GroupMain>
            <AsyncSelect
              className="perfil field-group"
              label="Filtrar por perfil de questão"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                perfilQuestoesService.listarPerfils(page, 30, value)
              }
            />
            <AsyncSelect
              className="autor field-group"
              label="Filtrar por autor"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                autorService.listarAutores(page, 30, value)
              }
            />
          </GroupMain>
        </SubMenuTitle>
      </ComentariosFiltrosContent>
    );
  };

  const EscolhaProjeto = () => {
    return (
      <ListarProjetosContent>
        <SubMenuTitle
          title={`Procure um projeto a qual deseja importar as questões e selecione:`}
          subContent={
            <InputIcon
              onChangeValue={(value) => {
                setSearch_key(value);
                setLoading(true);
              }}
              onClickSearch={() => { }}
              icon={FaSearch}
              className={"search"}
              placeholder={"Pesquisar por nome..."}
            />
          }
          icon={MdManageSearch}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <ListCard className="list-projetos">
            {provaState[0].map((item, index) => {
              return (
                <ProjetoItemContent
                  key={index}
                  onClick={() => {
                    setSelectedProjeto(item);
                  }}
                >
                  <p>{item.value}</p>
                  {item.id == selectedProjeto.id && (
                    <HiOutlineCheckCircle
                      size={24}
                      color={"var(--color-primary-darking)"}
                    />
                  )}
                </ProjetoItemContent>
              );
            })}
          </ListCard>
          <PaginationContent className="pagination">
            <Pagination
              totalNumberPages={totalPages}
              isInitialPage={false}
              onCurrentPage={(current) => {
                setCurrentPage(current);
                setLoading(true);
              }}
            />
          </PaginationContent>
        </SubMenuTitle>


      </ListarProjetosContent>
    );
  };

  const EscolherDivisao = () => {
    const canDropItem = async (
      dropZone: DropZoneData<DivisaoData>,
      item: DropZoneData<DivisaoData>,
      layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
    ) => {
      return false;
    };

    const renderDivisao = (
      dragTarget: React.RefObject<HTMLDivElement>,
      features: LayoutDragFeatures<DivisaoData>,
      zone: DropZoneData<DivisaoData>,
      data?: DivisaoData | undefined
    ) => {
      return (
        selectedProjeto.id != 0 && (
          <DivisaoOption
            type="editar"
            idProject={selectedProjeto.id}
            externalAction={context.currentActionState}
            selectedDivisao={divisaoEscolhida?.value}
            onSelectedDivisao={(value) => {
              if (value.data.data) {
                const { title, order, level } = value.data.data;
                setDivisaoEscolhida({
                  id: Number(value.data.id),
                  title: title ? title : `${order} - ${level}`,
                  value: value,
                });
              }
            }}
            zone={zone}
            data={data}
            authInstance={axiosInstance}
            features={features}
          />
        )
      );
    };

    return (
      selectedProjeto.id != 0 && (
        <div className="divisoes">
          <DragAndDropList
            dragAndDropName={`listaDivisoesEscolha`}
            showBorder={true}
            renderItem={renderDivisao}
            context={assistenteContext}
            canDropItem={canDropItem}
            maxLevel={4}
          />
        </div>
      )
    );
  };

  const Footer = () => {
    return (
      <ProjetoFooterContent>
        {
          <Fragment>
            <div>
              {selectedProjeto.id != 0 && (
                <p className={"p-style"}>
                  Você selecionou o projeto:&nbsp;&nbsp;
                  <small>{selectedProjeto.value}</small>
                  {divisaoEscolhida && (
                    <small>{` / ${divisaoEscolhida.title}`}</small>
                  )}
                </p>
              )}
            </div>
            <Filtros>
              {useTagActions.getTags().map((item, index) => {
                return (
                  <FilterTag
                    key={index}
                    nome={item.value}
                    onMouseClick={() => useTagActions.removeTag(item)}
                  />
                );
              })}
            </Filtros>
            {selectedProjeto.id != 0 && (
              <ButtonBottomLess
                className="save"
                title="Importar para projeto"
                icon={BiExport}
                onClick={() => {
                  exportarQuestoes();
                }}
              />
            )}
          </Fragment>
        }
      </ProjetoFooterContent>
    );
  };

  const options = (action: ActionType) => {
    switch (action.action) {
      case "comentarios": {
        return ComentariosFiltros();
      }
      case "projeto": {
        return EscolhaProjeto();
      }
      case "divisao": {
        return EscolherDivisao();
      }
      case "configuracao-exportacao": {
        return ConfiguracaoExportacao();
      }
    }
  };

  return (
    <ModalAuthRoute
      handleUnauthorization={() => {
        context.changeAction({
          id: 0,
          action: "erro",
          message: "Você não tem permissão para acessar a funcionalidade!",
        });
      }}
      permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
      roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
    >
      <Modal
        action={context.currentActionState}
        title={"Importar questões para o projeto"}
        options={Footer()}
      >
        <MainContentAddQuestaoProjeto>
          <Options>
            <GroupButtons
              initialPositionSelected={0}
              buttons={
                selectedProjeto.id !== 0 ? comDivisoesOptions : initialOptions
              }
            />
          </Options>
          <AddQuestaoProjetoContent>
            {options(tabActions)}
          </AddQuestaoProjetoContent>
        </MainContentAddQuestaoProjeto>
      </Modal>
    </ModalAuthRoute>
  );
};
