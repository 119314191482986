import { AxiosInstance } from "axios";
import { MesclagemResponse } from "../../../components/app/mesclar-entidade";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { ImageInfoDetails, Service } from "../../service";

export class OrgaoService extends Service {
  private ENDPOINT = "body";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarOrgaos(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
        `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Orgao) =>
          array.push({
            id: value.id,
            value: value.name,
            key: "body",
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async listagemOrgaos(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<OrgaosPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
        `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let orgaos = new Array<Orgao>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        orgaos = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: orgaos,
          paginationData: pagination,
        };
      });
  }

  async buscarOrgao(id: number): Promise<Orgao> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarOrgao(orgao: OrgaoRequest): Promise<Orgao> {
    return this.fetch
      .post(this.ENDPOINT, orgao, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarOrgao(orgao: OrgaoRequest): Promise<Orgao> {
    return this.fetch
      .put(this.ENDPOINT + `/${orgao.id}`, orgao, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirOrgao(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }

  async mesclarOrgaos(
    idDestino: number,
    orgaos: Set<number>
  ): Promise<MesclagemResponse> {

    const url = this.ENDPOINT + `/${idDestino}/merge`;

    return this.fetch
      .patch(url, Array.from(orgaos))
      .then((value) => {
        return value.data;
      });
  }
}

export const useOrgaoService = (axios: AxiosInstance) => {
  return new OrgaoService(axios);
};

export interface Orgao {
  id: number;
  name: string;
  abbreviation: string;
  image?: ImageInfoDetails;

}

export interface OrgaoRequest {
  id?: number;
  name: string;
  abbreviation: string;
}

export type OrgaosPagination = {
  content: Array<Orgao>;
  paginationData: PaginationData;
};
