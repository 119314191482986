import React, { Fragment } from 'react';
import { AiOutlineTags } from 'react-icons/ai';
import { BiCheckCircle } from 'react-icons/bi';
import { BsXCircle } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { GiPlainCircle } from 'react-icons/gi';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { StatusComponent } from '../../components/status';
import { CodigoIDContent, LabelContent } from './styles';

export const Validation: React.FC<{
  value: string;
  mode: string;
  classname?: string;
}> = (props) => {
  const validationHeaderRender = () => {
    if (props.value === 'Validada') {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={RiCheckboxBlankCircleFill}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-clear)'}
          icon={RiCheckboxBlankCircleFill}
        />
      );
    }
  };

  const validationItemRender = () => {
    if (props.value === 'Validada') {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={BiCheckCircle}
          status={props.value}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={HiOutlineExclamationCircle}
          status={props.value}
        />
      );
    }
  };

  return <Fragment>{props.mode == 'header' ? validationHeaderRender() : validationItemRender()}</Fragment>;
};

export const Classificacao: React.FC<{
  subject: string;
  classname?: string;
}> = (props) => {
  const classificacaoRender = () => {
    if (props.subject) {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={AiOutlineTags}
          status={'Classificada'}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={HiOutlineExclamationCircle}
          status={'Não classificada'}
        />
      );
    }
  };

  return <Fragment>{classificacaoRender()}</Fragment>;
};

export const Status: React.FC<{ status: string; classname?: string }> = ({ status, classname }) => {
  const statusRender = () => {
    if (status === 'Regular') {
      return (
        <StatusComponent
          classname={classname}
          color={'var(--color-status-regular)'}
          icon={GiPlainCircle}
          status={status}
        />
      );
    } else if (status === 'Cancelada') {
      return (
        <StatusComponent
          classname={classname}
          color={'var(--color-status-cancelada)'}
          icon={BsXCircle}
          status={status}
        />
      );
    }
    return (
      <StatusComponent
        classname={classname}
        color={'var(--color-status-desatualizada)'}
        icon={HiOutlineExclamationCircle}
        status={status}
      />
    );
  };

  return <Fragment>{statusRender()}</Fragment>;
};

export const CodigoID: React.FC<{
  codigo: string;
  id: number;
  classname?: string;
}> = (props) => {
  const codigoRender = () => {
    if (props.codigo) {
      return (
        <CodigoIDContent className={props.classname}>
          <span>
            <b>{props.id}</b> -
          </span>
          <span>Questão #{props.codigo}</span>
        </CodigoIDContent>
      );
    }

    return (
      <CodigoIDContent className={props.classname}>
        <span>
          <b>{props.id}</b> -
        </span>
        <span>Questão</span>
      </CodigoIDContent>
    );
  };

  return <Fragment>{codigoRender()}</Fragment>;
};

export const ComentarioValidation: React.FC<{
  value: string;
  classname?: string;
}> = (props) => {
  const validationItemRender = () => {
    if (props.value === 'Publicado') {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={FaCheck}
          status={props.value}
        />
      );
    } else {
      return (
        <StatusComponent
          classname={props.classname}
          color={'var(--color-primary-darking)'}
          icon={HiOutlineExclamationCircle}
          status={props.value}
        />
      );
    }
  };

  return <Fragment>{validationItemRender()}</Fragment>;
};

export const LabelValue: React.FC<{
  value: string;
  label: string;
  classname?: string;
}> = (props) => {
  return (
    <LabelContent className={props.classname}>
      <label>{props.label}</label>
      <p>{props.value}</p>
    </LabelContent>
  );
};
