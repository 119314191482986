import styled from "styled-components";

export const LayoutManagerContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--color-primary-light-clear);
  overflow: auto;

  .list {
    flex: 1 1 auto;
    background-color: white;
    padding: 12px;
  }

  .area-layout {
    flex: 1 1 auto;
    padding: 20px;
    margin: 10px;
    background-color: white;
  }

  .list-layout {
    flex: 1 1 auto;
    background-color: white;
    border: 1px dashed var(--color-primary-dark);
    padding: 20px;
  }
`;
