import styled from 'styled-components';

export const ValidacaoComentarioContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .field-group {
      margin-left: 18px;
    }
  }
`;

export const ValidacaoComentarioFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;
