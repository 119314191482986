import { DropZoneData } from "../dropzone";

export interface ParentElement<T> {
    id: string;
    name?: string;
    data?: T;
    subGroups: Array<ChildrenElement<T> | ParentElement<T>>;
    path?: string;
    isOpen: boolean;
    updateContent?: boolean;
    type: "parent"
    externalPath?: string;
}

export interface ChildrenElement<T> {
    id: string;
    name?: string;
    path?: string;
    data?: T;
    updateContent?: boolean;
    type: "children"
    externalPath?: string;
}

export interface PathElement<T> {
    itemPos: number,
    layout?: (ParentElement<T> | ChildrenElement<T>)[],
    itemParent?: ParentElement<T> | ChildrenElement<T>
}
export const ItemTypes = {
    PARENT: "parent",
    CHILDREN: "children",
};

export interface LayoutDragFeatures<T> {
    addSubGroupsToParent: (isOpen: boolean, path: string, subGroups: Array<ChildrenElement<T> | ParentElement<T>>, layout?: (ParentElement<T> | ChildrenElement<T>)[]) => void;
    findParentByPath: (path: string, layout: (ParentElement<T> | ChildrenElement<T>)[]) => PathElement<T>;
    moveElementWithinLayout: (toZone: DropZoneData<T>, item: DropZoneData<T>) => void;
    moveChildrenWithinParent: (toZone: DropZoneData<T>, item: DropZoneData<T>) => void;
    moveChildrenFromParentToLayout: (toZone: DropZoneData<T>, item: DropZoneData<T>) => void;
    moveChildrenFromLayoutToParent: (toZone: DropZoneData<T>, item: DropZoneData<T>) => void;
    setData: (zone: DropZoneData<T>, data: T) => void;
    setOpen: (open: boolean, path: string) => void
    setPath: (path: string) => void
    setUpdateContent: (zone: DropZoneData<T>, update: boolean) => void
    setUpdateContentByPath: (path: string, update: boolean) => void
    setLayout: (array: Array<ParentElement<T> | ChildrenElement<T>>) => void
}

export interface TLayoutDragSystem<T> {

    features: LayoutDragFeatures<T>,
    layout: Array<ParentElement<T> | ChildrenElement<T>>;

}

export const useLayoutDragSystem = <T>(layout: Array<ParentElement<T> | ChildrenElement<T>>, setLayout: (array: Array<ParentElement<T> | ChildrenElement<T>>) => void) => {


    const setPath = (path: string) => {

        let layoutTmp = [...layout];

        const pathData = findParentByPath(path, layoutTmp);

        if (pathData.layout) {

            const parent = pathData.layout[pathData.itemPos];

            if (parent.path !== path) {
                parent.path = path;
                setLayout(layoutTmp);
            }


        } else {

            if (pathData.itemParent && pathData.itemParent.type === "parent") {
                const parent = pathData.itemParent.subGroups[pathData.itemPos];

                if (parent.path !== path) {
                    parent.path = path;
                    setLayout(layoutTmp);
                }

            }

        }


    }


    const setUpdateContentByPath = (
        path: string,
        update: boolean
    ) => {
        let layoutTmp = [...layout];

        const pathData: PathElement<T> = findParentByPath(
            path,
            layoutTmp
        );

        if (pathData.layout) {

            const parent = pathData.layout[pathData.itemPos];

            pathData.layout[pathData.itemPos] = {
                ...parent,
                updateContent: update
            };
        } else {
            if (
                pathData.itemParent &&
                pathData.itemParent.type === "parent"
            ) {

                const parent = pathData.itemParent.subGroups[pathData.itemPos];

                pathData.itemParent.subGroups[pathData.itemPos] = {
                    ...parent,
                    updateContent: update
                };
            }
        }

        setLayout(layoutTmp);
    };


    const setUpdateContent = (
        zone: DropZoneData<T>,
        update: boolean
    ) => {
        let layoutTmp = [...layout];
        let parent = zone.data;

        const pathData: PathElement<T> = findParentByPath(
            zone.path,
            layoutTmp
        );

        if (pathData.layout && parent.data) {
            pathData.layout[pathData.itemPos] = {
                ...parent,
                updateContent: update
            };
        } else {
            if (
                pathData.itemParent &&
                pathData.itemParent.type === "parent" &&
                parent.data
            ) {
                pathData.itemParent.subGroups[pathData.itemPos] = {
                    ...parent,
                    updateContent: update
                };
            }
        }

        setLayout(layoutTmp);
    };


    const setData = (
        zone: DropZoneData<T>,
        data: T
    ) => {
        let layoutTmp = [...layout];
        let parent = zone.data;

        const pathData: PathElement<T> = findParentByPath(
            zone.path,
            layoutTmp
        );

        if (pathData.layout && parent.data) {
            pathData.layout[pathData.itemPos] = {
                ...parent,
                data: data,
            };
        } else {
            if (
                pathData.itemParent &&
                pathData.itemParent.type === "parent" &&
                parent.data
            ) {
                pathData.itemParent.subGroups[pathData.itemPos] = {
                    ...parent,
                    data: data,
                };
            }
        }

        setLayout(layoutTmp);
    };


    const reorder = (array: Array<ParentElement<T> | ChildrenElement<T>>, startIndex: number, endIndex: number) => {
        const result = Array.from(array);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const addSubGroupsToParent = (open: boolean, path: string, subGroups: Array<ChildrenElement<T> | ParentElement<T>>, layoutParam?: (ParentElement<T> | ChildrenElement<T>)[]) => {

        let layoutTmp = layoutParam ? [...layoutParam] : [...layout];

        const pathData = findParentByPath(path, layoutTmp);

        if (pathData.layout) {

            const parent = pathData.layout[pathData.itemPos];

            if (parent.type === "parent") {
                parent.subGroups = subGroups;
                parent.isOpen = open;
            }


        } else {

            if (pathData.itemParent) {

                if (pathData.itemParent.type === "parent") {
                    const parent: ParentElement<T> = pathData.itemParent.subGroups[pathData.itemPos] as ParentElement<T>;
                    parent.subGroups = subGroups;
                    parent.isOpen = open;
                }

            }

        }

        if (layoutParam === undefined) {
            setLayout(layoutTmp);
        }

    }

    const setOpen = (open: boolean, path: string) => {
        let layoutTmp = [...layout];

        const pathData = findParentByPath(path, layoutTmp);

        if (pathData.layout) {

            const parent = pathData.layout[pathData.itemPos];

            if (parent.type === "parent") {
                parent.isOpen = open;
            }


        } else {

            if (pathData.itemParent) {

                if (pathData.itemParent.type === "parent") {
                    const parent: ParentElement<T> = pathData.itemParent.subGroups[pathData.itemPos] as ParentElement<T>;
                    parent.isOpen = open;
                }

            }

        }

        setLayout(layoutTmp);
    }


    const moveElementWithinLayout = (toZone: DropZoneData<T>, item: DropZoneData<T>) => {

        const indexItem = Number(item.path.split("-")[0]);
        const indexZone = Number(toZone.path.split("-")[0]);

        if (indexItem < indexZone) {
            const array = reorder(layout, indexItem, indexZone - 1);
            setLayout(array);
        } else {
            const array = reorder(layout, indexItem, indexZone);
            setLayout(array);
        }

    }

    const moveChildrenWithinParent = (toZone: DropZoneData<T>, item: DropZoneData<T>) => {

        const newArray = [...layout];

        const itemData = findParentByPath(item.path, newArray);

        const zoneData = findParentByPath(toZone.path, newArray);

        if (zoneData.itemParent && zoneData.itemParent.type === "parent") {

            if (itemData.itemPos < zoneData.itemPos) {
                const array = reorder(zoneData.itemParent.subGroups, itemData.itemPos, zoneData.itemPos - 1);
                zoneData.itemParent.subGroups = array;
            } else {
                const array = reorder(zoneData.itemParent.subGroups, itemData.itemPos, zoneData.itemPos);
                zoneData.itemParent.subGroups = array;
            }

            setLayout(newArray);
        }


    }

    const moveChildrenFromParentToLayout = (toZone: DropZoneData<T>, item: DropZoneData<T>) => {

        let newArray = [...layout];
        const itemData = findParentByPath(item.path, newArray);
        const zoneData = findParentByPath(toZone.path, newArray);

        newArray = removeElement(newArray, itemData);
        addChildrenWithinParent(newArray, item, zoneData);
        setLayout(newArray);

    }

    const moveChildrenFromLayoutToParent = (toZone: DropZoneData<T>, item: DropZoneData<T>) => {

        let newArray = [...layout];

        const itemData = findParentByPath(item.path, newArray);
        const zoneData = findParentByPath(toZone.path, newArray);


        if (itemData.layout) {

            addChildrenWithinParent(newArray, item, zoneData);
            newArray = removeElement(newArray, itemData);

        } else {

            newArray = removeElement(newArray, itemData);
            addChildrenWithinParent(newArray, item, zoneData);

        }

        setLayout(newArray);

    }

    const removeElement = (newArray: Array<ParentElement<T> | ChildrenElement<T>>, data: PathElement<T>) => {

        if (data.layout) {

            newArray = layout.filter((value, index) => index !== data.itemPos);

        } else if (data.itemParent && data.itemParent.type === "parent") {

            const newChildrens = data.itemParent.subGroups.filter((value, index) => index !== data.itemPos);
            data.itemParent.subGroups = newChildrens;

        }

        return newArray;
    }

    const addChildrenWithinParent = (array: Array<ParentElement<T> | ChildrenElement<T>>, item: DropZoneData<T>, zoneData: PathElement<T>) => {

        const parent = zoneData.itemParent;

        if (zoneData.layout) {
            array.splice(zoneData.itemPos, 0, item.data);
        } else if (parent && parent.type == "parent") {
            parent.subGroups.splice(zoneData.itemPos, 0, item.data);
        }

    }

    const findParentByPath = (path: string, layout: (ParentElement<T> | ChildrenElement<T>)[]): PathElement<T> => {

        const positions = path.split("-");
        const itemPos = positions[positions.length - 1];

        let parent: ParentElement<T> | ChildrenElement<T> = {
            id: "",
            name: "",
            type: "parent",
            isOpen: false,
            subGroups: []
        };

        let isLayout = false;


        let index = 0;

        for (let pos of positions) {

            const position = Number(pos);

            if (positions.length === 1) {

                isLayout = true;

                break;

            } else if (index === 0) {

                parent = layout[position];

            } else {

                if (parent.type === "parent") {

                    const found: ParentElement<T> | ChildrenElement<T> = parent.subGroups[position];

                    if (found) {
                        parent = found;
                    }

                }

            }

            if (index == positions.length - 2) {
                break;
            }

            index++;

        }

        return isLayout ? {
            itemPos: Number(itemPos),
            layout: layout
        } : {
            itemPos: Number(itemPos),
            itemParent: parent
        };


    }


    const features: TLayoutDragSystem<T> = {
        features: {
            addSubGroupsToParent: addSubGroupsToParent,
            moveChildrenFromLayoutToParent: moveChildrenFromLayoutToParent,
            moveChildrenFromParentToLayout: moveChildrenFromParentToLayout,
            moveChildrenWithinParent: moveChildrenWithinParent,
            moveElementWithinLayout: moveElementWithinLayout,
            findParentByPath: findParentByPath,
            setData: setData,
            setOpen: setOpen,
            setPath: setPath,
            setUpdateContent: setUpdateContent,
            setUpdateContentByPath: setUpdateContentByPath,
            setLayout: setLayout
        },
        layout: layout
    }
    return features;
}