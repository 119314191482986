import { VscOpenPreview } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { CheckBox } from "../../../../components/checkbox";
import {
  ThreeNormal,
  ThreeNormalOption,
} from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { ProvaDetailsCount } from "../../../../services/api-services/prova";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";

export const ProvaHeader = (
  selectedEntitiesAction: SelectedDataActions<ProvaDetailsCount>
) => {
  const ProvaRow = (elem: ItemDataSelect<ProvaDetailsCount>, index: number) => {

    const { data } = elem.entity;
    const context = useGerenciarContext();
    const auth = useAuthContext();
    const { roles } = auth.user;
    const history = useHistory();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{data.id}</RowItem>
        <RowItem>{data.body ? data.body.name : ""}</RowItem>
        <RowItem>{data.exam_board ? data.exam_board.name : ""}</RowItem>
        <RowItem>{data.office ? data.office.name : ""}</RowItem>
        <RowItem>{data.career ? data.career.name : ""}</RowItem>
        <RowItem>{data.year}</RowItem>
        <RowItem>{data.level}</RowItem>
        <RowItem>{data.oab_exam_number}</RowItem>
        <RowItem width="10px">
          <ThreeNormal
            size={18}
            className="list-options"
            isLastPosition={true}
            options={!roles.includes("ROLE_CLASSIFICADOR") ? [
              <ThreeNormalOption
                name="Ver questões"
                icon={VscOpenPreview}
                onClick={() => {
                  history.push(`/gerenciar-questoes?exam=${data.id}`);
                }}
              />,
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar-prova",
                    id: data.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: data.id,
                  });
                }}
              />,
            ] : [
              <ThreeNormalOption
                name="Ver questões"
                icon={VscOpenPreview}
                onClick={() => {
                  history.push(`/gerenciar-questoes?exam=${data.id}`);
                }}
              />
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    provaHeader: ProvaRow,
  };
};
