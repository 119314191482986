import styled from "styled-components";


export interface DivisaoContentStyles {
  isExpand: boolean
  isAgrupamento: boolean
}




export const DivisaoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .questoes{
    width: 100%;
    margin-top: 24px;
  }

  .titulos {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;



    .drag-content{
      cursor: move;
    }

    .divisao-nome{
      display: flex;
      flex-direction: row ;
      text-align: center ;
      align-items: center ;
      margin-left: 8px ;
      
    }

  .divisao-options{

    margin-right: 12px ;

    .button-option{
      height: 20px ;
      min-width: 148px ;
      justify-items: center;
      margin-top: 4px;
      margin-left: 8px;
    }

    }

    .salvar-alteracoes{
       height: 40px;
       margin-top: 8px;
       margin-left: 8px;
    }

    .tipo-titulo {
      //width: 10%;
    }

    .titulo {
      flex: 3 2 0;
      //width: 74%;
    }

    .tipo-conteudo {
      //width: 100px;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;


    .parametro-options {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;

      .field-group {
        margin-left: 7px;
        margin-right: 7px;
      }

  
    }

    .agrupamento-options {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      .reiniciar-sequencia {
        width: 100%;
      }

      small{
        padding-left: 8px;
        padding-right: 8px;
      }

      .parametro-ordenacao {
        min-width: 160px;
        margin-right: 14px;
        margin-left: 4px;
      }

      .filtros-ordenacao {
        margin-right: 14px;
      }
    }

    .salvar-alteracoes{
        margin-left: 8px;
    }
  }

  @media (max-width: 800px) {

    height: auto;

    .titulos {
      flex-direction: column;

      .titulo {
        width: 100%;
        flex-wrap: wrap;
      }

      .tipo-titulo {
        width: 100%;
      }
    }

    .options {
      flex-direction: column;

      .agrupamento-options{
        margin-bottom: 12px ;
        flex-direction: column;
        align-items: center;

        .ordem {
          margin: 4px;
          width: 100%;
        }

        .parametro {
          margin: 4px;
          width: 100%;
        }

      }

      .parametro-options {
        flex-direction: column;

        .field-group {
          margin: 12px;
        }
      }
    }
  }
`;
