import { useEffect, useState } from "react";
import { BiIdCard } from "react-icons/bi";
import { FcLock } from "react-icons/fc";
import { RiGoogleFill, RiLockPasswordLine } from "react-icons/ri";

import { SiCodefactor } from "react-icons/si";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { RoundedButton } from "../../components/button/rounded";
import { InputIcon } from "../../components/input/input-icon";
import { SectionText } from "../../components/section-text";
import { SubMenuTitle } from "../../components/sub-menu-title";
import { RestErro } from "../../services/api";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import logo from "./logo.png";
import { ContentLogin, MainLogin } from "./styles";



export interface OAuthLocationState {
  from: {
    search: string
  },
  authenticationProviderSuccess: boolean,
  provider: string
}

export interface LoginProps {
  appName: string
  GOOGLE_AUTH_URL: string
  secondaryPlatform: {
    url: string
    name: string
  }
}

export const Login = ({ appName, GOOGLE_AUTH_URL, secondaryPlatform }: LoginProps) => {

  const auth = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const location = useLocation<OAuthLocationState>();
  const [preAuth, setPreAuth] = useState<boolean>(false);
  const [tfaTk, setTfaTk] = useState<string>("");
  const [code, setCode] = useState<string>("");

  useEffect(() => {

    if (location.state !== undefined) {

      const { authenticationProviderSuccess, from, provider } = location.state;

      if (authenticationProviderSuccess !== undefined) {

        if (authenticationProviderSuccess) {

          auth.redirectOAuthLogin(from.search, provider).then((data) => {

            if (typeof data === "string") {
              setPreAuth(true);
              setTfaTk(data);
            } else {
              history.push("/");
              window.location.reload();
            }

          }).catch((res) => {

            const erro: RestErro = res.response.data;

            if (erro) {
              alert(erro.message);
            } else {
              alert("Ocorreu um erro ao realizar o login, tente novamente!!!");
            }

          });

        } else {
          alert("Não foi possível autenticar com " + provider);
        }

      }

    }

  }, [location]);

  const login = () => {
    if (username.length > 0 && password.length > 0) {
      auth
        .initiateLogin(username, password, "default")
        .then((data) => {

          if (typeof data === "string") {
            setPreAuth(true);
            setTfaTk(data);
          } else {
            history.push("/");
            window.location.reload();
          }

        })
        .catch(() => {
          alert("Ocorreu um erro ao realizar o login, tente novamente!!!");
        });
    }
  };

  const tfaLogin = () => {
    if (code.length > 0 && tfaTk) {
      auth.tfaLogin(tfaTk, code).then(() => {

        setPreAuth(false);
        setTfaTk("");

        history.push("/");
        window.location.reload();

      }).catch((res) => {

        const erro: RestErro = res.response.data;

        if (erro) {
          alert(erro.message);
        } else {
          alert("Ocorreu um erro ao realizar o login, tente novamente!!!");
        }

      });
    }
  }

  return (
    <MainLogin>
      <ContentLogin>
        {preAuth ?
          <SectionText title="Autenticação de dois fatores está ativada" icon={FcLock}>
            <p>Para continuar com login, informe o código fornecido pelo Google Authenticator</p>
            <SubMenuTitle title="Código de acesso"  >
              <InputIcon
                placeholder="Informe seu código"
                className="input-group"
                type={"number"}
                onClickSearch={() => { }}
                onChangeValue={(value) => setCode(value)}
                icon={SiCodefactor} />
            </SubMenuTitle>
            <ButtonNormal
              title="Continuar"
              className="login-button"
              onClick={tfaLogin} />
          </SectionText>
          :
          <><div className="logo-area">
            <img src={logo} width="80" height="80" alt="Logo da Editora Juspodivm" />
            <h2>{appName}</h2>
          </div>
            <RoundedButton
              title={`Acessar ${secondaryPlatform.name}`}
              className="banco-legislacao"
              href={secondaryPlatform.url} />
            <form className="form-area">
              <InputIcon
                placeholder="Informe seu usuário"
                className="input-group"
                onClickSearch={() => { }}
                onChangeValue={(value) => setUsername(value)}
                icon={BiIdCard} />
              <InputIcon
                type="password"
                placeholder="Informe sua senha"
                className="input-group"
                onClickSearch={() => { }}
                onChangeValue={(value) => setPassword(value)}
                icon={RiLockPasswordLine} />
            </form>
            <ButtonNormal
              title="Acessar"
              className="login-button"
              onClick={() => login()} />
            <RoundedButton
              title="Acessar com google"
              className="login-google"
              icon={RiGoogleFill}
              href={GOOGLE_AUTH_URL} />

          </>
        }
      </ContentLogin>
    </MainLogin >
  );
};
