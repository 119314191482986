import { Fragment, useEffect, useState } from "react";
import { FcLock, FcUnlock } from "react-icons/fc";
import { ImGoogle, ImQrcode } from "react-icons/im";
import { VscDeviceMobile } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../components/button/bottomless";
import { ActionType } from "../../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../../components/page/structure/BaseComponent";
import { SectionText } from "../../../components/section-text";
import { SubMenuTitle } from "../../../components/sub-menu-title";
import { TextItem } from "../../../components/text-item";
import { APIFetch, APIOauthFetch, RestErro } from "../../../services/api";
import { useTFAService } from "../../../services/api-services/tfa";
import { useUsuarioService } from "../../../services/api-services/usuarios";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";
import { ConfirmarAutorizacao } from "../../GerenciarUsuarios/forms/confirmar-autorizacao";
import { AutenticacaoDoisFatoresContent } from "./styles";


export interface TAutenticacaoDoisFatores {

}

const INITIAL_CURRENT_STATE = {
    id: 0,
    action: "",
    loading: false,
    message: "",
    object: {},
}

export const AutenticacaoDoisFatores: React.FC<TAutenticacaoDoisFatores> = (props) => {

    const auth = useAuthContext();
    const tfaService = useTFAService(APIOauthFetch(auth));
    const usuarioService = useUsuarioService(APIFetch(auth));
    const [tfaActive, setTfaActive] = useState<boolean>(false);
    const [qrcode, setQrCode] = useState<string>("");

    const currentActionState = useState<ActionType>(INITIAL_CURRENT_STATE);

    const [currentAction, setCurrentAction] = currentActionState;

    const modals = () => {
        switch (currentAction.action) {
            case "autorizar": {
                return <ConfirmarAutorizacao notShowAlert={true} state={currentActionState} chargeUsername={false} />;
            }
            default: {
                return <Fragment />;
            }
        }
    };

    useEffect(() => {
        usuarioService.buscarUsuario(auth.user.id as unknown as number).then((usuario) => {
            setTfaActive(usuario.two_fa);
        });
    }, []);

    const activeTFA = () => {
        setCurrentAction({
            action: "autorizar",
            id: currentAction.id,
            promisse: async (properties: any) => {

                const currentPassword = properties.password;

                try {

                    const value = await tfaService.activeTFA(auth.user.id as unknown as number, {
                        password: currentPassword
                    });

                    setQrCode(value);
                    setCurrentAction(INITIAL_CURRENT_STATE);

                } catch (error: any) {

                    setCurrentAction(INITIAL_CURRENT_STATE);
                    const erro: RestErro = error.response.data;

                    alert(erro.message);

                }
            },
        });
    }

    const disableTFA = () => {
        setCurrentAction({
            action: "autorizar",
            id: currentAction.id,
            promisse: async (properties: any) => {

                const currentPassword = properties.password;

                try {

                    await tfaService.disableTFA(auth.user.id as unknown as number, {
                        password: currentPassword
                    });

                    setCurrentAction(INITIAL_CURRENT_STATE);
                    setTfaActive(false);
                    setQrCode("");

                    alert("Autenticação de dois fatores foi desativada!");

                } catch (error: any) {

                    setCurrentAction(INITIAL_CURRENT_STATE);
                    const erro: RestErro = error.response.data;

                    alert(erro.message);

                }
            },
        });
    }

    const newQrCode = () => {
        setCurrentAction({
            action: "autorizar",
            id: currentAction.id,
            promisse: async (properties: any) => {

                const currentPassword = properties.password;

                try {

                    const qrcode = await tfaService.generateNewQRCode(auth.user.id as unknown as number, {
                        password: currentPassword
                    });

                    setQrCode(qrcode);
                    setCurrentAction(INITIAL_CURRENT_STATE);


                } catch (error: any) {

                    setCurrentAction(INITIAL_CURRENT_STATE);
                    const erro: RestErro = error.response.data;

                    alert(erro.message);

                }
            },
        });
    }

    return (
        <Fragment>
            <BaseComponent pageName={`Segurança e Login`}>
                <AutenticacaoDoisFatoresContent>
                    {!tfaActive && <SectionText title="Ajude a proteger sua conta" icon={FcUnlock}>
                        <p>Para garantir que apenas você acessa sua conta, toda vez que fizer login será solicitado um código de verificação para método de segurança escolhido.</p>
                        <SubMenuTitle title="Aplicativo de autenticação" subTitle="Use o aplicativo Google Authenticator para gerar códigos de verificação para proteção da sua conta" icon={ImQrcode} >
                            {qrcode ? <TextItem title={"Leia o qrcode em seu aparelho com o Google Authenticator"}  >
                                {qrcode && <><img src={qrcode} /><TextItem title={"Terminou a leitura? Clique em "}>
                                    <ButtonBottomLess
                                        className="ok-button"
                                        title="ok"
                                        onClick={() => {
                                            setQrCode("");
                                            setTfaActive(true);
                                        }} />
                                </TextItem></>}
                            </TextItem> : <TextItem button={{
                                className: "dois-fatores-button",
                                title: "Usar aplicativo de autenticação",
                                icon: ImGoogle,
                                onClick: () => activeTFA()
                            }} >
                            </TextItem>}
                        </SubMenuTitle>
                    </SectionText>}
                    {tfaActive &&
                        <SectionText title="Autenticação de dois fatores está ativada" icon={FcLock} button={{
                            className: "dois-fatores-button",
                            title: "Desativar",
                            icon: FcUnlock,
                            onClick: () => disableTFA()
                        }}>
                            <p>Sua conta está protegida, a cada login que você fizer, será solicitado um código de verificação fornecido pelo seu método de segurança escolhido.    </p>
                            {qrcode ?
                                <TextItem title={"Leia o qrcode em seu aparelho com o Google Authenticator"} >
                                    <img src={qrcode} />
                                    <TextItem title={"Terminou a leitura? Clique em "} >
                                        <ButtonBottomLess
                                            className="ok-button"
                                            title="ok"
                                            onClick={() => setQrCode("")}
                                        />
                                    </TextItem>
                                </TextItem>
                                : <SubMenuTitle title="Seu método de segurança"  >
                                    <TextItem title="Google Authenticator" button={{
                                        className: "dois-fatores-button",
                                        title: "Trocar dispositivo",
                                        icon: VscDeviceMobile,
                                        onClick: () => newQrCode()
                                    }} >
                                        <p>Solicitaremos um código do dispositivo cadastrado toda que vez que você fizer login, caso você tenha trocado de dispositivo você pode ler um novo qrcode clicando em trocar dispositivo.</p>
                                    </TextItem>
                                </SubMenuTitle>}
                        </SectionText>
                    }
                </AutenticacaoDoisFatoresContent>
            </BaseComponent>
            {modals()}
        </Fragment>
    );
}