import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { DropZoneData } from "../dropzone";
import {
  ItemTypes,
  LayoutDragFeatures,
  ParentElement
} from "../hooks/useLayoutDragSystem";
import { ElementoContent } from "./styles";

export interface TElement<T> {
  dragAndDropName: string;
  data: ParentElement<T>;
  path: string;
  handleDrop: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => void;
  canDropItem?: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => boolean;
  renderContent?: (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<T>,
    zone: DropZoneData<T>,
    data?: T
  ) => React.ReactNode;
  features: LayoutDragFeatures<T>;
  allOpen?: boolean;
}

export const ParentElementComponent = <T extends unknown>({
  data,
  path,
  children,
  renderContent,
  features,
  allOpen,
  dragAndDropName
}: PropsWithChildren<TElement<T>>) => {
  const ref = useRef<HTMLDivElement>(null);

  let zone: DropZoneData<T> = {
    data: data,
    path: path,
    type: ItemTypes.PARENT,
    dragAndDropName: dragAndDropName
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PARENT,
    item: zone,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  zone.isDragging = isDragging;

  useEffect(() => {
    if (path !== data.path) {
      features.setPath(path);
    }
  }, [path]);

  const isRoot = () => {
    const pathStr = path.split("-");

    if (pathStr.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  drag(ref);

  const checkOpen = () => {
    if (allOpen) {
      return true;
    } else {
      return data.isOpen;
    }
  };

  return (
    <ElementoContent isDragging={isDragging} isRoot={isRoot()}>
      <div className={"content"} ref={ref}>
        {renderContent
          ? renderContent(ref, features, zone, data.data)
          : data.name}
      </div>
      {checkOpen() && <div className={"sub-groups"}>{children}</div>}
    </ElementoContent>
  );
};
