import React from "react";
import { useSidebarActive } from "../Navbar/sidebar-active-context";
import { HeaderComponent, PageBaseContent } from "./styles";

type TBasePage = {
  pageName: string;
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
};

export const BaseComponent: React.FC<TBasePage> = ({
  pageName,
  headerLeft,
  headerRight,
  children,
}) => {
  const { active } = useSidebarActive();

  return (
    <PageBaseContent active={active}>
      <BaseHeaderComponent pageName={pageName} headerLeft={headerLeft} headerRight={headerRight} />
      <main>{children}</main>
    </PageBaseContent>
  );
};

export const BaseHeaderComponent: React.FC<TBasePage> = ({
  pageName,
  headerLeft,
  headerRight,
}) => {
  return (<HeaderComponent>
    <section>
      <h3>{pageName}</h3>
    </section>
    <div>
      {headerLeft}
      {headerRight}
    </div>
  </HeaderComponent>);
}