import { Fragment } from "react";
import { RespostaDetails } from "../../../../../../services/api-services/questao";
import { QuestaoViewContainer } from "../../styles";
import { RespostaEscolhaView } from "../escolha";
import {
  LeftResposta,
  RespostaContent,
  RespostaGroup,
  RespostaRow,
  RespostaTwoColumns
} from "../styles";

interface TRespostaRelacionalView {
  respostas: any;
  onShowComments?: (title: string, id: number) => void;
  onAddComentarios?: (idResposta: number) => void;
}

export const RespostaRelacionalView: React.FC<TRespostaRelacionalView> = ({
  respostas,
  onShowComments,
  onAddComentarios,
}) => {
  const colunaRelacionalTipos = (tipos: RespostaDetails[]) => {
    return (
      <QuestaoViewContainer className="tipos">
        {tipos.map((ele, index) => {
          index = index + 1;
          return (
            <RespostaRow>
              <LeftResposta>
                <div className="leter">
                  <b>{`${index} -`}</b>
                </div>
                <div className="content">
                  <div
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                      __html: ele.answer,
                    }}
                  />
                </div>
              </LeftResposta>
            </RespostaRow>
          );
        })}
      </QuestaoViewContainer>
    );
  };

  const colunaRelacionalDescricao = (
    tipos: RespostaDetails[],
    descricoes: RespostaDetails[]
  ) => {
    const getPositionRelacional = (ele: RespostaDetails) => {
      let found = -1;

      tipos.forEach((value, index) => {
        if (ele.related_column === value.id) {
          found = index + 1;
        }
      });

      return <Fragment>{found != -1 && <b>{`(${found})`}</b>}</Fragment>;
    };

    return (
      <RespostaContent className="descricao">
        {descricoes.map((ele) => {
          return (
            <RespostaRow>
              <LeftResposta>
                <div className="leter">{getPositionRelacional(ele)}</div>
                <div className="content">
                  <div
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                      __html: ele.answer,
                    }}
                  />
                </div>
              </LeftResposta>
            </RespostaRow>
          );
        })}
      </RespostaContent>
    );
  };

  return (
    <RespostaGroup>
      <RespostaTwoColumns>
        {respostas.first_column &&
          colunaRelacionalTipos(respostas.first_column)}
        {respostas.second_column &&
          colunaRelacionalDescricao(
            respostas.first_column,
            respostas.second_column
          )}
      </RespostaTwoColumns>
      {respostas.third_column && (
        <RespostaEscolhaView
          onAddComentarios={onAddComentarios}
          onShowComments={onShowComments}
          respostas={respostas.third_column}
        />
      )}
    </RespostaGroup>
  );
};
