import { AxiosInstance } from "axios";
import { PaginationData } from "../../../components/pagination/types";
import { Service } from "../../service";
import { QuestaoDetails, QuestaoItem } from "../questao";

export class QuestaoProjetoService extends Service {
  private ENDPOINT = "project";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarQuestaoProjetosPorPagina(
    idProjeto: number,
    page: number,
    qtd: string,
    validation: string,
    period: string,
    filters: string[],
    search: string,
    search_content: string,
    sort: string,
    order: string
  ): Promise<QuestaoProjetosPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
        `/${idProjeto}/question` +
        `?search=search_key:'*${search}*';search_content:'${search_content}';validation:'${validation}';status:'${filters[0]}';type:'${filters[1]}';difficulty:'${filters[2]}';nature:'${filters[3]}';exam:'${filters[4]}';subject:'${filters[5]}';topicIds:'${filters[6]}';source:'${filters[7]}';authorName:'${filters[8]}';profileName:'${filters[9]};classification:'${filters[10]}';tagsIds:'${filters[11]}';legalReferences:'*${filters[12]}*';period:'${period}'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let questoes = new Array<QuestaoProjetoItem>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        questoes = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: questoes,
          paginationData: pagination,
        };
      });
  }

  async buscarQuestaoProjeto(id: number): Promise<QuestaoProjetoDetails> {
    return this.fetch
      .get(this.ENDPOINT + `/question/${id}`)
      .then((response) => {
        return response.data;
      });
  }

  async excluirQuestaoProjeto(idQuestaoProjeto: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/question/${idQuestaoProjeto}`);
  }

  async downloadQuestoesSelecionadasDoProjeto(
    idProjeto: number,
    order: string,
    params: string[],
    authorComments: string,
    profileComments: string,
    exportComments: boolean,
    questions: number[],
    tipoArquivo: string
  ): Promise<any> {
    const config: QuestaoExportacao = {
      export_comments: exportComments,
      order: order ? order : undefined,
      sorting_parameters: params.length >= 1 ? params : undefined,
      questions: questions,
    };

    return this.fetch
      .post(
        this.ENDPOINT +
        `/${idProjeto}/question/export/download` +
        `?search=authorComments:'${authorComments}';profileComments:'${profileComments}'&type=${tipoArquivo}`,
        config,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.headers && response.headers["content-disposition"]) {
          const filename =
            response.headers["content-disposition"].split('"')[1];
          const blob = new Blob([response.data]);
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = downloadUrl;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        }
      });
  }

  async downloadQuestoesDoProjeto(
    idProjeto: number,
    period: string,
    filters: string[],
    search: string,
    search_content: string,
    order: string,
    params: string[],
    authorComments: string,
    profileComments: string,
    exportComments: boolean,
    tipoArquivo: string
  ): Promise<any> {
    const config: QuestaoExportacao = {
      export_comments: exportComments,
      order: order ? order : undefined,
      sorting_parameters: params.length >= 1 ? params : undefined,
    };

    return this.fetch
      .post(
        this.ENDPOINT +
        `/${idProjeto}/question/export/download` +
        `?search=search_key:'*${search}*';search_content:'${search_content}';status:'${filters[0]}';type:'${filters[1]}';difficulty:'${filters[2]}';nature:'${filters[3]}';exam:'${filters[4]}';subject:'${filters[5]}';topicIds:'${filters[6]}';source:'${filters[7]}';article:'*${filters[8]}*';authorName:'${filters[9]}';profileName:'${filters[10]};law:'*${filters[11]}*';classification:'${filters[12]}';tagsIds:'${filters[13]}';period:'${period}';authorComments:'${authorComments}';profileComments:'${profileComments}'&type=${tipoArquivo}`,
        config,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.headers && response.headers["content-disposition"]) {
          const filename =
            response.headers["content-disposition"].split('"')[1];
          const blob = new Blob([response.data]);
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = downloadUrl;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        }
      });
  }
}

export class ExportarQuestaoProjetoService extends Service {
  private ENDPOINT = "question/project";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async exportarQuestoesParaProjeto(
    questoesIds: number[],
    idProjeto: number,
    idDivisao: number,
    filters: string[],
    ordenarQuestoes: boolean
  ): Promise<void> {
    if (idDivisao) {
      return this.fetch.post(
        this.ENDPOINT +
        `/${idProjeto}?search=author:'${filters[0]}';profile:'${filters[1]}'&id_divisao=${idDivisao}&sort_questions=${ordenarQuestoes}`,
        questoesIds
      );
    } else {
      return this.fetch.post(
        this.ENDPOINT +
        `/${idProjeto}?search=author:'${filters[0]}';profile:'${filters[1]}&sort_questions=${ordenarQuestoes}`,
        questoesIds
      );
    }
  }
}

export const useQuestaoProjetoService = (axios: AxiosInstance) => {
  return {
    questaoProjeto: new QuestaoProjetoService(axios),
    exportarQuestaoParaProjeto: new ExportarQuestaoProjetoService(axios),
  };
};

export interface QuestaoProjetoItem {
  id: number;
  id_project: number;
  question: QuestaoItem;
}

export type QuestaoProjetosPagination = {
  content: Array<QuestaoProjetoItem>;
  paginationData: PaginationData;
};

export interface QuestaoProjetoDetails {
  id: number;
  id_project: number;
  question: QuestaoDetails;
}

export interface QuestaoExportacao {
  questions?: number[];
  order?: string;
  sorting_parameters?: string[];
  export_comments: boolean;
}
