import { ThreeDotsLink, ThreeDotsOption } from '../../../../components/menu/three-dots';
import { ThreeNormalOption } from '../../../../components/menu/three-normal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { RowItem } from '../../../../components/page/structure/ListComponent/list-table';
import { ItemDataSelect } from '../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction';
import { ListTableRow } from '../../../../components/page/structure/ListComponent/styles';
import { APIOauthFetch } from '../../../../services/api';
import { useTFAService } from '../../../../services/api-services/tfa';
import { UsuarioResumido } from '../../../../services/api-services/usuarios';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { Ativado } from '../../elements';

export const UsuarioHeader = () => {
  const UsuarioRow = (elem: ItemDataSelect<UsuarioResumido>, index: number) => {
    const usuario = elem.entity as UsuarioResumido;
    const context = useGerenciarContext();
    const authContext = useAuthContext();
    const tfaService = useTFAService(APIOauthFetch(authContext));

    const desabilitarTFA = () => {
      context.changeAction({
        action: "autorizar",
        id: usuario.id,
        promisse: async (properties: any) => {

          const currentPassword = properties.password;

          const senhaUpdate = {
            password: currentPassword
          };

          try {

            await tfaService.disableTFAAdmin(usuario.id, senhaUpdate);

            context.changeAction({
              id: 0,
              action: 'sucesso',
              message: 'Autenticação de dois fatores foi desabilitada!',
              loading: false,
            });

          } catch (error: any) {
            context.changeAction({
              id: 0,
              action: 'erro',
              message: error.response.data.message,
            });
          }
        }
      });
    }

    return (
      <ListTableRow key={index}>
        <RowItem></RowItem>
        <RowItem>{usuario.username}</RowItem>
        <RowItem>{`${usuario.name} ${usuario.surname}`}</RowItem>
        <RowItem>
          <Ativado value={usuario.activated} />
        </RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeDotsOption name="Alterar usuário" to={`/alterar/usuario/${usuario.id}`} />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: 'excluir',
                    id: usuario.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Resetar senha"
                onClick={() => {
                  context.changeAction({
                    action: 'trocar-senha',
                    id: usuario.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Desabilitar autenticação dois fatores"
                onClick={desabilitarTFA}
              />
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    usuarioHeader: UsuarioRow,
  };
};
