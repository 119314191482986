import React, { Fragment, useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoIosBusiness, IoMdAdd } from "react-icons/io";
import { MdBusinessCenter } from "react-icons/md";
import { TiMortarBoard } from "react-icons/ti";
import { VscChromeClose, VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { InputForm } from "../../../../components/input";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { AsyncSelect } from "../../../../components/select/async";
import { NormalSelect } from "../../../../components/select/normal";
import { TitleIcon } from "../../../../components/title-icon";
import { getYears } from "../../../../components/util/DateUtil";
import { selectNivel } from "../../../../mocks/data";
import { APIFetch } from "../../../../services/api";
import { useBancaService } from "../../../../services/api-services/banca";
import { useCargoService } from "../../../../services/api-services/cargo";
import { useOrgaoService } from "../../../../services/api-services/orgao";
import { useProvaService } from "../../../../services/api-services/prova";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { CriarBanca } from "../../../GerenciarBancas/forms/criar-banca";
import { CriarCargo } from "../../../GerenciarCargos/forms/criar-cargo";
import { CriarOrgao } from "../../../GerenciarOrgaos/forms/criar-orgao";
import { CriarProvaContent, FormContent, ProvaContent } from "./styles";
import { useCarreiraService } from "../../../../services/api-services/carreira";
import { CriarCarreira } from "../../../GerenciarCarreiras/forms/criar-carreira";

export interface TCriarProva {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const CriarProva: React.FC<TCriarProva> = ({ externalAction }) => {
  const auth = useAuthContext();

  const currentFormState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentForm, setCurrentForm] = currentFormState;

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const services = {
    provaService: useProvaService(APIFetch(auth)),
    orgaoService: useOrgaoService(APIFetch(auth)),
    bancaService: useBancaService(APIFetch(auth)),
    cargoService: useCargoService(APIFetch(auth)),
    carreiraService: useCarreiraService(APIFetch(auth)),
  };

  const [numExameOAB, setNumExameOAB] = useState("");

  const [orgaoSelect, setOrgaoSelect] = useState({
    id: 0,
    key: "body",
    useId: true,
    value: "",
  });

  const [carreiraSelect, setCarreiraSelect] = useState({
    id: 0,
    key: "career",
    useId: true,
    value: "",
  });


  const [cargoSelect, setCargoSelect] = useState({
    id: -1,
    key: "office",
    useId: true,
    value: "",
  });

  const [bancaSelect, setBancaSelect] = useState({
    id: -1,
    key: "exam_board",
    useId: true,
    value: "",
  });

  const [anoSelect, setAnoSelect] = useState({
    id: -1,
    key: "year",
    useId: true,
    value: "",
    selected: false,
  });

  const [nivelSelect, setNivelSelect] = useState({
    id: -1,
    key: "level",
    useId: true,
    value: "",
    selected: false,
  });

  const years = () => {
    if (currentAction.action == "editar-prova") {
      return [
        {
          id: 0,
          key: "select",
          value: "Selecionar o ano de realização",
          useId: false,
          selected: true,
        },
        ...getYears(),
      ];
    } else {
      return getYears();
    }
  };

  const onSubmit = () => {
    if (currentAction.action == "editar-prova") {
      services.provaService
        .editarProva({
          id: currentAction.id,
          level: nivelSelect.value,
          year: `01/01/${anoSelect.id}`,
          selected_body_id: orgaoSelect.id,
          oab_exam_number: numExameOAB,
          selected_exam_board_id: bancaSelect.id,
          selected_office_id: cargoSelect.value ? cargoSelect.id : null,
          selected_career_id: carreiraSelect.value ? carreiraSelect.id : null,
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Prova salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else if (currentAction.action == "criar-prova") {
      services.provaService
        .salvarProva({
          level: nivelSelect.value,
          year: `01/01/${anoSelect.id}`,
          selected_body_id: orgaoSelect.id,
          oab_exam_number: numExameOAB,
          selected_exam_board_id: bancaSelect.id,
          selected_office_id: cargoSelect.value ? cargoSelect.id : null,
          selected_career_id: carreiraSelect.value ? carreiraSelect.id : null,
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Prova salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    currentAction.action == "editar-prova" &&
      services.provaService.buscarProva(currentAction.id).then((prova) => {
        setNumExameOAB(prova.oab_exam_number);

        if (prova.career) {
          setCarreiraSelect({
            id: prova.career.id,
            key: "career",
            useId: true,
            value: prova.career.name,
          });
        }

        if (prova.body) {
          setOrgaoSelect({
            id: prova.body.id,
            key: "body",
            useId: true,
            value: prova.body.name,
          });
        }

        if (prova.office) {
          setCargoSelect({
            id: prova.office.id,
            key: "office",
            useId: true,
            value: prova.office.name,
          });
        }

        if (prova.exam_board) {
          setBancaSelect({
            id: prova.exam_board.id,
            key: "exam_board",
            useId: true,
            value: prova.exam_board.name,
          });
        }

        const anoSelectFind = getYears().find(
          (e) => e.value === "" + prova.year
        );

        const nivelSelectFind = selectNivel.find(
          (e) => e.value === prova.level
        );

        setAnoSelect(
          anoSelectFind
            ? anoSelectFind
            : {
              id: -1,
              key: "year",
              useId: true,
              value: "",
              selected: false,
            }
        );
        setNivelSelect(
          nivelSelectFind
            ? nivelSelectFind
            : {
              id: -1,
              key: "level",
              useId: true,
              value: "",
              selected: false,
            }
        );
      });
  }, []);

  const options = () => {
    switch (currentForm.action) {
      case "criar-banca": {
        return subMenu(
          <CriarBanca externalAction={currentFormState} isModal={false} />,
          "Criar nova banca",
          TiMortarBoard
        );
      }
      case "criar-orgao": {
        return subMenu(
          <CriarOrgao externalAction={currentFormState} isModal={false} />,
          "Criar novo orgão",
          IoIosBusiness
        );
      }
      case "criar-cargo": {
        return subMenu(
          <CriarCargo externalAction={currentFormState} isModal={false} />,
          "Criar novo cargo",
          MdBusinessCenter
        );
      }
      case "criar-carreira": {
        return subMenu(
          <CriarCarreira externalAction={currentFormState} isModal={false} />,
          "Criar nova carreira",
          MdBusinessCenter
        );
      }

      default: {
        return <Fragment />;
      }
    }
  };

  const subMenu = (
    children: React.ReactChild,
    titulo: string,
    icon?: React.ComponentType<IconBaseProps>
  ) => {
    return (
      <FormContent>
        <TitleIcon
          name={titulo}
          icon={icon}
          classname={"titulo"}
          extra={
            <VscChromeClose
              onClick={() => {
                setCurrentForm({
                  action: "",
                  id: 0,
                });
              }}
              size={22}
              style={{
                color: "var(--color-primary-darking)",
                cursor: "pointer",
              }}
            />
          }
        />
        <div className="content">{children}</div>
      </FormContent>
    );
  };

  const footer = () => {
    return (
      <ButtonNormal
        icon={VscSave}
        onClick={() => onSubmit()}
        title={"Salvar"}
        className={"save"}
        type={"button"}
      />
    );
  };

  return (
    <Modal
      width={900}
      action={actionState}
      options={footer()}
      title={
        currentAction.action == "criar-prova"
          ? "Nova prova"
          : `Editar prova #${currentAction.id}`
      }
    >
      <CriarProvaContent>
        <ProvaContent>
          <div className="form-group">
            <div className="field-group">
              <label>Nível</label>
              <NormalSelect
                minWidth={0}
                className="nivel form-group"
                label="Selecione o nível"
                selectItem={nivelSelect}
                onSelectCurrentItem={(item) =>
                  setNivelSelect({
                    ...nivelSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  })
                }
                selectedItems={selectNivel}
              />
            </div>
            <div className="field-group">
              <label>Ano de realização</label>
              <NormalSelect
                minWidth={0}
                className="ano form-group"
                label="Selecione o ano"
                onSelectCurrentItem={(item) =>
                  setAnoSelect({
                    ...anoSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  })
                }
                selectedItems={years()}
                selectItem={anoSelect}
              />
            </div>
          </div>
          <TitleIcon name="Concurso" icon={HiOutlineOfficeBuilding} />
          <div className="form-group">
            <div className="field-group">
              <label>Orgão</label>
              <AsyncSelect
                className="orgao form-group"
                label="Selecione o orgão"
                showSelectItem={true}
                selectItem={orgaoSelect}
                onAddFilterItem={(item) => {
                  setOrgaoSelect({
                    ...orgaoSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  });
                }}
                getDataAPI={(page, value) =>
                  services.orgaoService.listarOrgaos(page, 30, value)
                }
              />
            </div>
            <div className="field-group">
              <label>Banca</label>
              <AsyncSelect
                className="banca form-group"
                label="Selecione a banca"
                showSelectItem={true}
                selectItem={bancaSelect}
                onAddFilterItem={(item) => {
                  setBancaSelect({
                    ...bancaSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  });
                }}
                getDataAPI={(page, value) =>
                  services.bancaService.listarBancas(page, 30, value)
                }
              />
            </div>
            <div className="field-group">
              <label>Cargo</label>
              <AsyncSelect
                className="cargo form-group"
                label="Selecione um cargo"
                showSelectItem={true}
                selectItem={cargoSelect}
                onAddFilterItem={(item) => {
                  setCargoSelect({
                    ...cargoSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  });
                }}
                getDataAPI={(page, value) =>
                  services.cargoService.listarCargos(page, 30, value)
                }
              />
            </div>
            <div className="field-group">
              <label>Carreira</label>
              <AsyncSelect
                className="carreira form-group"
                label="Selecione a carreira"
                showSelectItem={true}
                selectItem={carreiraSelect}
                onAddFilterItem={(item) => {
                  setCarreiraSelect({
                    ...carreiraSelect,
                    ["id"]: item.id,
                    ["value"]: item.value,
                  });
                }}
                getDataAPI={(page, value) =>
                  services.carreiraService.listarCarreiras(page, 30, value)
                }
              />
            </div>
            <div className="field-group">
              <label>Número exame (OAB)</label>
              <InputForm
                className="numOAB"
                value={numExameOAB}
                onChange={(value) => {
                  setNumExameOAB(value.currentTarget.value);
                }}
              />
            </div>
            <ThreeDotsLink
              size={16}
              icon={IoMdAdd}
              text={"Criar"}
              className="list-options"
              isLastPosition={false}
              options={[
                <ThreeNormalOption
                  name="Orgão"
                  onClick={() => {
                    setCurrentForm({
                      ...currentForm,
                      action: "criar-orgao",
                    });
                  }}
                />,
                <ThreeNormalOption
                  name="Banca"
                  onClick={() => {
                    setCurrentForm({
                      ...currentForm,
                      action: "criar-banca",
                    });
                  }}
                />,
                <ThreeNormalOption
                  name="Cargo"
                  onClick={() => {
                    setCurrentForm({
                      ...currentForm,
                      action: "criar-cargo",
                    });
                  }}
                />,
                <ThreeNormalOption
                  name="Carreira"
                  onClick={() => {
                    setCurrentForm({
                      ...currentForm,
                      action: "criar-carreira",
                    });
                  }}
                />,
              ]}
            />
          </div>
        </ProvaContent>
        {options()}
      </CriarProvaContent>
    </Modal>
  );
};
