import styled from "styled-components";

export const CriarTipoLeiContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TipoLeiContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;

  .termos-tags {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .padrao-lei,
  .tipo-lei,
  .termo {
    min-width: 420px;

    @media (max-width: 700px) {
      min-width: 100%;
    }
  }

  .list-options {
    margin-right: 24px;
    margin-bottom: -18px;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
