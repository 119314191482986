import { Tag } from "../../../../../../components/tag";
import { RespostaDetails } from "../../../../../../services/api-services/questao";
import { QuestaoViewContainer } from "../../styles";
import {
  LeftResposta, RespostaRow,
  RightResposta
} from "../styles";

interface TRespostaPadraoView {
  respostas: RespostaDetails[];
}

export const RespostaPadraoView: React.FC<TRespostaPadraoView> = ({
  respostas,
}) => {
  return (
    <QuestaoViewContainer>
      {respostas.map((ele, index) => {
        const count = index + 1;
        return (
          <RespostaRow>
            <LeftResposta>
              <div className="leter">
                <b>{`${count}-)`}</b>
              </div>
              <div className="content">
                <div
                  className={"ql-editor"}
                  dangerouslySetInnerHTML={{
                    __html: ele.answer,
                  }}
                />
              </div>
            </LeftResposta>
            <RightResposta>
              <Tag nome={`${ele.points} pontos`} />
            </RightResposta>
          </RespostaRow>
        );
      })}
    </QuestaoViewContainer>
  );
};
