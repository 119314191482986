import { Dispatch, SetStateAction } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCheckCircle, BiErrorCircle } from "react-icons/bi";
import { BsInfoCircleFill } from "react-icons/bs";
import { ButtonBottomLess } from "../../button/bottomless";
import { ActionType } from "../../page/GerenciarPageBase/types";
import { ModalOption } from "../modal-option";

export const SucessModal: React.FC<{
  title: string;
  message: string;
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  onOptionOk: () => void;
}> = (props) => {
  return (
    <ModalOption action={props.action} title={""} options={[]}>
      <BiCheckCircle size={38} color={"#5DD479"} />
      <p>{props.message}</p>
    </ModalOption>
  );
};

export const ErrorModal: React.FC<{
  title: string;
  message: string;
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  onOptionOk: () => void;
}> = (props) => {
  return (
    <ModalOption action={props.action} title={""} options={[]}>
      <BiErrorCircle size={38} color={"#fa4040"} />
      <p>{props.message}</p>
    </ModalOption>
  );
};

export const InfoModal: React.FC<{
  title: string;
  message: string;
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  onOptionOk: () => void;
}> = (props) => {
  return (
    <ModalOption action={props.action} title={""} options={[]}>
      <AiOutlineInfoCircle size={38} color={"#fa4040"} />
      <p>{props.message}</p>
      <ButtonBottomLess
        className="ok-button"
        title="Ok"
        onClick={props.onOptionOk}
      />
    </ModalOption>
  );
};
