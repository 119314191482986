import { Fragment, useState } from "react";
import { CgPassword } from "react-icons/cg";
import { VscDeviceMobile, VscKey } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { SubMenuTitle } from "../../components/sub-menu-title";
import { TextItem } from "../../components/text-item";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ConfirmarAutorizacao } from "../GerenciarUsuarios/forms/confirmar-autorizacao";
import { TrocarSenha } from "../GerenciarUsuarios/forms/trocar-senha";



export interface TSegurancaLogin {

}


export const SegurancaLogin: React.FC<TSegurancaLogin> = (props) => {

    const history = useHistory();

    const auth = useAuthContext();

    const currentActionState = useState<ActionType>({
        id: 0,
        action: "",
        loading: false,
        message: "",
        object: {},
    });

    const [currentAction, setCurrentAction] = currentActionState;

    const modals = () => {
        switch (currentAction.action) {
            case "trocar-senha": {
                return <TrocarSenha state={currentActionState} />;
            }
            case "autorizar": {
                return <ConfirmarAutorizacao state={currentActionState} chargeUsername={false} notShowAlert={true} />;
            }
            default: {
                return <Fragment />;
            }
        }
    };


    return (
        <Fragment>
            <BaseComponent pageName={`Segurança e Login`}>
                <SubMenuTitle title="Login" >
                    <TextItem title="Alterar senha" icon={VscKey} button={{
                        className: "alterar-senha-button",
                        title: "Alterar",
                        icon: CgPassword,
                        onClick: () => {
                            setCurrentAction({
                                action: 'trocar-senha',
                                id: auth.user.id as unknown as number,
                            });
                        }
                    }} >
                        <p>Ao alterar sua senha crie uma senha forte seguindo os requisitos do sistema</p>
                    </TextItem>
                </SubMenuTitle>
                <SubMenuTitle title="Autenticação de dois fatores" >
                    <TextItem title="Usar autenticação de dois fatores" icon={VscDeviceMobile} button={{
                        className: "dois-fatores-button",
                        title: "Editar",
                        icon: CgPassword,
                        onClick: () => {
                            history.push("/seguranca-login/2fa");
                        }
                    }} >
                        <p>Solicitaremos um código do dispositivo cadastrado toda que vez que você fizer login</p>
                    </TextItem>
                </SubMenuTitle>
            </BaseComponent>
            {modals()}
        </Fragment>
    );
}