import styled from "styled-components";

export interface ElementContentSettings {
  isDragging: boolean;
  isRoot: boolean;
}

export const ElementoContent = styled.div`
  display: flex;
  flex-direction: column;
  ${(settings: ElementContentSettings) =>
    settings.isRoot ? `` : `padding-left: 3rem;`}
  width: 100%;
  opacity: ${(settings: ElementContentSettings) =>
    settings.isDragging ? 0.5 : 1};

  @media (max-width: 700px) {
    ${(settings: ElementContentSettings) =>
    settings.isRoot ? `` : `padding-left: 0.8rem;`}
  }

  .content {
    padding: 0.5rem;
    border-bottom: 2px solid var(--color-content-border);
    background-color: var(--color-primary-clear);
    border-radius: 8px;
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.9rem;
  }

  .sub-groups {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: 2px solid var(--color-primary-light);
    border-right: 2px solid var(--color-primary-light);
    border-bottom: 2px solid var(--color-primary-light);
    margin-bottom: 8px;
    padding: 4px;

  }
`;
