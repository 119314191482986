import styled from "styled-components";

export const PosExclusaoQuestoesModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const QuestoesNaoExcluidasContent = styled.div`
  background-color: var(--color-background);
  border-radius: 12px;

  .main-table {
    height: 340px;
    width: 100%;
    background-color: var(--color-background);
  }
`;
