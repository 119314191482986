import { Fragment, useEffect, useState } from "react";
import { IoMdExit } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { DragAndDropList } from "../../components/drag-and-drop";
import { DropZoneData } from "../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  LayoutDragFeatures,
  ParentElement
} from "../../components/drag-and-drop/hooks/useLayoutDragSystem";
import {
  ErrorModal,
  InfoModal,
  SucessModal
} from "../../components/modals/info-modal";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { APIFetch } from "../../services/api";
import {
  DivisaoData,
  useDivisaoProjeto
} from "../../services/api-services/divisoesProjeto";
import {
  ProjetoDetails,
  useProjetoService
} from "../../services/api-services/projeto";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { EditarTextoDivisao } from "../CriarProjeto/components/add-texto-divisao";
import { DivisaoItem } from "../CriarProjeto/components/divisao-item";
import { useDivisoesProjetoContext } from "../CriarProjeto/forms/assistente-estrutura/context";
import { DivisoesProjetoProvider } from "../CriarProjeto/forms/assistente-estrutura/provider";
import { LoadingComponent } from "./components/loading-screen";
import { MoverConteudoSeparador } from "./forms/escolher-separador";
import { ExcluirQuestaoProjeto } from "./forms/excluir-questao";

interface Props {
  id: string;
}

export const EdicaoProjeto: React.FC<{}> = () => {
  return (
    <DivisoesProjetoProvider>
      <EdicaoDoProjetoComponent />
    </DivisoesProjetoProvider>
  );
};

export const EdicaoDoProjetoComponent: React.FC<{}> = () => {
  //
  const auth = useAuthContext();

  const { id } = useParams<Props>();

  const assistenteContext = useDivisoesProjetoContext();

  const history = useHistory();

  const axiosInstance = APIFetch(auth);

  const services = {
    projetoService: useProjetoService(axiosInstance),
    divisaoProjetoService: useDivisaoProjeto(axiosInstance),
  };

  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentAction, setCurrentAction] = currentActionState;

  const [projeto, setProjeto] = useState<ProjetoDetails>();

  const [configuracaoMover, setConfiguracaoMover] = useState<{
    adjacent_division_id: number;
    move_division_id: number;
    dynamic_movement_content: boolean,
    sort_questions: boolean
  }>();

  const [divisaoDropped, setDivisaoDropped] = useState<{
    dropZone: DropZoneData<DivisaoData>;
    item: DropZoneData<DivisaoData>;
  }>();

  const [features, setFeatures] = useState<LayoutDragFeatures<DivisaoData>>();

  const [layoutAPI, setLayoutAPI] = useState<
    Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  >([]);

  const [isUpdating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (isUpdating) {
      features && layoutAPI && features.setLayout(layoutAPI);
      setUpdating(false);
      setLayoutAPI([]);
    }
  }, [layoutAPI]);

  const options = () => {
    switch (currentAction.action) {
      case "escolher-separador": {
        return (
          <MoverConteudoSeparador
            action={currentActionState}
            idProject={Number(id)}
            atualizarLayout={atualizarLayout}
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={currentActionState}
            message={currentAction.message ? currentAction.message : ""}
            title={""}
            onOptionOk={() =>
              setCurrentAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "sucesso": {
        return (
          <SucessModal
            action={currentActionState}
            message={currentAction.message ? currentAction.message : ""}
            title={""}
            onOptionOk={() =>
              setCurrentAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "loading": {
        return <LoadingComponent />
      }
      case "info-adjacente": {
        return (
          <InfoModal
            action={currentActionState}
            message={currentAction.message ? currentAction.message : ""}
            title={"Ok"}
            onOptionOk={() =>
              setCurrentAction({
                ...currentAction,
                action: "escolher-separador",
                id: 0,
              })
            }
          />
        );
      }
      case "editar-texto-divisao": {
        return <EditarTextoDivisao action={currentActionState} />;
      }
      case "excluir-questao-projeto": {
        return <ExcluirQuestaoProjeto currentAction={currentActionState} />;
      }
      default: {
        return <Fragment />;
      }
    }
  };

  useEffect(() => {
    if (id) {
      services.divisaoProjetoService
        .realizarAcesso(Number(id))
        .then((value) => {
          const liberado = value;

          if (liberado == true) {
            services.projetoService
              .buscarProjeto(parseInt(id))
              .then((projeto) => {
                setProjeto(projeto);
              })
              .catch((error) => {
                alert(error.response.data.message);
              });

            services.divisaoProjetoService
              .listarDivisoes(id as unknown as number)
              .then((value) => {
                assistenteContext.setLayout(value.content);
              });
          } else {
            alert(
              "Já existe um usuário acessando o modo de edição, aguarde liberar o acesso!"
            );
            history.push(`/projetos`);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
          history.push(`/projetos`);
        });
    }
  }, []);

  useEffect(() => {
    if (configuracaoMover && divisaoDropped) {
      if (
        divisaoDropped.dropZone.data.data &&
        divisaoDropped.item.target == "separator"
      ) {

        setCurrentAction({
          ...currentAction,
          action: "loading"
        });

        services.divisaoProjetoService
          .moverSeparador(
            Number(id),
            Number(divisaoDropped.item.data.id),
            Number(divisaoDropped.dropZone.data.id),
            divisaoDropped.dropZone.data.data.order,
            {
              recommended_division_id: configuracaoMover.adjacent_division_id,
              selected_division_id: configuracaoMover.move_division_id,
            },
            divisaoDropped.dropZone.isInside
              ? divisaoDropped.dropZone.isInside
              : false,
            configuracaoMover.dynamic_movement_content,
            configuracaoMover.sort_questions
          )
          .then(async () => {

            assistenteContext.handleDrop(
              divisaoDropped.dropZone,
              divisaoDropped.item
            );

            setCurrentAction({
              ...currentAction,
              action: ""
            });

            await atualizarLayout();

            return true;
          })
          .catch((error) => {
            alert(error.response.data.message);

            setCurrentAction({
              ...currentAction,
              action: ""
            });

            return false;
          });

        setConfiguracaoMover(undefined);
        setDivisaoDropped(undefined);
      }
    }
  }, [configuracaoMover]);

  const atualizarLayout = async () => {
    const divisoesAbertas = assistenteContext.layout.filter(
      (value) => value.type === "parent" && value.isOpen
    );

    let layoutTmp: Array<
      ParentElement<DivisaoData> | ChildrenElement<DivisaoData>
    > = new Array();

    const checkOpen = (parent: ParentElement<DivisaoData>) => {
      const checked = divisoesAbertas.find((value) => value.id === parent.id);

      if (checked && checked.type === "parent") {
        return checked.isOpen;
      }

      return false;
    };

    const openDivisoesByParent = async (
      parentAtual: ParentElement<DivisaoData>,
      parentAPI: ParentElement<DivisaoData>
    ) => {
      if (parentAtual.isOpen) {
        const checkOpen = (parent: ParentElement<DivisaoData>) => {
          const checked = parentAtual.subGroups.find(
            (value) => value.id === parent.id
          );

          if (checked && checked.type === "parent") {
            return checked.isOpen;
          }

          return false;
        };

        let responseAPI = await services.divisaoProjetoService
          .listarSubDivisoes(Number(id), Number(parentAPI.id), checkOpen)
          .then((response) => {
            return response.content;
          })
          .catch(() => {
            return undefined;
          });

        if (responseAPI) {
          let divisoesAPI = new Array<
            ChildrenElement<DivisaoData> | ParentElement<DivisaoData>
          >();
          divisoesAPI = responseAPI;

          parentAPI.subGroups = divisoesAPI;

          const divisoesAbertas = parentAPI.subGroups.filter(
            (value) => value.type === "parent" && value.isOpen
          );

          for (var i = 0; i < divisoesAbertas.length; i++) {
            const parentTmp = divisoesAbertas[i] as ParentElement<DivisaoData>;

            if (parentAtual.subGroups) {
              let found = parentAtual.subGroups.find(
                (value) => value.id === parentTmp.id
              );

              if (found && found.type === "parent" && found.isOpen) {
                openDivisoesByParent(found, parentTmp);
              }
            }
          }
        }
      }

      return {
        parentWithChildrens: parentAPI,
      };
    };

    const layoutAPIResponse = await services.divisaoProjetoService
      .listarDivisoes(id as unknown as number, checkOpen)
      .then((value) => {
        let layoutTmp = value.content;
        return layoutTmp;
      })
      .catch(() => {
        return undefined;
      });

    if (layoutAPIResponse) {
      layoutTmp = layoutAPIResponse;

      for (var i = 0; i < divisoesAbertas.length; i++) {
        let parentAtual = divisoesAbertas[i] as ParentElement<DivisaoData>;
        let index = layoutTmp.findIndex((value) => value.id === parentAtual.id);
        let parent = layoutTmp[index];

        if (parent && parent.type === "parent") {
          const { parentWithChildrens } = await openDivisoesByParent(
            parentAtual,
            parent
          );

          layoutTmp[index] = parentWithChildrens;
        }
      }
    }

    setUpdating(true);
    setLayoutAPI(layoutTmp);
  };

  const renderDivisao = (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<DivisaoData>,
    zone: DropZoneData<DivisaoData>,
    data?: DivisaoData | undefined
  ) => {
    return (
      <DivisaoItem
        type="editar"
        atualizarLayout={atualizarLayout}
        idProject={Number(id)}
        externalAction={currentActionState}
        zone={zone}
        data={data}
        authInstance={axiosInstance}
        features={features}
        dragTarget={dragTarget}
      />
    );
  };

  const moverSeparador = async (item: DropZoneData<DivisaoData>) => {
    const { data } = item.data;

    const getDivisaoAdjacente = async (properties: any) => {
      const { adjacent_division_id, move_division_id, dynamic_movement_content, sort_questions } = properties;
      setConfiguracaoMover({
        adjacent_division_id: adjacent_division_id,
        move_division_id: move_division_id,
        dynamic_movement_content: dynamic_movement_content,
        sort_questions: sort_questions
      });
    };

    if (item.data && data) {
      services.divisaoProjetoService
        .consultarDivisaoAdjacente(Number(id), Number(item.data.id))
        .then((idAdjacent) => {
          setCurrentAction({
            ...currentAction,
            action: "escolher-separador",
            promisse: getDivisaoAdjacente,
            id: idAdjacent,
            object: {
              titulo: data.title ? data.title : `${data.order} - ${data.level}`,
              idDivision: item.data.id,
            },
          });
        })
        .catch((error) => {
          const action = error.response.data.action;

          if (action === "mover-conteudo") {
            setCurrentAction({
              ...currentAction,
              action: "info-adjacente",
              promisse: getDivisaoAdjacente,
              message: error.response.data.message,
              object: {
                titulo: data.title
                  ? data.title
                  : `${data.order} - ${data.level}`,
                idDivision: item.data.id,
              },
            });
          } else {
            setCurrentAction({
              ...currentAction,
              id: 0,
              action: "escolher-separador",
              promisse: getDivisaoAdjacente,
              object: {
                titulo: data.title
                  ? data.title
                  : `${data.order} - ${data.level}`,
                idDivision: item.data.id,
              },
            });
          }
        });
    }
  };

  const canDropItem = async (
    dropZone: DropZoneData<DivisaoData>,
    item: DropZoneData<DivisaoData>,
    layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  ) => {
    if (dropZone.data.data) {
      if (item.target === "separator") {
        await moverSeparador(item);

        setDivisaoDropped({
          dropZone: dropZone,
          item: item,
        });

        return false;
      } else {

        setCurrentAction({
          ...currentAction,
          action: "loading"
        });

        await services.divisaoProjetoService
          .moverGrupo(
            Number(id),
            Number(item.data.id),
            Number(dropZone.data.id),
            dropZone.data.data.order,
            dropZone.isInside ? dropZone.isInside : false
          )
          .then(async () => {

            setCurrentAction({
              ...currentAction,
              action: ""
            });

            await atualizarLayout();
            return true;
          })
          .catch((error) => {
            alert(error.response.data.message);

            setCurrentAction({
              ...currentAction,
              action: ""
            });

            return false;
          });

        return false;
      }
    }
    return false;
  };

  return (
    <Fragment>
      {projeto && (
        <Fragment>
          <BaseComponent
            pageName={`Edição do projeto - #${projeto?.name} `}
            headerLeft={
              <ButtonNormal
                icon={IoMdExit}
                title={"Sair da edição"}
                className={"questao-button"}
                onClick={() => {
                  services.divisaoProjetoService
                    .liberarAcesso(Number(id))
                    .then(() => {
                      history.push("/");
                    });
                }}
              />
            }
          >
            <DragAndDropList
              renderItem={renderDivisao}
              dragAndDropName={`listaDivisoesEdicao`}
              context={assistenteContext}
              onFeatures={(value) => {
                if (features !== value) {
                  setFeatures(value);
                }
              }}
              canDropItem={canDropItem}
              maxLevel={assistenteContext.niveis.length}
            />
          </BaseComponent>
          {options()}
        </Fragment>
      )}
    </Fragment>
  );
};
