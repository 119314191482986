import styled from 'styled-components';

export const ButtonNormalContent = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--color-primary-dark);
  color: var(--color-content-light);
  padding: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  margin: 4px;
  font-weight: 500;
  font-size: 0.9rem;

  .icon {
    color: var(--color-content-light);
    margin: 2px;
  }

  :hover {
    background-color: var(--color-primary-darking);
  }
`;
