import { Fragment, useEffect, useState } from "react";
import { AiOutlineFileWord, AiOutlineInfoCircle } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import { FaUserFriends } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { VscSave } from "react-icons/vsc";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { SelecaoImagem } from "../../components/app/selecao-imagem";
import { ButtonBottomLess } from "../../components/button/bottomless";
import { ButtonNormal } from "../../components/button/normal";
import { DragAndDropList } from "../../components/drag-and-drop";
import { DropZoneData } from "../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  LayoutDragFeatures,
  ParentElement
} from "../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { FilterTag } from "../../components/filter-tag";
import { InputForm } from "../../components/input";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../components/page/structure/FilterComponent/types";
import { AsyncSelect } from "../../components/select/async";
import { NormalSelect, TItemSelect } from "../../components/select/normal";
import { StatusComponent } from "../../components/status";
import { TitleIcon } from "../../components/title-icon";
import { getYears } from "../../components/util/DateUtil";
import { visibilidadeProjeto } from "../../mocks/data";
import { APIFetch, APIFileFetch } from "../../services/api";
import { useAutorService } from "../../services/api-services/autores";
import { DivisaoData } from "../../services/api-services/divisoesProjeto";
import { useEstiloExportacaoService } from "../../services/api-services/estiloExportacao";
import { useEstruturaExportacaoService } from "../../services/api-services/estruturaExportacao";
import {
  NivelData,
  ProjetoDetails,
  useProjetoService
} from "../../services/api-services/projeto";
import { useProjetoFileService } from "../../services/api-services/projetoFile";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ImageInfoDetails } from "../../services/service";
import { CriarEstiloExportacao } from "../GerenciarEstiloExportacao/forms/criar-estilo-exportacao";
import { CriarEstruturaExportacao } from "../GerenciarEstruturaExportacao/forms/criar-estrutura-exportacao";
import { EditarTextoDivisao } from "./components/add-texto-divisao";
import { DivisaoItem } from "./components/divisao-item";
import { AssistenteEstrutura } from "./forms/assistente-estrutura";
import { useDivisoesProjetoContext } from "./forms/assistente-estrutura/context";
import { DivisoesProjetoProvider } from "./forms/assistente-estrutura/provider";
import {
  Filtros,
  PadraoExportacaoButtonOptions,
  ProjetoFooter,
  ProjetoForm
} from "./styles";

interface Props {
  id: string;
}

export const CriarProjeto: React.FC<{}> = () => {
  return (
    <DivisoesProjetoProvider>
      <CriarProjetoComponent />
    </DivisoesProjetoProvider>
  );
};

export const CriarProjetoComponent: React.FC<{}> = () => {
  //
  const auth = useAuthContext();

  const { id } = useParams<Props>();

  const assistenteContext = useDivisoesProjetoContext();

  const history = useHistory();

  const axiosInstance = APIFetch(auth);

  const services = {
    autorService: useAutorService(axiosInstance),
    projetoService: useProjetoService(axiosInstance),
    estiloexportacaoService: useEstiloExportacaoService(axiosInstance),
    estruturaexportacaoService: useEstruturaExportacaoService(axiosInstance),
    projetoFileService: useProjetoFileService(APIFileFetch(auth))
  };

  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentAction, setCurrentAction] = currentActionState;

  const [projeto, setProjeto] = useState<ProjetoDetails>();

  const [nomeProjeto, setNomeProjeto] = useState("");

  const [visibilidade, setVisibilidade] = useState<TItemSelect>(visibilidadeProjeto[0]);

  const autores = useTagAction();

  const listarAutoresAPI = (page: number, value: string) => {
    return services.autorService.listarAutores(page, 30, value);
  };

  const listarEstilosExportacao = (page: number, value: string) => {
    return services.estiloexportacaoService.listarEstiloExportacaos(
      page,
      30,
      value
    );
  };

  const listarEstruturasExportacao = (page: number, value: string) => {
    return services.estruturaexportacaoService.listarEstruturaExportacaos(
      page,
      30,
      value
    );
  };

  const options = () => {
    switch (currentAction.action) {
      case "criar-estilo-exportacao": {
        return <CriarEstiloExportacao externalAction={currentActionState} />;
      }
      case "criar-estrutura-exportacao": {
        return <CriarEstruturaExportacao externalAction={currentActionState} />;
      }
      case "assistente-estrutura": {
        return <AssistenteEstrutura externalAction={currentActionState} />;
      }
      case "editar-texto-divisao": {
        return <EditarTextoDivisao action={currentActionState} />;
      }
      default: {
        return <Fragment />;
      }
    }
  };

  const [anoSelect, setAnoSelect] = useState({
    id: -1,
    key: "",
    useId: true,
    value: "",
    selected: false,
  });


  const [estiloExportacao, setEstiloExportacao] = useState({
    id: -1,
    key: "",
    useId: true,
    value: "",
  });

  const [estruturaExportacao, setEstruturaExportacao] = useState({
    id: -1,
    key: "",
    useId: true,
    value: "",
  });

  const [imagemProjeto, setImagemProjeto] = useState<ImageInfoDetails>();

  useEffect(() => {
    if (id) {
      services.projetoService
        .buscarProjeto(parseInt(id))
        .then((projeto) => {
          setProjeto(projeto);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  }, []);

  useEffect(() => {
    if (projeto) {

      setNomeProjeto(projeto.name);

      setImagemProjeto(projeto.image);

      const visibilidadeEncontrada: TItemSelect | undefined = visibilidadeProjeto.find((value) => value.value === projeto.visibility);

      if (visibilidadeEncontrada) {
        setVisibilidade(visibilidadeEncontrada);
      }

      const anoSelectFind = getYears().find(
        (e) => e.value === "" + projeto.year
      );

      setAnoSelect(
        anoSelectFind
          ? anoSelectFind
          : {
            id: -1,
            key: "",
            useId: true,
            value: "",
            selected: false,
          }
      );

      if (projeto.export_style) {
        setEstiloExportacao({
          id: projeto.export_style.id,
          key: "export-style",
          useId: true,
          value: projeto.export_style.style_name,
        });
      }

      if (projeto.export_structure) {
        setEstruturaExportacao({
          id: projeto.export_structure.id,
          key: "export-structure",
          useId: true,
          value: projeto.export_structure.structure_name,
        });
      }

      autores.addAllTags(
        projeto.authors.map((autor) => {
          const item: ItemSelect = {
            id: autor.author.id,
            value: autor.author.name,
            key: "authorName",
            useId: false,
          };

          return item;
        })
      );
    }
  }, [projeto]);

  const uploadImagemProjeto = (formData: FormData, id: string) => {
    return services.projetoFileService.salvarImagemProjeto(formData, Number(id));
  }

  const deletarImagem = (idImagem: string, id: string) => {
    return services.projetoFileService.deletarImagemProjeto(idImagem, Number(id));
  }

  const salvarProjeto = () => {
    const authors = autores.getTags().map((item) => {
      return {
        selected_author: item.id,
      };
    });

    const year = `01/01/${anoSelect.value}`;

    if (projeto) {

      services.projetoService
        .editarProjeto({
          id: projeto.id,
          name: nomeProjeto,
          year: year,
          visibility: visibilidade.value,
          selected_export_structure_id: estruturaExportacao.id >= 1 ? estruturaExportacao.id : undefined,
          selected_export_style_id: estiloExportacao.id >= 1 ? estiloExportacao.id : undefined,
          authors: authors,
        })
        .then(() => {
          alert("Projeto alterado com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });

    } else {

      if (assistenteContext.niveis.length >= 1) {

        const division_levels: NivelData[] = assistenteContext.niveis.map(
          (value) => {
            const nivel: NivelData = {
              type: value.ordem + "",
              metadado: value.fonte
            }

            return nivel;
          }
        );

        const sort = assistenteContext.parametrosOrdenacao.getTags().map((value) => {
          return {
            parameter: value.id as string,
            order: value.object ? value.object : "asc"
          }
        });

        services.projetoService
          .salvarProjeto({
            name: nomeProjeto,
            divisions: assistenteContext.layout,
            year: year,
            configuration: {
              division_levels: {
                levels: division_levels,
                sort: sort
              },
            },
            visibility: visibilidade.value,
            selected_export_structure_id: estruturaExportacao.id >= 1 ? estruturaExportacao.id : undefined,
            selected_export_style_id: estiloExportacao.id >= 1 ? estiloExportacao.id : undefined,
            authors: authors,
          })
          .then((response) => {
            alert("Projeto salvo com sucesso!");
            history.push(`/projeto/editar/${response.id}`)
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      } else {
        alert(
          "Para salvar este projeto, crie um estrutura padrão para o projeto usando o assistente de estrutura."
        );
      }
    }
  };

  const onPosUploadImage = (value?: ImageInfoDetails) => {
    setImagemProjeto(value);
  }

  const isEditar = () => {
    if (id) {
      return true;
    }
    return false;
  };

  const opcoesPadraoExportacao = () => {
    return (
      <PadraoExportacaoButtonOptions>
        <ButtonBottomLess
          title="Novo estilo"
          className=""
          icon={IoAdd}
          onClick={() => {
            setCurrentAction({ id: 0, action: "criar-estilo-exportacao" });
          }}
        />
        <ButtonBottomLess
          title="Novo formato"
          className=""
          icon={IoAdd}
          onClick={() => {
            setCurrentAction({ id: 0, action: "criar-estrutura-exportacao" });
          }}
        />
      </PadraoExportacaoButtonOptions>
    );
  };

  const renderDivisao = (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<DivisaoData>,
    zone: DropZoneData<DivisaoData>,
    data?: DivisaoData | undefined
  ) => {
    return (
      <DivisaoItem
        type="criar"
        dragTarget={undefined}
        externalAction={currentActionState}
        zone={zone}
        data={data}
        authInstance={axiosInstance}
        features={features}
      />
    );
  };

  const canDropItem = async (
    dropZone: DropZoneData<DivisaoData>,
    item: DropZoneData<DivisaoData>,
    layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  ) => {
    return false;
  };

  const estruturaStatus = () => {
    if (assistenteContext.niveis && assistenteContext.nivel) {
      return <StatusComponent
        classname={"estrutura-definida-status"}
        color={'var(--color-primary-darking)'}
        icon={BsCheckCircle}
        status={"Estrutura definida"}
      />
    } else {
      return <StatusComponent
        classname={"estrutura-definida-status"}
        color={'var(--color-primary-darking)'}
        icon={AiOutlineInfoCircle}
        status={"Estrutura não definida"}
      />
    }
  }

  return (
    <Fragment>
      <BaseComponent
        pageName={!isEditar() ? "Criar projeto" : `Editar projeto - #${id}`}
        headerLeft={
          !isEditar() && (
            <Fragment>
              {estruturaStatus()}
              <ButtonNormal
                title={"Definir Estrutura"}
                className={"questao-button"}
                onClick={() => {
                  setCurrentAction({ id: 0, action: "assistente-estrutura" });
                }}
              />
            </Fragment>
          )
        }
      >
        <ProjetoForm>
          <div className="informacoes-content">
            <div className="sobre-projeto-content">
              <TitleIcon name="Sobre projeto" icon={CgDetailsMore} />
              <div className="form-group">
                <div className="field-group">
                  <label>Nome</label>
                  <InputForm
                    minWidth="300px"
                    className="lei"
                    type="text"
                    value={nomeProjeto}
                    onChange={(value) =>
                      setNomeProjeto(value.currentTarget.value)
                    }
                    placeholder="Informe nome do projeto"
                  />
                </div>
                <div className="field-group">
                  <label>Ano</label>
                  <NormalSelect
                    label="Selecione o ano"
                    minWidth={200}
                    className="ano"
                    onSelectCurrentItem={(item) =>
                      setAnoSelect({
                        ...anoSelect,
                        "id": item.id,
                        "value": item.value,
                      })
                    }
                    selectedItems={getYears()}
                    selectItem={anoSelect}
                  />
                </div>
                <div className="field-group">
                  <label>Visibilidade</label>
                  <NormalSelect
                    minWidth={200}
                    className="visibilidade"
                    selectItem={visibilidade}
                    onSelectCurrentItem={(item) => {
                      setVisibilidade(item);
                    }}
                    selectedItems={visibilidadeProjeto}
                  />
                </div>
              </div>
            </div>
            <div className="autores-content">
              <TitleIcon name="Autores do projeto" icon={FaUserFriends} />
              <div className="form-group">
                <div className="field-group">
                  <label>Autor</label>
                  <AsyncSelect
                    className="autor"
                    showSelectItem={true}
                    label="Selecione um autor"
                    onAddFilterItem={(item) => autores.addTag(item)}
                    getDataAPI={listarAutoresAPI}
                  />
                </div>
              </div>
              <Filtros className="field-group">
                {autores.getTags().map((item, index) => {
                  return (
                    <FilterTag
                      key={index}
                      nome={item.value}
                      onMouseClick={() => autores.removeTag(item)}
                    />
                  );
                })}
              </Filtros>
            </div>
          </div>
          <Fragment>
            {visibilidade.value === "Público" && id &&
              <SelecaoImagem
                referenciaImagem={"projeto"}
                onUploadImage={uploadImagemProjeto}
                onDeleteImage={deletarImagem}
                idEntidade={id}
                imagemProjeto={imagemProjeto}
                onPosUpload={onPosUploadImage} />
            }
          </Fragment>
          {visibilidade.value === "Interno" &&
            <Fragment>
              <TitleIcon
                name="Padrão de exportação"
                icon={AiOutlineFileWord}
                extra={opcoesPadraoExportacao()}
              />
              <div className="form-group">
                <div className="field-group">
                  <label>Estilo da questão</label>
                  <AsyncSelect
                    className="estilo"
                    showSelectItem={true}
                    selectItem={estiloExportacao}
                    onAddFilterItem={(item) => {
                      setEstiloExportacao({
                        ...estiloExportacao,
                        "id": item.id,
                        "value": item.value,
                      });
                    }}
                    label="Selecione um estilo"
                    getDataAPI={listarEstilosExportacao}
                  />
                </div>
                <div className="field-group">
                  <label>Formato da questão</label>
                  <AsyncSelect
                    className="estrutura"
                    showSelectItem={true}
                    selectItem={estruturaExportacao}
                    onAddFilterItem={(item) => {
                      setEstruturaExportacao({
                        ...estruturaExportacao,
                        "id": item.id,
                        "value": item.value,
                      });
                    }}
                    label="Selecione um formato"
                    getDataAPI={listarEstruturasExportacao}
                  />
                </div>
              </div>
            </Fragment>}
          <ProjetoFooter>
            <ButtonNormal
              icon={VscSave}
              title={"Salvar"}
              className={"save-projeto"}
              onClick={salvarProjeto}
            />
          </ProjetoFooter>
        </ProjetoForm>
        {!isEditar() && assistenteContext.layout.length > 0 && (
          <DragAndDropList
            dragAndDropName={`listaDivisoesCriada`}
            renderItem={renderDivisao}
            context={assistenteContext}
            canDropItem={canDropItem}
            maxLevel={assistenteContext.niveis.length}
          />
        )}
      </BaseComponent>
      {options()}
    </Fragment >
  );
};
