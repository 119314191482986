import React, { Fragment, useEffect, useState } from "react";
import { FaRegComments, FaRegFileCode } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { SiMicrosoftword } from "react-icons/si";
import { VscListOrdered } from "react-icons/vsc";
import Loader from "react-loader-spinner";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckLabel } from "../../../../components/check-label";
import { CheckBox } from "../../../../components/checkbox";
import { FilterTag } from "../../../../components/filter-tag";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { SelectedTagActions, useTagAction } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { GroupMain } from "../../../../components/page/structure/Group/styles";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../../../components/select/async";
import { NormalSelect } from "../../../../components/select/normal";
import { TitleIcon } from "../../../../components/title-icon";
import { ordenacaoParams, paramsOptions } from "../../../../mocks/data";
import { APIFetch } from "../../../../services/api";
import { useAutorService } from "../../../../services/api-services/autores";
import { usePerfilQuestoesService } from "../../../../services/api-services/perfilQuestoes";
import { QuestaoProjetoItem, useQuestaoProjetoService } from "../../../../services/api-services/questaoProjeto";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { Filtros } from "../../../CriarProjeto/styles";
import { ProcessoStatus } from "../../../ImportacaoQuestoes";
import { ExportacaoBar, ExportacaoContent, ExportacaoModal, TagItemContent } from "./styles";

export interface TExportacaoQuestoes {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  idProjeto: number;
  ids?: ItemDataSelect<QuestaoProjetoItem>[],
  search_filter?: {
    filters: string[];
    search: string,
    search_content: string,
    period: string
  }

}

export const ExportacaoQuestoes: React.FC<TExportacaoQuestoes> = ({ externalAction, idProjeto, ids, search_filter }) => {
  const auth = useAuthContext();

  const currentFormState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentForm, setCurrentForm] = currentFormState;

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const [mostrarOrdenacao, setMostrarOrdenacao] = useState<boolean>();

  const services = {
    questaoProjetoService: useQuestaoProjetoService(APIFetch(auth)),
    autorService: useAutorService(APIFetch(auth)),
    perfilQuestoesService: usePerfilQuestoesService(APIFetch(auth)),
  };

  const [status, setStatus] = useState<ProcessoStatus>(
    ProcessoStatus.INICIO
  );

  const [ordem, setOrdem] = useState({
    id: -1,
    key: '',
    useId: true,
    value: '',
    selected: false,
  });

  const [exportarComentarios, setExportarComentarios] = useState<boolean>(false);

  const autores = useTagAction();

  const perfilsQuestao = useTagAction();

  const parametros = useTagAction();

  useEffect(() => {
    if (
      status === ProcessoStatus.FINALIZADO ||
      status === ProcessoStatus.ERRO
    ) {
      reset();
    }
  }, [status]);

  const baixarQuestoesDoProjeto = (tipoArquivo: string) => {
    if (status === ProcessoStatus.INICIO) {
      setStatus(ProcessoStatus.PROCESSANDO);

      const params = parametros.getTags().map((item) => item.value);

      if (ids) {

        const questoes = ids.map((item) => item.entity.question.id);

        services.questaoProjetoService.questaoProjeto
          .downloadQuestoesSelecionadasDoProjeto(idProjeto, ordem.key, params, autores.getTagsString(","), perfilsQuestao.getTagsString(","), exportarComentarios, questoes, tipoArquivo)
          .then((data) => {

            if (data) {
              setStatus(ProcessoStatus.FINALIZADO);
              alert("Arquivo baixado com sucesso!");
            }

          })
          .catch(() => {
            setStatus(ProcessoStatus.ERRO);
            alert("Ocorreu um erro não foi possível processar o arquivo para download!");
          });
      } else if (search_filter) {

        services.questaoProjetoService.questaoProjeto
          .downloadQuestoesDoProjeto(idProjeto, search_filter.period, search_filter.filters, search_filter.search, search_filter.search_content, ordem.key, params, autores.getTagsString(","), perfilsQuestao.getTagsString(","), exportarComentarios, tipoArquivo)
          .then((data) => {

            if (data) {
              setStatus(ProcessoStatus.FINALIZADO);
              alert("Arquivo baixado com sucesso!");
            }

          })
          .catch(() => {
            setStatus(ProcessoStatus.ERRO);
            alert("Ocorreu um erro não foi possível processar o arquivo para download!");
          });
      }



    } else {
      alert("Aguarde o processamento do arquivo...");
    }
  }

  const reset = () => {
    setStatus(ProcessoStatus.INICIO);
  };

  const options = () => {
    switch (currentForm.action) {

      default: {
        return <Fragment />;
      }
    }
  };

  const OrdenacaoQuestao = () => {
    return <Fragment>
      <TitleIcon
        icon={VscListOrdered}
        name={`Ordem das questões`}
        extra={<CheckLabel label={"Usar ordenação padrão"} />}
      />
      <div className="form-group">
        <div className="field-group">
          <label>Ordem</label>
          <NormalSelect
            minWidth={200}
            className="ordem"
            label="Selecione a ordem"
            selectItem={ordem}
            onSelectCurrentItem={(item) => setOrdem(item)}
            selectedItems={ordenacaoParams}
          />
        </div>
        <div className="field-group">
          <label>Parâmetro</label>
          <NormalSelect
            minWidth={200}
            className="parametro"
            label="Selecione um parâmetro"
            onSelectCurrentItem={(item) => parametros.addTag(item)}
            selectedItems={paramsOptions}
          />
        </div>
        <TagItem action={parametros} label={"Ordenar por: "} classname={"field-group"} />
      </div>
    </Fragment>
  }

  const ComentariosFiltros = () => {
    return (
      <div>
        <TitleIcon
          icon={FaRegComments}
          name={`Comentários`}
        />
        <p className={'title'}>Para filtrar comentários especificos para exportação utilize os filtros abaixo: </p>
        <GroupMain>
          <AsyncSelect
            className="perfil field-group"
            label="Filtrar por perfil de questão"
            onAddFilterItem={(item) => { perfilsQuestao.addTag(item) }}
            getDataAPI={(page, value) => services.perfilQuestoesService.listarPerfils(page, 30, value)}
          />
          <AsyncSelect
            className="autor field-group"
            label="Filtrar por autor"
            onAddFilterItem={(item) => { autores.addTag(item) }}
            getDataAPI={(page, value) => services.autorService.listarAutores(page, 30, value)}
          />
        </GroupMain>
        <TagItem action={perfilsQuestao} label={"Perfils de questão escolhidos"} classname={"field-group"} />
        <TagItem action={autores} label={"Autores escolhidos"} classname={"field-group"} />
      </div>
    );
  };

  const footer = () => {
    return (
      <Fragment />
    );
  };

  return (
    <Modal
      width={1000}
      action={actionState}
      options={footer()}
      title={"Exportar questões"}
    >
      <ExportacaoModal>
        <ExportacaoContent>
          <ExportacaoBar>
            <div className="title-bar">
              <p>Selecione as configurações de exportação</p>
            </div>
            <div className="buttons-options">
              {status === ProcessoStatus.PROCESSANDO && (
                <div className="loading">
                  <Loader
                    type="Oval"
                    color="var(--color-primary-dark)"
                    height={28}
                    width={28}
                    timeout={10000000000000}
                  />
                  <small>Aguarde, o arquivo está sendo preparado para download...</small>
                </div>
              )}
              {ids &&
                <ButtonBottomLess
                  title={"Alterar ordem"}
                  onClick={() => {
                    mostrarOrdenacao ? setMostrarOrdenacao(false) : setMostrarOrdenacao(true);
                  }}
                  className="alterar-ordem margin"
                />}
              <ButtonBottomLess
                onClick={() => baixarQuestoesDoProjeto("word")}
                className="word-button margin"
                sizeIcon={28}
                icon={SiMicrosoftword}
              />
              <ButtonBottomLess
                onClick={() => baixarQuestoesDoProjeto("xml")}
                className="xml-button margin"
                sizeIcon={28}
                icon={FaRegFileCode}
              />
              <ButtonBottomLess
                onClick={() => baixarQuestoesDoProjeto("gift")}
                className="gift-button margin"
                sizeIcon={28}
                icon={IoMdSchool}
              />
            </div>
          </ExportacaoBar>
          <div className={"exportar-comentarios-option"}>
            <CheckBox
              label={"Exportar comentários"}
              checked={exportarComentarios}
              onChange={(event) => {
                setExportarComentarios(event.currentTarget.checked)
              }}
            />
          </div>
          {mostrarOrdenacao && OrdenacaoQuestao()}
          {exportarComentarios && ComentariosFiltros()}
        </ExportacaoContent>
        {options()}
      </ExportacaoModal>
    </Modal>
  );
};

export const TagItem: React.FC<{ action: SelectedTagActions, label: string, classname?: string }> = ({ action, label, classname }) => {
  return <TagItemContent className={classname}>
    {action.getTags().length >= 1 &&
      <div className={"filtros"}>
        <small className={"label"}>{label}</small>
        <Filtros>
          {action.getTags().map((item, index) => {
            return <FilterTag key={index} nome={item.value} onMouseClick={() => action.removeTag(item)} />;
          })}
        </Filtros>
      </div>
    }
  </TagItemContent>
}