import React, { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../components/select/async";
import { NormalSelect } from "../../components/select/normal";
import { SelectOption } from "../../components/select/option";
import { getYears } from "../../components/util/DateUtil";
import { nivelProva, provaOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useBancaService } from "../../services/api-services/banca";
import { useCargoService } from "../../services/api-services/cargo";
import { useOrgaoService } from "../../services/api-services/orgao";
import {
  ProvaDetailsCount,
  useProvaService,
} from "../../services/api-services/prova";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarProva } from "./forms/criar-prova";
import { ExcluirProva } from "./forms/excluir-prova";
import { ProvaCard } from "./views/card-questao";
import { ProvaHeader } from "./views/row-prova";
import { ThreeNormalOption } from "../../components/menu/three-normal";
import { FormAtribuirCarreiraEmLote } from "./forms/atribuir-carreira-em-lote";
import { useCarreiraService } from "../../services/api-services/carreira";

export const GerenciarProvasComponent = () => {
  const auth = useAuthContext();

  const { roles } = auth.user;

  const context = useGerenciarContext();

  const services = {
    provaService: useProvaService(APIFetch(auth)),
    orgaoService: useOrgaoService(APIFetch(auth)),
    bancaService: useBancaService(APIFetch(auth)),
    cargoService: useCargoService(APIFetch(auth)),
    carreiraService: useCarreiraService(APIFetch(auth))
  };

  ///////////////////////////////////////////////////

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("item");

  const [period, setPeriod] = useState("");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const provaState = useState<Array<ItemDataSelect<ProvaDetailsCount>>>([]);
  const setProvas = provaState[1];

  const useTagActions = useTagAction();

  const selectedEntitiesAction = useSelectedDataAction<ProvaDetailsCount>({
    state: provaState,
  });

  const keys: string[] = ["body", "bank", "office", "level", "year", "career"];

  useEffect(() => {
    if (context.loading) {
      services.provaService
        .listagemProva(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          period,
          useTagActions.getFilters(keys),
          search,
          sort,
          order,
          mode
        )
        .then((provas) => {
          setProvas(
            provas.content.map((value: ProvaDetailsCount) => {
              return {
                isChecked: false,
                id_item: value.data.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(provas.paginationData);
          context.toPage(provas.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return !roles.includes("ROLE_CLASSIFICADOR") ? (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Nova prova"}
          className={"prova-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar-prova" });
          }}
        />
      </Fragment>
    ) : (
      <Fragment />
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirProva />;
      }
      case "validacao": {
        return <FormAtribuirCarreiraEmLote ids={selectedEntitiesAction.getSelectItems()} />
      }
      case "criar-prova": {
        return <CriarProva />;
      }
      case "editar-prova": {
        return <CriarProva />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Prova" isOpen={true}>
          <GroupMain>
            <AsyncSelect
              className="orgao field-group"
              label="Filtrar por orgão"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                services.orgaoService.listarOrgaos(page, 30, value)
              }
            />
            <AsyncSelect
              className="banca field-group"
              label="Filtrar por banca"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                services.bancaService.listarBancas(page, 30, value)
              }
            />
            <AsyncSelect
              className="cargo field-group"
              label="Filtrar por cargo"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                services.cargoService.listarCargos(page, 30, value)
              }
            />
            <AsyncSelect
              className="carreira field-group"
              label="Carreira"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                services.carreiraService.listarCarreiras(page, 30, value)
              }
            />
            <SelectOption
              className="nivel field-group"
              label="Filtrar por nível"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={nivelProva}
            />
            <NormalSelect
              minWidth={64}
              className="ano field-group"
              label="Filtrar por ano realização"
              onSelectCurrentItem={(item) => useTagActions.addTag(item)}
              selectedItems={getYears()}
            />
          </GroupMain>
        </Group>
      </Fragment>
    );
  };

  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(true);
  };

  const opcoes = () => {
    if (roles.includes("ROLE_CLASSIFICADOR")) {
      return [];
    } else {
      return [
        <ThreeNormalOption
          name="Atribuir carreira [Em lote]"
          onClick={() => {
            if (selectedEntitiesAction.getSelectItems().length == 0) {
              alert("Você precisa selecionar ao menos uma prova..");
            } else {
              context.changeAction({
                action: "validacao",
                id: 0,
              });
            }
          }}
        />,
      ];
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<ProvaDetailsCount>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={provaState}
        filters={filters()}
        filterBar={{
          left: (
            <GroupButtons
              initialPositionSelected={3}
              buttons={[
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "hoje",
                  classname: "period-option",
                  value: "day",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "semana",
                  classname: "period-option",
                  value: "week",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "mês",
                  classname: "period-option",
                  value: "month",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "todos",
                  classname: "period-option",
                  value: "",
                },
              ]}
            />
          ),
        }}
        page={{
          name: roles.includes("ROLE_CLASSIFICADOR")
            ? "Início - Provas"
            : "Gerenciar Provas",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder:
            "Pesquisar por orgão,banca, cargo ou número exame OAB...",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: provaOrdenacao,
          },
          options: opcoes(),
          mode: mode,
          card: {
            dataColumn: ProvaCard(selectedEntitiesAction, mode).provaCard,
          },
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Orgão</div>,
              <div>Banca</div>,
              <div>Cargo</div>,
              <div>Carreira</div>,
              <div>Ano de realização</div>,
              <div>Nível</div>,
              <div>Número exame OAB</div>,
              <div>Ações</div>,
            ],
            dataColumn: ProvaHeader(selectedEntitiesAction).provaHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarProvas = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarProvasComponent />
    </GerenciarBaseProvider>
  );
};
