import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { perfilQuestoesOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { PerfilQuestoes, usePerfilQuestoesService } from "../../services/api-services/perfilQuestoes";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarPerfilQuestao } from "./forms/criar-perfil-questao";
import { ExcluirPerfilQuestao } from "./forms/excluir-perfil-questao";
import { PerfilQuestoesHeader } from "./views/row-perfil-questoes";

export const GerenciarPerfilQuestoesComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const perfilQuestoesService = usePerfilQuestoesService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const perfilQuestoesState = useState<Array<ItemDataSelect<PerfilQuestoes>>>([]);
  const setPerfil = perfilQuestoesState[1];

  const selectedEntitiesAction = useSelectedDataAction<PerfilQuestoes>({
    state: perfilQuestoesState,
  });

  useEffect(() => {
    if (context.loading) {
      perfilQuestoesService
        .listagemPerfil(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((perfils) => {
          setPerfil(
            perfils.content.map((value: PerfilQuestoes) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(perfils.paginationData);
          context.toPage(perfils.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo Perfil de questão"}
          className={"Perfil-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar" });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirPerfilQuestao />;
      }
      case "criar": {
        return <CriarPerfilQuestao />;
      }
      case "editar": {
        return <CriarPerfilQuestao />;
      }

      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<PerfilQuestoes>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={perfilQuestoesState}
        page={{
          name: "Listagem de Perfil de questões",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do perfil",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: perfilQuestoesOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [<div>ID</div>, <div>Nome</div>, <div>Ações</div>],
            dataColumn: PerfilQuestoesHeader(selectedEntitiesAction).header,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarPerfilQuestoes = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarPerfilQuestoesComponent />
    </GerenciarBaseProvider>
  );
};
