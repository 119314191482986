import { StatusComponent } from "jusadmin-ui-kit";
import { Fragment } from "react";
import { AiOutlineTags } from "react-icons/ai";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { MdBadge, MdOutlineAdd } from "react-icons/md";
import { VscOpenPreview } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { CountTag } from "../../../../components/page/structure/CountTag";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ProvaDetailsCount } from "../../../../services/api-services/prova";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { CodigoID } from "../../../GerenciarComentarios/elements";
import { Validation } from "../../../GerenciarQuestoes/elements";
import { getHeaderProva } from "../../../GerenciarQuestoes/views/visualizar-questao/enunciado";
import {
  FooterLeft,
  FooterRight,
  FooterStyle,
  HeaderStyle
} from "./styles";

export const ProvaCard = (
  selectedEntitiesAction: SelectedDataActions<ProvaDetailsCount>,
  mode: string
) => {

  const ProvaCardComponent = (
    elem: ItemDataSelect<ProvaDetailsCount>,
    index: number
  ) => {
    const context = useGerenciarContext();
    const { data } = elem.entity;
    const auth = useAuthContext();
    const { roles } = auth.user;

    return (
      <CardItem
        settings={{
          width: "100%",
        }}
        key={index}
        header={
          <ProvaCardHeader
            elem={elem}
            index={index}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
        body={<Fragment />}
        footer={<ProvaCardFooter prova={elem.entity} />}
        options={!roles.includes("ROLE_CLASSIFICADOR") ? [
          <ThreeNormalOption
            name="Editar"
            onClick={() => {
              context.changeAction({
                action: "editar-prova",
                id: data.id,
              });
            }}
          />,
          <ThreeNormalOption
            name="Excluir"
            onClick={() => {
              context.changeAction({
                action: "excluir",
                id: data.id,
              });
            }}
          />,
        ] : []}
      />
    );
  };

  return {
    provaCard: ProvaCardComponent,
  };
};

const ProvaCardHeader: React.FC<{
  index: number;
  elem: ItemDataSelect<ProvaDetailsCount>;
  mode: string;
  selectedEntitiesAction: SelectedDataActions<ProvaDetailsCount>;
}> = (props) => {

  const { data } = props.elem.entity;

  return (
    <HeaderStyle>
      <CheckBox
        checked={props.selectedEntitiesAction.checkSelect(props.elem.id_item)}
        onChange={(e) => {
          props.selectedEntitiesAction.checkItem(props.index, e);
        }}
      />
      <CodigoID
        codigo={""}
        id={data.id}
        classname={"item-margin"} title={""} />
      <div className="header-prova">
        <span>{getHeaderProva(data)}</span>
        {data.career && <StatusComponent
          classname={""}
          color={"var(--color-primary-darking)"}
          icon={MdBadge}
          status={`Carreira: ${data.career.name}`} />
        }
      </div>

    </HeaderStyle>
  );
};


const ProvaCardFooter: React.FC<{ prova: ProvaDetailsCount }> = ({ prova }) => {

  const auth = useAuthContext();
  const { roles } = auth.user;

  const { data, is_classified, total_questions, is_validated, total_pending_questions, total_unclassified_questions } = prova;

  return (
    <Fragment>
      <FooterStyle className={"item-margin"}>
        <FooterRight>
          <CountTag
            count={total_questions}
            title="questões"
            classname={"item-margin"}
          />
          {total_questions >= 1 && <>
            <CountTag
              count={total_pending_questions}
              title="pendentes"
              classname={"item-margin"}
            />
            <CountTag
              count={total_unclassified_questions}
              title="sem classificação"
              classname={"item-margin"}
            />
            <Validation
              value={is_validated ? "Validada" : "Não validada"}
              mode={"card"}
              classname={"item-margin"}
            />
            {
              is_classified ? <StatusComponent
                classname={""}
                color={"var(--color-primary-darking)"}
                icon={AiOutlineTags}
                status={"Classificada"}
              /> : <StatusComponent
                classname={""}
                color={"var(--color-primary-darking)"}
                icon={HiOutlineExclamationCircle}
                status={"Não classificada"}
              />
            }
          </>}
        </FooterRight>
        <FooterLeft>
          <Link to={`/gerenciar-questoes?exam=${data.id}`}>
            <ButtonBottomLess
              className="button-view"
              title={"Ver questões"}
              icon={VscOpenPreview}
            />
          </Link>
          {!roles.includes("ROLE_CLASSIFICADOR") &&
            <ButtonBottomLess
              className="button-view"
              title={"Criar questão"}
              icon={MdOutlineAdd}
              onClick={() => {
                window.open(`/questao?exam=${data.id}`, "_blank");
              }}
            />
          }
        </FooterLeft>
      </FooterStyle>
    </Fragment>
  );
};
