import styled from "styled-components";

export interface ListaReferenciasLegaisStyleProps {
  agruparAcima: boolean;
}

export const ListaReferenciasLegaisStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: -22px;


  .referencias-list {
    width: 100%;
  }

  .referencias-legais-container {
    width: 100%;
  }

  ${(value: ListaReferenciasLegaisStyleProps) =>
    value.agruparAcima
      ? `::before {
    bottom: -10px;
    margin-bottom: -8px;
    position: relative;
    width: 98%;
    height: 18px;
    background: rgb(255, 148, 148);
    background: linear-gradient(
      90deg,
      rgba(255, 148, 148, 1) 0%,
      rgba(249, 203, 203, 1) 49%,
      rgba(255, 148, 148, 1) 100%
    );
    content: "";
  }`
      : ``}
`;

export const ReferenciasOpcoes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
`;

export const ReferenciasLegaisContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 24px;
  border-radius: 0px 0px 12px 12px;
`;

export const Referencias = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  margin-bottom: 24px;
`;

export const ReferenciaItemBase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .referencia-content {
    display: flex;
    flex-direction: row;
    flex: 3 1;
    flex-wrap: nowrap;
    align-items: center;

    .number {
      color: var(--color-primary-dark);
      font-weight: 500;
      font-size: 1.1rem;
      margin-right: 12px;
    }

    .dispositivos-legais .label {
      color: var(--color-text-secondary);
      font-weight: 500;
      font-size: 0.9rem;
      margin-left: 12px;
    }

    .dispositivos-legais {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .dispositivo {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2px;
        flex-wrap: wrap;

        .path-arrow {
          margin-left: 2px;
        }
      }
    }
  }

  .referencia-options {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const ReferenciaItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--color-primary-light-clear);
  padding: 12px;
  margin: 12px;
  box-shadow: 4px 0 5px 0 rgb(0 0 0 / 8%);
`;

export const ReferenciaLegalForm = styled.div`
  padding: 18px;
  margin: 12px;
  border: 2px solid var(--color-primary-light-clear);
  border-radius: 8px;

  .lei,
  .artigo {
    min-width: 240px;

    @media (max-width: 700px) {
      min-width: 100%;
    }
  }

  .add-referencia {
    margin-top: 38px;
  }

  .dispositivos-options {
    margin-top: 32px;
    width: 110px;
  }

  .remover-dispositivo {
    height: 38px;
    margin-top: 32px;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 4px;
    margin-bottom: 4px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;

      label b {
        text-transform: none;
      }

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      .error {
        color: #db2269;
        font-size: 0.625em;
        position: relative;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
