import styled from "styled-components";

export interface SidebarModalOptions {
  width?: number;
}

export const SidebarModalContent = styled.div`
  .p-style {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    height: 100%;
    width: 100%;

    ::-webkit-scrollbar {
      width: 4px;
      display: block;
    }

    ::-webkit-scrollbar-track {
      background-color: var(--color-background);
      opacity: 0.6;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--color-content-border);
      border-radius: 20px;
  }

  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--color-select-border);
    height: 70px;
    width: 100%;
    padding: 8px;

    h3 {
      margin-left: 18px;
      font-weight: 700;
      font-size: 1.1rem;
      color: var(--color-text-dark);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin: 0;
  padding: 8px;
  max-height: ${(options: SidebarModalOptions) =>
    options.width ? `${options.width}px` : "520px"};
  background-color: var(--color-content-light);
  box-shadow: -4px 2px 25px 0px rgba(0, 0, 0, 0.1);
  height: 98%;
  max-width: 78%;
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 998;

  @media (max-width: 700px) {
    width: 90%;
    height: 90%;
    max-width: none;
    position: fixed;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 998;
  }
`;

export const MenuTitle = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-content-border);
  height: auto;
  width: 100%;
  padding: 8px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--color-content-border);
  height: auto;
  width: 100%;
  padding: 8px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
  }
`;

export const Background = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 996;
  background-color: rgba(41, 41, 41, 0.8);
  position: fixed;
  cursor: pointer;
`;
