import styled from "styled-components";

export const MesclarEntidadesStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const MesclarEntidadesContent = styled.div`
  background-color: var(--color-background);
  border-radius: 12px;

  .main-table {
    height: 340px;
    width: 100%;
    background-color: var(--color-background);

    .header-item{
      padding: 2px;
    }
  }
`;

export const MesclarEntidadeModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 8px;

`;
