import { Dispatch, SetStateAction } from "react";
import { QuestaoPesquisaOpcoes } from "..";

export interface TPesquisaQuestaoCheckHandler {
  filtrosState: [
    QuestaoPesquisaOpcoes[],
    Dispatch<SetStateAction<QuestaoPesquisaOpcoes[]>>
  ];
  filtrarTodosState: [
    QuestaoPesquisaOpcoes,
    Dispatch<SetStateAction<QuestaoPesquisaOpcoes>>
  ];
}

export const usePesquisaQuestaoCheckHandler = ({
  filtrosState,
  filtrarTodosState,
}: TPesquisaQuestaoCheckHandler) => {
  //
  const [filtros, setFiltros] = filtrosState;
  const [filtrarTodos, setFiltrarTodos] = filtrarTodosState;

  const handleCheckPesquisa = (
    event: React.FormEvent<HTMLInputElement>,
    pesquisa: QuestaoPesquisaOpcoes
  ) => {
    const checado = event.currentTarget.checked;

    if (checado !== undefined) {
      if (filtrarTodos.check) {
        setFiltrarTodos({
          check: false,
          label: filtrarTodos.label,
          valor: filtrarTodos.valor,
        });
      }

      const opcoes = filtros.map((o) => {
        if (o.valor === pesquisa.valor) {
          o.check = checado;
        }

        return o;
      });

      setFiltros([...opcoes]);
    }
  };

  const handleCheckAllPesquisa = (event: React.FormEvent<HTMLInputElement>) => {
    const checado = event.currentTarget.checked;

    if (checado !== undefined) {
      const found = filtros.find((v) => v.check === true);

      if (found && checado) {
        const opcoes = filtros.map((o) => {
          if (o.check === true) {
            o.check = false;
          }

          return o;
        });

        setFiltros([...opcoes]);
      }

      setFiltrarTodos({
        check: checado,
        label: filtrarTodos.label,
        valor: filtrarTodos.valor,
      });
    }
  };

  const getFiltros = () => {
    let filtrosValues = new Array<string>();

    if (filtrarTodos.check === true) {
      filtrosValues.push(filtrarTodos.valor);
    } else {
      filtrosValues = filtros
        .filter((value) => value.check)
        .map((value) => value.valor);
    }

    return filtrosValues.join(",");
  };

  return {
    handleCheckPesquisa: handleCheckPesquisa,
    handleCheckAllPesquisa: handleCheckAllPesquisa,
    getFiltros: getFiltros,
  };
};
