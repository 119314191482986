import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import { BiBold, BiItalic } from "react-icons/bi";
import { FiAlignCenter, FiAlignJustify, FiAlignLeft, FiAlignRight } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { ChangeIcon } from "../../../../components/change-icon";
import { GroupButtons, TButtonSelect } from "../../../../components/group-buttons";
import { InputForm } from "../../../../components/input";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { AsyncSelect } from "../../../../components/select/async";
import { TitleIcon } from "../../../../components/title-icon";
import useOnClickOutside from "../../../../components/util/ClickOutside";
import { APIFetch } from "../../../../services/api";
import { EstiloExportacaoService, EstiloPadraoRequest, useEstiloExportacaoService } from "../../../../services/api-services/estiloExportacao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ColorPickerContent, ColorPickerStyle, CriarEstiloExportacaoContent, EstiloExportacaoContent } from "./styles";

export interface TCriarEstiloExportacao {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

const alinhamentos: TButtonSelect[] = [
  {
    icon: FiAlignLeft,
    onClick: () => {
    },
    classname: "align-button",
    minWidth: 50,
    value: "left",
  },
  {
    icon: FiAlignCenter,
    onClick: () => { },
    classname: "align-button",
    minWidth: 50,
    value: "center",
  },
  {
    icon: FiAlignJustify,
    onClick: () => { },
    classname: "align-button",
    minWidth: 50,
    value: "justified",
  },
  {
    icon: FiAlignRight,
    onClick: () => { },
    classname: "align-button",
    minWidth: 50,
    value: "right",
  },
]

export const CriarEstiloExportacao: React.FC<TCriarEstiloExportacao> = ({ externalAction }) => {
  const auth = useAuthContext();

  const currentFormState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentForm, setCurrentForm] = currentFormState;

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const estiloexportacaoService = useEstiloExportacaoService(APIFetch(auth));

  const [nomeEstilo, setNomeEstilo] = useState<string>("");

  const headerState = useState<EstiloPadraoRequest>({
    bold: undefined,
    font: undefined,
    fontColor: undefined,
    fontSize: undefined,
    italic: undefined,
    paragraphAlignment: undefined,
    paragraphSpacing: undefined,
    textHighlightColor: undefined
  });

  const [headerConfig, setHeaderConfig] = headerState;


  const statementState = useState<EstiloPadraoRequest>({
    bold: undefined,
    font: undefined,
    fontColor: undefined,
    fontSize: undefined,
    italic: undefined,
    paragraphAlignment: undefined,
    paragraphSpacing: undefined,
    textHighlightColor: undefined
  });

  const [statementConfig, setStatementConfig] = statementState;

  const answersState = useState<EstiloPadraoRequest>({
    bold: undefined,
    font: undefined,
    fontColor: undefined,
    fontSize: undefined,
    italic: undefined,
    paragraphAlignment: undefined,
    paragraphSpacing: undefined,
    textHighlightColor: undefined
  });

  const [answersConfig, setAnswersConfig] = answersState;

  useEffect(() => {
    if (currentAction.action == "editar-estilo-exportacao") {
      estiloexportacaoService.buscarEstiloExportacao(currentAction.id).then((estilo) => {
        setNomeEstilo(estilo.style_name);
        setHeaderConfig(estilo.style.header);
        setStatementConfig(estilo.style.statement);
        setAnswersConfig(estilo.style.answers);
      });
    }

  }, []);


  const onSubmit = () => {

    if (currentAction.action == "editar-estilo-exportacao") {

      estiloexportacaoService.editarEstiloExportacao({
        id: currentAction.id,
        style_name: nomeEstilo,
        style: {
          answers: answersConfig,
          header: headerConfig,
          statement: statementConfig
        }
      }).then((response) => {
        changeAction({
          ...currentAction,
          loading: true,
        });
        alert("Estilo de Exportação salvo com sucesso!");
      })
        .catch((error) => {
          alert(error.response.data.message);
        });

    } else if (currentAction.action == "criar-estilo-exportacao") {

      estiloexportacaoService.salvarEstiloExportacao({
        style_name: nomeEstilo,
        style: {
          answers: answersConfig,
          header: headerConfig,
          statement: statementConfig
        }
      }).then((response) => {
        changeAction({
          ...currentAction,
          loading: true,
        });
        alert("Estilo de Exportação salvo com sucesso!");
      })
        .catch((error) => {
          alert(error.response.data.message);
        });

    }
  };


  const footer = () => {
    return (
      <ButtonNormal
        icon={VscSave}
        onClick={() => onSubmit()}
        title={"Salvar"}
        className={"save"}
        type={"button"}
      />
    );
  };

  return (
    <Modal
      width={900}
      action={actionState}
      options={footer()}
      title={
        currentAction.action == "criar-estilo-exportacao"
          ? "Novo estilo de exportação"
          : `Editar estilo de exportação #${currentAction.id}`
      }
    >
      <CriarEstiloExportacaoContent>
        <EstiloExportacaoContent>
          <div className="form-group">
            <div className="field-group">
              <label>Nome do estilo</label>
              <InputForm
                className="nome-estilo form-group"
                value={nomeEstilo}
                placeholder="Informe o nome"
                onChange={(value) => {
                  setNomeEstilo(value.target.value);
                }}
              />
            </div>
          </div>
          <PadraoEstiloForm estilo={headerConfig} setEstilo={setHeaderConfig} titulo={"1. Cabeçalho (Prova)"} estiloexportacaoService={estiloexportacaoService} />
          <PadraoEstiloForm estilo={statementConfig} setEstilo={setStatementConfig} titulo={"2. Enunciado"} estiloexportacaoService={estiloexportacaoService} />
          <PadraoEstiloForm estilo={answersConfig} setEstilo={setAnswersConfig} titulo={"3. Alternativas/Respostas"} estiloexportacaoService={estiloexportacaoService} />
        </EstiloExportacaoContent>
      </CriarEstiloExportacaoContent>
    </Modal>
  );
};




const PadraoEstiloForm: React.FC<{
  estilo: EstiloPadraoRequest, setEstilo: Dispatch<SetStateAction<EstiloPadraoRequest>>, titulo: string, estiloexportacaoService: EstiloExportacaoService
}> = ({ estilo, setEstilo, titulo, estiloexportacaoService }) => {


  return (
    <div>
      <TitleIcon
        name={titulo}
        classname={"titulo"}
      />
      <div className="form-group">
        <div className="field-group">
          <label>Fonte</label>
          <AsyncSelect
            className="font form-group"
            label="Selecionar fonte"
            showSelectItem={true}
            selectItem={estilo.font ? {
              id: 1,
              value: estilo.font,
              key: "font",
              useId: false,
            } : undefined}
            onAddFilterItem={(item) => {
              setEstilo({
                ...estilo,
                ["font"]: item.value
              })
            }}
            getDataAPI={(page, value) =>
              estiloexportacaoService.listarFontesDisponiveis(value)
            }
          />
        </div>
        <div className="field-group">
          <label>Tamanho da fonte</label>
          <InputForm
            className="font-size form-group"
            type={"number"}
            placeholder={"Informe tamanho"}
            style={{
              minWidth: "40px",
            }}
            value={estilo.fontSize}
            onChange={(value) => {
              setEstilo({
                ...estilo,
                ["fontSize"]: value.target.valueAsNumber
              })
            }}
          />
        </div>
        <div className="field-group">
          <label>Cor da fonte</label>
          <ColorPickerComponent initialColor={"#000000"} classname={"form-group"} color={estilo.fontColor} setColor={(color) => {
            setEstilo({
              ...estilo,
              ["fontColor"]: color
            })
          }} />
        </div>
        <div className="field-group">
          <label>Cor do Highlight</label>
          <ColorPickerComponent initialColor={"#ffffff"} classname={"form-group"} color={estilo.textHighlightColor} setColor={(color) => {
            setEstilo({
              ...estilo,
              ["textHighlightColor"]: color
            })
          }} />
        </div>
        <div className="field-group">
          <ChangeIcon currentValue={estilo.bold} icon={BiBold} classname={"form-group"} onChange={(check) => {
            setEstilo({
              ...estilo,
              ["bold"]: check
            })
          }} />
        </div>
        <div className="field-group">
          <ChangeIcon currentValue={estilo.italic} icon={BiItalic} classname={"form-group"} onChange={(check) => {
            setEstilo({
              ...estilo,
              ["italic"]: check
            })
          }} />
        </div>
        <div className="field-group">
          <GroupButtons
            initialPositionSelected={0}
            currentValue={estilo.paragraphAlignment}
            onCurrentButtonSelected={(current) => {
              setEstilo({
                ...estilo,
                ["paragraphAlignment"]: current.value
              })
            }}
            buttons={alinhamentos}
          />
        </div>
        <div className="field-group">
          <label>Espaçamento</label>
          <InputForm
            className="espacamento-field form-group"
            type={"number"}
            step="0.01"
            placeholder={"Espaçamento"}
            style={{
              minWidth: "40px",
            }}
            value={estilo.paragraphSpacing}
            onChange={(value) => {
              setEstilo({
                ...estilo,
                ["paragraphSpacing"]: value.target.valueAsNumber
              })
            }}
          />
        </div>
      </div>
    </div >
  )
}

export const ColorPickerComponent: React.FC<{ color?: string, setColor: (color: string) => void, classname: string, initialColor: string }> = ({ color, setColor, classname, initialColor }) => {

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [colorState, setColorState] = useColor("hex", color ? color : initialColor);

  useEffect(() => {
    if (colorState.hex !== color) {
      setColor(colorState.hex);
    }
  }, [showPicker]);


  useOnClickOutside(divRef, () => {
    setShowPicker(false);
  });

  return (
    <ColorPickerContent className={classname}>
      <ColorPickerStyle color={colorState.hex} className={"form-group"} onClick={() => setShowPicker(true)} />
      {showPicker && <div ref={divRef}><ColorPicker width={356} height={228} color={colorState} onChange={setColorState} hideHSV hideRGB /></div>}
    </ColorPickerContent>
  )
}
