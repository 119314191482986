import { CheckBox, FormKit, TitleCollapsibleContent } from "jusadmin-ui-kit";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineTags } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { MdOutlineAdd } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import { VscChromeClose } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../components/button/bottomless";
import { FilterTag } from "../../../components/filter-tag";
import { InputForm } from "../../../components/input";
import { ActionType } from "../../../components/page/GerenciarPageBase/types";
import { useTagAction } from "../../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemSelect
} from "../../../components/page/structure/FilterComponent/types";
import { AsyncSelect } from "../../../components/select/async";
import { NormalSelect } from "../../../components/select/normal";
import { TitleIcon } from "../../../components/title-icon";
import {
  dificuldadesOptions,
  fontesOptions,
  naturezaOptions,
  statusQuestaoOption
} from "../../../mocks/data";
import { APIFetch, APIFileFetch } from "../../../services/api";
import { useDisciplinaService } from "../../../services/api-services/disciplina";
import { useQuestaoFileService } from "../../../services/api-services/questaoFile";
import { useTagService } from "../../../services/api-services/tag";
import { useTemaService } from "../../../services/api-services/tema";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";
import { CriarDisciplina } from "../../GerenciarDisciplinas/forms/criar-disciplina";
import { CriarTipoLei } from "../../GerenciarTipoLeis/forms/criar-tipo-lei";
import { useQuestaoContext } from "../context";
import { CriarRespostaEnunciado } from "../respostas/objetivas/respostaEnunciado";
import { CriarClassificacaoContent, QuestaoInfoForm } from "../styles";

export interface QuestaoInfoProps {
  modoClassificao: boolean
}

export const QuestaoInfo = ({ modoClassificao }: QuestaoInfoProps) => {
  //
  const auth = useAuthContext();

  const { roles } = auth.user;

  const { callbacks, questao } = useQuestaoContext();

  const [showCriarClassificacao, setShowCriarClassificacao] = useState(false);

  const [nome, setNome] = useState("");

  const fontes = useTagAction((value) => {
    callbacks.setFontes(value);
  });

  const [disciplina, setDisciplina] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });


  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentAction, setCurrentAction] = currentActionState;

  const services = {
    tagService: useTagService(APIFetch(auth)),
    temaService: useTemaService(APIFetch(auth)),
    disciplinaService: useDisciplinaService(APIFetch(auth)),
    questaoFileService: useQuestaoFileService(APIFileFetch(auth)),
  };

  useEffect(() => {
    if (questao) {
      fontes.setInitialValues(questao.fontes);
    }
  }, [questao]);

  const listarTemasAPI = (page: number, value: string, param: string) => {
    return services.temaService.listarTemasByDisciplina(
      page,
      30,
      value,
      param,
      false
    );
  };

  const listarTagsAPI = (page: number, value: string, param: string) => {
    return services.tagService.listarTagsByDisciplina(
      page,
      30,
      value,
      param,
      false
    );
  };

  const listarDisciplinasAPI = (page: number, value: string) => {
    return services.disciplinaService.listarDisciplinas(page, 30, value);
  };


  const options = () => {
    switch (currentAction.action) {
      case "criar-disciplina": {
        return <CriarDisciplina externalAction={currentActionState} />;
      }
      case "criar-tipo-lei": {
        return <CriarTipoLei externalAction={currentActionState} />;
      }
      default: {
        return <Fragment />;
      }
    }
  };



  const showPremissaUnica = () => {
    if (questao) {
      if (questao.tipoResposta === "Única") {
        return <CriarRespostaEnunciado />;
      }
    }

    return <Fragment />;
  };

  const showMenuClassificacao = () => {
    const salvarTema = () => {
      services.temaService
        .salvarTema({
          name: nome,
          selected_subject_id: disciplina.id,
        })
        .then((response) => {
          alert("Tema foi salvo com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    };

    const salvarTag = () => {
      services.tagService
        .salvarTag({
          name: nome,
          selected_subject_id: disciplina.id,
        })
        .then((response) => {
          alert("Tag foi salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    };

    return (
      <CriarClassificacaoContent>
        <div className="form-classificacao">
          <InputForm
            className="nome field-group"
            placeholder="Informe o nome"
            value={nome}
            onChange={(value) => {
              setNome(value.currentTarget.value);
            }}
          />
          <AsyncSelect
            className="disciplina field-group"
            showSelectItem={true}
            label="Disciplina"
            selectItem={disciplina}
            onAddFilterItem={(item) => setDisciplina(item)}
            getDataAPI={(page, value) =>
              services.disciplinaService.listarDisciplinas(page, 30, value)
            }
          />
        </div>
        <div className="form-options">
          <ButtonBottomLess
            className="salvar-tema"
            title="Salvar tema"
            onClick={salvarTema}
            icon={IoMdCheckmark}
          />
          <ButtonBottomLess
            className="salvar-tema"
            title="Salvar tag"
            onClick={salvarTag}
            icon={IoMdCheckmark}
          />
          <ButtonBottomLess
            className="nova-disciplina"
            title="Nova disciplina"
            icon={IoAdd}
            onClick={() => {
              setCurrentAction({ id: 0, action: "criar-disciplina" });
            }}
          />
        </div>
      </CriarClassificacaoContent>
    );
  };

  return (
    <Fragment>
      <QuestaoInfoForm>
        <TitleCollapsibleContent isOpen={false}
          name="Informações" icon={RiQuestionnaireLine} >
          <FormKit.FormContent>
            <FormKit.FormSubContent>
              <FormKit.GroupField error="" label="Natureza">
                <NormalSelect
                  minWidth={200}
                  className="input-form"
                  selectItem={questao.natureza}
                  onSelectCurrentItem={(item) => {
                    callbacks.setNatureza(item);
                  }}
                  selectedItems={naturezaOptions}
                />
              </FormKit.GroupField>
              <FormKit.GroupField error="" label="Status">
                <NormalSelect
                  minWidth={200}
                  className="input-form"
                  selectItem={questao.status}
                  onSelectCurrentItem={(item) => {
                    callbacks.setStatus(item);
                  }}
                  selectedItems={statusQuestaoOption}
                />
              </FormKit.GroupField>
            </FormKit.FormSubContent>
            <FormKit.FormSubContent>
              <FormKit.GroupField error="" label="Dificuldade">
                <NormalSelect
                  minWidth={200}
                  label={"Selecione a dificuldade"}
                  className="input-form"
                  selectItem={questao.dificuldade}
                  onSelectCurrentItem={(item) => {
                    callbacks.setDificuldade(item);
                  }}
                  selectedItems={dificuldadesOptions}
                />
              </FormKit.GroupField>
              {questao.natureza.value === "Jurídica" &&
                <FormKit.GroupField error="" label="Fonte" >
                  <NormalSelect
                    minWidth={200}
                    className="input-form"
                    label="Selecione uma fonte"
                    onSelectCurrentItem={(item) => {
                      fontes.addTag(item)
                    }
                    }
                    selectedItems={fontesOptions}
                  />
                  <div className="fontes-content field-group">
                    {questao.fontes.map((item, index) => {
                      return <FilterTag key={index} nome={item.value} onMouseClick={() => fontes.removeTag(item)} />;
                    })}
                  </div>
                </FormKit.GroupField>
              }
            </FormKit.FormSubContent>
          </FormKit.FormContent>
        </TitleCollapsibleContent>
        <TitleCollapsibleContent
          name="Temática"
          isOpen={true}
          icon={AiOutlineTags}
          extraOptionsRight={!roles.includes("ROLE_CLASSIFICADOR") &&
            <ButtonBottomLess
              title="Criar temática"
              className=""
              icon={IoAdd}
              onClick={() => setShowCriarClassificacao(true)}
            />
          }
        >
          {!roles.includes("ROLE_CLASSIFICADOR") &&
            <Fragment>
              {showCriarClassificacao && (
                <TitleIcon
                  name="Nova temática"
                  icon={MdOutlineAdd}
                  extra={
                    <VscChromeClose
                      onClick={() => {
                        setShowCriarClassificacao(false);
                      }}
                      size={22}
                      style={{
                        color: "var(--color-primary-darking)",
                        cursor: "pointer",
                      }}
                    />
                  }
                />
              )}
              {showCriarClassificacao && showMenuClassificacao()}
            </Fragment>
          }
          <FormKit.FormContent>
            <FormKit.FormSubContent>
              <FormKit.GroupField label="Disciplina" error="">
                <AsyncSelect
                  className="input-form"
                  showSelectItem={true}
                  label="Disciplina"
                  selectItem={questao.disciplina}
                  onAddFilterItem={(item) => {
                    callbacks.setDisciplina(item);
                  }}
                  getDataAPI={listarDisciplinasAPI}
                />
              </FormKit.GroupField>
              <FormKit.GroupField label="Tema" error="">
                <AsyncSelect
                  className="input-form"
                  label="Tema"
                  dependency={true}
                  secondaryValue={questao.disciplina}
                  selectItem={questao.tema}
                  showSelectItem={true}
                  onAddFilterItem={(item) => {
                    callbacks.setTema(item);
                  }}
                  getDataAPIParam={listarTemasAPI}
                />
              </FormKit.GroupField>
            </FormKit.FormSubContent>
            <FormKit.FormSubContent>
              <FormKit.GroupField label="" error="">
                <CheckBox label="Mostrar (disciplina prova)" onChange={() => callbacks.setAtivarDiscProva(questao.ativarDiscProva ? false : true)} checked={questao.ativarDiscProva} />
              </FormKit.GroupField>
              {questao.ativarDiscProva && <FormKit.GroupField label="Disciplina Prova" error="">
                <InputForm
                  className="input-form"
                  placeholder="Informe o nome"
                  value={questao.discProva}
                  onChange={(value) => {
                    callbacks.setDiscProva(value.currentTarget.value);
                  }}
                />
              </FormKit.GroupField>}
              <FormKit.GroupField label="TAG" error="">
                <AsyncSelect
                  className="input-form"
                  label="TAG"
                  dependency={true}
                  selectItem={questao.tag}
                  secondaryValue={questao.disciplina}
                  showSelectItem={true}
                  onAddFilterItem={(item) => {
                    callbacks.setTAG(item);
                  }}
                  getDataAPIParam={listarTagsAPI}
                />
              </FormKit.GroupField>
            </FormKit.FormSubContent>
          </FormKit.FormContent>
        </TitleCollapsibleContent>
        {!modoClassificao && showPremissaUnica()}
      </QuestaoInfoForm>
      {options()}
    </Fragment>
  );
};
