import React, { Fragment } from "react";
import { VscOpenPreview } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import { Tag } from "../../../../components/tag";
import {
  Questao,
  QuestaoResumida,
} from "../../../../services/api-services/questao";
import {
  Classificacao,
  CodigoID,
  Status,
  Validation,
} from "../../../GerenciarQuestoes/elements";
import { QuestaoCardBody } from "../../../GerenciarQuestoes/views/card-questao/body";
import { HeaderStyle } from "../../../GerenciarQuestoes/views/card-questao/styles";

export const QuestaoImportadaItem: React.FC<{
  elem: QuestaoResumida;
  index: number;
}> = ({ elem, index }) => {
  return (
    <CardItem
      settings={{
        width: "100%",
      }}
      key={index}
      optionsInLastPos={false}
      header={<HeaderContent elem={elem} index={index} />}
      body={<QuestaoCardBody questao={elem} />}
      footer={<Fragment />}
      options={[
        <ThreeNormalOption
          name="Editar"
          onClick={() => {
            window.open(`/questao/editar/${elem.id}`, "_blank");
          }}
        />,
      ]}
    />
  );
};

const HeaderContent: React.FC<{
  index: number;
  elem: QuestaoResumida;
}> = ({ elem }) => {
  return (
    <HeaderStyle>
      <ButtonBottomLess
        className="button-view"
        title={"Ver questão"}
        icon={VscOpenPreview}
        onClick={() => {
          window.open(`/questao/${elem.id}`, "_blank");
        }}
      />
      <CodigoID codigo={elem.cod} id={elem.id} classname={"item-margin"} />
      <Status status={elem.status_question} classname={"item-margin"} />
      <Validation
        value={elem.validation}
        mode={"card"}
        classname={"item-margin"}
      />
      <Classificacao
        questao={elem as unknown as Questao}
        classname={"item-margin"}
      />
      <Tag nome={"Importada"} />
    </HeaderStyle>
  );
};
