import { useEffect } from "react";
import { AiFillTag } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { QuestaoCardState } from "..";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { CheckBox } from "../../../../../components/checkbox";
import { useGerenciarContext } from "../../../../../components/page/GerenciarPageBase/context";
import {
    ItemDataSelect,
    SelectedDataActions,
} from "../../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
    Questao,
    QuestaoItem,
} from "../../../../../services/api-services/questao";
import { useQuestaoContext } from "../../../../CriarQuestao/context";
import { Classificacao, CodigoID, Status, Validation } from "../../../elements";
import { FecharFormQuestaoState } from "../../../forms/fechar-form-card";
import { HeaderStyle } from "../styles";

export interface QuestaoCardHeaderProps {
    index: number;
    elem: ItemDataSelect<Questao>;
    mode: string;
    selectedEntitiesAction: SelectedDataActions<Questao>;
    onChangeStateCard: (state: QuestaoCardState) => void;
    stateCard: QuestaoCardState;
}

export const QuestaoCardHeader = ({
    elem,
    index,
    selectedEntitiesAction,
    mode,
    onChangeStateCard,
    stateCard,
}: QuestaoCardHeaderProps) => {
    const context = useGerenciarContext();
    const { salvarQuestaoAPI } = useQuestaoContext();
    const questao = elem.entity as QuestaoItem;

    useEffect(() => {
        if (context.currentAction.action === "pos-confirmacao") {
            if (context.currentAction.object !== undefined) {
                const state = context.currentAction.object as FecharFormQuestaoState;

                if (state.index === index) {
                    if (state.action === "atualizar-questao-form") {
                        salvarQuestaoAPI()
                            .then((questao) => {
                                context.changeAction({
                                    action: "sucesso",
                                    id: 0,
                                    object: undefined,
                                    message: "Alterações realizadas com sucesso!",
                                    loading: true,
                                });
                            })
                            .catch((error) => {
                                context.changeAction({
                                    action: "erro",
                                    id: 0,
                                    object: undefined,
                                    message: error.response.data.message,
                                    loading: true
                                });
                            });
                    }

                    onChangeStateCard(QuestaoCardState.VISUALIZACAO);
                }
            }
        }
    }, [context.currentAction.object]);

    const renderBotaoClassificacao = () => {
        switch (stateCard) {
            case QuestaoCardState.FORM_ATUALIZACAO: {
                return (
                    <ButtonBottomLess
                        onClick={onClickSalvarQuestao}
                        className={""}
                        icon={BsCheckLg}
                        title="Salvar alterações"
                    />
                );
            }

            case QuestaoCardState.VISUALIZACAO: {
                return (
                    <ButtonBottomLess
                        onClick={() => onChangeStateCard(QuestaoCardState.FORM_ATUALIZACAO)}
                        className={""}
                        icon={AiFillTag}
                        title="Classificar questão"
                    />
                );
            }
        }
    };

    const onClickSalvarQuestao = () => {
        context.changeAction({
            action: "atualizar-questao-form",
            id: index,
            message: "Deseja salvar as alterações feitas?",
        });
    };

    const onClickFecharForm = () => {
        context.changeAction({
            action: "fechar-form-questao",
            id: index,
            message: "Deseja fechar o formulário?",
        });
    };

    return (
        <HeaderStyle>
            <div className="card-header-left">
                <CheckBox
                    checked={selectedEntitiesAction.checkSelect(elem.id_item)}
                    onChange={(e) => {
                        selectedEntitiesAction.checkItem(index, e);
                    }}
                />
                <CodigoID
                    codigo={questao.cod}
                    id={questao.id}
                    classname={"item-margin"}
                />
                <Status status={questao.status_question} classname={"item-margin"} />
                <Validation
                    value={questao.validation}
                    mode={mode}
                    classname={"item-margin"}
                />
                <Classificacao questao={elem.entity} classname={"item-margin"} />
            </div>
            <div className="card-header-right">
                {stateCard === QuestaoCardState.FORM_ATUALIZACAO && (
                    <ButtonBottomLess
                        onClick={onClickFecharForm}
                        className={""}
                        icon={ImCancelCircle}
                        title="Fechar"
                    />
                )}
                {renderBotaoClassificacao()}
            </div>
        </HeaderStyle>
    );
};
