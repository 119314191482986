import { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated, isAuthorized } from "..";
import { useAuthContext } from "../../../services/auth-services/auth/contextAuth";
import { TAuthorizationModalRoute } from "../types";

export const ModalAuthRoute: React.FC<TAuthorizationModalRoute> = (props) => {
    const auth = useAuthContext();

    const renderComponent = () => {
        if (isAuthenticated(auth)) {
            if (isAuthorized(auth, props.roles, props.permissions)) {
                return props.children;
            } else {
                props.handleUnauthorization();
                return <Fragment />;
            }
        } else {
            auth.logout();
            return <Redirect to={'/login'} />;
        }
    };

    return <Fragment>{renderComponent()}</Fragment>;
};