import styled from 'styled-components';



export const MainContentAddQuestaoProjeto = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;


export const AddQuestaoProjetoContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const Filtros = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ComentariosFiltrosContent = styled.div`
  padding: 12px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  background-color: var(--color-content-light);
  padding: 12px;
  height: 54px;
  margin-bottom: 8px ;
`;

export const ListarProjetosContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

   .search {
      margin-left: 12px;
      width: 600px;
    }

  .pagination {
    justify-content: flex-end;
  }

  .list-projetos {
    height: 100%;
    overflow: auto;
  }
`;

export const ProjetoItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-select-border);
  padding: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
  p {
    font-weight: 500;
    font-size: 0.9 rem;
    color: var(--color-text-grey);
  }
`;

export const ProjetoFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;
