import React from "react";
import { IconBaseProps } from "react-icons";
import { RoundedButtonContent } from "./styles";

interface TButtonBottomLess
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: React.ComponentType<IconBaseProps>;
  title?: string;
  className: string;
}

export const RoundedButton: React.FC<TButtonBottomLess> = ({
  icon: Icon,
  className,
  title,
  ...rest
}) => {
  return (
    <RoundedButtonContent className={className} {...rest}>
      {Icon && <Icon size={20} className={"bottom-icon"} />}
      <small>{title}</small>
    </RoundedButtonContent>
  );
};
