import { CheckboxItem } from "./styles";

interface TCheckBox extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const CheckBox: React.FC<TCheckBox> = ({ label, ...rest }) => {
  return (
    <CheckboxItem className={rest.className}>
      <label className="b-contain">
        {label && <span>{label}</span>}
        <input type="checkbox" {...rest} />
        <div className="b-input"></div>
      </label>
    </CheckboxItem>
  );
};
