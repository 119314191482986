import React, { Fragment, useEffect, useState } from "react";
import { RiFileDownloadLine } from "react-icons/ri";
import { useParams } from "react-router";
import { ButtonNormal } from "../../components/button/normal";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { ThreeNormalOption } from "../../components/menu/three-normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect } from "../../components/page/structure/FilterComponent/types";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../components/select/async";
import { SelectOption } from "../../components/select/option";
import {
  classificacaoQuestao,
  dificuldades,
  fontes,
  pesquisaQuestaoProjetoOptions,
  projetoQuestaoOrdenacao,
  statusQuestao,
  tipoQuestao
} from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAutorService } from "../../services/api-services/autores";
import { useDisciplinaService } from "../../services/api-services/disciplina";
import { usePerfilQuestoesService } from "../../services/api-services/perfilQuestoes";
import {
  ProjetoDetails,
  useProjetoService
} from "../../services/api-services/projeto";
import { useProvaService } from "../../services/api-services/prova";
import {
  QuestaoProjetoItem,
  useQuestaoProjetoService
} from "../../services/api-services/questaoProjeto";
import { useTagService } from "../../services/api-services/tag";
import { useTemaService } from "../../services/api-services/tema";
import { useTipoLeiService } from "../../services/api-services/tipoLeis";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { PesquisaFiltros, QuestaoPesquisaOpcoes } from "../GerenciarQuestoes";
import { ReferenciaLegalFiltros } from "../GerenciarQuestoes/forms/pesquisa-referencias";
import { usePesquisaQuestaoCheckHandler } from "../GerenciarQuestoes/hooks/usePesquisaQuestaoCheckHandler";
import { useDispositivosLegaisHook } from "../GerenciarQuestoes/views/referencias-legais/hooks/useDispositivosLegaisHook";
import { QuestaoCard } from "./views/card-questao";
import { ExportacaoQuestoes } from "./views/exportar-questoes";

interface props {
  id: string;
  nome: string;
}

export const ProjetoQuestoesComponent = () => {
  //
  const { id, nome } = useParams<props>();

  const dispositivosLegaisHook = useDispositivosLegaisHook();

  const context = useGerenciarContext();

  const auth = useAuthContext();

  const questaoService = useQuestaoProjetoService(APIFetch(auth));

  const disciplinaService = useDisciplinaService(APIFetch(auth));

  const tagService = useTagService(APIFetch(auth));

  const provaService = useProvaService(APIFetch(auth));

  const temaService = useTemaService(APIFetch(auth));

  const tipoLeiService = useTipoLeiService(APIFetch(auth));

  const autorService = useAutorService(APIFetch(auth));

  const perfilQuestaoService = usePerfilQuestoesService(APIFetch(auth));

  const projetoService = useProjetoService(APIFetch(auth));

  const [sort, setSort] = useState("default");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("item");

  const [status, setStatus] = useState("");

  const [period, setPeriod] = useState("");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const [disciplina, setDisciplina] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const [projeto, setProjeto] = useState<ProjetoDetails>();

  const questaoState = useState<Array<ItemDataSelect<QuestaoProjetoItem>>>([]);
  const setQuestaos = questaoState[1];

  const useTagActions = useTagAction();

  const pesquisaCheckboxFiltros = useState<Array<QuestaoPesquisaOpcoes>>(
    pesquisaQuestaoProjetoOptions
  );

  const pesquisaTodosCheckbox = useState<QuestaoPesquisaOpcoes>({
    check: true,
    label: "Todos",
    valor: "",
  });

  const usePesquisaCheckHandler = usePesquisaQuestaoCheckHandler({
    filtrarTodosState: pesquisaTodosCheckbox,
    filtrosState: pesquisaCheckboxFiltros,
  });

  const selectedEntitiesAction = useSelectedDataAction<QuestaoProjetoItem>({
    state: questaoState,
  });

  const listarTiposLeisAPI = (page: number, value: string) => {
    return tipoLeiService.listarTipoLeis(page, 30, value);
  };

  const keys: string[] = [
    "status",
    "type",
    "difficulty",
    "nature",
    "exam",
    "subject",
    "topic",
    "source",
    "authorName",
    "profileName",
    "classification",
    "tag",
    "legalReferences",
  ];

  useEffect(() => {
    projetoService.buscarProjeto(parseInt(id)).then((projeto) => {
      setProjeto(projeto);
    });
  }, []);

  useEffect(() => {
    if (context.loading) {
      questaoService.questaoProjeto
        .listarQuestaoProjetosPorPagina(
          parseInt(id),
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          status,
          period,
          useTagActions.getFilters(keys),
          search,
          usePesquisaCheckHandler.getFiltros(),
          sort,
          order
        )
        .then((questoes) => {
          setQuestaos(
            questoes.content.map((value: QuestaoProjetoItem) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(questoes.paginationData);
          context.toPage(questoes.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Questão" isOpen={true}>
          <GroupMain>
            <SelectOption
              className="fonte field-group"
              label="Filtrar por fonte"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={fontes}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por dificuldade"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={dificuldades}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por tipo"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={tipoQuestao}
            />
            <SelectOption
              className="fonte field-group"
              label="Filtrar por status"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={statusQuestao}
            />
            <AsyncSelect
              className="prova field-group"
              label="Filtrar por prova"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                provaService.listarProvas(page, 30, value)
              }
            />
          </GroupMain>
        </Group>
        <ReferenciaLegalFiltros
          dispositivosLegaisHook={dispositivosLegaisHook}
          useTagActions={useTagActions}
          listarTiposLeisAPI={listarTiposLeisAPI}
        />
        <Group nameGroup="Classificação">
          <GroupMain>
            <SelectOption
              className="fonte field-group"
              label="Filtrar por classificação"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getData={classificacaoQuestao}
            />
            <AsyncSelect
              className="disciplina field-group"
              label="Filtrar por disciplina"
              onAddFilterItem={(item) => {
                useTagActions.addTag(item);
                setDisciplina(item);
              }}
              getDataAPI={(page, value) =>
                disciplinaService.listarDisciplinas(page, 30, value)
              }
            />
            <AsyncSelect
              className="tema field-group"
              label="Filtrar por tema"
              dependency={true}
              secondaryValue={disciplina}
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPIParam={(page, value, param) =>
                temaService.listarTemasByDisciplina(
                  page,
                  30,
                  value,
                  param,
                  true
                )
              }
            />
            <AsyncSelect
              className="tag field-group"
              label="Filtrar por TAG"
              dependency={true}
              secondaryValue={disciplina}
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPIParam={(page, value, param) =>
                tagService.listarTagsByDisciplina(page, 30, value, param, true)
              }
            />
          </GroupMain>
        </Group>
        <Group nameGroup="Questão comentada">
          <GroupMain>
            <AsyncSelect
              className="perfil field-group"
              label="Filtrar por perfil de questão"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                perfilQuestaoService.listarPerfils(page, 30, value)
              }
            />
            <AsyncSelect
              className="autor field-group"
              label="Filtrar por autor"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                autorService.listarAutores(page, 30, value)
              }
            />
          </GroupMain>
        </Group>
      </Fragment>
    );
  };

  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(false);
  };

  const sucessoAcao = () => {
    return (
      <SucessModal
        action={context.currentActionState}
        message={
          context.currentAction.message ? context.currentAction.message : ""
        }
        title={""}
        onOptionOk={() =>
          context.changeAction({
            action: "",
            id: 0,
          })
        }
      />
    );
  };

  const erroAcao = () => {
    return (
      <ErrorModal
        action={context.currentActionState}
        message={
          context.currentAction.message ? context.currentAction.message : ""
        }
        title={""}
        onOptionOk={() =>
          context.changeAction({
            action: "",
            id: 0,
          })
        }
      />
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {

      case "exportacao-questoes": {
        return (
          <ExportacaoQuestoes
            idProjeto={id as unknown as number}
            search_filter={{
              filters: useTagActions.getFilters(keys),
              period: period,
              search: search,
              search_content: usePesquisaCheckHandler.getFiltros(),
            }}
          />
        );
      }

      case "exportacao-questoes-selecionadas": {
        return (
          <ExportacaoQuestoes
            idProjeto={id as unknown as number}
            ids={selectedEntitiesAction.getSelectItems()}
          />
        );
      }

      case "sucesso": {
        return sucessoAcao();
      }
      case "erro": {
        return erroAcao();
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<QuestaoProjetoItem>
        callbacks={{
          search: (key) => {
            setSearch(key);

            if (key.length === 0) {
              context.changeLoading(true);
            }
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={questaoState}
        filters={filters()}
        filterBar={{
          right: (
            <GroupButtons
              initialPositionSelected={3}
              buttons={[
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "hoje",
                  classname: "period-option",
                  value: "day",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "semana",
                  classname: "period-option",
                  value: "week",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "mês",
                  classname: "period-option",
                  value: "month",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "todos",
                  classname: "period-option",
                  value: "",
                },
              ]}
            />
          ),
          left: (
            <Fragment>
              <ButtonNormal
                className="button-exportacao"
                title={"Exportar questões"}
                icon={RiFileDownloadLine}
                onClick={() => {
                  context.changeAction({
                    action: "exportacao-questoes",
                    id: 0,
                  });
                }}
              />
            </Fragment>
          ),
        }}
        page={{
          name: `Projeto - ${projeto?.name} - ${projeto?.year}`,
          header: {
            left: null,
            right: null,
          },
          searchPlaceHolder: "Pesquisar por código,id ou enunciado...",
          searchOptions: (
            <PesquisaFiltros
              opcoes={pesquisaCheckboxFiltros[0]}
              todos={pesquisaTodosCheckbox[0]}
              handleCheckAllPesquisa={
                usePesquisaCheckHandler.handleCheckAllPesquisa
              }
              handleCheckPesquisa={usePesquisaCheckHandler.handleCheckPesquisa}
            />
          ),
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: projetoQuestaoOrdenacao,
          },
          options: [
            <ThreeNormalOption
              name={`Exportar - (${selectedEntitiesAction.getSelectItems().length
                }) questões `}
              onClick={() => {
                if (selectedEntitiesAction.getSelectItems().length == 0) {
                  alert("Você precisa selecionar ao menos uma questão..");
                } else {
                  context.changeAction({
                    action: "exportacao-questoes-selecionadas",
                    id: 0,
                  });
                }
              }}
            />,
          ],
          mode: "card",
          card: {
            dataColumn: QuestaoCard(selectedEntitiesAction, mode).questaoCard,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const ProjetoQuestoes = () => {
  return (
    <GerenciarBaseProvider>
      <ProjetoQuestoesComponent />
    </GerenciarBaseProvider>
  );
};
