import styled from "styled-components";


export const SectionTextContent = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: var(--color-content-light);
    padding-bottom: 24px;
    padding-top: 24px;

    h2 {
        font-weight: 500;
        color: var(--color-text-dark);  
        margin-bottom: 12px;
    }

    p {
        font-size: 1.0rem;
        color: var(--color-text-grey);
        margin-bottom: 12px;
    }

    .icon-title{
      margin-top: 12px;
      margin-bottom: 12px;
      color: var(--color-primary-light);
    }

    .button-item{
        margin-top: 12px;
        margin-bottom: 12px;
    }
  
    .content{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
    }

`;