import { Fragment, useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CgCheckO, CgRadioCheck } from "react-icons/cg";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { MdClear, MdDelete } from "react-icons/md";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { ButtonNormal } from "../../../../../components/button/normal";
import { Editor } from "../../../../../components/editor";
import {
  NormalSelect,
  TItemSelect
} from "../../../../../components/select/normal";
import { SelectNormalContext } from "../../../../../components/select/normal/context";
import { TitleIcon } from "../../../../../components/title-icon";
import {
  colunasRelacionalOptions,
  respostaEscolhaOption
} from "../../../../../mocks/data";
import { generateID } from "../../../../../utils/id-generator";
import {
  LeftResposta,
  RespostaContent,
  RespostaRow,
  RightResposta
} from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/styles";
import { Resposta, useQuestaoContext } from "../../../context";
import { RespostaEscolhaContent } from "../../../styles";

export const CriarRespostasRelacionais: React.FC<{}> = () => {
  //
  const questaoContext = useQuestaoContext();

  const [respostaAnterior, setRespostaAnterior] = useState<Resposta>();

  const [edit, setEdit] = useState(false);

  const [enunciado, setEnunciado] = useState("<p><br></p>");

  const [correto, setCorreto] = useState<TItemSelect>({
    id: -1,
    key: "",
    selected: false,
    useId: false,
    value: "",
  });

  const [colunas, setColunas] = useState<TItemSelect>({
    id: -1,
    key: "",
    selected: false,
    useId: false,
    value: "",
  });

  const [colunaRelacionadaCX, setColunaRelacionadaCX] =
    useState<SelectNormalContext>();

  const [colunaRelacionada, setColunaRelacionada] = useState<TItemSelect>({
    id: -1,
    key: "",
    selected: false,
    useId: false,
    value: "",
  });

  const [editarColunaRelacionada, setEditarColunaRelacionada] = useState(false);

  const handleResposta = () => {
    if (validar()) {
      if (edit) {
        editarResposta();
      } else {
        criarResposta();
      }
    }
  };

  const clear = () => {
    setRespostaAnterior(undefined);
    setEnunciado("<p><br></p>");
    setColunaRelacionada({
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    });
    setCorreto({
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    });

    setEdit(false);
    setEditarColunaRelacionada(false);
  };

  const editarResposta = () => {
    if (respostaAnterior) {
      switch (colunas.key) {
        case "first_column": {
          const resposta: Resposta = {
            id: respostaAnterior.id,
            answer: enunciado,
            anwser_correct: {
              id: -1,
              key: "",
              selected: false,
              useId: false,
              value: "",
            },
            column: "first_column",
            points: 0,
            related_column: "",
          };

          questaoContext.editarResposta(
            respostaAnterior,
            resposta,
            resposta.column
          );
          clear();

          break;
        }
        case "second_column": {
          const resposta: Resposta = {
            id: respostaAnterior.id,
            answer: enunciado,
            anwser_correct: {
              id: -1,
              key: "",
              selected: false,
              useId: false,
              value: "",
            },
            column: "second_column",
            points: 0,
            related_column: colunaRelacionada ? colunaRelacionada.key : "",
          };

          questaoContext.editarResposta(
            respostaAnterior,
            resposta,
            resposta.column
          );
          clear();

          break;
        }
        case "third_column": {
          const resposta: Resposta = {
            id: respostaAnterior.id,
            answer: enunciado,
            anwser_correct: correto,
            column: "third_column",
            points: 0,
            related_column: "",
          };

          questaoContext.editarResposta(
            respostaAnterior,
            resposta,
            resposta.column
          );
          clear();

          break;
        }
      }
    }
  };

  const criarResposta = () => {
    switch (colunas.key) {
      case "first_column": {
        const resposta: Resposta = {
          id: generateID(),
          answer: enunciado,
          anwser_correct: {
            id: -1,
            key: "",
            selected: false,
            useId: false,
            value: "",
          },
          column: "first_column",
          points: 0,
          related_column: "",
        };

        questaoContext.addResposta(resposta, "first_column");
        break;
      }
      case "second_column": {
        const resposta: Resposta = {
          id: generateID(),
          answer: enunciado,
          anwser_correct: {
            id: -1,
            key: "",
            selected: false,
            useId: false,
            value: "",
          },
          column: "second_column",
          points: 0,
          related_column: colunaRelacionada ? colunaRelacionada.key : "",
        };

        questaoContext.addResposta(resposta, "second_column");
        break;
      }
      case "third_column": {
        const resposta: Resposta = {
          id: generateID(),
          answer: enunciado,
          anwser_correct: correto,
          column: "third_column",
          points: 0,
          related_column: "",
        };

        questaoContext.addResposta(resposta, "third_column");
        break;
      }
    }

    clear();
  };

  useEffect(() => {
    if (respostaAnterior) {
      if (editarColunaRelacionada && colunaRelacionadaCX) {
        const itemRelacionado = colunaRelacionadaCX.selectedItens.find(
          (value) => value.key === respostaAnterior.related_column
        );

        if (itemRelacionado) {
          setColunaRelacionada(itemRelacionado);
        }

        setEditarColunaRelacionada(false);
      }
    }
  }, [editarColunaRelacionada, colunaRelacionadaCX]);

  const excluirResposta = (resposta: Resposta) => {
    questaoContext.removerResposta(resposta, resposta.column);
  };

  const handleEditar = (resposta: Resposta) => {
    setRespostaAnterior(resposta);
    setEnunciado(resposta.answer);
    setCorreto(resposta.anwser_correct);
    setEdit(true);
  };

  const alternativas = (respostas: Resposta[]) => {
    return (
      <RespostaContent>
        {respostas.map((ele, index) => {
          const count = index + 1;

          return (
            <RespostaRow key={index}>
              <LeftResposta>
                {ele.anwser_correct.key === "Certo" ? (
                  <CgCheckO
                    size={22}
                    style={{
                      color: "var(--color-resposta-correta)",
                    }}
                  />
                ) : (
                  <CgRadioCheck
                    size={22}
                    style={{
                      color: "var(--color-primary-darking)",
                    }}
                  />
                )}
                <div
                  className="content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: ele.answer,
                  }}
                />
              </LeftResposta>
              <RightResposta>
                <ButtonBottomLess
                  className="edit"
                  icon={FiEdit2}
                  onClick={() => {
                    if (!edit) {
                      handleEditar(ele);
                      setColunas(colunasRelacionalOptions[2]);
                    } else {
                      alert("Limpe ou salve antes de editar outra resposta!");
                    }
                  }}
                />
                <ButtonBottomLess
                  className="new-comentarios option-margin"
                  icon={MdDelete}
                  onClick={() => excluirResposta(ele)}
                />
              </RightResposta>
            </RespostaRow>
          );
        })}
      </RespostaContent>
    );
  };

  const colunaA = (tipos: Resposta[]) => {
    return (
      <RespostaContent className="tipos">
        <h4>Coluna A</h4>
        <Fragment>
          {tipos.map((ele, index) => {
            const count = index + 1;
            return (
              <RespostaRow key={index}>
                <LeftResposta>
                  <div className="leter">
                    <b>{`${count} -`}</b>
                  </div>
                  <div
                    className="content ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: ele.answer,
                    }}
                  />
                </LeftResposta>
                <RightResposta>
                  <ButtonBottomLess
                    className="edit"
                    icon={FiEdit2}
                    onClick={() => {
                      if (!edit) {
                        handleEditar(ele);
                        setColunas(colunasRelacionalOptions[0]);
                      } else {
                        alert("Limpe ou salve antes de editar outra resposta!");
                      }
                    }}
                  />
                  <ButtonBottomLess
                    className="new-comentarios option-margin"
                    icon={MdDelete}
                    onClick={() => excluirResposta(ele)}
                  />
                </RightResposta>
              </RespostaRow>
            );
          })}
        </Fragment>
      </RespostaContent>
    );
  };

  const colunaB = (tipos: Resposta[], descricoes: Resposta[]) => {
    const getPositionRelacional = (ele: Resposta) => {
      let found = -1;

      tipos.forEach((value, index) => {
        if (ele.related_column === value.id) {
          found = index + 1;
        }
      });

      return (
        <Fragment>
          {found != -1 ? <b>{`(${found})`}</b> : <b>{`( )`}</b>}
        </Fragment>
      );
    };

    return (
      <RespostaContent className="descricao">
        <h4>Coluna B</h4>
        <Fragment>
          {descricoes.map((ele, index) => {
            return (
              <RespostaRow key={index}>
                <LeftResposta>
                  <div className="leter">{getPositionRelacional(ele)}</div>
                  <div
                    className="content ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: ele.answer,
                    }}
                  />
                </LeftResposta>
                <RightResposta>
                  <ButtonBottomLess
                    className="edit"
                    icon={FiEdit2}
                    onClick={() => {
                      if (!edit) {
                        handleEditar(ele);
                        setColunas(colunasRelacionalOptions[1]);
                        setEditarColunaRelacionada(true);
                      } else {
                        alert("Limpe ou salve antes de editar outra resposta!");
                      }
                    }}
                  />
                  <ButtonBottomLess
                    className="new-comentarios option-margin"
                    icon={MdDelete}
                    onClick={() => excluirResposta(ele)}
                  />
                </RightResposta>
              </RespostaRow>
            );
          })}
        </Fragment>
      </RespostaContent>
    );
  };

  const validar = () => {
    let valid = true;

    if (enunciado === "<p><br></p>") {
      valid = false;
      alert("Informe um texto!");
    }

    switch (colunas.value) {
      case "Coluna 2": {
        if (colunaRelacionada && colunaRelacionada.key === "") {
          valid = false;
          alert("Selecione uma coluna que deseja relacionar a resposta!");
        }
        break;
      }
      case "Alternativas": {
        if (correto.value === "") {
          valid = false;
          alert("Selecione a resposta!");
        }
        break;
      }
    }

    return valid;
  };

  const getTitleResposta = () => {
    if (edit) {
      switch (colunas.value) {
        case colunasRelacionalOptions[0].value: {
          return "Editar item (Coluna 1)";
        }
        case colunasRelacionalOptions[1].value: {
          return "Editar item (Coluna 2)";
        }
        case colunasRelacionalOptions[2].value: {
          return "Editar alternativa";
        }
        default: {
          return "Nova resposta";
        }
      }
    }

    switch (colunas.value) {
      case colunasRelacionalOptions[0].value: {
        return "Novo item (Coluna 1)";
      }
      case colunasRelacionalOptions[1].value: {
        return "Novo item (Coluna 2)";
      }
      case colunasRelacionalOptions[2].value: {
        return "Novo alternativa";
      }
      default: {
        return "Nova resposta";
      }
    }
  };

  return (
    <RespostaEscolhaContent>
      <TitleIcon name={getTitleResposta()} icon={AiOutlineUnorderedList} />
      <div className="relacional-options">
        {!edit && (
          <NormalSelect
            minWidth={40}
            className="coluna"
            label="Selecione a coluna"
            selectItem={colunas}
            onSelectCurrentItem={(item) => setColunas(item)}
            selectedItems={colunasRelacionalOptions}
          />
        )}
        {colunas === colunasRelacionalOptions[2] && (
          <NormalSelect
            minWidth={40}
            className="resposta"
            label="Selecione uma resposta"
            selectItem={correto}
            onSelectCurrentItem={(item) => setCorreto(item)}
            selectedItems={respostaEscolhaOption}
          />
        )}
        {colunas === colunasRelacionalOptions[1] && (
          <NormalSelect
            getContext={(context) => setColunaRelacionadaCX(context)}
            minWidth={40}
            selectItem={colunaRelacionada}
            className="colunaRelacionada"
            label="Selecione a opção correta"
            onSelectCurrentItem={(item) => setColunaRelacionada(item)}
            selectedItems={questaoContext.respostas[0].map((ele, index) => {
              const count = index + 1;

              return {
                id: index,
                key: ele.id,
                selected: false,
                useId: false,
                value: `Item ${count}`,
              };
            })}
          />
        )}
      </div>
      <Editor
        currentText={enunciado}
        referencia={"resposta"}
        classname="editor-enunciado"
        html={(html) => setEnunciado(html)}
        uploadData={questaoContext.imageData}
      />
      <div className="new-alternativa">
        <div className="options">
          <ButtonBottomLess
            className="limpar-alternativa"
            title={"Limpar"}
            icon={MdClear}
            onClick={clear}
          />
          <ButtonNormal
            className="add-alternativa"
            title={edit ? "Salvar alterações" : "Adicionar"}
            icon={edit ? FiCheck : IoMdAdd}
            onClick={handleResposta}
          />
        </div>
      </div>
      <div className="colunas">
        {colunaA(questaoContext.respostas[0])}
        {colunaB(questaoContext.respostas[0], questaoContext.respostas[1])}
      </div>
      <h4>Alternativas</h4>
      {alternativas(questaoContext.respostas[2])}
    </RespostaEscolhaContent>
  );
};
