import { AxiosInstance } from "axios";

export class Service {
  public fetch: AxiosInstance;

  constructor(fetch: AxiosInstance) {
    this.fetch = fetch;
  }
}


export interface ImageInfoDetails {
  id: string,
  name: string,
  path: string
} 