import { AxiosInstance } from 'axios';
import { Service } from '../../service';

export class TFAService extends Service {
  private ENDPOINT = 'oauth/user';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async activeTFA(idUsuario: number, usuario: UsuarioSenha): Promise<string> {
    return this.fetch
      .patch(this.ENDPOINT + `/${idUsuario}/tfa/active`, usuario,)
      .then((response) => {
        return response.data;
      });
  }

  async disableTFA(idUsuario: number, usuario: UsuarioSenha): Promise<void> {
    return this.fetch
      .patch(this.ENDPOINT + `/${idUsuario}/tfa/disable`, usuario, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  }


  async disableTFAAdmin(idUsuario: number, usuario: UsuarioSenha): Promise<void> {
    return this.fetch
      .patch(this.ENDPOINT + `/${idUsuario}/tfa/admin/disable`, usuario, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  }

  async generateNewQRCode(idUsuario: number, usuario: UsuarioSenha): Promise<string> {
    return this.fetch
      .patch(this.ENDPOINT + `/${idUsuario}/tfa/qrcode`, usuario, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        return response.data;
      });
  }


}

export const useTFAService = (axios: AxiosInstance) => {
  return new TFAService(axios);
};


//////////////////////////////

export interface UsuarioSenha {
  password: string;
}

