import React, { Fragment, PropsWithChildren, useEffect } from "react";
import { DropZone, DropZoneData } from "./dropzone";
import { ParentElementComponent } from "./element";
import {
  ChildrenElement,
  ItemTypes,
  LayoutDragFeatures,
  ParentElement,
  TLayoutDragSystem,
  useLayoutDragSystem
} from "./hooks/useLayoutDragSystem";
import { LayoutManagerContent } from "./styles";
import { ChildrenElementComponent } from "./subelement";

export interface TDragContext<T> {
  layout: Array<ParentElement<T> | ChildrenElement<T>>;
  setLayout: (layout: Array<ParentElement<T> | ChildrenElement<T>>) => void;
  handleDrop: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => void;
  setHandleDropFeatureData: (
    handleDrop?: (dropZone: DropZoneData<T>, item: DropZoneData<T>) => void
  ) => void;
}

export interface TDragAndDropList<T> {
  dragAndDropName: string;
  onFeatures?: (features: LayoutDragFeatures<T>) => void;
  showBorder?: boolean;
  allOpen?: boolean;
  canDropItem?: (
    dropZone: DropZoneData<T>,
    item: DropZoneData<T>,
    layout: Array<ParentElement<T> | ChildrenElement<T>>
  ) => Promise<boolean>;
  customDragHandling?: (
    dropZone: DropZoneData<T>,
    item: DropZoneData<T>,
    dragDropManager: TLayoutDragSystem<T>,
    maxLevel: number
  ) => void;
  maxLevel: number;
  context: TDragContext<T>;
  renderItem?: (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<T>,
    zone: DropZoneData<T>,
    data?: T
  ) => React.ReactNode;
}

export const DragAndDropList = <T extends unknown>({
  canDropItem,
  onFeatures,
  customDragHandling,
  maxLevel,
  context,
  renderItem,
  showBorder,
  dragAndDropName
}: PropsWithChildren<TDragAndDropList<T>>) => {
  const { layout, setLayout, setHandleDropFeatureData } = context;

  const dragAndDropManager = useLayoutDragSystem<T>(layout, setLayout);

  useEffect(() => {
    onFeatures && onFeatures(dragAndDropManager.features);
  }, [layout]);

  useEffect(() => {
    setHandleDropFeatureData(handle);
  }, []);

  const handle = (dropZone: DropZoneData<T>, item: DropZoneData<T>) => {
    const dropZoneArray = dropZone.path.split("-");
    const itemArray = item.path.split("-");

    if (customDragHandling) {
      customDragHandling(dropZone, item, dragAndDropManager, maxLevel);
    } else {
      if (dropZoneArray.length === 1 && itemArray.length === 1) {
        dragAndDropManager.features.moveElementWithinLayout(dropZone, item);
      } else if (
        itemArray.length >= 1 &&
        itemArray.length <= maxLevel &&
        dropZoneArray.length >= 2 &&
        dropZoneArray.length <= maxLevel
      ) {
        dragAndDropManager.features.moveChildrenFromLayoutToParent(
          dropZone,
          item
        );
      } else if (
        itemArray.length >= 2 &&
        itemArray.length <= maxLevel &&
        dropZoneArray.length === 1
      ) {
        dragAndDropManager.features.moveChildrenFromParentToLayout(
          dropZone,
          item
        );
      }
    }
  };

  const handleDrop = (dropZone: DropZoneData<T>, item: DropZoneData<T>) => {
    if (canDropItem) {
      canDropItem(dropZone, item, layout).then((can) => {
        if (can) {
          handle(dropZone, item);
        }
      });
    }
  };

  const render = (
    element: ParentElement<T> | ChildrenElement<T>,
    path: string,
    childrenCount: number
  ) => {
    let component: JSX.Element = <Fragment />;

    if (element.type === "parent") {
      //
      const parent = element as ParentElement<T>;

      component = (
        <ParentElementComponent
          renderContent={renderItem}
          data={parent}
          handleDrop={handleDrop}
          dragAndDropName={dragAndDropName}
          features={dragAndDropManager.features}
          path={path}
        >
          {parent.subGroups.map((subElement, index) => {
            const currentPath = `${path}-${index}`;
            return render(subElement, currentPath, parent.subGroups.length);
          })}
          <DropZone
            data={{
              data:
                parent.subGroups.length >= 1
                  ? parent.subGroups[parent.subGroups.length - 1]
                  : parent,
              path: `${path}-${parent.subGroups.length}`,
              childrenCount: parent.subGroups.length,
              dragAndDropName: dragAndDropName,
              isInside: parent.subGroups.length >= 1 ? false : true,
              type: ItemTypes.CHILDREN,
            }}
            handleDrop={handleDrop}
            isLast
          />
        </ParentElementComponent>
      );
    } else {
      component = (
        <ChildrenElementComponent
          dragAndDropName={dragAndDropName}
          renderContent={renderItem}
          data={element as ChildrenElement<T>}
          features={dragAndDropManager.features}
          path={path}
        />
      );
    }

    return (
      <Fragment key={element.id}>
        <DropZone
          data={{
            data: element,
            dragAndDropName: dragAndDropName,
            path: path,
            type:
              element.type === "parent" ? ItemTypes.PARENT : ItemTypes.CHILDREN,
            childrenCount: childrenCount,
          }}
          handleDrop={handleDrop}
        />
        {component}
      </Fragment>
    );
  };

  const renderLayout = () => {
    return (
      <Fragment>
        {dragAndDropManager.layout.map((element, index) => {
          const currentPath = `${index}`;
          return render(element, currentPath, dragAndDropManager.layout.length);
        })}

        {
          dragAndDropManager.layout.length === 0 ? <DropZone
            data={{
              path: `${dragAndDropManager.layout.length}`,
              dragAndDropName: dragAndDropName,
              childrenCount: dragAndDropManager.layout.length,
              data: dragAndDropManager.layout[
                dragAndDropManager.layout.length - 1
              ],
              isEmpty: true,
              type: ItemTypes.PARENT,
            }}
            handleDrop={handleDrop}
            isLast
          /> : <DropZone
            data={{
              path: `${dragAndDropManager.layout.length}`,
              dragAndDropName: dragAndDropName,
              childrenCount: dragAndDropManager.layout.length,
              data: dragAndDropManager.layout[
                dragAndDropManager.layout.length - 1
              ],
              type: ItemTypes.PARENT,
            }}
            handleDrop={handleDrop}
            isLast
          />
        }
      </Fragment>
    );
  };

  const renderAll = (showBorders: boolean) => {
    if (showBorders) {
      return <div className="list">{renderLayout()}</div>;
    } else {
      return (
        <div className="area-layout">
          <div className="list-layout">{renderLayout()}</div>
        </div>
      );
    }
  };

  return (
    <LayoutManagerContent>
      {renderAll(showBorder ? true : false)}
    </LayoutManagerContent>
  );
};
