import styled from 'styled-components';
import { SidebarConfig } from '../../../../GlobalStyle';

export const NavbarContent = styled.nav`
  display: flex;
  box-shadow: 4px 0 5px rgb(0 0 0 / 8%);
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 199;
  background-color: var(--color-content-light);
`;

export const NavbarLeft = styled.div`

    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: ${(c: SidebarConfig) => (c.active ? '260px' : '0px')};

  .button-menu-sidebar {
    color: #d2d2d2;
    cursor: pointer;
    margin-right: 24px;
    
    @media (max-width: 1000px) {
      margin-left: 0px;
    }
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .logout{
    margin-right: 18px;
  }

  .username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: 28px;
    padding: 8px 12px 8px 12px;
    margin-right: 18px;

    small {
      font-weight: 500;
      color: var(--color-text-dark);
    }

    .list-options{
      width: 200px;
      background-color: black;
    }

    .user-option-arrow {
      color: var(--color-text-dark);
    }
  }
`;
