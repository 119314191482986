import { Sources } from "quill";
import ImageUploader from "quill-image-uploader";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { ImagemUploaded } from "../../services/api-services/questaoFile";
import { EditorContent } from "./styles";

Quill.register("modules/imageUploader", ImageUploader);

interface TEditor {
  referencia?: string;
  uploadData?: UploadDataImage;
  html: (html: string) => void;
  classname: string;
  currentText?: string;
  options?: any;
}

export interface ImagemData {
  prefixURL: string;
  id: string;
}

export interface UploadDataImage {
  uploadImagem: (
    formData: FormData,
    associated_id: string
  ) => Promise<ImagemUploaded>;
  data: ImagemData;

  getImagensDeletadas: (imagens: string[]) => void;
}

var array: Array<any> = [];

export const Editor: React.FC<TEditor> = (props) => {
  const { html, classname, currentText, uploadData, referencia, options } = props;

  const [moduleState, setModuleState] = useState<any>();

  const [text, setText] = useState("");

  const [newText, setNewText] = useState("");

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (uploadData) {
      const { id, prefixURL } = uploadData.data;

      setModuleState({

        toolbar: {
          handlers: {
            image: () => handleImage(id, prefixURL),
          },
          container: options ? options : [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ header: "1" }, { header: "2" }, "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["link"],
            ["image"],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        },
      });
    } else {
      setModuleState({
        toolbar: {
          handlers: {
            image: () => handleImage("", ""),
          },
          container: options ? options : [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ header: "1" }, { header: "2" }, "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["link"],
            ["image"],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        },
      });
    }
  }, [uploadData]);

  uploadData?.getImagensDeletadas(array);

  useEffect(() => {
    html(text);
  }, [text]);

  useEffect(() => {
    if (currentText) {
      if (currentText !== newText) {
        setNewText(currentText);
      }
    }
  }, [props.currentText]);

  useEffect(() => {
    if (newText) {
      setText(newText);
    }
  }, [newText]);

  const handleImage = (id: string, url: string) => {
    if (uploadData) {
      let input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const { files } = input;

        if (files) {
          const file = files[0];
          const formData = new FormData();

          formData.append("file", file);
          formData.append(
            "info",
            new Blob(
              [
                JSON.stringify({
                  reference: referencia,
                }),
              ],
              { type: "application/json" }
            )
          );

          uploadData.uploadImagem(formData, id).then((res) => {
            const urlImage = url + "/images/" + res.id_image;

            if (quillRef.current) {
              const quill = quillRef.current.getEditor();

              const { index, length } = quill.getSelection(true);

              let position = index;

              if (array.includes(urlImage)) {
                array = array.filter((url) => url !== urlImage);
              }

              quill.insertEmbed(
                position,
                "image",
                {
                  src: urlImage,
                  alt: "question-image",
                },
                "api"
              );

              quill.setSelection(position + 1, length);
            }
          });
        }
      };
    }
  };

  const handleChange = (
    content: string,
    delta: any,
    source: Sources,
    editor
  ) => {
    setText(content);
  };

  return (
    <EditorContent disable={uploadData ? false : true}>
      <ReactQuill
        className={`editor ${classname}`}
        value={text}
        theme={"snow"}
        onChange={handleChange}
        modules={moduleState}
        ref={quillRef}
        formats={formats}
      />
    </EditorContent>
  );
};

const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "header",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "script",
  "link",
  "image",
  "imageBlot",
];

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const imgTag = super.create();

    imgTag.setAttribute("src", value.src);
    imgTag.setAttribute("alt", value.alt);
    imgTag.setAttribute("class", "editor-image");
    imgTag.contentEditable = "false";

    return imgTag;
  }

  static value(node) {
    return { src: node.getAttribute("src"), alt: node.getAttribute("alt") };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);
