import { useState } from "react";
import { BsFileText } from "react-icons/bs";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { Editor } from "../../../../components/editor";
import { TitleIcon } from "../../../../components/title-icon";
import {
  TextLeft,
  TextoContent,
  TextoRow,
  TextRight,
} from "../../../GerenciarQuestoes/views/visualizar-questao/apoios/styles";
import { TextoApoio, useQuestaoContext } from "../../context";
import { ApoioContent, ApoioForm } from "../../styles";

export const CriarTexto: React.FC<{}> = () => {
  //
  const questaoContext = useQuestaoContext();

  const [textoAnterior, setTextoAnterior] = useState<TextoApoio>();

  const [edit, setEdit] = useState(false);

  const [enunciado, setEnunciado] = useState("<p><br></p>");

  const handleTexto = () => {
    if (edit) {
      editarTexto();
    } else {
      criarTexto();
    }
  };

  const clear = () => {
    setEnunciado("<p><br></p>");
    setEdit(false);
  };

  const editarTexto = () => {
    if (textoAnterior) {
      const text: TextoApoio = {
        id: textoAnterior.id,
        sequencia: textoAnterior.sequencia,
        texto: enunciado,
      };

      if (textoAnterior) {
        questaoContext.editarTexto(textoAnterior, text);
      }

      clear();
    }
  };

  const criarTexto = () => {
    const texto: TextoApoio = {
      sequencia: questaoContext.apoios.length + 1,
      texto: enunciado,
      id: "",
    };

    questaoContext.addTexto(texto);
    clear();
  };

  const excluirTexto = (texto: TextoApoio) => {
    questaoContext.removerTexto(texto);
  };

  const handleEditar = (texto: TextoApoio) => {
    setTextoAnterior(texto);
    setEnunciado(texto.texto);
    setEdit(true);
  };

  const textos = (textos: TextoApoio[]) => {
    return (
      <TextoContent>
        {textos.map((ele, index) => {
          const count = index + 1;
          return (
            <TextoRow>
              <TextLeft>
                <div className="number">
                  <b>{`${count}-)`}</b>
                </div>
                <div
                  className="content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: ele.texto,
                  }}
                />
              </TextLeft>
              <TextRight>
                <ButtonBottomLess
                  className="edit"
                  icon={FiEdit2}
                  onClick={() => handleEditar(ele)}
                />
                <ButtonBottomLess
                  className="new-comentarios option-margin"
                  icon={MdDelete}
                  onClick={() => excluirTexto(ele)}
                />
              </TextRight>
            </TextoRow>
          );
        })}
      </TextoContent>
    );
  };

  return (
    <ApoioForm>
      <ApoioContent>
        <TitleIcon
          name={edit ? "Editar texto" : "Novo texto"}
          icon={BsFileText}
        />
        <Editor
          currentText={enunciado}
          referencia={"apoio"}
          classname="editor-enunciado"
          html={(html) => setEnunciado(html)}
          uploadData={questaoContext.imageData}
        />
        <div className="new-apoio">
          <div />
          <ButtonBottomLess
            className="add-alternativa"
            title={edit ? "Salvar alterações" : "Adicionar"}
            icon={edit ? FiCheck : IoAdd}
            onClick={handleTexto}
          />
        </div>
        {textos(questaoContext.apoios)}
      </ApoioContent>
    </ApoioForm>
  );
};
