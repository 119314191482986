import validator from "password-validator";

const validacao = new validator();
validacao
  .is()
  .min(8)
  .is()
  .max(12)
  .has()
  .uppercase(1)
  .has()
  .lowercase(1)
  .has()
  .digits(2)
  .has()
  .symbols(1)
  .has()
  .not()
  .spaces();

export const validar = (value: string, name: string) => {
  const errors = new Array<string>();

  const array = validacao.validate(value, { list: true });

  array.forEach((error) => {
    switch (error) {
      case "min": {
        errors.push(`${name} deve ter no minimo 8 caracteres...`);
        break;
      }
      case "max": {
        errors.push(`${name} deve ter no maximo 8 caracteres...`);
        break;
      }
      case "uppercase": {
        errors.push(`${name} deve ter no minimo 1 letra maiúscula...`);
        break;
      }
      case "lowercase": {
        errors.push(`${name} deve ter no minimo 1 letra minuscula...`);
        break;
      }
      case "digits": {
        errors.push(`${name} deve ter 2 números...`);
        break;
      }
      case "symbols": {
        errors.push(`${name} deve ter 2 símbolos...`);
        break;
      }
      case "spaces": {
        errors.push(`${name} não deve conter espaços...`);
        break;
      }
    }
  });
  return errors;
};

export const countProperties = (errors: any) => {
  let count = 0;
  Object.values(errors).forEach(
    (val: any) => val.length > 0 && (count = count + 1)
  );
  return count;
};
