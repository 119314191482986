import { AxiosInstance } from "axios";
import {
  ItemSelectEntity,
  ItemsSelectEntitiesPage,
} from "../../../components/page/structure/FilterComponent/types";
import { Service } from "../../service";
import { PaginationData } from "./../../../components/pagination/types";

export class TipoLeiService extends Service {
  private ENDPOINT = "law-type";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemTipoLeis(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<TipoLeisPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let tipoLeis = new Array<TipoLei>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        tipoLeis = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: tipoLeis,
          paginationData: pagination,
        };
      });
  }

  async listarTipoLeis(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectEntitiesPage<TipoLei>> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=name&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelectEntity<TipoLei>>();

        response.data.content.forEach((value: TipoLei) =>
          array.push({
            id: value.id,
            value: value.name,
            key: "law-type",
            useId: false,
            entity: value,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async buscarTipoLei(id: number): Promise<TipoLei> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarTipoLei(tipoLei: TipoLeiRequest): Promise<TipoLei> {
    return this.fetch
      .post(this.ENDPOINT, tipoLei, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarTipoLei(tipoLei: TipoLeiRequest): Promise<TipoLei> {
    return this.fetch
      .put(this.ENDPOINT + `/${tipoLei.id}`, tipoLei, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirTipoLei(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useTipoLeiService = (axios: AxiosInstance) => {
  return new TipoLeiService(axios);
};

export interface TipoLei {
  id: number;

  name: string;
  pattern_type: string;
  terms: string;
}

export interface TipoLeiRequest {
  id?: number;
  name: string;
  pattern_type: string;
  terms: string;
}

export type TipoLeisPagination = {
  content: Array<TipoLei>;
  paginationData: PaginationData;
};
