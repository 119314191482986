import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { temaOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  Disciplina,
  useDisciplinaService
} from "../../services/api-services/disciplina";
import { Tema, useTemaService } from "../../services/api-services/tema";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarTema } from "./forms/criar-tema";
import { ExcluirTema } from "./forms/excluir-tema";
import { TemaHeader } from "./views/row-tema";

interface props {
  id: string;
}

export const GerenciarTemasComponent = () => {
  //
  const auth = useAuthContext();

  const { id } = useParams<props>();

  const [disciplina, setDisciplina] = useState<Disciplina>();

  const context = useGerenciarContext();

  const temaService = useTemaService(APIFetch(auth));

  const disciplinaService = useDisciplinaService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const temaState = useState<Array<ItemDataSelect<Tema>>>([]);
  const setTemas = temaState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<Tema>({
    state: temaState,
  });

  const keys: string[] = ["subject"];

  useEffect(() => {
    disciplinaService
      .buscarDisciplina(parseInt(id))
      .then((value: Disciplina) => {
        setDisciplina(value);
      });
  }, []);

  useEffect(() => {
    if (context.loading && disciplina) {
      temaService
        .listagemTema(
          disciplina.name,
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order,
          useTagActions.getFilters(keys)
        )
        .then((temas) => {
          setTemas(
            temas.content.map((value: Tema) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(temas.paginationData);
          context.toPage(temas.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading, disciplina]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo tema"}
          className={"tema-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar" });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirTema />;
      }

      case "criar": {
        return <CriarTema disciplinaVinculada={disciplina} />;
      }
      case "editar": {
        return <CriarTema disciplinaVinculada={disciplina} />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<Tema>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={temaState}
        filters={null}
        page={{
          name: `${disciplina?.name} - Temas`,
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do tema...",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: temaOrdenacao,
          },
          options: [],
          mode: mode,
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Nome</div>,
              <div>Disciplina</div>,
              <div>Ações</div>,
            ],
            dataColumn: TemaHeader(selectedEntitiesAction).temaHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarTemas = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarTemasComponent />
    </GerenciarBaseProvider>
  );
};
