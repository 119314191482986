import styled from "styled-components";

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .item-margin {
    margin: 12px;
  }

  p {
    margin: 12px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-text-grey);
  }
`;

export const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 12px;

  .comentario-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 12px;

    span {
      margin: 12px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-text-grey);
    }
  }

  .tags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`;
