import React, { Fragment } from "react";
import { CodigoIDContent } from "./styles";

export const CodigoID: React.FC<{
  id: number;
  name: string;
  classname?: string;
}> = (props) => {
  const codigoRender = () => {
    return (
      <CodigoIDContent className={props.classname}>
        <span>
          <b>{`#${props.id}`}</b>
        </span>
        <span>{`
         ${props.name}`}</span>
      </CodigoIDContent>
    );
  };

  return <Fragment>{codigoRender()}</Fragment>;
};
