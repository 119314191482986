import styled from "styled-components";

export const GroupFilterBar = styled.div`
  .ambos-validacao {
    margin-right: 24px;
  }
`;

export const HeaderLeft = styled.div`
  .botoes-gerenciamento-questao {
    width: 280px;
    height: 100%;
  }
`;

export const HeaderRight = styled.div`
  .nova-questao {
    width: 140px;

    @media (max-width: 800px) {
      width: auto;
    }
  }
`;

export const CodigoIDContent = styled.div`
  color: var(--color-primary-dark);
  font-weight: 500;
  font-size: 1rem;

  span {
    margin: 2px;
  }
`;

export const LabelContent = styled.div`
  padding: 12px;

  label {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-left: 12px;
  }

  p {
    color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 1rem;
    margin-left: 12px;
  }
`;

export const PesquisaOptions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap;

  small {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 12px;
  }

  .options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
