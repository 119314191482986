import { Dispatch, SetStateAction } from 'react';
import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { APIFetch } from '../../../../services/api';
import { useComentarioService } from '../../../../services/api-services/comentarios';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const ExcluirComentario: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();
  const excluirComentario = useComentarioService(APIFetch(auth));

  const handleExcluir = () => {
    excluirComentario
      .excluirComentario(context.currentAction.id)
      .then((response) => {
        context.changeAction({
          action: 'sucesso',
          id: 0,
          message: 'Comentário foi excluído com sucesso!',
          loading: true,
        });
      })
      .catch((error) => {
        context.changeAction({
          action: 'erro',
          id: 0,
          message: error.response.data.message,
        });
      });
  };

  return (
    <ConfirmationModal
      action={context.currentActionState}
      message={`Tem certeza se deseja excluir o comentário com id #${context.currentAction.id} ?`}
      title={'Excluir comentário'}
      onOptionNot={() =>
        context.changeAction({
          action: '',
          id: 0,
        })
      }
      onOptionYes={handleExcluir}
    />
  );
};
