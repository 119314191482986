import React, { Fragment } from "react";
import { MdOutlineShortText } from "react-icons/md";
import { Editor } from "../../../components/editor";
import { TitleIcon } from "../../../components/title-icon";
import { useQuestaoContext } from "../context";
import { EnunciadoForm } from "../styles";

export const EditarEnunciadoQuestao: React.FC<{}> = () => {
    //
    const { callbacks, questao, imageData } = useQuestaoContext();

    return (
        <Fragment>
            <EnunciadoForm>
                <TitleIcon
                    name={"Enunciado da questão"}
                    icon={MdOutlineShortText}
                />
                <div className="form-group">
                    <Editor
                        uploadData={imageData}
                        referencia={"enunciado"}
                        currentText={questao.enunciado}
                        classname="editor-enunciado"
                        html={(html) => {
                            callbacks.setEnunciado(html);
                        }}
                    />
                </div>
            </EnunciadoForm>
        </Fragment>
    );
};
