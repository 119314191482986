import { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { BiCheckCircle } from "react-icons/bi";
import { CheckIconContent, CheckLabelContent } from "./styles";

export interface TCheckLabel {
    label: string;


}

export interface TCheckIcon {
    checked: React.ComponentType<IconBaseProps>;
    unchecked: React.ComponentType<IconBaseProps>;
    onCheck: (check: boolean) => void;

    initialValue?: boolean;
}


export const CheckLabel: React.FC<TCheckLabel> = ({ label }) => {

    const [check, setCheck] = useState<boolean>(false);

    return <CheckLabelContent>
        {check && <BiCheckCircle size={20} className={"bottom-icon"} />}
        <small onClick={(event) => { check ? setCheck(false) : setCheck(true) }}>{label}</small>
    </CheckLabelContent>
}

export const CheckIcon: React.FC<TCheckIcon> = ({ checked, unchecked, onCheck, initialValue }) => {

    const IconChecked = checked;

    const IconUnchecked = unchecked;

    const [check, setCheck] = useState<boolean>(false);

    useEffect(() => {
        initialValue && setCheck(initialValue);
    }, []);

    useEffect(() => {
        onCheck(check);
    }, [check]);

    return <CheckIconContent>
        {check ? <IconChecked onClick={(event) => {
            check ? setCheck(false) :
                setCheck(true)
        }} size={20} color={"#FFFFFF"} className={"bottom-icon"} /> : <IconUnchecked color={"#FFFFFF"} onClick={(event) => { check ? setCheck(false) : setCheck(true) }} size={20} className={"bottom-icon"} />}
    </CheckIconContent>
}