import styled from "styled-components";

export const MainLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const ContentLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-content-light);
  padding: 0px 40px 0px 40px;
  border-radius: 8px;
  width: 320px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


  .logo-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 42px;

    h2 {
      color: var(--color-text-secondary);
      font-weight: 600;
      flex: 1 1 0;
    }
  }

  form {
    margin-top: 42px;
    width: 100%;

    .input-group {
      width: 100%;
    }
  }

  .login-button {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .login-google{
    margin-bottom: 80px;
  } 

  .banco-legislacao{
      margin-top: 5px;
      margin-bottom: 8px;
  }
`;
