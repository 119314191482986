import { AxiosInstance } from 'axios';
import { MesclagemResponse } from '../../../components/app/mesclar-entidade';
import { ItemSelect, ItemsSelectPage } from '../../../components/page/structure/FilterComponent/types';
import { PaginationData } from '../../../components/pagination/types';
import { Service } from '../../service';

export class BancaService extends Service {
  private ENDPOINT = 'exam-boards';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarBancas(page: number, qtd: number, nome: string): Promise<ItemsSelectPage> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`)
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Banca) =>
          array.push({
            id: value.id,
            value: value.name,
            key: 'bank',
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async listagemBancas(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<BancasPagination> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`)
      .then((response) => {
        let bancas = new Array<Banca>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        bancas = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: bancas,
          paginationData: pagination,
        };
      });
  }

  async buscarBanca(id: number): Promise<Banca> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarBanca(banca: BancaRequest): Promise<Banca> {
    return this.fetch
      .post(this.ENDPOINT, banca, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarBanca(banca: BancaRequest): Promise<Banca> {
    return this.fetch
      .put(this.ENDPOINT + `/${banca.id}`, banca, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirBanca(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }

  async mesclarBancas(
    idDestino: number,
    bancas: Set<number>
  ): Promise<MesclagemResponse> {

    const url = this.ENDPOINT + `/${idDestino}/merge`;

    return this.fetch
      .patch(url, Array.from(bancas))
      .then((value) => {
        return value.data;
      });
  }
}

export const useBancaService = (axios: AxiosInstance) => {
  return new BancaService(axios);
};

export interface Banca {
  id: number;
  name: string;
}

export interface BancaRequest {
  id?: number;
  name: string;
}
export type BancasPagination = {
  content: Array<Banca>;
  paginationData: PaginationData;
};
