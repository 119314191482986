import styled from "styled-components";

export const Input = styled.input`
  background-color: var(--color-content-light);
  color: var(--color-text-secondary);
  font-weight: 600;
  font-size: 0.9rem;
  width: 100%;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
[type=number] {
  -moz-appearance: textfield;
}
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  height: 38px;
  background-color: var(--color-content-light);
  border: 1px solid var(--color-select-border);
  color: var(--color-text-secondary);

  .bottom-icon {
    cursor: pointer;
    color: var(--color-primary-dark);
  }
`;
