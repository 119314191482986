import { AxiosInstance } from "axios";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { Service } from "../../service";
import { getHeaderProva } from "./../../../pages/GerenciarQuestoes/views/visualizar-questao/enunciado/index";

export class ProvaService extends Service {
  private ENDPOINT = "exam";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemProva(
    page: number,
    qtd: string,
    period: string,
    filters: string[],
    search: string,
    sort: string,
    order: string,
    mode: string
  ): Promise<ProvasPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?mode=${mode}&search=search_key:'*${search}*';career:'${filters[5]}';body:'${filters[0]}';bank:'${filters[1]}';office:'${filters[2]}';level:'${filters[3]}';year:'${filters[4]}';period:'${period}'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let provas = new Array<ProvaDetailsCount>();

        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        for (const prova of response.data.content) {
          if (prova.data !== undefined) {
            provas.push(prova as ProvaDetailsCount);
          } else {
            provas.push({
              data: prova as Prova,
              total_questions: 0,
              total_pending_questions: 0,
              total_unclassified_questions: 0,
              is_classified: false,
              is_validated: false,
            });
          }
        }

        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: provas,
          paginationData: pagination,
        };
      });
  }

  async listarProvas(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?mode=${"header"}&search=search_key:'*${nome}*'&page=${page}&size=${qtd}&sort=year&order=desc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Prova) =>
          array.push({
            id: value.id,
            value: getHeaderProva(value),
            key: "exam",
            useId: true,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async buscarProva(id: number): Promise<Prova> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarProva(prova: ProvaRequest): Promise<Prova> {
    return this.fetch
      .post(this.ENDPOINT, prova, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarProva(prova: ProvaRequest): Promise<Prova> {
    return this.fetch
      .put(this.ENDPOINT + `/${prova.id}`, prova, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirProva(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }

  async atribuirCarreiraEmLote(
    provas: Array<number>,
    idCarreira: number
  ): Promise<void> {
    return this.fetch.patch(this.ENDPOINT + `/career/${idCarreira}`, provas);
  }
}

export const useProvaService = (axios: AxiosInstance) => {
  return new ProvaService(axios);
};

export interface ProvaDetailsCount {
  data: Prova;
  total_questions: number;
  total_pending_questions: number;
  total_unclassified_questions: number;
  is_classified: boolean;
  is_validated: boolean;
}

export interface Prova {
  id: number;
  body: {
    id: number;
    name: string;
    abbreviation: string;
  };
  career: {
    id: number;
    name: string;
  };
  exam_board: {
    id: number;
    name: string;
  };
  office: {
    id: number;
    name: string;
  };
  oab_exam_number: string;
  exam_name: string;
  year: number;
  level: string;
}

export interface ProvaRequest {
  id?: number;
  selected_body_id: number;
  selected_office_id: number | null;
  selected_career_id: number | null;
  oab_exam_number: string | null;
  selected_exam_board_id: number;
  level: string;
  year: string;
}

export type ProvasPagination = {
  content: Array<ProvaDetailsCount>;
  paginationData: PaginationData;
};
