import { AxiosInstance } from "axios";
import { ImageInfoDetails, Service } from "../../service";

export class OrgaoFileService extends Service {
    private ENDPOINT = "body";
    async salvarImagemOrgao(
        dados: FormData,
        idOrgao: number
    ): Promise<ImageInfoDetails> {
        return this.fetch
            .post(this.ENDPOINT + `/${idOrgao}/images/upload`, dados)
            .then((response) => {
                return response.data;
            });
    }
    async deletarImagemOrgao(
        idImagem: String,
        idOrgao: number
    ): Promise<void> {
        return this.fetch.delete(
            this.ENDPOINT + `/${idOrgao}/images/${idImagem}`
        );
    }
}

export const useOrgaoFileService = (axios: AxiosInstance) => {
    return new OrgaoFileService(axios);
};
