import { createContext, useContext } from "react";
import { TItemSelect } from ".";

export interface SelectNormalContext {
  selectedItens: Array<TItemSelect>;
  selectItem: TItemSelect;
  open: boolean;
  callbacks: {
    setOpen: (open: boolean) => void;
    getTitle: () => string | undefined;
    handleselectItem: (selected: TItemSelect) => void;
  };
}

const context = createContext<SelectNormalContext>({
  open: true,
  selectItem: {
    id: 0,
    key: "",
    selected: false,
    useId: false,
    value: "",
  },
  selectedItens: [],
  callbacks: {
    getTitle: () => "",
    handleselectItem: () => { },
    setOpen: () => { },
  },
});

export const NormalSelectProvider = context.Provider;
export const NormalSelectConsumer = context.Consumer;

export const useNormalSelect = () => useContext(context);
