import { AxiosInstance } from "axios";
import { ChildrenElement, ParentElement } from "../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { ItemSelect, ItemsSelectPage } from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { Service } from "../../service";

export class EstruturaExportacaoService extends Service {
    private ENDPOINT = "project/export-structure";

    constructor(axios: AxiosInstance) {
        super(axios);
    }

    async listagemEstruturaExportacao(
        page: number,
        qtd: string,
        search: string,
        sort: string,
        order: string
    ): Promise<EstruturaExportacaoPagination> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `?search=search_key:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
            )
            .then((response) => {
                let EstruturaExportacaos = new Array<EstruturaExportacao>();
                let pagination = {
                    current_page: 0,
                    total_items: 0,
                    total_number_pages: 1,
                };

                EstruturaExportacaos = response.data.content;
                pagination = {
                    total_items: response.data.total_items,
                    current_page: response.data.current_page,
                    total_number_pages: response.data.total_number_pages,
                };

                return {
                    content: EstruturaExportacaos,
                    paginationData: pagination,
                };
            });
    }

    async listarEstruturaExportacaos(
        page: number,
        qtd: number,
        nome: string
    ): Promise<ItemsSelectPage> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `?search=search_key:'*${nome}*'&page=${page}&size=${qtd}&sort=name&order=desc`
            )
            .then((response) => {
                const array = new Array<ItemSelect>();

                response.data.content.forEach((value: EstruturaExportacao) =>
                    array.push({
                        id: value.id,
                        value: value.structure_name,
                        key: "export-structure",
                        useId: true,
                    })
                );

                return {
                    content: array,
                    total_number_pages: response.data.total_number_pages,
                };
            });
    }

    async buscarEstruturaExportacao(id: number): Promise<EstruturaExportacao> {
        return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
            return response.data;
        });
    }

    async salvarEstruturaExportacao(EstruturaExportacao: EstruturaExportacaoRequest): Promise<EstruturaExportacao> {
        return this.fetch
            .post(this.ENDPOINT, EstruturaExportacao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async editarEstruturaExportacao(EstruturaExportacao: EstruturaExportacaoRequest): Promise<EstruturaExportacao> {
        return this.fetch
            .put(this.ENDPOINT + `/${EstruturaExportacao.id}`, EstruturaExportacao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async excluirEstruturaExportacao(id: number): Promise<void> {
        return this.fetch.delete(this.ENDPOINT + `/${id}`);
    }
}

export const useEstruturaExportacaoService = (axios: AxiosInstance) => {
    return new EstruturaExportacaoService(axios);
};

export interface EstruturaExportacao {
    id: number;
    structure_name: string,
    structure: {
        map: Array<ParentElement<any> | ChildrenElement<any>>
    }
}


export interface EstruturaExportacaoRequest {
    id?: number;
    structure_name: string,
    structure: {
        map: Array<ParentElement<any> | ChildrenElement<any>>
    }
}


export type EstruturaExportacaoPagination = {
    content: Array<EstruturaExportacao>;
    paginationData: PaginationData;
};
