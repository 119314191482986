import styled from 'styled-components';

export const LoadingContent = styled.div`
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: 200;
 background-color: rgba(255, 255, 255, 0.8);
 position: fixed;
 cursor: pointer;

  margin: 0 auto;
  display: flex;
  align-items: center;

 .loading{
   margin: 0 auto;
   display: flex;
   align-items: center;
   flex-direction: column;

   small {
        margin-left: 12px;
        margin-right: 12px;
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--color-text-grey);
      }

 }
`

export const ProjetoForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 28px;
  width: 100%;

  @media (max-width: 700px) {
    margin: 12px 4px 12px 4px;
  }

  h4 {
    font-weight: 500;
    color: var(--color-text-dark);
    padding: 12px 0px 12px 0px;
    font-weight: bold;
  }

  .informacoes-content{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .sobre-projeto-content{
      margin-right: 28px;
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  .autores-content{
    width: 100%;
  }

  .editor-enunciado {
    width: 100%;
    height: 380px;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;

      label {
        color: var(--color-text-secondary);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;

export const PadraoExportacaoButtonOptions = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Filtros = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ProjetoFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;

  .save-projeto {
    width: 140px;
  }
`;

export const EscolherSeparadorFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;

export interface EscolherSeparadorContentStyles {
  isBetween?: boolean;
}


export const EscolherSeparadorContent = styled.div`
  display: flex;
  flex-direction: column ;
  align-items: center;
  justify-content: ${(styles: EscolherSeparadorContentStyles) => styles.isBetween ? `space-between` : `center`} ;
  width: 100%;


  .menu-selecionar-adjcente{
      width: 100%;
      padding-right: 12px ;
  }

  p {
    margin-bottom: 24px ;
    font-weight: 500;
    font-size: 0.9 rem;
    color: var(--color-text-grey);
  }

  .divisoes{
    height: 20%;
    width: 99% ;
    overflow: auto;
    border-width: 1px;
    border-color: var(--color-select-border);
    border-style: solid;
    border-radius: 8px;

    ::-webkit-scrollbar {
      width: 8px;
      display: block;
    }

    ::-webkit-scrollbar-track {
      background-color: var(--color-background);
      opacity: 0.6;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--color-content-border);
      border-radius: 20px;
    }




    }
  

  
`

export const ConfiguracoesDinamicasContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const MoverConteudoContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .conteudo-opcoes{
    width: 100%;
    height: 100%;
  }
`;

