import styled from "styled-components";

export const VisualizarQuestaoContent = styled.div``;

export const QuestaoOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: var(--color-content-light);
  padding: 12px;
  height: 54px;
`;

export const QuestaoHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  flex-wrap: wrap;
  background-color: var(--color-content-light);
  padding: 12px;
`;

export const EnunciadoContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: -18px;
`;

export const ComentarioOptions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  border: 1px solid var(--color-select-border);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .option-margin {
    margin-left: 24px;
  }
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;

export const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 12px;

  .statement_question {
    margin: 12px;

    span {
      margin: 12px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-text-grey);
    }
  }

  .tags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;
