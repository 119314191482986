import { RefObject, useEffect } from 'react';
type Event = MouseEvent | TouchEvent;

function useMoveScroll<T extends HTMLElement = HTMLElement>(ref: RefObject<T>) {
  useEffect(() => {
    const element = ref.current;

    if (element) {
      let isDown = false;
      let startX = 0;
      let scrollLeft = 0;

      element.addEventListener('mousedown', (e) => {
        isDown = true;
        startX = e.pageX - element.offsetLeft;
        scrollLeft = element.scrollLeft;
      });
      element.addEventListener('mouseleave', () => {
        isDown = false;
      });
      element.addEventListener('mouseup', () => {
        isDown = false;
      });
      element.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - element.offsetLeft;
        const walk = (x - startX) * 3;
        element.scrollLeft = scrollLeft - walk;
      });
    }
  }, [ref]);
}

export default useMoveScroll;
