import { IconBaseProps } from "react-icons";
import { StatusContent } from "./style";

export interface TStatusComponent {
  status?: string;
  icon?: React.ComponentType<IconBaseProps>;
  color: string;
  classname?: string;
}

export const StatusComponent: React.FC<TStatusComponent> = ({
  color,
  icon: Icon,
  status,
  classname,
}) => {
  return (
    <StatusContent className={classname}>
      {Icon && (
        <Icon
          size={18}
          style={{
            color: color,
          }}
        />
      )}
      <div
        style={{
          color: color,
        }}
      >
        {status && status}
      </div>
    </StatusContent>
  );
};
