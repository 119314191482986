import { useEffect, useState } from "react";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { projetoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  ProjetoResume,
  useProjetoService
} from "../../services/api-services/projeto";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ProjetoCard } from "./views/card-projeto";

export const BuscarProjetosComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const projetoService = useProjetoService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const projetoState = useState<Array<ItemDataSelect<ProjetoResume>>>([]);
  const setProjetos = projetoState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<ProjetoResume>({
    state: projetoState,
  });

  useEffect(() => {
    if (context.loading) {
      projetoService
        .listagemProjeto(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          ["", ""],
          search,
          sort,
          order
        )
        .then((projetos) => {
          setProjetos(
            projetos.content.map((value: ProjetoResume) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(projetos.paginationData);
          context.toPage(projetos.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        });
    }
  }, [context.loading]);

  return (
    <GerenciarPageBase<ProjetoResume>
      callbacks={{
        search: (key) => {
          setSearch(key);
          useTagActions.removeAllTags();
          context.changeLoading(true);
        },
        setQtdItens: (qtd: string) => {
          setQtdItems(qtd);
          context.changeLoading(true);
        },
        onCurrentSortParam: (item) => {
          setSort(item);
          context.changeLoading(true);
        },
        onCurrentOrderParam: (sort) => {
          setOrder(sort);
          context.changeLoading(true);
        },

        setCurrentVisualizationMode: (button) => setMode(button.value),
      }}
      selectItensActions={selectedEntitiesAction}
      tagActions={useTagActions}
      dataState={projetoState}
      page={{
        name: "Projetos de questões",
        header: {
          left: null,
          right: null,
        },
        searchPlaceHolder: "Pesquisar por nome do projeto,autor ou ano",
        showFilters: false,
      }}
      visualization={{
        sort: {
          params: projetoOrdenacao,
        },
        options: [],
        mode: "card",
        card: {
          dataColumn: ProjetoCard(selectedEntitiesAction, mode).projetoCard,
        },
      }}
    />
  );
};

export const BuscarProjetos = () => {
  return (
    <GerenciarBaseProvider>
      <BuscarProjetosComponent />
    </GerenciarBaseProvider>
  );
};
