import { AxiosInstance } from "axios";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { ImageInfoDetails, Service } from "../../service";

export class CarreiraService extends Service {
  private ENDPOINT = "career";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarCarreiras(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Carreira) =>
          array.push({
            id: value.id,
            value: value.name,
            key: "career",
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async listagemCarreira(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<CarreirasPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let carreiras = new Array<Carreira>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        carreiras = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: carreiras,
          paginationData: pagination,
        };
      });
  }

  async buscarCarreira(id: number): Promise<Carreira> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarCarreira(carreira: CarreiraRequest): Promise<Carreira> {
    return this.fetch
      .post(this.ENDPOINT, carreira, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarCarreira(carreira: CarreiraRequest): Promise<Carreira> {
    return this.fetch
      .put(this.ENDPOINT + `/${carreira.id}`, carreira, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirCarreira(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useCarreiraService = (axios: AxiosInstance) => {
  return new CarreiraService(axios);
};

export interface Carreira {
  id: number;
  name: string;
  image?: ImageInfoDetails;
}

export interface CarreiraRequest {
  id?: number;
  name: string;
}

export type CarreirasPagination = {
  content: Array<Carreira>;
  paginationData: PaginationData;
};
