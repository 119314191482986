import { AxiosInstance } from "axios";
import { Service } from "../../service";

export class ComentarioFileService extends Service {
  private ENDPOINT = "comment";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async salvarImagemComentario(
    dados: FormData,
    idComentario: number
  ): Promise<ImagemUploaded> {
    return this.fetch
      .post(this.ENDPOINT + `/${idComentario}/images/upload`, dados)
      .then((response) => {
        return response.data;
      });
  }

  async deletarImagemComentario(
    idImagem: String,
    idComentario: number
  ): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idComentario}/images/${idImagem}`
    );
  }
}

export interface ImagemUploaded {
  id_image: string;
}

export const useComentarioFileService = (axios: AxiosInstance) => {
  return new ComentarioFileService(axios);
};
