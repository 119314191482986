import React, { createContext, SetStateAction, useContext } from "react";
import { PaginationData } from "../../pagination/types";
import { ActionType } from "./types";

export interface TGerenciarContext {
  loading: boolean;
  currentAction: ActionType;
  currentActionState: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  paginationData: PaginationData;
  nextPage: number;
  isNewPage: boolean;
  changeAction: (action: ActionType) => void;
  changeLoading: (loading: boolean) => void;
  setIsNewPage: (isNew: boolean) => void;
  changePaginationData: (pagination: PaginationData) => void;
  toPage: (next: number) => void;
  resetPagination: () => boolean;
}

export const GERENCIAR_INITIAL_CONTEXT: TGerenciarContext = {
  loading: false,
  currentAction: {
    id: 0,
    action: "",
    loading: false,
    message: "",
    object: {},
  },
  currentActionState: [
    {
      id: 0,
      action: "",
      loading: false,
      message: "",
      object: {},
    },
    (value: SetStateAction<ActionType>) => { },
  ],
  nextPage: 0,
  isNewPage: false,
  paginationData: {
    current_page: 0,
    total_items: 0,
    total_number_pages: 0,
  },
  changeAction: (action) => { },
  changeLoading: (loading) => { },
  setIsNewPage: (isNew: boolean) => { },
  changePaginationData: (pagination: PaginationData) => { },
  toPage: (next: number) => { },
  resetPagination: () => false,
};

const context = createContext<TGerenciarContext>(GERENCIAR_INITIAL_CONTEXT);

export const GerenciarConsumer = context.Consumer;
export const GerenciarProvider = context.Provider;

export const useGerenciarContext = () => useContext(context);

