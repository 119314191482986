import { Fragment, useEffect, useState } from "react";
import { FiLayout } from "react-icons/fi";
import { MdAddCircleOutline, MdStyle } from "react-icons/md";
import { useHistory } from "react-router";
import { ButtonBottomLess } from "../../components/button/bottomless";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../components/select/async";
import { NormalSelect, TItemSelect } from "../../components/select/normal";
import { projetoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAutorService } from "../../services/api-services/autores";
import {
  ProjetoResume,
  useProjetoService,
} from "../../services/api-services/projeto";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ExcluirProjeto } from "./forms/excluir-projeto";
import { HeaderRight } from "./styles";
import { ProjetoHeader } from "./views/row-projeto";

export const GerenciarProjetosComponent = () => {
  //
  const auth = useAuthContext();

  const history = useHistory();

  const context = useGerenciarContext();

  const projetoService = useProjetoService(APIFetch(auth));

  const autorService = useAutorService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const projetoState = useState<Array<ItemDataSelect<ProjetoResume>>>([]);
  const setProjetos = projetoState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<ProjetoResume>({
    state: projetoState,
  });

  const keys: string[] = ["author", "year"];

  useEffect(() => {
    if (context.loading) {
      projetoService
        .listagemProjeto(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          useTagActions.getFilters(keys),
          search,
          sort,
          order
        )
        .then((projetos) => {
          setProjetos(
            projetos.content.map((value: ProjetoResume) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(projetos.paginationData);
          context.toPage(projetos.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <HeaderRight>
        <div />
        <div>
          <ButtonBottomLess
            className={"estrutura-exportacao-button margin-options"}
            title="Layout"
            icon={FiLayout}
            onClick={() => {
              history.push("/gerenciar-estrutura-exportacao");
            }}
          />
          <ButtonBottomLess
            className={"estilo-exportacao-button margin-options"}
            title="Estilos"
            icon={MdStyle}
            onClick={() => {
              history.push("/gerenciar-estilos-exportacao");
            }}
          />
          <ButtonNormal
            icon={MdAddCircleOutline}
            title={"Novo projeto"}
            className={"projeto-button margin-options"}
            onClick={() => {
              history.push("/projeto");
            }}
          />
        </div>
      </HeaderRight>
    );
  };

  const getYears = () => {
    const array = new Array<TItemSelect>();
    const year = new Date().getFullYear();

    for (var i = year; i != 1900; i--) {
      if (i == year) {
        array.push({
          id: i,
          key: "year",
          selected: true,
          useId: false,
          value: i.toString(),
        });
      } else {
        array.push({
          id: i,
          key: "year",
          selected: false,
          useId: false,
          value: i.toString(),
        });
      }
    }

    return array;
  };

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Projeto" isOpen={true}>
          <GroupMain>
            <AsyncSelect
              className="autor field-group"
              label="Filtrar por autor"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                autorService.listarAutores(page, 30, value)
              }
            />
            <NormalSelect
              minWidth={64}
              className="ano field-group"
              label="Filtrar por ano realização"
              onSelectCurrentItem={(item) => useTagActions.addTag(item)}
              selectedItems={getYears()}
            />
          </GroupMain>
        </Group>
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirProjeto />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<ProjetoResume>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={projetoState}
        filters={filters()}
        page={{
          name: "Gerenciar projeto de questões",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do projeto,autor ou ano",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: projetoOrdenacao,
          },
          options: [],
          mode: mode,
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Nome</div>,
              <div>Ano</div>,
              <div>Visibilidade</div>,
              <div>Ações</div>,
            ],
            dataColumn: ProjetoHeader(selectedEntitiesAction).projetoHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarProjetos = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarProjetosComponent />
    </GerenciarBaseProvider>
  );
};
