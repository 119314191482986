import { AxiosInstance } from "axios";
import { ImageInfoDetails, Service } from "../../service";

export class CarreiraFileService extends Service {

    private ENDPOINT = "career";

    async salvarImagemCarreira(
        dados: FormData,
        idCarreira: number
    ): Promise<ImageInfoDetails> {
        return this.fetch
            .post(this.ENDPOINT + `/${idCarreira}/images/upload`, dados)
            .then((response) => {
                return response.data;
            });
    }

    async deletarImagemCarreira(
        idImagem: String,
        idCarreira: number
    ): Promise<void> {
        return this.fetch.delete(
            this.ENDPOINT + `/${idCarreira}/images/${idImagem}`
        );
    }
}

export const useCarreiraFileService = (axios: AxiosInstance) => {
    return new CarreiraFileService(axios);
};
