import styled from "styled-components";

export const FilterBarContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--color-content-light);
  padding: 10px;
  margin-top: 18px;
  margin-bottom: 18px;
  border-radius: 12px ;
  border: 2px solid var(--color-primary-light-clear);

  .filter-left{
      display: flex;
      margin-bottom: 4px;
      margin-top: 4px;
      padding: 4px;
      flex-wrap: wrap;
      border-radius: 12px ;
      background-color: var(--color-primary-clear);

      @media (min-width: 1600px) {
        border: none;
      }

      @media (max-width: 700px) {
        justify-content: flex-end;
        width: 100%;
       
      }

    div{
        margin-bottom: 12px;
    }

  }

  .filter-right{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-grow: 3;


      @media (max-width: 700px) {
        justify-content: flex-start;
        width: 100%;
      }

  }
`;
