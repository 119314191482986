import styled from "styled-components";

export const CheckIconContent = styled.div`
  display: flex;
  

  .bottom-icon{
    margin-left: 4px;
    margin-right: 8px;
  }
`

export const CheckLabelContent = styled.div`

  user-select: none; 
  transition: background-color 0.3s;

 .bottom-icon {
    margin-right: 8px;
    color: var(--color-primary-darking);
  }

   small {
    color: var(--color-primary-dark);
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  :hover{
    background-color: var(--color-primary-light-clear) ;
    padding: 4px;
    border-radius: 8px;
  }
  

`