import { AxiosInstance } from 'axios';
import { Service } from '../../service';

export class DashboardService extends Service {
  private ENDPOINT = 'dashboard';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async dashboard(): Promise<DashboardEstatisticas> {
    return this.fetch.get(this.ENDPOINT).then((response) => {
      return response.data;
    });
  }
}

export const useDashboard = (axios: AxiosInstance) => {
  return new DashboardService(axios);
};

export interface DashboardEstatisticas {
  registered_comments: number;
  registered_exams: number;
  registered_questions: number;
  registered_questions_no_exams: number;
  registered_questions_outdated: number;
  registered_questions_no_comments: number;
  registered_questions_no_classification: number;
  registered_questions_validated: number;
  registered_questions_pending: number;
  registered_questions_pendency: number;
}
