import styled from "styled-components";

export const RoundedButtonContent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  background: none;

  small {
    color: var(--color-primary-dark);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .bottom-icon {
    margin-right: 8px;
    color: var(--color-primary-darking);
  }

  :hover {
    .bottom-icon {
      color: var(--color-primary-darking);
    }

    small {
      color: var(--color-primary-darking);
    }
  }
`;
