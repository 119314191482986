import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { BiCommentCheck } from 'react-icons/bi';
import { ButtonBottomLess } from '../../components/button/bottomless';
import { Editor, UploadDataImage } from '../../components/editor';
import { Modal } from '../../components/modals/modal-base';
import { ActionType } from '../../components/page/GerenciarPageBase/types';
import { ItemSelect } from '../../components/page/structure/FilterComponent/types';
import { GroupMain } from '../../components/page/structure/Group/styles';
import { AsyncSelect } from '../../components/select/async';
import { APIFetch, APIFileFetch } from '../../services/api';
import { useAutorService } from '../../services/api-services/autores';
import { useComentarioFileService } from '../../services/api-services/comentarioFile';
import { Comentario, ComentarioUpdate, useComentarioService } from '../../services/api-services/comentarios';
import { usePerfilQuestoesService } from '../../services/api-services/perfilQuestoes';
import { useAuthContext } from '../../services/auth-services/auth/contextAuth';
import { ComentarContent, ComentarFooter, ComentarioOptions } from './styles';

interface TComentarQuestao {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  edit: boolean;
  type: string;
}

export const ComentarQuestao: React.FC<TComentarQuestao> = (props) => {
  const [action, setAction] = props.action;

  const auth = useAuthContext();

  const comentarioService = useComentarioService(APIFetch(auth));
  const autorService = useAutorService(APIFetch(auth));
  const perfilQuestoesService = usePerfilQuestoesService(APIFetch(auth));
  const comentarioFileService = useComentarioFileService(APIFileFetch(auth));

  const comentarioInicial = '<p><br></p>';

  const [comentario, setComentario] = useState<Comentario | undefined>(undefined);

  const [uploadData, setUploadData] = useState<UploadDataImage | undefined>(undefined);

  const [selectedAutor, setSelectedAutor] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const [selectedPerfil, setSelectedPerfil] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const [texto, setTexto] = useState<string>('');

  useEffect(() => {
    if (props.edit) {

      comentarioService.buscarComentario(action.object.idComentario).then((comentario) => {
        setComentario(comentario);
        setTexto(comentario.comment_content);
        setUploadData({
          data: {
            prefixURL:
              `${process.env.REACT_APP_STORAGE_IMAGE}/comment/` +
              comentario.id,
            id: comentario.id as unknown as string,
          },
          getImagensDeletadas: (imagens: string[]) => { },
          uploadImagem: (formData: FormData, associatedId: string) => {
            return comentarioFileService.salvarImagemComentario(
              formData,
              associatedId as unknown as number
            );
          }
        });
      });
    }

  }, []);

  const comentar = () => {
    if (props.edit) {
      if (action.object) {
        const request: ComentarioUpdate = {
          comment_content: texto,
        };

        if (props.type === 'enunciado') {
          comentarioService
            .editarComentario(action.object.idQuestao, action.object.idComentario, request)
            .then(() => {
              alert('Comentário alterado com sucesso!');
              setAction({
                action: '',
                id: 0,
                loading: true,
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        } else if (props.type === 'resposta') {
          comentarioService
            .editarComentarioResposta(
              action.object.idQuestao,
              action.object.idResposta,
              action.object.idComentario,
              request
            )
            .then((comentario) => {
              alert('Comentário alterado com sucesso!');
              setAction({
                action: '',
                id: 0,
                loading: true,
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        }
      }
    } else {
      if (action.object) {
        if (props.type === 'enunciado') {
          comentarioService
            .salvarComentario(action.object.idQuestao, {
              comment_content: texto,
              comment_type: 'Enunciado',
              selected_id_author: selectedAutor.id,
              selected_id_question_profile: selectedPerfil.id,
            })
            .then(() => {
              alert('Comentário salvo com sucesso!');
              setAction({
                action: '',
                id: 0,
                loading: true,
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        } else if (props.type === 'resposta') {
          comentarioService
            .salvarComentarioResposta(action.object.idQuestao, action.object.idResposta, {
              comment_content: texto,
              comment_type: 'Alternativa',
              selected_id_author: selectedAutor.id,
              selected_id_question_profile: selectedPerfil.id,
            })
            .then(() => {
              alert('Comentário salvo com sucesso!');
              setAction({
                action: '',
                id: 0,
                loading: true,
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        }
      }
    }
  };

  const Footer = () => {
    const render = () => {
      let footer: JSX.Element = <Fragment />;

      if (props.edit) {
        footer = (
          <ButtonBottomLess className="comentar" title="Salvar alterações" icon={BiCommentCheck} onClick={comentar} />
        );
      } else {
        if (selectedPerfil.value && selectedAutor.value && texto !== comentarioInicial) {
          footer = (
            <ButtonBottomLess className="comentar" title="Salvar comentário" icon={BiCommentCheck} onClick={comentar} />
          );
        }
      }

      return footer;
    };

    return <ComentarFooter>{render()}</ComentarFooter>;
  };

  return (
    <Modal action={props.action} title={'Comentar questão'} options={Footer()}>
      <ComentarContent>
        {!props.edit && (
          <ComentarioOptions>
            <GroupMain>
              <AsyncSelect
                showSelectItem={true}
                className="perfil field-group"
                selectItem={selectedPerfil}
                label="Selecione o perfil de questão"
                onAddFilterItem={(item) => setSelectedPerfil(item)}
                getDataAPI={(page, value) => perfilQuestoesService.listarPerfils(page, 30, value)}
              />
              <AsyncSelect
                showSelectItem={true}
                className="autor field-group"
                label="Selecione o autor"
                selectItem={selectedAutor}
                onAddFilterItem={(item) => setSelectedAutor(item)}
                getDataAPI={(page, value) => autorService.listarAutores(page, 30, value)}
              />
            </GroupMain>
          </ComentarioOptions>
        )}
        <p className="p-style">Escreva um comentário vinculado a questão ou alternativa:</p>
        <Editor referencia='comentario' uploadData={uploadData} currentText={texto} classname="editor-enunciado" html={(html) => setTexto(html)} />
      </ComentarContent>
    </Modal>
  );
};
