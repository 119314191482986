import styled from 'styled-components';

export const Input = styled.input`
  border-radius: 4px;
  background-color: var(--color-content-light);
  color: var(--color-text-secondary);
  font-weight: 500;
  border-radius: 8px;
  font-size: 0.8rem;
  padding: 10px;
  border: 1px solid var(--color-select-border);
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  height: 38px;
  background-color: var(--color-content-light);
  border: 1px solid var(--color-select-border);
  color: var(--color-text-secondary);
  font-weight: 500;
  font-size: 0.875;
`;
