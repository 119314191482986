import { authServer } from "../../api";
import { APIAuthProvider, AuthDataResponse, TokenInfo, UserInfo } from "../../auth-services/auth/contextAuth";

var instance: JusAuthService;

export class JusAuthService extends APIAuthProvider {

    private AUTHORIZATION_SERVER_URL = 'oauth';

    async redirectOAuthLogin(query: string, provider: string): Promise<AuthDataResponse | any> {

        const url = "login/oauth2/code/" + provider + query;

        try {
            const response = await authServer
                .get(url, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                });


            const userInfo: UserInfo = {
                id: response.data.id,
                fullname: response.data.fullname,
                rl: response.data.authorities,
            };

            const tokenInfo: TokenInfo = {
                act: response.data.access_token,
                rt: response.data.refresh_token,
                in: response.data.expires_in,
                iat: response.data.iat,
                provider: response.data.provider,
            };

            return {
                userInfo: userInfo,
                tokenInfo: tokenInfo
            }
        } catch (error: any) {
            throw error;
        }


    }

    async tfaLogin(tfaTk: string, code: string): Promise<AuthDataResponse | any> {

        const data = new URLSearchParams();
        data.append("grant_type", "tfa");
        data.append("tfa_token", tfaTk);
        data.append("tfa_code", code);
        data.append("audience", process.env.REACT_APP_AUDIENCE ? process.env.REACT_APP_AUDIENCE : "");

        try {

            const response = await authServer
                .post(this.AUTHORIZATION_SERVER_URL + "/token", data);

            const userInfo: UserInfo = {
                id: response.data.id,
                fullname: response.data.fullname,
                rl: response.data.authorities,
            };

            const tokenInfo: TokenInfo = {
                act: response.data.access_token,
                rt: response.data.refresh_token,
                in: response.data.expires_in,
                iat: response.data.iat,
                provider: response.data.provider,
            };

            return {
                userInfo: userInfo,
                tokenInfo: tokenInfo
            }

        } catch (error: any) {
            throw error;
        }

    }
    async initiateLogin(username: string, password: string, provider: string): Promise<AuthDataResponse | any> {

        const data = new URLSearchParams();
        data.append("grant_type", "password");
        data.append("username", username);
        data.append("password", password);
        data.append("audience", process.env.REACT_APP_AUDIENCE ? process.env.REACT_APP_AUDIENCE : "");

        try {

            const response = await authServer
                .post(this.AUTHORIZATION_SERVER_URL + "/token", data, {
                    headers: {
                        "provider": provider
                    }
                });

            const userInfo: UserInfo = {
                id: response.data.id,
                fullname: response.data.fullname,
                rl: response.data.authorities,
            };

            const tokenInfo: TokenInfo = {
                act: response.data.access_token,
                rt: response.data.refresh_token,
                in: response.data.expires_in,
                iat: response.data.iat,
                provider: response.data.provider,
            };

            return {
                userInfo: userInfo,
                tokenInfo: tokenInfo
            }

        } catch (error: any) {
            throw error;
        }


    }

    async refresh(refreshTk: string, provider: string): Promise<AuthDataResponse | any> {

        const data = new URLSearchParams();
        data.append("grant_type", "refresh_token");
        data.append("refresh_token", refreshTk);

        try {

            const response = await authServer
                .post(this.AUTHORIZATION_SERVER_URL + "/token", data, {
                    headers: {
                        'provider': provider
                    }
                });

            const userInfo: UserInfo = {
                id: response.data.id,
                fullname: response.data.fullname,
                rl: response.data.authorities,
            };

            const tokenInfo: TokenInfo = {
                act: response.data.access_token,
                rt: response.data.refresh_token,
                in: response.data.expires_in,
                iat: response.data.iat,
                provider: response.data.provider,
            };

            return {
                userInfo: userInfo,
                tokenInfo: tokenInfo
            }

        } catch (error: any) {
            throw error;
        }


    }


}

export const useJusAuthService = () => {

    if (instance === undefined) {
        instance = new JusAuthService();
    }

    return instance;
};