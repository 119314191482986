import React, { Fragment, useState } from "react";
import { BiLayerPlus, BiMenuAltRight } from "react-icons/bi";
import { BsListNested } from "react-icons/bs";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckIcon } from "../../../../components/check-label";
import { CheckBox } from "../../../../components/checkbox";
import {
  ChildrenElement,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { FilterTag } from "../../../../components/filter-tag";
import { GroupButtons } from "../../../../components/group-buttons";
import { InputForm } from "../../../../components/input";
import { Modal } from "../../../../components/modals/modal-base";
import {
  MultipleButtonSelection,
  TButtonSelection
} from "../../../../components/multiple-button-selection";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { SectionText } from "../../../../components/section-text";
import { NormalSelect } from "../../../../components/select/normal";
import { SelectOption } from "../../../../components/select/option";
import { SubMenuTitle } from "../../../../components/sub-menu-title";
import { Tag } from "../../../../components/tag";
import {
  parametrosOrdenacaoQuestao,
  paramsOptions
} from "../../../../mocks/data";
import { DivisaoData } from "../../../../services/api-services/divisoesProjeto";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { generateID } from "../../../../utils/id-generator";
import { Filtros } from "../../styles";
import { TNivel, useDivisoesProjetoContext } from "./context";
import {
  AssistenteEstruturaContent,
  AssistenteEstruturaModal,
  ListaNiveisContent,
  NivelItemContent
} from "./styles";

const ETAPA_INICIAL = {
  id: 0,
  action: "modo-criacao-estrutura",
  loading: false,
  message: "",
  object: {},
};

export interface TAssistenteEstrutura {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const AssistenteEstrutura: React.FC<TAssistenteEstrutura> = ({
  externalAction,
}) => {
  return <AssistenteEstruturaComponent externalAction={externalAction} />;
};

export const AssistenteEstruturaComponent: React.FC<TAssistenteEstrutura> = ({
  externalAction,
}) => {
  const auth = useAuthContext();

  const {
    modoGeracao,
    nivel,
    niveis,
    callbacks,
    layout,
    setLayout,
    parametrosOrdenacao,
  } = useDivisoesProjetoContext();

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const [assistenteEtapa, setAssistenteEtapa] =
    useState<ActionType>(ETAPA_INICIAL);

  const [totalNivel, setTotalNivel] = useState<number>(0);

  const gerarEstruturaProjeto = async () => {
    const totalNivel = niveis.length;

    const indiceNivel = -1;

    let divisoes = await preencherDivisoes(
      totalNivel,
      indiceNivel,
      new Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>(),
      undefined
    );

    setLayout(divisoes);
  };

  const preencherDivisoes = async (
    totalNivel: number,
    indiceNivel: number,
    divisoes: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>,
    parent?: ParentElement<DivisaoData>
  ) => {
    indiceNivel++;

    if (indiceNivel <= totalNivel - 1) {
      const { agrupar, fonte, nome, qtd } = niveis[indiceNivel];

      for (var i = 1; i <= qtd; i++) {
        const divisao: ParentElement<DivisaoData> = {
          id: generateID(),
          type: "parent",
          isOpen: false,
          data: {
            is_grouping: agrupar,
            level: nome,
            order: i,
            restart_sequence: false,
            additional_text: "",
            metadata: {
              parameter: fonte ? fonte : "",
              value: "",
            },
          },
          name: `${i} - ${nome}`,
          subGroups: new Array<
            ParentElement<DivisaoData> | ChildrenElement<DivisaoData>
          >(),
        };

        if (indiceNivel === 0) {
          divisoes = [...divisoes, divisao];
        } else {
          if (parent) {
            parent.subGroups.push(divisao);
          }
        }
      }

      for (var i = 0; i < qtd; i++) {
        if (parent) {
          const parentTmp = parent.subGroups[i] as ParentElement<DivisaoData>;
          preencherDivisoes(totalNivel, indiceNivel, divisoes, parentTmp);
        } else if (indiceNivel === 0) {
          const parentTmp = divisoes[i] as ParentElement<DivisaoData>;
          preencherDivisoes(totalNivel, indiceNivel, divisoes, parentTmp);
        }
      }
    }

    return divisoes;
  };

  const isSelected = () => {
    const nivelResult = nivel - totalNivel;

    if (nivelResult === 0) {
      return true;
    }

    return false;
  };

  const selecaoNiveisTitulo = () => {
    const nivelResult = nivel - totalNivel;

    if (nivelResult === 1) {
      return `Nessa etapa você deve selecionar ${nivelResult} nível de hierarquia`;
    } else {
      return `Nessa etapa você deve selecionar ${nivelResult} níveis de hierarquia`;
    }
  };

  const modoCriacaoEstrutura = () => {
    return (
      <Fragment>
        <SubMenuTitle
          title="Etapa 1 - Como gerar a estrutura"
          subTitle="Escolha como deseja que a estrutura seja configurada"
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <GroupButtons
            initialPositionSelected={-1}
            key={"geracao-options"}
            className="geracao-options"
            buttons={[
              {
                onClick: (current) => {
                  callbacks.setModoGeracao(Number(current.value));
                },
                name: "Geração dinâmica",
                classname: "geracao-option",
                value: "0",
              },
              {
                onClick: (current) => {
                  callbacks.setModoGeracao(Number(current.value));
                },
                name: "Geração manual",
                classname: "geracao-option",
                value: "1",
              },
            ]}
          />
        </SubMenuTitle>
        <div className="button-content">
          {
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({ id: 0, action: "niveis-hierarquia" });
              }}
            />
          }
        </div>
      </Fragment>
    );
  };

  const nivelHierarquia = (etapa: number, proximaEtapa: string) => {
    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Nível de Hierarquia`}
          subTitle="Vamos começar especificando a quantidade de níveis dos agrupamentos"
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <GroupButtons
            initialPositionSelected={-1}
            key={"nivel-options"}
            className="nivel-options"
            buttons={[
              {
                onClick: (current) => {
                  callbacks.setNivel(Number(current.value));
                },
                name: "1 nível",
                classname: "nivel-option",
                value: "1",
              },
              {
                onClick: (current) => {
                  callbacks.setNivel(Number(current.value));
                },
                name: "2 níveis",
                classname: "nivel-option",
                value: "2",
              },
              {
                onClick: (current) => {
                  callbacks.setNivel(Number(current.value));
                },
                name: "3 níveis",
                classname: "nivel-option",
                value: "3",
              },
              {
                onClick: (current) => {
                  callbacks.setNivel(Number(current.value));
                },
                name: "4 níveis",
                classname: "nivel-option",
                value: "4",
              },
            ]}
          />
        </SubMenuTitle>
        <div className="button-content">
          {nivel >= 1 && nivel <= 4 && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({
                  id: 0,
                  action: proximaEtapa,
                });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const selecaoDosNiveis = (etapa: number, proximaEtapa: string) => {
    const setNiveis = (values: TButtonSelection[]) => {
      let newNiveis = new Array<TNivel>();

      values.forEach((value) => {
        if (value.selected) {
          newNiveis = [
            ...newNiveis,
            {
              nome: value.name,
              agrupar: false,
              fonte: "",
              ordem: value.value,
              qtd: 0,
            },
          ];
        }
      });

      callbacks.setNiveis(newNiveis);
    };

    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Seleção dos níveis`}
          subTitle={selecaoNiveisTitulo()}
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <MultipleButtonSelection
            isHierarchy
            totalSelectedValue={(value) => {
              setTotalNivel(value);
            }}
            getValues={(values) => setNiveis(values)}
            maxSelections={nivel}
            buttons={[
              {
                name: "Capítulo",
                selected: false,
                value: 1,
                icon: BiLayerPlus,
              },
              {
                name: "Subcapítulo",
                selected: false,
                value: 2,
                icon: BiLayerPlus,
              },
              {
                name: "Título",
                selected: false,
                value: 3,
                icon: BiLayerPlus,
              },
              {
                name: "Subtítulo",
                selected: false,
                value: 4,
                icon: BiLayerPlus,
              },
            ]}
          />
        </SubMenuTitle>
        <div className="button-content">
          {niveis.length === nivel && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({ id: 0, action: proximaEtapa });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const definicaoQtd = (etapa: number, proximaEtapa: string) => {
    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Definição da quantidade de níveis`}
          subTitle={"Selecione a quantidade de separadores de cada nível "}
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <ListaNiveisComponent fase={assistenteEtapa.action} />
        </SubMenuTitle>
        <div className="button-content">
          {
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({ id: 0, action: proximaEtapa });
              }}
            />
          }
        </div>
      </Fragment>
    );
  };

  const configurarAgrupamento = (etapa: number, proximaEtapa: string) => {
    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Configuração dos agrupamentos`}
          subTitle={
            "Marque quais níveis poderão receber contéudo(questões) dentro de seu nível"
          }
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <ListaNiveisComponent fase={assistenteEtapa.action} />
        </SubMenuTitle>
        <div className="button-content">
          {isSelected() && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({ id: 0, action: proximaEtapa });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const configurarTitulos = (etapa: number, proximaEtapa: string) => {
    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Configuração dos títulos dos níveis`}
          subTitle={
            "Defina a fonte de dados para o título de cada nível ou deixe vazio caso não queira definir agora"
          }
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <ListaNiveisComponent fase={assistenteEtapa.action} />
        </SubMenuTitle>
        <div className="button-content">
          {isSelected() && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({
                  id: 0,
                  action: proximaEtapa,
                });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const configurarParametroOrdenacao = (
    etapa: number,
    proximaEtapa: string
  ) => {
    return (
      <Fragment>
        <SubMenuTitle
          title={`Etapa ${etapa} - Ordenação das questões`}
          subTitle="Escolha com quais parâmetros as questões serão ordenadas dentro dos agrupamentos"
          icon={BiMenuAltRight}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <SelectOption
            className="add-parametro-ordenacao"
            label="Escolher parâmetro"
            onAddFilterItem={(item) =>
              parametrosOrdenacao.addTag({
                ...item,
                object: "asc",
              })
            }
            getData={parametrosOrdenacaoQuestao}
          />
          <Filtros className="field-group">
            {parametrosOrdenacao.getTags().map((item, index) => {
              return (
                <FilterTag
                  option={
                    <CheckIcon
                      checked={HiSortDescending}
                      unchecked={HiSortAscending}
                      initialValue={item.object === "desc" ? true : false}
                      onCheck={(value) => {
                        parametrosOrdenacao.setObjectByTagValue(
                          item,
                          value ? "desc" : "asc"
                        );
                      }}
                    />
                  }
                  key={index}
                  nome={item.value}
                  onMouseClick={() => parametrosOrdenacao.removeTag(item)}
                />
              );
            })}
          </Filtros>
        </SubMenuTitle>
        <div className="button-content">
          {parametrosOrdenacao.getTags().length >= 1 && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                setAssistenteEtapa({
                  id: 0,
                  action: proximaEtapa,
                });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const configuracaoFinalizada = () => {
    return (
      <Fragment>
        <SectionText
          title="Pré-configuração da estrutura finalizada"
          icon={BsListNested}
        >
          <p>
            A seguir, clique em finalizar para que a estrutura seja pré gerada
            na página "Criar projeto" e você poderá continuar editando a
            estrutura...
          </p>
        </SectionText>
        <div className="button-content">
          {isSelected() && (
            <ButtonBottomLess
              className="proxima-etapa-button"
              title="Próximo"
              onClick={() => {
                modoGeracao === 1 && gerarEstruturaProjeto();
                setAssistenteEtapa({ id: 0, action: "" });
                setCurrentAction({ id: 0, action: "" });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  const etapas = () => {
    switch (assistenteEtapa.action) {
      case "modo-criacao-estrutura": {
        return modoCriacaoEstrutura();
      }
      default: {
        return modoGeracaoOptions();
      }
    }
  };

  const modoGeracaoOptions = () => {
    if (modoGeracao == 0) {
      return etapasGeracaoDinamica();
    } else if (modoGeracao == 1) {
      return etapasGeracaoManual();
    }
  };

  const etapasGeracaoDinamica = () => {
    switch (assistenteEtapa.action) {
      case "niveis-hierarquia": {
        return nivelHierarquia(2, "selecao-niveis");
      }
      case "selecao-niveis": {
        return selecaoDosNiveis(3, "configurar-titulos");
      }
      case "configurar-titulos": {
        return configurarTitulos(4, "configurar-ordenacao");
      }
      case "configurar-ordenacao": {
        return configurarParametroOrdenacao(5, "configuracao-finalizada");
      }
      case "configuracao-finalizada": {
        return configuracaoFinalizada();
      }
      default: {
        return <Fragment />;
      }
    }
  };

  const etapasGeracaoManual = () => {
    switch (assistenteEtapa.action) {
      case "niveis-hierarquia": {
        return nivelHierarquia(2, "selecao-niveis");
      }
      case "selecao-niveis": {
        return selecaoDosNiveis(3, "definicao-qtd");
      }
      case "definicao-qtd": {
        return definicaoQtd(4, "configurar-agrupamento");
      }
      case "configurar-agrupamento": {
        return configurarAgrupamento(5, "configurar-titulos");
      }
      case "configurar-titulos": {
        return configurarTitulos(6, "configurar-ordenacao");
      }
      case "configurar-ordenacao": {
        return configurarParametroOrdenacao(5, "configuracao-finalizada");
      }
      case "configuracao-finalizada": {
        return configuracaoFinalizada();
      }
      default: {
        return <Fragment />;
      }
    }
  };

  return (
    <Modal
      width={900}
      action={actionState}
      options={<Fragment />}
      title={"Assistente de Estrutura de Projetos"}
    >
      <AssistenteEstruturaModal>
        <AssistenteEstruturaContent>{etapas()}</AssistenteEstruturaContent>
      </AssistenteEstruturaModal>
    </Modal>
  );
};

export interface TListaNiveisComponent {
  fase: string;
}

export const ListaNiveisComponent: React.FC<TListaNiveisComponent> = ({
  fase,
}) => {
  const { niveis, callbacks } = useDivisoesProjetoContext();

  const setNivel = (
    nivel: TNivel,
    value: any,
    index: number,
    field: string
  ) => {
    const nivelTmp = { ...nivel, [field]: value };

    let niveisTmp = [...niveis];
    niveisTmp[index] = nivelTmp;

    callbacks.setNiveis(niveisTmp);
  };

  const showItem = (value: TNivel, index: number) => {
    const renderDefinicaoQtdOptions = () => {
      return (
        <NivelItemContent
          row={{
            marginLeft: index == 0 ? 0 : 5 * (index + 1),
          }}
        >
          <div>
            <Tag classname={"titulo"} nome={value.nome} />
          </div>
          <div>
            <label>Qtd: </label>
            <InputForm
              type={"number"}
              placeholder={"?"}
              max={2000}
              onChange={(qtd) =>
                setNivel(value, Number(qtd.currentTarget.value), index, "qtd")
              }
            />
          </div>
        </NivelItemContent>
      );
    };

    const renderConfigurarAgrupamentoOptions = () => {
      return (
        <NivelItemContent
          row={{
            marginLeft: index == 0 ? 0 : 5 * (index + 1),
          }}
        >
          <div>
            <Tag classname={"titulo"} nome={value.nome} />
          </div>
          <div>
            <CheckBox
              key={index}
              className={"agrupar"}
              label={"Agrupar"}
              onChange={(event) =>
                setNivel(value, event.currentTarget.checked, index, "agrupar")
              }
            />
          </div>
        </NivelItemContent>
      );
    };

    const renderDefinirFonteOptions = () => {
      return (
        <NivelItemContent
          row={{
            marginLeft: index == 0 ? 0 : 5 * (index + 1),
          }}
        >
          <div>
            <Tag classname={"titulo"} nome={value.nome} />
          </div>
          <div>
            <NormalSelect
              minWidth={200}
              className="parametro"
              label="Selecione parâmetro"
              onSelectCurrentItem={(item) =>
                setNivel(value, item.id, index, "fonte")
              }
              selectedItems={paramsOptions}
            />
          </div>
        </NivelItemContent>
      );
    };

    switch (fase) {
      case "definicao-qtd": {
        return renderDefinicaoQtdOptions();
      }
      case "configurar-agrupamento": {
        return renderConfigurarAgrupamentoOptions();
      }
      case "configurar-titulos": {
        return renderDefinirFonteOptions();
      }
    }
  };

  return (
    <ListaNiveisContent>
      {niveis.map((value, index) => {
        return showItem(value, index);
      })}
    </ListaNiveisContent>
  );
};
