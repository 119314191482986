import { createContext, useContext } from "react";
import { UploadDataImage } from "../../components/editor";
import {
  ItemSelect,
  ItemSelectEntity
} from "../../components/page/structure/FilterComponent/types";
import { TItemSelect } from "../../components/select/normal";
import { TipoPremissa } from "../../mocks/data";
import { Prova } from "../../services/api-services/prova";
import { QuestaoDetails, QuestaoResumida } from "../../services/api-services/questao";
import { TipoLei } from "../../services/api-services/tipoLeis";

export interface Resposta {
  id: string;
  answer: string;
  points: number;
  column: string;
  anwser_correct: TItemSelect;
  related_column: string;
}

export interface TextoApoio {
  id: string;
  sequencia: number;
  texto: string;
}

export interface TReferencialegal {

  id?: number;
  lei: {
    value: string;
    erro: string;
  };
  artigo: {
    value: string;
    erro: string;
  };

  paragrafo: {
    value: string;
    erro: string;
  };

  inciso: {
    value: string;
    erro: string;
  };

  alinea: {
    value: string;
    erro: string;
  };

  item: {
    value: string;
    erro: string;
  };

  tipo_lei: ItemSelectEntity<TipoLei>;
}

export interface TQuestao {
  tipo: string;
  tipoResposta: string;

  enunciado: string;
  disciplina: ItemSelect;
  tema: ItemSelect;
  tag: ItemSelect;
  dificuldade: TItemSelect;
  correto: TItemSelect;
  fontes: ItemSelect[];
  referencias_legais: TReferencialegal[];
  natureza: TItemSelect;
  status: TItemSelect;
  ativarDiscProva: boolean;
  discProva: string;
}

export interface TQuestaoContext {
  idQuestao: string | undefined;
  imageData: UploadDataImage | undefined;
  tipoPremissa?: TipoPremissa;
  questao: TQuestao;
  respostas: Resposta[][];
  apoios: TextoApoio[];
  prova?: Prova;
  addTexto: (resposta: TextoApoio) => void;
  editarTexto: (textoAnterior: TextoApoio, textoAlterado: TextoApoio) => void;
  removerTexto: (resposta: TextoApoio) => void;
  addResposta: (resposta: Resposta, coluna: string) => void;
  editarResposta: (
    respostaAnterior: Resposta,
    respostaAlterada: Resposta,
    coluna: string
  ) => void;
  removerResposta: (resposta: Resposta, coluna: string) => void;
  addReferenciaLegal: (referenciaLegal: TReferencialegal) => void
  editarReferenciaLegal: (referenciaAtualizar: TReferencialegal, referenciaAlvo: TReferencialegal) => void
  deletarReferenciaLegal: (referencia: TReferencialegal) => void
  salvarQuestaoAPI: () => Promise<QuestaoResumida | void>;
  atribuirTipoRespostaAPI: (tipo: string) => void;
  callbacks: {
    setTipo: (value: string) => void;
    setTipoPremissa: (tipo: TipoPremissa) => void;
    setEnunciado: (value: string) => void;
    setDisciplina: (value: ItemSelect) => void;
    setTema: (value: ItemSelect) => void;
    setTAG: (value: ItemSelect) => void;
    setDificuldade: (value: TItemSelect) => void;
    setCorreto: (value: TItemSelect) => void;
    setFontes: (value: ItemSelect[]) => void;
    setNatureza: (value: TItemSelect) => void;
    setStatus: (value: TItemSelect) => void;
    setTipoResposta: (value: string) => void;
    setIdQuestao: (value: string | undefined) => void;
    setProva: (value?: Prova) => void;
    setQuestaoData: (questao: QuestaoDetails) => void;
    setAtivarDiscProva: (value: boolean) => void;
    setDiscProva: (value: string) => void;
  };
}

const questaoContext = createContext<TQuestaoContext>({
  questao: {
    tipo: "",
    tipoResposta: "",
    referencias_legais: [],
    disciplina: {
      id: -1,
      key: "",
      useId: false,
      value: "",
    },
    enunciado: "",
    fontes: [],
    natureza: {
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    },
    dificuldade: {
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    },
    correto: {
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    },
    status: {
      id: -1,
      key: "",
      selected: false,
      useId: false,
      value: "",
    },
    tema: {
      id: -1,
      key: "",
      useId: false,
      value: "",
    },
    tag: {
      id: -1,
      key: "",
      useId: false,
      value: "",
    },
    ativarDiscProva: false,
    discProva: ""
  },
  idQuestao: undefined,
  imageData: undefined,
  tipoPremissa: undefined,
  prova: undefined,
  respostas: [],
  apoios: [],
  addTexto: (texto) => { },
  editarTexto: (textoAnterior, textoAlterado) => { },
  removerTexto: (texto) => { },
  addResposta: (resposta, coluna) => { },
  editarResposta: (respostaAnterior, respostaAlterada, coluna) => { },
  removerResposta: (resposta, coluna) => { },
  addReferenciaLegal: (referenciaLegal: TReferencialegal) => { },
  editarReferenciaLegal: (referenciaAtualizar: TReferencialegal, referenciaAlvo: TReferencialegal) => { },
  deletarReferenciaLegal: (referencia: TReferencialegal) => { },
  salvarQuestaoAPI: () => new Promise<QuestaoResumida | void>((value) => { }),
  atribuirTipoRespostaAPI: (tipo) => { },
  callbacks: {
    setTipo: (value) => { },
    setEnunciado: (value) => { },
    setDisciplina: (value) => { },
    setTema: (value) => { },
    setTAG: (value) => { },
    setTipoPremissa: (value) => { },
    setDificuldade: (value) => { },
    setFontes: (value) => { },
    setCorreto: (value) => { },
    setNatureza: (value) => { },
    setStatus: (value) => { },
    setTipoResposta: (value) => { },
    setIdQuestao: (valueing) => { },
    setProva: (value) => { },
    setQuestaoData: (value) => { },
    setAtivarDiscProva: (value) => { },
    setDiscProva: (value) => { }
  },
});

export const QuestaoProvider = questaoContext.Provider;
export const QuestaoConsumer = questaoContext.Consumer;

export const useQuestaoContext = () => useContext(questaoContext);
