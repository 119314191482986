import styled from 'styled-components';

export const FormCriarOrgao = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  margin-left: 12px;
  border-radius: 8px;

  .form-group {
    margin: 12px;
  }

  label {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    .form-group {
      width: 100%;
    }
  }
`;
