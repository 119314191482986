import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { estruturaExportacaoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { EstruturaExportacao, useEstruturaExportacaoService } from "../../services/api-services/estruturaExportacao";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarEstruturaExportacao } from "./forms/criar-estrutura-exportacao";
import { ExcluirEstruturaExportacao } from "./forms/excluir-estrutura-exportacao";
import { EstruturaExportacaoHeader } from "./views/row-estrutura-exportacao";

const GerenciarEstruturaExportacaoesComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const estruturaexportacaoService = useEstruturaExportacaoService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const estruturaexportacaoState = useState<Array<ItemDataSelect<EstruturaExportacao>>>([]);
  const setEstruturaExportacaoes = estruturaexportacaoState[1];

  const selectedEntitiesAction = useSelectedDataAction<EstruturaExportacao>({
    state: estruturaexportacaoState,
  });

  useEffect(() => {
    if (context.loading) {
      estruturaexportacaoService
        .listagemEstruturaExportacao(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((estruturaexportacaoes) => {
          setEstruturaExportacaoes(
            estruturaexportacaoes.content.map((value: EstruturaExportacao) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(estruturaexportacaoes.paginationData);
          context.toPage(estruturaexportacaoes.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Nova Estrutura de Exportação"}
          className={"estrutura-exportacao-button"}
          onClick={() => {
            context.changeAction({
              id: 0,
              action: "criar-estrutura-exportacao",
            });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirEstruturaExportacao />;
      }
      case "criar-estrutura-exportacao": {
        return <CriarEstruturaExportacao />;
      }
      case "editar-estrutura-exportacao": {
        return <CriarEstruturaExportacao />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() => {
              context.changeAction({
                id: 0,
                action: "",
              });
            }}
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                id: 0,
                action: "",
              })
            }
          />
        );
      }
    }
  };
  return (
    <Fragment>
      <GerenciarPageBase<EstruturaExportacao>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={estruturaexportacaoState}
        page={{
          name: "Listagem de Estruturas de Exportação",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome da estrutura",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: estruturaExportacaoOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [<div>ID</div>, <div>Nome</div>, <div>Ações</div>],
            dataColumn: EstruturaExportacaoHeader(selectedEntitiesAction).estruturaExportacaoHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarEstruturasExportacao = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarEstruturaExportacaoesComponent />
    </GerenciarBaseProvider>
  );
};
