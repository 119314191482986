import { ReferenciasLegaisDetails } from '../../../../../services/api-services/questao';
import { mapReferenciasLegaisByQuestaoDetails } from '../../../../CriarQuestao/provider';
import { ListaReferenciasLegais } from '../../referencias-legais';

export interface ReferenciasLegaisViewProps {
    referenciasDetails?: ReferenciasLegaisDetails[];

    className?: string;
}


export const ReferenciasLegaisViewDetails = ({ referenciasDetails }: ReferenciasLegaisViewProps) => {

    const referencias = referenciasDetails && mapReferenciasLegaisByQuestaoDetails(referenciasDetails);

    return (
        <ListaReferenciasLegais
            referencias_legais={referencias}
            agruparAcima={false}
            modoAtualizacao={false}
        />
    );
};
