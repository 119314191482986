import { Dispatch, SetStateAction } from 'react';
import { ButtonGroup } from '../../button/group';
import { ActionType } from '../../page/GerenciarPageBase/types';
import { ModalOption } from '../modal-option';

export const ConfirmationModal: React.FC<{
  title: string;
  message: string;
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  onOptionYes: () => void;
  onOptionNot: () => void;
}> = (props) => {
  return (
    <ModalOption
      action={props.action}
      title={props.title}
      options={
        <div className="button-option">
          <ButtonGroup onClick={props.onOptionYes} title={'Sim'} active={true} className="option-yes" />
          <ButtonGroup onClick={props.onOptionNot} title={'Não'} active={false} className="option-not" />
        </div>
      }>
      <p>{props.message}</p>
    </ModalOption>
  );
};
