import styled from "styled-components";

export interface ImagemDisable {
  disable?: boolean;
}

export const EditorContent = styled.div`
  width: 100%;

  .editor {
    div {
      padding: 12px;
      border-color: var(--color-primary-light-clear);
    }

    ${(imagem: ImagemDisable) =>
      imagem.disable &&
      `
        .ql-image {

      :active {
        .ql-stroke {
          stroke: #e0e0e0;
        }

        .ql-fill {
          fill: #e0e0e0;
        }
      }

       :hover {
        .ql-stroke {
          stroke: #e0e0e0;
        }

        .ql-fill {
          fill: #e0e0e0;
        }
      }

      .ql-stroke {
        stroke: #e0e0e0;
      }

      .ql-fill {
        fill: #e0e0e0;
      }
    }
    `}
  }
`;
