import { CheckBoxItem } from "../components/group-checkbox";
import { ItemSelect } from "../components/page/structure/FilterComponent/types";
import { QuestaoPesquisaOpcoes } from "../pages/GerenciarQuestoes";
import { DispositivoItem } from "../pages/GerenciarQuestoes/views/referencias-legais/hooks/useDispositivosLegaisHook/types";
import { SortItem } from "./../components/page/structure/FilterComponent/types";
import { TItemSelect } from "./../components/select/normal/index";

export const fontes: Array<ItemSelect> = [
  {
    id: 1,
    key: "source",
    value: "Norma",
    useId: false,
  },
  {
    id: 2,
    key: "source",
    value: "Jurisprudência",
    useId: false,
  },
  {
    id: 3,
    key: "source",
    value: "Doutrina",
    useId: false,
  },
];

export const dificuldades: Array<ItemSelect> = [
  {
    id: 1,
    key: "difficulty",
    value: "Fácil",
    useId: false,
  },
  {
    id: 2,
    key: "difficulty",
    value: "Intermediário",
    useId: false,
  },
  {
    id: 3,
    key: "difficulty",
    value: "Difícil",
    useId: false,
  },
];

export const tipoQuestao: Array<ItemSelect> = [
  {
    id: 1,
    key: "type",
    value: "Dissertativa",
    useId: false,
  },
  {
    id: 2,
    key: "type",
    value: "Objetiva",
    useId: false,
  },
];

export const nivelProva: Array<ItemSelect> = [
  {
    id: 1,
    key: "level",
    value: "Médio",
    useId: false,
  },
  {
    id: 2,
    key: "level",
    value: "Superior",
    useId: false,
  },
  {
    id: 3,
    key: "level",
    value: "Específico",
    useId: false,
  },
];

export const statusQuestao: Array<ItemSelect> = [
  {
    id: 1,
    key: "status",
    value: "Regular",
    useId: false,
  },
  {
    id: 2,
    key: "status",
    value: "Desatualizada",
    useId: false,
  },
  {
    id: 3,
    key: "status",
    value: "Cancelada",
    useId: false,
  },
];

export const classificacaoQuestao: Array<ItemSelect> = [
  {
    id: 1,
    key: "classification",
    value: "Classificada",
    useId: true,
  },
  {
    id: 2,
    key: "classification",
    value: "Não classificada",
    useId: true,
  },
];

export const validacaoQuestao: Array<ItemSelect> = [
  {
    id: 1,
    key: "validation",
    value: "Validada",
    useId: false,
  },
  {
    id: 2,
    key: "validation",
    value: "Pendente",
    useId: false,
  },
];

export const comentarioStatus: Array<ItemSelect> = [
  {
    id: 1,
    key: "validation",
    value: "Publicado",
    useId: false,
  },
  {
    id: 2,
    key: "validation",
    value: "Pendente",
    useId: false,
  },
];

export const questaoOrdenacao: Array<SortItem> = [
  {
    entity: "",
    value: "ID",
  },
  {
    entity: "cod",
    value: "Código",
  },
  {
    entity: "creationDate",
    value: "Data",
  },
  {
    entity: "year",
    value: "Ano prova",
  },
  {
    entity: "difficulty",
    value: "Dificuldade",
  },
  {
    entity: "body",
    value: "Orgão",
  },
  {
    entity: "exam-boards",
    value: "Banca",
  },
  {
    entity: "office",
    value: "Cargo",
  },
  {
    entity: "subject",
    value: "Disciplina",
  },
  {
    entity: "topic",
    value: "Tema",
  },
  {
    entity: "type",
    value: "Tipo",
  },
  {
    entity: "status",
    value: "Status",
  },

  {
    entity: "validation",
    value: "Validação",
  },
];

export const indicacaoFonteRespostaOrdenacao: Array<SortItem> = [
  {
    entity: "date_indication",
    value: "Data de indicação",
  },
  {
    entity: "id",
    value: "ID",
  },
];

export const projetoQuestaoOrdenacao: Array<SortItem> = [
  {
    entity: "default",
    value: "Padrão",
  },
  ...questaoOrdenacao,
];

export const comentarioOrdenacao: Array<SortItem> = [
  {
    entity: "",
    value: "Id",
  },
  {
    entity: "creationDate",
    value: "Data criação",
  },
];

export const usuarioOrdenacao: Array<SortItem> = [
  {
    entity: "",
    value: "ID",
  },
  {
    entity: "nomeUsuario",
    value: "Nome",
  },
];

export const bancaOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
];

export const autorOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
];

export const cargoOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
];

export const orgaoOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
  {
    entity: "sigla",
    value: "Sigla",
  },
];

export const perfilQuestoesOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
];

export const disciplinaOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "nome",
    value: "Nome",
  },
];

export const temaOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
  {
    entity: "subject",
    value: "Disciplina",
  },
];

export const tagOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
];

export const tipoLeiOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
];

export const provaOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "year",
    value: "Data realização",
  },
  {
    entity: "creationDate",
    value: "Data criação",
  },
  {
    entity: "body",
    value: "Orgão",
  },
  {
    entity: "bank",
    value: "Banca",
  },
  {
    entity: "office",
    value: "Cargo",
  },
  {
    entity: "level",
    value: "Nível",
  },
];

export const projetoOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
  {
    entity: "year",
    value: "Ano do projeto",
  },
];

export const estiloExportacaoOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
];

export const estruturaExportacaoOrdenacao: Array<SortItem> = [
  {
    entity: "id",
    value: "ID",
  },
  {
    entity: "name",
    value: "Nome",
  },
];

export const sorting: Array<SortItem> = [
  {
    entity: "desc",
    value: "Decrescente",
  },
  {
    entity: "asc",
    value: "Crescente",
  },
];

export const sortOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "desc",
    value: "Decrescente",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "asc",
    value: "Crescente",
    useId: false,
    selected: false,
  },
];

export const selectNivel: Array<TItemSelect> = [
  {
    id: 1,
    key: "level",
    value: "Médio",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "level",
    value: "Superior",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "level",
    value: "Específico",
    useId: false,
    selected: false,
  },
];

export const selectMostrarProvaDisciplina: Array<TItemSelect> = [
  {
    id: 0,
    key: "exam_disc_active",
    value: "Selecione a visibilidade (disciplina prova)",
    useId: false,
    selected: true,
  },
  {
    id: 1,
    key: "exam_disc_active",
    value: "Mostrar (disciplina prova)",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "exam_disc_active",
    value: "Esconder (disciplina prova)",
    useId: false,
    selected: false,
  },
];

export const elegibilidadeClassificacaoQuestao: Array<TItemSelect> = [
  {
    id: 0,
    key: "eligibility_classification",
    value: "Selecione a elegibilidade de classificação externa (JusQuestões)",
    useId: false,
    selected: true,
  },
  {
    id: 1,
    key: "eligibility_classification",
    value: "Elegível a classificação",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "eligibility_classification",
    value: "Não elegível a classificação",
    useId: false,
    selected: false,
  },
];

export const naturezaOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "nature",
    value: "Jurídica",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "nature",
    value: "Sem natureza",
    useId: false,
    selected: false,
  },
];

export const visibilidadeProjeto: Array<TItemSelect> = [
  {
    id: 1,
    key: "visibility",
    value: "Interno",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "visibility",
    value: "Público",
    useId: false,
    selected: false,
  },
];

export const statusQuestaoOption: Array<TItemSelect> = [
  {
    id: 1,
    key: "status",
    value: "Regular",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "status",
    value: "Desatualizada",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "status",
    value: "Cancelada",
    useId: false,
    selected: false,
  },
];

export const fontesOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "source",
    value: "Norma",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "source",
    value: "Jurisprudência",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "source",
    value: "Doutrina",
    useId: false,
    selected: false,
  },
];

export const respostaEscolhaOption: Array<TItemSelect> = [
  {
    id: 1,
    key: "Certo",
    value: "Certo",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "Errado",
    value: "Errado",
    useId: false,
    selected: false,
  },
];

export enum TipoPremissa {
  VF,
  CR,
}

export const premissasOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "Certo",
    value: "Certo",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "Errado",
    value: "Errado",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "Verdadeiro",
    value: "Verdadeiro",
    useId: false,
    selected: false,
  },
  {
    id: 4,
    key: "Falso",
    value: "Falso",
    useId: false,
    selected: false,
  },
];

export const respostaCertoErradoOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "Certo",
    value: "Certo",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "Errado",
    value: "Errado",
    useId: false,
    selected: false,
  },
];

export const respostaVerdadeiroFalsoOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "Verdadeiro",
    value: "Verdadeiro",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "Falso",
    value: "Falso",
    useId: false,
    selected: false,
  },
];

export const colunasRelacionalOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "first_column",
    value: "Coluna 1",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "second_column",
    value: "Coluna 2",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "third_column",
    value: "Alternativas",
    useId: false,
    selected: false,
  },
];

export const tipoPadraoLeiOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "law-type",
    value: "Numeração",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "law-type",
    value: "Termos",
    useId: false,
    selected: false,
  },
];

export const dificuldadesOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "difficulty",
    value: "Fácil",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "difficulty",
    value: "Intermediário",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "difficulty",
    value: "Difícil",
    useId: false,
    selected: false,
  },
];

export const ordenacaoParams: Array<TItemSelect> = [
  {
    id: 1,
    key: "asc",
    value: "Crescente",
    useId: false,
    selected: false,
  },
  {
    id: 2,
    key: "desc",
    value: "Decrescente",
    useId: false,
    selected: false,
  },
];

export const qtdOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "qtdItens",
    value: "10",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "qtdItens",
    value: "50",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "qtdItens",
    value: "100",
    useId: false,
    selected: false,
  },
];

export const parametrosOrdenacaoQuestao: Array<TItemSelect> = [
  {
    id: "",
    key: "param",
    value: "Selecione parâmetro",
    useId: false,
    selected: true,
  },
  {
    id: "Orgão",
    key: "param",
    value: "Orgão",
    useId: false,
    selected: false,
  },
  {
    id: "Banca",
    key: "param",
    value: "Banca",
    useId: false,
    selected: false,
  },
  {
    id: "Cargo",
    key: "param",
    value: "Cargo",
    useId: false,
    selected: false,
  },
  {
    id: "Nível",
    key: "param",
    value: "Nível",
    useId: false,
    selected: false,
  },
  {
    id: "Ano",
    key: "param",
    value: "Ano",
    useId: false,
    selected: false,
  },
  {
    id: "Disciplina",
    key: "param",
    value: "Disciplina",
    useId: false,
    selected: false,
  },
  {
    id: "Tema",
    key: "param",
    value: "Tema",
    useId: false,
    selected: false,
  },
  {
    id: "Dificuldade",
    key: "param",
    value: "Dificuldade",
    useId: false,
    selected: false,
  },
];

export const paramsOptions: Array<TItemSelect> = [
  {
    id: "",
    key: "param",
    value: "Selecione parâmetro",
    useId: false,
    selected: true,
  },
  {
    id: "Orgao:id=nome",
    key: "param",
    value: "Orgão",
    useId: false,
    selected: false,
  },
  {
    id: "Banca:id=",
    key: "param",
    value: "Banca",
    useId: false,
    selected: false,
  },
  {
    id: "Cargo:id=",
    key: "param",
    value: "Cargo",
    useId: false,
    selected: false,
  },
  {
    id: "NivelFormacao:enum=",
    key: "param",
    value: "Nível",
    useId: false,
    selected: false,
  },
  {
    id: "AnoProva:literal=",
    key: "param",
    value: "Ano",
    useId: false,
    selected: false,
  },
  {
    id: "Disciplina:id=",
    key: "param",
    value: "Disciplina",
    useId: false,
    selected: false,
  },
  {
    id: "Tema:id=",
    key: "param",
    value: "Tema",
    useId: false,
    selected: false,
  },
  {
    id: "Dificuldade:enum=",
    key: "param",
    value: "Dificuldade",
    useId: false,
    selected: false,
  },
  {
    id: "FonteJuridica:enum=",
    key: "param",
    value: "Fonte de resposta",
    useId: false,
    selected: false,
  },
];

export const autoridadesOptions: Array<TItemSelect> = [
  {
    id: 1,
    key: "authority",
    value: "Editor",
    useId: false,
    selected: true,
  },
  {
    id: 2,
    key: "authority",
    value: "Líder Editorial",
    useId: false,
    selected: false,
  },
  {
    id: 3,
    key: "authority",
    value: "Admin",
    useId: false,
    selected: false,
  },
  {
    id: 4,
    key: "authority",
    value: "Classificador",
    useId: false,
    selected: false,
  },
];

export const admin: Array<CheckBoxItem> = [];

export const pesquisaQuestaoOptions: Array<QuestaoPesquisaOpcoes> = [
  {
    check: false,
    label: "Enunciado",
    valor: "E",
  },
  {
    check: false,
    label: "Textos de apoio",
    valor: "T",
  },
  {
    check: false,
    label: "Alternativas/Respostas",
    valor: "R",
  },
  {
    check: false,
    label: "Comentários",
    valor: "C",
  },
];

export const pesquisaQuestaoProjetoOptions: Array<QuestaoPesquisaOpcoes> = [
  {
    check: false,
    label: "Enunciado",
    valor: "E",
  },
  {
    check: false,
    label: "Textos de apoio",
    valor: "T",
  },
  {
    check: false,
    label: "Alternativas/Respostas",
    valor: "R",
  },
  {
    check: false,
    label: "Comentários do projeto",
    valor: "P",
  },
];

export const dispositivosItem: DispositivoItem = {
  nome: "Item",
  padrao: "XXXX - números",
  subDispositivos: [],
};

export const dispositivosAlinea: DispositivoItem = {
  nome: "Alínea",
  padrao: "x - letra minúscula",
  subDispositivos: [dispositivosItem],
};

export const dispositivosInciso: DispositivoItem = {
  nome: "Inciso",
  padrao: "MDCLXVI - letras romanas",
  subDispositivos: [dispositivosAlinea],
};

export const dispositivosParagrafo: DispositivoItem = {
  nome: "Parágrafo",
  padrao: "XXXX números ou pu(parágrafo único)",
  subDispositivos: [dispositivosInciso, dispositivosAlinea, dispositivosItem],
};

export const dispositivosArtigo: DispositivoItem = {
  nome: "Artigo",
  subDispositivos: [
    dispositivosInciso,
    dispositivosAlinea,
    dispositivosItem,
    dispositivosParagrafo,
  ],
};
