import { FormEvent, Fragment, useEffect, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { VscSave } from 'react-icons/vsc';
import { SelecaoImagem } from '../../../../components/app/selecao-imagem';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { ButtonNormal } from '../../../../components/button/normal';
import { InputForm } from '../../../../components/input';
import { SidebarModal } from '../../../../components/modals/sidebar-modal';
import { SidebarForm } from '../../../../components/modals/sidebar-modal/styles';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { APIFetch, APIFileFetch } from '../../../../services/api';
import { OrgaoService, useOrgaoService } from '../../../../services/api-services/orgao';
import { useOrgaoFileService } from '../../../../services/api-services/orgaoFile';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { ImageInfoDetails } from '../../../../services/service';
import { FormCriarOrgao } from './styles';

export interface TCriarOrgao {
  externalAction?: [ActionType, React.Dispatch<React.SetStateAction<ActionType>>];
  isModal: boolean;
}

export const CriarOrgao: React.FC<TCriarOrgao> = ({ isModal, externalAction }) => {
  const auth = useAuthContext();

  const services = {
    orgaoService: useOrgaoService(APIFetch(auth)),
    orgaoFileService: useOrgaoFileService(APIFileFetch(auth))
  };

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [action, setAction] = actionState;

  const [nome, setNome] = useState('');
  const [sigla, setSigla] = useState('');

  const [imagemProjeto, setImagemProjeto] = useState<ImageInfoDetails | undefined>(undefined);

  useEffect(() => {
    action.action === 'editar-orgao' &&
      services.orgaoService.buscarOrgao(action.id).then((orgao) => {
        setNome(orgao.name);
        setSigla(orgao.abbreviation === null ? "" : orgao.abbreviation);
        setImagemProjeto(orgao.image === null ? undefined : orgao.image);
      });
  }, [action]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (action.action === 'editar-orgao') {
      editarOrgaoAPI(nome, sigla, services.orgaoService, action, setAction, changeAction);
    } else if (action.action === 'criar-orgao') {
      salvarOrgaoAPI(nome, sigla, services.orgaoService, action, setAction, changeAction);
    }
  };

  const editarOrgaoAPI = (
    nome: string,
    sigla: string,
    orgaoService: OrgaoService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    orgaoService
      .editarOrgao({
        id: action.id,
        name: nome,
        abbreviation: sigla
      })
      .then((response) => {

        setActionState({
          ...action,
          loading: true,
        });
        alert('Orgão salvo com sucesso!');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const salvarOrgaoAPI = (
    nome: string,
    sigla: string,
    orgaoService: OrgaoService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    orgaoService
      .salvarOrgao({
        name: nome,
        abbreviation: sigla,
      })
      .then((response) => {


        setActionState({
          ...action,
          loading: true,
        });

        alert('Orgão salvo com sucesso!');

      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const setActionState = (dispatchAction: ActionType) => {

    if (!setAction) {
      changeAction(dispatchAction);
    } else {
      setAction(dispatchAction);
    }

  }

  const uploadImagemProjeto = (formData: FormData, id: string) => {
    return services.orgaoFileService.salvarImagemOrgao(formData, Number(id));
  }

  const deletarImagem = (idImagem: string, id: string) => {
    return services.orgaoFileService.deletarImagemOrgao(idImagem, Number(id));
  }

  const onPosUploadImage = (value?: ImageInfoDetails) => {

    setImagemProjeto(value);

    setActionState({
      ...action,
      loading: true,
    });

  }

  const renderContent = (isModal: boolean) => {
    return (
      <Fragment>
        <label>Nome</label>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        <label>Sigla</label>
        <InputForm
          className="nome form-group"
          placeholder="Informe a sigla"
          value={sigla}
          onChange={(value) => {
            setSigla(value.currentTarget.value);
          }}
        />
        {action.action === 'editar-orgao' &&
          <SelecaoImagem
            referenciaImagem={"orgao"}
            onUploadImage={uploadImagemProjeto}
            onDeleteImage={deletarImagem}
            idEntidade={String(action.id)}
            imagemProjeto={imagemProjeto}
            onPosUpload={onPosUploadImage} />
        }
        {isModal ? (
          <ButtonNormal icon={VscSave} title={'Salvar'} className={'save'} type={'submit'} />
        ) : (
          <ButtonBottomLess icon={IoMdCheckmark} title={'Salvar orgão'} className={'save'} type={'submit'} />
        )}
      </Fragment>
    );
  };

  const renderForm = (isModal: boolean) => {
    return (
      <Fragment>
        {isModal ? (
          <SidebarForm onSubmit={onSubmit}>{renderContent(isModal)}</SidebarForm>
        ) : (
          <FormCriarOrgao onSubmit={onSubmit}>{renderContent(isModal)}</FormCriarOrgao>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isModal ? (
        <SidebarModal
          action={actionState}
          title={action.action === 'criar-orgao' ? 'Novo orgão' : `Editar orgão #${action.id}`}>
          {renderForm(isModal)}
        </SidebarModal>
      ) : (
        <Fragment> {renderForm(isModal)} </Fragment>
      )}
    </Fragment>
  );
};
