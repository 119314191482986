import { Tag } from "../../../../../components/tag";
import {
    QuestaoItem,
    QuestaoResumida,
    isQuestaoItem
} from "../../../../../services/api-services/questao";
import { getProvaInfoHeader } from "../../visualizar-questao/enunciado";
import { BodyStyle } from "../styles";

export interface QuestaoCardBodyProps {
    questao: QuestaoItem | QuestaoResumida
}

export const QuestaoCardBody = ({ questao }: QuestaoCardBodyProps) => {

    const headerProva = () => {
        if (isQuestaoItem(questao)) {
            const questaoItem: QuestaoItem = questao as QuestaoItem;
            return questaoItem.exam_header;
        } else {
            const questaoResumida = questao as QuestaoResumida;
            const { exam_board, year, body, office } = questaoResumida;
            return getProvaInfoHeader(exam_board, office, "", body, year);
        }
    };

    return (
        <BodyStyle className={"item-margin"}>
            <span className={"exam-header"}>{headerProva()}</span>
            <div className="statement_question">
                <div
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                        __html: questao.statement_question,
                    }} />
            </div>
            <div className="tags">
                {questao.exam_discipline && <Tag nome={questao.exam_discipline} title="Disciplina (PROVA)" />}
                {questao.subject && <Tag nome={questao.subject} title="Disciplina" />}
                {questao.topic && <Tag nome={questao.topic} title="Tema" />}
                {questao.tag && <Tag nome={questao.tag} title="TAG" />}
                {questao.sources && <Tag nome={questao.sources.join(";")} title={"Fonte"} />}
                {questao.type && (
                    <Tag nome={questao.type} title={"Tipo de questão"} />
                )}
                {questao.difficulty && (
                    <Tag nome={questao.difficulty} title={"Dificuldade"} />
                )}
                {questao.career && (
                    <Tag nome={questao.career} title={"Carreira"} />
                )}
            </div>
        </BodyStyle>
    );
};
