import { FormEvent, useEffect, useState } from 'react';
import { VscSave } from 'react-icons/vsc';
import { ButtonNormal } from '../../../../components/button/normal';
import { InputForm } from '../../../../components/input';
import { SidebarModal } from '../../../../components/modals/sidebar-modal';
import { SidebarForm } from '../../../../components/modals/sidebar-modal/styles';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { APIFetch } from '../../../../services/api';
import { useDisciplinaService } from '../../../../services/api-services/disciplina';
import { PerfilQuestoes, usePerfilQuestoesService } from '../../../../services/api-services/perfilQuestoes';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';

export const CriarPerfilQuestao: React.FC<{}> = (props) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    perfilQuestoesService: usePerfilQuestoesService(APIFetch(auth)),
    disciplinaService: useDisciplinaService(APIFetch(auth)),
  };

  const [perfilQuestao, setPerfilQuestao] = useState<PerfilQuestoes>({
    id: 0,
    name: '',
  });

  const [nome, setNome] = useState('');

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (context.currentAction.action == 'editar') {
      services.perfilQuestoesService
        .editarPerfil({
          id: context.currentAction.id,
          name: nome,
        })
        .then((response) => {
          context.changeAction({
            ...context.currentAction,
            loading: true,
          });
          alert('Perfil salvo com sucesso!');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else if (context.currentAction.action == 'criar') {
      services.perfilQuestoesService
        .salvarPerfil({
          name: nome,
        })
        .then((response) => {
          context.changeAction({
            ...context.currentAction,
            loading: true,
          });
          alert('Perfil de questão salvo com sucesso!');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    context.currentAction.action == 'editar' &&
      services.perfilQuestoesService.buscarPerfil(context.currentAction.id).then((perfil) => {
        setPerfilQuestao(perfil);
        setNome(perfil.name);
      });
  }, []);

  return (
    <SidebarModal
      action={context.currentActionState}
      title={context.currentAction.action == 'criar' ? 'Novo perfil de questão' : `Editar perfil de questão #${context.currentAction.id}`}>
      <SidebarForm onSubmit={onSubmit}>
        <small>Nome</small>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        <ButtonNormal icon={VscSave} title={'Salvar'} className={'save'} type={'submit'} />
      </SidebarForm>
    </SidebarModal>
  );
};
