import styled from 'styled-components';

export const BottomScrollBar = styled.nav`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: 64px;
  position: fixed;
  top: 95%;
  left: 0;
  right: 0;
  z-index: 199;
`;

