import styled from 'styled-components';

export const MudarSenhaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .field-size {
    width: 260px;
  }

  .form-senha {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .form-autorizacao {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .field-group {
    margin-right: 24px;
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    justify-items: center;

    label {
      color: var(--color-text-secondary);
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.9rem;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .error {
      color: #db2269;
      font-size: 0.625em;
      position: relative;
    }

    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

export const FooterTrocarSenha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;
