import styled from "styled-components";

export interface ElementContentSettings {
  isDragging: boolean;
  isRoot: boolean;
}

export const SubElementoContent = styled.div`
  display: flex;
  flex-direction: column;
  ${(settings: ElementContentSettings) =>
    settings.isRoot ? `` : `padding-left: 3rem;`}
  opacity: ${(settings: ElementContentSettings) =>
    settings.isDragging ? 0 : 1};
  flex: 1 1 100%;

  .content {
    padding: 0.5rem;
    border-bottom: 2px solid var(--color-primary-dark);
    background-color: var(--color-primary-clear);
    border-radius: 8px;
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.9rem;
  }
`;
