import styled from "styled-components";

export const ProjetoForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 28px;
  width: 100%;

  @media (max-width: 700px) {
    margin: 12px 4px 12px 4px;
  }

  h4 {
    font-weight: 500;
    color: var(--color-text-dark);
    padding: 12px 0px 12px 0px;
    font-weight: bold;
  }

  .informacoes-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .sobre-projeto-content {
      margin-right: 28px;
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  .autores-content {
    width: 100%;
  }

  .editor-enunciado {
    width: 100%;
    height: 380px;
  }
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;

      label {
        color: var(--color-text-secondary);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;

export const PadraoExportacaoButtonOptions = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Filtros = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ProjetoFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;

  .save-projeto {
    width: 140px;
  }
`;
