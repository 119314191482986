import React, { Fragment, useEffect, useState } from "react";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { ThreeNormalOption } from "../../components/menu/three-normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { AsyncSelect } from "../../components/select/async";
import { comentarioOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAutorService } from "../../services/api-services/autores";
import {
  ComentarioItem,
  useComentarioService
} from "../../services/api-services/comentarios";
import { usePerfilQuestoesService } from "../../services/api-services/perfilQuestoes";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ClassificarComentarios } from "./forms/classificar-comentarios";
import { ExcluirComentario } from "./forms/excluir-comentario";
import { ValidacaoComentario } from "./forms/validacao";
import { ComentarioCard } from "./views/card-comentario";

export const GerenciarComentariosComponent = () => {
  //
  const context = useGerenciarContext();

  const auth = useAuthContext();

  const comentarioService = useComentarioService(APIFetch(auth));

  const autorService = useAutorService(APIFetch(auth));

  const perfilQuestoesService = usePerfilQuestoesService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("card");

  const [status, setStatus] = useState("");

  const [period, setPeriod] = useState("");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const comentarioState = useState<Array<ItemDataSelect<ComentarioItem>>>([]);
  const setComentarios = comentarioState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<ComentarioItem>({
    state: comentarioState,
  });

  const keys: string[] = ["authorName", "profileName", "project"];

  useEffect(() => {
    if (context.loading) {
      comentarioService
        .listarComentariosPorPagina(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          status,
          period,
          useTagActions.getFilters(keys),
          search,
          sort,
          order
        )
        .then((comentarios) => {
          setComentarios(
            comentarios.content.map((value: ComentarioItem) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(comentarios.paginationData);
          context.toPage(comentarios.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Comentário" isOpen={true}>
          <GroupMain>
            <AsyncSelect
              className="perfil field-group"
              label="Filtrar por perfil de questão"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                perfilQuestoesService.listarPerfils(page, 30, value)
              }
            />
            <AsyncSelect
              className="autor field-group"
              label="Filtrar por autor"
              onAddFilterItem={(item) => useTagActions.addTag(item)}
              getDataAPI={(page, value) =>
                autorService.listarAutores(page, 30, value)
              }
            />
          </GroupMain>
        </Group>
      </Fragment>
    );
  };

  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(true);
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirComentario />;
      }

      case "validar": {
        return <ValidacaoComentario />;
      }

      case "add-classificacao": {
        return (
          <ClassificarComentarios
            ids={selectedEntitiesAction.getSelectItems()}
          />
        );
      }

      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<ComentarioItem>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={comentarioState}
        filters={filters()}
        filterBar={{
          left: (
            <GroupButtons
              initialPositionSelected={2}
              buttons={[
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setStatus),
                  name: "Publicado",
                  classname: "status-option",
                  value: "Publicado",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setStatus),
                  name: "Pendente",
                  classname: "status-option",
                  value: "Pendente",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setStatus),
                  name: "ambos",
                  classname: "status-option",
                  value: "",
                },
              ]}
            />
          ),
          right: (
            <GroupButtons
              initialPositionSelected={3}
              buttons={[
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "hoje",
                  classname: "period-option",
                  value: "day",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "semana",
                  classname: "period-option",
                  value: "week",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "mês",
                  classname: "period-option",
                  value: "month",
                },
                {
                  onClick: (current) =>
                    loadingByButtonFilter(current, setPeriod),
                  name: "todos",
                  classname: "period-option",
                  value: "",
                },
              ]}
            />
          ),
        }}
        page={{
          name: "Gerenciar comentários",
          header: {
            left: null,
            right: null,
          },
          searchPlaceHolder: "Pesquisar por perfil de questão,autor ou enunciado",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: comentarioOrdenacao,
          },
          options: [
            <ThreeNormalOption
              name="Classificar comentários"
              onClick={() => {
                if (selectedEntitiesAction.getSelectItems().length == 0) {
                  alert("Você precisa selecionar ao menos um comentário..");
                } else {
                  context.changeAction({
                    action: "add-classificacao",
                    id: 0,
                  });
                }
              }}
            />,
          ],
          mode: "card",
          card: {
            dataColumn: ComentarioCard(selectedEntitiesAction, mode)
              .comentarioCard,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarComentarios = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarComentariosComponent />
    </GerenciarBaseProvider>
  );
};
