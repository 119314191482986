import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { Tag } from "../../../../services/api-services/tag";

export const TagHeader = (selectedEntitiesAction: SelectedDataActions<Tag>) => {
  const TagRow = (elem: ItemDataSelect<Tag>, index: number) => {
    const tag = elem.entity as Tag;
    const context = useGerenciarContext();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{tag.id}</RowItem>
        <RowItem>{tag.name}</RowItem>
        <RowItem>{tag.subject.name}</RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: "editar",
                    id: tag.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: tag.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    tagHeader: TagRow,
  };
};
