import { AxiosInstance } from "axios";
import { Service } from "../../service";

export class QuestaoFileService extends Service {
  private ENDPOINT = "question";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async salvarImagemQuestao(
    dados: FormData,
    idQuestao: number
  ): Promise<ImagemUploaded> {
    return this.fetch
      .post(this.ENDPOINT + `/${idQuestao}/images/upload`, dados)
      .then((response) => {
        return response.data;
      });
  }

  async deletarImagemQuestao(
    idImagem: String,
    idQuestao: number
  ): Promise<void> {
    return this.fetch.delete(
      this.ENDPOINT + `/${idQuestao}/images/${idImagem}`
    );
  }
}

export interface ImagemUploaded {
  id_image: string;
}

export const useQuestaoFileService = (axios: AxiosInstance) => {
  return new QuestaoFileService(axios);
};
