import { createContext, useContext } from "react";
import { TDragContext } from "../../../../components/drag-and-drop";


const context = createContext<TDragContext<any>>({
    layout: [],
    setLayout: (layout) => { },
    handleDrop: (drop, item) => { },
    setHandleDropFeatureData: (handle) => { }
})

export const useEstruturaQuestaoContext = () => useContext(context);

export const EstruturaQuestaoCXProvider = context.Provider;