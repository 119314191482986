import { FormEvent, useEffect, useState } from "react";
import { VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { InputForm } from "../../../../components/input";
import { SidebarModal } from "../../../../components/modals/sidebar-modal";
import { SidebarForm } from "../../../../components/modals/sidebar-modal/styles";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { APIFetch } from "../../../../services/api";
import {
  Disciplina,
  useDisciplinaService,
} from "../../../../services/api-services/disciplina";
import { useTagService } from "../../../../services/api-services/tag";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";

export interface TCriarTag {
  disciplinaVinculada?: Disciplina;
}

export const CriarTag: React.FC<TCriarTag> = ({ disciplinaVinculada }) => {
  const auth = useAuthContext();
  const context = useGerenciarContext();

  const services = {
    tagService: useTagService(APIFetch(auth)),
    disciplinaService: useDisciplinaService(APIFetch(auth)),
  };

  const [nome, setNome] = useState("");

  const [disciplina, setDisciplina] = useState({
    id: 0,
    key: "subject",
    useId: true,
    value: "",
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (context.currentAction.action == "editar") {
      services.tagService
        .editarTag({
          id: context.currentAction.id,
          name: nome,
          selected_subject_id: disciplina.id,
        })
        .then((response) => {
          context.changeAction({
            ...context.currentAction,
            loading: true,
          });
          alert("Tag salvo com sucesso!");
        })
        .catch((error) => {
          alert("Ocorreu um erro tag não foi salvo!");
        });
    } else if (context.currentAction.action == "criar") {
      services.tagService
        .salvarTag({
          name: nome,
          selected_subject_id: disciplina.id,
        })
        .then((response) => {
          context.changeAction({
            ...context.currentAction,
            loading: true,
          });
          alert("Tag salvo com sucesso!");
        })
        .catch((error) => {
          alert("Ocorreu um erro tag não foi salvo!");
        });
    }
  };

  useEffect(() => {
    //
    context.currentAction.action == "editar" &&
      services.tagService.buscarTag(context.currentAction.id).then((tag) => {
        setNome(tag.name);
      });

    if (disciplinaVinculada) {
      setDisciplina({
        id: disciplinaVinculada.id,
        key: "subject",
        useId: true,
        value: disciplinaVinculada.name,
      });
    }
  }, []);

  return (
    <SidebarModal
      action={context.currentActionState}
      title={
        context.currentAction.action == "criar"
          ? "Nova tag"
          : `Editar tag #${context.currentAction.id}`
      }
    >
      <SidebarForm onSubmit={onSubmit}>
        <small>Nome</small>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />

        <ButtonNormal
          icon={VscSave}
          title={"Salvar"}
          className={"save"}
          type={"submit"}
        />
      </SidebarForm>
    </SidebarModal>
  );
};
