import { AsyncSelect, ButtonBottomLess, ContainerContent, FilterTag, InputForm, ItemSelectEntity, TitleIcon } from 'jusadmin-ui-kit';
import { useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { IoMdAdd } from 'react-icons/io';
import { IoAdd } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';
import { ThreeDotsList } from '../../../../components/menu/three-dots';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { APIFetch } from '../../../../services/api';
import { Questao } from '../../../../services/api-services/questao';
import { TipoLei, useTipoLeiService } from '../../../../services/api-services/tipoLeis';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { TReferencialegal, useQuestaoContext } from '../../../CriarQuestao/context';
import { mapReferenciasLegaisByQuestaoDetails } from '../../../CriarQuestao/provider';
import { referenciaToArray, useDispositivosLegaisHook } from './hooks/useDispositivosLegaisHook';
import { ReferenciaLegalItem } from "./referencia-item";
import { ListaReferenciasLegaisStyles, ReferenciaLegalForm, Referencias, ReferenciasOpcoes } from './styles';

export interface ListaReferenciasLegaisProps {
  className?: string;
  agruparAcima: boolean;
  modoAtualizacao: boolean;
  questao?: Questao;
  referencias_legais?: TReferencialegal[];
  externalActionState?: [ActionType, React.Dispatch<React.SetStateAction<ActionType>>];
}
export const ListaReferenciasLegais = ({ questao, referencias_legais, externalActionState, modoAtualizacao, agruparAcima, className }: ListaReferenciasLegaisProps) => {

  const referencias = useMemo(() => {

    if (referencias_legais) {

      return referencias_legais;

    } else if (questao) {

      return mapReferenciasLegaisByQuestaoDetails(questao.legal_references);

    }

  }, [questao, referencias_legais]);

  const auth = useAuthContext();

  const { artigo, dispositivos, dispositivosDisponiveis, lei, tipoLei, callbacks, mascaras } = useDispositivosLegaisHook();

  const { deletarReferenciaLegal, addReferenciaLegal, editarReferenciaLegal } = useQuestaoContext();

  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentAction, setCurrentAction] = externalActionState ? externalActionState : currentActionState;

  const [showNovaReferencia, setShowNovaReferencia] = useState(false);

  const [editarReferencia, setEditarReferencia] = useState(false);

  const [referenciaLegal, setReferenciaLegal] = useState<TReferencialegal>();

  const [sequenciaReferencia, setSequenciaReferencia] = useState<number>(0);

  const services = {
    tipoLeiService: useTipoLeiService(APIFetch(auth)),
  };

  const listarTiposLeisAPI = (page: number, value: string) => {
    return services.tipoLeiService.listarTipoLeis(page, 30, value);
  };

  const onClickEditarReferencia = (referencia: TReferencialegal, index: number) => {

    const grupos = referenciaToArray(referencia, true);

    callbacks.carregarDispositivos(grupos);
    callbacks.setLei(referencia.lei);
    callbacks.setArtigo(referencia.artigo);
    callbacks.setTipoLei(referencia.tipo_lei);

    setEditarReferencia(true);
    setShowNovaReferencia(true);
    setSequenciaReferencia(index);
    setReferenciaLegal(referencia);

  }

  const onClickDeletarReferencia = (referencia: TReferencialegal, index: number) => {
    deletarReferenciaLegal(referencia);
  }

  const cancelarEditarOuCriar = () => {
    setShowNovaReferencia(false);
    setEditarReferencia(false);
    setReferenciaLegal(undefined);
    setSequenciaReferencia(0);
    callbacks.setTipoLei({
      id: -1,
      key: "",
      useId: false,
      value: ""
    })
    callbacks.setDispositivos([]);
    callbacks.setLei({
      erro: "",
      value: ""
    });
    callbacks.setArtigo({
      erro: "",
      value: ""
    });
    callbacks.setDispositivosDisponiveis([]);
  }


  const onClickCriarOuSalvar = () => {

    const { mensagemDeErro, validado } = callbacks.validarReferenciaLegal();

    if (validado) {

      const alinea = callbacks.buscarDispositivoByNome("Alínea");
      const paragrafo = callbacks.buscarDispositivoByNome("Parágrafo");
      const inciso = callbacks.buscarDispositivoByNome("Inciso");
      const item = callbacks.buscarDispositivoByNome("Item");

      let referenciaLegalSalvar: TReferencialegal = {
        lei: lei,
        tipo_lei: tipoLei,
        artigo: artigo,
        paragrafo: paragrafo ? {
          value: paragrafo.value ? paragrafo.value : "",
          erro: ""
        } : {
          value: "",
          erro: ""
        },
        alinea: alinea ? {
          value: alinea.value ? alinea.value : "",
          erro: ""
        } : {
          value: "",
          erro: ""
        },
        inciso: inciso ? {
          value: inciso.value ? inciso.value : "",
          erro: ""
        } : {
          value: "",
          erro: ""
        },
        item: item ? {
          value: item.value ? item.value : "",
          erro: ""
        } : {
          value: "",
          erro: ""
        }
      }

      if (editarReferencia && referenciaLegal) {

        referenciaLegalSalvar.id = referenciaLegal.id;
        editarReferenciaLegal(referenciaLegalSalvar, referenciaLegal);
        setEditarReferencia(false);

      } else {

        referenciaLegalSalvar.id = undefined;
        addReferenciaLegal(referenciaLegalSalvar);

      }

      setShowNovaReferencia(false);

    } else {
      alert(mensagemDeErro);
    }


  }

  const onClickNovaReferencia = () => {

    setShowNovaReferencia(true);
    setEditarReferencia(false);
    setReferenciaLegal(undefined);
    setSequenciaReferencia(0);

    callbacks.setTipoLei({
      id: -1,
      key: "",
      useId: false,
      value: ""
    })
    callbacks.setDispositivos([]);
    callbacks.setLei({
      erro: "",
      value: ""
    });
    callbacks.setArtigo({
      erro: "",
      value: ""
    });
    callbacks.setDispositivosDisponiveis([]);
  }

  const renderOpcoesReferencia = () => {
    return (modoAtualizacao &&
      <ReferenciasOpcoes>
        {showNovaReferencia ? <ButtonBottomLess
          title="Salvar referência"
          className="button-margin"
          icon={FiCheck}
          onClick={() => onClickCriarOuSalvar()}
        /> : <ButtonBottomLess
          title="Nova referência"
          className="button-margin"
          icon={IoAdd}
          onClick={() => onClickNovaReferencia()}
        />}
        {(editarReferencia || showNovaReferencia) && <ButtonBottomLess
          title="Cancelar"
          className="button-margin"
          icon={MdClear}
          onClick={() => cancelarEditarOuCriar()}
        />}
        <ButtonBottomLess
          title="Novo Tipo de Lei"
          className="button-margin"
          icon={IoAdd}
          onClick={() => {
            setCurrentAction({ id: 0, action: "criar-tipo-lei" });
          }}
        />
      </ReferenciasOpcoes>
    );
  };

  return (
    <ListaReferenciasLegaisStyles className={className} agruparAcima={agruparAcima}>
      <ContainerContent title="Referências" options={renderOpcoesReferencia()} className="referencias-legais-container">
        {showNovaReferencia && modoAtualizacao && <ReferenciaLegalForm>
          <TitleIcon
            name={editarReferencia ? `Editar referência #${sequenciaReferencia}` : "Nova referência"}
            icon={IoMdAdd}
          />
          <div className="form-group">
            <div className="field-group">
              <label>Tipo de Lei</label>
              <AsyncSelect
                className="tipo-lei field-group"
                showSelectItem={true}
                label="Tipo de Lei"
                selectItem={tipoLei}
                onAddFilterItem={(item) =>
                  callbacks.setTipoLeiData(item as ItemSelectEntity<TipoLei>)
                }
                getDataAPI={listarTiposLeisAPI}
              />
            </div>
            <div className="field-group">
              <label>
                Lei - Padrão (
                <b>
                  {tipoLei.entity && tipoLei.entity.terms
                    ? tipoLei.entity.terms
                    : "XX.XXX/XXXX"}
                </b>
                )
              </label>
              <InputForm
                spellCheck={false}
                className="lei"
                name="lei"
                value={lei.value}
                onKeyUp={(e) => mascaras.maskLei(e)}
                onChange={(value) => {
                  callbacks.setLeiData(value.currentTarget.value);
                }}
                placeholder="Informe a lei de acordo com padrão"
              />
              {lei.erro.length > 0 && (
                <span className="error">{lei.erro}</span>
              )}
            </div>
            <div className="field-group">
              <label>
                Artigo XXXX ou XXXX-X Ex:<b>0024 ou 0024-F</b>
              </label>
              <InputForm
                name="artigo"
                onKeyUp={(e) => mascaras.maskArtigo(e)}
                className="artigo"
                value={artigo.value}
                onChange={(value) => {
                  callbacks.setArtigoData(value.currentTarget.value);
                }}
                placeholder="XXXX"
              />
              {artigo.erro.length > 0 && (
                <span className="error">{artigo.erro}</span>
              )}
            </div>
            {
              dispositivos.map((dispositivo, index) => {
                return <div className="field-group">
                  <label>
                    {`${dispositivo.name} - ${dispositivo.padrao}`}
                  </label>
                  <InputForm
                    name={dispositivo.name}
                    key={dispositivo.name}
                    onKeyUp={(e) => mascaras.maskDispositivo(e, dispositivo, index)}
                    className="dispositivo-field"
                    value={dispositivo.value}
                    onChange={(value) => {
                      callbacks.setDispositivoData(value.currentTarget.value, dispositivo, index);
                    }}
                    placeholder=""
                  />
                  {dispositivo.erro && dispositivo.erro.length > 0 && (
                    <span className="error">{dispositivo.erro}</span>
                  )}
                </div>
              })
            }
            {dispositivos.length >= 1 && <FilterTag nome={""} className={"remover-dispositivo"} onMouseClick={callbacks.onRemoverUltimoDispositivo} />}
            {dispositivosDisponiveis.length >= 1 && <div className="field-group">
              <ThreeDotsList
                size={28}
                icon={IoMdAdd}
                className="dispositivos-options"
                isLastPosition={false}
                text={"Dispositivo"}
                options={dispositivosDisponiveis}
              />
            </div>}
          </div>
        </ReferenciaLegalForm>}
        {referencias && (
          <Referencias>
            {referencias.map((referencia, index) => {
              return (
                <ReferenciaLegalItem
                  index={index + 1}
                  key={index}
                  referencia={referencia}
                  showOptions={modoAtualizacao}
                  onClickDeletar={onClickDeletarReferencia}
                  onClickEditar={onClickEditarReferencia}
                />
              );
            })}
          </Referencias>
        )}
      </ContainerContent>
    </ListaReferenciasLegaisStyles>
  );
};
