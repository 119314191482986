import React, { HTMLAttributes } from "react";
import { IconBaseProps } from "react-icons";
import { ButtonGroupContent } from "./styles";

export interface TButtonGroup extends HTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType<IconBaseProps>;
  title?: string;
  className: string;
  active: boolean;
  text: string;
}

export const OptionGroup: React.FC<TButtonGroup> = ({
  icon: Icon,
  className,
  title,
  active,
  text,
  ...rest
}) => {
  return (
    <ButtonGroupContent active={active} className={className} {...rest}>
      <div className={"title"}>
        {Icon && <Icon size={18} className={"icon"} />}
        {title && <small>{title}</small>}
      </div>
      <div className={"text"}>
        <p>{text}</p>
      </div>
    </ButtonGroupContent>
  );
};
