import { FormEvent, Fragment, useEffect, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { VscSave } from "react-icons/vsc";
import { SelecaoImagem } from "../../../../components/app/selecao-imagem";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { ButtonNormal } from "../../../../components/button/normal";
import { InputForm } from "../../../../components/input";
import { SidebarModal } from "../../../../components/modals/sidebar-modal";
import { SidebarForm } from "../../../../components/modals/sidebar-modal/styles";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch, APIFileFetch } from "../../../../services/api";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ImageInfoDetails } from "../../../../services/service";
import {
  CarreiraService,
  useCarreiraService,
} from "../../../../services/api-services/carreira";
import { useCarreiraFileService } from "../../../../services/api-services/carreira-file";
import { FormCriarCarreira } from "./styles";

export interface TCriarCarreira {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
  isModal: boolean;
}

export const CriarCarreira: React.FC<TCriarCarreira> = ({
  isModal,
  externalAction,
}) => {
  const auth = useAuthContext();

  const services = {
    carreiraService: useCarreiraService(APIFetch(auth)),
    carreiraFileService: useCarreiraFileService(APIFileFetch(auth)),
  };

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [action, setAction] = actionState;

  const [nome, setNome] = useState("");

  const [imagemProjeto, setImagemProjeto] = useState<
    ImageInfoDetails | undefined
  >(undefined);

  useEffect(() => {
    action.action === "editar-carreira" &&
      services.carreiraService.buscarCarreira(action.id).then((carreira) => {
        setNome(carreira.name);
        setImagemProjeto(carreira.image === null ? undefined : carreira.image);
      });
  }, [action]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (action.action === "editar-carreira") {
      editarCarreiraAPI(
        nome,
        services.carreiraService,
        action,
        setAction,
        changeAction
      );
    } else if (action.action === "criar-carreira") {
      salvarCarreiraAPI(
        nome,
        services.carreiraService,
        action,
        setAction,
        changeAction
      );
    }
  };

  const editarCarreiraAPI = (
    nome: string,
    carreiraService: CarreiraService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    carreiraService
      .editarCarreira({
        id: action.id,
        name: nome,
      })
      .then((response) => {
        setActionState({
          ...action,
          loading: true,
        });
        alert("Carreira salva com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const salvarCarreiraAPI = (
    nome: string,
    carreiraService: CarreiraService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    carreiraService
      .salvarCarreira({
        name: nome,
      })
      .then((response) => {
        setActionState({
          ...action,
          loading: true,
        });

        alert("Carreira salva com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const setActionState = (dispatchAction: ActionType) => {
    if (!setAction) {
      changeAction(dispatchAction);
    } else {
      setAction(dispatchAction);
    }
  };

  const uploadImagemProjeto = (formData: FormData, id: string) => {
    return services.carreiraFileService.salvarImagemCarreira(
      formData,
      Number(id)
    );
  };

  const deletarImagem = (idImagem: string, id: string) => {
    return services.carreiraFileService.deletarImagemCarreira(
      idImagem,
      Number(id)
    );
  };

  const onPosUploadImage = (value?: ImageInfoDetails) => {
    setImagemProjeto(value);

    setActionState({
      ...action,
      loading: true,
    });
  };

  const renderContent = (isModal: boolean) => {
    return (
      <Fragment>
        <label>Nome</label>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        {action.action === "editar-carreira" && (
          <SelecaoImagem
            referenciaImagem={"carreira"}
            onUploadImage={uploadImagemProjeto}
            onDeleteImage={deletarImagem}
            idEntidade={String(action.id)}
            imagemProjeto={imagemProjeto}
            onPosUpload={onPosUploadImage}
          />
        )}
        {isModal ? (
          <ButtonNormal
            icon={VscSave}
            title={"Salvar"}
            className={"save"}
            type={"submit"}
          />
        ) : (
          <ButtonBottomLess
            icon={IoMdCheckmark}
            title={"Salvar carreira"}
            className={"save"}
            type={"submit"}
          />
        )}
      </Fragment>
    );
  };

  const renderForm = (isModal: boolean) => {
    return (
      <Fragment>
        {isModal ? (
          <SidebarForm onSubmit={onSubmit}>
            {renderContent(isModal)}
          </SidebarForm>
        ) : (
          <FormCriarCarreira onSubmit={onSubmit}>
            {renderContent(isModal)}
          </FormCriarCarreira>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isModal ? (
        <SidebarModal
          action={actionState}
          title={
            action.action === "criar-carreira"
              ? "Nova carreira"
              : `Editar carreira #${action.id}`
          }
        >
          {renderForm(isModal)}
        </SidebarModal>
      ) : (
        <Fragment> {renderForm(isModal)} </Fragment>
      )}
    </Fragment>
  );
};
