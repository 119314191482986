import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { tipoLeiOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  TipoLei,
  useTipoLeiService
} from "../../services/api-services/tipoLeis";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarTipoLei } from "./forms/criar-tipo-lei";
import { ExcluirTipoLei } from "./forms/excluir-tipo-lei";
import { TipoLeiHeader } from "./views/row-tipo-lei";

export const GerenciarTipoLeiComponent = () => {
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const services = {
    tipoLeiService: useTipoLeiService(APIFetch(auth)),
  };

  ///////////////////////////////////////////////////

  const [sort, setSort] = useState("name");

  const [order, setOrder] = useState("asc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("100");

  const tipoLeiState = useState<Array<ItemDataSelect<TipoLei>>>([]);
  const setTipoLeis = tipoLeiState[1];

  const selectedEntitiesAction = useSelectedDataAction<TipoLei>({
    state: tipoLeiState,
  });

  useEffect(() => {
    if (context.loading) {
      services.tipoLeiService
        .listagemTipoLeis(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((tipoLeis) => {
          setTipoLeis(
            tipoLeis.content.map((value: TipoLei) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(tipoLeis.paginationData);
          context.toPage(tipoLeis.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Criar Tipo de Lei"}
          className={"tipoLei-button"}
          onClick={() => {
            context.changeAction({ id: 0, action: "criar-tipo-lei" });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirTipoLei />;
      }

      case "criar-tipo-lei": {
        return <CriarTipoLei />;
      }
      case "editar-tipo-lei": {
        return <CriarTipoLei />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<TipoLei>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={tipoLeiState}
        filters={undefined}
        filterBar={undefined}
        page={{
          name: "Gerenciar Tipos de Leis",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do tipo da Lei",
          showFilters: false,
        }}
        visualization={{
          sort: {
            params: tipoLeiOrdenacao,
          },
          options: [],
          mode: mode,
          table: {
            dataRow: [
              <div>ID</div>,
              <div>Nome</div>,
              <div>Tipo de padrão</div>,
              <div>Ações</div>,
            ],
            dataColumn: TipoLeiHeader(selectedEntitiesAction).tipoLeiHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciasTipoLeis = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarTipoLeiComponent />
    </GerenciarBaseProvider>
  );
};
