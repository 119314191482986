import React from "react";
import { MainApp, MainAppBaseType } from "../../components/page/structure/MainApp";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { JusBqNavbar } from "./Navbar";
import { JusBqSidebar, JusBqSidebarClassificador } from "./Sidebar";



export const BancoQuestoesApp: React.FC<MainAppBaseType> = ({
  component,
}: MainAppBaseType) => {

  const { user } = useAuthContext();
  const { roles } = user;

  return (
    <MainApp
      component={component}
      sidebar={{
        sidebarValue: {
          appName: "Banco de questões",
          companyName: "Juspodivm",
        },
        sidebarType: roles.includes("ROLE_CLASSIFICADOR") ? JusBqSidebarClassificador : JusBqSidebar,
      }}
      navbar={{
        navbarType: JusBqNavbar,
      }}
    />
  );
};
