import { AxiosInstance } from 'axios';
import { Service } from '../../service';
import { Comentario } from '../comentarios';
import { PaginationData } from './../../../components/pagination/types';

export class ComentarioProjetoService extends Service {
  private ENDPOINT = 'project-question';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarComentarioProjetosByQuestao(
    page: number,
    search: string,
    idQuestao: number,
    validacao: string
  ): Promise<ComentarioProjetoPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `/${idQuestao}/comment?search=search_key:*'${search}'*;status:'${validacao}'&page=${page}&size=${30}&sort=id&order=desc`
      )
      .then((response) => {
        let ComentarioProjetos = new Array<ComentarioProjeto>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        ComentarioProjetos = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: ComentarioProjetos,
          paginationData: pagination,
        };
      });
  }

  async listarComentarioProjetosByResposta(
    page: number,
    search: string,
    idQuestao: number,
    idResposta: number,
    validacao: string
  ): Promise<ComentarioProjetoPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `/${idQuestao}/answer/${idResposta}/comment?search=search_key:*'${search}'*;status:'${validacao}'&page=${page}&size=${30}&sort=id&order=desc`
      )
      .then((response) => {
        let ComentarioProjetos = new Array<ComentarioProjeto>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        ComentarioProjetos = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: ComentarioProjetos,
          paginationData: pagination,
        };
      });
  }
}

export const useComentarioProjetoService = (axios: AxiosInstance) => {
  return new ComentarioProjetoService(axios);
};

export interface ComentarioProjeto {
  id: number;
  id_question_project: number;
  comment: Comentario;
}

export type ComentarioProjetoPagination = {
  content: Array<ComentarioProjeto>;
  paginationData: PaginationData;
};
