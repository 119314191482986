import styled from "styled-components";

export interface MultipleButtonSelectionStyles {
    row?: {
        marginLeft?: number
    }
}

export const MultipleButtonSelectionContent = styled.div`
    display: flex;
    flex-direction: column;

  
`;

export const ButtonWrapper = styled.div`
    margin-left: ${(settings: MultipleButtonSelectionStyles) => settings.row ? settings.row.marginLeft ? `${settings.row.marginLeft}px` : `24px` : `24px`} ;
    margin-top: 24px;

      .button-selected{
        border-bottom: 2px solid var(--color-primary-dark);  
    }
`
