import styled from 'styled-components';

export const ExportacaoModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TagItemContent = styled.div`
 .filtros {
    margin-bottom: 12px;

    .label {
      color: var(--color-text-grey);
      font-weight: 500;
      font-size: 0.8rem;
      text-transform: uppercase;
      margin-left: 12px;
    }
  }

`
export const ExportacaoBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-width: 1px;
  border-color: var(--color-select-border);
  border-style: solid;
  border-radius: 12px;
  padding: 12px;

  .buttons-options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .word-button{

      .bottom-icon{
          color: #646afa;
      }

    }

    .gift-button{
      .bottom-icon{
        color: #ff824d;
      }
    }

    .xml-button{

      .bottom-icon{
          color: #f760e2;
      }

    }

    .margin{
      margin-left: 6px;
    }

    .loading {
      display: flex;
      flex-direction: row;
      align-items: center;

      small {
        margin-left: 12px;
        margin-right: 12px;
        font-weight: 500;
        font-size: 0.6 rem;
        color: var(--color-text-grey);
      }
    }
  }
`;

export const ExportacaoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;

   h4 {
    font-weight: bold;
    color: var(--color-text-grey);
    margin-top: 14px;
    margin-bottom: 14px;
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    color: var(--color-text-grey);
  }

  .title{
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .exportar-comentarios-option{
    margin: 12px;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
