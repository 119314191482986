import React, { useState } from "react";
import { APIAuthProvider, AuthDataResponse, AuthProvider } from "./contextAuth";

interface TAuth {
  authAPIProvider: APIAuthProvider
}

export interface TAuthState {
  authenticated: boolean;
  user: {
    id: string;
    fullname: string;
    roles: Array<string>;
  };
  provider: string;

}

export interface TkLocalStorage {
  act: string;
  rt: string;
  ex_in: number;
  iat: number;
}

const TFA_AUTHORITIES = "TFA_PRE_AUTH";

export const Auth: React.FC<TAuth> = ({ children, authAPIProvider }) => {

  const tokenInf = JSON.parse(localStorage.getItem("t_inf") || "{}");
  const userInf = JSON.parse(localStorage.getItem("u_inf") || "{}");

  const [auth, setAuth] = useState<TAuthState>({
    authenticated: tokenInf.act ? true : false,
    user: {
      roles: userInf.rl ? userInf.rl : ["ROLE_VISITOR"],
      fullname: userInf.fullname ? userInf.fullname : "Usuário",
      id: userInf.id ? userInf.id : "",
    },
    provider: tokenInf.provider ? tokenInf.provider : "default"
  });



  React.useEffect(() => {

    const tokenInf = JSON.parse(localStorage.getItem("t_inf") || "{}");
    const userInf = JSON.parse(localStorage.getItem("u_inf") || "{}");

    setState(userInf, tokenInf);

  }, []);


  const redirectOAuthLogin = async (query: string, provider: string): Promise<string | boolean> => {

    return authAPIProvider.redirectOAuthLogin(query, provider).then((response) => {

      const { tokenInfo, userInfo } = response;

      const authorities: string[] = userInfo.rl;

      if (authorities.length === 1) {
        if (authorities.includes(TFA_AUTHORITIES)) {
          return tokenInfo.act;
        }
      }

      storeAuth(response, true);

      return true;
    });
  }

  const tfaLogin = async (tfaTk: string, code: string): Promise<boolean> => {

    return authAPIProvider.tfaLogin(tfaTk, code).then((response) => {
      storeAuth(response, true);
      return true;
    });

  }

  const initiateLogin = async (
    username: string,
    password: string,
    provider: string,
  ): Promise<string | boolean> => {

    return authAPIProvider.initiateLogin(username, password, provider).then((response) => {

      const { tokenInfo, userInfo } = response;

      const authorities: string[] = userInfo.rl;

      if (authorities.length === 1) {
        if (authorities.includes(TFA_AUTHORITIES)) {
          return tokenInfo.act;
        }
      }

      storeAuth(response, true);

      return true;
    });

  };

  const refresh = async (): Promise<string> => {

    return authAPIProvider.refresh(getTkInfo().rt, auth.provider).then((response) => {

      const { tokenInfo } = response;

      storeAuth(response, false);

      return tokenInfo.act;
    });

  };


  const logout = () => {
    setAuth({
      authenticated: false,
      provider: "default",
      user: {
        id: "",
        fullname: "",
        roles: ["ROLE_VISITOR"],
      },
    });
    localStorage.setItem("t_inf", "");
    localStorage.setItem("u_inf", "");
  };

  const storeAuth = (response: AuthDataResponse, updateState: boolean) => {

    const { tokenInfo, userInfo } = response;

    if (tokenInfo.act) {
      localStorage.setItem("t_inf", JSON.stringify(tokenInfo));
      localStorage.setItem("u_inf", JSON.stringify(userInfo));
    }

    if (updateState) {
      setState(userInfo, tokenInfo);
    }

  }

  const isAuthenticated = () => {
    if (!getTkInfo().ex_in) {
      return false;
    }
    const result = new Date().getTime() < getTkInfo().iat;

    return result;
  };

  const getTkInfo = () => {

    const json = JSON.parse(localStorage.getItem("t_inf") || "{}");

    const tk: TkLocalStorage = {
      act: json.act ? json.act : "",
      rt: json.rt ? json.rt : "",
      ex_in: json.in ? json.in : 0,
      iat: json.iat ? json.iat : 0
    }

    return tk;
  }


  const setState = (user: any, tk: any) => {
    setAuth({
      provider: tk.provider,
      authenticated: tk.act ? true : false,
      user: {
        id: user.id,
        roles: user.rl,
        fullname: user.fullname,
      },
    });
  };

  const authProviderValue = {
    ...auth,
    getTkInfo: getTkInfo,
    redirectOAuthLogin: redirectOAuthLogin,
    tfaLogin: tfaLogin,
    initiateLogin: initiateLogin,
    refresh: refresh,
    isAuthenticated: isAuthenticated,
    logout: logout,
  };

  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
};
