import { Tag } from "jusadmin-ui-kit";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFileText } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { MdOutlineShortText } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import { VscLaw } from "react-icons/vsc";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ButtonBottomLess } from "../../components/button/bottomless";
import { ButtonNormal } from "../../components/button/normal";
import { OptionsButton, TButtonSelect } from "../../components/options/group";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { APIFetch } from "../../services/api";
import { useProvaService } from "../../services/api-services/prova";
import { QuestaoResumida } from "../../services/api-services/questao";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarTexto } from "./apoios/texto";
import { QuestaoConsumer, useQuestaoContext } from "./context";
import { EditarEnunciadoQuestao } from "./enunciado";
import { PosSalvamentoQuestaoModal } from "./modals/pos-salvar";
import { TipoQuestao } from "./modals/tipo-questao";
import { TipoResposta } from "./modals/tipo-resposta";
import { CriarQuestaoProvider } from "./provider";
import { QuestaoInfo } from "./questao-info";
import { ReferenciasLegais } from "./referencias";
import { CriarRespostas } from "./respostas";
import {
  ProvaTitulo,
  QuestaoAcoes,
  QuestaoMenu,
  QuestaoOptions,
} from "./styles";

interface Props {
  id: string;
}

interface CriarQuestaoLocation {
  search: string;
}

export const CriarQuestao: React.FC<{}> = () => {
  const { id } = useParams<Props>();

  return (
    <CriarQuestaoProvider desativarAtualizacao={false}>
      <QuestaoConsumer
        children={(context) => {
          context.callbacks.setIdQuestao(id);
          return <CriarQuestaoComponent id={id} />;
        }}
      ></QuestaoConsumer>
    </CriarQuestaoProvider>
  );
};

export const CriarQuestaoComponent: React.FC<{
  id: string;
}> = ({ id }) => {
  ///

  const location = useLocation<CriarQuestaoLocation>();

  const auth = useAuthContext();

  const provaService = useProvaService(APIFetch(auth));

  const { questao, salvarQuestaoAPI, callbacks, prova } = useQuestaoContext();

  const history = useHistory();

  const actionState = useState<ActionType>(
    id
      ? {
        action: "questao",
        id: 0,
        loading: false,
      }
      : {
        action: "tipo",
        id: 0,
        loading: false,
      }
  );

  const [opcoes, setOpcoes] = useState<TButtonSelect[]>([]);

  const [action, setAction] = actionState;

  const [opcaoQuestao, setOpcaoQuestao] = useState(0);

  useEffect(() => {
    const { search } = location;

    if (search.length >= 1) {
      const params = new URLSearchParams(location.search);
      const provaId = params.get("exam");

      if (provaId) {
        provaService.buscarProva(Number.parseInt(provaId)).then((data) => {
          callbacks.setProva(data);
        });
      }
    } else {
      if (prova) {
        callbacks.setProva(undefined);
      }
    }
  }, [location]);

  useEffect(() => {
    if (questao.natureza.value === "Jurídica") {
      setOpcoes([
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "questao",
              loading: false,
            });
          },
          classname: "status-option",
          value: "Questao",
          icon: RiQuestionnaireLine,
          name: "Questão",
          text: "Preencha as informações relacionadas a questão",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "referencias-legais",
              loading: false,
            });
          },
          classname: "status-option",
          value: "referencias-legais",
          icon: VscLaw,
          name: "Referências legais",
          text: "Indique os dispositivos legais que responde a questão",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "enunciado",
              loading: false,
            });
          },
          classname: "status-option",
          value: "enunciado",
          icon: MdOutlineShortText,
          name: "Enunciado principal",
          text: "Escreva o enunciado da questão",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "textos",
              loading: false,
            });
          },
          classname: "status-option",
          value: "textos",
          icon: BsFileText,
          name: "Textos de apoios",
          text: "Crie textos auxiliares ao enunciado",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "respostas",
              loading: false,
            });
          },
          classname: "status-option",
          value: "Respostas",
          icon: AiOutlineUnorderedList,
          name: "Respostas",
          text: "Crie as respostas da questão",
        },
      ]);
    } else {
      setOpcoes([
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "questao",
              loading: false,
            });
          },
          classname: "status-option",
          value: "Questao",
          icon: RiQuestionnaireLine,
          name: "Questão",
          text: "Preencha as informações relacionadas a questão",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "enunciado",
              loading: false,
            });
          },
          classname: "status-option",
          value: "enunciado",
          icon: MdOutlineShortText,
          name: "Enunciado principal",
          text: "Escreva o enunciado da questão",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "textos",
              loading: false,
            });
          },
          classname: "status-option",
          value: "textos",
          icon: BsFileText,
          name: "Textos de apoios",
          text: "Crie textos auxiliares ao enunciado",
        },
        {
          onClick: (current) => {
            setAction({
              id: 0,
              action: "respostas",
              loading: false,
            });
          },
          classname: "status-option",
          value: "Respostas",
          icon: AiOutlineUnorderedList,
          name: "Respostas",
          text: "Crie as respostas da questão",
        },
      ]);
    }
  }, [questao.natureza]);

  useEffect(() => {
    if (questao.tipoResposta === "Única") {
      const array = opcoes.filter((value) => value.value !== "Respostas");
      setOpcoes([...array]);
    }
  }, [questao.tipoResposta]);

  const retornarInicio = () => {
    const reset = {
      id: 0,
      action: "questao",
      loading: false,
    };

    setAction(reset);
    questaoOptions(reset);

    const botaoInicial = opcoes.find((value) => value.value === "Questao");
    botaoInicial?.onClick(botaoInicial);
    setOpcaoQuestao(0);
  };

  const questaoOptions = (action: ActionType) => {
    switch (action.action) {
      case "questao": {
        return <QuestaoInfo modoClassificao={false} />;
      }
      case "respostas": {
        if (questao.tipoResposta === "Sem resposta") {
          return <TipoResposta action={actionState} />;
        } else if (questao.tipoResposta === "Única") {
          retornarInicio();
          return <Fragment />;
        } else {
          return <CriarRespostas />;
        }
      }
      case "referencias-legais": {
        return <ReferenciasLegais />;
      }
      case "enunciado": {
        return <EditarEnunciadoQuestao />;
      }
      case "textos": {
        return <CriarTexto />;
      }
      case "tipo": {
        return <TipoQuestao action={actionState} />;
      }
      case "pos-salvamento-questao": {
        return <PosSalvamentoQuestaoModal action={actionState} />;
      }
    }
  };

  const salvarQuestao = () => {
    salvarQuestaoAPI()
      .then((questao) => {
        const questaoAPI: QuestaoResumida = questao as QuestaoResumida;

        if (!id) {
          setAction({
            id: questaoAPI.id,
            action: "pos-salvamento-questao",
          });
        } else {
          alert("Questão salva com sucesso!");
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const getTituloQuestao = () => {
    if (id) {
      return questao.tipo && questao.tipoResposta !== "Sem resposta"
        ? `Editar questão ${questao.tipo.toLowerCase()}(${questao.tipoResposta.toLowerCase()})`
        : "Editar questão";
    }

    return questao.tipo && questao.tipoResposta !== "Sem resposta"
      ? `Criar questão ${questao.tipo.toLowerCase()}(${questao.tipoResposta.toLowerCase()})`
      : "Criar questão";
  };

  const salvarQuestaoArea = () => {
    return (
      <QuestaoAcoes>
        <div className="right">
          {prova && (
            <ProvaTitulo>
              <Tag
                nome={prova.exam_name}
                title={"Prova"}
                classname="prova-tag"
              />
            </ProvaTitulo>
          )}
          {action.action === "questao" && (
            <Fragment>
              <ButtonBottomLess
                title="Cancelar"
                className="cancelar"
                onClick={() => {
                  window.location.reload();
                }}
              />
              <ButtonNormal
                title={"Salvar"}
                className="salvar"
                icon={FaCheck}
                onClick={() => salvarQuestao()}
              />
            </Fragment>
          )}
        </div>
      </QuestaoAcoes>
    );
  };

  return (
    <BaseComponent
      pageName={getTituloQuestao()}
      headerRight={salvarQuestaoArea()}
    >
      <QuestaoMenu>
        <QuestaoOptions>
          <OptionsButton
            className={"opcoes-questao"}
            initialPositionSelected={opcaoQuestao}
            buttons={opcoes}
          />
        </QuestaoOptions>
        {questaoOptions(action)}
      </QuestaoMenu>
    </BaseComponent>
  );
};
