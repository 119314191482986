import { AxiosInstance } from 'axios';
import { ChildrenElement, ParentElement } from '../../../components/drag-and-drop/hooks/useLayoutDragSystem';
import { PaginationData } from '../../../components/pagination/types';
import { QuestaoItem } from '../questao';
import { Service } from './../../service';


export interface DivisaoSeparador {
    type: "group" | "separator";
    metadata?: AgrupamentoDados;
    additional_text: string | "";
}

export interface DivisaoGrupo {
    type: "group" | "separator";
    meta_sorting?: OrdenacaoMeta[];
    restart_sequence: boolean;
}

export interface DivisaoData {
    order: number;
    title?: string;
    is_grouping: boolean;
    level: string;
    id_parent?: number;
    additional_text: string | "";
    restart_sequence: boolean;
    meta_sorting?: OrdenacaoMeta[];
    metadata?: AgrupamentoDados;

}

export interface DivisaoAdjacente {
    adjacent_division_id: number;
    move_division_id?: number;
}

export interface DivisaoAdjacenteData {
    recommended_division_id: number,
    selected_division_id?: number
}


export interface OrdenacaoMeta {
    parameter: string;
    order: string;
}

export interface AgrupamentoDados {
    parameter: string;
    value?: string;
}

export interface Divisoes {
    content: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
}

export interface QuestoesDaDivisao {
    content: Array<ParentElement<QuestaoProjetoDivisao> | ChildrenElement<QuestaoProjetoDivisao>>,
    paginationData: PaginationData;

}


export interface QuestaoProjetoDivisao {
    id: number,
    id_project: number,
    id_division: number,
    position: number,
    sequence_number: number
    question: QuestaoItem;

}

export class DivisaoProjetoService extends Service {
    private ENDPOINT = 'project';

    constructor(axios: AxiosInstance) {
        super(axios);
    }

    async listarDivisoes(idProject: number, checkOpen?: (value: ParentElement<DivisaoData>) => boolean): Promise<Divisoes> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `/${idProject}/division?search`
            )
            .then((response) => {
                let projetos = new Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>();
                projetos = response.data.content.map((value: ParentElement<DivisaoData> | ChildrenElement<DivisaoData>) => {
                    if (value.type === "parent") {
                        const parent = {
                            ...value,
                            isOpen: checkOpen ? checkOpen(value) : false,
                        }
                        return parent;
                    } else {
                        return value;
                    }

                });

                return {
                    content: projetos,
                };
            });
    }

    async listarSubDivisoes(idProject: number, idDivisionParent: number, checkOpen?: (value: ParentElement<DivisaoData>) => boolean): Promise<Divisoes> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `/${idProject}/division/${idDivisionParent}/sub-divisions?search`
            )
            .then((response) => {
                let projetos = new Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>();

                projetos = response.data.content.map((value: ParentElement<DivisaoData> | ChildrenElement<DivisaoData>) => {
                    if (value.type === "parent") {
                        const parent = {
                            ...value,
                            isOpen: checkOpen ? checkOpen(value) : false,
                        }
                        return parent;
                    } else {
                        return value;
                    }

                });
                return {
                    content: projetos,
                };
            });
    }


    async consultarDivisoes(idProject: number, idDivision: number): Promise<DivisaoData> {
        return this.fetch.get(this.ENDPOINT + `/${idProject}/division/${idDivision}`).then((response) => {
            const data: DivisaoData = response.data;
            return data;
        });
    }

    async consultarDivisaoAdjacente(idProject: number, idDivision: number): Promise<number> {
        return this.fetch.get(this.ENDPOINT + `/${idProject}/division/${idDivision}/adjacent`).then((response) => {
            const adjacente: number = response.data;
            return adjacente;
        });
    }

    async editarGrupo(idProject: number, idDivision: number, divisao: DivisaoGrupo): Promise<DivisaoData> {
        return this.fetch
            .put(this.ENDPOINT + `/${idProject}/division/${idDivision}/group`, divisao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async editarSeparador(idProject: number, idDivision: number, divisao: DivisaoSeparador): Promise<DivisaoData> {
        return this.fetch
            .put(this.ENDPOINT + `/${idProject}/division/${idDivision}/separator`, divisao, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async excluirGrupo(idProject: number, idDivision: number): Promise<void> {
        return this.fetch
            .delete(this.ENDPOINT + `/${idProject}/division/${idDivision}/group`, {
                data: {},
                headers: {
                    "Content-Type": "application/json",
                }
            });
    }

    async excluirSeparador(idProject: number, idDivision: number, data: DivisaoAdjacente, dynamic_movement_content: boolean, sort_questions: boolean): Promise<DivisaoData> {
        return this.fetch
            .delete(this.ENDPOINT + `/${idProject}/division/${idDivision}/separator?&dynamic_movement_content=${dynamic_movement_content}&sort_questions=${sort_questions}`, {
                data: data,
                headers: {
                    "Content-Type": "application/json",
                }
            });
    }

    async moverGrupo(idProjeto: number, idDivisaoMovida: number, idDivisaoPai: number, ordemDivisao: number, isInside: boolean): Promise<void> {
        return this.fetch
            .patch(this.ENDPOINT + `/${idProjeto}/division/from/${idDivisaoMovida}/to/${idDivisaoPai}/order/${ordemDivisao}/group/drag?isInside=${isInside ? 1 : 0}`, {
                data: {},
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async moverSeparador(idProjeto: number, idDivisaoMovida: number, idDivisaoPai: number, ordemDivisao: number, data: DivisaoAdjacenteData,
        isInside: boolean, dynamic_movement_content: boolean, sort_questions: boolean): Promise<void> {
        return this.fetch
            .patch(this.ENDPOINT + `/${idProjeto}/division/from/${idDivisaoMovida}/to/${idDivisaoPai}/order/${ordemDivisao}/separator/drag?
            isInside=${isInside ? 1 : 0}&dynamic_movement_content=${dynamic_movement_content}&sort_questions=${sort_questions}`, data, {
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    async moverQuestao(idProjeto: number, idQuestaoProjeto: number, idDivisaoPai: number, ordem_questao: number): Promise<void> {
        return this.fetch
            .patch(this.ENDPOINT + `/${idProjeto}/division/${idDivisaoPai}/order/${ordem_questao}/question/${idQuestaoProjeto}/drag`)
            .then((response) => {
                return response.data;
            });
    }


    async listarQuestoesDivisao(
        idProjeto: number,
        idDivision: number,
        page: number
    ): Promise<QuestoesDaDivisao> {
        return this.fetch
            .get(
                this.ENDPOINT +
                `/${idProjeto}/division/${idDivision}/question` +
                `?search=&page=${page}&size=100`
            )
            .then((response) => {
                let questoes = new Array<ParentElement<QuestaoProjetoDivisao> | ChildrenElement<QuestaoProjetoDivisao>>();

                let pagination = {
                    current_page: 0,
                    total_items: 0,
                    total_number_pages: 1,
                };

                questoes = response.data.content;

                pagination = {
                    total_items: response.data.total_items,
                    current_page: response.data.current_page,
                    total_number_pages: response.data.total_number_pages,
                };

                return {
                    content: questoes,
                    paginationData: pagination,
                };
            });
    }

    async verificarAcesso(idProjeto: number): Promise<Boolean> {
        return this.fetch
            .get(this.ENDPOINT + `/${idProjeto}/division/check`)
            .then((response) => {
                return response.data;
            });
    }

    async liberarAcesso(idProjeto: number): Promise<void> {
        return this.fetch
            .patch(this.ENDPOINT + `/${idProjeto}/division/release`);
    }

    async realizarAcesso(idProjeto: number): Promise<Boolean> {
        return this.fetch
            .patch(this.ENDPOINT + `/${idProjeto}/division/access`).then((value) => {
                return value.data;
            })
    }

}

export const useDivisaoProjeto = (axios: AxiosInstance) => {
    return new DivisaoProjetoService(axios);
};
