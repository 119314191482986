import React from "react";
import "react-color-palette/lib/css/styles.css";
import ReactDOM from "react-dom";
import "react-quill/dist/quill.snow.css"; // ES6
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
