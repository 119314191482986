import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { BiIdCard } from 'react-icons/bi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { InputIcon } from '../../../../components/input/input-icon';
import { Modal } from '../../../../components/modals/modal-base';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { FooterTrocarSenha, MudarSenhaContent } from '../trocar-senha/styles';


export interface TConfirmarAutorizacao {
  state?: [ActionType, Dispatch<SetStateAction<ActionType>>];
  chargeUsername: boolean;
  notShowAlert?: boolean;

}

export const ConfirmarAutorizacao: React.FC<TConfirmarAutorizacao> = ({ state, chargeUsername, notShowAlert }) => {

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const [currentState, setCurrentState] = useState(state ? state : context.currentActionState);

  const [username, setUsername] = useState('');

  const [password, setPassword] = useState('');

  const Footer = () => {
    return (
      <FooterTrocarSenha>
        <div />
        <ButtonBottomLess className="save" title="Autorizar" icon={RiLockPasswordLine} type={'submit'} />
      </FooterTrocarSenha>
    );
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    currentState[0]
      .promisse?.({
        username: username,
        password: password
      })
      .then((action) => {
        if (!notShowAlert) {
          alert('Ação realizada com sucesso!');
        }
      })
      .catch((error) => {
        if (!notShowAlert) {
          alert('Ação realizada com sucesso!');
        }
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <Modal action={currentState} title={'Autorização para ação'} options={Footer()}>
        <MudarSenhaContent>
          <div className="form-autorizacao">
            {chargeUsername &&
              <div className="form-group">
                <div className="field-group">
                  <label htmlFor={'senha'}>Usuário:</label>
                  <InputIcon
                    placeholder="Informe seu usuário"
                    className="nomeUsuario field-size"
                    onClickSearch={() => { }}
                    onChangeValue={(value) => setUsername(value)}
                    icon={BiIdCard}
                  />
                </div>
              </div>}
            <div className="form-group">
              <div className="field-group">
                <label htmlFor={'senhaConfirmacao'}>Senha:</label>
                <InputIcon
                  type="password"
                  placeholder="Informe sua senha"
                  className="senha field-size"
                  onClickSearch={() => { }}
                  onChangeValue={(value) => setPassword(value)}
                  icon={RiLockPasswordLine}
                />
              </div>
            </div>
          </div>
        </MudarSenhaContent>
      </Modal>
    </form>
  );
};
