import { Fragment, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { estiloExportacaoOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { EstiloExportacao, useEstiloExportacaoService } from "../../services/api-services/estiloExportacao";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarEstiloExportacao } from "./forms/criar-estilo-exportacao";
import { ExcluirEstiloExportacao } from "./forms/excluir-estilo-exportacao";
import { EstiloExportacaoHeader } from "./views/row-estilo-exportacao";

const GerenciarEstiloExportacaoesComponent = () => {
  //
  const auth = useAuthContext();

  const context = useGerenciarContext();

  const estiloexportacaoService = useEstiloExportacaoService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const estiloexportacaoState = useState<Array<ItemDataSelect<EstiloExportacao>>>([]);
  const setEstiloExportacaoes = estiloexportacaoState[1];

  const selectedEntitiesAction = useSelectedDataAction<EstiloExportacao>({
    state: estiloexportacaoState,
  });

  useEffect(() => {
    if (context.loading) {
      estiloexportacaoService
        .listagemEstiloExportacao(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((estiloexportacaoes) => {
          setEstiloExportacaoes(
            estiloexportacaoes.content.map((value: EstiloExportacao) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(estiloexportacaoes.paginationData);
          context.toPage(estiloexportacaoes.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo Estilo de Exportação"}
          className={"estilo-exportacao-button"}
          onClick={() => {
            context.changeAction({
              id: 0,
              action: "criar-estilo-exportacao",
            });
          }}
        />
      </Fragment>
    );
  };

  const modals = () => {
    switch (context.currentAction.action) {
      case "excluir": {
        return <ExcluirEstiloExportacao />;
      }
      case "criar-estilo-exportacao": {
        return <CriarEstiloExportacao />;
      }
      case "editar-estilo-exportacao": {
        return <CriarEstiloExportacao />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() => {
              context.changeAction({
                id: 0,
                action: "",
              });
            }}
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                id: 0,
                action: "",
              })
            }
          />
        );
      }
    }
  };
  return (
    <Fragment>
      <GerenciarPageBase<EstiloExportacao>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        dataState={estiloexportacaoState}
        page={{
          name: "Listagem de Estilos de Exportação",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome do estilo",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: estiloExportacaoOrdenacao,
          },
          mode: "header",
          options: [],
          table: {
            dataRow: [<div>ID</div>, <div>Nome</div>, <div>Ações</div>],
            dataColumn: EstiloExportacaoHeader(selectedEntitiesAction).estiloExportacaoHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarEstilosExportacao = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarEstiloExportacaoesComponent />
    </GerenciarBaseProvider>
  );
};
