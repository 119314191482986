import { Fragment, useEffect, useState } from "react";
import { ActionType } from "../../../../../components/page/GerenciarPageBase/types";
import { PaginationData } from "../../../../../components/pagination/types";
import { Comentario } from "../../../../../services/api-services/comentarios";
import { ComentarioProjetoService } from "../../../../../services/api-services/comentariosProjeto";
import { QuestaoDetails } from "../../../../../services/api-services/questao";
import { ComentarQuestao } from "../../../../ComentarQuestao";
import { RespostaCertoErradoView } from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/certoErrado";
import { RespostaEscolhaView } from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/escolha";
import { RespostaPadraoView } from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/padrao";
import { RespostaRelacionalView } from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/relacional";
import { ListBaseComentarios } from "../../../../GerenciarQuestoes/views/visualizar-questao/comentarios";

export interface TAlternativas {
  questao?: QuestaoDetails;
  idQuestao: number;
  comentarioService: ComentarioProjetoService;
}

export const Alternativas: React.FC<TAlternativas> = ({
  idQuestao,
  questao,
  comentarioService,
}) => {
  const respostasObject: any = questao?.answers;

  const [comentariosState, setComentariosState] = useState<ActionType>({
    action: "",
    id: 0,
    message: "",
  });

  const modalState = useState<ActionType>({
    action: "",
    id: 0,
  });

  const [modal, setModal] = modalState;

  const [comentarios, setComentarios] = useState<Array<Comentario>>([]);

  const loadingState = useState(false);

  const [loading, setLoading] = loadingState;

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  const [validacao, setValidacao] = useState("");

  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 0,
    total_items: 0,
    total_number_pages: 0,
  });

  useEffect(() => {
    if (loading) {
      comentarioService
        .listarComentarioProjetosByResposta(
          currentPage - 1,
          search,
          idQuestao,
          comentariosState.id,
          validacao
        )
        .then((comentario) => {
          setComentarios(comentario.content.map((item) => item.comment));
          setPaginationData(comentario.paginationData);
          setLoading(false);
        });
    }
  }, [loading]);

  useEffect(() => {
    if (comentariosState.action === "comments") {
      if (modal.loading) {
        setLoading(true);
        setModal({
          ...modal,
          loading: false,
        });
      }
    }
  }, [modal]);

  const showComments = (title: string, id: number) => {
    setLoading(true);
    setComentariosState({
      action: "comments",
      id: id,
      message: title,
    });
  };

  const modals = (action: ActionType) => {
    switch (action.action) {
      case "editar-comentario": {
        return (
          <ComentarQuestao action={modalState} edit={true} type="resposta" />
        );
      }
    }
  };

  const onEditComentario = (idComentario: number, idResposta: number) => {
    setModal({
      id: 0,
      action: "editar-comentario",
      object: {
        idQuestao: idQuestao,
        idComentario: idComentario,
        idResposta: idResposta,
      },
    });
  };

  const comments = (action: ActionType) => {
    switch (action.action) {
      case "comments": {
        return (
          <ListBaseComentarios
            title={action.message ? action.message : "Comentários"}
            comentarios={comentarios}
            idQuestao={idQuestao}
            paginacaoData={paginationData}
            callback={{
              onCurrentPage: (page) => {
                setCurrentPage(page);
                setLoading(true);
              },
              onSearchPage: (search) => {
                setSearch(search);
                setLoading(true);
              },
              onValidacao: (validacao) => {
                setValidacao(validacao);
                setLoading(true);
              },
              onEditComentario: onEditComentario,
            }}
          />
        );
      }
    }
  };

  const respostas = (tipo: string) => {
    switch (tipo) {
      case "Padrão": {
        if (questao) {
          if (respostasObject) {
            return (
              <RespostaPadraoView respostas={respostasObject.first_column} />
            );
          }
        }
        return <Fragment />;
      }
      case "Múltipla Escolha": {
        if (questao) {
          if (respostasObject) {
            return (
              <RespostaEscolhaView
                respostas={respostasObject.first_column}
                onShowComments={showComments}
              />
            );
          }
        }
        return <Fragment />;
      }
      case "Certo/Errado": {
        if (questao) {
          if (respostasObject) {
            return (
              <RespostaCertoErradoView
                respostas={respostasObject.first_column}
                onShowComments={showComments}
              />
            );
          }
        }
        return <Fragment />;
      }
      case "Verdadeiro/Falso": {
        if (questao) {
          if (respostasObject) {
            return (
              <RespostaCertoErradoView
                respostas={respostasObject.first_column}
                onShowComments={showComments}
              />
            );
          }
        }
        return <Fragment />;
      }
      case "Relacional": {
        if (questao) {
          if (respostasObject) {
            return (
              <RespostaRelacionalView
                respostas={respostasObject}
                onShowComments={showComments}
              />
            );
          }
        }
        return <Fragment />;
      }
    }
  };

  return (
    <Fragment>
      {respostas(questao?.answer_type ? questao.answer_type : "")}
      {comments(comentariosState)}
      {modals(modal)}
    </Fragment>
  );
};
