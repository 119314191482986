import { createContext, useContext } from "react";
import { TDragContext } from "../../../../components/drag-and-drop";
import {
  ChildrenElement,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { QuestaoProjetoDivisao } from "../../../../services/api-services/divisoesProjeto";



export interface TQuestoesContext extends TDragContext<QuestaoProjetoDivisao> {

}

const initialState: TQuestoesContext = {
  layout: [],
  setLayout: (
    layout: Array<ParentElement<QuestaoProjetoDivisao> | ChildrenElement<QuestaoProjetoDivisao>>
  ) => { },
  handleDrop: (drop, item) => { },
  setHandleDropFeatureData: (handle) => { }
};

export const context = createContext(initialState);

export const QuestoesProvider = context.Provider;
export const QuestoesConsumer = context.Consumer;

export const useQuestoesContext = () => useContext(context);
