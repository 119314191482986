import styled from "styled-components";

export interface Ttag {
  width?: string;
  colorDark?: string;
  position?: string;
}

export const TagFilterContent = styled.div`
  ${(settings: Ttag) =>
    settings.width &&
    ` 
    max-height: ${settings.width}
  `}

  display: flex;
  flex-direction: row;
  text-align: ${(settings: Ttag) => settings.position ? `${settings.position}` : 'center'};
  align-items: center;
  justify-content: ${(settings: Ttag) => settings.position ? `${settings.position}` : 'center'};;
  padding: 4px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
  margin-top: 8px;
  background-color: var(--color-content-light);
  border-radius: 12px;

  div {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  small {
    margin-right: 4px;
    font-weight: 500;
    font-size: 0.65rem;
  }

  ${(settings: Ttag) =>
    settings.colorDark
      ? ` 
      border: 2.5px solid var(--${settings.colorDark});

      div,small{
           color: var(--${settings.colorDark}); 
      }
 
  `
      : `
    border: 2.5px solid var(--color-primary-light-clear);
    
    div,small{
        color: var(--color-primary-dark);
    }
  `}
`;
