import { Fragment, useEffect, useState } from "react";
import { BiCommentAdd } from "react-icons/bi";
import { FaRegComments } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import {
  GroupButtons,
  TButtonSelect
} from "../../../../components/group-buttons";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../../../components/page/structure/BaseComponent";
import { PaginationData } from "../../../../components/pagination/types";
import { APIFetch } from "../../../../services/api";
import {
  Comentario,
  useComentarioService
} from "../../../../services/api-services/comentarios";
import { ProjetoDetails } from "../../../../services/api-services/projeto";
import {
  QuestaoDetails,
  RespostaDetails,
  useQuestaoService
} from "../../../../services/api-services/questao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ComentarQuestao } from "../../../ComentarQuestao";
import { Alternativas } from "./alternativas";
import { RespostaEnunciado } from "./alternativas/enunciado";
import { Apoios } from "./apoios";
import { ListBaseComentarios } from "./comentarios";
import { Enunciado } from "./enunciado";
import { ReferenciasLegaisViewDetails } from "./referencias-legais";
import {
  ComentarioOptions,
  QuestaoViewContainer, VisualizarQuestaoContent
} from "./styles";

interface Props {
  id: string;
}

export function VisualizarQuestao() {
  const actionState = useState<ActionType>({
    action: "enunciado",
    id: 3,
  });

  const [action, setAction] = actionState;

  const modalState = useState<ActionType>({
    action: "",
    id: 0,
  });

  const [modal, setModal] = modalState;

  const auth = useAuthContext();
  const questaoService = useQuestaoService(APIFetch(auth));
  const comentarioService = useComentarioService(APIFetch(auth));

  const { id } = useParams<Props>();

  const [showComentarios, setShowComentarios] = useState(false);

  const answers = new Map<string, RespostaDetails[]>();



  const [questao, setQuestao] = useState<QuestaoDetails>({
    answer_type: "",
    legal_references: [],
    sources: [],
    nature: "",
    supports: [],
    statement_question: "",
    status: "",
    subject: {
      id: 0,
      name: "",
    },
    topic: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    tag: {
      name: "",
      id: 0,
      subject: {
        id: 0,
        name: "",
      },
    },
    type: "",
    validation: "",
    id: 0,
    answers: answers,
    cod: "",
    correct: "",
    difficulty: "",
    exam_header: {
      id: 0,
      exam_name: "",
      oab_exam_number: "",
      body: {
        abbreviation: "",
        id: 0,
        name: "",
      },
      exam_board: {
        id: 0,
        name: "",
      },
      level: "",
      office: {
        id: 0,
        name: "",
      },
      year: 0,
      career: {
        id: 0,
        name: ""
      }
    },
    exam_disc_active: false,
    exam_discipline: ""
  });

  const [projeto, setProjeto] = useState<Array<ProjetoDetails>>([]);

  const [comentarios, setComentarios] = useState<Array<Comentario>>([]);

  const loadingState = useState(false);

  const [loading, setLoading] = loadingState;

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  const [validacao, setValidacao] = useState("");

  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 0,
    total_items: 0,
    total_number_pages: 0,
  });

  useEffect(() => {
    questaoService.buscarQuestao(parseInt(id)).then((ele) => {
      setQuestao(ele);
    });
  }, []);

  useEffect(() => {
    questaoService
      .listarProjetosVinculadosQuestao(parseInt(id))
      .then((projetos) => {
        setProjeto(projetos);
      });
  }, []);

  useEffect(() => {
    if (showComentarios) {
      if (modal.loading) {
        setLoading(true);
        setModal({
          ...modal,
          loading: false,
        });
      }
    }
  }, [modal]);

  useEffect(() => {
    if (loading) {
      comentarioService
        .listarComentariosByQuestao(
          currentPage - 1,
          search,
          parseInt(id),
          validacao
        )
        .then((comentario) => {
          setComentarios(comentario.content);
          setPaginationData(comentario.paginationData);
          setLoading(false);
        });
    }
  }, [loading]);

  const modals = (action: ActionType) => {
    switch (action.action) {
      case "comentar": {
        return (
          <ComentarQuestao action={modalState} edit={false} type="enunciado" />
        );
      }
      case "editar-comentario": {
        return (
          <ComentarQuestao action={modalState} edit={true} type="enunciado" />
        );
      }
    }
  };

  const onEditComentario = (idComentario: number, idResposta: number) => {
    setModal({
      id: 0,
      action: "editar-comentario",
      object: {
        idQuestao: parseInt(id),
        idComentario: idComentario,
      },
    });
  };

  const options = (action: ActionType) => {
    switch (action.action) {
      case "referencias-legais": {
        return <QuestaoViewContainer>
          <ReferenciasLegaisViewDetails referenciasDetails={questao.legal_references} /> </QuestaoViewContainer>
      }
      case "apoios": {
        return <Apoios questao={questao} />;
      }
      case "alternativas": {
        return (
          <Alternativas
            questao={questao}
            idQuestao={parseInt(id)}
            comentarioService={comentarioService}
          />
        );
      }
      case "enunciado": {
        return (
          <Fragment>
            <QuestaoViewContainer>
              <Enunciado questao={questao} projetos={projeto} />
              {questao.answer_type === "Única" && (
                <RespostaEnunciado correta={questao.correct} />
              )}
              <ComentarioOptions>
                <ButtonBottomLess
                  className="open-comentarios option-margin"
                  title="Comentários"
                  icon={FaRegComments}
                  onClick={() => {
                    setLoading(true);
                    setShowComentarios(showComentarios ? false : true);
                  }}
                />
                <ButtonBottomLess
                  className="new-comentarios option-margin"
                  title="Comentar"
                  icon={BiCommentAdd}
                  onClick={() => {
                    setModal({
                      id: 0,
                      action: "comentar",
                      object: {
                        idQuestao: parseInt(id),
                      },
                    });
                  }}
                />
              </ComentarioOptions>
            </QuestaoViewContainer>
            {showComentarios && (
              <ListBaseComentarios
                title={action.message ? action.message : "Comentários"}
                comentarios={comentarios}
                idQuestao={parseInt(id)}
                paginacaoData={paginationData}
                callback={{
                  onCurrentPage: (page) => {
                    setCurrentPage(page);
                    setLoading(true);
                  },
                  onSearchPage: (search) => {
                    setSearch(search);
                    setLoading(true);
                  },
                  onValidacao: (validacao) => {
                    setValidacao(validacao);
                    setLoading(true);
                  },
                  onEditComentario: onEditComentario,
                }}
              />
            )}
          </Fragment>
        );
      }
    }
  };

  const buttonGroupRender = () => {
    const comRespostas: TButtonSelect[] = [
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 1,
          });
        },
        name: "enunciado",
        classname: "status-option",
        value: "enunciado",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 2,
          });
        },
        name: "referências legais",
        classname: "status-option",
        value: "referencias-legais",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 3,
          });
        },
        name: "alternativas",
        classname: "status-option",
        value: "alternativas",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 4,
          });
        },
        name: "textos de apoio",
        classname: "status-option",
        value: "apoios",
      },
    ];

    const semRespostas: TButtonSelect[] = [
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 1,
          });
        },
        name: "enunciado",
        classname: "status-option",
        value: "enunciado",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 2,
          });
        },
        name: "referências legais",
        classname: "status-option",
        value: "referencias-legais",
      },
      {
        onClick: (current) => {
          setAction({
            action: current.value,
            id: 3,
          });
        },
        name: "textos de apoio",
        classname: "status-option",
        value: "apoios",
      },
    ];

    if (questao?.answer_type === "Única") {
      return semRespostas;
    }

    return comRespostas;
  };

  return (
    <BaseComponent pageName={`Questão`} headerRight={<GroupButtons
      initialPositionSelected={0}
      buttons={buttonGroupRender()}
    />}>
      <VisualizarQuestaoContent>{options(action)}</VisualizarQuestaoContent>
      {modals(modal)}
    </BaseComponent>
  );
}
