import { useEffect, useState } from "react";
import { TThreeNormalOption } from "../../../../../../components/menu/three-normal";
import { ItemSelectEntity } from "../../../../../../components/page/structure/FilterComponent/types";
import { dispositivosAlinea, dispositivosArtigo, dispositivosInciso, dispositivosItem, dispositivosParagrafo } from "../../../../../../mocks/data";
import { TipoLei } from "../../../../../../services/api-services/tipoLeis";
import { TReferencialegal } from "../../../../../CriarQuestao/context";
import { validarArtigo, validarDispositivo, validarLei, validarLeiNumeracao } from "../../../../../CriarQuestao/provider";
import { DispositivoItem, TagDispositivoLegal } from "./types";

export interface TDispositivosLegaisHook {
    lei: {
        value: string;
        erro: string;
    },
    artigo: {
        value: string;
        erro: string;
    }
    tipoLei: ItemSelectEntity<TipoLei>;
    dispositivos: Array<TagDispositivoLegal>;
    dispositivosDisponiveis: Array<TThreeNormalOption>;

    callbacks: {
        getQueryDispositivos: () => {
            referenciaLegal: TReferencialegal;
            query: string;
        };
        getDispositivos: () => { dispositivos: TagDispositivoLegal[], referenciaLegal: TReferencialegal };
        buscarDispositivoByNome: (nome: string) => TagDispositivoLegal | undefined;
        carregarDispositivos: (grupos: TagDispositivoLegal[]) => void;
        onRemoverUltimoDispositivo: () => void
        setDispositivos: React.Dispatch<React.SetStateAction<TagDispositivoLegal[]>>;
        setDispositivosDisponiveis: React.Dispatch<React.SetStateAction<Array<TThreeNormalOption>>>;
        setLei: React.Dispatch<React.SetStateAction<{
            value: string;
            erro: string;
        }>>,
        setArtigo: React.Dispatch<React.SetStateAction<{
            value: string;
            erro: string;
        }>>;
        setTipoLei: React.Dispatch<React.SetStateAction<ItemSelectEntity<TipoLei>>>;
        setLeiData: (value: string) => void;
        setTipoLeiData: (value: ItemSelectEntity<TipoLei>) => void;
        setArtigoData: (value: string) => void
        setDispositivoData: (valorMascarado: string, dispositivo: TagDispositivoLegal, index: number) => void;
        validarReferenciaLegal: () => {
            mensagemDeErro: string;
            validado: boolean;
        };
    },
    mascaras: {
        maskLei: (e: React.FormEvent<HTMLInputElement>) => void
        maskArtigo: (e: React.FormEvent<HTMLInputElement>) => void
        maskDispositivo: (e: React.FormEvent<HTMLInputElement>, dispositivo: TagDispositivoLegal, index: number) => void
    }
}

export const useDispositivosLegaisHook = (): TDispositivosLegaisHook => {

    const [tipoLei, setTipoLei] = useState<ItemSelectEntity<TipoLei>>({
        id: -1,
        key: "",
        useId: false,
        value: "",
        entity: undefined,
    });

    const [lei, setLei] = useState<{
        value: string;
        erro: string;
    }>({
        value: "",
        erro: "",
    });

    const [artigo, setArtigo] = useState<{
        value: string;
        erro: string;
    }>({
        value: "",
        erro: "",
    });

    const [dispositivos, setDispositivos] = useState<Array<TagDispositivoLegal>>([]);

    const [dispositivosDisponiveis, setDispositivosDisponiveis] = useState<Array<TThreeNormalOption>>([]);

    useEffect(() => {
        atualizaDispositivosDisponiveis();
    }, [dispositivos]);

    const maskLei = (e: React.FormEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value;

        if (tipoLei.entity) {
            switch (tipoLei.entity.pattern_type) {
                //
                case "Termos": {
                    const termos = tipoLei.entity.terms.split(";").join("|");
                    const regexStep_1 = new RegExp("(\\b(?:" + termos + ")\\b)", "s");

                    value = value.replace(regexStep_1, '$1');

                    break;
                }
                case "Numeração": {
                    value = value.replace(/\D/s, "");
                    value = value.replace(/(\d{2})(\d{3})(\d{4})/s, "$1.$2/$3");
                    break;
                }
            }
        } else {
            value = value.replace(/\D/g, "");
            value = value.replace(/(\d{2})(\d{3})(\d{4})/s, "$1.$2/$3");
        }

        e.currentTarget.value = value;
        setLeiData(value);
    };

    const maskArtigo = (e: React.FormEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value;
        value = value.replace(/(\d{4})(-[A-Z]{1})?/s, "$1$2");
        e.currentTarget.value = value;

        setArtigoData(value);
    };

    const maskDispositivo = (e: React.FormEvent<HTMLInputElement>, dispositivo: TagDispositivoLegal, index: number) => {

        let valorMascarado = e.currentTarget.value;

        switch (dispositivo.name) {
            case "Inciso": {

                valorMascarado = valorMascarado.replace(/^([MDCLXVI]+)$/s, "$1");
                e.currentTarget.value = valorMascarado;

                break;
            }
            case "Alínea": {

                valorMascarado = valorMascarado.replace(/^([a-z]{1})$/s, "$1");
                e.currentTarget.value = valorMascarado;

                break;
            }

            case "Item": {

                valorMascarado = valorMascarado.replace(/^(\d{4})$/s, "$1");
                e.currentTarget.value = valorMascarado;

                break;
            }

            case "Parágrafo": {

                valorMascarado = valorMascarado.replace(/^PU$/s, "parágrafo único");
                valorMascarado = valorMascarado.replace(/^(\d{4}|PU)$/s, "$1");

                e.currentTarget.value = valorMascarado;

                break;
            }
        }


        setDispositivoData(valorMascarado, dispositivo, index);

    }

    const atualizaDispositivosDisponiveis = () => {
        let ultimoDispositivo: TagDispositivoLegal | undefined = undefined;

        if (dispositivos.length >= 1) {
            ultimoDispositivo = dispositivos[dispositivos.length - 1];
        }

        let disponiveis: DispositivoItem[] | undefined = [];

        const nomeDispositivo = ultimoDispositivo ? ultimoDispositivo.name : "";

        switch (nomeDispositivo) {
            case "Inciso": {

                disponiveis = dispositivosInciso.subDispositivos;

                break;
            }
            case "Alínea": {

                disponiveis = dispositivosAlinea.subDispositivos;

                break;
            }

            case "Item": {

                disponiveis = dispositivosItem.subDispositivos;

                break;
            }

            case "Parágrafo": {

                disponiveis = dispositivosParagrafo.subDispositivos;

                break;
            } default: {

                disponiveis = dispositivosArtigo.subDispositivos;

                break;
            }
        }

        if (disponiveis) {
            setDispositivosDisponiveis(disponiveis.map((value) => {

                const dispositivo: TThreeNormalOption = {
                    name: value.nome,
                    onClick: () => { onAddDispositivo(value) }
                }

                return dispositivo;
            }));
        }

    }

    const carregarDispositivos = (grupos: TagDispositivoLegal[]) => {
        let dispositivosCarregados = new Array<TagDispositivoLegal>();

        grupos.forEach((tagDispositivo) => {

            if (tagDispositivo.value) {

                const dispositivoValidado = validarDispositivo(tagDispositivo.value, tagDispositivo);

                if (tagDispositivo.value) {

                    dispositivosCarregados.push({
                        ...tagDispositivo,
                        padrao: dispositivoValidado.padrao,
                        value: dispositivoValidado.value,
                        erro: dispositivoValidado.erro
                    });

                } else {

                    dispositivosCarregados.push({
                        ...tagDispositivo,
                        padrao: dispositivoValidado.padrao,
                        value: dispositivoValidado.value,
                        erro: "Campo vazio"
                    });

                }


            }


        });

        setDispositivos(dispositivosCarregados);
    }

    const onAddDispositivo = (dispositivo: DispositivoItem) => {

        const addDispositivo: TagDispositivoLegal = {
            name: dispositivo.nome,
            padrao: dispositivo.padrao,
            erro: "Campo vazio"
        }

        setDispositivos([...dispositivos, addDispositivo]);

    }

    const onRemoverUltimoDispositivo = () => {

        if (dispositivos.length >= 1) {

            const ultimo = dispositivos[dispositivos.length - 1];
            const dispositivosAtualizado = dispositivos.filter((item) => item.name !== ultimo.name);

            setDispositivos(dispositivosAtualizado);
        }

    }

    const setDispositivoData = (valorMascarado: string, dispositivo: TagDispositivoLegal, index: number) => {
        const dispositivosAtualizados = atualizarDispositivoByIndex([...dispositivos], valorMascarado, dispositivo, index);
        setDispositivos(dispositivosAtualizados);
    }


    const atualizarDispositivoByIndex = (dispositivosCopy: TagDispositivoLegal[], valorMascarado: string, dispositivo: TagDispositivoLegal, index: number) => {

        const dispositivoValidado = validarDispositivo(valorMascarado, dispositivo);

        if (dispositivoValidado) {

            const dispositivoAlterar = dispositivosCopy[index];

            if (valorMascarado) {

                dispositivosCopy[index] = {
                    ...dispositivoAlterar,
                    value: dispositivoValidado.value,
                    erro: dispositivoValidado.erro
                };
            } else {

                dispositivosCopy[index] = {
                    ...dispositivoAlterar,
                    value: dispositivoValidado.value,
                    erro: "Campo vazio"
                };
            }


        }

        return dispositivosCopy;
    }

    const buscarDispositivoByNome = (nome: string) => {
        return dispositivos.find((value) => value.name
            === nome);
    }

    const setLeiData = (value: string) => {
        if (value !== lei.value) {
            if (tipoLei.entity) {
                const leiValidada = validarLei(value, tipoLei.entity);
                leiValidada && setLei(leiValidada);
            } else {
                const leiValidada = validarLeiNumeracao(value);
                leiValidada && setLei(leiValidada);
            }
        }
    };


    const setTipoLeiData = (value: ItemSelectEntity<TipoLei>) => {
        if (value !== tipoLei) {
            if (value.entity) {
                const leiValidada = validarLei(lei.value, value.entity);
                leiValidada && setLei(leiValidada);
            } else {
                const leiValidada = validarLeiNumeracao(lei.value);
                leiValidada && setLei(leiValidada);
            }
            setTipoLei(value);
        }
    };

    const setArtigoData = (value: string) => {
        setArtigo(validarArtigo(value));
    };

    const getDispositivos = (): { dispositivos: TagDispositivoLegal[], referenciaLegal: TReferencialegal } => {

        const alinea = buscarDispositivoByNome("Alínea");
        const paragrafo = buscarDispositivoByNome("Parágrafo");
        const inciso = buscarDispositivoByNome("Inciso");
        const item = buscarDispositivoByNome("Item");

        const referenciaLegal = {
            lei: lei,
            artigo: artigo,
            paragrafo: paragrafo ? {
                value: paragrafo.value ? paragrafo.value : "",
                erro: ""
            } : {
                value: "",
                erro: ""
            },
            alinea: alinea ? {
                value: alinea.value ? alinea.value : "",
                erro: ""
            } : {
                value: "",
                erro: ""
            },
            inciso: inciso ? {
                value: inciso.value ? inciso.value : "",
                erro: ""
            } : {
                value: "",
                erro: ""
            },
            item: item ? {
                value: item.value ? item.value : "",
                erro: ""
            } : {
                value: "",
                erro: ""
            },
            tipo_lei: tipoLei
        };

        return {
            dispositivos: referenciaToArray(referenciaLegal, false),
            referenciaLegal: referenciaLegal
        };
    }

    const getQueryDispositivos = (): {
        referenciaLegal: TReferencialegal
        query: string
    } => {

        const { dispositivos, referenciaLegal } = getDispositivos();

        const filtros = new Array<TagDispositivoLegal>();

        if (tipoLei && tipoLei.id >= 1) {
            filtros.push({
                name: "TipoLei",
                value: tipoLei.id
            });
        }

        filtros.push(...dispositivos);


        const query = filtros.filter((dis) => dis.value).map((value) => {
            return getDispositivoQuery(value);
        }).join("_");

        return {
            query: query,
            referenciaLegal: referenciaLegal
        };

    }

    const getDispositivoQuery = (tagDispositivo: TagDispositivoLegal) => {

        let query = "";

        switch (tagDispositivo.name) {

            case "Lei": {

                query = `Lei ${tagDispositivo.value}`

                break;
            }
            case "art.": {

                query = `Artigo ${tagDispositivo.value}`

                break;
            }
            case "Parágrafo": {

                query = `Paragrafo ${tagDispositivo.value}`

                break;
            }
            case "Inciso": {

                query = `Inciso ${tagDispositivo.value}`

                break;
            }
            case "Alínea": {

                query = `Alinea ${tagDispositivo.value}`

                break;
            }
            case "Item": {

                query = `Item ${tagDispositivo.value}`

                break;
            }
            case "TipoLei": {

                query = `TipoLei ${tagDispositivo.value}`


            }
        }

        return query;
    }

    const validarReferenciaLegal = () => {

        let mensagemDeErro = "";

        let validado = true;

        if (dispositivos.filter((value) => value.erro).length >= 1) {
            mensagemDeErro = mensagemDeErro + " - Existe algum erro de validação em dos dispositivos do artigo \n ";
            validado = false;
        }
        if (tipoLei.id === -1) {
            mensagemDeErro = mensagemDeErro + " - Selecione o campo tipo de lei \n";
            validado = false;
        }

        if (lei.value === "" || lei.erro) {
            mensagemDeErro = mensagemDeErro + " - Preencha o campo lei corretamente \n";
            validado = false;
        }

        if (artigo.erro) {
            mensagemDeErro = mensagemDeErro + " - Existe erro de validação no campo artigo \n";
            validado = false;
        }

        if (artigo.value === "" && dispositivos.length >= 1) {
            mensagemDeErro = mensagemDeErro + " - Você deve indicar o artigo dos dispositivos \n";
            validado = false;
        }

        return {
            mensagemDeErro: mensagemDeErro,
            validado: validado
        }
    }

    return {
        lei: lei,
        artigo: artigo,
        tipoLei: tipoLei,
        dispositivos: dispositivos,
        dispositivosDisponiveis: dispositivosDisponiveis,
        callbacks: {
            getQueryDispositivos: getQueryDispositivos,
            getDispositivos: getDispositivos,
            buscarDispositivoByNome: buscarDispositivoByNome,
            carregarDispositivos: carregarDispositivos,
            onRemoverUltimoDispositivo: onRemoverUltimoDispositivo,
            setDispositivos: setDispositivos,
            setDispositivosDisponiveis: setDispositivosDisponiveis,
            setLei: setLei,
            setArtigo: setArtigo,
            setTipoLei: setTipoLei,
            setLeiData: setLeiData,
            setTipoLeiData: setTipoLeiData,
            setArtigoData: setArtigoData,
            setDispositivoData: setDispositivoData,
            validarReferenciaLegal: validarReferenciaLegal
        },
        mascaras: {
            maskArtigo: maskArtigo,
            maskDispositivo: maskDispositivo,
            maskLei: maskLei
        }
    }

}

export const referenciaToArray = (referencia: TReferencialegal, apenasDispositivos: boolean) => {

    let grupos: Array<TagDispositivoLegal> = new Array<TagDispositivoLegal>();

    if (!apenasDispositivos) {

        grupos.push({
            name: "Lei",
            value: referencia.lei.value,
            erro: referencia.lei.erro,
        });

        grupos.push({
            name: "art.",
            value: referencia.artigo.value,
            erro: referencia.artigo.erro,
        });

    }

    grupos.push({
        name: "Parágrafo",
        value: referencia.paragrafo.value,
        erro: referencia.paragrafo.erro,
    });

    grupos.push({
        name: "Inciso",
        value: referencia.inciso.value,
        erro: referencia.inciso.erro,
    });

    grupos.push({
        name: "Alínea",
        value: referencia.alinea.value,
        erro: referencia.alinea.erro,
    });

    grupos.push({
        name: "Item",
        value: referencia.item.value,
        erro: referencia.item.erro,
    });

    grupos = grupos.filter((value) => value.value);

    return grupos;
}