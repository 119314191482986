import styled from "styled-components";

export const GerenciarAutoresContent = styled.div``;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div`
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--color-primary-dark);
    color: var(--color-content-light);
    font-weight: bold;
    padding: 8px;
    transition: background-color 0.2s;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.75rem;
    margin: 4px;

    .add-autor-icon {
      color: var(--color-content-light);
      margin: 2px;
    }

    :hover {
      background-color: var(--color-primary-darking);
    }
  }

  div {
    flex-basis: 10%;
  }
`;

export const CodigoIDContent = styled.div`
  color: var(--color-primary-dark);
  font-weight: 500;
  font-size: 1rem;

  span {
    margin: 2px;
  }
`;
