import { useState } from "react";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import { ChildrenElement, ParentElement } from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { EstruturaQuestaoCXProvider } from "./context";


export const EstruturaQuestaoProvider: React.FC<{
    initialLayout: Array<ParentElement<any> | ChildrenElement<any>>
}> = ({ initialLayout, children }) => {

    const [handleDropFeature, setHandleDropFeature] = useState<{
        handleDrop: (dropZone: DropZoneData<any>, item: DropZoneData<any>) => void
    }>();

    const setHandleDropFeatureData = (handleDrop?: (dropZone: DropZoneData<any>, item: DropZoneData<any>) => void) => {

        if (handleDrop) {
            setHandleDropFeature({
                handleDrop: handleDrop
            })
        }

    }

    const [layout, setLayout] = useState<Array<ParentElement<any> | ChildrenElement<any>>>(initialLayout);

    const setLayoutData = (array: Array<ParentElement<any> | ChildrenElement<any>>) => {
        if (array !== layout) {
            setLayout(array);
        }
    }

    return (
        <EstruturaQuestaoCXProvider value={{
            layout: layout,
            setLayout: setLayoutData,
            handleDrop: handleDropFeature ? handleDropFeature.handleDrop : (dropZone, item) => { },
            setHandleDropFeatureData: setHandleDropFeatureData
        }}>
            {children}
        </EstruturaQuestaoCXProvider>
    )
}