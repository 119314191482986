import { TItemSelect } from "../../select/normal";

export const getYears = () => {
  const array = new Array<TItemSelect>();
  const year = new Date().getFullYear();

  for (var i = year; i != 1900; i--) {
    if (i == year) {
      array.push({
        id: i,
        key: "year",
        selected: true,
        useId: false,
        value: i.toString(),
      });
    } else {
      array.push({
        id: i,
        key: "year",
        selected: false,
        useId: false,
        value: i.toString(),
      });
    }
  }

  return array;
};
