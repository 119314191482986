import React from "react";
import { IconBaseProps } from "react-icons";
import { Link } from "react-router-dom";
import { ButtonBottomLess } from "../../../../../button/bottomless";
import { ThreeDotsLink } from "../../../../../menu/three-dots";
import { CardContent, CardSettings } from "./styles";

export interface TCardItem {
  optionsInLastPos?: boolean;
  settings?: CardSettings;
  header: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element;
  options: Array<React.ReactElement<Link>>;
}

export interface TCardView {
  header: JSX.Element;
  settings?: CardSettings;
  handleClick: () => void;
  buttonName?: string;
  buttonIcon: React.ComponentType<IconBaseProps>;
  options?: Array<React.ReactElement<Link>>;

}

export const CardItem: React.FC<TCardItem> = ({
  body,
  header,
  footer,
  options,
  settings,
  optionsInLastPos,
}: TCardItem) => {
  return (
    <CardContent width={settings ? settings.width : ""}>
      <div className="header-card">
        <div className="header-content">{header}</div>
        <div className="header-options">
          {options.length > 0 && (
            <ThreeDotsLink
              size={18}
              className="list-options"
              isLastPosition={
                optionsInLastPos !== undefined ? optionsInLastPos : true
              }
              options={options}
            />
          )}
        </div>
      </div>
      <div className="body-card">{body}</div>
      {footer && <div className="footer-card">{footer}</div>}
    </CardContent>
  );
};

export const CardView: React.FC<TCardView> = ({
  header,
  settings,
  handleClick,
  buttonName,
  buttonIcon,
  options
}: TCardView) => {


  return (
    <CardContent width={settings ? settings.width : ""}>
      <div className="header-card-view">
        <div className="header-content">{header}</div>
        <div className="header-options">
          <ButtonBottomLess
            className={"clear-filters"}
            title={buttonName}
            icon={buttonIcon}
            onClick={handleClick}
          />
          {options && options.length > 0 && (
            <ThreeDotsLink
              size={18}
              className="list-options"
              isLastPosition={true}
              options={options}
            />
          )}
        </div>
      </div>
    </CardContent>
  );
};
