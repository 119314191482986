import styled from "styled-components";

export const RespostaContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 0px 0px 12px 12px;
`;

export const RespostaGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  width: 100%;
  padding: 24px;
`;

export const RespostaTwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--color-content-light);
  width: 100%;

  .tipo {
    flex: 1 1 20%;
  }

  .descricao {
    flex: 1 1 80%;
  }
`;

export const RightResposta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    color: var(--color-text-grey);
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 12px;
  }

  .answer_correct {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-left: 12px;
  }

  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .comments {
      margin-left: 12px;
    }
  }
`;

export const LeftResposta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 100%;

  .content {
    max-width: 840px;
    margin-left: 24px;
  }

  .leter {
    color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 12px;
  }
`;

export const RespostaRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--color-primary-light-clear);
  box-shadow: 4px 0 5px rgb(0 0 0 / 8%);
  padding: 12px;
  margin: 12px;
`;
