import { FormEvent, Fragment, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { Modal } from '../../../../components/modals/modal-base';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ItemSelect } from '../../../../components/page/structure/FilterComponent/types';
import { SelectOption } from '../../../../components/select/option';
import { comentarioStatus } from '../../../../mocks/data';
import { APIFetch } from '../../../../services/api';
import { useComentarioService } from '../../../../services/api-services/comentarios';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { ValidacaoComentarioContent, ValidacaoComentarioFooter } from './styles';

export const ValidacaoComentario: React.FC<{}> = () => {
  const auth = useAuthContext();
  const context = useGerenciarContext();
  const services = {
    comentarioService: useComentarioService(APIFetch(auth)),
  };

  const [validacao, setValidacao] = useState<ItemSelect>({
    id: 0,
    key: '',
    useId: false,
    value: '',
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    services.comentarioService
      .validacaoComentario(context.currentAction.id, validacao.value)
      .then(() => {
        alert('Status do comentário atualizado com sucesso!');
        context.changeAction({
          action: '',
          id: 0,
          loading: true,
        });
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const Footer = () => {
    return (
      <ValidacaoComentarioFooter>
        {validacao.value && (
          <Fragment>
            <p className={'p-style'}>
              Você selecionou o status
              <small>{`(${validacao.value})`}</small>
            </p>
            <ButtonBottomLess className="save" title="Alterar status" icon={IoMdCheckmark} type={'submit'} />
          </Fragment>
        )}
      </ValidacaoComentarioFooter>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Modal
        action={context.currentActionState}
        title={`Alterar status do comentário #${context.currentAction.id}`}
        options={Footer()}>
        <ValidacaoComentarioContent>
          <div className="search-prova">
            <p className={'p-style'}>Selecione um status de validação:</p>
          </div>
          <div className="filters">
            <SelectOption
              className="validacao field-group"
              label="Status"
              onAddFilterItem={(item) => setValidacao(item)}
              getData={comentarioStatus}
            />
          </div>
        </ValidacaoComentarioContent>
      </Modal>
    </form>
  );
};
