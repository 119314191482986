import { ReactNode } from "react";
import { FilterBarContent } from "./styles";

type TFilterBar = {
  filterBar?: {
    left: ReactNode;
    right: ReactNode;
  };
};

export const FilterBar: React.FC<TFilterBar> = (props) => {
  return (
    <FilterBarContent>
      <div className="filter-left">{props.filterBar?.left}</div>
      <div className="filter-right">{props.filterBar?.right}</div>
    </FilterBarContent>
  );
};
