import React, { useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { BsThreeDots } from 'react-icons/bs';
import { Link, LinkProps } from 'react-router-dom';
import useOnClickOutside from '../../util/ClickOutside';
import { DropdrownItem } from '../styles';
import { ThreeNormalOption, TThreeNormalOption } from '../three-normal';
import { MainMenu, MenuContent, MenuHeaderText, ThreeDotsOptionContent } from './styles';
export interface TThreeDotsBase {
  size: number;
  isLastPosition: boolean;
  className: string;
  icon?: React.ComponentType<IconBaseProps>;
  text?: string;
  onOpen?: () => void;
}

export interface TThreeDotsLink extends TThreeDotsBase {
  options: Array<React.ReactElement<Link>>;
}

export interface TThreeDotsList extends TThreeDotsBase {
  options: Array<TThreeNormalOption>;
}


const ThreeDotsBase: React.FC<TThreeDotsBase> = ({ className, isLastPosition, size, icon: Icon, text, onOpen, children }) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    onOpen && onOpen();
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }

  useOnClickOutside(divRef, () => {
    setIsOpen(false);
  });

  const renderIcon = () => {
    return Icon ? (
      <Icon size={size} className={'select-icon-open'} />
    ) : (
      <BsThreeDots size={size} className={'select-icon-open'} />
    );
  };

  return (
    <MainMenu ref={divRef} onClick={open} className={className}>
      {text ? (
        <MenuHeaderText isOpen={isOpen} isLastPosition={isLastPosition}>
          {renderIcon()}
          <span>{text}</span>
        </MenuHeaderText>
      ) : (
        <MenuHeaderText isOpen={isOpen} isLastPosition={isLastPosition}>
          {renderIcon()}
        </MenuHeaderText>
      )}
      {isOpen && (
        <MenuContent isLastPosition={isLastPosition}>
          {children}
        </MenuContent>
      )}
    </MainMenu>
  );
}

export const ThreeDotsLink: React.FC<TThreeDotsLink> = (props) => {
  return (
    <ThreeDotsBase {...props}>
      {props.options.map((value: React.ReactElement<Link>, index) => (
        <DropdrownItem>
          <h5>{value}</h5>
        </DropdrownItem>
      ))}
    </ThreeDotsBase>
  );
};

export const ThreeDotsList: React.FC<TThreeDotsList> = (props) => {
  return (
    <ThreeDotsBase {...props}>
      {props.options.map((value: TThreeNormalOption, index) => (
        <DropdrownItem>
          <h5><ThreeNormalOption {...value} /></h5>
        </DropdrownItem>
      ))}
    </ThreeDotsBase>
  );
};

export interface TThreeDotsOption extends LinkProps {
  to: string;
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

export const ThreeDotsOption: React.FC<TThreeDotsOption> = ({ icon: Icon, name, ...rest }) => {
  return (
    <ThreeDotsOptionContent>
      {Icon && <Icon size={18} className={'bottom-icon'} />}
      <Link className="item-link" {...rest}>
        <h5>{name}</h5>
      </Link>
    </ThreeDotsOptionContent>
  );
};
