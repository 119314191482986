import React, { ReactNode, useEffect, useState } from "react";
import { BsListCheck } from "react-icons/bs";
import { ButtonBottomLess } from "../../../../button/bottomless";
import { Pagination } from "../../../../pagination";
import { useGerenciarContext } from "../../../GerenciarPageBase/context";
import { ListCard, MainList, PaginationContent } from "../styles";
import { PaginationOptions } from "./model/styles";

type TListComponent = {
  showFilters: boolean;
  cards: () => Array<ReactNode>;
  filterBar?: {
    left?: ReactNode;
    right?: ReactNode;
  };
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkAnyIdIsSelected: () => boolean;
  checkAll(check: boolean): void;
};

export const ListCardComponent: React.FC<TListComponent> = (props) => {
  //
  const context = useGerenciarContext();
  const [checkAll, setCheckAll] = useState(props.checkAnyIdIsSelected());

  useEffect(() => {
    setCheckAll(props.checkAnyIdIsSelected());
  }, [context.paginationData.current_page]);

  return (
    <MainList>
      <PaginationOptions>
        {context.paginationData.total_items} resultados na página atual (
        {context.paginationData.current_page + 1} -{" "}
        {context.paginationData.total_number_pages})
        {props.showFilters && (
          <ButtonBottomLess
            className={"clear-select"}
            title={checkAll ? "Desmarcar página" : "Marcar página"}
            onClick={() => {
              setCheckAll(checkAll ? false : true);
              props.checkAll(checkAll ? false : true);
            }}
            icon={BsListCheck}
          />
        )}
      </PaginationOptions>
      <ListCard>{props.cards()}</ListCard>
      <PaginationContent>
        <Pagination
          isInitialPage={context.resetPagination()}
          totalNumberPages={context.paginationData.total_number_pages}
          onCurrentPage={(page) => {
            context.toPage(page);
            context.changeLoading(true);
          }}
        />
      </PaginationContent>
    </MainList>
  );
};
