import { Fragment } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ComentarioItem } from "../../../../services/api-services/comentarios";
import { CodigoID, ComentarioValidation, LabelValue } from "../../elements";
import { BodyStyle, FooterStyle, HeaderStyle } from "./styles";

export const ComentarioCard = (
  selectedEntitiesAction: SelectedDataActions<ComentarioItem>,
  mode: string
) => {
  const ComentarioCardComponent = (
    elem: ItemDataSelect<ComentarioItem>,
    index: number
  ) => {
    const context = useGerenciarContext();

    return (
      <CardItem
        settings={{
          width: "100%",
        }}
        key={index}
        header={
          <HeaderContent
            elem={elem}
            index={index}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
        body={<BodyContent comentario={elem.entity as ComentarioItem} />}
        footer={<FooterContent comentario={elem.entity as ComentarioItem} />}
        options={[
          <ThreeNormalOption
            name="Excluir"
            onClick={() => {
              context.changeAction({
                action: "excluir",
                id: elem.entity.id,
              });
            }}
          />,
          <ThreeNormalOption
            name="Validação"
            onClick={() => {
              context.changeAction({
                action: "validar",
                id: elem.entity.id,
              });
            }}
          />,
        ]}
      />
    );
  };

  return {
    comentarioCard: ComentarioCardComponent,
  };
};

const HeaderContent: React.FC<{
  index: number;
  elem: ItemDataSelect<ComentarioItem>;
  mode: string;
  selectedEntitiesAction: SelectedDataActions<ComentarioItem>;
}> = (props) => {
  const comentario = props.elem.entity as ComentarioItem;

  return (
    <HeaderStyle>
      <CheckBox
        checked={props.selectedEntitiesAction.checkSelect(props.elem.id_item)}
        onChange={(e) => {
          props.selectedEntitiesAction.checkItem(props.index, e);
        }}
      />
      <CodigoID
        title={"Questão"}
        codigo={comentario.question_header.cod}
        id={comentario.question_header.id}
        classname={"item-margin"}
      />
      <p>{comentario.question_header.exam_header}</p>
      <ButtonBottomLess
        className={"ver-comentario"}
        title="Ver comentário"
        icon={BiCommentDetail}
        onClick={() => {
          window.open(
            `/questao/${comentario.question_header.id}/comentarios/${comentario.id}`,
            "_blank"
          );
        }}
      />
    </HeaderStyle>
  );
};

const BodyContent: React.FC<{ comentario: ComentarioItem }> = ({
  comentario,
}) => {
  return (
    <BodyStyle className={"item-margin"}>
      <div className="comentario-content ">
        <span
          className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: comentario.comment_content,
          }}
        />
      </div>
    </BodyStyle>
  );
};

const FooterContent: React.FC<{ comentario: ComentarioItem }> = ({
  comentario,
}) => {
  const nomeAutor = comentario.author
    ? comentario.author.name
    : "Não especificado";
  const nomePerfil = comentario.author
    ? comentario.author.name
    : "Não especificado";

  return (
    <Fragment>
      <FooterStyle className={"item-margin"}>
        <LabelValue label={"Comentado por"} value={nomeAutor} />
        <LabelValue label={"Perfil de questão"} value={nomePerfil} />
        <ComentarioValidation
          value={comentario.validation}
          classname={"item-margin"}
        />
      </FooterStyle>
    </Fragment>
  );
};
