import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { TButtonSelect } from "../../group-buttons";
import { BaseComponent } from "../structure/BaseComponent";
import { FilterBar } from "../structure/FilterBar";
import { FilterComponent } from "../structure/FilterComponent";
import { SelectedTagActions } from "../structure/FilterComponent/hooks/useTagAction";
import { SortItem } from "../structure/FilterComponent/types";
import { ListCardComponent } from "../structure/ListComponent/list-card";
import { ListTableComponent } from "../structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { GerenciarConsumer } from "./context";

interface GerenciarPageBaseProps<T> {
  page: {
    name: string;
    header: {
      left: ReactNode;
      right: ReactNode;
    };
    body?: {
      component: React.ReactNode;
    };
    searchPlaceHolder: string;
    searchOptions?: React.ReactNode;
    showFilters: boolean;
    showSearch?: boolean;
  };
  dataState: [
    ItemDataSelect<T>[],
    React.Dispatch<React.SetStateAction<ItemDataSelect<T>[]>>
  ];
  visualization: {
    sort: {
      params: SortItem[];
    };
    options: Array<React.ReactElement<Link>>;
    mode?: string;
    table?: {
      dataRow: Array<ReactNode>;
      dataColumn: (elem: ItemDataSelect<T>, index: number) => ReactNode;
    };
    card?: {
      dataColumn: (elem: ItemDataSelect<T>, index: number) => ReactNode;
    };
  };

  filters?: ReactNode;
  filterBar?: {
    left?: ReactNode;
    right?: ReactNode;
  };
  tagActions?: SelectedTagActions;
  selectItensActions: SelectedDataActions<T>;
  callbacks: {
    search: (text: string) => void;
    setCurrentVisualizationMode?: (current: TButtonSelect) => void;
    onCurrentSortParam: (item: string) => void;
    onCurrentOrderParam: (order: string) => void;
    setQtdItens: (qtd: string) => void;
  };
}

export function GerenciarPageBase<T>(props: GerenciarPageBaseProps<T>) {
  //Definindo estados iniciais

  const shouldShowMode = () => {
    if (props.visualization.card && props.visualization.table) {
      return true;
    }

    return false;
  };

  const filterBarRender = () => {
    if (props.filters) {
      if (props.filterBar?.left || props.filterBar?.right) {
        return (
          <FilterBar
            filterBar={{
              left: props.filterBar?.left,
              right: props.filterBar?.right,
            }}
          />
        );
      }
    }
  };

  return (
    <GerenciarConsumer
      children={(context) => {
        return (
          <BaseComponent
            pageName={props.page.name}
            headerLeft={props.page.header.left}
            headerRight={props.page.header.right}
          >
            {props.page.body && props.page.body.component}
            <FilterComponent
              sort={{
                params: props.visualization.sort.params,
              }}
              showSearch={props.page.showSearch != undefined ? props.page.showSearch : true}
              showFilters={props.page.showFilters}
              showMode={shouldShowMode()}
              mode={props.visualization.mode}
              options={props.visualization.options}
              searchOptions={props.page.searchOptions}
              searchPlaceHolder={props.page.searchPlaceHolder}
              onCurrentVisualizationMode={
                props.callbacks.setCurrentVisualizationMode
              }
              handleClearSelectedItens={() => {
                props.selectItensActions.removeAll();
              }}
              handleClearFilter={() => {
                props.selectItensActions.removeAll();
                props.tagActions && props.tagActions.removeAllTags();
                context.changeLoading(true);
              }}
              handleClickFilter={() => {
                context.changeLoading(true);
              }}
              handleSearchClick={() => {
                context.changeLoading(true);
                context.setIsNewPage(true);
                context.toPage(0);
              }}
              onCurrentSortParam={props.callbacks.onCurrentSortParam}
              onCurrentOrderParam={props.callbacks.onCurrentOrderParam}
              handleSearchValue={props.callbacks.search}
              handleQtdItens={props.callbacks.setQtdItens}
              totalSelected={props.selectItensActions.getSelectItems().length}
              filterItems={props.tagActions && props.tagActions.getTags()}
              removeFilterItem={props.tagActions && props.tagActions.removeTag}
              filters={props.filters}
            >
              {filterBarRender()}
              {props.visualization.mode == "header" ? (
                <ListTableComponent
                  filterBar={props.filterBar}
                  checkAnyIdIsSelected={
                    props.selectItensActions.checkAnyIdIsSelected
                  }
                  onCheckAll={props.selectItensActions.checkAll}
                  headerElements={props.visualization.table?.dataRow}
                  dataColumn={() => {
                    return props.dataState[0].map((elem, index) => {
                      return props.visualization.table?.dataColumn(elem, index);
                    });
                  }}
                />
              ) : (
                <ListCardComponent
                  showFilters={props.page.showFilters}
                  checkAll={props.selectItensActions.checkAllCheck}
                  filterBar={props.filterBar}
                  checkAnyIdIsSelected={
                    props.selectItensActions.checkAnyIdIsSelected
                  }
                  onCheckAll={props.selectItensActions.checkAll}
                  cards={() => {
                    return props.dataState[0].map((elem, index) => {
                      return props.visualization.card?.dataColumn(elem, index);
                    });
                  }}
                />
              )}
            </FilterComponent>
          </BaseComponent>
        );
      }}
    />
  );
}
