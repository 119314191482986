import { useEffect, useState } from "react";
import { MdDoubleArrow } from "react-icons/md";
import { Tag } from "../../../../../components/tag";
import { TReferencialegal } from "../../../../CriarQuestao/context";
import { referenciaToArray } from "../hooks/useDispositivosLegaisHook";
import { TagDispositivoLegal } from "../hooks/useDispositivosLegaisHook/types";
import { ReferenciaItemBase } from "../styles";

export interface TReferenciaLegalITagline {
    referencia: TReferencialegal;
}

export const ReferenciaLegalTagline = ({
    referencia,
}: TReferenciaLegalITagline) => {

    const [dispositivos, setDispositivos] = useState<TagDispositivoLegal[]>();

    useEffect(() => {

        const grupos = referenciaToArray(referencia, false);
        setDispositivos(grupos);

    }, [referencia]);

    return (
        <ReferenciaItemBase>
            <div className="referencia-content">
                {referencia.tipo_lei && (
                    <Tag
                        nome={referencia.tipo_lei.entity?.name}
                        title={"Classificação da Lei:"}
                        colorDark={"color-primary-light"} />
                )}
                <div className="dispositivos-legais">
                    {dispositivos?.map((dispositivo, index, array) => {
                        return (
                            <div className="dispositivo">
                                <Tag nome={dispositivo.value} title={dispositivo.name} />
                                {array.length - 1 !== index && (
                                    <MdDoubleArrow
                                        className="path-arrow"
                                        color="var(--color-primary-light)" />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </ReferenciaItemBase>
    );
};
