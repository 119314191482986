import axios, { AxiosInstance } from "axios";
import { TAuthContext } from "./auth-services/auth/contextAuth";

export const authServer = axios.create({
  baseURL: process.env.REACT_APP_AUTH,
});

export const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL + '&grant_type=authorization_code&response_type=code';

export const apiServer = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const APIFetchServer = (
  auth: TAuthContext,
  apiServer: AxiosInstance
) => {
  return Interceptors(auth, apiServer);
};

export const Interceptors = (auth: TAuthContext, apiServer: AxiosInstance) => {

  apiServer.interceptors.request.use(
    (config) => {

      if (config.headers && config.headers['Authorization'] === undefined) {
        config.headers.Authorization = `Bearer ${auth.getTkInfo().act}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiServer.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      console.log("response: " + error);

      if (error.response) {
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {

            const token = await auth.refresh();

            apiServer.defaults.headers.common["Authorization"] =
              "Bearer " + token;

            originalRequest.headers['Authorization'] = "Bearer " + token;

            return apiServer(originalRequest);

          } catch (e) {
            auth.logout();
          }

        }
      }

      return Promise.reject(error);
    }
  );

  return apiServer;
};

export const APIFileFetch = (auth: TAuthContext) => {
  return APIFetchServer(auth, authServer);
};

export const APIOauthFetch = (auth: TAuthContext) => {
  return APIFetchServer(auth, authServer);
};

export const APIFetch = (auth: TAuthContext) => {
  return Interceptors(auth, apiServer);
};

export interface RestErro {
  message: string
}

export interface RestAPIErro extends RestErro {
  status: string,
  date: string,
  erros: RestErro[]
}

export interface RestValidacaoErro extends RestErro {
  field: string
}
