import { useState } from "react";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { useTagAction } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { DivisaoData } from "../../../../services/api-services/divisoesProjeto";
import { DivisoesProjetoCXProvider, TNivel } from "./context";

export const DivisoesProjetoProvider: React.FC<{}> = ({ children }) => {
  const [nivel, setNivel] = useState<number>(0);
  const [modoGeracao, setModoGeracao] = useState<number>(0);
  const [niveis, setNiveis] = useState<Array<TNivel>>([]);
  const [divisoes, setDivisoes] = useState<
    Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  >([]);
  const parametrosOrdenacao = useTagAction();

  const [handleDropFeature, setHandleDropFeature] = useState<{
    handleDrop: (
      dropZone: DropZoneData<DivisaoData>,
      item: DropZoneData<DivisaoData>
    ) => void;
  }>();

  const setHandleDropFeatureData = (
    handleDrop?: (
      dropZone: DropZoneData<DivisaoData>,
      item: DropZoneData<DivisaoData>
    ) => void
  ) => {
    if (handleDrop) {
      setHandleDropFeature({
        handleDrop: handleDrop,
      });
    }
  };

  const setModoGeracaoData = (value: number) => {
    setModoGeracao(value);
  }

  const setNivelData = (value: number) => {
    setNivel(value);
  };

  const setNiveisData = (value: TNivel[]) => {
    setNiveis(value);
  };

  const setDivisoesData = (
    layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  ) => {
    setDivisoes(layout);
  };

  return (
    <DivisoesProjetoCXProvider
      value={{
        nivel: nivel,
        modoGeracao: modoGeracao,
        parametrosOrdenacao: parametrosOrdenacao,
        niveis: niveis,
        layout: divisoes,
        setLayout: setDivisoesData,
        callbacks: {
          setNivel: setNivelData,
          setNiveis: setNiveisData,
          setModoGeracao: setModoGeracaoData
        },
        handleDrop: handleDropFeature
          ? handleDropFeature.handleDrop
          : (dropZone, item) => { },
        setHandleDropFeatureData: setHandleDropFeatureData,
      }}
    >
      {children}
    </DivisoesProjetoCXProvider>
  );
};
