import { AxiosInstance } from "axios";
import {
  ChildrenElement,
  ParentElement,
} from "../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import {
  ItemSelect,
  ItemsSelectPage,
} from "../../../components/page/structure/FilterComponent/types";
import { PaginationData } from "../../../components/pagination/types";
import { ImageInfoDetails, Service } from "../../service";
import { Autor } from "../autores";
import { DivisaoData } from "../divisoesProjeto";
import { EstiloExportacao } from "../estiloExportacao";
import { EstruturaExportacao } from "../estruturaExportacao";

export class ProjetoService extends Service {
  private ENDPOINT = "project";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listagemProjeto(
    page: number,
    qtd: string,
    filters: string[],
    search: string,
    sort: string,
    order: string
  ): Promise<ProjetosPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=search_key:'*${search}*';author:'${filters[0]}';year:'${filters[1]}'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let projetos = new Array<ProjetoResume>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        projetos = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: projetos,
          paginationData: pagination,
        };
      });
  }

  async buscarProjeto(id: number): Promise<ProjetoDetails> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async listarProjetos(
    page: number,
    qtd: number,
    nome: string
  ): Promise<ItemsSelectPage> {
    return this.fetch
      .get(
        this.ENDPOINT +
          `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=name&order=asc`
      )
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: ProjetoResume) =>
          array.push({
            id: value.id,
            value: value.name,
            key: "project",
            useId: true,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async salvarProjeto(projeto: ProjetoRequest): Promise<ProjetoDetails> {
    return this.fetch
      .post(this.ENDPOINT, projeto, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarProjeto(projeto: ProjetoUpdate): Promise<void> {
    return this.fetch.put(this.ENDPOINT + `/${projeto.id}`, projeto, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async excluirProjeto(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useProjetoService = (axios: AxiosInstance) => {
  return new ProjetoService(axios);
};

export type ProjetosPagination = {
  content: Array<ProjetoResume>;
  paginationData: PaginationData;
};

export interface ProjetoResume {
  id: number;
  name: string;
  year: string;
  visibility: string;
  current_username: string;
}

export interface ProjetoDetails {
  id: number;
  name: string;
  visibility: string;
  image: ImageInfoDetails;
  year: string;
  configuration: {
    division_levels: string[];
  };
  export_style: EstiloExportacao;
  export_structure: EstruturaExportacao;
  authors: AutorProjetoDetails[];
}

export interface AutorProjetoDetails {
  id: number;
  id_project: number;
  author: Autor;
}

export interface ProjetoRequest {
  id?: number;
  name: string;
  year: string;
  visibility: string;
  divisions: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>;
  configuration: {
    division_levels: {
      sort: SortData[];
      levels: NivelData[];
    };
  };
  selected_export_style_id?: number;
  selected_export_structure_id?: number;
  authors: AutorSelect[];
}

export interface SortData {
  order: string;
  parameter: string;
}

export interface NivelData {
  type: string;
  metadado: string;
}

export interface ProjetoUpdate {
  id?: number;
  name: string;
  year: string;
  visibility: string;
  selected_export_style_id?: number;
  selected_export_structure_id?: number;
  authors: AutorSelect[];
}

export interface AutorSelect {
  selected_author: number;
}
