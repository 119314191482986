import { Dispatch, FC, SetStateAction } from "react";
import {
  GroupButtons,
  TButtonSelect
} from "../../../../components/group-buttons";
import { Modal } from "../../../../components/modals/modal-base";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { TipoPremissa } from "../../../../mocks/data";
import { useQuestaoContext } from "../../context";
import { TipoRespostaContent } from "./styles";

export const TipoResposta: FC<{
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
}> = (props) => {
  const [action, setAction] = props.action;
  const questaoContext = useQuestaoContext();

  const atribuirTipoResposta = (tipoResposta: string) => {
    const tipo = questaoContext.questao.tipoResposta;

    if (questaoContext.idQuestao) {
      if (tipo === "Sem resposta") {
        questaoContext.atribuirTipoRespostaAPI(tipoResposta);
      }
    } else {
      questaoContext.callbacks.setTipoResposta(tipoResposta);
    }
  };

  const renderOptions = () => {
    let options: TButtonSelect[] = [];

    if (questaoContext.questao.tipo === "Dissertativa") {
      options = [
        {
          onClick: () => {
            atribuirTipoResposta("Padrão");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Padrão resposta",
          classname: "status-option",
          value: "Padrão",
          minWidth: 200,
        },
      ];
    } else {
      options = [
        {
          onClick: () => {
            atribuirTipoResposta("Múltipla Escolha");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Múltipla escolha",
          classname: "status-option",
          value: "escolha",
          minWidth: 400,
        },
        {
          onClick: () => {
            atribuirTipoResposta("Relacional");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Relacional",
          classname: "status-option",
          value: "relacional",
          minWidth: 400,
        },
        {
          onClick: () => {
            questaoContext.callbacks.setTipoPremissa(TipoPremissa.CR);
            atribuirTipoResposta("Única");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Única premissa - Certo/Errado",
          classname: "status-option",
          value: "Única",
          minWidth: 400,
        },
        {
          onClick: () => {
            atribuirTipoResposta("Certo/Errado");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Múltiplas premissas - Certo/Errado",
          classname: "status-option",
          value: "certoErrado",
          minWidth: 400,
        },

        {
          onClick: () => {
            questaoContext.callbacks.setTipoPremissa(TipoPremissa.VF);
            atribuirTipoResposta("Única");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Única premissa - Verdadeiro/Falso",
          classname: "status-option",
          value: "Única",
          minWidth: 400,
        },
        {
          onClick: () => {
            atribuirTipoResposta("Verdadeiro/Falso");

            setAction({
              id: 0,
              action: "respostas",
            });
          },
          name: "Múltiplas premissas - Verdadeiro/Falso",
          classname: "status-option",
          value: "verdadeiroFalso",
          minWidth: 400,
        },
      ];
    }

    return options;
  };

  return (
    <Modal
      action={props.action}
      title={"Escolha um formato de respostas"}
      options={[]}
    >
      <TipoRespostaContent>
        <GroupButtons
          initialPositionSelected={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          direction="column"
          buttons={renderOptions()}
        />
      </TipoRespostaContent>
    </Modal>
  );
};
