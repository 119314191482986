import styled from "styled-components";

export const CheckboxItem = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  .b-contain *,
  .b-contain *::before,
  .b-contain *::after {
    box-sizing: content-box !important;
  }

  .b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .b-contain span {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 0.8rem;
    margin-right: 18px;
    margin-left: 18px;
  }

  .b-contain {
    position: relative;
    padding-left: 1.8rem;
    cursor: pointer;
  }

  .b-contain input[type="checkbox"] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background: rgba(255, 255, 255, 1);
    transition: background 250ms;
    border: 1px solid rgba(255, 222, 222, 1);
    border-radius: 0.333rem;
  }

  .b-contain input[type="radio"] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background: rgba(255, 255, 255, 1);
    transition: background 250ms;
    border: 1px solid rgba(255, 222, 222, 1);
    border-radius: 2rem;
  }

  .b-contain input[type="checkbox"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 0.45rem;
    top: 0.18rem;
    width: 0.25rem;
    height: 0.6rem;
    border: solid rgba(255, 255, 255, 1);
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }

  .b-contain input[type="radio"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 0.25rem;
    top: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 1);
    transition: background 250ms;
  }

  .b-contain input:disabled ~ .b-input::after {
    border-color: rgba(135, 149, 161, 1);
  }

  .b-contain input:checked ~ .b-input::after {
    display: block;
  }

  .b-contain:hover input ~ .b-input,
  .b-contain input:focus ~ .b-input {
    background: rgba(255, 240, 240, 1);
  }

  .b-contain input:focus ~ .b-input {
    box-shadow: 0 0 0 2px rgba(255, 148, 148, 1);
  }

  .b-contain input:checked ~ .b-input {
    background: rgba(255, 101, 101, 1);
    border-color: rgba(255, 222, 222, 1);
  }

  .b-contain input[type="checkbox"]:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
  }

  .b-contain input[type="radio"]:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
  }

  .b-contain input[type="radio"]:disabled ~ .b-input::after {
    background: rgba(135, 149, 161, 1);
  }

  .b-contain input:checked:focus ~ .b-input,
  .b-contain:hover input:not([disabled]):checked ~ .b-input {
    background: rgba(255, 101, 101, 1);
    border-color: rgba(255, 101, 101, 1);
  }

  .b-contain .b-input::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    margin-left: -0.85rem;
    margin-top: -2.686rem;
    background: rgba(255, 240, 240, 1);
    border-radius: 2rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }

  @keyframes b-ripple {
    0% {
      transform: scale(0);
    }

    20% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @keyframes b-ripple-duplicate {
    0% {
      transform: scale(0);
    }

    30% {
      transform: scale(1);
    }

    60% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .b-contain input + .b-input::before {
    animation: b-ripple 250ms ease-out;
  }

  .b-contain input:checked + .b-input::before {
    animation-name: b-ripple-duplicate;
  }

  .b-contain .b-input::before {
    visibility: hidden;
  }

  .b-contain input:focus + .b-input::before {
    visibility: visible;
  }

  .b-contain:first-child .b-input::before {
    visibility: hidden;
  }
`;
