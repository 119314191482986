import styled from "styled-components";

export const VisualizarQuestaoContent = styled.div``;

export const QuestaoOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: var(--color-content-light);
  padding: 12px;
  height: 54px;
`;

export const QuestaoHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  flex-wrap: wrap;
  background-color: var(--color-content-light);
  padding: 12px;
`;

export const QuestaoViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-content-light);
  padding: 24px;
  border-radius: 0px 0px 12px 12px;
`;

export const ComentarioOptions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .option-margin {
    margin-left: 24px;
  }
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;

export const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 12px;

  .exam-header {
    margin: 12px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-text-grey);
  }

  .statement_question {
    margin: 12px;
  }

  .tags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .projetos {
    margin-top: 12px;
    padding: 12px;
    font-weight: bold;
    color: var(--color-primary-dark);

    .projeto-link {
      margin-left: 12px;
      color: var(--color-primary-light);
      font-size: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-light);
    }
  }
`;

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;
