import { useEffect, useState } from "react";

export interface ItemDataSelect<T> {
  isChecked: boolean;
  id_item: number;
  entity: T;
}

export interface SelectedDataActions<T> {
  checkItem(index: number, event: React.ChangeEvent<HTMLInputElement>): void;
  checkAll(event: React.ChangeEvent<HTMLInputElement>): void;
  getSelectItems(): ItemDataSelect<T>[];
  checkSelect(id: number): boolean;
  removeAll(): void;
  checkAnyIdIsSelected(): boolean;
  checkAllCheck(check: boolean): void;
}

interface TSelectedDataAction<T> {
  state: [
    ItemDataSelect<T>[],
    React.Dispatch<React.SetStateAction<ItemDataSelect<T>[]>>
  ];
}

export const useSelectedDataAction = <T>(
  props: TSelectedDataAction<T>
): SelectedDataActions<T> => {
  const itens = props.state[0];
  const setItens = props.state[1];

  const [selectedItens, setSelectedItens] = useState<Array<ItemDataSelect<T>>>(
    []
  );

  function handleCheckItem(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const isCheck = event.target.checked;
    const item = itens[index];
    const found = selectedItens.find((ele) => ele.id_item === item.id_item);

    if (found) {
      if (!isCheck) {
        setSelectedItens(
          selectedItens.filter((elem) => elem.id_item !== item.id_item)
        );
      }
    } else {
      setSelectedItens([...selectedItens, item]);
    }

    item.isChecked = event.target.checked;
  }

  function checkAnyIdIsSelected() {
    let result = false;

    for (var i = 0; i < itens.length; i++) {
      const find = selectedItens.find((ele) => ele.id_item == itens[i].id_item);

      if (find) {
        result = true;
        break;
      }
    }

    return result;
  }

  function handleCheckAll(event: React.ChangeEvent<HTMLInputElement>) {
    checkAll(event.target.checked);
  }

  function handleRemoveAll() {
    checkAll(false);
    setSelectedItens([]);
  }

  function checkAll(status: boolean) {
    const isCheckAll = status;
    const temp: Array<ItemDataSelect<T>> = [];
    const remove: Array<ItemDataSelect<T>> = [];

    const itensTmp = itens.map((item: ItemDataSelect<T>) => {
      const found = selectedItens.find((ele) => ele.id_item === item.id_item);

      if (!found) {
        if (!isCheckAll) {
          setSelectedItens(
            selectedItens.filter((elem) => elem.id_item !== item.id_item)
          );
        } else {
          temp.push(item);
        }
      } else {
        remove.push(item);
      }

      item.isChecked = isCheckAll;
      return item;
    });

    setItens(itensTmp);

    if (!isCheckAll) {
      const tmp = selectedItens.filter((elem) => {
        const found = remove.find((ele) => ele.id_item === elem.id_item);
        return found ? false : true;
      });

      setSelectedItens(tmp);
    } else {
      setSelectedItens([...selectedItens, ...temp]);
    }
  }

  function checkSelect(id: number) {
    const result = selectedItens.find((ele) => ele.id_item == id);

    if (result) {
      return result.isChecked;
    } else {
      return false;
    }
  }

  function getSelectItems() {
    return selectedItens;
  }

  return {
    checkItem: handleCheckItem,
    checkAll: handleCheckAll,
    getSelectItems: getSelectItems,
    checkSelect: checkSelect,
    removeAll: handleRemoveAll,
    checkAnyIdIsSelected: checkAnyIdIsSelected,
    checkAllCheck: checkAll,
  };
};
