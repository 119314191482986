import styled from 'styled-components';

export const AnexarProvaContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .search-prova {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 8px 24px 8px;
    padding: 8px;
    flex-wrap: wrap;

    .search {
      margin-left: 12px;
      width: 300px;
    }
  }

  .pagination {
    justify-content: flex-end;
  }

  .list-prova {
    height: 100%;
    overflow: auto;
  }
`;

export const ProvaItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-select-border);
  padding: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
  p {
    font-weight: 500;
    font-size: 0.9 rem;
    color: var(--color-text-grey);
  }
`;

export const ProvaFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 100%;

  p {
    width: 600px;
    font-size: 0.9rem;
    small {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-primary-dark);
    }
  }
`;
