import React, { Dispatch, Fragment, SetStateAction } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { ActionType } from "../../page/GerenciarPageBase/types";
import { MenuTitle, SidebarModalContent } from "./styles";

export interface TSidebarModal {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  title: string;
}

export const SidebarModal: React.FC<TSidebarModal> = (props) => {
  const action = props.action[0];
  const setAction = props.action[1];

  return (
    <Fragment>
      <SidebarModalContent>
        <MenuTitle>
          <h3>{props.title}</h3>
          <VscChromeClose
            onClick={() => {
              setAction({
                action: "",
                id: 0,
              });
            }}
            size={22}
            style={{
              color: "var(--color-primary-darking)",
              cursor: "pointer",
            }}
          />
        </MenuTitle>
        <div>{props.children}</div>
      </SidebarModalContent>
    </Fragment>
  );
};
