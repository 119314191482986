import { FormEvent, Fragment, useEffect, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { VscSave } from 'react-icons/vsc';
import { ButtonBottomLess } from '../../../../components/button/bottomless';
import { ButtonNormal } from '../../../../components/button/normal';
import { InputForm } from '../../../../components/input';
import { SidebarModal } from '../../../../components/modals/sidebar-modal';
import { SidebarForm } from '../../../../components/modals/sidebar-modal/styles';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { ActionType } from '../../../../components/page/GerenciarPageBase/types';
import { APIFetch } from '../../../../services/api';
import { CargoService, useCargoService } from '../../../../services/api-services/cargo';
import { useAuthContext } from '../../../../services/auth-services/auth/contextAuth';
import { FormCriarCargo } from './styles';

export interface TCriarCargo {
  externalAction?: [ActionType, React.Dispatch<React.SetStateAction<ActionType>>];
  isModal: boolean;
}

export const CriarCargo: React.FC<TCriarCargo> = ({ isModal, externalAction }) => {
  const auth = useAuthContext();

  const services = {
    cargoService: useCargoService(APIFetch(auth)),
  };

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [action, setAction] = actionState;

  const [nome, setNome] = useState('');
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (action.action == 'editar-cargo') {
      editarCargoAPI(nome, services.cargoService, action, setAction, changeAction);
    } else if (action.action == 'criar-cargo') {
      salvarCargoAPI(nome, services.cargoService, action, setAction, changeAction);
    }
  };

  const editarCargoAPI = (
    nome: string,
    orgaoService: CargoService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    orgaoService
      .editarCargo({
        id: action.id,
        name: nome,
      })
      .then((response) => {
        const dispatchAction = {
          ...action,
          loading: true,
        };

        if (!setAction) {
          changeAction(dispatchAction);
        } else {
          setAction(dispatchAction);
        }

        alert('Cargo salvo com sucesso!');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const salvarCargoAPI = (
    nome: string,
    orgaoService: CargoService,
    action: ActionType,
    setAction: (value: React.SetStateAction<ActionType>) => void,
    changeAction: (action: ActionType) => void
  ) => {
    orgaoService
      .salvarCargo({
        name: nome,
      })
      .then((response) => {
        const dispatchAction = {
          ...action,
          loading: true,
        };

        if (!setAction) {
          changeAction(dispatchAction);
        } else {
          setAction(dispatchAction);
        }

        alert('Cargo salvo com sucesso!');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {
    action.action == 'editar-cargo' &&
      services.cargoService.buscarCargo(action.id).then((Cargo) => {
        setNome(Cargo.name);
      });
  }, []);

  const renderContent = (isModal: boolean) => {
    return (
      <Fragment>
        <label>Nome</label>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        {isModal ? (
          <ButtonNormal icon={VscSave} title={'Salvar'} className={'save'} type={'submit'} />
        ) : (
          <ButtonBottomLess icon={IoMdCheckmark} title={'Salvar cargo'} className={'save'} type={'submit'} />
        )}
      </Fragment>
    );
  };

  const renderForm = () => {
    return (
      <Fragment>
        {isModal ? (
          <SidebarForm onSubmit={onSubmit}>{renderContent(isModal)}</SidebarForm>
        ) : (
          <FormCriarCargo onSubmit={onSubmit}>{renderContent(isModal)}</FormCriarCargo>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isModal ? (
        <SidebarModal
          action={actionState}
          title={action.action == 'criar-cargo' ? 'Novo Cargo' : `Editar Cargo #${action.id}`}>
          {renderForm()}
        </SidebarModal>
      ) : (
        <Fragment> {renderForm()} </Fragment>
      )}
    </Fragment>
  );
};
