import styled from "styled-components";

export const SelecaoImagemStyle = styled.div`
  .selecao-imagem {
    display: flex;
    flex-direction: column;

    .aviso-file {
      margin-top: 12px;
      font-weight: 500;
      text-transform: none;
      font-size: 0.9 rem;
      color: var(--color-text-grey);
      margin-right: 18px;
      margin-left: 18px;
    }

    .nome-arquivo {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      margin-left: 18px;
      margin-right: 18px;

      small {
        color: var(--color-text-light);
      }
    }

    .importar-button {
      align-items: center;
      width: 240px;
      flex-direction: column;
      border-width: 4px;
      border-color: var(--color-select-border);
      border-style: dashed;
      border-radius: 12px;
      padding: 12px;
      margin-left: 18px;
      height: 82px;
    }
  }
`;
