import React, { Dispatch, SetStateAction } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { ActionType } from "../../page/GerenciarPageBase/types";
import {
  Background,
  MenuFooter,
  MenuTitle,
  SidebarModalContent,
} from "./styles";

export interface TModalOption {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  title: string;
  options: React.ReactNode;
}

export const ModalOption: React.FC<TModalOption> = (props) => {
  const action = props.action[0];
  const setAction = props.action[1];

  return (
    <Background>
      <SidebarModalContent>
        <MenuTitle>
          <h3>{props.title}</h3>
          <VscChromeClose
            onClick={() => {
              setAction({
                action: "",
                id: 0,
              });
            }}
            size={22}
            style={{
              color: "var(--color-sidebar-header)",
              cursor: "pointer",
            }}
          />
        </MenuTitle>
        <div className="content-modal">{props.children}</div>
        <MenuFooter>{props.options}</MenuFooter>
      </SidebarModalContent>
    </Background>
  );
};
