import { useEffect, useState } from "react";
import { ItemSelect } from "../types";


export interface SelectedTagActions {
  setInitialValues: (itens: ItemSelect[]) => void;
  setObjectByTagValue(tag: ItemSelect, object: any): void;
  addTag: (item: ItemSelect) => void;
  addTagValue: (value: string, key: string, object?: any, render?: (item?: ItemSelect) => React.ReactNode) => void;

  setTagValue: (value: string, key: string, object?: any, render?: (item?: ItemSelect) => React.ReactNode) => void;
  addAllTags: (item: ItemSelect[]) => void;
  removeTag: (item: ItemSelect) => void;
  removeAllTags: () => void;
  getTags: () => ItemSelect[];
  getFilters: (keys: string[]) => string[];
  getFilter: (key: string) => string[];

  getTagsString: (delimitador: string) => string;
}


export const useTagAction = (onChangedValues?: (values: ItemSelect[]) => void) => {

  const INITIAL_STATE = [{
    id: -1,
    key: "initial",
    useId: false,
    value: "",
  }
  ];

  const [initialItens, setInitialItens] = useState<ItemSelect[]>(INITIAL_STATE);

  const [filterItems, setFilterItems] = useState<Array<ItemSelect>>([]);

  useEffect(() => {
    onChangedValues && onChangedValues(filterItems);
  }, [filterItems]);

  useEffect(() => {
    if (initialItens != INITIAL_STATE) {
      if (initialItens.length >= 1 && initialItens !== filterItems) {
        setFilterItems([...initialItens]);
      }
    }
  }, [initialItens]);


  function handleAddItem(item: ItemSelect) {

    const found = filterItems.find((ele) => {
      if (ele.id === item.id && ele.key === item.key) {
        return ele;
      }
    });

    if (!found) {
      setFilterItems([...filterItems, item]);
    }
  }

  function setObjectByTagValue(tag: ItemSelect, object: any) {

    const tmp = [...filterItems];

    const found = tmp.findIndex((value) => value.id === tag.id);

    if (found !== -1) {

      tmp[found] = {
        ...tag,
        object: object
      };

      setFilterItems([...tmp]);

    }


  }

  function addAllTags(tags: ItemSelect[]) {
    setFilterItems(tags);
  }

  function addTagValue(value: string, key: string, object?: any, render?: (item?: ItemSelect) => React.ReactNode) {

    const item: ItemSelect = {
      id: -1,
      key: key,
      value: value,
      useId: false,
      object: object,
      render: render
    };

    const found = filterItems.find((ele) => {
      if (ele.value === item.value && ele.key === item.key) {
        return ele;
      }
    });

    if (!found) {
      setFilterItems([...filterItems, item]);
    }
  }

  function setTagValue(value: string, key: string, object?: any, render?: (item?: ItemSelect) => React.ReactNode) {

    const item: ItemSelect = {
      id: -1,
      key: key,
      value: value,
      useId: false,
      object: object,
      render: render
    };

    const found = filterItems.find((elem) => elem.key === item.key);

    if (found) {
      const filter = filterItems.filter((elem) => elem.key !== item.key);

      if (item.value.length > 0) {
        setFilterItems([...filter, item]);
      } else {
        setFilterItems([...filter]);
      }
    } else {
      setFilterItems([...filterItems, item]);
    }
  }

  function handleRemoveItem(item: ItemSelect) {
    if (item.id === -1) {
      setFilterItems(filterItems.filter((elem) => elem.key !== item.key));
    } else {
      setFilterItems(filterItems.filter((elem) => elem.value !== item.value));
    }
  }

  function setInitialValues(itens: ItemSelect[]) {
    if (itens) {
      if (itens !== INITIAL_STATE) {
        if (itens !== initialItens) {
          setInitialItens(itens);
        }
      }
    }
  }

  function getFilterItens() {
    return filterItems.filter((value) => value.key !== "initial");
  }

  function removeAllTags() {
    setFilterItems([]);
  }

  function getFilters(keys: string[]) {
    let filters = new Array<any>();

    keys.forEach((value, index) => {
      let filterRow = new Array<any>();

      filterItems
        .filter((ele) => ele.key === value)
        .forEach((ele, index) => {
          if (ele.value !== "") {
            if (ele.useId) {
              filterRow.push(ele.id);
            } else {
              filterRow.push(ele.value);
            }
          }
        });

      filters[index] = filterRow.join(",");
    });

    return filters;
  }

  function getTagsString(delimitador: string) {
    return filterItems.map((item) => item.value).join(delimitador);
  }

  function getFilter(key: string) {

    let filters = new Array<any>();
    let filterRow = new Array<any>();

    filterItems
      .filter((ele) => ele.key === key)
      .forEach((ele, index) => {
        if (ele.value !== "") {
          if (ele.useId) {
            filterRow.push(ele.id);
          } else {
            filterRow.push(ele.value);
          }
        }
      });

    filters[0] = filterRow.join(",");

    return filters;
  }

  const data: SelectedTagActions = {
    setInitialValues: setInitialValues,
    setObjectByTagValue: setObjectByTagValue,
    setTagValue: setTagValue,
    addTagValue: addTagValue,
    addTag: handleAddItem,
    addAllTags: addAllTags,
    removeTag: handleRemoveItem,
    removeAllTags: removeAllTags,
    getTags: getFilterItens,
    getFilters: getFilters,
    getFilter: getFilter,
    getTagsString: getTagsString,
  };

  return data;
};