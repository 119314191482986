import React, {
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDebounce as debounce } from "use-debounce";
import { ItemTypes } from "../drag-and-drop/hooks/useLayoutDragSystem";
import { PaginationContent } from "./styles";

interface TPagination {
  totalNumberPages: number;
  isInitialPage: boolean;
  onCurrentPage: (page: number) => void;
  className?: string;
  nextRef?: React.RefObject<HTMLAnchorElement>;
  previousRef?: React.RefObject<HTMLAnchorElement>;
  numberRef?: React.RefObject<HTMLLIElement>;
}

interface TPaginationDragging<T> extends TPagination { }

export const PaginationDropping = <T extends unknown>({
  isInitialPage,
  onCurrentPage,
  totalNumberPages,
  className,
}: PropsWithChildren<TPaginationDragging<T>>) => {
  const previousRef = useRef<HTMLAnchorElement>(null);
  const numberRef = useRef<HTMLLIElement>(null);
  const nextRef = useRef<HTMLAnchorElement>(null);

  const nextDrop = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    hover: (item: unknown, monitor: DropTargetMonitor<unknown, unknown>) => {
      nextRef.current?.click();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  nextDrop[1](nextRef);

  const previousDrop = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    hover: (item: unknown, monitor: DropTargetMonitor<unknown, unknown>) => {
      previousRef.current?.click();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  previousDrop[1](previousRef);

  const numberDrop = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    hover: (item: unknown, monitor: DropTargetMonitor<unknown, unknown>) => {
      numberRef.current?.click();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  numberDrop[1](numberRef);

  return (
    <Pagination
      nextRef={nextRef}
      previousRef={previousRef}
      numberRef={numberRef}
      isInitialPage={isInitialPage}
      totalNumberPages={totalNumberPages}
      onCurrentPage={onCurrentPage}
      className={className}
    />
  );
};

export const Pagination: React.FC<TPagination> = (props) => {
  const total_number_pages = props.totalNumberPages;
  const maxPage = total_number_pages < 5 ? total_number_pages : 5;
  const totalIndex = Math.round(total_number_pages / maxPage);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [countPointer, setCountPointer] = useState(1);

  const [debouncedPointer] = debounce(countPointer, 1200);

  useEffect(() => {
    props.onCurrentPage(debouncedPointer);
  }, [debouncedPointer]);

  useEffect(() => {
    if (props.isInitialPage) {
      setCurrentIndex(0);
      setCountPointer(1);
    }
  }, [props.isInitialPage]);

  const previous = () => {
    const initial = maxPage * currentIndex + 1;

    if (debouncedPointer > initial) {
      setCountPointer(Math.round(debouncedPointer - 1));
    } else {
      if (currentIndex >= 1) {
        setCurrentIndex(Math.round(currentIndex - 1));
      }

      if (debouncedPointer > 1) {
        setCountPointer(Math.round(debouncedPointer - 1));
      }
    }
  };

  const next = () => {
    const initial = maxPage * currentIndex + 1;
    const last = initial + maxPage;

    if (debouncedPointer < last - 1) {
      setCountPointer(Math.round(debouncedPointer + 1));
    } else {
      if (currentIndex < totalIndex - 1) {
        setCurrentIndex(Math.round(currentIndex + 1));
      }

      if (debouncedPointer < total_number_pages) {
        setCountPointer(Math.round(debouncedPointer + 1));
      }
    }
  };

  const selectPage = (index: number) => {
    if (index == total_number_pages) {
      setCurrentIndex(Math.round(totalIndex - 1));
    } else if (index == 1) {
      setCurrentIndex(0);
    }

    setCountPointer(Math.round(index));
  };

  const getNumbers = () => {
    const numbersArray = new Array<number>();
    const initial = maxPage * currentIndex + 1;
    const last = initial + maxPage;

    for (var i = initial; i < last; i++) {
      numbersArray.push(i);
    }

    return numbersArray;
  };

  return (
    <PaginationContent className={props.className}>
      <ul>
        {props.previousRef ? (
          <a onClick={previous} ref={props.previousRef}>
            <FaChevronLeft size={16} />
          </a>
        ) : (
          <a onClick={previous}>
            <FaChevronLeft size={16} />
          </a>
        )}
        <li
          onClick={() => {
            selectPage(1);
          }}
        >
          1
        </li>
        <li className="no-number">...</li>
        <ul>
          {getNumbers().map((elem) => {
            if (elem == debouncedPointer) {
              return (
                <li key={elem} className="page-selected">
                  {elem}
                </li>
              );
            } else {
              if (props.numberRef) {
                return (
                  <li
                    ref={props.numberRef}
                    onClick={() => {
                      selectPage(elem);
                    }}
                    key={elem}
                  >
                    {elem}
                  </li>
                );
              } else {
                return (
                  <li
                    onClick={() => {
                      selectPage(elem);
                    }}
                    key={elem}
                  >
                    {elem}
                  </li>
                );
              }
            }
          })}
        </ul>
        <li className="no-number">...</li>
        <li
          onClick={() => {
            selectPage(total_number_pages);
          }}
        >
          {total_number_pages}
        </li>
        {props.nextRef ? (
          <a onClick={next} ref={props.nextRef}>
            <FaChevronRight size={16} />
          </a>
        ) : (
          <a onClick={next}>
            <FaChevronRight size={16} />
          </a>
        )}
      </ul>
    </PaginationContent>
  );
};
