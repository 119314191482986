import { CheckBox } from '../../../../components/checkbox';
import { ThreeDotsLink } from '../../../../components/menu/three-dots';
import { ThreeNormalOption } from '../../../../components/menu/three-normal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { RowItem } from '../../../../components/page/structure/ListComponent/list-table';
import {
  ItemDataSelect,
  SelectedDataActions
} from '../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction';
import { ListTableRow } from '../../../../components/page/structure/ListComponent/styles';
import { Tema } from '../../../../services/api-services/tema';

export const TemaHeader = (selectedEntitiesAction: SelectedDataActions<Tema>) => {
  const TemaRow = (elem: ItemDataSelect<Tema>, index: number) => {
    const tema = elem.entity as Tema;
    const context = useGerenciarContext();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{tema.id}</RowItem>
        <RowItem>{tema.name}</RowItem>
        <RowItem>{tema.subject.name}</RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: 'editar',
                    id: tema.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: 'excluir',
                    id: tema.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    temaHeader: TemaRow,
  };
};
