import {
    ItemDataSelect,
    ListTableKit,
    useSelectedDataAction,
} from "jusadmin-ui-kit";
import { Fragment, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCheckAll } from "react-icons/bs";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { SectionText } from "../../../../components/section-text";
import { SubMenuTitle } from "../../../../components/sub-menu-title";
import { QuestaoInfo } from "../../../../services/api-services/questao";
import { QuestaoInfoItem } from "./ItemTable";
import {
    PosExclusaoQuestoesModalStyles,
    QuestoesNaoExcluidasContent,
} from "./styles";

const { ListTableComponent } = ListTableKit;

interface PosExclusaoQuestoesStateProps {
    questoes: Set<QuestaoInfo>;
}

export const PosExclusaoQuestoesModal = () => {

    const context = useGerenciarContext();

    const dataState: PosExclusaoQuestoesStateProps | undefined = context
        .currentAction.object as unknown as PosExclusaoQuestoesStateProps;

    const questoesNaoExcluidasState = useState<
        Array<ItemDataSelect<QuestaoInfo>>
    >(questaoInfoToItemDataSelect(dataState ? dataState.questoes : new Set()));

    const [questoesNaoExcluidas, setQuestoesNaoExcluidas] =
        questoesNaoExcluidasState;

    const questoesSelectHook = useSelectedDataAction<QuestaoInfo>({
        state: questoesNaoExcluidasState,
    });

    return (
        <Modal
            action={context.currentActionState}
            title={"Exclusão de questões"}
            width={questoesNaoExcluidas.length >= 1 ? 800 : 316}
            options={<Fragment />}
        >
            <PosExclusaoQuestoesModalStyles>
                <QuestoesNaoExcluidasContent>
                    {questoesNaoExcluidas.length >= 1 ? (
                        <Fragment>
                            <SectionText
                                title="Questões parcialmente excluídas"
                                icon={AiOutlineInfoCircle}
                            />
                            <SubMenuTitle
                                title={`${questoesNaoExcluidas.length} questões selecionadas não puderam ser excluídas devido ao seu vínculo com projetos.\nVocê encontrará a lista completa das questões não deletadas na tabela abaixo.`}
                                styles={{
                                    titleBar: {
                                        justifyContent: "center",
                                    },
                                }}
                            >
                                <ListTableComponent
                                    checkAnyIdIsSelected={questoesSelectHook.checkAnyIdIsSelected}
                                    disablePagination
                                    headerElements={[
                                        <div>ID</div>,
                                        <div>Cód.</div>,
                                        <div>Enunciado</div>,
                                    ]}
                                    className={"main-table"}
                                    tableClassName={"list-table"}
                                    onCheckAll={questoesSelectHook.checkAll}
                                    dataColumn={() => {
                                        return questoesNaoExcluidasState[0].map((elem, index) => {
                                            return QuestaoInfoItem().render(elem, index);
                                        });
                                    }}
                                />
                            </SubMenuTitle>
                        </Fragment>
                    ) : (
                        <SectionText
                            title="Questões excluídas com sucesso"
                            icon={BsCheckAll}
                        />
                    )}
                </QuestoesNaoExcluidasContent>
            </PosExclusaoQuestoesModalStyles>
        </Modal>
    );
};

export function questaoInfoToItemDataSelect(
    questoes: Set<QuestaoInfo>
): Array<ItemDataSelect<QuestaoInfo>> {
    const arrayResultado: Array<ItemDataSelect<QuestaoInfo>> = [];

    const iterator = questoes.values();
    let next = iterator.next();

    while (!next.done) {
        const questao = next.value;

        const itemData: ItemDataSelect<QuestaoInfo> = {
            entity: questao,
            id_item: questao.id,
            isChecked: false,
        };

        arrayResultado.push(itemData);

        next = iterator.next();
    }

    return arrayResultado;
}
