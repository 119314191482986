import {
  ContentSidebar,
  HeaderLogo,
  MainSidebar,
  MenuGroup
} from "./styles";

import { SidebarItemOption, SidebarItemProps } from "../Sidebar/Item";
import { SidebarSubItemOption, SubItemSidebarProps } from "./SubItem";


export interface SidebarBaseType {
  appName: string,
  companyName: string;
}


export interface SidebarProps extends SidebarBaseType {
  mainOptions: Array<SidebarItemProps>;
  otherOptions: Array<SubItemSidebarProps>;
}

export const Sidebar: React.FC<SidebarProps> = ({ appName, companyName, mainOptions, otherOptions }) => {

  return (
    <MainSidebar>
      <HeaderLogo>
        <div>
          <h3>{appName}</h3>
          <h5>{companyName}</h5>
        </div>
      </HeaderLogo>
      <ContentSidebar>
        <MenuGroup>
          <section>
            <small>Principal</small>
          </section>
          <ul>
            {
              mainOptions.map((value) => {
                return <SidebarItemOption {...value} />;
              })
            }
          </ul>
        </MenuGroup>
        {otherOptions.length >= 1 &&
          <MenuGroup>
            <section>
              <small>Menu</small>
            </section>
            <ul>
              {
                otherOptions.map((value) => {
                  return <SidebarSubItemOption {...value} />;
                })
              }
            </ul>
          </MenuGroup>}
      </ContentSidebar>
    </MainSidebar>
  );
};
