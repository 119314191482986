import React, { Dispatch, SetStateAction } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { ActionType } from '../../page/GerenciarPageBase/types';
import { Background, MenuFooter, MenuTitle, SidebarModalContent } from './styles';

export interface TModal {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  title: string;
  options: React.ReactNode;
  width?: number;
}

export const Modal: React.FC<TModal> = (props) => {
  const action = props.action[0];
  const setAction = props.action[1];

  return (
    <Background>
      <SidebarModalContent width={props.width}>
        <MenuTitle>
          <h3>{props.title}</h3>
          <VscChromeClose
            onClick={() => {
              setAction({
                action: '',
                id: 0,
              });
            }}
            size={24}
            style={{
              color: 'var(--color-primary-darking)',
              cursor: 'pointer',
            }}
          />
        </MenuTitle>
        <div className="content-modal">{props.children}</div>
        <MenuFooter>{props.options}</MenuFooter>
      </SidebarModalContent>
    </Background>
  );
};
