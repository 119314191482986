import { Fragment } from "react";
import { BiCommentAdd, BiCommentDetail } from "react-icons/bi";
import { ButtonBottomLess } from "../../../../../../components/button/bottomless";
import { Tag } from "../../../../../../components/tag";
import { RespostaDetails } from "../../../../../../services/api-services/questao";
import { QuestaoViewContainer } from "../../styles";
import {
  LeftResposta, RespostaRow,
  RightResposta
} from "../styles";

const leters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

interface TRespostaCertoErradoView {
  respostas: RespostaDetails[];
  onShowComments?: (title: string, id: number) => void;
  onAddComentarios?: (idResposta: number) => void;
}

export const RespostaCertoErradoView: React.FC<TRespostaCertoErradoView> = ({
  respostas,
  onShowComments,
  onAddComentarios,
}) => {
  const renderShowComentarios = (ele: RespostaDetails, index: number) => {
    let options = <Fragment />;

    if (onShowComments) {
      options = (
        <div className="options">
          <ButtonBottomLess
            className="comments"
            icon={BiCommentDetail}
            onClick={() => {
              onShowComments &&
                onShowComments(
                  `Comentários - Alternativa (${leters[index]})`,
                  ele.id
                );
            }}
          />
        </div>
      );
    }

    return options;
  };

  const renderAddCOmentarios = (ele: RespostaDetails, index: number) => {
    let options = <Fragment />;

    if (onAddComentarios) {
      options = (
        <div className="options">
          <ButtonBottomLess
            className="new-comentarios option-margin"
            icon={BiCommentAdd}
            onClick={() => {
              if (onAddComentarios) {
                onAddComentarios(ele.id);
              }
            }}
          />
        </div>
      );
    }

    return options;
  };

  return (
    <QuestaoViewContainer>
      {respostas.map((ele, index) => {
        return (
          <RespostaRow>
            <LeftResposta>
              <div className="leter">
                <b>{`${leters[index]}-)`}</b>
              </div>
              <div className="answer_correct">
                {ele.anwser_correct === "Certo" ||
                  ele.anwser_correct === "Verdadeiro" ? (
                  <Tag
                    nome={ele.anwser_correct}
                    colorDark={"color-resposta-correta"}
                  />
                ) : (
                  <Tag
                    nome={ele.anwser_correct}
                    colorDark={"color-primary-darking"}
                  />
                )}
              </div>
              <div className="content">
                <div
                  className={"ql-editor"}
                  dangerouslySetInnerHTML={{
                    __html: ele.answer,
                  }}
                />
              </div>
            </LeftResposta>
            <RightResposta>
              <div>{renderAddCOmentarios(ele, index)}</div>
              <div>{renderShowComentarios(ele, index)}</div>
            </RightResposta>
          </RespostaRow>
        );
      })}
    </QuestaoViewContainer>
  );
};
