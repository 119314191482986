import React from "react";
import { IconBaseProps } from "react-icons";
import { ButtonBottomLess, TButtonBottomLess } from "../button/bottomless";
import { SectionTextContent } from "./styles";


export interface TSectionText {
    title: string;
    icon?: React.ComponentType<IconBaseProps>
    button?: TButtonBottomLess
}

export const SectionText: React.FC<TSectionText> = ({ button, title, icon: Icon, children }) => {
    return (
        <SectionTextContent>
            {Icon &&
                <Icon className={'icon-title'} size={'38px'} />
            }
            <div className="content">
                <h2>{title}</h2>
            </div>
            {button &&
                <div className="button-item">
                    <ButtonBottomLess {...button} />
                </div>
            }
            {children}
        </SectionTextContent>
    )
}