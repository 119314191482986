import { useRef, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { BsGearFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { HiMenu } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ButtonBottomLess } from "../../../button/bottomless";
import { ItemTypes } from "../../../drag-and-drop/hooks/useLayoutDragSystem";
import { ThreeDotsLink } from "../../../menu/three-dots";
import { Tag } from "../../../tag";
import { useSidebarActive } from "./sidebar-active-context";
import { NavbarContent, NavbarLeft, NavbarRight } from "./styles";

export interface NavBarBaseTypes { }

export interface NavBarProps extends NavBarBaseTypes {
  nomeCompleto: string;
  onLogoutEvent: () => void;
  optionsConfig: Array<React.ReactElement<Link>>;
}

export const Navbar: React.FC<NavBarProps> = ({
  nomeCompleto,
  optionsConfig,
  onLogoutEvent,
  children,
}) => {
  const [nomeUsuario, setNomeUsuario] = useState(nomeCompleto);

  const { active, setActive } = useSidebarActive();

  const navbarContentRef = useRef<HTMLDivElement>(null);

  const topMouseHover = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    hover: (item: unknown, monitor: DropTargetMonitor<unknown, unknown>) => {
      window.scrollBy({
        behavior: "smooth",
        top: -24,
      });
    },
    drop: () => { },
    canDrop: (item: unknown, monitor) => {
      return false;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  topMouseHover[1](navbarContentRef);

  return (
    <NavbarContent ref={navbarContentRef}>
      <NavbarLeft active={active}>
        <HiMenu
          className={"button-menu-sidebar"}
          size={34}
          onClick={() => (active ? setActive(false) : setActive(true))}
        />
        {children}
      </NavbarLeft>
      <NavbarRight>
        <Tag classname={"username"} nome={nomeUsuario} />
        <ButtonBottomLess
          icon={FiLogOut}
          className="logout"
          title="Sair"
          onClick={() => {
            onLogoutEvent();
          }}
        />
        <ThreeDotsLink
          icon={BsGearFill}
          size={20}
          className="list-options"
          isLastPosition={true}
          options={optionsConfig}
        />
      </NavbarRight>
    </NavbarContent>
  );
};
