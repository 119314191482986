import styled from "styled-components";



export const FormQuestaoAtualizacaoStyles = styled.div`
    height: 100%;
    width: 100%;
    border: 2px solid var(--color-primary-light-clear);
    border-radius: 18px;
    margin-top: 14px;
    padding: 8px;
`;  