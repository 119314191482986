import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavBarBaseTypes } from "../Navbar";
import { SidebarActiveCX } from "../Navbar/sidebar-active-context";
import { InvisibleScrollBottom } from "../ScrollBottom";
import { SidebarBaseType } from "../Sidebar";
import { Main, MainContent } from "./styles";

export interface MainAppProps {
  component: {
    type: React.ComponentType<any>,
    props?: any
  };
  sidebar: {
    sidebarValue: SidebarBaseType;
    sidebarType: React.ComponentType<SidebarBaseType>;
  };
  navbar: {
    navbarType: React.ComponentType<NavBarBaseTypes>;
  };
}

export interface MainAppBaseType {
  component: {
    type: React.ComponentType<any>,
    props?: any
  };
}

export const MainApp: React.FC<MainAppProps> = ({
  component,
  sidebar,
  navbar,
}: MainAppProps) => {
  const { sidebarType: Sidebar, sidebarValue } = sidebar;
  const { navbarType: Navbar } = navbar;
  const { type: Component, props } = component;

  const [active, setActive] = useState(true);

  useMediaQuery({ maxWidth: 700 }, undefined, (matches: boolean) =>
    matches ? setActive(false) : setActive(true)
  );

  return (
    <Main>
      <SidebarActiveCX.Provider value={{ active, setActive }}>
        {active && (
          <div
            onClick={() => setActive(false)}
            className={"mobile-hide-background"}
          />
        )}
        {active && <Sidebar {...sidebarValue} />}
        <Navbar />
        <MainContent active={active}>{<Component {...props} />}</MainContent>
        <InvisibleScrollBottom />
      </SidebarActiveCX.Provider>
    </Main>
  );
};
