import { AxiosInstance } from 'axios';
import { ItemSelect, ItemsSelectPage } from '../../../components/page/structure/FilterComponent/types';
import { PaginationData } from '../../../components/pagination/types';
import { Service } from '../../service';

export class PerfilQuestoesService extends Service {
  private ENDPOINT = 'profile-questions';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarPerfils(page: number, qtd: number, nome: string): Promise<ItemsSelectPage> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`)
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: PerfilQuestoes) =>
          array.push({
            id: value.id,
            value: value.name,
            key: 'profileName',
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async listagemPerfil(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<PerfilsQuestoesPagination> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`)
      .then((response) => {
        let perfils = new Array<PerfilQuestoes>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        perfils = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: perfils,
          paginationData: pagination,
        };
      });
  }

  async buscarPerfil(id: number): Promise<PerfilQuestoes> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarPerfil(perfil: PerfilQuestoesRequest): Promise<PerfilQuestoes> {
    return this.fetch
      .post(this.ENDPOINT, perfil, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarPerfil(perfil: PerfilQuestoesRequest): Promise<PerfilQuestoes> {
    return this.fetch
      .put(this.ENDPOINT + `/${perfil.id}`, perfil, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirPerfil(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const usePerfilQuestoesService = (axios: AxiosInstance) => {
  return new PerfilQuestoesService(axios);
};

export interface PerfilQuestoes {
  id: number;
  name: string;
}

export interface PerfilQuestoesRequest {
  id?: number;
  name: string;
}

export type PerfilsQuestoesPagination = {
  content: Array<PerfilQuestoes>;
  paginationData: PaginationData;
};
