import styled from "styled-components";


export const ButtonBottomlessContent = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  background-color: var(--color-primary-clear);
  border-radius: 8px;

  small {
    color: var(--color-primary-dark);
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 8px;
    margin-left: 8px;
  }

  .bottom-icon {
    margin-right: 8px;
    margin-left: 8px;
    color: var(--color-primary-darking);
  }

  :hover {
    background-color: var(--color-primary-light-clear);
  }

  :hover {
    .bottom-icon {
      color: var(--color-primary-darking);
    }

    small {
      color: var(--color-primary-darking);
    }
  }
`;
