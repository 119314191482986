import styled from "styled-components";

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item-margin {
    margin: 12px;
  }
`;
