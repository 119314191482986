import { AxiosInstance } from "axios";
import { Service } from "../../service";
import { PerfilQuestoes } from "../perfilQuestoes/index";
import { PaginationData } from "./../../../components/pagination/types";
import { Autor } from "./../autores/index";
import { QuestaoItem } from "./../questao/index";

export class ComentarioService extends Service {
  private ENDPOINT = "question/";

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarComentariosByQuestao(
    page: number,
    search: string,
    idQuestao: number,
    validacao: string
  ): Promise<ComentarioPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
        `${idQuestao}/comment?search=search_key:*'${search}'*;status:'${validacao}'&page=${page}&size=${30}&sort=id&order=desc`
      )
      .then((response) => {
        let comentarios = new Array<Comentario>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        comentarios = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: comentarios,
          paginationData: pagination,
        };
      });
  }

  async listarComentariosPorPagina(
    page: number,
    qtd: string,
    status: string,
    period: string,
    filters: string[],
    search: string,
    sort: string,
    order: string
  ): Promise<ComentarioItemPagination> {
    return this.fetch
      .get(
        `comment?search=search_key:'*${search}*';status:'${status}';period:'${period}';author:'${filters[0]}';profile:'${filters[1]}';project:'${filters[2]}'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`
      )
      .then((response) => {
        let questoes = new Array<ComentarioItem>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        questoes = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: questoes,
          paginationData: pagination,
        };
      });
  }

  async listarComentariosByResposta(
    page: number,
    search: string,
    idQuestao: number,
    idResposta: number,
    validacao: string
  ): Promise<ComentarioPagination> {
    return this.fetch
      .get(
        this.ENDPOINT +
        `${idQuestao}/answer/${idResposta}/comment?search=search_key:*'${search}'*;status:'${validacao}'&page=${page}&size=${30}&sort=id&order=desc`
      )
      .then((response) => {
        let comentarios = new Array<Comentario>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        comentarios = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: comentarios,
          paginationData: pagination,
        };
      });
  }

  async buscarComentario(id: number): Promise<ComentarioDetails> {
    return this.fetch.get(`comment/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarComentario(
    idQuestao: number,
    comentario: ComentarioRequest
  ): Promise<Comentario> {
    return this.fetch
      .post(this.ENDPOINT + `${idQuestao}/comment`, comentario, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarComentario(
    idQuestao: number,
    idComentario: number,
    comentario: ComentarioUpdate
  ): Promise<void> {
    return this.fetch.put(
      this.ENDPOINT + `${idQuestao}/comment/${idComentario}`,
      comentario,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async validacaoComentario(
    idComentario: number,
    status: String
  ): Promise<void> {
    return this.fetch.patch(`comment/${idComentario}/approval`);
  }

  async classificar(
    comentarios: Array<number>,
    idPerfil: number,
    idAutor: number
  ): Promise<void> {
    const classificacao = {
      classification: {
        selected_author_id: idAutor,
        selected_profile_id: idPerfil,
      },
      comments: comentarios,
    };

    return this.fetch.patch(`comments/classification`, classificacao);
  }

  async salvarComentarioResposta(
    idQuestao: number,
    idResposta: number,
    comentario: ComentarioRequest
  ): Promise<Comentario> {
    return this.fetch
      .post(
        this.ENDPOINT + `${idQuestao}/answer/${idResposta}/comment`,
        comentario,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  async editarComentarioResposta(
    idQuestao: number,
    idResposta: number,
    idComentario: number,
    comentario: ComentarioUpdate
  ): Promise<void> {
    return this.fetch.put(
      this.ENDPOINT +
      `${idQuestao}/answer/${idResposta}/comment/${idComentario}`,
      comentario,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async excluirComentario(idComentario: number): Promise<void> {
    return this.fetch.delete(`/comment/${idComentario}`);
  }
}

export const useComentarioService = (axios: AxiosInstance) => {
  return new ComentarioService(axios);
};

export interface Comentario {
  id: number;
  id_question: number;
  id_answer: number;
  question_profile: {
    id: number;
    name: string;
  };
  author: {
    id: number;
    name: string;
  };
  comment_content: string;
  comment_type: string;
  validation: string;
}

export interface ComentarioRequest {
  id?: number;
  comment_type: string;
  selected_id_author: number;
  selected_id_question_profile: number;
  comment_content: string;
}

export interface ComentarioUpdate {
  comment_content: string;
}

export type ComentarioPagination = {
  content: Array<Comentario>;
  paginationData: PaginationData;
};

export interface ComentarioItem {
  id: number;
  question_header: QuestaoItem;
  comment_content: string;
  question_profile: PerfilQuestoes;
  author: Autor;
  comment_type: string;
  validation: string;
}

export interface ComentarioDetails {
  id: number;
  id_answer: number;
  id_question: number;
  comment_content: string;
  question_profile: PerfilQuestoes;
  author: Autor;
  comment_type: string;
  validation: string;
}

export type ComentarioItemPagination = {
  content: Array<ComentarioItem>;
  paginationData: PaginationData;
};
