import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiFileUploadLine, RiQuestionnaireLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { ButtonBottomLess } from "../../components/button/bottomless";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { ItemSelect } from "../../components/page/structure/FilterComponent/types";
import { AsyncSelect } from "../../components/select/async";
import { TitleIcon } from "../../components/title-icon";
import { APIFetch } from "../../services/api";
import { useProvaService } from "../../services/api-services/prova";
import {
  QuestaoResumida,
  useQuestaoService
} from "../../services/api-services/questao";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { CriarProva } from "../GerenciarProvas/forms/criar-prova";
import {
  ImportacaoBar,
  ImportacaoForm,
  ListQuestoesImportadasContent,
  MenuImportacaoContent
} from "../ImportacaoQuestoes/style";
import { QuestaoImportadaItem } from "../ImportacaoQuestoes/views/question-item";

export enum ProcessoStatus {
  INICIO = 1,
  PROCESSANDO = 2,
  FINALIZADO = 3,
  ERRO = 4,
}

export const ImportarQuestoes = () => {
  //

  const [questoesImportadas, setQuestoesImportadas] = useState<
    QuestaoResumida[]
  >([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
    loading: false,
    message: "",
    object: {},
  });

  const [currentAction, setCurrentAction] = currentActionState;

  const auth = useAuthContext();

  const provaService = useProvaService(APIFetch(auth));
  const questaoService = useQuestaoService(APIFetch(auth));

  const [status, setStatus] = useState<ProcessoStatus>(
    ProcessoStatus.INICIO
  );

  const [prova, setProva] = useState<ItemSelect>({
    id: 0,
    key: "",
    useId: false,
    value: "",
  });

  const [tipo, setTipo] = useState<String>("");

  const [arquivoQuestoes, setArquivoQuestoes] = useState<File>();

  useEffect(() => {
    if (
      status === ProcessoStatus.FINALIZADO ||
      status === ProcessoStatus.ERRO
    ) {
      reset();
    }
  }, [status]);

  const importarQuestoesAPI = () => {
    if (status === ProcessoStatus.INICIO) {
      if (arquivoQuestoes) {
        let formData = new FormData();

        const info = {
          type: tipo,
          selected_exam: prova.id ? prova.id : null,
        };

        formData.append("file", arquivoQuestoes);
        formData.append(
          "info",
          new Blob([JSON.stringify(info)], { type: "application/json" })
        );

        setStatus(ProcessoStatus.PROCESSANDO);

        questaoService
          .importarQuestoes(formData)
          .then((questoes) => {
            setQuestoesImportadas(questoes);
            setStatus(ProcessoStatus.FINALIZADO);
            alert("Importação de questões concluída!");
          })
          .catch(() => {
            setStatus(ProcessoStatus.ERRO);
            alert("Ocorreu um erro não foi possível terminar a importação..");
          });
      } else {
        alert("Selecione um arquivo para importação.");
      }
    } else {
      alert("Aguarde a importação atual terminar...");
    }
  };

  const reset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setTipo("");
    setArquivoQuestoes(undefined);
    setStatus(ProcessoStatus.INICIO);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files.length) {
      const file = files[0];

      const filename = file.name.split(".");
      const filetype = filename[filename.length - 1];

      if (filetype !== "txt" && filetype !== "xlsx") {
        alert("Tipo de arquivo escolhido deve ser um txt(moodle)");
      } else if (file.size > 2000000) {
        alert("Tamanho do arquivo deve ser no máximo até 2MB");
      } else {
        setArquivoQuestoes(file);
        setTipo("moodle");
      }
    }
  };

  const modals = () => {
    switch (currentAction.action) {
      case "criar-prova": {
        return <CriarProva externalAction={currentActionState} />;
      }
    }
  };

  return (
    <Fragment>
      <BaseComponent pageName={"Importar questões"}>
        <MenuImportacaoContent>
          <ImportacaoBar>
            <div className="title-bar">
              <p>Selecione as configurações de importação</p>
            </div>
            <div className="buttons-options">
              {status === ProcessoStatus.PROCESSANDO && (
                <div className="loading">
                  <Loader
                    type="Oval"
                    color="var(--color-primary-dark)"
                    height={28}
                    width={28}
                    timeout={10000000000000}
                  />
                  <small>Aguarde o processo de importação...</small>
                </div>
              )}
              <ButtonBottomLess
                title={"Importar"}
                onClick={importarQuestoesAPI}
                className="importar-questoes"
                icon={BiImport}
              />
            </div>
          </ImportacaoBar>
          <ImportacaoForm>
            <div className="selecao-prova">
              <div className="field-group">
                <ButtonBottomLess
                  title="Nova prova"
                  className="criarProva"
                  icon={MdAdd}
                  onClick={() => {
                    setCurrentAction({
                      id: 0,
                      action: "criar-prova",
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <div className="field-group">
                  <label>Prova</label>
                  <AsyncSelect
                    className="prova field-group"
                    showSelectItem={true}
                    label="Selecione uma prova"
                    selectItem={prova}
                    onAddFilterItem={(item) => {
                      setProva(item);
                    }}
                    getDataAPI={(page, value) => {
                      return provaService.listarProvas(page, 30, value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="selecao-arquivo">
              <input
                style={{ display: "none" }}
                type="file"
                onChange={handleFile}
                ref={fileInputRef}
              />
              <ButtonBottomLess
                title={"Selecionar arquivo"}
                className="importar-button"
                onClick={() => fileInputRef.current?.click()}
                icon={RiFileUploadLine}
              />
              <small className="aviso-file">
                Selecione um único arquivo de até 2MB no formato moodle(txt)...
              </small>
              <div className="nome-arquivo">
                <ButtonBottomLess
                  className="selecionado"
                  icon={AiOutlineFileText}
                />
                <p>
                  {arquivoQuestoes
                    ? arquivoQuestoes.name
                    : "Nenhum arquivo selecionado..."}
                </p>
              </div>
            </div>
          </ImportacaoForm>
          <TitleIcon
            icon={RiQuestionnaireLine}
            name={`(${questoesImportadas.length}) - Questões importadas`}
          />
          <ListQuestoesImportadasContent>
            {questoesImportadas.map((questao, index) => {
              return (
                <QuestaoImportadaItem
                  elem={questao}
                  index={index}
                  key={index}
                />
              );
            })}
          </ListQuestoesImportadasContent>
        </MenuImportacaoContent>
      </BaseComponent>
      {modals()}
    </Fragment>
  );
};
