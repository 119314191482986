import { BiErrorAlt } from 'react-icons/bi';
import { ButtonBottomLess } from '../../../components/button/bottomless';
import { IndisponivelContent } from './styles';

export const Indisponivel = () => {
  return (
    <IndisponivelContent>
      <ButtonBottomLess className="error" icon={BiErrorAlt} />
      <p>Sistema está indisponível no momento, aguarde!</p>
    </IndisponivelContent>
  );
};
