import styled from "styled-components";

export const PaginationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin-left: 2px;
      margin-right: 2px;
      padding: 5px 10px 5px 10px;
      text-align: center;
      background-color: var(--color-background);
      border-radius: 50%;
      font-weight: 600;
      font-size: 0.9rem;
      color: var(--color-primary-dark);
      cursor: pointer;
    }

    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 4px;
      padding: 4px;
      align-items: center;
      min-width: 30px;
      background-color: var(--color-primary-clear);
      border-radius: 50%;
      color: var(--color-primary-dark);
      cursor: pointer;
    }

    .no-number {
      border: none;
    }

    .page-selected {
      background-color: var(--color-primary-light-clear);
      font-weight: bold;
      border: 3px solid var(--color-primary-light);
    }
  }
`;
