import React, { Fragment, useEffect, useState } from "react";
import { MdViewHeadline } from "react-icons/md";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
  indicacaoFonteRespostaOrdenacao,
  questaoOrdenacao,
} from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { IndicacaoFonteRespostaRow } from "./views/row";
import { IndicacaoFonteRespostaDetails, useIndicacaoFonteRespostaService } from "../../services/api-services/indicacaoFonteResposta";
import { AprovarIndicacao } from "./forms/aprovar-indicacao";
import { ReprovarIndicacao } from "./forms/reprovar-indicacao";

export const GerenciarIndicacaoFonteRespostaComponent = () => {

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const indicacaoFonteResService = useIndicacaoFonteRespostaService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [aplicacao, setAplicacao] = useState("");

  const [periodo, setPeriodo] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const indicacaoFonteState = useState<Array<ItemDataSelect<IndicacaoFonteRespostaDetails>>>([]);
  const setIndicacaoFonteState = indicacaoFonteState[1];

  const useTagActions = useTagAction();

  const selectedEntitiesAction = useSelectedDataAction<IndicacaoFonteRespostaDetails>({
    state: indicacaoFonteState,
  });

  useEffect(() => {
    if (context.loading) {
      indicacaoFonteResService
        .listar(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          aplicacao,
          periodo,
          sort,
          order
        )
        .then((data) => {
          setIndicacaoFonteState(
            data.content.map((value: IndicacaoFonteRespostaDetails) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(data.paginationData);
          context.toPage(data.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        })
        .catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);


  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(true);  
  };

  const modals = () => {

    switch (context.currentAction.action) {

      case "aprovar": {
        return <AprovarIndicacao />;
      }

      case "reprovar": {
        return <ReprovarIndicacao />;
      }


      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }

      default: {
        return <Fragment />;
      }
    }
  };

  const opcaoGerenciamento = () => {
    return [];
  };

  return (
    <Fragment>
      <GerenciarPageBase<IndicacaoFonteRespostaDetails>
        callbacks={{
          search: (key) => {
            if (key.length === 0) {
              context.changeLoading(true);
            }
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => {
            setMode(button.value);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={indicacaoFonteState}
        filters={<Fragment />}
        filterBar={{
          left: <Fragment />,
          right: (
            <Fragment>
              <GroupButtons
                initialPositionSelected={3}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setAplicacao),
                    name: "Aprovado",
                    classname: "status-option",
                    value: "Aprovado",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setAplicacao),
                    name: "Reprovado",
                    classname: "status-option",
                    value: "Reprovado",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setAplicacao),
                    name: "Pendente",
                    classname: "status-option",
                    value: "Pendente",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setAplicacao),
                    classname: "status-option ambos-validacao",
                    value: "",
                    minWidth: 8,
                    icon: MdViewHeadline,
                  },
                ]}
              />
              <GroupButtons
                initialPositionSelected={3}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriodo),
                    name: "hoje",
                    classname: "periodo-option",
                    value: "day",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriodo),
                    name: "semana",
                    classname: "periodo-option",
                    value: "week",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriodo),
                    name: "mês",
                    classname: "periodo-option",
                    value: "month",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriodo),
                    classname: "periodo-option",
                    value: "",
                    minWidth: 9,
                    icon: MdViewHeadline,
                  },
                ]}
              />
            </Fragment>
          ),
        }}
        page={{
          name: "Indicações de fonte de resposta",
          header: {
            left: <Fragment />,
            right: <Fragment />,
          },
          searchPlaceHolder: "Pesquisar por id, código, ou palavra-chave",
          showFilters: true,
          showSearch: false
        }}
        visualization={{
          sort: {
            params: indicacaoFonteRespostaOrdenacao,
          },
          options: opcaoGerenciamento(),
          mode: mode,
          table: {
            dataRow: [
              <div>Ações</div>,
              <div>ID</div>,
              <div>Fundamentação da Resposta</div>,
              <div>Tipo de Lei</div>,
              <div>Lei/Artigo</div>,
              <div>Dispositivo</div>,
              <div>Questão(ID)</div>,
              <div>Apelido(JusQuestões)</div>,
              <div>Data de indicação</div>,
              <div>Status da indicação</div>,
            ],
            dataColumn: IndicacaoFonteRespostaRow(selectedEntitiesAction)
              .header,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarIndicacaoFonteResposta = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarIndicacaoFonteRespostaComponent />
    </GerenciarBaseProvider>
  );
};
