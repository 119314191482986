import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { BsFilterRight, BsSortUpAlt } from "react-icons/bs";
import { ImMoveUp } from "react-icons/im";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckBox } from "../../../../components/checkbox";
import { DragAndDropList } from "../../../../components/drag-and-drop";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  LayoutDragFeatures,
  ParentElement
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { GroupButtons, TButtonSelect } from "../../../../components/group-buttons";
import { Modal } from "../../../../components/modals/modal-base";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import {
  NormalSelect,
  TItemSelect
} from "../../../../components/select/normal";
import { SubMenuTitle } from "../../../../components/sub-menu-title";
import { TextItem } from "../../../../components/text-item";
import { APIFetch } from "../../../../services/api";
import {
  DivisaoData,
  useDivisaoProjeto
} from "../../../../services/api-services/divisoesProjeto";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { DivisaoOption } from "../../../CriarProjeto/components/divisao-option";
import { useDivisoesProjetoContext } from "../../../CriarProjeto/forms/assistente-estrutura/context";
import { Options } from "../../../GerenciarQuestoes/forms/adicionar-questoes-projeto/styles";
import {
  ConfiguracoesDinamicasContent,
  EscolherSeparadorContent,
  EscolherSeparadorFooter,
  MoverConteudoContent
} from "../../styles";

interface TEscolherSeparador {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
  idProject: number;
  atualizarLayout: () => Promise<void>;
}

export const MoverConteudoSeparador: React.FC<TEscolherSeparador> = (props) => {

  const tabActionsState = useState<ActionType>({
    action: "mover-manualmente",
    id: 1,
  });

  const [tabActions, setTabActions] = tabActionsState;

  const [action, setAction] = props.action;

  const auth = useAuthContext();

  const assistenteContext = useDivisoesProjetoContext();

  const axiosInstance = APIFetch(auth);

  const services = {
    divisaoProjetoService: useDivisaoProjeto(axiosInstance),
  };

  const [divisaoAdjacenteAPI, setDivisaoAdjacenteAPI] = useState<DivisaoData>();

  const [divisaoRecomendada, setDivisaoRecomendada] = useState<TItemSelect>();

  const [divisaoEscolhidaLista, setDivisaoEscolhidaLista] =
    useState<DropZoneData<DivisaoData>>();

  const [idDivisaoAdjacente, setIdDivisaoAdjacente] = useState<{
    id: number;
    title: string;
  }>();

  const [titleDivisao] = useState<string>(
    action.object ? action.object.titulo : ""
  );

  const [ordenarQuestoes, setOrdenarQuestoes] = useState<boolean>(true);

  const initialOptions: TButtonSelect[] = [
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 1,
        });
      },
      name: "Mover manualmente",
      classname: "status-option",
      value: "mover-manualmente",
    },
    {
      onClick: (current) => {
        setTabActions({
          action: current.value,
          id: 2,
        });
      },
      name: "Mover dinamicamente",
      classname: "status-option",
      value: "mover-dinamicamente",
    },

  ];

  useEffect(() => {
    if (action.id && action.id >= 1) {
      services.divisaoProjetoService
        .consultarDivisoes(props.idProject, action.id)
        .then((value) => {
          setDivisaoAdjacenteAPI(value);
        });
    }
  }, []);


  const onClickMoverConteudo = () => {
    if (action.promisse) {
      let info = {};

      if (tabActions.action === "mover-manualmente") {

        if (idDivisaoAdjacente) {

          if (idDivisaoAdjacente.id === action.id) {
            info = {
              adjacent_division_id: action.id,
              move_division_id: 0,
              dynamic_movement_content: false,
              sort_questions: ordenarQuestoes
            };
          } else {
            info = {
              adjacent_division_id: action.id,
              move_division_id: divisaoEscolhidaLista?.data.id,
              dynamic_movement_content: false,
              sort_questions: ordenarQuestoes
            };
          }

          action
            .promisse(info)
            .then((value) => {
              props.atualizarLayout();
              setAction({
                ...action,
                id: 0,
                action: "",
              });
            })
            .catch((error) => {
              alert(error.response.data.message);
              setAction({
                ...action,
                id: 0,
                action: "",
              });
            });

        } else {
          alert("Selecione o separador")
        }

      } else if (tabActions.action === "mover-dinamicamente") {

        let info = {
          dynamic_movement_content: true,
          sort_questions: ordenarQuestoes
        };

        action
          .promisse(info)
          .then((value) => {
            props.atualizarLayout();
            setAction({
              ...action,
              id: 0,
              action: "",
            });
          })
          .catch((error) => {
            alert(error.response.data.message);
            setAction({
              ...action,
              id: 0,
              action: "",
            });
          });

      }

    }
  }

  const canDropItem = async (
    dropZone: DropZoneData<DivisaoData>,
    item: DropZoneData<DivisaoData>,
    layout: Array<ParentElement<DivisaoData> | ChildrenElement<DivisaoData>>
  ) => {
    return false;
  };

  const renderDivisao = (
    dragTarget: React.RefObject<HTMLDivElement>,
    features: LayoutDragFeatures<DivisaoData>,
    zone: DropZoneData<DivisaoData>,
    data?: DivisaoData | undefined
  ) => {
    return (
      <DivisaoOption
        type="editar"
        idProject={props.idProject}
        externalAction={props.action}
        selectedDivisao={divisaoEscolhidaLista}
        onSelectedDivisao={(value) => {
          if (value.data.data) {
            const { title, order, level } = value.data.data;

            setDivisaoEscolhidaLista(value);
            setIdDivisaoAdjacente({
              id: Number(value.data.id),
              title: title ? title : `${order} - ${level}`,
            });
          }
        }}
        zone={zone}
        data={data}
        authInstance={axiosInstance}
        features={features}
      />
    );
  };

  const Footer = () => {
    return (
      <EscolherSeparadorFooter>
        {idDivisaoAdjacente && (
          <Fragment>
            <p className={"p-style"}>
              Você selecionou <small>{idDivisaoAdjacente.title}</small>
            </p>
          </Fragment>
        )}
        <ButtonBottomLess
          className="save"
          title="Mover"
          onClick={() => onClickMoverConteudo()}
          icon={ImMoveUp}
        />
      </EscolherSeparadorFooter>
    );
  };

  const isDivisaoAdjacente = () => {
    if (divisaoAdjacenteAPI) {
      return true;
    } else {
      return false;
    }
  };

  const MoverConteudoManualmenteMenu = () => {
    return <EscolherSeparadorContent isBetween={isDivisaoAdjacente()}>
      {divisaoAdjacenteAPI && (
        <div className="menu-selecionar-adjcente">
          <SubMenuTitle
            icon={BsFilterRight}
            title="Selecione qual separador que receberá o conteúdo"
            styles={{
              titleBar: {
                justifyContent: "center",
              },
            }}
          >
            <TextItem title="Você pode escolher o recomendado ou escolher o separador que preferir ">
              <NormalSelect
                minWidth={64}
                className="destino field-group"
                label="Selecionar destino recomendado"
                selectItem={divisaoRecomendada}
                onSelectCurrentItem={(item) => {
                  setDivisaoRecomendada(item);
                  setDivisaoEscolhidaLista(undefined);
                  setIdDivisaoAdjacente({
                    id: Number(item.id),
                    title: item.value,
                  });
                }}
                selectedItems={[
                  {
                    id: action.id,
                    key: "adjacent",
                    selected: false,
                    useId: false,
                    value: divisaoAdjacenteAPI.title
                      ? `${divisaoAdjacenteAPI.order} - ${divisaoAdjacenteAPI.level} - ${divisaoAdjacenteAPI.title}`
                      : `${divisaoAdjacenteAPI.order} - ${divisaoAdjacenteAPI.level}`,
                  },
                ]}
              />
            </TextItem>
          </SubMenuTitle>
        </div>
      )}
      {MoverConteudoDinamicamenteMenu(action.action)}

      <div className="divisoes">
        {!isDivisaoAdjacente() && (
          <TextItem title="Selecione um separador para qual o conteúdo deve ser movido:" />
        )}
        <DragAndDropList
          dragAndDropName={`listaEscolhaDaDivisao`}
          showBorder={true}
          renderItem={renderDivisao}
          context={assistenteContext}
          canDropItem={canDropItem}
          maxLevel={assistenteContext.niveis.length}
        />
      </div>
    </EscolherSeparadorContent>
  }

  const MoverConteudoDinamicamenteMenu = (tipoMover: string) => {
    return (
      <ConfiguracoesDinamicasContent>
        <SubMenuTitle
          title={tipoMover === "mover-dinamicamente" ? `Configuração de ordenação` : `Marque se ao final da importação as questões que contiverem no separador (${titleDivisao}) devem ser ordenadas`}
          subTitle={tipoMover === "mover-dinamicamente" ? "Marque se ao final da importação as questões dos agrupamentos relacionados devem ser ordenadas" : ""}
          icon={BsSortUpAlt}
          styles={{
            titleBar: {
              justifyContent: "center",
            },
          }}
        >
          <CheckBox
            label={"Deve ordenar questões"}
            className={"ordenar-questoes field-group"}
            checked={ordenarQuestoes}
            onChange={(event) => {
              setOrdenarQuestoes(event.currentTarget.checked)
            }}
          />
        </SubMenuTitle>

      </ConfiguracoesDinamicasContent>
    )
  }

  const options = (action: ActionType) => {
    switch (action.action) {
      case "mover-manualmente": {
        return MoverConteudoManualmenteMenu();
      } case "mover-dinamicamente": {
        return MoverConteudoDinamicamenteMenu(action.action);
      } default: {
        return <Fragment />
      }
    }
  };

  return (
    <Modal
      action={props.action}
      title={`Mover conteúdo do separador - (${titleDivisao})`}
      options={Footer()}
    >
      <MoverConteudoContent>
        <Options>
          <GroupButtons
            initialPositionSelected={0}
            buttons={initialOptions}
          />
        </Options>
        <div className="conteudo-opcoes">
          {options(tabActions)}
        </div>
      </MoverConteudoContent>
    </Modal>
  );
};
