import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { disciplinaIsClassificado } from "../../../../services/api-services/disciplina";
import {
  Questao,
  QuestaoResumida
} from "../../../../services/api-services/questao";
import { tagIsClassificado } from "../../../../services/api-services/tag";
import { temaIsClassificado } from "../../../../services/api-services/tema";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { Status, Validation } from "../../elements";

export const QuestaoHeader = (
  selectedEntitiesAction: SelectedDataActions<Questao>,
  mode: string
) => {
  const QuestaoRowComponent = (
    elem: ItemDataSelect<Questao>,
    index: number
  ) => {
    const context = useGerenciarContext();
    const questao = elem.entity as QuestaoResumida;
    const auth = useAuthContext();
    const { roles } = auth.user;

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={false}
            options={!roles.includes("ROLE_CLASSIFICADOR") ? [
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  window.open(`/questao/editar/${questao.id}`, "_blank");
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: questao.id,
                  });
                }}
              />,
            ] : [
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  window.open(`/questao/editar/${questao.id}`, "_blank");
                }}
              />
            ]}
          />
        </RowItem>
        <RowItem
          onClick={() => {
            if (!roles.includes("ROLE_CLASSIFICADOR")) {
              window.open(`/questao/${questao.id}`, "_blank");
            }
          }}
        >
          {questao.id}
        </RowItem>
        <RowItem>{<Status status={questao.status_question} />}</RowItem>
        <RowItem>{questao.eligibility_classification ? "Sim" : "Não"}</RowItem>
        <RowItem>
          {<Validation value={questao.validation} mode={mode} />}
        </RowItem>
        <RowItem>{questao.cod ? questao.cod : "-"}</RowItem>
        <RowItem>{questao.type}</RowItem>
        <RowItem>{temaIsClassificado(questao.topic)}</RowItem>
        <RowItem>{tagIsClassificado(questao.tag)}</RowItem>
        <RowItem>{tagIsClassificado(questao.exam_discipline)}</RowItem>
        <RowItem>{disciplinaIsClassificado(questao.subject)}</RowItem>
        <RowItem>{questao.exam_board ? questao.exam_board : "-"}</RowItem>
        <RowItem>{questao.office ? questao.office : "-"}</RowItem>
        <RowItem>{questao.career ? questao.career : "-"}</RowItem>
        <RowItem>{questao.year ? questao.year : "-"}</RowItem>
        <RowItem>{questao.difficulty}</RowItem>
        <RowItem>{questao.sources ? questao.sources.join("\n") : "-"}</RowItem>
      </ListTableRow>
    );
  };

  return {
    questaoHeader: QuestaoRowComponent,
  };
};
