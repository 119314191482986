import React, { Fragment } from "react";
import { IconBaseProps } from "react-icons";
import { ButtonBottomlessContent } from "./styles";

export interface TButtonBottomLess
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType<IconBaseProps>;
  sizeIcon?: number;
  title?: string;
  className: string;
  iconPosition?: "left" | "right"
}

export const ButtonBottomLess: React.FC<TButtonBottomLess> = ({
  icon: Icon,
  className,
  title,
  sizeIcon,
  iconPosition,
  ...rest
}) => {

  const iconLeft = () => {
    return (
      <Fragment>
        {Icon && <Icon size={sizeIcon ? sizeIcon : 20} className={"bottom-icon"} />}
        {title && <small>{title}</small>}
      </Fragment>
    )
  }

  const iconRight = () => {
    return (
      <Fragment>
        {title && <small>{title}</small>}
        {Icon && <Icon size={sizeIcon ? sizeIcon : 20} className={"bottom-icon"} />}
      </Fragment>
    )
  }

  const render = () => {

    if (iconPosition) {
      if (iconPosition === "left") {
        return iconLeft();
      } else if (iconPosition === "right") {
        return iconRight();
      }
    }

    return iconLeft();
  }


  return (
    <ButtonBottomlessContent className={className} {...rest}>
      {render()}
    </ButtonBottomlessContent>
  );
};
