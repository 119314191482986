import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { RiLockPasswordLine } from "react-icons/ri";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { InputForm } from "../../../../components/input";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch, RestErro } from "../../../../services/api";
import { useUsuarioService } from "../../../../services/api-services/usuarios";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { countProperties, validar } from "../../../../utils/validacao";
import { FooterTrocarSenha, MudarSenhaContent } from "./styles";

interface Registro {
  senha: "";
  senhaConfirmacao: "";
}

interface Erros {
  senha: string[];
  senhaConfirmacao: string[];
}

export interface TTrocarSenha {
  state?: [ActionType, Dispatch<SetStateAction<ActionType>>];
  resetSenha?: boolean;
}

export const TrocarSenha: React.FC<TTrocarSenha> = ({ state, resetSenha }) => {

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const usuarioService = useUsuarioService(APIFetch(auth));

  const [currentState, setCurrentState] = useState(state ? state : context.currentActionState);

  const [register, setRegister] = useState<{
    fields: Registro;
    erros: Erros;
  }>({
    fields: {
      senha: "",
      senhaConfirmacao: "",
    },
    erros: {
      senha: [],
      senhaConfirmacao: [],
    },
  });

  useEffect(() => {
    const erros = register.erros;
    const fields = register.fields;

    const senhaErros = validar(fields.senha, "Senha");

    erros.senha = senhaErros.length > 0 ? senhaErros : [];

    const senhaConfirmacaoErros = validar(fields.senhaConfirmacao, "Senha");

    if (fields.senhaConfirmacao !== fields.senha) {
      senhaConfirmacaoErros.push(
        "A senha de confirmação deve ser igual a senha anteriormente informada..."
      );
    }

    erros.senhaConfirmacao =
      senhaConfirmacaoErros.length > 0 ? senhaConfirmacaoErros : [];

    setRegister({
      erros: erros,
      fields: fields,
    });
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (countProperties(register.erros) > 0) {
      alert("Existem erros de validação, revise os campos e tente novamente!");
    } else {
      currentState[1]({
        action: "autorizar",
        id: context.currentAction.id,
        promisse: async (properties: any) => {

          const currentPassword = properties.password;

          const senhaUpdate = {
            confirmed_new_password: register.fields.senhaConfirmacao,
            new_password: register.fields.senha,
            current_password: currentPassword
          };

          try {

            if (resetSenha) {
              await usuarioService.resetarSenha(currentState[0].id, senhaUpdate);
              successTrocaSenha();
            } else {
              await usuarioService.trocarSenha(currentState[0].id, senhaUpdate);
              successTrocaSenha();
            }

          } catch (error: any) {
            failTrocaSenha(error);
          }
        },
      });
    }

  };

  const failTrocaSenha = (error: any) => {

    const erro: RestErro = error.response.data;
    alert(erro.message);

    currentState[1]({
      ...currentState[0],
      action: "trocar-senha"
    });
  }

  const successTrocaSenha = () => {
    alert("Senha alterada com sucesso!")

    currentState[1]({
      id: 0,
      action: ""
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    registerField(name, value);
  };

  const registerField = (name: string, value: string) => {
    const fields = register.fields;
    const erros = register.erros;

    switch (name) {
      case "senha": {
        const arrayErros = validar(value, "Senha");
        erros.senha = arrayErros.length > 0 ? arrayErros : [];
        break;
      }
      case "senhaConfirmacao": {
        const arrayErros = validar(value, "Senha de confirmação");

        if (value !== fields.senha) {
          arrayErros.push(
            "A senha de confirmação deve ser igual a senha anteriormente informada..."
          );
        }

        erros.senhaConfirmacao = arrayErros.length > 0 ? arrayErros : [];
        break;
      }
    }

    setRegister({
      erros: erros,
      fields: { ...fields, [name]: value },
    });
  };

  const showErros = (erros: string[]) => {
    return erros.map((item) => {
      return <span className="error">{item}</span>;
    });
  };

  const Footer = () => {
    return (
      <FooterTrocarSenha>
        <div />
        <ButtonBottomLess
          className="save"
          title="Trocar senha"
          icon={RiLockPasswordLine}
          type={"submit"}
        />
      </FooterTrocarSenha>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Modal
        action={currentState}
        title={"Trocar senha usuário"}
        options={Footer()}
      >
        <MudarSenhaContent>
          <div className="form-senha">
            <div className="form-group">
              <div className="field-group">
                <label htmlFor={"senha"}>Nova senha</label>
                <InputForm
                  className="senha field-size"
                  type="password"
                  name={"senha"}
                  value={register.fields.senha}
                  onChange={handleChange}
                  maxLength={100}
                  placeholder="Informe a nova senha"
                  formNoValidate
                />
                {showErros(register.erros.senha)}
              </div>
            </div>
            <div className="form-group">
              <div className="field-group">
                <label htmlFor={"senhaConfirmacao"}>
                  Confirmação da nova senha
                </label>
                <InputForm
                  className="senhaConfirmacao field-size"
                  type="password"
                  value={register.fields.senhaConfirmacao}
                  name={"senhaConfirmacao"}
                  onChange={handleChange}
                  maxLength={100}
                  formNoValidate
                  placeholder="Informe a nova senha novamente"
                />
                {showErros(register.erros.senhaConfirmacao)}
              </div>
            </div>
          </div>
        </MudarSenhaContent>
      </Modal>
    </form>
  );
};
