import React, { useState } from "react";
import { IconBaseProps } from "react-icons";
import { Link } from "react-router-dom";
import { SidebarItem } from "../styles";
import { SidebarSubItemOption, SubItemSidebarProps } from "../SubItem";

export interface SidebarItemProps {
  title: string;
  icon?: React.ComponentType<IconBaseProps>;
  path?: string;
  subItems?: Array<SubItemSidebarProps>;
}

export const SidebarItemOption: React.FC<SidebarItemProps> = ({
  subItems,
  icon: Icon,
  title,
  path,
}) => {
  const [active, setActive] = useState<boolean>();

  return (
    <SidebarItem optionIsActive={active}>
      {subItems ? (
        <div onClick={() => subItems && setActive(active ? false : true)}>
          {Icon && <Icon size={34} className={"sidebar-menu-option-icone"} />}
          <h4>{title}</h4>
        </div>
      ) : (
        <Link to={path ? path : {}}>
          <div>
            {Icon && <Icon size={34} className={"sidebar-menu-option-icone"} />}
            <h4>{title}</h4>
          </div>
        </Link>
      )}
      {subItems && (
        <ul>
          {subItems.map((value) => {
            return <SidebarSubItemOption {...value} />;
          })}
        </ul>
      )}
    </SidebarItem>
  );
};
