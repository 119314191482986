import styled from "styled-components";

export interface SubMenuTitleStyles {
  titleBar?: {
    justifyContent?: string
  }
}

export const SubMenuTitleContent = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  border-radius: 8px;
  padding: 8px;
  height: 100%; 
  
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border-width: 1px;
  border-color: var(--color-select-border);
  border-style: solid;
  border-radius: 0px 0px 8px 8px;
  background-color: var(--color-content-light);
  padding: 18px;
  width: 100%;
  `;


export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:  ${(settings: SubMenuTitleStyles) => settings.titleBar && settings.titleBar ? settings.titleBar.justifyContent : "space-between"};
  width: 100%;
  background-color:var(--color-content-light);
  border-width: 1px;
  border-color: var(--color-select-border);
  border-style: solid;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  flex-wrap: wrap;

  .titles{
    display: flex;
    flex-direction: row;
      align-items: center;
  }

   h4 {
    font-weight: 600;
    color: var(--color-text-dark);
    margin-left: 12px;
    margin-right: 24px;
  }

  p {
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--color-text-grey);
  }

  `;