import { ActionType, Modal } from "jusadmin-ui-kit";
import { Dispatch, SetStateAction } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { GroupButtons } from "../../../../components/group-buttons";
import { PosSalvamentoQuestaoModalStyle } from "./styles";

export const PosSalvamentoQuestaoModal: React.FC<{
    action: [ActionType, Dispatch<SetStateAction<ActionType>>];
}> = (props) => {

    const [action, setAction] = props.action;
    const history = useHistory();

    return (
        <Modal action={props.action} title={'Questão salva com sucesso, onde deseja ir?'} options={[]}>
            <PosSalvamentoQuestaoModalStyle>
                <GroupButtons
                    initialPositionSelected={3}
                    buttons={[
                        {
                            onClick: (current) => {
                                window.location.reload();
                            },
                            classname: 'status-option',
                            value: 'criar',
                            icon: MdOutlineAdd,
                            name: 'Criar uma nova questão',
                            minWidth: 200,
                        },
                        {
                            onClick: (current) => {
                                history.push(`/questao/editar/${action.id}`);
                            },
                            classname: 'status-option',
                            value: 'editar',
                            icon: FiEdit2,
                            name: 'Editar questão',
                            minWidth: 200,
                        },
                        {
                            onClick: (current) => {
                                setAction({
                                    id: 0,
                                    action: "questao"
                                })
                            },
                            classname: 'status-option',
                            value: 'voltar',
                            icon: BiArrowBack,
                            name: 'Voltar ao formulário',
                            minWidth: 200,
                        },
                    ]}
                />
            </PosSalvamentoQuestaoModalStyle>
        </Modal>
    );
};
