import { QuestaoInfo } from "../../../../CriarQuestao/questao-info";
import { FormQuestaoAtualizacaoStyles } from "./styles";

export const FormQuestaoAtualizacao = () => {
    return (
        <FormQuestaoAtualizacaoStyles>
            <QuestaoInfo modoClassificao />
        </FormQuestaoAtualizacaoStyles>
    );
};
