import { ConfirmationModal } from '../../../../components/modals/confirmation-modal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';

export interface FecharFormQuestaoState {
  yes: boolean
  action: string;
  index: number;
}

export const FecharFormQuestao = () => {

  const context = useGerenciarContext();

  const handleExcluir = () => {

    const state: FecharFormQuestaoState = {
      yes: true,
      action: context.currentAction.action,
      index: context.currentAction.id
    }

    context.changeAction({
      action: 'pos-confirmacao',
      object: state,
      id: 0
    })
  };

  return (
    <ConfirmationModal
      action={context.currentActionState}
      message={context.currentAction.message + ""}
      title={'Confirmação'}
      onOptionNot={() =>
        context.changeAction({
          action: '',
          id: 0,
        })
      }
      onOptionYes={handleExcluir}
    />
  );
};
