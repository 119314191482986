import { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { CheckContent } from "./styles";

export interface TChangeIcon {
    icon: React.ComponentType<IconBaseProps>;
    classname: string;
    onChange: (check: boolean) => void;
    currentValue?: boolean;
}


export const ChangeIcon: React.FC<TChangeIcon> = ({ icon: Icon, classname, onChange, currentValue }) => {

    const [check, setCheck] = useState<boolean>(false);

    useEffect(() => {
        if (currentValue) {
            if (currentValue !== check) {
                setCheck(currentValue);
            }
        }
    }, [currentValue])

    useEffect(() => {
        onChange(check);
    }, [check])

    return (
        <CheckContent className={classname} selected={check} onClick={(event) => check ? setCheck(false) : setCheck(true)}>
            <Icon size={20} className={"bottom-icon"} />
        </CheckContent>
    );
}