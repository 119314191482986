import { IconBaseProps } from "react-icons";
import { MenuContent, SubMenuTitleContent, SubMenuTitleStyles, TitleBar } from "./styles";
export interface TSubMenuTitle {
    title?: string;
    subTitle?: string;
    styles?: SubMenuTitleStyles;
    icon?: React.ComponentType<IconBaseProps>
    subContent?: React.ReactNode
}

export const SubMenuTitle: React.FC<TSubMenuTitle> = ({ title, subTitle, icon: Icon, children, styles, subContent }) => {
    return (<SubMenuTitleContent>
        {title && <TitleBar titleBar={styles && styles.titleBar}>
            <div className="titles">
                {Icon && <Icon className={'icon-title'} size={'24px'} />}
                <h4>{title}</h4>
                {subContent ? subContent : subTitle && <p>{subTitle}</p>}
            </div>
        </TitleBar>}
        <MenuContent>
            {children}
        </MenuContent>
    </SubMenuTitleContent>);
}