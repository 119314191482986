import styled from "styled-components";

export const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  justify-content: center;
  background-color:  var(--color-background);
  border-radius: 8px;
  padding-left: 14px;
  padding-bottom: 2px;

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-text-secondary);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;

    .open-arrow{
      cursor: pointer;
    }

    small{
      margin-left: 8px;
      font-size: 0.8rem;
    }

  }
`;

export const GroupMain = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .field-group {
    margin-bottom: 12px;
    margin-right: 18px;
    width: 220px;

    @media (max-width: 700px) {
      width: 100%;
      align-items: center;
    }
  }
`;
