import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { BiCommentCheck } from "react-icons/bi";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { Editor } from "../../../../components/editor";
import { Modal } from "../../../../components/modals/modal-base";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { EditarTituloContent, EditarTituloFooter } from "./styles";

interface TEditarTextoDivisao {
  action: [ActionType, Dispatch<SetStateAction<ActionType>>];
}

export const TEXTO_INICIAL = '<p><br></p>';
export const EditarTextoDivisao: React.FC<TEditarTextoDivisao> = (props) => {
  const [action, setAction] = props.action;

  const [texto, setTexto] = useState<string>(action.message ? action.message : "");

  const salvarTexto = () => {
    if (action.promisse) {
      action.promisse(texto).then(() => {
        setAction({
          ...action,
          promisse: undefined,
          action: ""
        })
      })
    }
  };

  const Footer = () => {
    const render = () => {
      let footer: JSX.Element = <Fragment />;

      if (texto && texto !== TEXTO_INICIAL) {
        footer = (
          <ButtonBottomLess className="botao-editar" title="Salvar alterações" icon={BiCommentCheck} onClick={salvarTexto} />
        );
      }
      return footer;
    };

    return <EditarTituloFooter>{render()}</EditarTituloFooter>;
  };

  return (
    <Modal action={props.action} title={'Editar título'} options={Footer()}>
      <EditarTituloContent>
        <Editor options={[
          [{ size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ header: "1" }, { header: "2" }, "blockquote"],
          [
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ align: [] }],
          [{ script: "sub" }, { script: "super" }],
        ]} currentText={texto} classname="editor-texto" html={(html) => setTexto(html)} />
      </EditarTituloContent>
    </Modal>
  );
};
