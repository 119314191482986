import React, { useEffect, useState } from "react";
import { VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { DragAndDropList } from "../../../../components/drag-and-drop";
import { DropZoneData } from "../../../../components/drag-and-drop/dropzone";
import {
  ChildrenElement,
  ItemTypes,
  ParentElement,
  TLayoutDragSystem
} from "../../../../components/drag-and-drop/hooks/useLayoutDragSystem";
import { InputForm } from "../../../../components/input";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch } from "../../../../services/api";
import { useEstruturaExportacaoService } from "../../../../services/api-services/estruturaExportacao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { useEstruturaQuestaoContext } from "./context";
import { EstruturaQuestaoProvider } from "./provider";
import {
  CriarEstruturaExportacaoContent,
  EstruturaExportacaoContent
} from "./styles";

export interface TCriarEstruturaExportacao {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const CriarEstruturaExportacao: React.FC<TCriarEstruturaExportacao> = ({
  externalAction,
}) => {
  const initialLayout: Array<ParentElement<any> | ChildrenElement<any>> = [
    {
      id: "cabecalho",
      name: "Cabeçalho (Prova)",
      type: "children",
    },
    {
      id: "enunciado",
      name: "Enunciado",
      subGroups: [],
      type: "parent",
      isOpen: true,
    },
    {
      id: "alternativas",
      name: "Alternativa/Resposta",
      subGroups: [],
      type: "parent",
      isOpen: true,
    },
    {
      id: "comentario_enunciado",
      name: "Comentários - (Enunciado)",
      type: "children",
    },
    {
      id: "comentario_alternativa",
      name: "Comentários - (Alternativa)",
      type: "children",
    },
  ];

  return (
    <EstruturaQuestaoProvider initialLayout={initialLayout}>
      <CriarEstruturaExportacaoComponent externalAction={externalAction} />
    </EstruturaQuestaoProvider>
  );
};

export const CriarEstruturaExportacaoComponent: React.FC<
  TCriarEstruturaExportacao
> = ({ externalAction }) => {
  const estruturaQuestaoCX = useEstruturaQuestaoContext();

  const auth = useAuthContext();

  const currentFormState = useState<ActionType>({
    id: 0,
    action: "",
  });

  const [currentForm, setCurrentForm] = currentFormState;

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const estruturaexportacaoService = useEstruturaExportacaoService(
    APIFetch(auth)
  );

  const [nomeEstrutura, setNomeEstrutura] = useState<string>("");

  useEffect(() => {
    if (currentAction.action == "editar-estrutura-exportacao") {
      estruturaexportacaoService
        .buscarEstruturaExportacao(currentAction.id)
        .then((estrutura) => {
          const array = estrutura.structure.map;
          estruturaQuestaoCX.setLayout(array);
          setNomeEstrutura(estrutura.structure_name);
        });
    }
  }, []);

  const onSubmit = () => {
    if (currentAction.action == "editar-estrutura-exportacao") {
      estruturaexportacaoService
        .editarEstruturaExportacao({
          id: currentAction.id,
          structure_name: nomeEstrutura,
          structure: {
            map: estruturaQuestaoCX.layout,
          },
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Estrutura salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else if (currentAction.action == "criar-estrutura-exportacao") {
      estruturaexportacaoService
        .salvarEstruturaExportacao({
          structure_name: nomeEstrutura,
          structure: {
            map: estruturaQuestaoCX.layout,
          },
        })
        .then((response) => {
          changeAction({
            ...currentAction,
            loading: true,
          });
          alert("Estrutura salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const footer = () => {
    return (
      <ButtonNormal
        icon={VscSave}
        onClick={() => onSubmit()}
        title={"Salvar"}
        className={"save"}
        type={"button"}
      />
    );
  };

  const customDragHandling = (
    dropZone: DropZoneData<any>,
    item: DropZoneData<any>,
    dragDropManager: TLayoutDragSystem<any>,
    maxLevel: number
  ) => {
    const dropZoneArray = dropZone.path.split("-");
    const itemArray = item.path.split("-");

    if (dropZoneArray.length === 1 && itemArray.length === 1) {
      dragDropManager.features.moveElementWithinLayout(dropZone, item);
    } else if (
      itemArray.length === 1 &&
      dropZoneArray.length === 2 &&
      item.type === ItemTypes.CHILDREN
    ) {
      dragDropManager.features.moveChildrenFromLayoutToParent(dropZone, item);
    } else if (
      itemArray.length === 2 &&
      dropZoneArray.length === 1 &&
      item.type === ItemTypes.CHILDREN
    ) {
      dragDropManager.features.moveChildrenFromParentToLayout(dropZone, item);
    } else if (
      itemArray.length === 2 &&
      dropZoneArray.length == 2 &&
      ItemTypes.CHILDREN
    ) {
      dragDropManager.features.moveChildrenWithinParent(dropZone, item);
    }
  };

  const canDropItem = async (
    dropZone: DropZoneData<any>,
    item: DropZoneData<any>,
    layout: Array<ParentElement<any> | ChildrenElement<any>>
  ) => {
    const parentPos = dropZone.path.split("-");

    if (parentPos.length === 2) {
      const parent: ParentElement<any> | ChildrenElement<any> =
        layout[parentPos[0]];

      if (parent && item.data) {
        if (
          item.data.id !== "comentario_enunciado" &&
          parent.id === "enunciado"
        ) {
          return false;
        } else if (
          item.data.id !== "comentario_alternativa" &&
          parent.id === "alternativas"
        ) {
          return false;
        }
      }
    }

    return true;
  };

  return (
    <Modal
      width={900}
      action={actionState}
      options={footer()}
      title={
        currentAction.action == "criar-estrutura-exportacao"
          ? "Nova estrutura de exportação"
          : `Editar estrutura de exportação #${currentAction.id}`
      }
    >
      <CriarEstruturaExportacaoContent>
        <EstruturaExportacaoContent>
          <div className="form-group">
            <div className="field-group">
              <label>Nome da estrutura</label>
              <InputForm
                className="nome-estrutura form-group"
                value={nomeEstrutura}
                placeholder="Informe o nome"
                onChange={(value) => {
                  setNomeEstrutura(value.target.value);
                }}
              />
            </div>
          </div>
          <DragAndDropList
            allOpen={true}
            dragAndDropName={`estruturaExportacao`}
            context={estruturaQuestaoCX}
            canDropItem={canDropItem}
            customDragHandling={customDragHandling}
            maxLevel={2}
          />
        </EstruturaExportacaoContent>
      </CriarEstruturaExportacaoContent>
    </Modal>
  );
};
