import { useEffect, useState } from "react";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
  Questao,
  QuestaoItem,
} from "../../../../services/api-services/questao";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { QuestaoConsumer, useQuestaoContext } from "../../../CriarQuestao/context";
import { ListaReferenciasLegais } from "../referencias-legais";
import { QuestaoCardBody } from "./body";
import { QuestaoCardFooter } from "./footer";
import { FormQuestaoAtualizacao } from "./form-atualizacao";
import { QuestaoCardHeader } from "./header";
import { MenusCardStyles, QuestaoCardComponenteStyles } from "./styles";

export interface QuestaoCardComponentProps extends QuestaoCardProps {
  elem: ItemDataSelect<Questao>;
  index: number;
}

export interface QuestaoCardProps {
  selectedEntitiesAction: SelectedDataActions<Questao>;
  mode: string;
}

export enum QuestaoCardState {
  FORM_ATUALIZACAO = "form_atualizacao",
  VISUALIZACAO = "visualizacao",
}

export const QuestaoCardComponente = ({
  elem,
  index,
  mode,
  selectedEntitiesAction,
}: QuestaoCardComponentProps) => {

  const questaoContext = useQuestaoContext();
  const questaoItem = elem.entity as QuestaoItem;
  const context = useGerenciarContext();
  const auth = useAuthContext();
  const { roles } = auth.user;

  const [stateCard, setStateCard] = useState<QuestaoCardState>(
    QuestaoCardState.VISUALIZACAO
  );

  const abrirRefsState = useState<boolean>(false);

  const [abrirReferencias, setAbrirReferencias] = abrirRefsState;

  useEffect(() => {

    if (abrirReferencias || stateCard === QuestaoCardState.FORM_ATUALIZACAO) {
      //atualiza questao
      questaoContext.callbacks.setIdQuestao(questaoItem.id as unknown as string);
    } else if (abrirReferencias === false && stateCard === QuestaoCardState.VISUALIZACAO) {
      //atualiza questao
      questaoContext.callbacks.setIdQuestao(undefined);
    }

  }, [abrirReferencias, stateCard])

  const renderBody = (statusCard: QuestaoCardState) => {
    switch (statusCard) {
      case QuestaoCardState.VISUALIZACAO: {
        return <QuestaoCardBody questao={questaoItem} />;
      }
      case QuestaoCardState.FORM_ATUALIZACAO: {
        return (
          <MenusCardStyles>
            <QuestaoCardBody questao={questaoItem} />
            <QuestaoConsumer
              children={(context) => {
                return <FormQuestaoAtualizacao />;
              }}
            />
          </MenusCardStyles>
        );
      }
    }
  };

  const onChangeStateCard = (state: QuestaoCardState) => {
    setStateCard(state);
  };

  return (
    <QuestaoCardComponenteStyles>
      <CardItem
        settings={{
          width: "100%",
        }}
        header={
          <QuestaoCardHeader
            elem={elem}
            stateCard={stateCard}
            index={index}
            onChangeStateCard={onChangeStateCard}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
        body={renderBody(stateCard)}
        footer={
          <QuestaoCardFooter
            abrirRefsState={abrirRefsState}
            questao={questaoItem}
          />
        }
        options={
          !roles.includes("ROLE_CLASSIFICADOR")
            ? [
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  window.open(
                    `/questao/editar/${elem.entity.id}`,
                    "_blank"
                  );
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: elem.entity.id,
                  });
                }}
              />,
            ]
            : []
        }
      />
      {abrirReferencias && (
        <QuestaoConsumer
          children={(questaoContext) => {
            return (
              <ListaReferenciasLegais
                className="lista-referencias-legais"
                referencias_legais={questaoContext.questao.referencias_legais}
                externalActionState={context.currentActionState}
                modoAtualizacao={true}
                agruparAcima
              />
            );
          }}
        />
      )}
    </QuestaoCardComponenteStyles>
  );
};
