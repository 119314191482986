import { IconBaseProps } from "react-icons";
import { ButtonBottomLess, TButtonBottomLess } from "../button/bottomless";
import { TextItemContent } from "./styles";



export interface TTextItem {
    title?: string;
    icon?: React.ComponentType<IconBaseProps>
    button?: TButtonBottomLess
}

export const TextItem: React.FC<TTextItem> = ({ button, title, icon: Icon, children }) => {
    return (
        <TextItemContent>
            {Icon &&
                <Icon className={'icon-title'} size={'24px'} />
            }
            {title &&
                <div className="content">
                    <h4>{title}</h4>
                    {children}
                </div>
            }
            {button &&
                <div className="button-item">
                    <ButtonBottomLess {...button} />
                </div>
            }
        </TextItemContent>
    );
}