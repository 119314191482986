import { AxiosInstance } from 'axios';
import { ItemSelect, ItemsSelectPage } from '../../../components/page/structure/FilterComponent/types';
import { Service } from '../../service';
import { PaginationData } from './../../../components/pagination/types';

export class AutoresService extends Service {
  private ENDPOINT = 'author';

  constructor(axios: AxiosInstance) {
    super(axios);
  }

  async listarAutores(page: number, qtd: number, nome: string): Promise<ItemsSelectPage> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${nome}*'&page=${page}&size=${qtd}&sort=nome&order=asc`)
      .then((response) => {
        const array = new Array<ItemSelect>();

        response.data.content.forEach((value: Autor) =>
          array.push({
            id: value.id,
            value: value.name,
            key: 'authorName',
            useId: false,
          })
        );

        return {
          content: array,
          total_number_pages: response.data.total_number_pages,
        };
      });
  }

  async listagemAutores(
    page: number,
    qtd: string,
    search: string,
    sort: string,
    order: string
  ): Promise<AutoresPagination> {
    return this.fetch
      .get(this.ENDPOINT + `?search=name:'*${search}*'&page=${page}&size=${qtd}&sort=${sort}&order=${order}`)
      .then((response) => {
        let autores = new Array<Autor>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        autores = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: autores,
          paginationData: pagination,
        };
      });
  }

  async buscarAutor(id: number): Promise<Autor> {
    return this.fetch.get(this.ENDPOINT + `/${id}`).then((response) => {
      return response.data;
    });
  }

  async salvarAutor(autor: AutorRequest): Promise<Autor> {
    return this.fetch
      .post(this.ENDPOINT, autor, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async editarAutor(autor: AutorRequest): Promise<Autor> {
    return this.fetch
      .put(this.ENDPOINT + `/${autor.id}`, autor, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async excluirAutor(id: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `/${id}`);
  }
}

export const useAutorService = (axios: AxiosInstance) => {
  return new AutoresService(axios);
};

export interface Autor {
  id: number;
  name: string;
}

export interface AutorRequest {
  id?: number;
  name: string;
}

export type AutoresPagination = {
  content: Array<Autor>;
  paginationData: PaginationData;
};
