import styled from "styled-components";
import { SidebarConfig } from "../../../../GlobalStyle";

export const PageBaseContent = styled.header`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 80px;
  padding: 0px 12px 0px 0px;
  margin-left: ${(config: SidebarConfig) => (config.active ? "250px" : "12px")};


  @media (max-width: 700px) {
    margin-left: 12px;
  }

`;

export const HeaderComponent = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
    padding: 18px;
    background-color: var(--color-content-light);
    border-bottom: 1px solid var(--color-content-border);

    section {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: 500;
        color: var(--color-text-dark);
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
`
