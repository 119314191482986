import { Fragment, useState } from "react";
import { ActionType } from "../../../components/page/GerenciarPageBase/types";
import { ListaReferenciasLegais } from "../../GerenciarQuestoes/views/referencias-legais";
import { CriarTipoLei } from "../../GerenciarTipoLeis/forms/criar-tipo-lei";
import { useQuestaoContext } from "../context";
import { ReferenciasLegaisForm } from "./styles";

export const ReferenciasLegais = () => {

    const currentActionState = useState<ActionType>({
        id: 0,
        action: "",
    });

    const [currentAction, setCurrentAction] = currentActionState;

    const questaoContext = useQuestaoContext();

    const options = () => {
        switch (currentAction.action) {
            case "criar-tipo-lei": {
                return <CriarTipoLei externalAction={currentActionState} />;
            }
            default: {
                return <Fragment />;
            }
        }
    };

    return (
        <Fragment>
            <ReferenciasLegaisForm>
                <ListaReferenciasLegais
                    modoAtualizacao={true}
                    agruparAcima={false}
                    externalActionState={currentActionState}
                    referencias_legais={questaoContext.questao.referencias_legais}
                />
            </ReferenciasLegaisForm>
            {options()}
        </Fragment>
    );
};
