import { CheckBox } from "../../../../components/checkbox";
import { ThreeDotsLink } from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { FonteRespostaDetailsRest, IndicacaoFonteRespostaDetails } from "../../../../services/api-services/indicacaoFonteResposta";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";

export const IndicacaoFonteRespostaRow = (
  selectedEntitiesAction: SelectedDataActions<IndicacaoFonteRespostaDetails>
) => {
  const QuestaoRowComponent = (
    elem: ItemDataSelect<IndicacaoFonteRespostaDetails>,
    index: number
  ) => {

    const context = useGerenciarContext();
    const indicacao = elem.entity as IndicacaoFonteRespostaDetails;
    const fonteResposta = indicacao.source_response;

    const auth = useAuthContext();
    const { roles } = auth.user;

    function montarReferenciaCompleta(source_response: FonteRespostaDetailsRest) {

      const { paragraph, clause, subClause, item } = source_response;

      const referencia = new Array<string>();

      if (paragraph) referencia.push(`§ ${paragraph}`);
      if (clause) referencia.push(`Inc. ${clause}`);
      if (subClause) referencia.push(`Al. ${subClause}`);
      if (item) referencia.push(`Item ${item}`);

      return referencia.join(', ');

    }

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={false}
            options={!roles.includes("ROLE_CLASSIFICADOR") && indicacao.status_indication === "Pendente" ? [
              <ThreeNormalOption
                name="Aprovar"
                onClick={() => {
                  context.changeAction({
                    action: "aprovar",
                    id: indicacao.id,
                    object: {
                      idQuestao: indicacao.question_id
                    }
                  });
                }}
              />,
              <ThreeNormalOption
                name="Reprovar"
                onClick={() => {
                  context.changeAction({
                    action: "reprovar",
                    id: indicacao.id,
                    object: {
                      idQuestao: indicacao.question_id
                    }
                  });
                }}
              />
            ] : []}
          />
        </RowItem>
        <RowItem>
          {indicacao.id}
        </RowItem>
        <RowItem>
          {fonteResposta.legalSource}
        </RowItem>
        <RowItem>
          {fonteResposta.lawType}
        </RowItem>
        <RowItem width="400px">
          {`${fonteResposta.law ? fonteResposta.law : ``}${fonteResposta.article ? `, Art. ${fonteResposta.article}` : ``}`}
        </RowItem>
        <RowItem width="800px">
          {montarReferenciaCompleta(fonteResposta)}
        </RowItem>
        <RowItem onClick={() => {
          if (!roles.includes("ROLE_CLASSIFICADOR")) {
            window.open(`/questao/${indicacao.question_id}`, "_blank");
          }
        }}>
          {indicacao.question_id}
        </RowItem>
        <RowItem>
          {indicacao.student_nickname}
        </RowItem>
        <RowItem>
          {indicacao.indication_date}
        </RowItem>
        <RowItem>
          {indicacao.status_indication}
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    header: QuestaoRowComponent,
  };
};
