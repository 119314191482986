import { BsListNested } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { CardView } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { Tag } from "../../../../components/tag";
import { ProjetoResume } from "../../../../services/api-services/projeto";
import { clearStringURL } from "../../../../utils/string";
import { LabelValue } from "../../../Projeto/elements";
import { CodigoID } from "../../elements";
import { HeaderStyle } from "./styles";

export const ProjetoCard = (
  selectedEntitiesAction: SelectedDataActions<ProjetoResume>,
  mode: string
) => {
  const history = useHistory();

  const projetoCard = (elem: ItemDataSelect<ProjetoResume>, index: number) => {
    return (
      <CardView
        handleClick={() => {
          history.push(`/edicao-projeto/${elem.entity.id}`);
        }}
        settings={{
          width: "100%",
        }}
        options={[
          <ThreeNormalOption
            name="Visualizar questões"
            icon={BsListNested}
            onClick={() => {
              window.open(
                `/projeto-questoes/${elem.entity.id}/${clearStringURL(
                  elem.entity.name
                )} ${elem.entity.year}`,
                "_blank"
              );
            }}
          />,
        ]}
        buttonIcon={MdArrowForwardIos}
        key={index}
        header={
          <HeaderContent
            elem={elem}
            index={index}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
      />
    );
  };

  return {
    projetoCard: projetoCard,
  };
};

const HeaderContent: React.FC<{
  index: number;
  elem: ItemDataSelect<ProjetoResume>;
  mode: string;
  selectedEntitiesAction: SelectedDataActions<ProjetoResume>;
}> = (props) => {
  const projeto = props.elem.entity as ProjetoResume;

  return (
    <HeaderStyle>
      <CodigoID name={""} id={projeto.id} classname={"item-margin"} />
      <Tag nome={`${projeto.name} - ${projeto.year}`} />
      <Tag colorDark={"color-primary-light"} nome={`${projeto.visibility}`} />
      {projeto.current_username && (
        <LabelValue
          label={"No modo de edição: "}
          value={projeto.current_username}
        />
      )}
    </HeaderStyle>
  );
};
