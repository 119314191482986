import styled from "styled-components";

export const StatusContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px;

  div {
    font-weight: 500;
    font-size: 1rem;
    margin-left: 12px;
  }
`;
