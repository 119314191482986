import { CheckBox, CheckBoxItem, GroupCheckBox } from "jusadmin-ui-kit";
import React, { ChangeEvent, FormEvent, Fragment, useEffect, useState } from "react";
import { VscSave } from "react-icons/vsc";
import { useParams } from "react-router";
import { ButtonBottomLess } from "../../components/button/bottomless";
import { InputForm } from "../../components/input";
import { BaseComponent } from "../../components/page/structure/BaseComponent";
import { NormalSelect, TItemSelect } from "../../components/select/normal";
import {
  autoridadesOptions
} from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  Permission,
  PermissionID,
  useUsuarioService
} from "../../services/api-services/usuarios";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { countProperties, validar } from "../../utils/validacao";
import { UsuarioForm } from "./styles";

interface Registro {
  nome: string;
  sobrenome: string;
  email: string;
  nomeUsuario: string;
  senha: "";
  senhaConfirmacao: "";
}

interface Erros {
  nome: string;
  sobrenome: string;
  nomeUsuario: string;
  email: string;
  senha: string[];
  senhaConfirmacao: string[];
}

interface Props {
  id: string;
}

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const CriarUsuario: React.FC<{}> = () => {
  //
  const auth = useAuthContext();

  const { id } = useParams<Props>();

  const services = {
    usuarioService: useUsuarioService(APIFetch(auth)),
  };

  const permissoesDaQuestaoState = useState<Array<CheckBoxItem>>(
    new Array<CheckBoxItem>()
  );

  const permissoesDaLegislacaoState = useState<Array<CheckBoxItem>>(
    new Array<CheckBoxItem>()
  );

  const [permissoesDaQuestao, setPermissoesDaQuestao] = permissoesDaQuestaoState;

  const [permissoesDaLegislacao, setPermissoesDaLegislacao] = permissoesDaLegislacaoState;

  const [bancoLegislacaoSelecionado, setBancoLegislacaoSelecionado] = useState<CheckBoxItem>();

  const [bancoQuestoesSelecionado, setBancoQuestoesSelecionado] = useState<CheckBoxItem>();

  const [autoridade, setAutoridade] = useState<TItemSelect>(
    autoridadesOptions[0]
  );

  const [register, setRegister] = useState<{
    fields: Registro;
    erros: Erros;
  }>({
    fields: {
      nome: "",
      nomeUsuario: "",
      senha: "",
      senhaConfirmacao: "",
      email: "",
      sobrenome: "",
    },
    erros: {
      nome: "",
      nomeUsuario: "",
      senha: [],
      email: "",
      senhaConfirmacao: [],
      sobrenome: "",
    },
  });

  useEffect(() => {

    const check = (checkbox: CheckBoxItem[], permissao: Permission[]) => {
      return checkbox.map((item) => {
        const find = permissao.find((p) => p.id === item.id);

        if (find) {
          item.isChecked = true;
        }

        return item;
      });
    };

    const fetchData = async () => {


      const usuario = id ? await services.usuarioService.buscarUsuario(parseInt(id)) : undefined;

      if (usuario) {

        setRegister({
          fields: {
            nome: usuario.name,
            sobrenome: usuario.surname,
            nomeUsuario: usuario.username,
            email: usuario.email,
            senha: "",
            senhaConfirmacao: "",
          },
          erros: {
            nome: "",
            nomeUsuario: "",
            senha: [],
            email: "",
            senhaConfirmacao: [],
            sobrenome: "",
          },
        });


      }


      services.usuarioService
        .listarPermissoes("")
        .then((permissoes) => {

          let permissaoBancoQuestoes = permissoes.find((value) => value.value === "JusQuestões");
          let permissaoBancoLegislacao = permissoes.find((value) => value.value === "JusLegis");

          if (usuario) {

            usuario.permissions.forEach((permissao) => {

              if (permissao.name === "JusQuestões") {

                if (permissaoBancoQuestoes) {
                  permissaoBancoQuestoes.isChecked = true;
                }

              } else if (permissao.name === "JusLegis") {

                if (permissaoBancoLegislacao) {
                  permissaoBancoLegislacao.isChecked = true;
                }

              }

            });

          }

          setBancoQuestoesSelecionado(permissaoBancoQuestoes);
          setBancoLegislacaoSelecionado(permissaoBancoLegislacao);

        });


      const permissaoQuestoes = await services.usuarioService
        .listarPermissoes("JusQuestões");

      const permissaoLegislacoes = await services.usuarioService
        .listarPermissoes("JusLegis");


      if (usuario) {

        checkAutoridade(usuario.authority);
        check(permissaoQuestoes, usuario.permissions);
        check(permissaoLegislacoes, usuario.permissions);

      }


      setPermissoesDaQuestao(permissaoQuestoes);
      setPermissoesDaLegislacao(permissaoLegislacoes);

    }

    fetchData().catch((error) => {
      console.log(error);
    })

  }, []);

  useEffect(() => {

    const erros = register.erros;
    const fields = register.fields;

    erros.nome =
      fields.nome.length < 2 ? "Nome deve ter mais que 2 caracteres..." : "";

    erros.sobrenome =
      fields.sobrenome.length < 2
        ? "Sobrenome deve ter mais que 2 caracteres..."
        : "";

    erros.nomeUsuario =
      fields.nomeUsuario.length < 6
        ? "Nome de usuário deve ter mais que 6 caracteres..."
        : "";


    if (EMAIL_REGEX.exec(fields.email)) {
      erros.email = ""
    } else {
      erros.email = "Informe um email válido";
    }

    const senhaErros = validar(fields.senha, "Senha");

    erros.senha = senhaErros.length > 0 ? senhaErros : [];

    const senhaConfirmacaoErros = validar(fields.senhaConfirmacao, "Senha");

    if (fields.senhaConfirmacao !== fields.senha) {
      senhaConfirmacaoErros.push(
        "A senha de confirmação deve ser igual a senha anteriormente informada..."
      );
    }

    erros.senhaConfirmacao =
      senhaConfirmacaoErros.length > 0 ? senhaConfirmacaoErros : [];

    setRegister({
      erros: erros,
      fields: fields,
    });

  }, []);


  const salvarUsuario = (e: FormEvent) => {

    e.preventDefault();

    const aplicacaoNaoSelecionada = !bancoLegislacaoSelecionado?.isChecked && !bancoQuestoesSelecionado?.isChecked;

    const errosDeValidacao = countProperties(register.erros) > 0;

    if (id) {

      if (errosDeValidacao) {

        alert(
          "Existem erros de validação, revise os campos e tente novamente!"
        );

      } else {


        if (aplicacaoNaoSelecionada) {

          alert("Selecione ao menos uma permissão em nível de aplicação")

        } else {

          services.usuarioService
            .editarUsuario({
              id: parseInt(id),
              name: register.fields.nome,
              surname: register.fields.sobrenome,
              username: register.fields.nomeUsuario,
              password: register.fields.senha,
              email: register.fields.email,
              confirmed_password: register.fields.senhaConfirmacao,
              authority: autoridade.value,
              permissions: getPermissoes(),
            })
            .then((usuario) => {
              alert("Conta atualizada com sucesso!!!");
            })
            .catch((error) => {
              alert(error.response.data.message);
            });

        }
      }

    } else {

      if (errosDeValidacao) {

        alert(
          "Existem erros de validação, revise os campos e tente novamente!"
        );

      } else {

        if (aplicacaoNaoSelecionada) {

          alert("Selecione ao menos uma permissão em nível de aplicação")

        } else {

          services.usuarioService
            .salvarUsuario({
              name: register.fields.nome,
              surname: register.fields.sobrenome,
              username: register.fields.nomeUsuario,
              password: register.fields.senha,
              email: register.fields.email,
              confirmed_password: register.fields.senhaConfirmacao,
              authority: autoridade.value,
              permissions: getPermissoes(),
            })
            .then((usuario) => {
              alert("Nova conta de usuário criado com sucesso!!!");
            })
            .catch((error) => {
              alert(error.response.data.message);
            });

        }


      }
    }

  };

  const validarField = (name: string, value: string) => {
    const fields = register.fields;
    const erros = register.erros;

    switch (name) {
      case "nome": {
        erros.nome =
          value.length < 2 ? "Nome deve ter mais que 2 caracteres..." : "";
        break;
      }
      case "sobrenome": {
        erros.sobrenome =
          value.length < 2 ? "Sobrenome deve ter mais que 2 caracteres..." : "";
        break;
      }
      case "nomeUsuario": {
        erros.nomeUsuario =
          value.length < 6
            ? "Nome de usuário deve ter mais que 6 caracteres..."
            : "";
        break;
      }
      case "email": {
        if (EMAIL_REGEX.exec(value)) {
          erros.email = ""
        } else {
          erros.email = "Informe um email válido";
        }
        break;
      }
      case "senha": {
        const arrayErros = validar(value, "Senha");
        erros.senha = arrayErros.length > 0 ? arrayErros : [];
        break;
      }
      case "senhaConfirmacao": {
        const arrayErros = validar(value, "Senha");

        if (value !== fields.senha) {
          arrayErros.push(
            "A senha de confirmação deve ser igual a senha anteriormente informada..."
          );
        }

        erros.senhaConfirmacao = arrayErros.length > 0 ? arrayErros : [];
        break;
      }
    }

    setRegister({
      erros: erros,
      fields: { ...fields, [name]: value },
    });
  };

  const onChangePlataforma = (event: ChangeEvent<HTMLInputElement>) => {

    const { name, checked } = event.currentTarget;

    if (name === "jusquestoes") {

      if (!checked) {

        setPermissoesDaQuestao(permissoesDaQuestao.map(value => {
          value.isChecked = false;
          return value;
        }));

      }
      if (bancoQuestoesSelecionado) {

        setBancoQuestoesSelecionado({
          ...bancoQuestoesSelecionado,
          isChecked: checked
        });

      }

    } else if (name === "juslegis") {

      if (!checked) {

        setPermissoesDaLegislacao(permissoesDaLegislacao.map(value => {
          value.isChecked = false;
          return value;
        }));

      }

      if (bancoLegislacaoSelecionado) {
        setBancoLegislacaoSelecionado({
          ...bancoLegislacaoSelecionado,
          isChecked: checked
        });
      }

    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    event.preventDefault();
    const { name, value } = event.target;
    validarField(name, value);

  };


  const getPermissoes = () => {

    const temp = new Array<PermissionID>();

    permissoesDaQuestao.forEach((item) => {
      if (item.isChecked) {
        temp.push({
          id: item.id,
        });
      }
    });

    permissoesDaLegislacao.forEach((item) => {
      if (item.isChecked) {
        temp.push({
          id: item.id,
        });
      }
    });

    if (bancoLegislacaoSelecionado && bancoLegislacaoSelecionado.isChecked) {
      temp.push({
        id: bancoLegislacaoSelecionado.id
      });
    }

    if (bancoQuestoesSelecionado && bancoQuestoesSelecionado.isChecked) {
      temp.push({
        id: bancoQuestoesSelecionado.id
      });
    }


    return temp;
  };

  const checkAutoridade = (autoridade: string) => {

    const find = autoridadesOptions.find((item) => item.value === autoridade);

    if (find) {
      setAutoridade(find);
    }

  };

  const showErros = (erros: string[]) => {
    return erros.map((item) => {
      return <span className="error">{item}</span>;
    });
  };

  const onCheckPermissao = (item: CheckBoxItem, name: string) => {

    if (name === "jusquestoes") {

      if (!bancoQuestoesSelecionado?.isChecked && item.isChecked) {

        bancoQuestoesSelecionado && setBancoQuestoesSelecionado({
          ...bancoQuestoesSelecionado,
          isChecked: true
        });

      }

    } else if (name === "juslegis") {

      if (!bancoLegislacaoSelecionado?.isChecked && item.isChecked) {

        bancoLegislacaoSelecionado && setBancoLegislacaoSelecionado({
          ...bancoLegislacaoSelecionado,
          isChecked: true
        });

      }

    }


  }

  const onChangeAutoridade = (item: TItemSelect) => {


    setPermissoesDaLegislacao(permissoesDaLegislacao.map(value => {
      value.isChecked = false;
      return value;
    }));

    setPermissoesDaQuestao(permissoesDaQuestao.map(value => {
      value.isChecked = false;
      return value;
    }));

    setAutoridade(item);

  }


  return (
    <form onSubmit={salvarUsuario} autoComplete={"off"}>
      <BaseComponent
        pageName={id ? "Editar usuário" : "Criar usuário"}
        headerLeft={<div />}
        headerRight={
          <ButtonBottomLess
            icon={VscSave}
            title={"Salvar"}
            className={"save-usuario"}
            type={"submit"}
          />
        }
      >
        <UsuarioForm>
          <h4>Informações do usuário</h4>
          <div className="form-group">
            <div className="field-group">
              <label htmlFor={"nomeUsuario"}>Nome de usuário *</label>
              <InputForm
                className="nomeUsuario field-size"
                type="text"
                name={"nomeUsuario"}
                value={register.fields.nomeUsuario}
                maxLength={100}
                onChange={handleChange}
                placeholder="Informe nome de usuário"
                formNoValidate
              />
              {register.erros.nomeUsuario.length > 0 && (
                <span className="error">{register.erros.nomeUsuario}</span>
              )}
            </div>
            <div className="field-group">
              <label htmlFor={"email"}>Email do usuário *</label>
              <InputForm
                className="email field-size"
                type="email"
                name={"email"}
                value={register.fields.email}
                maxLength={100}
                onChange={handleChange}
                placeholder="Informe email"
                formNoValidate
              />
              {register.erros.email.length > 0 && (
                <span className="error">{register.erros.email}</span>
              )}
            </div>
            <div className="field-group">
              <label htmlFor={"nome"}>Nome *</label>
              <InputForm
                className="nome field-size"
                type="text"
                name={"nome"}
                maxLength={100}
                value={register.fields.nome}
                onChange={handleChange}
                placeholder="Informe nome"
                formNoValidate
              />
              {register.erros.nome.length > 0 && (
                <span className="error">{register.erros.nome}</span>
              )}
            </div>
            <div className="field-group">
              <label htmlFor={"sobrenome"}>sobrenome *</label>
              <InputForm
                className="sobrenome field-size"
                type="text"
                value={register.fields.sobrenome}
                name={"sobrenome"}
                maxLength={100}
                onChange={handleChange}
                placeholder="Informe o sobrenome"
                formNoValidate
              />
              {register.erros.sobrenome.length > 0 && (
                <span className="error">{register.erros.sobrenome}</span>
              )}
            </div>
          </div>

          {!id && (
            <Fragment>
              <h4>Preenchimento de senha</h4>
              <div className="form-group">
                <div className="field-group">
                  <label htmlFor={"senha"}>Senha</label>
                  <InputForm
                    className="senha field-size"
                    type="password"
                    name={"senha"}
                    value={register.fields.senha}
                    onChange={handleChange}
                    maxLength={100}
                    placeholder="Informe uma senha"
                    formNoValidate
                  />
                  {showErros(register.erros.senha)}
                </div>
              </div>
              <div className="form-group">
                <div className="field-group">
                  <label htmlFor={"senhaConfirmacao"}>
                    Confirmação da senha
                  </label>
                  <InputForm
                    className="senhaConfirmacao field-size"
                    type="password"
                    value={register.fields.senhaConfirmacao}
                    name={"senhaConfirmacao"}
                    onChange={handleChange}
                    maxLength={100}
                    formNoValidate
                    placeholder="Informe a senha novamente"
                  />
                  {showErros(register.erros.senhaConfirmacao)}
                </div>
              </div>
            </Fragment>
          )}
          <h4>Autoridade</h4>
          <div className="form-group">
            <div className="field-group">
              <NormalSelect
                minWidth={200}
                className="fonte"
                label="Selecione o nível"
                selectItem={autoridade}
                onSelectCurrentItem={onChangeAutoridade}
                selectedItems={autoridadesOptions}
              />
            </div>
          </div>
          <h4>Permissões</h4>
          <div className="form-group">
            <div className="field-checkbox">
              <CheckBox name="jusquestoes" label="Banco de Questões" onChange={onChangePlataforma} checked={bancoQuestoesSelecionado ? bancoQuestoesSelecionado.isChecked : false} />
              {autoridade.value === "Editor" &&
                <GroupCheckBox isMultiSelect={true} state={permissoesDaQuestaoState} onCheckItem={(item) => onCheckPermissao(item, "jusquestoes")} />
              }
              <CheckBox name="juslegis" label="Banco de Legislação" onChange={onChangePlataforma} checked={bancoLegislacaoSelecionado ? bancoLegislacaoSelecionado.isChecked : false} />
              {autoridade.value === "Editor" &&
                <GroupCheckBox isMultiSelect={true} state={permissoesDaLegislacaoState} onCheckItem={(item) => onCheckPermissao(item, "juslegis")} />
              }
            </div>
          </div>
        </UsuarioForm>
      </BaseComponent>
    </form>
  );
};
