import React, { Fragment, ReactNode } from "react";
import { CheckBox } from "../../../../checkbox";
import { Pagination } from "../../../../pagination";
import { useGerenciarContext } from "../../../GerenciarPageBase/context";
import useMoveScroll from "../../ScrollMove";
import {
  HeaderList,
  ListResponsibleContent,
  MainList,
  PaginationContent
} from "../styles";

type TListComponent = {
  headerElements?: Array<ReactNode>;
  dataColumn: () => Array<ReactNode>;
  filterBar?: {
    left?: ReactNode;
    right?: ReactNode;
  };
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkAnyIdIsSelected: () => boolean;
};

interface TRowItem extends React.TableHTMLAttributes<HTMLTableDataCellElement> {
  width?: string;
}

export const ListTableComponent: React.FC<TListComponent> = (props) => {

  const { checkAnyIdIsSelected, onCheckAll, headerElements, dataColumn } =
    props;

  const contentTableRef = React.useRef<HTMLDivElement>(null);

  const { paginationData, resetPagination, toPage, changeLoading } =
    useGerenciarContext();

  useMoveScroll(contentTableRef);

  return (
    <MainList>
      <section>
        {paginationData.total_items} resultados na página atual ({paginationData.current_page + 1}{" "}
        - {paginationData.total_number_pages})
      </section>
      <ListResponsibleContent ref={contentTableRef}>
        <table>
          <thead>
            <HeaderList>
              <th className={"header-item"}>
                <CheckBox
                  checked={checkAnyIdIsSelected()}
                  onChange={(e) => {
                    onCheckAll(e);
                  }}
                />
              </th>
              <Fragment>
                {headerElements?.map((elem, index) => {
                  return (
                    <th key={index} className="header-item">
                      {elem}
                    </th>
                  );
                })}
              </Fragment>
            </HeaderList>
          </thead>
          <tbody>{dataColumn()}</tbody>
        </table>
      </ListResponsibleContent>
      <PaginationContent>
        <div></div>
        <Pagination
          isInitialPage={resetPagination()}
          totalNumberPages={paginationData.total_number_pages}
          onCurrentPage={(page) => {
            toPage(page);
            changeLoading(true);
          }}
        />
      </PaginationContent>
    </MainList>
  );
};

export const RowItem: React.FC<TRowItem> = ({ children, width, ...rest }) => {
  return (
    <td
      className="body-item"
      {...rest}
      style={{
        width: width && width,
      }}
    >
      {children}
    </td>
  );
};
