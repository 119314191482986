import { Fragment } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { TNoAuthorizationRoute } from "../types";

export const NoauthRoute = ({ component, exact, path }: TNoAuthorizationRoute): JSX.Element => {

    const { type: Component, props } = component;

    const renderComponent = (routeProps: RouteComponentProps) => {
        return <Fragment>{<Component {...routeProps} {...props} />}</Fragment>;
    };

    return <Route exact={exact} path={path} render={routeProps => (
        <Fragment>
            {renderComponent(routeProps)}
        </Fragment>
    )} />;
};