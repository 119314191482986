import { MesclarEntidadesState } from '../../../../components/app/mesclar-entidade';
import { CheckBox } from '../../../../components/checkbox';
import { ThreeDotsLink } from '../../../../components/menu/three-dots';
import { ThreeNormalOption } from '../../../../components/menu/three-normal';
import { useGerenciarContext } from '../../../../components/page/GerenciarPageBase/context';
import { RowItem } from '../../../../components/page/structure/ListComponent/list-table';
import {
  ItemDataSelect,
  SelectedDataActions
} from '../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction';
import { ListTableRow } from '../../../../components/page/structure/ListComponent/styles';
import { Cargo } from '../../../../services/api-services/cargo';

export const CargoHeader = (selectedEntitiesAction: SelectedDataActions<Cargo>) => {
  const RowCargo = (elem: ItemDataSelect<Cargo>, index: number) => {

    const cargo = elem.entity as Cargo;
    const context = useGerenciarContext();

    const onClickMesclar = () => {

      const selecionados = selectedEntitiesAction.getSelectItems();

      if (selecionados.length >= 1) {

        const mesclagemState: MesclarEntidadesState = {
          destino: {
            id: cargo.id,
            nome: cargo.name
          },
          entidades: selecionados.map((value) => {
            return {
              entity: {
                id: value.entity.id,
                nome: value.entity.name
              },
              id_item: value.entity.id,
              isChecked: value.isChecked
            }
          })
        };

        context.changeAction({
          action: "mesclar-cargo",
          id: cargo.id,
          object: mesclagemState
        });

      } else {

        alert("Selecione um ou mais cargos para mesclagem");

      }

    };

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{cargo.id}</RowItem>
        <RowItem>{cargo.name}</RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeNormalOption
                name="Mesclagem de cargos"
                onClick={onClickMesclar}
              />,
              <ThreeNormalOption
                name="Editar"
                onClick={() => {
                  context.changeAction({
                    action: 'editar-cargo',
                    id: cargo.id,
                  });
                }}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: 'excluir',
                    id: cargo.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    header: RowCargo,
  };
};
