import { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoMdAlert } from "react-icons/io";
import { MdDelete, MdDoubleArrow } from "react-icons/md";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { Tag } from "../../../../../components/tag";
import { TReferencialegal } from "../../../../CriarQuestao/context";
import { referenciaToArray } from "../hooks/useDispositivosLegaisHook";
import { TagDispositivoLegal } from "../hooks/useDispositivosLegaisHook/types";
import { ReferenciaItem, ReferenciaItemBase } from "../styles";


export interface TReferenciaLegalItem {
  referencia: TReferencialegal;
  index: number;
  showOptions: boolean;
  onClickEditar?: (referencia: TReferencialegal, index: number) => void;
  onClickDeletar?: (referencia: TReferencialegal, index: number) => void;
}

export const ReferenciaLegalItem = ({
  referencia,
  index,
  showOptions,
  onClickEditar,
  onClickDeletar,
}: TReferenciaLegalItem) => {
  const [dispositivos, setDispositivos] = useState<TagDispositivoLegal[]>();
  const [foraPadrao, setForaPadrao] = useState(false);

  useEffect(() => {
    const grupos = referenciaToArray(referencia, false);

    setDispositivos(grupos);

    if (grupos.find((value) => value.erro)?.erro) {
      setForaPadrao(true);
    } else {
      setForaPadrao(false);
    }

  }, [referencia]);

  return (
    <ReferenciaItem>
      <ReferenciaItemBase>
        <div className="referencia-content">
          <div className="number">
            <b>{`${index}-)`}</b>
          </div>
          {foraPadrao && (
            <IoMdAlert size={24} color={"var(--color-status-desatualizada)"} />
          )}
          {referencia.tipo_lei && (
            <Tag
              nome={referencia.tipo_lei.entity?.name}
              title={"Classificação da Lei:"}
              colorDark={"color-primary-light"}
            />
          )}
          <div className="dispositivos-legais">
            <small className="label">Referência: </small>
            {dispositivos?.map((dispositivo, index, array) => {
              return (
                <div className="dispositivo">
                  <Tag nome={dispositivo.value} title={dispositivo.name} />
                  {array.length - 1 !== index && (
                    <MdDoubleArrow
                      className="path-arrow"
                      color="var(--color-primary-light)"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {showOptions && (
          <div className="referencia-options">
            <ButtonBottomLess
              className="editar-referencia"
              icon={FiEdit2}
              onClick={() => onClickEditar && onClickEditar(referencia, index)}
            />
            <ButtonBottomLess
              className="deletar-referencia"
              icon={MdDelete}
              onClick={() =>
                onClickDeletar && onClickDeletar(referencia, index)
              }
            />
          </div>
        )}
      </ReferenciaItemBase>
    </ReferenciaItem>
  );
};
