import { FormEvent, useEffect, useState } from "react";
import { VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { InputForm } from "../../../../components/input";
import { SidebarModal } from "../../../../components/modals/sidebar-modal";
import { SidebarForm } from "../../../../components/modals/sidebar-modal/styles";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch } from "../../../../services/api";
import { useDisciplinaService } from "../../../../services/api-services/disciplina";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";

export const CriarDisciplina: React.FC<{
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}> = ({ externalAction }) => {
  const auth = useAuthContext();
  const { changeAction, currentActionState } = useGerenciarContext();
  const actionState = externalAction ? externalAction : currentActionState;
  const [action, setAction] = actionState;

  const services = {
    disciplinaService: useDisciplinaService(APIFetch(auth)),
  };

  const [nome, setNome] = useState("");

  const dispatch = () => {
    const state = {
      ...action,
      loading: true,
    };

    externalAction ? setAction(state) : changeAction(state);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (action.action == "editar-disciplina") {
      services.disciplinaService
        .editarDisciplina({
          id: action.id,
          name: nome,
        })
        .then((response) => {
          dispatch();
          alert("Disciplina salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else if (action.action == "criar-disciplina") {
      services.disciplinaService
        .salvarDisciplina({
          name: nome,
        })
        .then((response) => {
          dispatch();
          alert("Disciplina salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    action.action == "editar-disciplina" &&
      services.disciplinaService
        .buscarDisciplina(action.id)
        .then((disciplina) => {
          setNome(disciplina.name);
        });
  }, []);

  return (
    <SidebarModal
      action={actionState}
      title={
        action.action == "criar-disciplina"
          ? "Nova disciplina"
          : `Editar disciplina #${action.id}`
      }
    >
      <SidebarForm onSubmit={onSubmit}>
        <small>Nome</small>
        <InputForm
          className="nome form-group"
          placeholder="Informe o nome"
          value={nome}
          onChange={(value) => {
            setNome(value.currentTarget.value);
          }}
        />
        <ButtonNormal
          icon={VscSave}
          title={"Salvar"}
          className={"save"}
          type={"submit"}
        />
      </SidebarForm>
    </SidebarModal>
  );
};
