import styled from 'styled-components';

export const QuestaoItemContent = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .titulos {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

  .divisao-options{

    .tipo-titulo {
    }

    .titulo {
      flex: 3 2 0;
    }

    .tipo-conteudo {
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
  }

}
    

`;