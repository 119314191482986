import styled from 'styled-components';

export const AssistenteEstruturaModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FiltrosOrdenacao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;


export const AssistenteEstruturaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 14px;

  .button-content{
    
    display:flex;
    justify-content: flex-end;
    flex-direction: row;

    .proxima-etapa-button{
       margin-top: 18px;
        margin-bottom: 18px;
    }
    
  }

  

  .niveis-option{
    display: flex;
    flex-direction: row;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .field-group {
      margin-right: 24px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        color: var(--color-text-grey);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-top: 8px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;

export interface NivelItemContentStyles {
  row: {
    marginLeft: number
  }
}

export const ListaNiveisContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const NivelItemContent = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--color-primary-dark);  
    background-color: var(--color-primary-clear);
    border-radius: 8px;
    padding: 2px;
    width: ${(settings: NivelItemContentStyles) => `${100 - settings.row.marginLeft}%`} ;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;
    max-height: 64px;

    .titulo{
    }

    small,label {
      color: var(--color-primary-dark);
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 8px;
      margin-left: 8px;
  }

    div .agrupar{
      
    }

    div{
    }

`