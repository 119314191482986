import React, { useEffect, useState } from "react";
import { Select } from "..";
import { ItemSelect } from "../../page/structure/FilterComponent/types";
import { SubMenuItem } from "../styles";
import {
  NormalSelectProvider,
  SelectNormalContext,
  useNormalSelect
} from "./context";

type TNormalSelect = {
  label?: string;
  selectItem?: TItemSelect;
  minWidth: number;
  className: string;
  selectedItems: Array<TItemSelect>;
  onSelectCurrentItem?: (item: TItemSelect) => void;
  getSelectedItems?: (items: Array<TItemSelect>) => void;
};

export interface TNormalSelectWithCX extends TNormalSelect {
  getContext?: (context: SelectNormalContext) => void;
}

export interface TItemSelect extends ItemSelect {
  selected: boolean;
}



export const NormalSelect: React.FC<TNormalSelectWithCX> = (props) => {
  //estado titulo do select

  const INITIAL_STATE = {
    id: -1,
    key: "select",
    useId: false,
    value: "",
    selected: false,
  };

  const [selectedItens, setSelectedItens] = useState<Array<TItemSelect>>(
    props.selectedItems
  );

  const [selectItem, setSelectItem] = useState<TItemSelect>(INITIAL_STATE);

  const [newItem, setNewItem] = useState(INITIAL_STATE);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.selectItem) {
      if (props.selectItem.id !== -1) {
        if (props.selectItem.id !== newItem.id) {
          setNewItem(props.selectItem);
        }
      }
    }
  }, [props.selectItem]);

  useEffect(() => {
    if (newItem != INITIAL_STATE) {
      if (newItem.value) {
        handleselectItem(newItem);
      }
    }
  }, [newItem]);

  const getTitle = () => {
    if (selectItem.value === "") {
      return props.label;
    } else {
      return selectItem.value;
    }
  };

  const handleselectItem = (selected: TItemSelect) => {
    selectItem.selected = false;
    const item = selected;
    item.selected = true;
    props.onSelectCurrentItem && props.onSelectCurrentItem(item);
    setSelectItem(item);
  };

  return (
    <NormalSelectProvider
      value={{
        selectedItens: selectedItens,
        selectItem: selectItem,
        open: open,
        callbacks: {
          getTitle: getTitle,
          handleselectItem: handleselectItem,
          setOpen: setOpen,
        },
      }}
    >
      <NormalSelectCX {...props} getContext={props.getContext} />
    </NormalSelectProvider>
  );
};

export interface TNormalSelectCX extends TNormalSelect {
  getContext?: (context: SelectNormalContext) => void;
}

export const NormalSelectCX: React.FC<TNormalSelectCX> = (props) => {
  const cx = useNormalSelect();

  useEffect(() => {
    props.getContext && props.getContext(cx);
  }, [cx.selectItem, cx.open, cx.selectedItens]);

  return (
    <Select
      minWidth={props.minWidth}
      onOpenSelect={cx.callbacks.setOpen}
      title={cx.callbacks.getTitle()}
      className={props.className}
    >
      {cx.selectedItens.map((value: TItemSelect, index) => (
        <SubMenuItem
          key={index}
          onClick={() => cx.callbacks.handleselectItem(value)}
        >
          <h5>{value.value}</h5>
        </SubMenuItem>
      ))}
    </Select>
  );
};
