import { Fragment, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { ItemTypes } from '../../../drag-and-drop/hooks/useLayoutDragSystem';
import { BottomScrollBar } from './styles';

export const InvisibleScrollBottom = () => {

  const navbarContentRef = useRef<HTMLDivElement>(null);

  const topMouseHover = useDrop({
    accept: [ItemTypes.PARENT, ItemTypes.CHILDREN],
    hover: (item: unknown, monitor: DropTargetMonitor<unknown, unknown>) => {
      window.scrollBy({
        behavior: 'smooth',
        top: +24
      });
    },
    drop: () => {

    },
    canDrop: (item: unknown, monitor) => {
      return false;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  });

  topMouseHover[1](navbarContentRef);


  return (
    <Fragment>
      <BottomScrollBar ref={navbarContentRef} />
    </Fragment >
  );
};
