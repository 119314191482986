/* eslint-disable react/react-in-jsx-scope */
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Switch } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import { ScrollToTop } from "./components/hooks/ScrollTop";
import { AuthRoute } from "./components/router/AuthRoute";
import { NoauthRoute } from "./components/router/NoAuthRoute";
import { NoAuthRouteBase } from "./components/router/NoAuthRouteBase";
import { BuscarProjetos } from "./pages/BuscarProjetos";
import { CriarProjeto } from "./pages/CriarProjeto";
import { CriarQuestao } from "./pages/CriarQuestao";
import { CriarUsuario } from "./pages/CriarUsuario";
import { Dashboard } from "./pages/Dashboard";
import { EdicaoProjeto } from "./pages/EdicaoDoProjeto";
import { GerenciarAutores } from "./pages/GerenciarAutores";
import { GerenciarBancas } from "./pages/GerenciarBancas";
import { GerenciarCargos } from "./pages/GerenciarCargos";
import { GerenciarComentarios } from "./pages/GerenciarComentarios";
import { VisualizarComentario } from "./pages/GerenciarComentarios/views/visualizar-comentario";
import { GerenciarDisciplinas } from "./pages/GerenciarDisciplinas";
import { GerenciarEstilosExportacao } from "./pages/GerenciarEstiloExportacao";
import { GerenciarEstruturasExportacao } from "./pages/GerenciarEstruturaExportacao";
import { GerenciarOrgaos } from "./pages/GerenciarOrgaos";
import { GerenciarPerfilQuestoes } from "./pages/GerenciarPerfilQuestoes";
import { GerenciarProjetos } from "./pages/GerenciarProjetos";
import { GerenciarProvas } from "./pages/GerenciarProvas";
import { GerenciarQuestoes } from "./pages/GerenciarQuestoes";
import { VisualizarQuestao } from "./pages/GerenciarQuestoes/views/visualizar-questao";
import { GerenciarTags } from "./pages/GerenciarTags";
import { GerenciarTemas } from "./pages/GerenciarTemas";
import { GerenciasTipoLeis } from "./pages/GerenciarTipoLeis";
import { GerenciarUsuarios } from "./pages/GerenciarUsuarios";
import { ImportarQuestoes } from "./pages/ImportacaoQuestoes";
import { Login } from "./pages/Login";
import { OAuthGoogleLoginRedirect } from "./pages/OAuthLoginGoogleRedirect";
import { BancoQuestoesApp } from "./pages/Principal";
import { ProjetoQuestoes } from "./pages/Projeto";
import { VisualizarQuestaoProjeto } from "./pages/Projeto/views/visualizar-questao";
import { SegurancaLogin } from "./pages/SegurancaLogin";
import { AutenticacaoDoisFatores } from "./pages/SegurancaLogin/AutenticacaoDoisFatores";
import { Indisponivel } from "./pages/erros/Indisponivel";
import { GOOGLE_AUTH_URL } from "./services/api";
import { useJusAuthService } from "./services/api-services/auth";

import { Auth } from "./services/auth-services/auth";
import { GerenciarCarreiras } from "./pages/GerenciarCarreiras";
import { GerenciarIndicacaoFonteResposta } from "./pages/GerenciarIndicacaoFonteResposta";

export const routes = [
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }}
    component={{
      type: Dashboard
    }}
    key={1}
    path="/"
    exact
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN", "ROLE_CLASSIFICADOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }}
    component={{
      type: GerenciarAutores
    }}
    key={2}
    path="/gerenciar-autores"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: GerenciarBancas
    }}
    key={3}
    path="/gerenciar-bancas"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: GerenciarCargos
    }}
    key={4}
    path="/gerenciar-cargos"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={5}
    component={{
      type: GerenciarOrgaos
    }}
    path="/gerenciar-orgaos"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={6}
    component={{
      type: GerenciarPerfilQuestoes
    }}
    path="/gerenciar-perfil-questoes"
    permissions={["PERMISSION_GERENCIAR_COMENTARIOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={7}
    component={{
      type: GerenciarDisciplinas
    }}
    path="/gerenciar-disciplinas"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={8}
    component={{
      type: GerenciarTemas
    }}
    path="/disciplina/:id/gerenciar-temas"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={9}
    component={{
      type: GerenciarUsuarios
    }}
    path="/gerenciar-usuarios"
    permissions={[]}
    roles={["ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={10}
    component={{
      type: CriarUsuario
    }}
    path="/usuario"
    permissions={[]}
    roles={["ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={11}
    component={{
      type: CriarUsuario
    }}
    path="/alterar/usuario/:id"
    permissions={[]}
    roles={["ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={12}
    component={{
      type: GerenciarProvas
    }}
    path="/gerenciar-provas"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN", "ROLE_CLASSIFICADOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={13}
    component={{
      type: GerenciarProjetos
    }}
    path="/gerenciar-projetos"
    permissions={["PERMISSION_GERENCIAR_PROJETOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={14}
    component={{
      type: GerenciarComentarios
    }}
    path="/gerenciar-comentarios"
    permissions={["PERMISSION_GERENCIAR_COMENTARIOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={15}
    component={{
      type: GerenciarQuestoes
    }}
    path="/gerenciar-questoes"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN", "ROLE_CLASSIFICADOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={16}
    component={{
      type: BuscarProjetos
    }}
    path="/projetos"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={17}
    component={{
      type: CriarQuestao
    }}
    path="/questao"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={18}
    component={{
      type: ImportarQuestoes
    }}
    path="/questao/importar"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={19}
    component={{
      type: CriarQuestao
    }}
    path="/questao/editar/:id"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN", "ROLE_CLASSIFICADOR"]}
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={20}
    component={{
      type: CriarProjeto
    }}
    path="/projeto"
    permissions={[]}
    exact={true}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={21}
    component={{
      type: CriarProjeto
    }}
    path="/projeto/editar/:id"
    permissions={[]}
    exact={true}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={22}
    component={{
      type: ProjetoQuestoes
    }}
    path="/projeto-questoes/:id/:nome"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={23}
    component={{
      type: VisualizarQuestaoProjeto
    }}
    path="/projeto-questoes/:idProjeto/questao/:idQuestao"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={24}
    component={{
      type: VisualizarComentario
    }}
    path="/questao/:idQuestao/comentarios/:idComentario"
    permissions={["PERMISSION_GERENCIAR_COMENTARIOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN", "ROLE_EDITOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={25}
    component={{
      type: VisualizarQuestao
    }}
    path="/questao/:id"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
    exact={true}
  />,
  <NoauthRoute key={26} component={{
    type: Login,
    props: {
      appName: "Banco de questões",
      GOOGLE_AUTH_URL: GOOGLE_AUTH_URL,
      secondaryPlatform: {
        name: "Banco de Legislações",
        url: process.env.REACT_APP_BANCO_LEGISLACAO_URL + "/login"
      }
    }
  }} path="/login" exact />,
  <NoAuthRouteBase key={27} mainApp={BancoQuestoesApp} component={{
    type: Indisponivel
  }} path="/indisponivel" />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={28}
    component={{
      type: GerenciarTags
    }}
    path="/disciplina/:id/gerenciar-tags"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    key={29}
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: GerenciasTipoLeis
    }}
    path="/gerenciar-tipo-leis"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: GerenciarEstilosExportacao
    }}
    key={30}
    path="/gerenciar-estilos-exportacao"
    permissions={["PERMISSION_GERENCIAR_PROJETOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: GerenciarEstruturasExportacao
    }}
    key={31}
    path="/gerenciar-estrutura-exportacao"
    permissions={["PERMISSION_GERENCIAR_PROJETOS"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <NoauthRoute
    key={32}
    component={{
      type: OAuthGoogleLoginRedirect
    }}
    path="/login/code/google"
    exact={true}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: SegurancaLogin
    }}
    key={33}
    path="/seguranca-login"
    exact={true}
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN", "ROLE_EDITOR", "ROLE_CLASSIFICADOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} component={{
      type: AutenticacaoDoisFatores
    }}
    key={34}
    exact={true}
    path="/seguranca-login/2fa"
    permissions={[]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN", "ROLE_EDITOR", "ROLE_CLASSIFICADOR"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={21}
    component={{
      type: EdicaoProjeto
    }}
    path="/edicao-projeto/:id"
    exact={true}
    permissions={["PERMISSION_EDICAO_PROJETO"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={22}
    component={{
      type: GerenciarCarreiras
    }}
    path="/gerenciar-carreiras"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
  <AuthRoute
    mainApp={{
      type: BancoQuestoesApp
    }} key={23}
    component={{
      type: GerenciarIndicacaoFonteResposta
    }}
    path="/indicacao-fonte-resposta"
    permissions={["PERMISSION_GERENCIAR_QUESTOES"]}
    roles={["ROLE_LIDER_EDITORIAL", "ROLE_EDITOR", "ROLE_ADMIN"]}
  />,
];

const AppRoutes = () => {
  //
  return (
    <Switch>
      {routes.map((route) => {
        return route;
      })}
    </Switch>
  );
};

function App() {

  const auth = useJusAuthService();

  return (
    <BrowserRouter>
      <Auth authAPIProvider={auth}>
        <DndProvider backend={HTML5Backend}>
          <ScrollToTop>
            <GlobalStyle />
            <AppRoutes />
          </ScrollToTop>
        </DndProvider>
      </Auth>
    </BrowserRouter>
  );
}

export default App;
