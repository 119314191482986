import { CheckBox } from "../../../../components/checkbox";
import {
  ThreeDotsLink,
  ThreeDotsOption,
} from "../../../../components/menu/three-dots";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { RowItem } from "../../../../components/page/structure/ListComponent/list-table";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ListTableRow } from "../../../../components/page/structure/ListComponent/styles";
import { ProjetoResume } from "../../../../services/api-services/projeto";

export const ProjetoHeader = (
  selectedEntitiesAction: SelectedDataActions<ProjetoResume>
) => {
  const ProjetoRow = (elem: ItemDataSelect<ProjetoResume>, index: number) => {
    const projeto = elem.entity as ProjetoResume;
    const context = useGerenciarContext();

    return (
      <ListTableRow key={index}>
        <RowItem>
          <CheckBox
            checked={selectedEntitiesAction.checkSelect(elem.id_item)}
            onChange={(e) => {
              selectedEntitiesAction.checkItem(index, e);
            }}
          />
        </RowItem>
        <RowItem>{projeto.id}</RowItem>
        <RowItem>{projeto.name}</RowItem>
        <RowItem>{projeto.year}</RowItem>
        <RowItem>{projeto.visibility}</RowItem>
        <RowItem width="10px">
          <ThreeDotsLink
            size={18}
            className="list-options"
            isLastPosition={true}
            options={[
              <ThreeDotsOption
                name="Editar"
                to={`/projeto/editar/${projeto.id}`}
              />,
              <ThreeNormalOption
                name="Excluir"
                onClick={() => {
                  context.changeAction({
                    action: "excluir",
                    id: projeto.id,
                  });
                }}
              />,
            ]}
          />
        </RowItem>
      </ListTableRow>
    );
  };

  return {
    projetoHeader: ProjetoRow,
  };
};
