import styled from "styled-components";

export const SidebarModalContent = styled.div`
  p {
    color: var(--color-text-secondary);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;

    p{
      margin-bottom: 8px ;
      margin-top: 8px ;
      text-align: center ;
    }
  }

  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin: 0;
  background-color: var(--color-content-light);
  box-shadow: -4px 2px 25px 0px rgba(0, 0, 0, 0.1);

  position: fixed;
  width: 400px;
  top: 30%;
  left: calc(50% - 200px);
  bottom: 40px;
  z-index: 1200;
  height: 200px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-content-border);
    height: 48px;
    width: 100%;
    padding: 8px;

    h3 {
      font-weight: 500;
      font-size: 1.1rem;
      color: var(--color-text-dark);
    }
  }

  @media (max-width: 700px) {
    width: 90%;
    position: "fixed";
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

export const MenuTitle = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-content-border);
  height: auto;
  width: 100%;
  padding: 8px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--color-content-border);
  height: auto;
  width: 100%;
  padding: 8px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
  }

  .button-option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const FormContent = styled.form`
  padding: 18px;
  display: flex;
  flex-direction: column;

  .save {
    width: 100%;
  }

  small {
    color: var(--color-text-secondary);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .form-group {
    margin: 22px 12px 22px 12px;
  }
`;

export const Background = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 996;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  cursor: pointer;
`;
