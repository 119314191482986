import React, { useState } from "react";
import {
  NormalSelect,
  TItemSelect
} from "../../../../../components/select/normal";
import {
  premissasOptions,
  respostaCertoErradoOptions,
  respostaVerdadeiroFalsoOptions,
  TipoPremissa
} from "../../../../../mocks/data";
import { useQuestaoContext } from "../../../context";
import { RespostaEscolhaContent } from "../../../styles";

export const CriarRespostaEnunciado: React.FC<{}> = () => {
  //
  const { questao, callbacks, tipoPremissa, idQuestao } = useQuestaoContext();

  const [options] = useState<TItemSelect[]>(() => {

    if (idQuestao) {
      return premissasOptions;
    } else {
      if (tipoPremissa === TipoPremissa.VF) {
        return respostaVerdadeiroFalsoOptions;
      } else {
        return respostaCertoErradoOptions;
      }
    }

  });


  return (
    <RespostaEscolhaContent>
      <div className="new-alternativa">
        <h4>{"Selecione a resposta correta da questão:"}</h4>
      </div>
      <NormalSelect
        minWidth={40}
        className="resposta"
        label="Selecione uma resposta"
        selectItem={questao.correto}
        onSelectCurrentItem={(item) => callbacks.setCorreto(item)}
        selectedItems={options}
      />
    </RespostaEscolhaContent>
  );
};
