import { Fragment } from "react";
import { GiInjustice } from "react-icons/gi";
import { VscOpenPreview } from "react-icons/vsc";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { CheckBox } from "../../../../components/checkbox";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { CountTag } from "../../../../components/page/structure/CountTag";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { Tag } from "../../../../components/tag";
import { QuestaoItem } from "../../../../services/api-services/questao";
import { QuestaoProjetoItem } from "../../../../services/api-services/questaoProjeto";
import { LabelValue } from "../../../GerenciarComentarios/elements";
import { Classificacao, CodigoID, Status, Validation } from "../../elements";
import {
  BodyStyle,
  FooterLeft,
  FooterRight,
  FooterStyle,
  HeaderStyle
} from "./styles";

export const QuestaoCard = (
  selectedEntitiesAction: SelectedDataActions<QuestaoProjetoItem>,
  mode: string
) => {
  const QuestaoCardComponent = (
    elem: ItemDataSelect<QuestaoProjetoItem>,
    index: number
  ) => {
    const questao = elem.entity.question as QuestaoItem;

    return (
      <CardItem
        settings={{
          width: "100%",
        }}
        key={index}
        header={
          <HeaderContent
            elem={elem}
            index={index}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
        body={<BodyContent questao={elem.entity.question as QuestaoItem} />}
        footer={<FooterContent questaoProjeto={elem.entity} />}
        options={[
          <ThreeNormalOption
            name="Editar"
            onClick={() => {
              window.open(`/questao/editar/${questao.id}`, "_blank");
            }}
          />,
        ]}
      />
    );
  };

  return {
    questaoCard: QuestaoCardComponent,
  };
};

const HeaderContent: React.FC<{
  index: number;
  elem: ItemDataSelect<QuestaoProjetoItem>;
  mode: string;
  selectedEntitiesAction: SelectedDataActions<QuestaoProjetoItem>;
}> = (props) => {

  const questao = props.elem.entity.question as QuestaoItem;

  return (
    <HeaderStyle>
      <CheckBox
        checked={props.selectedEntitiesAction.checkSelect(props.elem.id_item)}
        onChange={(e) => {
          props.selectedEntitiesAction.checkItem(props.index, e);
        }}
      />
      <CodigoID
        codigo={questao.cod}
        id={questao.id}
        classname={"item-margin"}
      />
      <Status status={questao.status_question} classname={"item-margin"} />
      <Validation
        value={questao.validation}
        mode={props.mode}
        classname={"item-margin"}
      />
      <Classificacao subject={questao.subject} classname={"item-margin"} />
    </HeaderStyle>
  );
};

const BodyContent: React.FC<{ questao: QuestaoItem }> = ({ questao }) => {

  return (
    <BodyStyle className={"item-margin"}>
      <span className={"exam-header"}>{questao.exam_header}</span>
      <div className="statement_question">
        <div
          className={"ql-editor"}
          dangerouslySetInnerHTML={{
            __html: questao.statement_question,
          }}
        />
      </div>
      <div className="tags">
        {questao.subject && <Tag nome={questao.subject} title={"Disciplina"} />}
        {questao.topic && <Tag nome={questao.topic} title={"Tema"} />}
        {questao.tag && <Tag nome={questao.tag} title={"TAG"} />}
        {questao.sources && <Tag nome={questao.sources.join(";")} title={"Fonte"} />}
        {questao.type && <Tag nome={questao.type} title={"Tipo de questão"} />}
        {questao.difficulty && (
          <Tag nome={questao.difficulty} title={"Dificuldade"} />
        )}
      </div>
    </BodyStyle>
  );
};

const FooterContent: React.FC<{ questaoProjeto: QuestaoProjetoItem }> = ({
  questaoProjeto,
}) => {

  const context = useGerenciarContext();


  return (
    <Fragment>
      <FooterStyle className={"item-margin"}>
        <FooterRight>
          {questaoProjeto.question.created_by !== undefined && (
            <LabelValue
              label={"Criado/exportado por"}
              value={questaoProjeto.question.created_by}
            />
          )}
          <CountTag
            count={questaoProjeto.question.qtd_comments}
            title="comentários"
            classname={"item-margin"}
          />
          <CountTag
            count={questaoProjeto.question.qtd_answers}
            title="respostas"
            classname={"item-margin"}
          />
          <CountTag
            count={questaoProjeto.question.qtd_supports}
            title="textos de apoios"
            classname={"item-margin"}
          />
        </FooterRight>
        <FooterLeft>
          <ButtonBottomLess
            className="button-view"
            title={"Referências legais"}
            icon={GiInjustice}
            onClick={() => {
              context.changeAction({
                action: "referencia-legais",
                object: questaoProjeto.question,
                id: 0
              });
            }}
          />
          <ButtonBottomLess
            className="button-view"
            title={"Ver questão"}
            icon={VscOpenPreview}
            onClick={() => {
              window.open(`questao/${questaoProjeto.id}`, "_blank");
            }}
          />
        </FooterLeft>
      </FooterStyle>
    </Fragment>
  );
};
