import { createContext, useContext } from "react";
import { TkLocalStorage } from ".";
import { RestErro } from "../../api";


export type UserInfo = {
  id: any;
  fullname: any;
  rl: any;
}

export type TokenInfo = {
  act: any;
  rt: any;
  in: any;
  iat: any;
  provider: any;
}

export type AuthDataResponse = {
  userInfo: UserInfo,
  tokenInfo: TokenInfo
}

export abstract class APIAuthProvider {
  abstract redirectOAuthLogin(query: string, provider: string): Promise<AuthDataResponse>;

  abstract tfaLogin(tfaTk: string, code: string): Promise<AuthDataResponse>;

  abstract initiateLogin(username: string,
    password: string,
    provider: string): Promise<AuthDataResponse>;

  abstract refresh(refreshTk: string, provider: string): Promise<AuthDataResponse>
}

export interface TAuthContext {
  authenticated: boolean;
  user: {
    id: string;
    fullname: string;
    roles: Array<string>;
  };
  provider: string;
  getTkInfo: () => TkLocalStorage;
  redirectOAuthLogin: (query: string, provider: string) => Promise<string | boolean>;
  tfaLogin: (tfaTk: string, code: string) => Promise<boolean | RestErro>;
  initiateLogin: (username: string, password: string, provider: string) => Promise<string | boolean>;
  refresh: () => Promise<string>;
  isAuthenticated: () => boolean;
  logout: () => void;
};

const authContext = createContext<TAuthContext>({
  authenticated: false,
  user: {
    id: "",
    fullname: "",
    roles: [],
  },
  provider: "default",
  getTkInfo: () => {
    return {
      act: "",
      ex_in: 0,
      iat: 0,
      rt: ""
    }
  },
  redirectOAuthLogin: (query, provider) => {
    return new Promise<boolean>((value) => {
      return false;
    })
  },
  tfaLogin: (tfaTk: string, code: string) =>
    new Promise<boolean>((value) => {
      return false;
    }),
  initiateLogin: (username: string, password: string, provider: string) =>
    new Promise<boolean>((value) => {
      return false;
    }),
  refresh: () =>
    new Promise<string>((value) => {
      return "";
    }),
  isAuthenticated: () => false,
  logout: () => { },
});

export const AuthProvider = authContext.Provider;
export const useAuthContext = () => useContext(authContext);
