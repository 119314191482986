import styled from 'styled-components';

export const SidebarModalContent = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  width: 320px;
  height: 100vh;
  z-index: 1200;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  background-color: var(--color-content-light);
  box-shadow: -4px 2px 25px 0px rgba(0, 0, 0, 0.1);

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-content-border);
    height: 70px;
    width: 100%;
    padding: 14px;

    h3 {
      font-weight: 500;
      color: var(--color-text-dark);
    }
  }

  @media (max-width: 700px) {
    width: 80%;
    height: 80%;
    position: 'fixed';
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

export const MenuTitle = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-content-border);
  height: 70px;
  width: 100%;
  padding: 14px;
  margin-bottom: 18px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
  }
`;

export const SidebarForm = styled.form`
  padding: 18px;
  display: flex;
  flex-direction: column;

  .save {
    width: 100%;
  }

  label,
  small {
    color: var(--color-text-secondary);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .form-group {
    margin: 22px 12px 22px 12px;
  }
`;
