import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RespostaObjetiva } from "..";
import { ButtonBottomLess } from "../../../../../components/button/bottomless";
import { Tag } from "../../../../../components/tag";
import {
  LeftResposta,
  RespostaRow,
  RightResposta
} from "../../../../GerenciarQuestoes/views/visualizar-questao/alternativas/styles";

export const RespostaCertoErrado: React.FC<RespostaObjetiva> = (props) => {
  const resposta = props.resposta;

  return (
    <RespostaRow>
      <LeftResposta>
        <div className="answer_correct">
          {resposta.anwser_correct.key === "Certo" ? (
            <Tag
              nome={resposta.anwser_correct.value}
              colorDark={"color-resposta-correta"}
            />
          ) : (
            <Tag
              nome={resposta.anwser_correct.value}
              colorDark={"color-primary-darking"}
            />
          )}
        </div>
        <div
          className="content ql-editor"
          dangerouslySetInnerHTML={{
            __html: props.resposta.answer,
          }}
        />
      </LeftResposta>
      <RightResposta>
        <ButtonBottomLess
          className="edit"
          icon={FiEdit2}
          onClick={() => props.handleEditar(props.resposta)}
        />
        <ButtonBottomLess
          className="new-comentarios option-margin"
          icon={MdDelete}
          onClick={() => props.excluirResposta(props.resposta)}
        />
      </RightResposta>
    </RespostaRow>
  );
};
