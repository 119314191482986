import styled from 'styled-components';

export const CountTagContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 1rem;
    margin-left: 12px;
    color: var(--color-text-grey);
  }

   .count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-dark);
    font-weight: 500;
    font-size: 0.9rem;
    margin-left: 12px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    color: white; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  }

  .count:hover {
    background-color: var(--color-primary-darking);
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;
