import { IoMdAdd } from "react-icons/io";
import { ButtonBottomLess } from "../../../../components/button/bottomless";
import { FilterTag } from "../../../../components/filter-tag";
import { InputForm } from "../../../../components/input";
import { ThreeDotsList } from "../../../../components/menu/three-dots";
import { SelectedTagActions } from "../../../../components/page/structure/FilterComponent/hooks/useTagAction";
import { ItemSelect, ItemSelectEntity, ItemsSelectEntitiesPage } from "../../../../components/page/structure/FilterComponent/types";
import { Group } from "../../../../components/page/structure/Group";
import { AsyncSelect } from "../../../../components/select/async";
import { TipoLei } from "../../../../services/api-services/tipoLeis";
import { TReferencialegal } from "../../../CriarQuestao/context";
import { TDispositivosLegaisHook } from "../../views/referencias-legais/hooks/useDispositivosLegaisHook";
import { ReferenciaLegalTagline } from "../../views/referencias-legais/referencia-tagline";
import { ReferenciaLegalForm } from "../../views/referencias-legais/styles";


export interface TPesquisaReferenciaLegal {
    useTagActions: SelectedTagActions;
    dispositivosLegaisHook: TDispositivosLegaisHook;
    listarTiposLeisAPI: (page: number, value: string) => Promise<ItemsSelectEntitiesPage<TipoLei>>;
}

export const ReferenciaLegalFiltros = ({ dispositivosLegaisHook, useTagActions, listarTiposLeisAPI }: TPesquisaReferenciaLegal) => {

    const { artigo, dispositivos, dispositivosDisponiveis, lei, tipoLei, callbacks, mascaras } = dispositivosLegaisHook;

    const addReferenciaFiltro = () => {

        const { query, referenciaLegal } = callbacks.getQueryDispositivos();

        useTagActions.addTagValue(query, "legalReferences", referenciaLegal, (item?: ItemSelect) => {
            return item && item?.object && <ReferenciaLegalTagline referencia={item.object as TReferencialegal} />
        });
    }

    return (
        <Group nameGroup="Referências Legais">
            <ReferenciaLegalForm>
                <div className="form-group">
                    <div className="field-group">
                        <label>Tipo de Lei</label>
                        <AsyncSelect
                            className="tipo-lei field-group"
                            showSelectItem={true}
                            label="Tipo de Lei"
                            selectItem={tipoLei}
                            onAddFilterItem={(item) =>
                                callbacks.setTipoLeiData(item as ItemSelectEntity<TipoLei>)
                            }
                            getDataAPI={listarTiposLeisAPI}
                        />
                    </div>
                    <div className="field-group">
                        <label>
                            Lei - Padrão (
                            <b>
                                {tipoLei.entity && tipoLei.entity.terms
                                    ? tipoLei.entity.terms
                                    : "XX.XXX/XXXX"}
                            </b>
                            )
                        </label>
                        <InputForm
                            spellCheck={false}
                            className="lei"
                            name="lei"
                            value={lei.value}
                            onKeyUp={(e) => mascaras.maskLei(e)}
                            onChange={(value) => {
                                callbacks.setLeiData(value.currentTarget.value);
                            }}
                            placeholder="Pesquisar por Lei de acordo padrão"
                        />

                    </div>
                    <div className="field-group">
                        <label>
                            Artigo XXXX ou XXXX-X Ex:<b>0024 ou 0024-F</b>
                        </label>
                        <InputForm
                            name="artigo"
                            onKeyUp={(e) => mascaras.maskArtigo(e)}
                            className="artigo"
                            value={artigo.value}
                            onChange={(value) => {
                                callbacks.setArtigoData(value.currentTarget.value);
                            }}
                            placeholder="Pesquisar por Artigo"
                        />

                    </div>
                    {
                        dispositivos.map((dispositivo, index) => {
                            return <div className="field-group">
                                <label>
                                    {`${dispositivo.padrao}`}
                                </label>
                                <InputForm
                                    name={dispositivo.name}
                                    key={dispositivo.name}
                                    onKeyUp={(e) => mascaras.maskDispositivo(e, dispositivo, index)}
                                    className="dispositivo-field"
                                    value={dispositivo.value}
                                    onChange={(value) => {
                                        callbacks.setDispositivoData(value.currentTarget.value, dispositivo, index);
                                    }}
                                    placeholder={`Pesquisar por ${dispositivo.name}`}
                                />

                            </div>
                        })
                    }
                    {dispositivos.length >= 1 && <FilterTag nome={""} className={"remover-dispositivo"} onMouseClick={callbacks.onRemoverUltimoDispositivo} />}
                    {dispositivosDisponiveis.length >= 1 && <div className="field-group">
                        <ThreeDotsList
                            size={28}
                            icon={IoMdAdd}
                            className="dispositivos-options"
                            isLastPosition={false}
                            text={"Dispositivo"}
                            options={dispositivosDisponiveis}
                        />
                    </div>}
                    <div className="field-group">
                        <ButtonBottomLess
                            className={"add-referencia"}
                            onClick={() => addReferenciaFiltro()}
                            icon={IoMdAdd}
                            title={"Filtro"}
                        />
                    </div>
                </div>
            </ReferenciaLegalForm>
        </Group>
    )
}