import styled from "styled-components";

export const CommentsContent = styled.div`

  margin-top: 32px;

  h3 {
    font-weight: 500;
    color: var(--color-text-dark);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .pagination-data {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .pagination {
    margin: 24px;
    justify-content: flex-end;
  }
`;

export const CommentsFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--color-content-light);
  padding: 12px;

  .search {
    flex: 0 1 40%;
  }

  .tabs {
    flex: 0 1 auto;
  }
`;
