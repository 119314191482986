

import { Redirect, useLocation } from 'react-router-dom';
import { OAuthLocationState } from '../Login';

export const OAuthGoogleLoginRedirect = () => {

    const location = useLocation<OAuthLocationState>();

    const getQueryParameter = (name: string, url: string) => {
        return new URLSearchParams(url).get(name);
    }

    const render = () => {
        if (getQueryParameter('code', location.search)) {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: location,
                    authenticationProviderSuccess: true,
                    provider: "google"
                }
            }} />;
        }

        return <Redirect to={{
            pathname: "/login",
            state: {
                from: location,
                authenticationProviderSuccess: false,
                provider: "google"
            }
        }} />;
    }

    return (
        render()
    )
}