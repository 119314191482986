import React, { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { ButtonGroup } from "../button/group";

export interface TButtonGroup extends React.HTMLAttributes<HTMLDivElement> {
  initialPositionSelected: number;
  buttons: Array<TButtonSelect>;
  className?: string;
  direction?: string;
  marginRight?: string,
  onCurrentButtonSelected?: (current: TButtonSelect) => void;
  currentValue?: string;
}

export interface TButtonSelect {
  name?: string;
  value: string;
  icon?: React.ComponentType<IconBaseProps>;
  classname: string;
  minWidth?: number;
  onClick: (current: TButtonSelect) => void;

}

export const GroupButtons: React.FC<TButtonGroup> = ({
  buttons,
  initialPositionSelected,
  className,
  direction,
  currentValue,
  marginRight,
  onCurrentButtonSelected,
  ...rest
}) => {
  const [optionActive, setOptionActive] = useState(initialPositionSelected);


  const [optionsActive, setOptionsActive] = useState<boolean[]>(() => {
    {
      const array = buttons.map((ele) => {
        return false;
      });

      array[initialPositionSelected] = true;

      return array;
    }
  });

  useEffect(() => {
    onCurrentButtonSelected && onCurrentButtonSelected(buttons[optionActive]);
  }, [optionActive]);


  useEffect(() => {
    if (currentValue) {
      if (currentValue !== buttons[optionActive].name) {

        const pos = buttons.findIndex((current) => current.value === currentValue);

        if (pos) {
          const array = buttons.map((ele) => {
            return false;
          });

          array[pos] = true;

          setOptionsActive(array);
          setOptionActive(pos);
        }

      }
    }
  }, [currentValue]);



  const activeOption = (id: number) => {
    optionsActive[optionActive] = false;
    optionsActive[id] = true;
    setOptionActive(id);
  };

  const optionsIsActive = (index: number): boolean => {
    return optionsActive[index];
  };

  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        marginRight: marginRight ? marginRight : '0px'
      }}
      {...rest}
    >
      {buttons.map((ele, index) => {
        return (
          <ButtonGroup
            key={index}
            minWidth={ele.minWidth}
            icon={ele.icon}
            className={ele.classname}
            title={ele.name}
            onClick={() => {
              activeOption(index);
              ele.onClick(buttons[index]);
            }}
            active={optionsIsActive(index)}
          />
        );
      })}
    </div>
  );
};

export const setStateValueGroup = (
  current: TButtonSelect,
  setVar: React.SetStateAction<any>
) => {
  setVar(current.value);
};
