import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UploadDataImage } from "../../components/editor";
import {
  ItemSelect,
  ItemSelectEntity,
} from "../../components/page/structure/FilterComponent/types";
import { TItemSelect } from "../../components/select/normal";
import {
  TipoPremissa,
  dificuldadesOptions,
  dispositivosAlinea,
  dispositivosInciso,
  dispositivosItem,
  dispositivosParagrafo,
  fontesOptions,
  naturezaOptions,
  premissasOptions,
  respostaCertoErradoOptions,
  respostaVerdadeiroFalsoOptions,
  statusQuestaoOption,
} from "../../mocks/data";
import { APIFetch, APIFileFetch } from "../../services/api";
import { Prova } from "../../services/api-services/prova";
import {
  ApoioTextoRequest,
  QuestaoDetails,
  QuestaoRequest,
  ReferenciaLegalRequest,
  ReferenciasLegaisDetails,
  RespostaDetails,
  RespostaEscolhaRequest,
  RespostaPadraoRequest,
  RespostaRelacionalRequest,
  RespostaRequest,
  useQuestaoService,
} from "../../services/api-services/questao";
import { useQuestaoFileService } from "../../services/api-services/questaoFile";
import { TipoLei } from "../../services/api-services/tipoLeis";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { TagDispositivoLegal } from "../GerenciarQuestoes/views/referencias-legais/hooks/useDispositivosLegaisHook/types";
import {
  QuestaoProvider,
  Resposta,
  TReferencialegal,
  TextoApoio,
} from "./context";

interface TCriarQuestaoProvider {

  desativarAtualizacao: boolean;

}

const INITIAL_STATE = {
  id: -1,
  key: "",
  selected: false,
  useId: false,
  value: "",
};


export const CriarQuestaoProvider: React.FC<TCriarQuestaoProvider> = ({
  children, desativarAtualizacao
}) => {
  //

  const auth = useAuthContext();

  const history = useHistory();

  const [idQuestao, setIdQuestao] = useState<string | undefined>(undefined);

  const [edit, setEdit] = useState(false);

  const services = {
    questaoService: useQuestaoService(APIFetch(auth)),
    questaoFileService: useQuestaoFileService(APIFileFetch(auth)),
  };

  const [imagemDataContext, setImagemData] = useState<UploadDataImage>();

  const [questao, setQuestao] = useState<QuestaoDetails | undefined>(undefined);

  const [tipoResposta, setTipoResposta] = useState("Sem resposta");

  const [tipo, setTipo] = useState<string>("");

  const [tipoPremissa, setTipoPremissa] = useState<TipoPremissa>();

  const [natureza, setNatureza] = useState<TItemSelect>(naturezaOptions[0]);

  const [dificuldade, setDificuldade] = useState<TItemSelect>(INITIAL_STATE);

  const [status, setStatus] = useState<TItemSelect>(statusQuestaoOption[0]);

  const [tema, setTema] = useState<ItemSelect>(INITIAL_STATE);

  const [tag, setTag] = useState<ItemSelect>(INITIAL_STATE);

  const [disciplina, setDisciplina] = useState<ItemSelect>(INITIAL_STATE);

  const [enunciado, setEnunciado] = useState<string>("");

  const [correto, setCorreto] = useState<TItemSelect>(INITIAL_STATE);

  const [colunaDataA, setColunaDataA] = useState<Array<Resposta>>([]);

  const [colunaDataB, setColunaDataB] = useState<Array<Resposta>>([]);

  const [colunaDataC, setColunaDataC] = useState<Array<Resposta>>([]);

  const [textosData, setTextosData] = useState<Array<TextoApoio>>([]);

  const [prova, setProva] = useState<Prova>();

  const [fontes, setFontes] = useState<ItemSelect[]>([]);

  const [referenciasLegais, setReferenciasLegais] = useState<
    Array<TReferencialegal>
  >([]);

  const [ativarDiscProva, setAtivarDiscProva] = useState<boolean>(false);

  const [discProva, setDiscProva] = useState<string>("");

  useEffect(() => {

    if (idQuestao && desativarAtualizacao === false) {
      services.questaoService
        .buscarQuestao(parseInt(idQuestao))
        .then((questaoAPI) => {
          setQuestao(questaoAPI);
        })
        .catch((error) => {
          alert(error.response.data.message);
          history.goBack();
        });
    } else {
      setDisciplina({
        id: -1,
        value: "",
        key: "",
        useId: false,
      });
      setTema({
        id: -1,
        value: "",
        key: "",
        useId: false,
      });
      setTag({
        id: -1,
        value: "",
        key: "",
        useId: false,
      });
      setAtivarDiscProva(false);
      setDiscProva("");
    }

  }, [idQuestao]);

  useEffect(() => {
    if (questao) {
      setEdit(true);
      setInitialTipoPremissa(questao);
      setTipoResposta(questao.answer_type);
      setInitialCorreto(questao);
      setDiscProva(questao.exam_discipline);
      setAtivarDiscProva(questao.exam_disc_active);
      setTipo(questao.type);
      setImagemData({
        data: {
          prefixURL:
            `${process.env.REACT_APP_STORAGE_IMAGE}/question/` + idQuestao,
          id: questao.id as unknown as string,
        },
        uploadImagem: upload,
        getImagensDeletadas: (imagens: string[]) => {
          return;
        },
      });

      const natureza = naturezaOptions.find(
        (value) => value.value === questao.nature
      );
      natureza && setNatureza(natureza);

      const dificuldade = dificuldadesOptions.find(
        (value) => value.value === questao.difficulty
      );
      dificuldade && setDificuldade(dificuldade);

      const status = statusQuestaoOption.find(
        (value) => value.value === questao.status
      );
      status && setStatus(status);

      questao.subject &&
        setDisciplina({
          id: questao.subject.id,
          value: questao.subject.name,
          key: "subject",
          useId: false,
        });

      questao.topic &&
        setTema({
          id: questao.topic.id,
          value: questao.topic.name,
          key: "topic",
          useId: false,
        });

      questao.tag &&
        setTag({
          id: questao.tag.id,
          value: questao.tag.name,
          key: "tag",
          useId: false,
        });

      let fontesAPI: ItemSelect[] = [];

      questao.sources.forEach((fonte) => {
        const fonteEncontrada = fontesOptions.find(
          (value) => value.value === fonte
        );

        if (fonteEncontrada) {
          fontesAPI.push({
            id: fonteEncontrada.id,
            key: fonteEncontrada.key,
            useId: false,
            value: fonteEncontrada.value,
          });
        }
      });

      questao.sources && setFontesData(fontesAPI);

      setEnunciado(questao.statement_question);
      setReferenciasLegais(
        mapReferenciasLegaisByQuestaoDetails(questao.legal_references)
      );

      const respostas: any = questao.answers;

      if (respostas) {
        const a: RespostaDetails[] = respostas.first_column;
        const b: RespostaDetails[] = respostas.second_column;
        const c: RespostaDetails[] = respostas.third_column;

        if (
          questao.answer_type === "Múltipla Escolha" ||
          questao.answer_type === "Certo/Errado" ||
          questao.answer_type === "Verdadeiro/Falso"
        ) {
          a &&
            a.forEach((res) => {
              if (questao.answer_type === "Verdadeiro/Falso") {
                const resultadoResposta = respostaVerdadeiroFalsoOptions.find(
                  (r) => r.key === res.anwser_correct
                );

                if (resultadoResposta) {
                  resultadoResposta.selected = true;
                }

                colunaDataA.push({
                  id: res.id + "",
                  answer: res.answer,
                  column: "first_column",
                  points: 0,
                  related_column: "",
                  anwser_correct: resultadoResposta
                    ? resultadoResposta
                    : respostaVerdadeiroFalsoOptions[1],
                });
              } else {
                const resultadoResposta = respostaCertoErradoOptions.find(
                  (r) => r.key === res.anwser_correct
                );

                if (resultadoResposta) {
                  resultadoResposta.selected = true;
                }

                colunaDataA.push({
                  id: res.id + "",
                  answer: res.answer,
                  column: "first_column",
                  points: 0,
                  related_column: "",
                  anwser_correct: resultadoResposta
                    ? resultadoResposta
                    : respostaCertoErradoOptions[1],
                });
              }
            });
        } else if (questao.answer_type === "Relacional") {
          a &&
            a.forEach((res) => {
              colunaDataA.push({
                id: res.id + "",
                answer: res.answer,
                column: "first_column",
                points: 0,
                related_column: "",
                anwser_correct: {
                  id: 0,
                  key: "",
                  selected: false,
                  useId: false,
                  value: "",
                },
              });
            });

          b &&
            b.forEach((res) => {
              colunaDataB.push({
                id: res.id + "",
                answer: res.answer,
                column: "second_column",
                points: 0,
                related_column: res.related_column + "",
                anwser_correct: {
                  id: 0,
                  key: "",
                  selected: false,
                  useId: false,
                  value: "",
                },
              });
            });

          c &&
            c.forEach((res) => {
              const resultadoResposta = respostaCertoErradoOptions.find(
                (r) => r.key === res.anwser_correct
              );

              if (resultadoResposta) {
                resultadoResposta.selected = true;
              }

              colunaDataC.push({
                id: res.id + "",
                answer: res.answer,
                column: "third_column",
                points: 0,
                related_column: "",
                anwser_correct: resultadoResposta
                  ? resultadoResposta
                  : respostaCertoErradoOptions[1],
              });
            });
        } else if ("Padrão") {
          a &&
            a.forEach((res) => {
              colunaDataA.push({
                id: res.id + "",
                answer: res.answer,
                column: "first_column",
                points: res.points ? parseInt(res.points) : 0,
                related_column: "",
                anwser_correct: {
                  id: 0,
                  key: "",
                  selected: false,
                  useId: false,
                  value: "",
                },
              });
            });
        }
      }

      questao.supports.forEach((ap) => {
        textosData.push({
          id: ap.id + "",
          texto: ap.support_text,
          sequencia: ap.sequence_id,
        });
      });
    }
  }, [questao]);




  const atribuirTipoRespostaAPI = (tipo: string) => {
    if (idQuestao) {
      services.questaoService
        .atribuirTipoResposta(idQuestao as unknown as number, tipo)
        .then(() => {
          setTipoResposta(tipo);
        })
        .catch(() => {
          alert(
            "Ocorreu um erro ao atribuir o tipo de resposta selecionado a questão!"
          );
        });
    }
  };

  /////////////////////////////////////////////////////////////

  const salvarQuestaoAPI = () => {
    /////////////////////////////////////
    let questaoRequest: QuestaoRequest = {
      id: undefined,
      type: "",
      selected_type: "",
      selected_answer_type: "",
      selected_difficulty: "",
      selected_nature: "",
      selected_status: "",
      statement_question: "",
      classification: undefined,
      legal_references: new Array<ReferenciaLegalRequest>(),
      answers: new Map<string, RespostaRequest[]>(),
      supports: new Array<ApoioTextoRequest>(),
      exam_disc_active: ativarDiscProva,
      exam_discipline: discProva
    };

    const apoios = () => {
      const arrayApoios = new Array<ApoioTextoRequest>();

      textosData.forEach((texto) => {
        if (texto) {
          arrayApoios.push({
            type: "text",
            id: undefined,
            support_text: texto.texto,
            sequence_id: texto.sequencia,
          });
        }
      });

      return arrayApoios;
    };

    const respostas = () => {
      let arrayRespostas = {};

      if (
        tipoResposta === "Múltipla Escolha" ||
        tipoResposta === "Certo/Errado" ||
        tipoResposta === "Verdadeiro/Falso"
      ) {
        if (colunaDataA.length > 0) {
          const respostas = new Array<RespostaEscolhaRequest>();

          colunaDataA.forEach((resposta, index) => {
            if (resposta) {
              respostas.push({
                type: tipoRespostaAPI(),
                answer: resposta.answer,
                answer_correct: resposta.anwser_correct.key,
              });
            }
          });

          arrayRespostas = { first_column: respostas };
        }
      } else if (tipoResposta === "Relacional") {
        if (colunaDataA.length > 0) {
          const alternativas = new Array<RespostaRelacionalRequest>();

          colunaDataA.forEach((resposta) => {
            if (resposta) {
              alternativas.push({
                type: tipoRespostaAPI(),
                answer: resposta.answer,
                selected_column: resposta.column,
              });
            }
          });

          arrayRespostas = { ...arrayRespostas, first_column: alternativas };
        }

        if (colunaDataB.length > 0) {
          const alternativas = new Array<RespostaRelacionalRequest>();

          colunaDataB.forEach((resposta) => {
            if (resposta) {
              alternativas.push({
                type: tipoRespostaAPI(),
                answer: resposta.answer,
                selected_column: resposta.column,
                related_column: idToSequenceRespostaRelacional(resposta),
              });
            }
          });

          arrayRespostas = { ...arrayRespostas, second_column: alternativas };
        }

        if (colunaDataC.length > 0) {
          const alternativas = new Array<RespostaEscolhaRequest>();

          colunaDataC.forEach((resposta) => {
            if (resposta) {
              alternativas.push({
                type: "E",
                answer: resposta.answer,
                answer_correct: resposta.anwser_correct.value,
              });
            }
          });

          arrayRespostas = {
            ...arrayRespostas,
            third_column: alternativas,
          };
        }
      } else if (tipoResposta === "Padrão") {
        if (colunaDataA.length > 0) {
          const respostas = new Array<RespostaPadraoRequest>();

          colunaDataA.forEach((resposta) => {
            if (resposta) {
              respostas.push({
                type: tipoRespostaAPI(),
                answer: resposta.answer,
                points: resposta.points,
              });
            }
          });

          arrayRespostas = { first_column: respostas };
        }
      }

      return arrayRespostas;
    };

    const referenciasRequest: Array<ReferenciaLegalRequest> =
      referenciasLegais.map((referencia) => {
        return {
          selected_law_type_id: referencia.tipo_lei.entity
            ? referencia.tipo_lei.id
            : undefined,
          law: referencia.lei.value,
          article: referencia.artigo.value
            ? referencia.artigo.value
            : undefined,
          alinea: referencia.alinea.value ? referencia.alinea.value : undefined,
          inciso: referencia.inciso.value ? referencia.inciso.value : undefined,
          paragrafo: referencia.paragrafo.value
            ? referencia.paragrafo.value
            : undefined,
          item: referencia.item.value ? referencia.item.value : undefined,
          id: referencia.id,
        };
      });

    questaoRequest = {
      id: undefined,
      type: tipoQuestaoAPI(),
      selected_answer_type: tipoResposta,
      selected_type: tipo,
      selected_nature: natureza.value,
      correct: correto.value ? correto.key : undefined,
      selected_difficulty:
        dificuldade.value !== "" ? dificuldade.value : undefined,
      selected_status: status.value,
      sources: fontes.map((value) => value.value),
      legal_references: referenciasRequest,
      classification: tema.value
        ? {
          selected_subject_id: disciplina.id,
          selected_tag_id: tag.id >= 1 ? tag.id : undefined,
          selected_topic_id: tema.id,
        }
        : undefined,
      statement_question: enunciado,
      answers: respostas(),
      supports: apoios(),
      exam_discipline: discProva,
      exam_disc_active: ativarDiscProva
    };

    if (questao) {
      return services.questaoService.editarQuestao({
        ...questaoRequest,
        id: questao.id,
      });
    } else {
      if (prova) {
        return services.questaoService.salvarQuestaoNaProva(
          questaoRequest,
          prova.id
        );
      } else {
        return services.questaoService.salvarQuestao(questaoRequest);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const idToSequenceRespostaRelacional = (resposta: Resposta) => {
    let pos: number | undefined = undefined;

    colunaDataA.forEach((res, index) => {
      if (res.id === resposta.related_column) {
        pos = index;
      }
    });

    return pos;
  };

  const setInitialCorreto = (questao: QuestaoDetails) => {
    if (questao.correct) {
      const temp = premissasOptions.find(
        (value) => value.key === questao.correct
      );

      temp && setCorreto(temp);
    }
  };

  const setInitialTipoPremissa = (questao: QuestaoDetails) => {
    let tipo: TipoPremissa | undefined = undefined;

    if (questao.answer_type === "Única") {
      if (questao.correct) {
        const correto = questao.correct;

        if (correto === "Verdadeiro" || correto === "Falso") {
          tipo = TipoPremissa.VF;
        } else if (correto === "Certo" || correto === "Errado") {
          tipo = TipoPremissa.CR;
        }
      }
    }

    setTipoPremissa(tipo);
  };

  const tipoQuestaoAPI = () => {
    switch (natureza.value) {
      case "Jurídica": {
        return "tipo_juridico";
      }
      default: {
        return "nao_juridica";
      }
    }
  };

  const tipoRespostaAPI = () => {
    switch (tipoResposta) {
      case "Padrão": {
        return "P";
      }

      case "Certo/Errado": {
        return "E";
      }

      case "Verdadeiro/Falso": {
        return "E";
      }
      case "Relacional": {
        return "R";
      }
      default: {
        return "E";
      }
    }
  };

  //////////////////////////////////////////////////////////////////

  const addTexto = (texto: TextoApoio) => {
    //
    const setTexto = (texto: TextoApoio) => {
      if (texto) {
        setTextosData([...textosData, texto]);
      }
    };

    if (questao) {
      const request: ApoioTextoRequest = {
        sequence_id: texto.sequencia,
        support_text: texto.texto,
        type: "text",
      };

      services.questaoService
        .salvarTexto(questao.id, request)
        .then((texto) => {
          setTexto({
            id: texto.id + "",
            texto: texto.support_text,
            sequencia: texto.sequence_id,
          });
          alert("Texto salvo com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      setTexto(texto);
    }
  };

  const editarTexto = (
    textoAnterior: TextoApoio,
    textoAlterado: TextoApoio
  ) => {
    const editar = (textos: TextoApoio[]) => {
      let pos = textosData.findIndex((text) => text === textoAnterior);

      if (pos >= 0) {
        textosData[pos] = textoAlterado;
      }

      return [...textos];
    };

    if (questao) {
      const request: ApoioTextoRequest = {
        id: parseInt(textoAlterado.id),
        sequence_id: textoAlterado.sequencia,
        support_text: textoAlterado.texto,
        type: "text",
      };

      services.questaoService
        .editarTexto(questao.id, request, parseInt(textoAnterior.id))
        .then(() => {
          setTextosData(editar(textosData));
          alert("Texto salvo com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      setTextosData(editar(textosData));
    }
  };

  const removerTexto = (texto: TextoApoio) => {
    const remover = () => {
      setTextosData(textosData.filter((ele) => ele !== texto));
    };

    if (questao) {
      services.questaoService
        .excluirTexto(parseInt(texto.id), questao.id)
        .then(() => {
          alert("Texto excluído com sucesso!");
          remover();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      remover();
    }
  };

  /////////////////////////////////////

  //////////////////////////////////////////////////////////////////

  const addReferenciaLegal = (referenciaLegal: TReferencialegal) => {
    //
    const setReferencia = (referencia: TReferencialegal) => {
      if (referencia) {
        setReferenciasLegais([...referenciasLegais, referencia]);
      }
    };

    if (questao) {
      const request: ReferenciaLegalRequest = {
        selected_law_type_id: referenciaLegal.tipo_lei.id,
        law: referenciaLegal.lei.value,
        article: referenciaLegal.artigo.value
          ? referenciaLegal.artigo.value
          : undefined,
        paragrafo: referenciaLegal.paragrafo.value
          ? referenciaLegal.paragrafo.value
          : undefined,
        inciso: referenciaLegal.inciso.value
          ? referenciaLegal.inciso.value
          : undefined,
        alinea: referenciaLegal.alinea.value
          ? referenciaLegal.alinea.value
          : undefined,
        item: referenciaLegal.item.value
          ? referenciaLegal.item.value
          : undefined,
        id: undefined,
      };

      services.questaoService
        .salvarReferenciaLegal(questao.id, request)
        .then((referenciaDetails) => {
          setReferencia({
            ...referenciaLegal,
            id: referenciaDetails.id,
          });

          alert("Referência legal salva com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      setReferencia(referenciaLegal);
    }
  };

  const editarReferenciaLegal = (
    referenciaAtualizar: TReferencialegal,
    referenciaAlvo: TReferencialegal
  ) => {
    const editar = (referencias: TReferencialegal[]) => {
      const index = referenciasLegais.findIndex(
        (value) => value === referenciaAlvo
      );

      if (index >= 0) {
        referenciasLegais[index] = referenciaAtualizar;
        return [...referencias];
      }

      return referencias;
    };

    if (questao && referenciaAtualizar.id) {
      const request: ReferenciaLegalRequest = {
        selected_law_type_id: referenciaAtualizar.tipo_lei.id,
        law: referenciaAtualizar.lei.value,
        article: referenciaAtualizar.artigo.value
          ? referenciaAtualizar.artigo.value
          : undefined,
        paragrafo: referenciaAtualizar.paragrafo.value
          ? referenciaAtualizar.paragrafo.value
          : undefined,
        inciso: referenciaAtualizar.inciso.value
          ? referenciaAtualizar.inciso.value
          : undefined,
        alinea: referenciaAtualizar.alinea.value
          ? referenciaAtualizar.alinea.value
          : undefined,
        item: referenciaAtualizar.item.value
          ? referenciaAtualizar.item.value
          : undefined,
        id: referenciaAtualizar.id,
      };

      services.questaoService
        .editarReferenciaLegal(questao.id, request, referenciaAtualizar.id)
        .then(() => {
          setReferenciasLegais(editar(referenciasLegais));
          alert("Referência legal atualizada com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      setReferenciasLegais(editar(referenciasLegais));
    }
  };

  const deletarReferenciaLegal = (referencia: TReferencialegal) => {
    const remover = () => {
      setReferenciasLegais(
        referenciasLegais.filter((ele) => ele !== referencia)
      );
    };

    if (questao && referencia.id) {
      services.questaoService
        .excluirReferenciaLegal(referencia.id, questao.id)
        .then(() => {
          alert("Referência legal excluída com sucesso!");
          remover();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      remover();
    }
  };

  /////////////////////////////////////

  const addResposta = (resposta: Resposta, coluna: string) => {
    //
    const adicionar = (resposta: Resposta) => {
      switch (coluna) {
        case "first_column": {
          setColunaDataA([...colunaDataA, resposta]);
          break;
        }
        case "second_column": {
          setColunaDataB([...colunaDataB, resposta]);
          break;
        }
        case "third_column": {
          setColunaDataC([...colunaDataC, resposta]);
          break;
        }
      }
    };

    if (questao) {
      let requestAPI: RespostaRequest = {
        answer: "",
        type: "",
        id: 0,
      };

      if (
        tipoResposta === "Múltipla Escolha" ||
        tipoResposta === "Certo/Errado" ||
        tipoResposta === "Verdadeiro/Falso"
      ) {
        const request: RespostaEscolhaRequest = {
          type: tipoRespostaAPI(),
          answer: resposta.answer,
          answer_correct: resposta.anwser_correct.key,
        };

        requestAPI = request;
      } else if (tipoResposta === "Relacional") {
        if (resposta.column === "first_column") {
          const request: RespostaRelacionalRequest = {
            type: tipoRespostaAPI(),
            answer: resposta.answer,
            selected_column: resposta.column,
          };

          requestAPI = request;
        } else if (resposta.column === "second_column") {
          const request: RespostaRelacionalRequest = {
            type: tipoRespostaAPI(),
            answer: resposta.answer,
            selected_column: resposta.column,
            related_column: parseInt(resposta.related_column),
          };
          requestAPI = request;
        } else if (resposta.column === "third_column") {
          const request: RespostaEscolhaRequest = {
            type: "E",
            answer: resposta.answer,
            answer_correct: resposta.anwser_correct.key,
          };

          requestAPI = request;
        }
      } else if (tipoResposta === "Padrão") {
        const request: RespostaPadraoRequest = {
          type: tipoRespostaAPI(),
          answer: resposta.answer,
          points: resposta.points,
        };

        requestAPI = request;
      }

      if (requestAPI.answer) {
        services.questaoService
          .salvarResposta(questao.id, requestAPI)
          .then((res) => {
            adicionar({
              ...resposta,
              id: res.id + "",
            });
            alert("Resposta salva com sucesso!");
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    } else {
      adicionar(resposta);
    }
  };

  const upload = async (formData: FormData, associated_id: string) => {
    return services.questaoFileService.salvarImagemQuestao(
      formData,
      associated_id as unknown as number
    );
  };

  const editarResposta = (
    respostaAnterior: Resposta,
    respostaAlterada: Resposta,
    coluna: string
  ) => {
    //

    const editar = (respostas: Resposta[], alterada: Resposta) => {
      let pos = respostas.findIndex((res) => res === respostaAnterior);

      if (pos >= 0) {
        respostas[pos] = alterada;
      }

      return [...respostas];
    };

    const alterar = (res: Resposta) => {
      //
      switch (coluna) {
        case "first_column": {
          setColunaDataA(editar(colunaDataA, res));
          break;
        }
        case "second_column": {
          setColunaDataB(editar(colunaDataB, res));
          break;
        }
        case "third_column": {
          setColunaDataC(editar(colunaDataC, res));
          break;
        }
      }
    };

    if (questao) {
      let requestAPI: RespostaRequest = {
        answer: "",
        type: "",
        id: 0,
      };

      if (
        tipoResposta === "Múltipla Escolha" ||
        tipoResposta === "Certo/Errado" ||
        tipoResposta === "Verdadeiro/Falso"
      ) {
        const request: RespostaEscolhaRequest = {
          id: parseInt(respostaAlterada.id),
          type: tipoRespostaAPI(),
          answer: respostaAlterada.answer,
          answer_correct: respostaAlterada.anwser_correct.key,
        };

        requestAPI = request;
      } else if (tipoResposta === "Relacional") {
        if (respostaAlterada.column === "first_column") {
          const request: RespostaRelacionalRequest = {
            id: parseInt(respostaAlterada.id),
            type: tipoRespostaAPI(),
            answer: respostaAlterada.answer,
            selected_column: respostaAlterada.column,
          };

          requestAPI = request;
        } else if (respostaAlterada.column === "second_column") {
          const request: RespostaRelacionalRequest = {
            id: parseInt(respostaAlterada.id),
            type: tipoRespostaAPI(),
            answer: respostaAlterada.answer,
            selected_column: respostaAlterada.column,
            related_column: parseInt(respostaAlterada.related_column),
          };
          requestAPI = request;
        } else if (respostaAlterada.column === "third_column") {
          const request: RespostaEscolhaRequest = {
            id: parseInt(respostaAlterada.id),
            type: "E",
            answer: respostaAlterada.answer,
            answer_correct: respostaAlterada.anwser_correct.key,
          };

          requestAPI = request;
        }
      } else if (tipoResposta === "Padrão") {
        const request: RespostaPadraoRequest = {
          id: parseInt(respostaAlterada.id),
          type: tipoRespostaAPI(),
          answer: respostaAlterada.answer,
          points: respostaAlterada.points,
        };

        requestAPI = request;
      }

      if (requestAPI.answer) {
        services.questaoService
          .editarResposta(questao.id, requestAPI, parseInt(respostaAnterior.id))
          .then(() => {
            alterar(respostaAlterada);
            alert("Resposta modificada com sucesso!");
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    } else {
      alterar(respostaAlterada);
    }
  };

  const removerResposta = (resposta: Resposta, coluna: string) => {
    if (questao) {
      deletarRespostaAPI(resposta, coluna);
    } else {
      deletarRespostaArray(resposta, coluna);
    }
  };

  const deletarRespostaArray = (resposta: Resposta, coluna: string) => {
    const excluir = (respostas: Resposta[]) => {
      return respostas.filter((ele) => ele !== resposta);
    };

    switch (coluna) {
      case "first_column": {
        setColunaDataA(excluir(colunaDataA));
        break;
      }
      case "second_column": {
        setColunaDataB(excluir(colunaDataB));
        break;
      }
      case "third_column": {
        setColunaDataC(excluir(colunaDataC));
        break;
      }
    }
  };

  const deletarRespostaAPI = (resposta: Resposta, coluna: string) => {
    if (questao) {
      services.questaoService
        .excluirResposta(parseInt(resposta.id), questao.id)
        .then(() => {
          deletarRespostaArray(resposta, coluna);
          alert("Resposta excluída com sucesso!");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const setTipoData = (value: string) => {
    if (value !== tipo) {
      setTipo(value);
    }
  };

  const setEnunciadoData = (value: string) => {
    if (value !== enunciado) {
      setEnunciado(value);
    }
  };

  const setDisciplinaData = (value: ItemSelect) => {
    if (value !== disciplina) {
      setDisciplina(value);
    }
  };

  const setTemaData = (value: ItemSelect) => {
    if (value !== tema) {
      setTema(value);
    }
  };

  const setTagData = (value: ItemSelect) => {
    if (value !== tag) {
      setTag(value);
    }
  };

  const setTipoPremissaData = (value: TipoPremissa) => {
    if (value !== tipoPremissa) {
      setTipoPremissa(value);
    }
  };

  const setCorretoData = (value: TItemSelect) => {
    if (value !== correto) {
      setCorreto(value);
    }
  };

  const setDificuldadeData = (value: TItemSelect) => {
    if (value !== dificuldade) {
      setDificuldade(value);
    }
  };
  const setFontesData = (value: ItemSelect[]) => {
    if (value !== fontes) {
      setFontes(value);
    }
  };
  const setNaturezaData = (value: TItemSelect) => {
    if (value !== natureza) {
      setNatureza(value);
    }
  };
  const setStatusData = (value: TItemSelect) => {
    if (value !== status) {
      setStatus(value);
    }
  };

  const setTipoRespostaData = (value: string) => {
    if (value !== tipoResposta) {
      setTipoResposta(value);
    }
  };

  const setIdQuestaoData = (value: string | undefined) => {
    setIdQuestao(value);
  };

  const setProvaData = (value?: Prova) => {
    if (value !== prova) {
      setProva(value);
    }
  };

  const setQuestaoData = (data: QuestaoDetails) => {
    setQuestao(data);
  }

  const context = {
    idQuestao,
    prova: prova,
    questao: {
      tipo: tipo,
      tipoResposta: tipoResposta,
      referencias_legais: referenciasLegais,
      enunciado: enunciado,
      disciplina: disciplina,
      correto: correto,
      tema: tema,
      tag: tag,
      dificuldade: dificuldade,
      fontes: fontes,
      natureza: natureza,
      status: status,
      ativarDiscProva: ativarDiscProva,
      discProva: discProva
    },
    imageData: imagemDataContext,
    tipoPremissa: tipoPremissa,
    apoios: textosData,
    addTexto: addTexto,
    editarTexto: editarTexto,
    removerTexto: removerTexto,
    respostas: [colunaDataA, colunaDataB, colunaDataC],
    addResposta: addResposta,
    editarResposta: editarResposta,
    removerResposta: removerResposta,
    addReferenciaLegal: addReferenciaLegal,
    editarReferenciaLegal: editarReferenciaLegal,
    deletarReferenciaLegal: deletarReferenciaLegal,
    salvarQuestaoAPI: salvarQuestaoAPI,
    atribuirTipoRespostaAPI: atribuirTipoRespostaAPI,
    callbacks: {
      setTipo: setTipoData,
      setTipoPremissa: setTipoPremissaData,
      setEnunciado: setEnunciadoData,
      setDisciplina: setDisciplinaData,
      setTAG: setTagData,
      setTema: setTemaData,
      setCorreto: setCorretoData,
      setDificuldade: setDificuldadeData,
      setFontes: setFontesData,
      setNatureza: setNaturezaData,
      setStatus: setStatusData,
      setTipoResposta: setTipoRespostaData,
      setIdQuestao: setIdQuestaoData,
      setProva: setProvaData,
      setQuestaoData,
      setAtivarDiscProva,
      setDiscProva
    },
  };

  return <QuestaoProvider value={context}>{children}</QuestaoProvider>;
};

export const validarLeiNumeracao = (lei: string) => {
  const regra = /^(\d{2}).(\d{3})\/(\d{4})$/s;

  if (regra.exec(lei) || !lei) {
    return {
      erro: "",
      value: lei,
    };
  } else {
    return {
      erro: "A leis não corresponde ao padrão...",
      value: lei,
    };
  }
};

export const validarDispositivo = (
  valor: string | undefined,
  dispositivo: TagDispositivoLegal
) => {
  let regex: RegExp | undefined = undefined;
  let padrao: string = "test";

  switch (dispositivo.name) {
    case "Inciso": {
      regex = /^M{0,3}(CM|CD|D?C{0,3})?(XC|XL|L?X{0,3})?(IX|IV|V?I{0,3})?$/;

      if (dispositivosInciso.padrao) {
        padrao = dispositivosInciso.padrao;
      }

      break;
    }
    case "Alínea": {
      regex = /^([a-z]{1})$/s;

      if (dispositivosAlinea.padrao) {
        padrao = dispositivosAlinea.padrao;
      }

      break;
    }

    case "Item": {
      regex = /^(\d{4})$/s;

      if (dispositivosItem.padrao) {
        padrao = dispositivosItem.padrao;
      }

      break;
    }

    case "Parágrafo": {
      regex = /^(\d{4}|parágrafo único)$/s;

      if (dispositivosParagrafo.padrao) {
        padrao = dispositivosParagrafo.padrao;
      }

      break;
    }
  }

  if (regex && valor && valor !== "") {
    if (regex.exec(valor)) {
      return {
        padrao: padrao,
        erro: "",
        value: valor,
      };
    } else {
      return {
        padrao: padrao,
        erro: `${dispositivo.name} não corresponde ao padrão...`,
        value: valor,
      };
    }
  } else {
    return {
      padrao: padrao,
      erro: "",
      value: "",
    };
  }
};

export const validarLei = (lei: string, tipo_lei: TipoLei) => {
  if (tipo_lei) {
    switch (tipo_lei.pattern_type) {
      case "Termos": {
        const termos = tipo_lei.terms.split(";").join("|");
        const regra = new RegExp("^(" + termos + ")$", "s");

        if (regra.exec(lei) || !lei) {
          return {
            erro: "",
            value: lei,
          };
        } else {
          return {
            erro: "A leis não corresponde ao padrão...",
            value: lei,
          };
        }
      }
      case "Numeração": {
        return validarLeiNumeracao(lei);
      }
    }
  } else {
    return validarLeiNumeracao(lei);
  }
};

export const validarArtigo = (value?: string) => {
  const regra = /^(\d{4})(-[A-Z]{1})?$/;

  if (value) {
    if (regra.exec(value) || !value) {
      return {
        erro: "",
        value: value,
      };
    } else {
      return {
        erro: "Os artigos não corresponde ao padrão...",
        value: value,
      };
    }
  } else {
    return {
      erro: "",
      value: "",
    };
  }
};

export const mapReferenciasLegaisByQuestaoDetails = (
  referenciasDetails: ReferenciasLegaisDetails[]
): TReferencialegal[] => {
  const referencias = new Array<TReferencialegal>();

  referenciasDetails.forEach((referencia) => {
    const tipoLei = referencia.law_type;

    let tipoLeiCarregado: ItemSelectEntity<TipoLei> = {
      ...INITIAL_STATE,
      useId: false,
      entity: undefined,
    };

    if (tipoLei) {
      tipoLeiCarregado = {
        id: tipoLei.id,
        value: tipoLei.name,
        key: "law-type",
        useId: false,
        entity: tipoLei,
      };
    }

    const lei = validarLei(referencia.law, referencia.law_type);

    const artigo = validarArtigo(referencia.article);

    const paragrafo = validarDispositivo(referencia.paragrafo, {
      name: "Parágrafo",
    });

    const inciso = validarDispositivo(referencia.inciso, {
      name: "Inciso",
    });

    const alinea = validarDispositivo(referencia.alinea, {
      name: "Alínea",
    });

    const item = validarDispositivo(referencia.item, {
      name: "Item",
    });

    referencias.push({
      tipo_lei: tipoLeiCarregado,
      lei: lei
        ? lei
        : {
          erro: "",
          value: "",
        },
      artigo: artigo,
      paragrafo: paragrafo,
      inciso: inciso,
      alinea: alinea,
      item: item,
      id: referencia.id,
    });
  });

  return referencias;
};
