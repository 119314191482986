import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ButtonNormal } from "../../components/button/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { ActionType } from "../../components/page/GerenciarPageBase/types";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import {
  ItemDataSelect,
  useSelectedDataAction
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { usuarioOrdenacao } from "../../mocks/data";
import { APIFetch } from "../../services/api";
import {
  SenhaUpdate,
  useUsuarioService,
  UsuarioResumido
} from "../../services/api-services/usuarios";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { ConfirmarAutorizacao } from "./forms/confirmar-autorizacao";
import { ExcluirUsuario } from "./forms/excluir-usuario";
import { TrocarSenha } from "./forms/trocar-senha";
import { UsuarioHeader } from "./views/row-usuario";

export const GerenciarUsuariosComponent = () => {
  //
  const history = useHistory();

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const usuarioService = useUsuarioService(APIFetch(auth));

  const [sort, setSort] = useState("id");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("header");

  const [search, setSearch] = useState("");

  const [qtdItems, setQtdItems] = useState("10");

  const usuarioState = useState<Array<ItemDataSelect<UsuarioResumido>>>([]);
  const setUsuarios = usuarioState[1];

  const useTagActions = useTagAction();
  const selectedEntitiesAction = useSelectedDataAction<UsuarioResumido>({
    state: usuarioState,
  });

  useEffect(() => {
    if (context.loading) {
      usuarioService
        .listagemUsuarios(
          context.resetPagination() ? 0 : context.nextPage - 1,
          qtdItems,
          search,
          sort,
          order
        )
        .then((usuarios) => {
          setUsuarios(
            usuarios.content.map((value: UsuarioResumido) => {
              return {
                isChecked: false,
                id_item: value.id,
                entity: { ...value },
              };
            })
          );

          context.changePaginationData(usuarios.paginationData);
          context.toPage(usuarios.paginationData.current_page);
          context.changeLoading(false);
          context.setIsNewPage(false);
        }).catch(() => {
          context.changeLoading(false);
        });
    }
  }, [context.loading]);

  const rightMenu = () => {
    return (
      <Fragment>
        <div />
        <ButtonNormal
          icon={MdAddCircleOutline}
          title={"Novo usuario"}
          className={"usuario-button"}
          onClick={() => {
            history.push("/usuario");
          }}
        />
      </Fragment>
    );
  };

  const goToAutorizacao = (state: [ActionType, Dispatch<SetStateAction<ActionType>>], senhaUpdate: SenhaUpdate) => {

    const [currentState, setCurrentState] = state;

    setCurrentState({
      action: "autorizar",
      id: currentState.id,
      promisse: () => {
        return usuarioService.trocarSenha(currentState.id, senhaUpdate);
      },
    });
  }

  const modals = () => {
    switch (context.currentAction.action) {
      case "trocar-senha": {
        return <TrocarSenha resetSenha={true} />;
      }

      case "autorizar": {
        return <ConfirmarAutorizacao chargeUsername={false} notShowAlert={true} />;
      }

      case "excluir": {
        return <ExcluirUsuario />;
      }
      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      default: {
        return <Fragment />;
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<UsuarioResumido>
        callbacks={{
          search: (key) => {
            setSearch(key);
            context.changeLoading(true);
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => setMode(button.value),
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={usuarioState}
        page={{
          name: "Gerenciar usuarios",
          header: {
            left: null,
            right: rightMenu(),
          },
          searchPlaceHolder: "Pesquisar por nome",
          showFilters: false,
        }}
        visualization={{
          sort: {
            params: usuarioOrdenacao,
          },
          options: [],
          mode: mode,
          table: {
            dataRow: [
              <div>Credenciais</div>,
              <div>Nome completo</div>,
              <div>Conta ativada</div>,
              <div>Ações</div>,
            ],
            dataColumn: UsuarioHeader().usuarioHeader,
          },
        }}
      />
      {modals()}
    </Fragment>
  );
};

export const GerenciarUsuarios = () => {
  return (
    <GerenciarBaseProvider>
      <GerenciarUsuariosComponent />
    </GerenciarBaseProvider>
  );
};
