import { useEffect, useState } from "react";
import { PaginationData } from "../../pagination/types";
import { GerenciarProvider } from "./context";
import { ActionType } from "./types";

export const GerenciarBaseProvider: React.FC<{}> = (props) => {
  const [loadingState, setLoadingState] = useState(true);

  const [nextPage, setNextPage] = useState(1);

  const [isNewPage, setIsNewPage] = useState(true);

  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 1,
    total_items: 0,
    total_number_pages: 0,
  });

  const currentActionState = useState<ActionType>({
    id: 0,
    action: "",
    loading: false,
    message: "",
    object: {},
  });

  const [currentAction, setCurrentAction] = currentActionState;

  useEffect(() => {
    if (currentAction.loading) {
      setLoadingState(true);
    }
  }, [currentAction]);

  const changeAction = (action: ActionType) => {
    if (action !== currentAction) {
      setCurrentAction(action);
    }
  };

  const changeLoading = (loading: boolean) => {
    if (loading !== loadingState) {
      setLoadingState(loading);
    }
  };

  const setIsNewPageData = (isNew: boolean) => {
    if (isNew !== isNewPage) {
      setIsNewPage(isNew);
    }
  };

  const changePaginationData = (pagination: PaginationData) => {
    if (pagination !== paginationData) {
      setPaginationData(pagination);
    }
  };

  const resetPagination = () => {
    let check = false;

    if (isNewPage) {
      if (nextPage === 0) {
        check = true;
      }
    }

    return check;
  };

  const toPage = (next: number) => {
    if (next !== nextPage) {
      setNextPage(next);
      setIsNewPage(true);
    }
  };

  return (
    <GerenciarProvider
      value={{
        toPage: toPage,
        nextPage: nextPage,
        isNewPage: isNewPage,
        currentAction: currentAction,
        currentActionState: currentActionState,
        loading: loadingState,
        changeAction: changeAction,
        changeLoading: changeLoading,
        resetPagination: resetPagination,
        setIsNewPage: setIsNewPageData,
        changePaginationData: changePaginationData,
        paginationData: paginationData,
      }}
    >
      {props.children}
    </GerenciarProvider>
  );
};
