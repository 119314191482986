import { Fragment, useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { ButtonBottomLess } from "../button/bottomless";
import { ButtonWrapper, MultipleButtonSelectionContent } from "./styles";



export interface TMultipleButtonSelection {
    buttons: Array<TButtonSelection>;
    maxSelections: number;
    totalSelectedValue?: (total: number) => void;
    getValues?: (values: TButtonSelection[]) => void;
    isHierarchy?: boolean;

}

export interface TButtonSelection {
    name: string,
    value: number,
    icon?: React.ComponentType<IconBaseProps>;
    selected: boolean

}


export const MultipleButtonSelection: React.FC<TMultipleButtonSelection> = ({ buttons, maxSelections, totalSelectedValue, isHierarchy, getValues }) => {

    const [multipleButtons, setMultipleButtons] = useState<TButtonSelection[]>(buttons);
    const [totalSelected, setTotalSelected] = useState<number>(0);

    useEffect(() => {
        totalSelectedValue && totalSelectedValue(totalSelected);
    }, [totalSelected]);

    useEffect(() => {

        getValues && getValues(multipleButtons.map((value) => {
            return value;
        }));

    }, [multipleButtons]);


    const onSelect = (selectedItem: TButtonSelection) => {
        setMultipleButtons(multipleButtons.map((item) => {

            if (item.value === selectedItem.value) {

                if (selectedItem.selected) {

                    item.selected = false;
                    setTotalSelected(totalSelected - 1);

                    return item;
                } else {

                    if (totalSelected != maxSelections) {
                        item.selected = true;
                        setTotalSelected(totalSelected + 1);

                    }
                    return item;
                }
            }
            return item;
        }));
    }

    const renderButton = (value: TButtonSelection, index: number) => {
        let buttonNode = <Fragment />

        if (value.selected) {
            buttonNode = <ButtonBottomLess
                icon={value.icon}
                className="button-selected"
                title={value.name}
                onClick={() => onSelect(value)}
            />
        } else {
            buttonNode = <ButtonBottomLess
                icon={value.icon}
                className="button"
                title={value.name}
                onClick={() => onSelect(value)}
            />
        }

        if (isHierarchy) {
            return <ButtonWrapper row={{
                marginLeft: 24 * (index + 1)
            }}>
                {buttonNode}
            </ButtonWrapper>
        }

        return <ButtonWrapper>
            {buttonNode}
        </ButtonWrapper>
    }

    return (
        <MultipleButtonSelectionContent>
            {multipleButtons.map((value, index) => {
                return renderButton(value, index);
            })}
        </MultipleButtonSelectionContent>
    )
};