import { QuestaoDetails } from "../../../../../services/api-services/questao";
import { QuestaoViewContainer } from "../styles";
import { TextLeft, TextoRow } from "./styles";

export interface TApoios {
  questao?: QuestaoDetails;
}

export const Apoios: React.FC<TApoios> = (props) => {
  return (
    <QuestaoViewContainer>
      {props.questao &&
        props.questao.supports.map((ele, index) => {
          const count = index + 1;
          return (
            <TextoRow>
              <TextLeft>
                <div className="number">
                  <b>{`${count}-)`}</b>
                </div>
                <div
                  className="content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: ele.support_text,
                  }}
                />
              </TextLeft>
            </TextoRow>
          );
        })}
    </QuestaoViewContainer>
  );
};
