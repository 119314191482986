import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { GroupButtons, setStateValueGroup } from '../../../../../components/group-buttons';
import { InputIcon } from '../../../../../components/input/input-icon';
import { BaseHeaderComponent } from '../../../../../components/page/structure/BaseComponent';
import { PaginationOptions } from '../../../../../components/page/structure/ListComponent/list-card/model/styles';
import { ListCard } from '../../../../../components/page/structure/ListComponent/styles';
import { Pagination } from '../../../../../components/pagination';
import { PaginationData } from '../../../../../components/pagination/types';
import { Comentario } from '../../../../../services/api-services/comentarios';
import { ComentarioItem } from '../comentario';
import { CommentsContent, CommentsFilter } from './styles';

interface TListComentariosProps {
  idQuestao: number;
  title: string;
  comentarios: Array<Comentario>;
  paginacaoData: PaginationData;
  callback: {
    onSearchPage: (search: string) => void;
    onValidacao: (validacao: string) => void;
    onCurrentPage: (page: number) => void;
    onEditComentario: (idComentario: number, idResposta: number) => void;
  };
}

export const ListBaseComentarios: React.FC<TListComentariosProps> = (props) => {
  //

  const { callback, paginacaoData, idQuestao, title } = props;

  const [currentPage, setCurrentPage] = useState(0);

  const [search, setSearch] = useState('');

  const [validacao, setValidacao] = useState('');

  const [comentariosItens, setComentariosItens] = useState<Array<Comentario>>([]);

  useEffect(() => {
    setComentariosItens(props.comentarios);
  }, [props.comentarios]);

  useEffect(() => {
    callback.onSearchPage(search);
  }, [search]);

  useEffect(() => {
    callback.onValidacao(validacao);
  }, [validacao]);

  useEffect(() => {
    callback.onCurrentPage(currentPage);
  }, [currentPage]);

  return (
    <CommentsContent>
      <BaseHeaderComponent pageName={title} />
      <CommentsFilter>
        <InputIcon
          onChangeValue={(text) => {
            setSearch(text);
          }}
          onClickSearch={() => { }}
          icon={FaSearch}
          className={'search'}
          placeholder={'Pesquisar por autor,perfil de questão ou enunciado'}
        />
        <GroupButtons
          className="tabs"
          initialPositionSelected={2}
          buttons={[
            {
              onClick: (current) => setStateValueGroup(current, setValidacao),
              name: 'publicados',
              classname: 'status-option',
              value: 'Publicado',
            },
            {
              onClick: (current) => setStateValueGroup(current, setValidacao),
              name: 'pendentes',
              classname: 'status-option',
              value: 'Pendente',
            },
            {
              onClick: (current) => setStateValueGroup(current, setValidacao),
              name: 'ambos',
              classname: 'status-option',
              value: '',
            },
          ]}
        />
      </CommentsFilter>
      <PaginationOptions className="pagination-data">
        {paginacaoData.total_items} resultados na página atual ({paginacaoData.current_page + 1} -{' '}
        {paginacaoData.total_number_pages})
      </PaginationOptions>
      <ListCard className="list-comentarios">
        {comentariosItens.map((item, index) => {
          return (
            <ComentarioItem comentario={item} key={index} id={idQuestao} onEditComentario={callback.onEditComentario} />
          );
        })}
      </ListCard>
      <Pagination
        className="pagination"
        isInitialPage={false}
        totalNumberPages={paginacaoData.total_number_pages}
        onCurrentPage={(current) => {
          setCurrentPage(current);
        }}
      />
    </CommentsContent>
  );
};
